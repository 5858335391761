// Form Name is corrrect
// Table name : ac_ledger_branch_wise
// using new css

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

// import { CSVLink } from "react-csv";

const ExamGroup = () => {    
    const dispatch = useDispatch();    
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);   
    
    // const router = useRouter(); 

    const navigate = useNavigate();

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);    
    const [defaultdata, setdefaultdata]= useState([]);

    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0);
    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);    

    const [fin_year, setfin_year] = useState(0);
    const [fin_yearItems, setfin_yearItems] = useState([]);    

    const [apiName, setapiName] = useState('')

    const [copy_under_comp_branch_id, copy_setunder_comp_branch_id] = useState(0);
    const [copy_fin_year, copy_setfin_year] = useState(0);

    const [st_classItems, setst_classItems] = useState([]);
    const [st_class, setst_class] = useState('') ;

    const [copy_st_classItems, copy_setst_classItems] = useState([]);
    const [copy_st_class, copy_setst_class] = useState('') ;    
  
    let list1 = [];
    
    const {
        pk_id,
        loading,                
        error,  
        created,
        updated,
        deleted,    
        createRecord,       
        setPkID,               
        setCreated,
        setUpdated,                                   
        setDeleted,
        clearErrors,

        deletedMessage, 
        createdMessage, 
        updatedMessage,    
        whenNulldeleteMessage,
        whenNoRecordFoundMessage,
    } = useContext(CRUDContext);    

    // ////////////////////////////

    // const getAuthroziedUser = async () => {  
    //     try{
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //               { headers: { Authorization: `Bearer ${token}` } });            
    
    //         if (response.status === 401 || response.status === 403){
    //             localStorage.clear();
    //             navigate('/Login')
    //         };

    //     }catch(error){
    //         localStorage.clear();
    //         navigate('/Login')
    //     }
    // }    

    // useEffect( () => {        
    //     getAuthroziedUser();    
    // }, []);

    // ////////////////////////////

    useEffect(() => { 
        if (error){
          toast.error(error);
          clearErrors();
        }

        if (created){
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
        }

        if (updated){
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
        }

        if (deleted){
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
        }
    }, [error, created, updated, deleted]);    

    useEffect(() =>{       

        let response = []
        let updatedata = []

        const getTableRecordFunctionBranch = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_comp_branchItems(updatedata);
        }
        getTableRecordFunctionBranch('url_comp_branch_view_drop_down');

        const getParamTableRecordFunctionBranch = async (var_link) => {
            response = await axios.get(`${var_link}/FY`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setfin_yearItems(updatedata);
            
            // response = await axios.get(`url_param_view_branch_wise_drop_down/${localStorage.getItem('br')}/ST_CLASS`,
            // { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            // updatedata = await response.data.v_out_data
            // setst_classItems(updatedata);  
            
        }
        getParamTableRecordFunctionBranch('url_param_view_drop_down');

              

    }, [])

    useEffect(() =>{
        showRecordFunction();
    }, [created]);
    
    const createRecordFunction = (event) => {
        event.preventDefault();

        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.error("Entry required for under branch !");
            clearErrors();
            return;
        }

        if(fin_year === undefined || fin_year.length === undefined || fin_year.length === 0){
            toast.error("Entry required for financial year !");
            clearErrors();
            return;
        }

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.error("Entry required for student class !");
            clearErrors();
            return;
        }

        const data = {            
            under_comp_branch_id,
            fin_year,
            st_class,
            toitems: Array(items),
        };

        // console.log("data is : ", data)

        dispatch(emptyCart());
        dispatch(addTempList(data));

        setapiName('url_subject_master_branch_wise_new_proc');

        setModelModelFormYesNo(true)
    }

    const handlerChange = (event, i) => {
        event.preventDefault();
        const { name, value } = event.target;

        // const list = [...items];        
        // list[i][name] = value;
        // setItems(list);

        // shiv

        let v_value = value;

        if (name === 'dis_seq' || name === 'exam_dis_seq' || name === 'max_marks' || name === 'min_marks')
        {
            v_value = event.target.value.replace(/\D/g, "");            
        } 

        const list = [...items];
        list[i][name] = v_value;
        setItems(list);
    };

    const showRecordFunction = async () => {
        if(under_comp_branch_id.length > 0 && fin_year.length > 0)
        {

            // console.log("1 is ")

            const response = await axios.get(`url_subject_master_branch_wise_view_fun/${under_comp_branch_id}/${fin_year}/${st_class}`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            // console.log("2 is ")

            let updatedata = await response.data.v_out_data;                

            // console.log("updatedata is : ", updatedata)

            if (updatedata.length === 0){

                toast(whenNoRecordFoundMessage);
                clearErrors();

                updatedata = structuredClone(defaultdata);
                
                // console.log("When 0 defaultdata is : ", defaultdata)

                setTabID(updatedata.length);
                setItems(updatedata);             
                return;
            }else{                

                // v_tab_id 
                // v_subject_id 
                // v_show_in_list 
                // v_subject_id_name 
                // v_dis_seq  
				// v_show_in_exam 
                // v_exam_dis_seq 
                // v_notes

                // allow_grading
                // max_marks
                // min_marks

                list1 = updatedata?.map((item, i) => (
                    {"id":item.v_tab_id || '', "subject_id" : item.v_subject_id || '',                     
                    "subject_id_name" : item.v_subject_id_name || '',
                    "allow_grading_isChecked" : item.v_allow_grading || '',
                    "dis_seq" : item.v_dis_seq || '',                    
                    "notes" : item.v_notes|| '', 
                    "isChecked" : item.v_show_in_list || '',
                    "show_in_exam_isChecked" : item.v_show_in_exam || '', 
                    "exam_dis_seq" : item.v_exam_dis_seq || '',
                    "max_marks" : item.v_max_marks || '',
                    "min_marks" : item.v_min_marks || '',
                    "t1_id" : i}
                ));
    
                setTabID(list1.length);        
                setItems(list1);
            }            
            
        }else{
            setItems(defaultdata);
        }       
    }

    const handleShowRecordOption = (event) => {
        event.preventDefault();
        showRecordFunction();  
    }

    const copy_handleUnderBranchOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        copy_setunder_comp_branch_id(getId);       

        const getParamTableRecordFunctionBranch = async (var_link) => {            
            const response = await axios.get(`${var_link}/${getId}/ST_CLASS`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            const updatedata = await response.data.v_out_data
            copy_setst_classItems(updatedata);  
            
        }
        getParamTableRecordFunctionBranch('url_param_view_branch_wise_drop_down');
    }

    const handleCopyToRecordOption = (event) => {
        event.preventDefault();
        
        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.error("Entry required for Under Branch !");
            clearErrors();
            return;
        }

        if(fin_year === undefined || fin_year.length === undefined || fin_year.length === 0){
            toast.error("Entry required for Financial Year !");
            clearErrors();
            return;
        }

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.error("Entry required for Student Class !");
            clearErrors();
            return;
        }

        if(copy_under_comp_branch_id === undefined || copy_under_comp_branch_id.length === undefined || copy_under_comp_branch_id.length === 0){
            toast.error("Entry required for Copy Under Branch !");
            clearErrors();
            return;
        }

        if(copy_fin_year === undefined || copy_fin_year.length === undefined || copy_fin_year.length === 0){
            toast.error("Entry required for Copy Financial Year !");
            clearErrors();
            return;
        }

        if(copy_st_class === undefined || copy_st_class.length === undefined || copy_st_class.length === 0){
            toast.error("Entry required for Copy Student Class !");
            clearErrors();
            return;
        }

        // if (under_comp_branch_id === copy_under_comp_branch_id){
        //     toast.error("From Branch and To Branch can not be same !");
        //     clearErrors();
        //     return;
        // }

        // if (fin_year === copy_fin_year){
        //     toast.error("From Financial Year and To Financial Year can not be same !");
        //     clearErrors();
        //     return;
        // }
        
        const data = {

            copy_from_under_comp_branch_id : under_comp_branch_id, 
            copy_to_under_comp_branch_id : copy_under_comp_branch_id,

            copy_from_fin_year : fin_year, 
            copy_to_fin_year : copy_fin_year,

            copy_from_st_class : st_class, 
            copy_to_st_class : copy_st_class,
            
        };

        dispatch(emptyCart());
        dispatch(addTempList(data));      

        // used when updation is not allow. we will disable the save button after first time click.
        // setdisableSave(true);

        setapiName('copy_to_url_subject_master_branch_wise')
        
        setModelModelFormYesNo(true);
    }

    const handleDeleteToRecordOption = (event) => {
        event.preventDefault();
        
        if(copy_under_comp_branch_id === undefined || copy_under_comp_branch_id.length === undefined || copy_under_comp_branch_id.length === 0){
            toast.error("Entry required for Delete Under Branch !");
            clearErrors();
            return;
        }
        
        if(copy_fin_year === undefined || copy_fin_year.length === undefined || copy_fin_year.length === 0){
            toast.error("Entry required for Copy Financial Year !");
            clearErrors();
            return;
        }

        if(copy_st_class === undefined || copy_st_class.length === undefined || copy_st_class.length === 0){
            toast.error("Entry required for Copy Student Class !");
            clearErrors();
            return;
        }
        
        const data = {            
            delete_to_under_comp_branch_id : copy_under_comp_branch_id,   
            copy_to_fin_year : copy_fin_year,
            copy_to_st_class : copy_st_class,
        };

        dispatch(emptyCart());
        dispatch(addTempList(data));      

        // used when updation is not allow. we will disable the save button after first time click.
        // setdisableSave(true);
        
        setapiName('url_del_to_subject_master_branch_wise_proc')
        
        setModelModelFormYesNo(true);
    }
    
    const handleUnderBranchOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        setunder_comp_branch_id(getId);        

        const getParamTableRecordFunctionBranch = async (var_link) => {            
            const response = await axios.get(`${var_link}/${getId}/ST_CLASS`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            const updatedata = await response.data.v_out_data
            setst_classItems(updatedata);  
            
        }
        getParamTableRecordFunctionBranch('url_param_view_branch_wise_drop_down');
        
        setItems([]);
    }

    const handleFinYearOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        setfin_year(getId);
        
        setItems([]);
    }

    const handlest_classListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setst_class(getId);

        setItems([]);
    }

    const handlecopy_st_classListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        copy_setst_class(getId);        
    }
    
    const copy_handleFinYearOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        copy_setfin_year(getId);       
        
        setItems([]);
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();
        setPkID(0);
        navigate('/brms')
    }

    const goCheckDataFunction = (event) => {
        event.preventDefault();
        
        console.log("Items are : ", items)
    }

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;
        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, isChecked: checked } });
            setItems(checkedvalue);
        }
        else {
            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, isChecked: checked } : user);
            setItems(checkedvalue);
        }
    }

    const handleChangeCheckBox_ShowInExam = (event) => {
        const { name, checked } = event.target;
        
        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, show_in_exam_isChecked: checked } });
            setItems(checkedvalue);
        }
        else {
            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, show_in_exam_isChecked: checked } : user);
            setItems(checkedvalue);
        }
    }
    
    const handleChangeCheckBox_allow_grading = (event) => {
        const { name, checked } = event.target;

        console.log("In check box 5 name is : ", name, " >> ", checked)
        
        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, allow_grading_isChecked: checked } });
            setItems(checkedvalue);
        }
        else {
            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, allow_grading_isChecked: checked } : user);
            setItems(checkedvalue);
        }
    }

    

    return (

<>

<div className="pt-PaddingTopAfterMenu pl-1 flex justify-start text-MainHeadingFontSize text-MainHeadingFontColor bg-MainHeadingBGColor">Subject Master Class & Branch & Financial Year Wise </div>
        <div className="mt-2">
            <label className="max-w-full text-my-font-color font-myFontWeight">Under Branch</label>        
            <select className="w-[20%] col-span-2 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="under_comp_branch_id" value={under_comp_branch_id} onChange={(e) => handleUnderBranchOption(e)}
                >
                <option></option>
                {
                    under_comp_branchItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>

            <label className="max-w-full text-my-font-color font-myFontWeight">Fin.Year</label>        
            <select className="w-[6%] col-span-2 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="fin_year" value={fin_year} onChange={(e) => handleFinYearOption(e)}
                >
                {/* <option></option> */}
                {
                    fin_yearItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>

            <label className="max-w-full text-my-font-color font-myFontWeight">Class</label>        
            <select className="w-[6%] col-span-2 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="st_class" value={st_class} onChange={(e) => handlest_classListOption(e)}
                >
                <option></option>
                {
                    st_classItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>

            <button className="w-[8%] ml-2 bg-ViewRowBtnBGColor hover:bg-ViewRowBtnHoverColor text-ViewRowBtnFontSize text-ViewRowBtnFontColor font-ViewRowBtnFontName rounded-none"
				onClick={handleShowRecordOption}
			>Show</button>

        </div>

        <div className="mt-2">

            {/* start coding for copy and delete */}

            <label className="max-w-full text-my-font-color font-myFontWeight">Copy/ Delete To Branch</label>
            <select className="w-[20%] col-span-2 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="copy_under_comp_branch_id" value={copy_under_comp_branch_id} onChange={(e) => copy_handleUnderBranchOption(e)}
                >
                <option></option>
                {
                    under_comp_branchItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>

            <label className="max-w-full text-my-font-color font-myFontWeight">To Fin.Year</label>        
            <select className="w-[6%] col-span-2 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="copy_fin_year" value={copy_fin_year} onChange={(e) => copy_handleFinYearOption(e)}
                >
                {/* <option></option> */}
                {
                    fin_yearItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>

            <label className="max-w-full text-my-font-color font-myFontWeight">To Class</label>        
            <select className="w-[6%] col-span-2 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="copy_st_class" value={copy_st_class} onChange={(e) => handlecopy_st_classListOption(e)}
                >
                <option></option>
                {
                    copy_st_classItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>

            <button className="w-[5%] ml-2 bg-ViewRowBtnBGColor hover:bg-ViewRowBtnHoverColor text-ViewRowBtnFontSize text-ViewRowBtnFontColor font-ViewRowBtnFontName rounded-none"
				onClick={handleCopyToRecordOption}
			>Copy To</button>

            <button className="w-[4%] ml-2 bg-ViewRowBtnBGColor hover:bg-ViewRowBtnHoverColor text-ViewRowBtnFontSize text-ViewRowBtnFontColor font-ViewRowBtnFontName rounded-none"
				onClick={handleDeleteToRecordOption}
			>Delete</button>

            {/* end coding for copy and delete */}

        </div>

        <div className="mt-2 border-TabBorderWidth border-TabHeadingBorderColor overflow-y-scroll">
            
            <div className="grid lg:grid-cols-12">

                <div className="text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-4">

                    <input className="w-[8%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor"
                             
                        type="checkbox" name="allselect" checked={!items.some((user) => user?.isChecked !== true)}
                        onChange={handleChangeCheckBox}
                    />

                    <label className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight  border-TabHeadingBorderColor bg-TabHeadBGColor
                            col-span-4 w-[92%]">
                        Subject Name
                    </label>
                </div>

                {/* allow_grading
                max_marks
                min_marks */}
                
                <div className="pr-PaddingRight text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Allow Grading

                    <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor"
                             
                        type="checkbox" name="allselect" checked={!items.some((user) => user?.allow_grading_isChecked !== true)}
                        onChange={handleChangeCheckBox_allow_grading}
                    />

                </div>
                

                <div className="pr-PaddingRight text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Subject Display Seq.
                </div>

                <div className="pr-PaddingRight text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Show-in-Exam

                    <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor"
                             
                        type="checkbox" name="allselect" checked={!items.some((user) => user?.show_in_exam_isChecked !== true)}
                        onChange={handleChangeCheckBox_ShowInExam}
                    />

                </div>

                <div className="pr-PaddingRight text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Max.Marks
                </div>

                <div className="pr-PaddingRight text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Min.Marks
                </div>

                <div className="pr-PaddingRight text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Exam Display Seq.
                </div>
        
                <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-2">
                    Notes
                </div>

            </div>
        </div>

        
        
        <div className="border-TabBorderWidth border-TabBorderColor h-InsertTableHeight overflow-y-scroll">
            {
                items?.map((item, i) => (
                    <div className="grid grid-cols-12" key={item.t1_id}>

                        <div className="col-span-4">
                            <input className="w-[8%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                                "
                                type="checkbox" name={item.t1_id} checked={item?.isChecked || false}
                                onChange={handleChangeCheckBox}
                            />

                            <input className="w-[92%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                                col-span-4"
                                type="text"
                                name="subject_id_name"
                                value={item.subject_id_name}
                                onChange={(e) => handlerChange(e, i)}
                                disabled                                
                            />

                        </div>

                        {/* allow_grading
                        max_marks
                        min_marks */}

                        <div className="col-span-1"> 

                            <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                                "
                                type="checkbox" name={item.t1_id} checked={item?.allow_grading_isChecked || false}
                                onChange={handleChangeCheckBox_allow_grading}
                            />

                        </div>

                        <input className="w-[100%] text-center pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="dis_seq"
                            value={item.dis_seq}
                            onChange={(e) => handlerChange(e, i)}                                
                        />

                        <div className="col-span-1"> 

                            <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                                "
                                type="checkbox" name={item.t1_id} checked={item?.show_in_exam_isChecked || false}
                                onChange={handleChangeCheckBox_ShowInExam}
                            />
                        </div>

                        {/* allow_grading
                        max_marks
                        min_marks */}

                        <input className="text-center pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="max_marks"
                            value={item.max_marks}
                            onChange={(e) => handlerChange(e, i)}                                
                        />

                        <input className="text-center pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="min_marks"
                            value={item.min_marks}
                            onChange={(e) => handlerChange(e, i)}                                
                        />

                        <input className="text-center pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="exam_dis_seq"
                            value={item.exam_dis_seq}
                            onChange={(e) => handlerChange(e, i)}                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-2"
                            type="text"
                            name="notes"
                            value={item.notes}
                            onChange={(e) => handlerChange(e, i)}                                
                        />                      

                    </div>
                ))
            }
        </div>
        
        <div className="mt-2 flex justify-center gap-4">
            <div className="w-[10%] text-center bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                >
                Record Found : {items.length}
            </div>  

            <button className="w-[10%] bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={createRecordFunction}>
                Save
            </button>        
        
            <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={goPrePageFunction}>
                Back
            </button>

            <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={goCheckDataFunction}>
                Check Data
            </button>
        
        
        </div>
        
        {/* <ModelFormDeleteUpdate api_type = {'UPDATE'} api_name={'url_fee_code_setting_branch_wise_updel_multiple'} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)} /> */}
       
        {/* <ModelFormYesNo table_name = 'fee_code_setting_branch_wise' api_name = {'url_fee_code_setting_branch_wise_updel_multiple'} isVisible = {ShowModelFormYesNo} onClose = {()=>setModelModelFormYesNo(false)} /> */}

        <ModelFormYesNo table_name = {'fee_code_setting_branch_wise'} api_name = {apiName} isVisible = {ShowModelFormYesNo} onClose = {()=>setModelModelFormYesNo(false)} />

</>
);
};

export default ExamGroup;
