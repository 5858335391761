import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {GetFormAuthDetails} from '../components/GlobalFunction';
import { useDispatch, useSelector } from 'react-redux';
import CRUDContext from '../components/CRUDContext';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { emptyTempDataFun, emptyGlobalDataFun } from '../components/redux/actions';
import moment from 'moment';

import { useReactToPrint } from "react-to-print";

const FeeRecPrint = () => {    
    const conponentPDF = useRef();

    let response = [], updatedata = [], v_toast_msg = '';
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // standard items define for table populate

    const [items, setItems] = useState([]); 

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData);
    const ReduxTempData = useSelector((state) => state.TempData);
    
    // define form name and navigation details

    const [v_backToNavigateName, setv_backToNavigateName] = useState('');    
    // const v_main_form_heading = 'Voucher Details !';
    const v_frm_no = 10;
    
    const[v_sumfeeamtpaid, setv_sumfeeamtpaid] = useState(0)
    const[v_sumfeeamtfine, setv_sumfeeamtfine] = useState(0)

    const[v_sumv_tot_due, setv_sumv_tot_due] = useState(0)
    const[v_sumv_adj_amt, setv_sumv_adj_amt] = useState(0)
    const[v_sumv_bal_amt, setv_sumv_bal_amt] = useState(0)
    
    const[vou_type_name, setvou_type_name] = useState('')
    const[vou_no, setvou_no] = useState('')

    const[vou_date, setvou_date] = useState('')
    const[notes, setnotes] = useState('')
    const[vou_amt, setvou_amt] = useState('')
    const[amt_in_word, setamt_in_word] = useState('')

    const[BlankLine, setBlankLine] = useState([]);

    // define standard errors and entry required messages

    const[v_report_head_1, setv_report_head_1] = useState('')
    const[v_report_head_2, setv_report_head_2] = useState('')
    const[v_report_head_3, setv_report_head_3] = useState('')
    const[v_f_session, setv_f_session] = useState('')
    const[v_rec_date, setv_rec_date] = useState('')
    const[v_rec_no, setv_rec_no] = useState('')
    const[v_st_name, setv_st_name] = useState('')
    const[v_fat_name, setv_fat_name] = useState('')
    const[v_st_class, setv_st_class] = useState('')
    const[v_st_section, setv_st_section] = useState('')
    const[v_adm_no, setv_adm_no] = useState('')
    const[v_fat_mobile, setv_fat_mobile] = useState('')
    const[v_pay_mode, setv_pay_mode] = useState('')
    const[v_totalamtpaid, setv_totalamtpaid] = useState('')
    const[v_totaldueamt, setv_totaldueamt] = useState('')    
    const[v_discountfineamt, setv_discountfineamt] = useState('')
    const[v_totalbalanceamt, setv_totalbalanceamt] = useState('')
    const[v_user_name, setv_user_name] = useState('')
    const[v_notes, setv_notes] = useState('')
    const[v_amttoword, setv_amttoword] = useState('')   

    const[v_st_stream, setv_st_stream] = useState('')

    const[v_report_head_4, setv_report_head_4] = useState('')
    const[v_sec_label, setv_sec_label] = useState('')
    const[v_group_label, setv_group_label] = useState('')    

    const {        
        v_allow_open,        
        v_session_expire_msg,
        v_access_denied_msg,        
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,
    } = useContext(CRUDContext);

    useEffect(() => {

        async function loadData() {
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);            
            // setUserAuthData(result);

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());
                
                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.print === 2) {
                toast.info(v_allow_open, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                navigate(`${ReduxTempData[0].back_url}`);
                return;
            } else {
                if (ReduxTempData.length > 0){
                    ShowRecordFun(ReduxTempData[0].temptableid);
                    setv_backToNavigateName(ReduxTempData[0].back_url);

                    // setvou_type_name(ReduxTempData[0].vou_type_name)
                    // setvou_no(ReduxTempData[0].vou_no)            
                    // setvou_date(ReduxTempData[0].vou_date);   
                    // setnotes(ReduxTempData[0].notes)
                    // setvou_amt(ReduxTempData[0].vou_amt)
                    // setamt_in_word(updatedata[0].amt_in_word)

                    dispatch(emptyTempDataFun());
                }
            }
        }
        loadData();
    }, [ReduxGlobalData[0]]);

    const ShowRecordFun = async (p_TableRecordID) => {       

        const v_token = Cookies.get('token');

        // console.log("p_TableRecordID >> ", p_TableRecordID);

        response = await axios.get(`url_fetch_data_branch_finyear_id_wise/10_url_fee_coll_rec_print_format2/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${p_TableRecordID}`,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;

        // console.log("1- In fee collectio receipt")

        setItems(await updatedata);

        // console.log("updatedata.length : ", updatedata.length)

        // we are considering total 26 rows in A5 size print

        let v_obj = [];
        let v_list = [];
        
        for (var i = 0; i < 21 - updatedata.length; i++) {
            v_obj = [];
            for (var j = 0; j < 4; j++) {
                let key = "col_" + (j + 1);
                let value = '';
                v_obj[key] = value;
            }                
            v_list.push(v_obj);
        }
        setBlankLine(v_list);

        // console.log("2- In fee collectio receipt")

        // calculate column wise sum

        let v_due_amt_sum = 0, v_fine_amt_fine = 0, v_tot_due = 0, v_adj_amt = 0, v_bal_amt = 0;
        for (let i = 0; i < updatedata.length; i++){
            v_due_amt_sum = parseFloat(v_due_amt_sum) + parseFloat(updatedata[i].v_due_amt)
            v_fine_amt_fine = parseFloat(v_fine_amt_fine) + parseFloat(updatedata[i].v_fine_amt)

            v_tot_due = parseFloat(v_tot_due) + parseFloat(updatedata[i].v_tot_due)
            v_adj_amt = parseFloat(v_adj_amt) + parseFloat(updatedata[i].v_adj_amt)
            v_bal_amt = parseFloat(v_bal_amt) + parseFloat(updatedata[i].v_bal_amt)
        }

        setv_sumfeeamtpaid(v_due_amt_sum);
        setv_sumfeeamtfine(v_fine_amt_fine);

        setv_sumv_tot_due(v_tot_due);
        setv_sumv_adj_amt(v_adj_amt);
        setv_sumv_bal_amt(v_bal_amt);       

        response = await axios.get(`url_fetch_data_branch_finyear_id_wise/10_url_fee_coll_rec_print_heading_fun/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${p_TableRecordID}`,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;

        // console.log(updatedata)

        // console.log("header print receipt updatedata is : ", updatedata)

        setv_report_head_1(updatedata[0].v_report_head_1);
        setv_report_head_2(updatedata[0].v_report_head_2);
        setv_report_head_3(updatedata[0].v_report_head_3);
        setv_f_session(updatedata[0].v_f_session);
        setv_rec_date(moment(updatedata[0].v_rec_date).format('DD-MM-YYYY'));   
        setv_rec_no(updatedata[0].v_rec_no);
        setv_st_name(updatedata[0].v_st_name);
        setv_fat_name(updatedata[0].v_fat_name);
        setv_st_class(updatedata[0].v_st_class);
        
        setv_adm_no(updatedata[0].v_adm_no);
        setv_fat_mobile(updatedata[0].v_fat_mobile);
        setv_pay_mode(updatedata[0].v_pay_mode);
        setv_totalamtpaid(updatedata[0].v_totalamtpaid);
        setv_discountfineamt(updatedata[0].v_discountfineamt);
        setv_totalbalanceamt(updatedata[0].v_totalbalanceamt);
        setv_user_name(updatedata[0].v_user_name);
        setv_notes(updatedata[0].v_notes);
        setv_amttoword(updatedata[0].v_amttoword);
        setv_totaldueamt(updatedata[0].v_totaldueamt)

        setv_report_head_4(updatedata[0].v_report_head_4)
        setv_sec_label(updatedata[0].v_sec_label)
        setv_group_label(updatedata[0].v_group_label)
        
        if(updatedata[0].v_sec_label.length === 0){
            setv_st_section("");
        }else{
            setv_st_section(updatedata[0].v_st_section);
        }

        if(updatedata[0].v_group_label.length === 0){
            setv_st_stream("");
        }else{
            setv_st_stream(updatedata[0].v_st_stream)
        }
    }
 
    const handleButtonValue = (e) => {
        e.preventDefault();

        const { name, value } = e.target;
 
        if (value === 'BTN_BACK') {       
            navigate(`/${v_backToNavigateName}`);
            return;
        }
    }

    const handleButtonExportValue = (e, p_value) => {
        e.preventDefault();

        if (p_value === 'BTN_EXPORT_EXCEL') {

            console.log("EXCEL")

            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        } else if (p_value === 'BTN_EXPORT_PDF') {

            console.log("PDF")

            return;
        }

    }

    const PrintPDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "",
        // onAfterPrint: () => alert("Data saved in PDF")
    });

    const goCheckDataFunction = (event) => {
        event.preventDefault();

    }

return (

<>

{/* <div className="mt-FromTopMenuMargin justify-center items-center fixed inset-0 bg-my-when-focus-bg-color bg-opacity-100 backdrop-blur-3xl"> */}

    <div className="w-[40%] flex justify-center">
        <div className="btn_div_model_saveupdel_css w-[20%]">
            <button className="btn_model_saveupdel_yes_css" type="submit" value = 'YES' onClick={PrintPDF}>Print</button>
        </div>  

        <div className="btn_div_model_saveupdel_css w-[20%]">
            <button className="btn_model_saveupdel_yes_css" type="submit" value = 'BTN_BACK' onClick={handleButtonValue}>Back</button>
        </div>
    </div>

    <div className="mt-1" ref={conponentPDF}>                
                
        <table className="table-fixed border ml-[20px] w-[506px] mt-5 font-my-font-name text-rec-fontSize">                
            <thead>                                      
                <tr><th className="font-my-font-name text-FeeRecFontSize-1 font-FeeRecFontWeight-1 pl-1 text-center">{v_report_head_1}</th></tr>
                <tr><th className="font-my-font-name text-FeeRecFontSize-2 font-FeeRecFontWeight-2 pl-1 text-center">{v_report_head_2}</th></tr>
                <tr><th className="font-my-font-name text-FeeRecFontSize-3 font-FeeRecFontWeight-3 pl-1 text-center">{v_report_head_3}</th></tr>
                <tr><th className="underline font-my-font-name text-FeeRecFontSize-3 font-FeeRecFontWeight-2 pl-1 text-center">{v_report_head_4}</th></tr>
            </thead>
        </table>

        <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize">                
            <thead>                                      
                <tr className="border-x-[1px] border-black">
                    <th className="pl-2 text-left text-[12px]">Adm.No.:</th>
                    <td className="pl-2 text-left text-[12px]">{v_adm_no}</td>

                    <th className="pl-2 text-left text-[12px]">Session:</th>
                    <td className="pl-2 text-left text-[12px]">{v_f_session}</td>      
                    
                    <th className="pl-2 text-left text-[12px]">Receipt.No.:</th>
                    <td className="pl-2 text-left text-[12px]">{v_rec_no}</td>
                </tr>
            </thead>
        </table>

        <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize">                
            <thead>                                      
                <tr className="border-x-[1px] border-black">
                    <th className="pl-2 w-[1px]  text-left  text-[12px]">Name:</th>
                    <td className="pl-1 w-[20px] text-left  text-[12px]">{v_st_name}</td>

                    <th className="pl-1 w-[11px] text-left  text-[12px]">Father's Name:</th>
                    <td className="pl-1 w-[20px] text-left  text-[12px]">{v_fat_name}</td>
                </tr>
            </thead>
        </table>

        <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize">                
            <thead>                                      
                {/* <tr className="border-x-[1px] border-b-[1px]"> */}
                <tr className="border-x-[1px] border-black">
                    <th className="pl-2 w-[15px]  text-left text-[12px]">Class:</th>
                    <td className="pl-1 w-[20px] text-left text-[12px]">{v_st_class}</td>

                    <th className="pl-1 w-[10px] text-left text-[12px]">{v_sec_label}</th>
                    <td className="pl-1 w-[20px] text-left text-[12px]">{v_st_section}</td>

                    <th className="pl-1 w-[11px] text-left text-[12px]">{v_group_label}</th>
                    <td className="pl-1 w-[20px] text-left text-[12px]">{v_st_stream}</td>
                </tr>
            </thead>
        </table>

        <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize">       

            <thead>                            

                <tr>
                    <th className="border w-52 pl-1 print-border-color text-left text-[12px]">Description of Fee</th>
                    <th className="border w-15 pr-1 print-border-color text-right text-[12px]">Due.Amt.</th>

                    <th className="border w-15 pr-1 print-border-color text-right text-[12px]">Rec.Amt.</th>
                    <th className="border w-15 pr-1 print-border-color text-right text-[12px]">Bal.Amt</th>

                </tr>
            </thead>

            {
                items.map((item, index) => (
                    <tr key={index}>

                        <td className="border w-52 pl-1 print-border-color text-left text-[12px]">{item.v_particulars}</td>
                        <td className="border w-15 pr-1 print-border-color text-right text-[12px]">{item.v_due_amt}</td> 
                        
                        <td className="border w-15 pr-1 print-border-color text-right text-[12px]">{item.v_adj_amt}</td>
                        <td className="border w-15 pr-1 print-border-color text-right text-[12px]">{item.v_bal_amt}</td>

                    </tr>                            
            ))}

            {
                BlankLine.map((item, index) => (
                    <tr key={index}>                                
                        <td className="border w-52 p-2.5 print-border-color text-left"></td>
                        <td className="border w-15 p-2.5 print-border-color text-right"></td> 
                        
                        <td className="border w-15 p-2.5 print-border-color text-right"></td>
                        <td className="border w-15 p-2.5 print-border-color text-right"></td>

                    </tr>                            
            ))}
            
            <tr>
                <th className="border pr-1 text-left text-[12px]">Total</th> 
                <td className="border pr-1 text-right text-[12px]">{v_sumfeeamtpaid}</td>

                <td className="border pr-1 text-right text-[12px]">{v_sumv_adj_amt}</td>  
                <td className="border pr-1 text-right text-[12px]">{v_sumv_bal_amt}</td>                    
            </tr>


        </table>

        <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize">
            <thead> 
                <tr className="border-x-[1px] border-black">
                    <th className="w-[100px] pl-1 pr-1 text-left text-[12px] border-black">Amount in Words:</th>
                    <td className="w-[250px] pl-1 pr-1 text-left text-[12px] border-black">{v_amttoword}</td> 

                    <th className="w-[100px]  pl-1 pr-1 text-left text-[12px] border-black">{v_user_name}</th>
                </tr>
            </thead>
        </table>

        <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize">
            <thead> 
                <tr className="border-x-[1px] border-black">
                    <td className="w-52 p-2.5 print-border-color text-left"></td>
                </tr>
            </thead>
        </table>

        <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize">
            <thead> 
                <tr className="border-x-[1px] border-b-[1px] border-black">
                    <th className="w-[70px] pl-1 pr-1 text-left text-[12px]">Date:</th>
                    <td className="w-[200px] pl-1 pr-1 text-left text-[12px]">{v_rec_date}</td>                        
                    <th className="w-[100px] pl-1 pr-1 text-left text-[12px]">Cashier/Accountant</th>      
                </tr>
            </thead>
        </table>            

    </div>  
   
    
    
</>
);};

export default FeeRecPrint;
