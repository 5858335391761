import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import CRUDContext from '../components/CRUDContext';
import DropDownTableComponent from "../components/DropDownTableComponent";

import { setTempDataFun, emptyGlobalDataFun } from '../components/redux/actions';

import Cookies from 'js-cookie';

const ExamFinalMarks = (props) => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';    
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);    
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);      
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);

    // console.log("Props >> ", props.p_data.allow_new)

    const [stream, setstream] = useState(props.p_data.stream);
    const [st_class, setst_class] = useState(props.p_data.st_class);
    const [st_sec, setst_sec] = useState(props.p_data.st_sec);    
    const [exam_group_id, setexam_group_id ] = useState(props.p_data.exam_group_id);
    const [exam_term_name, setexam_term_name] = useState(props.p_data.exam_term_name);
    const [exam_term_categ, setexam_term_categ] = useState(props.p_data.exam_term_categ);

    const [v_allow_new, setv_allow_new] = useState(props.p_data.allow_new);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disableSave, setdisableSave] = useState(0);    
    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');
    const [AllowUpdateTag, setAllowUpdateTag] = useState(false);

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData);
    
    // define drop downs inputs

    const [disableShow, setdisableShow] = useState(false);

    const [headingcolumn, setheadingcolumn] = useState([]);    
        
    // When we will use Multiple selection in Drop Down
    
    // define drop downs items
    
    let v_input_result;

    // define variable for tables entry (master detail form)    
   
    const [items, setItems] = useState([]);
    const [examfinalremarksItems, setexamfinalremarksItems] = useState([]);
       
    const v_urlShowReportData = '25_url_studentExam_final_Remarks_view_fun';
    const v_urlSaveUpdateDelete = '25_url_studentExam_Final_Remarks_new';
    const v_backToNavigateName = '/Dashboard';
    // const v_findToNavigateName = '/Fcview';
    // const v_main_form_heading = 'Student Subject Assignment !';
    const v_frm_no = 25;

    const {
        deletedMessage,
        createdMessage,
        updatedMessage,        
        whenStudentNotFoundMessage,
        WhenRecordNotFoundToUpdate,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {

        setstream(props.p_data.stream);
        setst_class(props.p_data.st_class);
        setst_sec(props.p_data.st_sec);
        setexam_group_id(props.p_data.exam_group_id);
        setexam_term_name(props.p_data.exam_term_name);
        setexam_term_categ(props.p_data.exam_term_categ);

        setv_open_frm(true);         

        setdisableSave(false);                               

        setBlankFields();

        getSavedDetailFunction();
        
    }, []);

    useEffect(() => {
        setBlankFields();
    }, [ReduxGlobalData[0]]);
       
    function setBlankFields() {
        setsaveUpdateTag('SAVE');
        setAllowUpdateTag(false);

        setdisableSave(false);
        
        setItems([]);        
        setheadingcolumn([]);
    }

    const getSavedDetailFunction = async () => {        
        setdisableShow(true);
        setBlankFields();

        const v_token = Cookies.get('token');

        let v_active_status = 1, v_null_parameter = 'NA';

        response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_get_data/38/0/0/EXAM_FINAL_RM`,
            { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;
        setexamfinalremarksItems(updatedata);               

        response = await axios.get(`url_fetch_data/${v_urlShowReportData}/${v_frm_no}/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
        /${st_class}
        /${st_sec}
        /${ReduxGlobalData[0].brd}
        /${ReduxGlobalData[0].medi}
        /${stream}
        /${exam_group_id}
        /${exam_term_name}
        /${exam_term_categ}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;        

        if (updatedata.length === 0){

            toast.info(whenStudentNotFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,
            });
            setdisableShow(false);
            return;
        }

        setItems(await updatedata);

        // console.log(updatedata)
                    
        // const column1 = Object.keys(await updatedata[0]);

        // setheadingcolumn(column1);

        setdisableShow(false);
    }


    // ///////////////////////////////////////////////////////////////////

    const handleInputValueFun = (name, value) => {        
    }
    
    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target; 
        
        
        let insupdel_type = '';       
        
        if (value === 'DELETE'){
            insupdel_type = 'DELETE';
        }else{
            if (TableRecordID === 0){
                insupdel_type = 'INSERT';            
            }else{

                if (TableRecordID === 0){
                    toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return;
                }

                if (userAuthData.allow_update === 1){
                    setAllowUpdateTag(false);
                    insupdel_type = 'UPDATE';
                }else{
                    setAllowUpdateTag(true);
                    return;
                }                  
            }
        }

        if (value != 'DELETE'){

            // need to validate the date here           

        }

        if (items.length === 0){
            toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }
        
        const data = {
            id: 0,
            insupdel_type:'INSERT',            
            under_comp_branch_id : ReduxGlobalData[0].br,
            fin_year : ReduxGlobalData[0].fy,
            board : ReduxGlobalData[0].brd,
            medium : ReduxGlobalData[0].medi,
            stream,
            exam_group_id,
            term_id:exam_term_name,            
            exam_type_id:exam_term_categ,
            st_class,
            st_sec,
            items: JSON.stringify(items), 
        };        
        
        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }    
    }   

   
    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);        

        if (p_data.ModelYesNoAnswer === 'YES'){
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async(p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final:p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final:p_last_updel_notes_final,
        }        

        const v_token = Cookies.get('token');

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });
        
        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }
    
    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }     

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                    
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);
            
            // console.log("updatedata.TabID.v_data >> ", updatedata.v_data);
            
            if (updatedata.transType === 'INSERT'){                         
                setsaveUpdateTag('UPDATE');
                setdisableSave(true);   
                v_toast_msg = createdMessage;
                getSavedDetailFunction();                
            }else if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;
                setdisableSave(true);   
            }else if (updatedata.transType === 'DELETE'){
                setdisableSave(true);                   
                v_toast_msg = deletedMessage;
            }

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }

    const setNewRecordIDFunction = async(TabID, p_v_data) => {
    }

    
    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        // console.log("handleInputFocus value >> ", value)

        // if (name === 'adm_no'){
        //     setBlankFields();
        //     setSearchStudentDetailsPopUp(true);            
        //     return;
        // }else{
        //     setSearchStudentDetailsPopUp(false);
        // }

    };

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        handleInputValueFun(name, value);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        if (value === 'ADD_NEW_ROW'){
            
            if(AllowUpdateTag){return;}
            return;
        }else if (value === 'BTN_NEW'){            
            setBlankFields();
            return;
        }else if (value === 'BTN_PRINT'){
            
            if (TableRecordID === 0) {
                toast.error(whenStudentNotFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            // console.log('BTN_PRINT TableRecordID >> ', TableRecordID)
            
            const temp_data = {
                temptableid: TableRecordID,                
                back_url: 'Fc'
            }
            
            dispatch(setTempDataFun(temp_data));

            // here we are printing the fee receipt

            navigate("/FcprFkg");

        }else if (value === 'BTN_EXPORT_EXCEL'){
                        
            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        }else if (value === 'BTN_EXPORT_PDF'){
            
            return;
        }else if (value === 'BTN_FIND'){            
            getSavedDetailFunction();
            return;
        }else if (value === 'BTN_COPY_TO_BRANCH'){
            return;
        }else if (value === 'BTN_ASSIGN_BRANCH'){
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const handlerChange = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;        

        let v_value = value;

        const list = [...items];           

        if (name === 'term_remarks'){                
            v_value = capitalizeWordFun(v_value);
        }

        list[i][name] = v_value; 
        setItems(list);
    };

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };
    
    const goCheckDataFunction = (event) => {
        event.preventDefault();
        
        // console.log("headingcolumn >> ", headingcolumn);

        console.log("Items >> ", items);

        // setp_till_month(6);       
        

        // setItems(emptyListItems);

    }
  
    const ThData =()=>{
        
        // let p_till_month = 8;

        // console.log("in heading function >> ", p_till_month);
    
        return headingcolumn.map((data, index)=>{

            // tab_id , student_id , adm_no , student_name , fat_name , term_remarks , term_attendance , term_school_days 

            if (index === 0){
                return <th className = "table_th_td_css text-center w-[4%]"></th>             
            }else if (index === 1){
                return <th className = "table_th_td_css text-center w-[8%]" key={data}>TabID</th>             
            }else if (index === 2){
                return <th className = "table_th_td_css text-left w-[10%]" key={data}>student_id</th>             
            }else if (index === 3){               
                return <th className = "table_th_td_css text-left w-[10%]" key={data}>adm_no</th>   
            }else if (index === 4){               
                return <th className = "table_th_td_css text-left w-[10%]" key={data}>student_name</th>   
            }else if (index === 5){               
                return <th className = "table_th_td_css text-left w-[10%]" key={data}>fat_name</th>   
            }else if (index === 6){               
                return <th className = "table_th_td_css text-left w-[20%]" key={data}>term_remarks</th>   
            }else if (index === 7){               
                return <th className = "table_th_td_css text-left w-[10%]" key={data}>term_attendance</th>   
            }else if (index === 8){               
                return <th className = "table_th_td_css text-left w-[10%]" key={data}>term_school_days</th>   
            }

        })
    }
    
    const tdData =() =>{

        return items.map((data, i)=>{
            return (
            <tr className="table_thead_tr_css table_tbody_hover_css">
                {
                    headingcolumn.map((v_data, index)=>{
                        
                        // row_no , tab_id , student_id , adm_no , student_name , fat_name , term_remarks , term_attendance , term_school_days 
                        
                        if (index === 0){
                            return <th className = "table_th_td_css text-center w-[4%]">{i + 1}</th>
                        }else if (index === 1 || index === 2 || index === 1){
                            return <th className = "table_th_td_css text-center w-[8%]">{data[v_data]}</th>

                        }else if (index === 2 || index === 3){
                            return <th className = "table_th_td_css text-left w-[15%]">{data[v_data]}</th>

                        }else if (index >= 4) {
                            return <th className = "table_th_td_css text-center w-[6.5%]">                               

                                <input className="table_input_inner_page_css text-right"
                                    type="text"                                   
                                    // name="dr_amt"
                                    // id="dr_amt"
                                    value={data[v_data]}                                               
                                    onChange={(e) => handlerChange(e, index)}
                                    disabled = {AllowUpdateTag}                                          
                                ></input>
                                
                                {/* <input className="table_th_td_css w-[100%]"
                                    type="checkbox" name={data.st_id} checked={data?.[v_data] || false}                                
                                    onChange={(e) => handleChangeCheckBox_allow_grading(e, i, v_data)}
                                /> */}

                            </th>
                        }
                    })
                }
            </tr>
            )
        })      
            
    }

    const handleChangeCheckBox_allow_grading = (event, i, v_data) => {
        const { name, checked } = event.target;

        const list = [...items];

        // console.log("In check box 5 name is : ", name, " >> ", checked, " i is >> ", i, "column is : ", v_data)
        
        if (name === "allselect") {

            // const checkedvalue = items.map((user) => { return { ...user, allow_grading_isChecked: checked } });

            const checkedvalue = items.map((user) => { return { ...user, [v_data]: checked } });

            setItems(checkedvalue);

        }
        else {
               
            list[i][v_data] = checked;
            setItems(list);
            
        }

    }

    // For details entry (entry in table)
    // For details entry (entry in table)
    
    const handlerChangeTableRowFocus = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;
    };

return (

<>    
{
    ShowConfirmModelSave ? 
    <div className="centerWindow_css">
        <ConfirmModelSave transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
    </div>
    : null
}

{
    ShowConfirmModelUpdateDelete ? 
    <div className="centerWindow_css w-[32%]">
        <ConfirmModelUpdateDelete transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
    </div>
    : null
}

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">

<table className="table_main_css">
<thead className="table_thead_css">            
    <tr className="table_thead_tr_css">
        <th className="table_th_td_css text-left w-[3%]"></th>
        <th className="table_th_td_css text-center w-[5%]">Adm.</th>        
        <th className="table_th_td_css text-left w-[18%]">Name</th>
        <th className="table_th_td_css text-left w-[19%]">Father Name</th>
        <th className="table_th_td_css text-left w-[21%]">Final Result</th>
        <th className="table_th_td_css text-left w-[35%]">Principal Remarks</th>
    </tr>
</thead>

<tbody className="table_tbody_css h-[60vh]">
{
    items?.map((item, index) => (                   

    <tr className="table_thead_tr_css table_tbody_hover_css" key={index}>

        <td className="table_th_td_css text-left w-[3%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            {index + 1}
        </td>            

        <td className="table_input_th_td_css w-[5%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
            <input className="table_input_inner_page_css text-center"
                type="text"                
                name="adm_no"
                id="adm_no"
                value={item.adm_no}                                           
                disabled
            ></input>
        </td>

        <td className="table_input_th_td_css text-left w-[18%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
            <input className="table_input_inner_page_css"
                type="text"                
                name="student_name"
                id="student_name"
                value={item.student_name}                                           
                disabled
            ></input>
        </td>

        <td className="table_input_th_td_css text-left w-[19%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
            <input className="table_input_inner_page_css"
                type="text"                
                name="fat_name"
                id="fat_name"
                value={item.fat_name}                                           
                disabled
            ></input>
        </td>

        <td className="table_input_th_td_css text-left w-[21%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <DropDownTableComponent 
                ref={(ref) => addInputRef(ref, 14)}
                paramItems={examfinalremarksItems} columnName={'final_remarks_id'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.final_remarks_id}                     
            />         
        </td>
        
        <td className="table_input_th_td_css text-left w-[35%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
            <input className="table_input_inner_page_css"
                type="text"
                ref={(ref) => addInputRef(ref, 15)}
                name="term_remarks"
                id="term_remarks"
                value={item.term_remarks}                                                                   
                onChange={(e) => handlerChange(e, index)}                                
                disabled = {AllowUpdateTag}
            ></input>              
            
        </td>        

    </tr>
    ))
}
</tbody>
</table>  

<nav className="btn_nav_inner_css">

<div className="btn_div_main_css">       

    <div className="btn_div_inner_css">

        <div>
            {v_allow_new === 1 && (
                <button className="btn_save_css"
                    // disabled={disableSave}
                    onClick={createRecordFunction}>
                    Save
                </button>
            )}
        </div>                                          

        <div>
            <button className="btn_back_css"
                value = 'BTN_BACK'
                onClick={handleButtonValue}>
                Back
            </button>
        </div>

        {/* <div>
            <button className="btn_back_css"
                onClick={goCheckDataFunction}>
                Check Data
            </button>
        </div> */}
            
    </div>
    
</div>
</nav>

</form>
:null
}
</>
);};

export default ExamFinalMarks;
