// Table name : student_master and all related tables

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import CRUDContext from '../components/CRUDContext';
import ModelFormYesNo from '../components/ModelFormYesNo';

// import { isAuthenticatedUser } from '../components/isAuthenticated';
import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
// import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';

// import {useRouter} from 'next/router';

import { useNavigate } from "react-router-dom";

const Sfa = ({ access_token }) => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state);
    const [ShowModelFormDeleteUpdate, setModelFormDeleteUpdate] = useState(false);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);
    const [ipAddess, setIPAddress] = useState('');
    const [updateDelTag, setUpdateDelTag] = useState('');

    const [items, setItems] = useState([]);
    const [ExcelItems, setExcelItems] = useState([]);
    const [filterdata, setFilterdata] = useState([]);

    const [queryGroupCode, setQueryGroupCode] = useState('');
    const [queryGroupName, setQueryGroupName] = useState('');
    const [queryGroupPrintName, setQueryGroupPrintName] = useState('');
    const [queryGroupUnderGroup, setQueryGroupUnderGroup] = useState('');

    const [st_secItems, setst_secItems] = useState([]);
    const [st_sec, setst_sec] = useState(0)

    const [fyItems, setfyItems] = useState([]);
    const [fy, setfy] = useState(0);

    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0);
    
    // const [pay_fee_monthItems, setfee_monthItems] = useState([]);
    // const [fee_codeItems, setfee_codeItems] = useState([]);

    const [from_fee_month, setfrom_fee_month] = useState(0)
    const [to_fee_month, setto_fee_month] = useState(0)
    const [fee_code_id, setfee_code_id] = useState(0)

    const [board, setboard] = useState(0)
    const [student_type, setstudent_type] = useState(0)
    const [medium, setmedium] = useState(0)
    const [stream, setstream] = useState(0)
    const [fee_categ, setfee_categ] = useState(0)
    const [pay_term, setpay_term] = useState(0)
    const [st_class, setst_class] = useState(0)
    const [notes, setnotes] = useState();

    const [fee_month, setfee_month] = useState(0)
    const [fee_code, setfee_code] = useState(0)

    const [show_in_list, setShow_in_list] = useState(1)

    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);
    const [boardItems, setboardItems] = useState([]);
    const [student_typeItems, setstudent_typeItems] = useState([]);
    const [mediumItems, setmediumItems] = useState([]);
    const [streamItems, setstreamItems] = useState([]);
    const [fee_categItems, setfee_categItems] = useState([]);
    const [pay_termItems, setpay_termItems] = useState([]);
    const [st_classItems, setst_classItems] = useState([]);

    const [pay_fee_monthItems, setfee_monthItems] = useState([]);
    const [fee_codeItems, setfee_codeItems] = useState([]);

    const [fee_amt, setfee_amt] = useState('')    

    // const ExcelHeaders = [
    //     { label: "Code", key: "l_code" },
    //     { label: "Name", key: "l_name" },
    //     { label: "Notes", key: "notes" }
    // ];

    let list1 = [];
    let checkedinputvalueItems = '';

    // const router = useRouter()

    const navigate = useNavigate();

    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,
        updateRecord,
        deleteRecord,
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        viewRecord,
        setviewRecord,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenNullViewRecordMessage,
        whenMultipleViewRecordMessage,
    } = useContext(CRUDContext);

    // // ////////////////////////////

    // const getAuthroziedUser = async () => {  
    //     try{
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //               { headers: { Authorization: `Bearer ${token}` } });            
    
    //         if (response.status === 401 || response.status === 403){
    //             localStorage.clear();
    //             // router.push('/Login')
    //             navigate("/Login");
    //         };

    //     }catch(error){
    //         localStorage.clear();
    //         // router.push('/Login')
    //         navigate("/Login");
    //     }
    // }    

    // useEffect( () => {        
    //     getAuthroziedUser();    
    // }, []);

    // // ////////////////////////////

    useEffect(() => {
        if (error) {
            toast.error(error);
            clearErrors();
            return;
        }

        if (created) {
            setDeleted(false);
            toast.success(createdMessage)
            clearErrors();
            return;
        }

    }, [error, created]);
    
    useEffect(() => {

        // setnewbtnshowhide(1);
        // setsavebtnshowhide(1);
        // setdeletebtnshowhide(1);
        // setprintbtnshowhide(2);
        // setexportexcelbtnshowhide(2);
        // setexportpdfbtnshowhide(2);
        // setfindbtnshowhide(1);

        // setdisableSave(false);
        // setdisableDelete(true);        

        // to fill the drop-down

        let response = []
        let updatedata = []

        // to fill the drop-down
        
        const getTableRecordFunctionBranch = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_comp_branchItems(updatedata);
        }
        getTableRecordFunctionBranch('url_comp_branch_view_drop_down');

        const getParamTableRecordFunctionBranch = async (var_link) => {

            // localStorage.setItem('brd', board); // board
            // localStorage.setItem('st', student_type); // student_type
            // localStorage.setItem('stem', stream); // stream
            // localStorage.setItem('medi', medium); // medium
            // localStorage.setItem('fcat', fee_categ); // fee_categ
            // localStorage.setItem('ptm', pay_term); // pay_term            
           
            if (localStorage.getItem('brd') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/BOARD`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setboardItems(updatedata);
            }

            if (localStorage.getItem('st') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/ST_TYPE`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setstudent_typeItems(updatedata);
            }

            if (localStorage.getItem('medi') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/MEDIUM`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setmediumItems(updatedata);
            }

            if (localStorage.getItem('stem') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/ST_STREAM`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setstreamItems(updatedata);
            }

            if (localStorage.getItem('fcat') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/FEE_CATEG`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setfee_categItems(updatedata);
            }

            if (localStorage.getItem('ptm') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/FEE_PAY_TERM`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setpay_termItems(updatedata);
            }

            // response = await axios.get(`${var_link}/${localStorage.getItem('br')}/MONTH`,
            //     { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            // updatedata = await response.data.v_out_data
            // setfee_monthItems(updatedata);

            // response = await axios.get(`${var_link}/${localStorage.getItem('br')}/FEE_CODE`,
            //     { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            // updatedata = await response.data.v_out_data
            // setfee_codeItems(updatedata);

            // response = await axios.get(`${var_link}/${localStorage.getItem('br')}/APP_FOR`,
            //     { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            // updatedata = await response.data.v_out_data
            // setapp_forItems(updatedata);

            response = await axios.get(`${var_link}/${localStorage.getItem('br')}/ST_CLASS`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setst_classItems(updatedata);

            // response = await axios.get(`url_FeeMonthSeqFun/${localStorage.getItem('br')}`,
            // { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            // updatedata = await response.data.v_out_data
            // setfee_monthItems(updatedata);

            response = await axios.get(`${var_link}/${localStorage.getItem('br')}/ST_SEC`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setst_secItems(updatedata);

            response = await axios.get(`${var_link}/${localStorage.getItem('br')}/FY`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setfyItems(updatedata);

        }
        getParamTableRecordFunctionBranch('url_param_view_branch_wise_drop_down');

        // setBlankFields();

    }, []);  

    function generateExcelData(data) {
        const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
        setExcelItems(checkedinputvalue);
    }    

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;
        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, isChecked: checked } });
            setItems(checkedvalue);
        }
        else {
            const checkedvalue = items.map((user) => user.id.toString() === name ? { ...user, isChecked: checked } : user);
            setItems(checkedvalue);
        }
    }

    // shiv

    const handleAddUpdateCheckBox = (event) => {
        event.preventDefault();        

        // if (st_id <= 0){
        //     toast.error("Entry required for student !");
        //     clearErrors();
        //     return;
        // }

        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.error("Entry required for Branch !");
            clearErrors();
            return;
        }

        if(fy === undefined || fy.length === undefined || fy.length === 0){
            toast.error("Entry required for financial year !");
            clearErrors();
            return;
        }

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.error("Entry required for class !");
            clearErrors();
            return;
        }

        if(st_sec === undefined || st_sec.length === undefined || st_sec.length === 0){
            toast.error("Entry required for sec !");
            clearErrors();
            return;
        }
        
        
        // console.log("checkedinputvalue is : ", checkedinputvalue)        

        const checkedinputvalue = [];
        for (let i = 0; i < items.length; i++) {
            if (items[i].isChecked === true) {
                checkedinputvalue.push(parseInt(items[i].id));
            }
        }

        if (checkedinputvalue.length === 0) {
            toast.error("Please select student to continue ... ");
            clearErrors();
            return;
        }

        // req.body.ListData.under_comp_branch_id,
        // req.body.ListData.fin_year,                                 
        // req.body.ListData.notes,
        // req.body.ListData.items

        const data = {
            under_comp_branch_id,
            fin_year : fy,			
            notes,
            items:checkedinputvalue,
        };

        // console.log("data is : ", items)

        dispatch(emptyCart());
        dispatch(addTempList(data));
        setModelModelFormYesNo(true);
    }    

    const goPrePageFunction = (event) => {
        event.preventDefault();
        setPkID(0);        
        setviewRecord(false);
        // router.push('/');

        navigate('/brms')
    }

    const handleSearchCode = (event) => {
        // event.preventDefault();
        // const getSearch = event.target.value;
        // let searchdata = filterdata;

        // searchdata = searchdata.filter((item) => item.l_code.toLowerCase().includes(getSearch.toLowerCase()));
        // searchdata = searchdata.filter((item) => item.l_name.toLowerCase().includes(queryName.toLowerCase()));

        // setQueryCode(getSearch);
        // setItems(searchdata);
        // generateExcelData(searchdata);
    }

    const handleSearchName = (event) => {
        // event.preventDefault();
        // const getSearch = event.target.value;
        // let searchdata = filterdata;


        // searchdata = searchdata.filter((item) => item.l_name.toLowerCase().includes(getSearch.toLowerCase()));
        // searchdata = searchdata.filter((item) => item.l_code.toLowerCase().includes(queryCode.toLowerCase()));

        // setQueryName(getSearch);
        // setItems(searchdata);
        // generateExcelData(searchdata);
    }

    const handleboardListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setboard(getId);
    }

    const handlefee_categListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setfee_categ(getId);
    }

    const handlepay_termOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setpay_term(getId);
    }

    const handlestudent_typeListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setstudent_type(getId);
    }

    const handlemediumListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setmedium(getId);
    }

    const handlest_classListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setst_class(getId);

        setItems([])
    }

    const handlest_secListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setst_sec(getId);

        setItems([])
    }

    const handleUnderBranchOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        setunder_comp_branch_id(getId);
        
        setItems([]);
    }

    const handleFyOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        setfy(getId);
        
        setItems([]);
    }    

    const handlestreamListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setstream(getId);

        setItems([])
    }

    const handleFeeMonthListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setfee_month(getId);
    }

    const handleFeeCodeListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setfee_code_id(getId);
    }    

    const showStudentFunction = (event) => {
        event.preventDefault();

        let v_stream = 0;
        
        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.error("Entry required for Branch !");
            clearErrors();
            return;
        }

        if(fy === undefined || fy.length === undefined || fy.length === 0){
            toast.error("Entry required for financial year !");
            clearErrors();
            return;
        }

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.error("Entry required for class !");
            clearErrors();
            return;
        }

        if(st_sec === undefined || st_sec.length === undefined || st_sec.length === 0){
            toast.error("Entry required for section !");
            clearErrors();
            return;
        }

        if(streamItems.length > 0){
            if(stream === undefined || stream.length === undefined || stream.length === 0){
                toast.error("Entry required for Stream !");
                clearErrors();
                return;
            }else{
                v_stream = stream;    
            }       
        }else{
            v_stream = localStorage.getItem('stem');
        }

        // shiv

        const getParamTableRecordFunctionBranch = async (var_link) => {

            let response = []
            let updatedata = []

            response = await axios.get(`${var_link}/${under_comp_branch_id}/${fy}/${st_class}/${st_sec}/${v_stream}`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data;

            // console.log(updatedata)

            setItems(updatedata);
            setFilterdata(updatedata);
            generateExcelData(updatedata);
        };
        getParamTableRecordFunctionBranch('url_student_master_FullFeeConcessionfun');  
    }

    const handleChangeBrachFY = (event) => {
        event.preventDefault();
        setviewRecord(false);

        localStorage.setItem('fm', 'fm'); // form_name

        // router.push('/cb');

        navigate("/cb");
    }

    const handlefrom_fee_monthListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setfrom_fee_month(getId);        
    }

    const handleto_fee_monthListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setto_fee_month(getId);
    }

    const handlefee_amt = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setfee_amt(value);
    };    

    return (

        <>

            <div className="pt-PaddingTopAfterMenu max-w-full text-my-main-heading-fontSize bg-my-main-heading-color text-my-main-heading-font-color text-left">Student Full Fee Concession Branch wise</div>

            <div className="border-my-borderWidth border-my-TableHeadingBorderColor overflow-y-scroll">
            <div className="grid lg:grid-cols-8 gap-2">    

            {boardItems.length > 0 && (
                <div className="col-span-1">
                    <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Board</label>
                    <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                        name="board" value={board} onChange={(e) => handleboardListOption(e)}
                    >
                        <option></option>
                        {
                            boardItems.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </div>
            )}

            {fee_categItems.length > 0 && (
                <div>
                    <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Fee Category</label>
                    <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                        name="fee_categ" value={fee_categ} onChange={(e) => handlefee_categListOption(e)}
                    >
                        <option></option>
                        {
                            fee_categItems.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </div>
            )}

            {pay_termItems.length > 0 && (
                <div>
                    <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Payment Term</label>
                    <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                        name="pay_term" value={pay_term} onChange={(e) => handlepay_termOption(e)}
                    >
                        <option></option>
                        {
                            pay_termItems.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </div>
            )}

            {student_typeItems.length > 0 && (
                <div>
                    <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Student Type</label>
                    <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                        name="student_type" value={student_type} onChange={(e) => handlestudent_typeListOption(e)}
                    >
                        <option></option>
                        {
                            student_typeItems.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </div>
            )}

            {mediumItems.length > 0 && (                    
                <div>
                    <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Medium</label>
                    <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                        name="medium" value={medium} onChange={(e) => handlemediumListOption(e)}
                    >
                        <option></option>
                        {
                            mediumItems.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </div>
            )}

            <div className="col-span-2">
                <label className="max-w-full text-my-font-color font-myFontWeight">Under Branch</label>        
                <select className="w-full col-span-4 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                    name="under_comp_branch_id" value={under_comp_branch_id} onChange={(e) => handleUnderBranchOption(e)}
                    >
                    <option></option>
                    {
                        under_comp_branchItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>      
            </div>   

            <div className="col-span-1">
                <label className="max-w-full text-my-font-color font-myFontWeight">Fin.Year</label>
                <select className="w-full col-span-4 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                    name="fy" value={fy} onChange={(e) => handleFyOption(e)}
                    >
                    <option></option>
                    {
                        fyItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>      
            </div>   

            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Class</label>
                <select className="w-[100%] h-DropDownHeight py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="st_class" value={st_class} onChange={(e) => handlest_classListOption(e)}
                >
                    <option></option>
                    {
                        st_classItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>
                <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Section</label>
                <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                    name="st_sec" value={st_sec} onChange={(e) => handlest_secListOption(e)}
                >
                    <option></option>
                    {
                        st_secItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div> 
            
            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Stream</label>
                <select className="w-[100%] h-DropDownHeight py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="stream" value={stream} onChange={(e) => handlestreamListOption(e)}
                >
                    <option></option>
                    {
                        streamItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>
        

            <div className="col-span-1">
                <label className="mb-1 h-DropDownHeight max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Notes</label>
                <input className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                        name="notes"
                        id="notes"
                        type="text"
                        value={notes}
                        onChange={(e) => setnotes(e.target.value)}
                        // onKeyDown={handleEnter} {...props}
                ></input>
            </div>  

            <div className="mt-6 col-span-1">
                <button className="w-[85%] h-DropDownHeight ml-3 bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    onClick={showStudentFunction}
                >
                    Show
                </button>
            </div>

           
        </div>


                <div className="mt-2 grid mm:grid-cols-1 md:grid-cols-12 lg:grid-cols-12">

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor                        
                            col-span-2">

                        <input className="py-my-padding-py px-my-padding-px  text-my-fontSize text-TableHeadingFontColor font-my-font-name font-myFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor w-[5%]"
                            type="checkbox" name="allselect" checked={!items.some((user) => user?.isChecked !== true)}
                            onChange={handleChangeCheckBox}
                        />

                        <label className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor                                
                                col-span-2 w-[95%]">
                            Admission No.
                        </label>
                    </div>                                       

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-1">
                        Class
                    </div>

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-1">
                        Section
                    </div>

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-1">
                        Stream
                    </div>

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-3">
                        Name
                    </div>

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-2">
                        Father's Name
                    </div>

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-2">
                        Mother's Name
                    </div>

                    {/* --------------------------------------------------------------------------- */}
                    {/* Start of Search */}

                    {/* <div className="col-span-3">
                        <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                w-[6%]"
                            disabled
                        />

                        <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-3 w-[94%]"
                            type="text"
                            name="username"
                            value={queryCode}
                            placeholder="Search User Name..."
                            onChange={(e) => handleSearchCode(e)}
                            autoComplete="off"
                        />
                    </div>

                    <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                        col-span-4"
                        type="text"
                        name="under_comp_group"
                        value={queryName}
                        placeholder="Search Under Group..."
                        onChange={(e) => handleSearchName(e)}
                        autoComplete="off"
                    /> */}

                    {/* --------------------------------------------------------------------------- */}
                    {/* End of Search */}

                </div>
            </div>

            <div className="py-my-padding-py px-my-padding-px border-my-borderWidth border-my-border-color h-myTableHeight overflow-y-scroll">
                {
                    items?.map((item) => (

                        <div className="grid mm:grid-cols-1 md:grid-cols-12 lg:grid-cols-12" key={item.id}>

                            {/* select id, adm_no, st_class, st_sec, st_name, fat_name, mot_name */}

                            <div className="col-span-2">
                                <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                    w-[6%]"
                                    type="checkbox" name={item.id} checked={item?.isChecked || false}
                                    onChange={handleChangeCheckBox}
                                />

                                <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                    col-span-2 w-[94%]"
                                    type="text"
                                    name="adm_no"
                                    value={item.adm_no}
                                    disabled
                                />
                            </div>

                            <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-1">                                                                
                                {item.st_class}
                            </div>                                                        

                            <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-1">                                                                
                                {item.st_sec}
                            </div>

                            <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-1">                                                                
                                {item.st_stream}
                            </div>

                            <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-3">                                                                
                                {item.st_name}
                            </div>

                            <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-2">                                                                
                                {item.fat_name}
                            </div>

                            <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-2">                                                                
                                {item.mot_name}
                            </div>

                        </div>
                    ))
                }
            </div>

            <div className="mt-2 py-my-padding-py px-my-padding-px overflow-y-scroll">
                <div className="grid mm:grid-cols-1 md:grid-cols-12 lg:grid-cols-9 gap-4">

                    <div></div><div></div><div></div>

                    <div>
                        Records: {items.length}
                    </div>                    

                    <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                        onClick={handleAddUpdateCheckBox}>
                        Add/Update Amount
                    </button>

                    <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                        onClick={goPrePageFunction}>
                        Back
                    </button>                 

                </div>
            </div>

            <ModelFormYesNo table_name='STANDARD' api_name={'url_StudentFullFeeConcession_new_proc'} isVisible={ShowModelFormYesNo} onClose={() => setModelModelFormYesNo(false)} />


        </>
    );
};

export default Sfa;
