import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import CRUDContext from '../components/CRUDContext';

import { emptyGlobalDataFun, setTempDataFun } from '../components/redux/actions';

import Cookies from 'js-cookie';

const ExamGroup = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';    
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);    
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);      
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);   

    const dispatch = useDispatch();
    const navigate = useNavigate();
        
    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData); 

    let v_input_result, v_active_status = 1, v_null_parameter = 'NA';

    // define variable for tables entry (master detail form)    

    const [items, setItems] = useState([]);    
 
    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0);
    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);    

    const [fin_year, setfin_year] = useState(0);
    const [fin_yearItems, setfin_yearItems] = useState([]);      

    const [copy_under_comp_branch_id, copy_setunder_comp_branch_id] = useState(0);
    const [copy_fin_year, copy_setfin_year] = useState(0);

    const [st_classItems, setst_classItems] = useState([]);
    const [st_class, setst_class] = useState('') ;

    const [copy_st_classItems, copy_setst_classItems] = useState([]);
    const [copy_st_class, copy_setst_class] = useState('');

    const [streamItems, setstreamItems] = useState([]);
    const [stream, setstream] = useState(0);

    const [copy_stream, copy_setstream] = useState(0);
    const [copy_streamItems, copy_setstreamItems] = useState([]);
  
    // define form name and navigation details

    const [v_urlSaveUpdateDelete, setv_urlSaveUpdateDelete] = useState('3_url_ExamGroup_branch_wise_new');
    
    const v_backToNavigateName = '/brms';
    // const v_findToNavigateName = '/';
    const v_main_form_heading = 'Exam Group Branch & Financial Year Wise';
    const v_frm_no = 3;

    const {
        deletedMessage,
        createdMessage,
        updatedMessage,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);          

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code

                setv_open_frm(true);

                // console.log("Update allow_update >> ", result.allow_update)

                setBlankFields();

                const v_token = Cookies.get('token');
                
                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_user_wise_branch_view_drop_down/38/0/0/${ReduxGlobalData[0].id}`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setunder_comp_branchItems(updatedata);

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_comp_group_branch_wise_drop_down/38/${ReduxGlobalData[0].ag}/0/FY`,
                    { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setfin_yearItems(updatedata);           
            }
        }
        loadData();
    }, []);

    useEffect(() => {
        setBlankFields();
    }, [ReduxGlobalData[0]]);

    const handleCopyToRecordOption = () => {      
        
        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.info('Entry required for Under branch', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;  
        }

        if(fin_year === undefined || fin_year.length === undefined || fin_year.length === 0){
            toast.info('Entry required for Financial year', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.info('Entry required for Student class', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(stream === undefined || stream.length === undefined || stream.length === 0){
            toast.info('Entry required for Stream', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(copy_under_comp_branch_id === undefined || copy_under_comp_branch_id.length === undefined || copy_under_comp_branch_id.length === 0){
            toast.info('Entry required for Copy Under branch', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;  
        }

        if(copy_fin_year === undefined || copy_fin_year.length === undefined || copy_fin_year.length === 0){
            toast.info('Entry required for Copy Financial year', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(copy_st_class === undefined || copy_st_class.length === undefined || copy_st_class.length === 0){
            toast.info('Entry required for Copy Student class', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(copy_stream === undefined || copy_stream.length === undefined || copy_stream.length === 0){
            toast.info('Entry required for Copy Stream', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }
        
        if (under_comp_branch_id + fin_year + st_class + stream === copy_under_comp_branch_id + copy_fin_year + copy_st_class + copy_stream){
            toast.info('Copy From and To are same', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }
        
        const data = {
            insupdel_type : 'INSERT',
            copy_from_under_comp_branch_id : under_comp_branch_id, 
            copy_to_under_comp_branch_id : copy_under_comp_branch_id,
            copy_from_fin_year : fin_year, 
            copy_to_fin_year : copy_fin_year,
            copy_from_st_class : st_class, 
            copy_to_st_class : copy_st_class,
            copy_from_st_stream : stream, 
            copy_to_st_stream : copy_stream,            
        };

        let insupdel_type = 'INSERT';       

        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }
        
        setv_urlSaveUpdateDelete('3_url_copy_to_url_examgroup_branch_wise');
    }

    const FindSavedTableDetail = async(p_TableRecordID) => {

        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.info('Entry required for Under branch', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;  
        }

        if(fin_year === undefined || fin_year.length === undefined || fin_year.length === 0){
            toast.info('Entry required for Financial year', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.info('Entry required for Student class', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(stream === undefined || stream.length === undefined || stream.length === 0){
            toast.info('Entry required for Stream', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        const v_token = Cookies.get('token');
        
        // show the detail part
        
        response = await axios.get(`url_fetch_data/3_url_examgroup_branch_wise_view_fun/${v_frm_no}/${ReduxGlobalData[0].ag}/0/0/${v_active_status}
        /${under_comp_branch_id}
        /${fin_year}
        /${st_class}
        /${stream}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;             
        setItems(await updatedata);        
    }
    
    function setBlankFields() {      
        setTableRecordID(0);        
    }

    const handleInputValueFun = async(name, value) => {       

        const v_token = Cookies.get('token');

        if (name === 'under_comp_branch_id') {            
             
            setunder_comp_branch_id(value);

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${value}/0/ST_CLASS`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            setst_classItems(updatedata);
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${value}/0/ST_STREAM`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            setstreamItems(updatedata);
            
            setItems([]);
            return;
        }else if(name === 'fin_year') {
            setfin_year(value);
            setItems([]);
        }else if(name === 'st_class') {
            setst_class(value);
            setItems([]);
        }else if(name === 'stream') {
            setstream(value);
            setItems([]);
        }

        else if (name === 'copy_under_comp_branch_id') {            
             
            copy_setunder_comp_branch_id(value);

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${value}/0/ST_CLASS`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            copy_setst_classItems(updatedata);
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${value}/0/ST_STREAM`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            copy_setstreamItems(updatedata);
            
            setItems([]);
            return;
        }else if(name === 'copy_fin_year') {
            copy_setfin_year(value);
            setItems([]);
        }else if(name === 'copy_st_class') {
            copy_setst_class(value);
            setItems([]);
        }else if(name === 'copy_stream') {
            copy_setstream(value);
            setItems([]);
        }
    }

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target; 
        
        let insupdel_type = 'INSERT';

        if (value != 'DELETE'){
            if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
                toast.info('Entry required for Under branch', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;  
            }
    
            if(fin_year === undefined || fin_year.length === undefined || fin_year.length === 0){
                toast.info('Entry required for Financial year', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }
    
            if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
                toast.info('Entry required for Student class', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }
    
            if(stream === undefined || stream.length === undefined || stream.length === 0){
                toast.info('Entry required for Stream', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            if(items.length === 0){
                toast.info('Record not found to be saved', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }
        }       

        const data = {
            id: 0,
            insupdel_type:'INSERT',            
            under_comp_branch_id,
            fin_year,
            st_class,
            stream,
            items: JSON.stringify(items)
        };
        
        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);
        setv_urlSaveUpdateDelete('3_url_ExamGroup_branch_wise_new');

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }

    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);        

        if (p_data.ModelYesNoAnswer === 'YES'){
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async(p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final:p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final:p_last_updel_notes_final,
        }        

        const v_token = Cookies.get('token');

        // response = await axios.post(`${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        // { headers: { Authorization: `Bearer ${v_token}` } });

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });
        
        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }
    
    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                    
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            // setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);
            
            // console.log("updatedata.TabID.v_data >> ", updatedata.v_data);
            
            if (updatedata.transType === 'INSERT'){                
                v_toast_msg = createdMessage;

                setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);

                getSavedDetailFunction(0);
            }else if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;

                getSavedDetailFunction(0);
            }else if (updatedata.transType === 'DELETE'){                
                v_toast_msg = deletedMessage;
                setBlankFields();
            }

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }

    const setNewRecordIDFunction = async() => {
        
        // console.log("setNewRecordIDFunction >> ", p_v_data[0].p_id, p_v_data[0].p_vou_no);

        // setTableRecordID(await p_v_data[0].p_id);        
    }

    const getSavedDetailFunction = async (p_v_data) => {     
        FindSavedTableDetail(p_v_data);
    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

    };

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        handleInputValueFun(name, value);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        if (value === 'BTN_FIND'){
            FindSavedTableDetail(0);
            return;
        }else if (value === 'BTN_COPY'){                        
            handleCopyToRecordOption();
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };
    
    const goCheckDataFunction = (event) => {
        event.preventDefault();

        // console.log("TableRecordID >> ", TableRecordID);

        console.log("items >> ", items);

    }

    // For details entry (entry in table)
    // For details entry (entry in table)
    
    const handlerChangeTableRowFocus = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;
    };

    const handlerChange = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;        

        let v_value = value;        
        const list = [...items];

        if (name === 'dis_seq'){
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', v_value, '', 100);
            v_value = v_input_result.v_value;
        }else if (name === 'print_name'){
            v_value = capitalizeWordFun(v_value);
        }else if (name === 'notes'){            
            v_value = capitalizeWordFun(v_value);
        }
        list[i][name] = v_value; 
        setItems(list);
    };

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;          

        let tempListID = [];        

        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, ischecked: checked } });
            setItems(checkedvalue);
        }
        else {
            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, ischecked: checked } : user);
            setItems(checkedvalue);
        }        
    }

    const setShowPopUpFalseFunction = (event) => {        
        // setShowPopUp(false);
    };

    const handleInputBlur = (event, i, p_col_name) => {
        // event.preventDefault();
        // const { name, value } = event.target;

        // console.log("handleInputBlur >> ", value);

        // // setSelectedrow(i);
        // // setSelectedColumn(p_col_name);

        // if (p_col_name === 'FEE_DETAIL_FEE_MONTH_DESCR' || p_col_name === 'FEE_DETAIL_FEE_CODE_DESCR'){
            

            
        //     setShowPopUp(true);
        // }else{
            
        //     setShowPopUp(false);
        // }

        // console.log('Input field lost focus');

    };


    const handleChangeCheckBox_allow_grading = (event, i, v_data) => {
        const { name, checked } = event.target;

        const list = [...items];

        console.log("In check name is : ", name)
        console.log("In check checked is : ", checked)
        console.log("In check i is : ", i)
        console.log("In check v_data is : ", v_data)
        
        if (name === "allselect") {

            // const checkedvalue = items.map((user) => { return { ...user, allow_grading_isChecked: checked } });

            const checkedvalue = items.map((user) => { return { ...user, [v_data]: checked } });

            setItems(checkedvalue);

        }
        else {

            console.log("In else part")
               
            // list[i][v_data] = checked;
            // setItems(list);

            list[i][v_data] = checked;
            setItems(list);
            
        }
    }

    const handleChangeCheckBox_allow_open = (event, v_data) => {

        const { name, checked } = event.target;
        
        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, [v_data]: checked } });
            setItems(checkedvalue);
        }
        else {
            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, [v_data]: checked } : user);
            setItems(checkedvalue);
        }
        
    }

return (

<>
    {
        ShowConfirmModelSave ? 
        <div className="centerWindow_css">
            <ConfirmModelSave transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

    {
        ShowConfirmModelUpdateDelete ? 
        <div className="centerWindow_css w-[32%]">
            <ConfirmModelUpdateDelete transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} onMouseOut={(e) => setShowPopUpFalseFunction(e)} autoComplete="off">

<div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

<div className="form_div_main_css">
    <div className="form_div_inner_css grid grid-cols-6">        

        <div className="col-span-2">
            <label className="label_inner_page_css required_css">Under Branch</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="under_comp_branch_id"
                id="under_comp_branch_id"
                value={under_comp_branch_id}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                 <option></option>
                {
                    under_comp_branchItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css required_css">Fin.Year</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="fin_year"
                id="fin_year"
                value={fin_year}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    fin_yearItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css required_css">Class</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="st_class"
                id="st_class"
                value={st_class}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    st_classItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css required_css">Stream</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="stream"
                id="stream"
                value={stream}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    streamItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </div>


        <div>
            <label className="label_inner_page_css text-white">.</label>
            <button className="btn_back_css w-[45%]"
                value = 'BTN_FIND'
                onClick={handleButtonValue}>
                Show
            </button>
        </div>

        <div className="col-span-2">
            <label className="label_inner_page_css required_css">Copy to Branch</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="copy_under_comp_branch_id"
                id="copy_under_comp_branch_id"
                value={copy_under_comp_branch_id}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    under_comp_branchItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </div>       

        <div className="col-span-1">
            <label className="label_inner_page_css required_css">To Fin./Year</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="copy_fin_year"
                id="copy_fin_year"
                value={copy_fin_year}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    fin_yearItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css required_css">To Class</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="copy_st_class"
                id="copy_st_class"
                value={copy_st_class}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    copy_st_classItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css required_css">To Stream</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="copy_stream"
                id="copy_stream"
                value={copy_stream}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    copy_streamItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </div>


        <div>
            <label className="label_inner_page_css text-white">.</label>
            <button className="btn_back_css w-[45%]"
                value = 'BTN_COPY'
                onClick={handleButtonValue}>
                Copy
            </button>
        </div>

    </div>
</div> 


<table className="table_main_css">
<thead className="table_thead_css">            
    <tr className="table_thead_tr_css">
      
        <th className="table_th_td_css w-[5%]">
            <input className="table_check_inner_page_css"
                type="checkbox" name="allselect" checked={!items.some((user) => user?.ischecked !== true)}
                onChange={handleChangeCheckBox}
                onFocus={handleInputFocus}                        
            />
        </th>

        <th className="table_th_td_css w-[35%] text-left">Exam Group Heading</th>
        <th className="table_th_td_css w-[5%] text-center">Dis.Seq.</th>
        <th className="table_th_td_css w-[30%] text-left">Print Name</th>        
        <th className="table_th_td_css w-[25%] text-left">Notes</th>
    </tr>
</thead>

<tbody className="table_tbody_css h-[42vh]">
{
    items?.map((item, index) => (                   

    <tr className="table_thead_tr_css table_tbody_hover_css" key={item.t1_id}>

        <td className="table_th_td_css w-[5%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <input className="table_check_inner_page_css"
                type="checkbox" name={item.t1_id}
                checked={item?.ischecked || false}
                onChange={handleChangeCheckBox}                                                                
            />
        </td>       

        <td className="table_input_th_td_css w-[35%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <div className="table_input_inner_page_css">{item.exam_group_param_id_name}</div>
        </td>

        <td className="table_input_th_td_css text-left w-[5%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
            <input className="table_input_inner_page_css text-center"
                type="text"
                ref={(ref) => addInputRef(ref, 15)}
                name="dis_seq"
                id="dis_seq"
                value={item.dis_seq}                                                                   
                onChange={(e) => handlerChange(e, index)}
            ></input>
        </td>    

        <td className="table_input_th_td_css text-left w-[30%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
            <input className="table_input_inner_page_css"
                type="text"
                ref={(ref) => addInputRef(ref, 15)}
                name="print_name"
                id="print_name"
                value={item.print_name}                                                                   
                onChange={(e) => handlerChange(e, index)} 
            ></input>
        </td>            

        <td className="table_input_th_td_css text-left w-[25%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
            <input className="table_input_inner_page_css"
                type="text"
                ref={(ref) => addInputRef(ref, 15)}
                name="notes"
                id="notes"
                value={item.notes}                                                                   
                onChange={(e) => handlerChange(e, index)} 
            ></input>
        </td>

    </tr>
    ))
}
</tbody>
</table>

<nav className="btn_nav_table_sum_inner_css">
<div className="btn_div_main_css">

    <div className="btn_div_inner_css">                

        <div>
            <button className="btn_save_css"                    
                onClick={createRecordFunction}>
                Save
            </button>                
        </div>                
        
        <div>
            <button className="btn_back_css"
                value = 'BTN_BACK'
                onClick={handleButtonValue}>
                Back
            </button>
        </div>

        {/* <div>                
            <button className="btn_back_css"
                onClick={goCheckDataFunction}>
                Check Data
            </button>
        </div> */}
            
    </div>
    
</div>
</nav>

</form>
:null
}

</>
);};

export default ExamGroup;
