import React, { useState, useEffect, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheck } from '@fortawesome/free-solid-svg-icons';

const SelectRecordPopUp = (props) => {    
    const inputRefs = useRef([]);
    const [items, setItems] = useState([]);    
    const [queryName, setQueryName] = useState(''); 
    const [origionalData, setOrigionalData] = useState([]);
    
    const inputQNRef = useRef(null);
    const inputChkBoxRef = useRef(null);       

    useEffect(() => {
        setOrigionalData(props.items);
        setItems(props.items);
        inputQNRef.current.focus();        
    }, []);
  
    const handleInputBlur = event => {
        console.log('lost focus 1');
    };

    const handleSearchName = (event) => {
        event.preventDefault();
        const getSearch = event.target.value;        
        let searchdata = origionalData;
        searchdata = searchdata.filter((item) => item.st_name.toLowerCase().includes(getSearch.toLowerCase()));
        setQueryName(getSearch);
        setItems(searchdata);     
    }

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;        

        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, ischecked: checked } });
            setItems(checkedvalue);
        }
        else {            
            ischeckedTick(name);
        }
    }

    const ischeckedTick = () => {}

    const onClickTrFun = () => {}

return (

<>

{/* <nav className="flex absolute bg-gray-400 text-black" onKeyDown={(e) => CloseBox(e)}></nav> */}

<nav className="flex absolute bg-orange-200 text-black w-[35%] ">
        <table className="table_main_css" onBlur={handleInputBlur}>
            
            <thead className="table_thead_css">
                <tr className= "table_thead_tr_css">

                    <th className="table_th_td_css w-[7%]"></th>

                    <th className="table_th_td_css text-center w-[50%]">Student Name</th>

                    <th className="table_th_td_css text-center w-[43%]">Father Name</th>

                </tr>        
            </thead>            

            <thead className="table_thead_css">
                <tr className= "table_thead_tr_css">

                    <th className="table_th_td_css w-[7%] mt-1">
                        <input className="table_check_inner_page_css"
                            type="checkbox" name="allselect" checked={!items.some((user) => user?.isChecked !== true)}
                            onChange={handleChangeCheckBox}                            
                        />
                    </th>

                    <th className="table_th_td_css w-[86%]">
                        <input className="input_inner_page_css"
                            ref={inputQNRef}
                            name="queryName"
                            id="queryName"
                            type="text"
                            value={queryName}
                            // onChange={(e) => setQueryName(e.target.value)}
                            // onKeyDown={handleEnter} {...props}
                            // ref={(ref) => addInputRef(ref, 0)}
                            // onChange={(e) => handleInputChange(e, 0)}
                            // onChange={(e) => handleSearchName(e, 0)}
                            onChange={(e) => handleSearchName(e)}                            
                        ></input>                        
                        {/* <FontAwesomeIcon className="fai_css" icon={faCheck} size="2xl" /> */}
                    </th>

                     <th className="table_th_td_css w-[7%]">                                              
                        <FontAwesomeIcon icon={faCheck} size="2xl" />
                    </th>                    

                </tr>        
            </thead>            

            <tbody className="table_tbody_css" >
                {
                    items?.map((item, index) => (

                        <tr className="table_thead_tr_css hover:bg-red-400">
                            <td className="table_th_td_css w-[7%]">
                                <input className="table_check_inner_page_css"
                                    ref={inputChkBoxRef}
                                    type="checkbox" name={item.id} checked={item?.isChecked || false}
                                    value={item.st_name}
                                    onChange={handleChangeCheckBox}
                                />
                            </td>


                            {/* <td className="table_th_td_css w-[7%]">
                                <input className="table_check_inner_page_css"
                                    ref={inputChkBoxRef}
                                    type="checkbox" name={item.id} checked={item?.isChecked || false}
                                    onChange={handleChangeCheckBox}                                    
                                />
                            </td> */}

                            <td className="table_th_td_css text-left w-[50%]" onMouseDown={(e) => onClickTrFun(e, item.id, item.isChecked)}>
                                {item.st_name}
                            </td>                           

                            <td className="table_th_td_css text-left w-[43%]" onMouseDown={(e) => onClickTrFun(e, item.id, item.isChecked)}>
                                {item.fat_name}
                            </td>
                        </tr>
                ))
                }    
            </tbody>              

        </table>
</nav>
</>
);
};

export default SelectRecordPopUp;
