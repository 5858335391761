import React, { useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import CRUDContext from './CRUDContext';
import { toast } from "react-toastify";
import axios from 'axios';

const ModelFormDeleteUpdate = ({ isVisible, onClose, api_name, api_type }) => {
    
    const {
        createRecord,
        clearErrors,
    } = useContext(CRUDContext);

    const [upDelNotes, setUpDelNotes] = useState('');
    const ReduxData = useSelector((state) => state.TempData);

    if (!isVisible) return null;

    const ListData = ReduxData[0];

    // const ListData = ReduxData.cartData[0];

    const upDeleteHandler = (e) => {
        e.preventDefault();

        if (upDelNotes.trim().length === 0) {
            toast.info("Entry reuired for reason !")
            clearErrors();
            return;
        }

        const getIPAddess = async () => {            

            const ipAddess = localStorage.getItem('ipadd');

            const listFinalData = {
                ListData,
                'last_access_ip_final': ipAddess,
                'last_access_by_final': localStorage.getItem('id'),
                'last_updel_notes_final': upDelNotes,
                'api_type': api_type,
            }

            createRecord(listFinalData, api_name, api_type);

            onClose();

        };
        getIPAddess();
    }

    

    return (

        // <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-3xl flex justify-center items-center">
        //     <div className="container justify-center pt-2 px-2 grid grid-cols-1 gap-4">

        <div className="justify-center items-center fixed inset-0 bg-my-when-focus-bg-color bg-opacity-100 backdrop-blur-3xl flex">

            <div className="pt-2 px-2">

                <div className="mb-4 pt-7 max-w-full text-my-font-color font-myFontWeight text-my-main-heading-fontSize text-center">
                    Please specify the reason
                </div>

                <input type="text" className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color"
                    placeholder="Please specify the reason"
                    onChange={(e) => setUpDelNotes(e.target.value)}
                    required
                ></input>

                <div>
                    <span>
                        <button className="mx-1 m-4 w-[48%] bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-my-fontSize text-SaveButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={upDeleteHandler}
                        >
                            Yes
                        </button>

                        <button className="w-[48%] bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-my-fontSize text-SaveButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={() => onClose()}
                        >
                            No
                        </button>
                    </span>
                </div>

            </div>
        </div>
    )
}

export default ModelFormDeleteUpdate;
