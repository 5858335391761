import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
// import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import CRUDContext from '../components/CRUDContext';
import DropDownTableComponent from "../components/DropDownTableComponent";

import SearchListDropdown from "../components/SearchListDropdown";

import { emptyTempDataFun, emptyGlobalDataFun } from '../components/redux/actions';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import Cookies from 'js-cookie';

const FeeMaster = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';    
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);    
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);      
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);   

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disableSave, setdisableSave] = useState(0);
    const [disableDelete, setdisableDelete] = useState(0);
    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');
    const [AllowUpdateTag, setAllowUpdateTag] = useState(false);

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData); 
    const ReduxTempData = useSelector((state) => state.TempData);
    const ReduxDDBranchBoardData = useSelector((state) => state.DDBranchBoardData);
    const ReduxDDMonthData = useSelector((state) => state.DDMonthData);
    const ReduxDDStudentTypeData = useSelector((state) => state.DDStudentTypeData);
    const ReduxDDMediumData = useSelector((state) => state.DDMediumData);
    const ReduxDDStStreamData = useSelector((state) => state.DDStStreamData);
    const ReduxDDFeeCategData = useSelector((state) => state.DDFeeCategData);
    const DDFeePaymentTermData = useSelector((state) => state.DDFeePaymentTermData);
    const ReduxDDStudentClassData = useSelector((state) => state.DDStudentClassData);    
    const ReduxDDFeeCodeData = useSelector((state) => state.DDFeeCodeData);  

    // define drop downs inputs
    
    const [board, setboard] = useState(0);
    const [student_type, setstudent_type] = useState(0);
    const [medium, setmedium] = useState(0);
    const [stream, setstream] = useState(0);
    const [fee_categ, setfee_categ] = useState(0);
    const [pay_term, setpay_term] = useState(0);
    const [st_class, setst_class] = useState(0);    

    const [board_descr, setboard_descr] = useState('');
    const [fee_categ_descr, setfee_categ_descr] = useState('');
    const [medium_descr, setmedium_descr] = useState('');
    const [pay_term_descr, setpay_term_descr] = useState('');
    const [student_type_descr, setstudent_type_descr] = useState('');    
    const [stream_descr, setstream_descr] = useState('');
    const [st_class_descr, setst_class_descr] = useState('');
        
    // When we will use Multiple selection in Drop Down

    // const [st_class_multiple, setst_class_multiple] = useState([]);
    // const [st_class_descr, setst_class_descr] = useState([]);

    // define drop downs items

    const [boardItems, setboardItems] = useState([]);
    const [student_typeItems, setstudent_typeItems] = useState([]);
    const [mediumItems, setmediumItems] = useState([]);
    const [streamItems, setstreamItems] = useState([]);
    const [fee_categItems, setfee_categItems] = useState([]);
    const [pay_termItems, setpay_termItems] = useState([]);
    const [st_classItems, setst_classItems] = useState([]);   

    const [pay_fee_monthItems, setfee_monthItems] = useState([]);
    const [fee_codeItems, setfee_codeItems] = useState([]);
    const [app_forItems, setapp_forItems] = useState([]);

    // define other input
     
    const [notes, setnotes] = useState();    

    let v_input_result;   

    // define variable for tables entry (master detail form)    

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);

    const [ShowPopUp, setShowPopUp] = useState(false);
    const [selectedrow, setSelectedrow] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(false);

    const [IDArray, setIDArray] = useState([]);
    const [IDArrayValue, setIDArrayValue] = useState([]);    
    
    const [SumAmount, setSumAmount] = useState(0);
    
    // define standard errors and entry required messages   

    // define form name and navigation details

    const v_urlSaveUpdateDelete = 'url_fee_master_new';
    const v_backToNavigateName = '/Dashboard';
    const v_findToNavigateName = '/Fmview';
    const v_main_form_heading = 'Fee Master';
    const v_frm_no = 7;

    const {        
        deletedMessage,
        createdMessage,
        updatedMessage,        
        whenStudentNotFoundMessage,                     
        WhenRecordNotFoundToDelete,
        WhenRecordNotFoundToUpdate,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);          

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code

                setv_open_frm(true);                

                setdisableSave(false);
                setdisableDelete(true);

                // console.log("Update allow_update >> ", result.allow_update)
                
                setst_classItems(ReduxDDStudentClassData[0]);                
                setfee_codeItems(ReduxDDFeeCodeData[0]);
                setfee_monthItems(ReduxDDMonthData[0]);

                if (ReduxGlobalData[0].brd === '0' && ReduxDDBranchBoardData.length > 0){
                    setboardItems(ReduxDDBranchBoardData[0]);}            

                if (ReduxGlobalData[0].st === '0' && ReduxDDStudentTypeData.length > 0){               
                    setstudent_typeItems(ReduxDDStudentTypeData[0]);}

                if (ReduxGlobalData[0].medi === '0' && ReduxDDMediumData.length > 0){                
                    setmediumItems(ReduxDDMediumData[0]);}

                if (ReduxGlobalData[0].stem === '0' && ReduxDDStStreamData.length > 0){                
                    setstreamItems(ReduxDDStStreamData[0]);}

                if (ReduxGlobalData[0].fcat === '0' && ReduxDDFeeCategData.length > 0){                
                    setfee_categItems(ReduxDDFeeCategData[0]);}

                if (ReduxGlobalData[0].ptm === '0' && DDFeePaymentTermData.length > 0){                
                    setpay_termItems(DDFeePaymentTermData[0]);
                }
                
                const v_token = Cookies.get('token');
                
                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${ReduxGlobalData[0].br}/0/APP_FOR`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setapp_forItems(updatedata);

                setBlankFields();

                if (ReduxTempData.length > 0){
                    FindSavedDetails(ReduxTempData[0].temptableid);
                    if(result.allow_update === 2){setAllowUpdateTag(true)};
                    dispatch(emptyTempDataFun());
                }
            }

        }
        loadData();
    }, []);

    useEffect(() => {
        setBlankFields();
    }, [ReduxGlobalData[0]]);    

    function FindSavedDetails(p_TableRecordID) {        

        const getAllTableRecordFunction = async () => {

            const v_token = Cookies.get('token');            

            response = await axios.get(`url_fee_master_view/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/1/${p_TableRecordID}`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            
            updatedata = await response.data.v_out_data;
            
            // console.log("In Fee Master >> ", updatedata)

            const returnVal = AuthenticationCheckDetailFun(updatedata, 'READ');
            if (!returnVal){return};

            updatedata = await response.data.v_out_data.v_data;

            setTableRecordID(p_TableRecordID); 
            
            setboard(updatedata[0].board);
            setstudent_type(updatedata[0].student_type);
            setmedium(updatedata[0].medium);
            setstream(updatedata[0].stream);
            setfee_categ(updatedata[0].fee_categ); 
            setpay_term(updatedata[0].pay_term);   
            setst_class(updatedata[0].st_class);   

            setboard_descr(updatedata[0].board_descr);
            setstudent_type_descr(updatedata[0].student_type_descr);
            setmedium_descr(updatedata[0].medium_descr);
            setstream_descr(updatedata[0].stream_descr);
            setfee_categ_descr(updatedata[0].fee_categ_descr); 
            setpay_term_descr(updatedata[0].pay_term_descr);   
            setst_class_descr(updatedata[0].st_class_descr);   

            setnotes(updatedata[0].notes);         

            if (updatedata[0].active_status === 1){
                setdisableSave(false);
                setdisableDelete(false);
            }else
            {
                setdisableSave(true);
                setdisableDelete(true);
            }

            setsaveUpdateTag('UPDATE');

            // show the detail part

            // response = await axios.get(`url_fee_detail_view/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}

            response = await axios.get(`url_fee_detail_view/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}
            /${updatedata[0].board}
            /${updatedata[0].st_class}
            /${updatedata[0].student_type}
            /${updatedata[0].medium} 
            /${updatedata[0].stream}
            /${updatedata[0].fee_categ}
            /${updatedata[0].pay_term}
            `,
            { headers: { Authorization: `Bearer ${v_token}` } });
            
            updatedata = await response.data.v_out_data.v_data;
            
            setItems(await updatedata);

            CalculateSumFunction(await updatedata);
        }
        getAllTableRecordFunction();
    }
    
    function setBlankFields() {
        setsaveUpdateTag('SAVE');
        setAllowUpdateTag(false);

        setdisableSave(false);
        setdisableDelete(true);        

        setTableRecordID(0);

        setboard(0);
        setst_class(0);
        setstudent_type(0);
        setmedium(0);
        setstream(0);
        setfee_categ(0);
        setpay_term(0);
        setnotes('');
        setSumAmount('');

        setboard_descr('');
        setst_class_descr('');
        setstudent_type_descr('');
        setmedium_descr('');
        setstream_descr('');
        setfee_categ_descr('');
        setpay_term_descr('');
        
        // set the default row        

        let v_temp_list = [];
        for (var i = 0; i <= 20; i++) {
            let v_data = {ischecked : false, t1_id: i * -1 , "id": 0, fee_month: 0, fee_month_descr: '', fee_code: 0, fee_code_descr: '', amount: '', app_for: 0, notes: ""};
            v_temp_list.push(v_data);
        }       

        setTabID(i);
        setItems(v_temp_list);

    }   

    const handleInputValueFun = (name, value) => {

        if (name === 'board_descr') {            
            setboard_descr(value);
            return;            
        }else if (name === 'fee_categ_descr') {
            setfee_categ_descr(value);
            return;            
        }else if (name === 'medium_descr') {
            setmedium_descr(value);
            return;            
        }else if (name === 'pay_term_descr') {
            setpay_term_descr(value);
            return;            
        }else if (name === 'student_type_descr') {
            setstudent_type_descr(value);
            return;            
        }else if (name === 'st_class_descr') {
            setst_class_descr(value);
            return;  
        }else if (name === 'stream_descr') {
            setstream_descr(value);
            return;    
        }else if (name === 'notes') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setnotes(capitalizeWordFun(v_input_result.v_value));            
            return;
        }

    }

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target; 
        
        let v_board = 0, v_student_type = 0, v_medium = 0, v_stream = 0, v_fee_categ = 0, v_pay_term = 0, insupdel_type = '';       
        
        if (value === 'DELETE'){

            if (TableRecordID === 0){
                toast.info(WhenRecordNotFoundToDelete, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            insupdel_type = 'DELETE';
        }else{
            if (TableRecordID === 0){
                insupdel_type = 'INSERT';            
            }else{

                if (TableRecordID === 0){
                    toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return;
                }

                if (userAuthData.allow_update === 1){
                    setAllowUpdateTag(false);
                    insupdel_type = 'UPDATE';
                }else{
                    setAllowUpdateTag(true);
                    return;
                }                  
            }
        }

        if (value != 'DELETE'){
            if (boardItems.length > 0){
                if(board.length === undefined || board.length === 0){
                    toast.error('Entry required for Board !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return; 
                }

                v_board = board;
            }else{            
                v_board = ReduxGlobalData[0].brd;
            }

            if (student_typeItems.length > 0){
                if(student_type.length === undefined || student_type.length === 0){
                    toast.error('Entry required for Student type !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return; 
                }

                v_student_type = student_type;
            }else{            
                v_student_type = ReduxGlobalData[0].st;
            }

            if (mediumItems.length > 0){
                if(medium.length === undefined || medium.length === 0){
                    toast.error('Entry required for Medium !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return; 
                }

                v_medium = medium;            
            }else{
                v_medium = ReduxGlobalData[0].medi;
            }

            if(streamItems.length > 0){
                if(stream.length === undefined || stream.length === 0){
                    toast.error('Entry required for Stream !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return; 
                }

                v_stream = stream;            
            }else{
                v_stream = ReduxGlobalData[0].stem;
            }        

            if (fee_categItems.length > 0){
                if(fee_categ.length === undefined || fee_categ.length === 0){
                    toast.error('Entry required for Fee category !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return;
                }

                v_fee_categ = fee_categ;            
            }else{
                v_fee_categ = ReduxGlobalData[0].fcat;
            }

            if (pay_termItems.length > 0){
                if(pay_term.length === undefined || pay_term.length === 0){
                    toast.error('Entry required for Payment term !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return; 
                }
                        
                v_pay_term = pay_term;
            }else{
                v_pay_term = ReduxGlobalData[0].ptm;
            }

            if(st_class.length === undefined || st_class.length === 0){
                toast.error('Entry required for Student class !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }
        }

        // to filter data where amount is not entered then save

        const filteredData = items.filter(item => {return item.amount != '';});

        const data = {
            id: TableRecordID,
            insupdel_type,           
            under_comp_branch_id : ReduxGlobalData[0].br,
            fin_year : ReduxGlobalData[0].fy,           
            board : v_board,
            st_class,
            student_type : v_student_type,
            medium : v_medium,
            stream : v_stream,
            fee_categ : v_fee_categ,
            pay_term : v_pay_term,
            notes,            
            items: JSON.stringify(filteredData),
        };
        
        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }    
    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);        

        if (p_data.ModelYesNoAnswer === 'YES'){
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async(p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final:p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final:p_last_updel_notes_final,
        }        

        const v_token = Cookies.get('token');

        response = await axios.post(`${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });
        
        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }
    
    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }     

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then           

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                    
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            setNewRecordIDFunction(updatedata.TabID);      
            
            if (updatedata.transType === 'INSERT'){
                setdisableDelete(false);             
                setsaveUpdateTag('UPDATE');
                v_toast_msg = createdMessage;

                getSavedDetailFunction();
            }else if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;

                getSavedDetailFunction();
            }else if (updatedata.transType === 'DELETE'){
                setdisableSave(true);
                setdisableDelete(true);   
                v_toast_msg = deletedMessage;
            }

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }

    const setNewRecordIDFunction = async(TabID) => {
        setTableRecordID(await TabID);
    }

    const getSavedDetailFunction = async () => {        

        let v_board = 0, v_student_type = 0, v_medium = 0, v_stream = 0, v_fee_categ = 0, v_pay_term = 0; 

        if (boardItems.length > 0){            
            v_board = board;
        }else{            
            v_board = ReduxGlobalData[0].brd;
        }

        if (student_typeItems.length > 0){
            v_student_type = student_type;
        }else{            
            v_student_type = ReduxGlobalData[0].st;
        }

        if (mediumItems.length > 0){
            v_medium = medium;            
        }else{
            v_medium = ReduxGlobalData[0].medi;
        }

        if(streamItems.length > 0){
            v_stream = stream;            
        }else{
            v_stream = ReduxGlobalData[0].stem;
        }        

        if (fee_categItems.length > 0){
            v_fee_categ = fee_categ;            
        }else{
            v_fee_categ = ReduxGlobalData[0].fcat;
        }

        if (pay_termItems.length > 0){
            v_pay_term = pay_term;
        }else{
            v_pay_term = ReduxGlobalData[0].ptm;
        }

        const v_token = Cookies.get('token');

        response = await axios.get(`url_fee_detail_view/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}
        /${v_board}
        /${st_class}
        /${v_student_type}
        /${v_medium} 
        /${v_stream}
        /${v_fee_categ}
        /${v_pay_term}
        `,
        { headers: { Authorization: `Bearer ${v_token}` } });
        
        updatedata = await response.data.v_out_data.v_data;

        // console.log("updatedata >> ", updatedata)
        
        setItems(updatedata);

        if (userAuthData.allow_update === 1){
            setAllowUpdateTag(false);
        }else{
            setAllowUpdateTag(true);
        }
    }

    const WhenNoDataFoundFunction = (p_data) => {
     
        // console.log("When no data >> ", p_data.selected_items);

        if(p_data.p_calling_fun_type === 'NO_DATA_FOUND'){
            SetDataAfterReturnFromSearchList(p_data);
        }
    }

    const onBlurSetDataFunction = (p_data) => {
        // console.log("On Blur selected_items Data >> ", p_data);

        if(p_data.p_calling_fun_type === 'CALL_WHEN_KEY_PRESS'){
            SetDataAfterReturnFromSearchList(p_data);
        }
    }

    const setPopUpDDFalseFunction = (p_data) => {

        // console.log("Return selected_items Data >> ", p_data.selected_items);
       
        SetDataAfterReturnFromSearchList(p_data);

        setShowPopUp(false);
        setConditionalCSS(false);        
    }

    const SetDataAfterReturnFromSearchList = (p_data) => {
        
        // console.log("Selected_items Data >> ", p_data.SelectedIDArray, p_data.SelectedIDArrayValue);        

        if (p_data.columnType === 'SINGLE_TABLE' || p_data.columnType === 'MULTIPLE_TABLE'){           

            const list = [...items];

            if (selectedColumn === 'FEE_DETAIL_FEE_MONTH_DESCR'){
                list[selectedrow]['fee_month'] = p_data.SelectedIDArray;
                list[selectedrow]['fee_month_descr'] = p_data.SelectedIDArrayValue; 

            }else if (selectedColumn === 'FEE_DETAIL_FEE_CODE_DESCR'){
                list[selectedrow]['fee_code'] = p_data.SelectedIDArray;
                list[selectedrow]['fee_code_descr'] = p_data.SelectedIDArrayValue; 

            }
        }

        if (p_data.columnType === 'SINGLE_NOT_TABLE' || p_data.columnType === 'MULTIPLE_NOT_TABLE'){
            if (p_data.selectedColumnName === 'board_descr'){
                setboard(p_data.SelectedIDArray);
                setboard_descr(p_data.SelectedIDArrayValue);
            }else if (p_data.selectedColumnName === 'fee_categ_descr'){
                setfee_categ(p_data.SelectedIDArray);
                setfee_categ_descr(p_data.SelectedIDArrayValue);
            }else if (p_data.selectedColumnName === 'medium_descr'){
                setmedium(p_data.SelectedIDArray);
                setmedium_descr(p_data.SelectedIDArrayValue);
            }else if (p_data.selectedColumnName === 'pay_term_descr'){
                setpay_term(p_data.SelectedIDArray);
                setpay_term_descr(p_data.SelectedIDArrayValue);
            }else if (p_data.selectedColumnName === 'student_type_descr'){
                setstudent_type(p_data.SelectedIDArray);
                setstudent_type_descr(p_data.SelectedIDArrayValue);
            }else if (p_data.selectedColumnName === 'st_class_descr'){
                setst_class(p_data.SelectedIDArray);
                setst_class_descr(p_data.SelectedIDArrayValue);
            }else if (p_data.selectedColumnName === 'stream_descr'){
                setstream(p_data.SelectedIDArray);
                setstream_descr(p_data.SelectedIDArrayValue);
            }            
        }
    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        // console.log("handleInputFocus value >> ", value)

        if (name === 'board_descr'){            
            setShowPopUp(true);
            setSelectedColumn('board_descr');
            return;
        }else if (name === 'fee_categ_descr'){            
            setShowPopUp(true);
            setSelectedColumn('fee_categ_descr');
            return;
        }else if (name === 'medium_descr'){            
            setShowPopUp(true);
            setSelectedColumn('medium_descr');
            return;
        }else if (name === 'pay_term_descr'){          
            setShowPopUp(true);
            setSelectedColumn('pay_term_descr');
            return;
        }else if (name === 'student_type_descr'){            
            setShowPopUp(true);
            setSelectedColumn('student_type_descr');
            return;
        }else if (name === 'st_class_descr'){           
            setShowPopUp(true);
            setSelectedColumn('st_class_descr');
            return;
        }else if (name === 'stream_descr'){           
            setShowPopUp(true);
            setSelectedColumn('stream_descr');
            return;
        }else{
            setShowPopUp(false);
            return;
        }

    };    

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        // console.log('in handleInputValue function')

        if (value.toString().trim().length === 0){

            if (name === 'board_descr'){            
                setboard(0);                                
            }else if (name === 'fee_categ_descr'){            
                setfee_categ(0);                
            }else if (name === 'medium_descr'){            
                setmedium(0);                
            }else if (name === 'pay_term_descr'){          
                setpay_term(0);                
            }else if (name === 'student_type_descr'){            
                setstudent_type(0);                
            }else if (name === 'st_class_descr'){           
                setst_class(0);                
            }else if (name === 'stream_descr'){           
                setstream(0);                
            }
        }

        handleInputValueFun(name, value);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        if (value === 'ADD_NEW_ROW'){
            
            if(AllowUpdateTag){return;}
            
            addItem();
            return;
        }else if (value === 'BTN_NEW'){            
            setBlankFields();
            return;
        }else if (value === 'BTN_PRINT'){
            
            if (TableRecordID === 0) {
                toast.error(whenStudentNotFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            return;
        }else if (value === 'BTN_EXPORT_EXCEL'){
                        
            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        }else if (value === 'BTN_EXPORT_PDF'){
            
            return;
        }else if (value === 'BTN_FIND'){            
            navigate(`${v_findToNavigateName}`); 
            return;
        }else if (value === 'BTN_COPY_TO_BRANCH'){
            return;
        }else if (value === 'BTN_ASSIGN_BRANCH'){
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };
    
    const goCheckDataFunction = (event) => {
        event.preventDefault();      
        
        // console.log("userAuthData >> ", userAuthData)

        // console.log("Board >> ", board);
        // console.log("Fee Category >> ", fee_categ);
        // console.log("Medium >> ", medium);
        // console.log("Payment Term >> ", pay_term);
        // console.log("Student Type >> ", student_type);
        // console.log("Class >> ", st_class);
        // console.log("Stream >> ", stream);

        console.log("Enetered Items >> ", items);
        
        // if(items.length>0){              
        //     var sum=0;
        //     sum = items.reduce((a,x)=>parseInt(a)+parseInt(x.amount),0);
        //     console.log("result is :",(sum));
        // }

        // let v_sum = 0;

        // for (let i = 0; i < items.length; i++) {
        //     if (items[i].amount.toString().trim().length > 0){
        //         v_sum = parseInt(v_sum) + parseInt(items[i].amount);   
        //     }
        // }

        // console.log("final result is :",v_sum);
    }

    // For details entry (entry in table)
    // For details entry (entry in table)
    
    const handlerChangeTableRowFocus = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;

        // console.log("In Focus >> ", i, name, p_col_no)        

        // if (name === 'querySelDesc' || name === 'fee_descr'){

        setSelectedrow(i);
        setSelectedColumn(p_col_name);

        if (p_col_name === 'FEE_DETAIL_FEE_MONTH_DESCR' || p_col_name === 'FEE_DETAIL_FEE_CODE_DESCR'){        
            setShowPopUp(true);
        }else{
            
            setShowPopUp(false);
        }


    };

    const addItem = () => {
        setTabID(tabID + 1);
        setItems([...items, {ischecked : false, t1_id: tabID * -1 , "id": 0, fee_month: 0, fee_month_descr: '', fee_code: 0, fee_code_descr: '', amount: '', app_for: 0, notes: ""}]);
    };

    const handlerChange = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;
        // const list = [...items];        
        // list[i][name] = value;
        // setItems(list);
        // console.log(event.target, " >> ", i);

        let v_value = value;       

        setSelectedrow(i);
        setSelectedColumn(p_col_name);
        const list = [...items];

        if (p_col_name === 'FEE_DETAIL_FEE_MONTH_DESCR' || p_col_name === 'FEE_DETAIL_FEE_CODE_DESCR'){        
            
            if (v_value.toString().trim().length === 0){                

                if (p_col_name === 'FEE_DETAIL_FEE_MONTH_DESCR'){                    
                    list[i]['fee_month'] = 0;                    
                }

                if (p_col_name === 'FEE_DETAIL_FEE_CODE_DESCR'){                    
                    list[i]['fee_code'] = 0;                    
                }

            }

            // console.log("v_value is >> ", v_value.toString().trim().length)

            setShowPopUp(true);
        }else{
            
            setShowPopUp(false);            

            if (name === 'amount'){
                v_input_result = ValidateInput('CHECK_NUMBER_ONLY', v_value, '', 100);
                v_value = v_input_result.v_value;
            }else if (name === 'notes'){                
                v_value = capitalizeWordFun(v_value);
            }
            
        }

        list[i][name] = v_value; 
        setItems(list);

        if (name === 'amount'){
            CalculateSumFunction(list);
        }
    };

    const CalculateSumFunction = (p_items) => {
        let v_sum = 0;

        for (let i = 0; i < p_items.length; i++) {
            if (p_items[i].amount.toString().trim().length > 0){
                v_sum = parseInt(v_sum) + parseInt(p_items[i].amount);   
            }
        }
        setSumAmount(v_sum)
    }

    const onClickTableRowFun = (event, p_id, p_value) => {
        event.preventDefault();

        // console.log("When Click on SHOW_RECORD >> ", saveUpdateTag);        

        if (p_value === 'ADD_NEW_ROW') {

            // console.log("When Row ID >> ", p_id);
            
            if(AllowUpdateTag){return};

            setTabID(tabID + 1);
            let v_data = {ischecked : false, t1_id: tabID * -1 , "id": 0, fee_month: 0, fee_month_descr: '', fee_code: 0, fee_code_descr: '', amount: '', app_for: 0, notes: ""};            
    
            items.splice(p_id + 1, 0, v_data);

        }else{
            ischeckedTick(p_id);
        }

    };

    const ischeckedTick = (p_id) => {

        let v_check_value = true;

        // console.log("In Table Row Click : ", p_id);

        // console.log("In Row Tick IDArray Before >> ", IDArray);

        // const filteredData = items.filter((item) => item.id.includes(p_id));

        const filteredData = items.find(item => {return item.id === p_id});        

        // console.log("In Table Row Click filteredData : ", filteredData.ischecked);

        if (filteredData.ischecked) {
            v_check_value = false;
            const index = IDArray.indexOf(p_id);
            IDArray.splice(index, 1);
            IDArrayValue.splice(index, 1);
        } else {
            v_check_value = true;
            IDArray.push(filteredData.id);
            // IDArrayValue.push(filteredData.st_class);
        }

        for (let i = 0; i < items.length; i++) {
            const checkedvalue = items.map((user) => user.id.toString() === p_id.toString() ? { ...user, ischecked: v_check_value } : user);
            setItems(checkedvalue);
        }

        SelectedValueConvertToStr(IDArray, IDArrayValue);

    }

    const SelectedValueConvertToStr = (p_tempIDList, p_tempValueList) => {

        // console.log("In Convert >> ", p_tempList.length, ' >> ', p_tempList);

        let tempIDArrayStr = '', tempIDArrayValueStr = '';

        for (let i = 0; i < p_tempIDList.length; i++) {
            if (i === 0) {
                tempIDArrayStr = p_tempIDList[i];
                tempIDArrayValueStr = p_tempValueList[i];
            } else {
                tempIDArrayStr = tempIDArrayStr + "," + p_tempIDList[i];
                tempIDArrayValueStr = tempIDArrayValueStr + "," + p_tempValueList[i];
            }
        }

        setIDArray(p_tempIDList);
        setIDArrayValue(p_tempValueList);
    }

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        if(AllowUpdateTag){return};        

        let tempListID = [];        

        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, ischecked: checked } });
            setItems(checkedvalue);

            setIDArray([]);            

            if (checked) {
                for (let i = 0; i < items.length; i++) {
                    tempListID.push(items[i].id);
                    // tempListValue.push(items[i].st_class);
                }
            }

            setIDArray(tempListID);
        }
        else {                     

            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, ischecked: checked } : user);
            setItems(checkedvalue);

            setIDArray([]);

            for (let i = 0; i < checkedvalue.length; i++) {

                if(checkedvalue[i].ischecked){
                    tempListID.push(checkedvalue[i].id);
                }
            }

            setIDArray(tempListID);

        }

        // SelectedValueConvertToStr(tempListID, tempListValue);
    }

    const setShowPopUpFalseFunction = (event) => {        
        // setShowPopUp(false);
    };

    const handleInputBlur = (event, i, p_col_name) => {
        // event.preventDefault();
        // const { name, value } = event.target;

        // console.log("handleInputBlur >> ", value);

        // // setSelectedrow(i);
        // // setSelectedColumn(p_col_name);

        // if (p_col_name === 'FEE_DETAIL_FEE_MONTH_DESCR' || p_col_name === 'FEE_DETAIL_FEE_CODE_DESCR'){
            

            
        //     setShowPopUp(true);
        // }else{
            
        //     setShowPopUp(false);
        // }

        // console.log('Input field lost focus');

    };

return (

<>
    {
        ShowConfirmModelSave ? 
        <div className="centerWindow_css">
            <ConfirmModelSave transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

    {
        ShowConfirmModelUpdateDelete ? 
        <div className="centerWindow_css w-[32%]">
            <ConfirmModelUpdateDelete transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} onMouseOut={(e) => setShowPopUpFalseFunction(e)} autoComplete="off">

    <div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

    <div className="form_div_main_css">
        <div className="form_div_inner_css grid grid-cols-7">            

            {boardItems.length > 0 && (
                <div>
                    <label className="label_inner_page_css required_css">Board</label>
                    <input className="input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 0)}
                        autoFocus
                        name="board_descr"
                        id="board_descr"
                        value={board_descr}                                        
                        onChange={handleInputValue}                                                        
                        onFocus={handleInputFocus}                        
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}                          
                    ></input>
                    
                    {
                        ShowPopUp & selectedColumn === 'board_descr' ? 
                            <div className="absolute w-[14%]">                        
                                <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                    onBlurSetDataFunction = {onBlurSetDataFunction}
                                    WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                    items={boardItems}
                                    columnName='board_descr'
                                    columnType='SINGLE_NOT_TABLE'
                                    PropsIDArray={[board]}
                                    PropsIDArrayValueStr={[board_descr]}
                                />
                            </div>
                        :
                        null
                    }                    

                </div>
            )}       

            {fee_categItems.length > 0 && (
                <div>
                    <label className="label_inner_page_css required_css">Fee Category</label>
                    <input className="input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 1)}
                        name="fee_categ_descr"
                        id="fee_categ_descr"
                        value={fee_categ_descr}                        
                        onChange={handleInputValue}                                        
                        onFocus={handleInputFocus}                        
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}                                                 
                    ></input>

                    {
                        ShowPopUp & selectedColumn === 'fee_categ_descr' ?
                            <div className="absolute w-[14%]"> 
                                <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                    onBlurSetDataFunction = {onBlurSetDataFunction}
                                    WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                    items={fee_categItems}
                                    columnName='fee_categ_descr'
                                    columnType='SINGLE_NOT_TABLE'
                                    PropsIDArray={[fee_categ]}
                                    PropsIDArrayValueStr={[fee_categ_descr]}
                                />
                            </div>
                        :
                        null
                    }

                </div>
            )}

            {mediumItems.length > 0 && (
                <div>
                    <label className="label_inner_page_css required_css">Medium</label>
                    <input className="input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 2)}
                        name="medium_descr"
                        id="medium_descr"
                        value={medium_descr}                        
                        onChange={handleInputValue}                                        
                        onFocus={handleInputFocus}                        
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}                                                    
                    ></input>

                    {
                        ShowPopUp & selectedColumn === 'medium_descr' ?
                            <div className="absolute w-[14%]">  
                                <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                    onBlurSetDataFunction = {onBlurSetDataFunction}
                                    WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                    items={mediumItems}
                                    columnName='medium_descr'
                                    columnType='SINGLE_NOT_TABLE'
                                    PropsIDArray={[medium]}
                                    PropsIDArrayValueStr={[medium_descr]}
                                />
                            </div>
                        :
                        null
                    }

                </div>
            )}

            {pay_termItems.length > 0 && (           

                <div>
                    <label className="label_inner_page_css required_css">Payment Term</label>
                    <input className="input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 3)}
                        name="pay_term_descr"
                        id="pay_term_descr"
                        value={pay_term_descr}                        
                        onChange={handleInputValue}                                        
                        onFocus={handleInputFocus}                        
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}                                                    
                    ></input>

                    {
                        ShowPopUp & selectedColumn === 'pay_term_descr' ? 
                            <div className="absolute w-[14%]">
                                <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                    onBlurSetDataFunction = {onBlurSetDataFunction}
                                    WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                    items={pay_termItems}
                                    columnName='pay_term_descr'
                                    columnType='SINGLE_NOT_TABLE'
                                    PropsIDArray={[pay_term]}
                                    PropsIDArrayValueStr={[pay_term_descr]}
                                />
                            </div>
                        :
                        null
                    }

                </div>
            )}

            {student_typeItems.length > 0 && (               

                <div>
                    <label className="label_inner_page_css required_css">Student Type</label>
                    <input className="input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 4)}
                        name="student_type_descr"
                        id="student_type_descr"
                        value={student_type_descr}                        
                        onChange={handleInputValue}                                        
                        onFocus={handleInputFocus}                        
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}                          
                    ></input>

                    {
                        ShowPopUp & selectedColumn === 'student_type_descr' ? 
                            <div className="absolute w-[14%]">
                                <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                    onBlurSetDataFunction = {onBlurSetDataFunction}
                                    WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                    items={student_typeItems}
                                    columnName='student_type_descr'
                                    columnType='SINGLE_NOT_TABLE'
                                    PropsIDArray={[student_type]}
                                    PropsIDArrayValueStr={[student_type_descr]}
                                />
                            </div>
                        :
                        null
                    }

                </div>
            )}

            <div>
                <label className="label_inner_page_css required_css">Class</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 5)}
                    name="st_class_descr"
                    id="st_class_descr"
                    value={st_class_descr}                    
                    onChange={handleInputValue}                                    
                    onFocus={handleInputFocus}
                    disabled = {saveUpdateTag === 'SAVE' ? false : true}
                ></input>

                {
                    ShowPopUp & selectedColumn === 'st_class_descr' ? 
                        <div className="absolute w-[14%]">
                            <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                onBlurSetDataFunction = {onBlurSetDataFunction}
                                WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                items={st_classItems}
                                columnName='st_class_descr'


                                // the below code is for SINGLE_NOT_TABLE, single selection.
                                columnType='SINGLE_NOT_TABLE'
                                PropsIDArray={[st_class]}
                                PropsIDArrayValueStr={[st_class_descr]}

                                // the below code is for MULTIPLE_NOT_TABLE, multiple selection.

                                // columnType='MULTIPLE_NOT_TABLE'
                                // PropsIDArray={st_class}
                                // PropsIDArrayValueStr={st_class_descr}
                            />
                        </div>
                    :
                    null
                }
                
            </div>           

            {streamItems.length > 0 && (
                <div>
                    <label className="label_inner_page_css required_css">Stream</label>
                    <input className="input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 6)}
                        name="stream_descr"
                        id="stream_descr"
                        value={stream_descr}                        
                        onChange={handleInputValue}                                        
                        onFocus={handleInputFocus} 
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}                                 
                    ></input>

                    {
                        ShowPopUp & selectedColumn === 'stream_descr' ? 
                            <div className="absolute w-[14%]">
                                <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                    onBlurSetDataFunction = {onBlurSetDataFunction}
                                    WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                    items={ReduxDDStStreamData[0]}
                                    columnName='stream_descr'
                                    columnType='SINGLE_NOT_TABLE'
                                    PropsIDArray={[stream]}
                                    PropsIDArrayValueStr={[stream_descr]}
                                />
                            </div>
                        :
                        null
                    }
                    
                </div>

            )}            
        </div>

        <div className="form_div_inner_css grid grid-cols-1">         
            <label className="label_inner_page_css">Notes</label>
            <textarea className="input_inner_page_css w-[100%] h-20 resize-none rounded-md"
                ref={(ref) => addInputRef(ref, 7)}                
                name="notes"
                id="notes"
                value={notes}                
                onChange={handleInputValue}                                
                onFocus={handleInputFocus} 
                disabled = {AllowUpdateTag}               
            ></textarea>
        </div>
    </div>

    {/* {
        JSON.stringify(board, fee_categ, medium,  pay_term, student_type, stream, st_class)
    } */}

    <table className="table_main_css">
        <thead className="table_thead_css">            
            <tr className="table_thead_tr_css">                

                <th className="table_th_td_css text-left w-[4%]"></th>
                <th className="table_th_td_css text-left w-[3%]"></th>

                <th className="table_th_td_css w-[5%]">
                    <input className="table_check_inner_page_css"
                        type="checkbox" name="allselect" checked={!items.some((user) => user?.ischecked !== true)}
                        onChange={handleChangeCheckBox}
                        onFocus={handleInputFocus}                        
                    />
                </th>
                
                <th className="table_th_td_css text-left w-[15%]">Month</th>
                <th className="table_th_td_css text-left w-[30%]">Fee Description</th>
                <th className="table_th_td_css text-right w-[10%]">Amount</th>
                <th className="table_th_td_css text-left w-[7%]">App.For</th>
                <th className="table_th_td_css text-left w-[23%]">Notes</th>
                <th className="table_th_td_css text-left w-[3%]"></th>

            </tr>
        </thead>

        <tbody className="table_tbody_css h-[42vh]">
            {
                items?.map((item, index) => (                   

                    <tr className="table_thead_tr_css table_tbody_hover_css" key={index}>

                        <td className="table_th_td_css text-left w-[4%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                            {index + 1}
                        </td> 

                        {
                            (item.t1_id <= 0) ?
                            <td className="table_th_td_css text-center w-[3%]" onClick={() => { setItems(items.filter(a => a.t1_id !== item.t1_id)); }} onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                                <FontAwesomeIcon className="text-blue-600 text-1xl" icon={faTrash}/>
                            </td>                            
                            :  
                            <td className="table_th_td_css text-center w-[3%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                                {/* <FontAwesomeIcon className="text-blue-600 text-1xl" icon={faTrash}/> */}
                            </td>                            
                        }  
                        
                        <td className="table_th_td_css w-[5%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                            <input className="table_check_inner_page_css"                                
                                ref={(ref) => addInputRef(ref, index + 8)}
                                type="checkbox" name={item.t1_id}
                                checked={item?.ischecked || false}
                                onChange={handleChangeCheckBox}                                                                
                            />
                        </td>
                        
                        <td className="table_input_th_td_css text-center w-[15%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, 'FEE_DETAIL_FEE_MONTH_DESCR')}>
                            <input className="table_input_inner_page_css"
                                type="text"
                                ref={(ref) => addInputRef(ref, 9)}
                                name="fee_month_descr"
                                id="fee_month_descr"
                                value={item.fee_month_descr}                                                                 
                                onChange={(e) => handlerChange(e, index, 'FEE_DETAIL_FEE_MONTH_DESCR')}  
                                disabled = {AllowUpdateTag}                                        
                            ></input>

                            {
                                ShowPopUp & selectedrow === index & selectedColumn === 'FEE_DETAIL_FEE_MONTH_DESCR' ? 
                                <div className="absolute w-[15%]">
                                    <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                        onBlurSetDataFunction = {onBlurSetDataFunction}
                                        WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                        items={pay_fee_monthItems}
                                        columnName='queryFeeMonthTabColumn'
                                        columnType='SINGLE_TABLE'
                                        PropsIDArray={[item.fee_month]}
                                        PropsIDArrayValueStr={[item.fee_month_descr]}
                                    />
                                </div>
                                :
                                null
                            }
                        </td>                      

                        <td className="table_input_th_td_css text-center w-[30%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, 'FEE_DETAIL_FEE_CODE_DESCR')}>
                            <input className="table_input_inner_page_css"
                                type="text"
                                ref={(ref) => addInputRef(ref, 10)}
                                name="fee_code_descr"
                                id="fee_code_descr"
                                value={item.fee_code_descr}                                                  

                                // the onChange is used SINGLE_TABLE selection, onChange will not be used for MULTIPLE_TABLE

                                onChange={(e) => handlerChange(e, index, 'FEE_DETAIL_FEE_CODE_DESCR')}

                                disabled = {AllowUpdateTag}
                            ></input>

                            {
                                ShowPopUp & selectedrow === index & selectedColumn === 'FEE_DETAIL_FEE_CODE_DESCR' ? 
                                    <div className="absolute w-[30%]"> 
                                        <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                            onBlurSetDataFunction = {onBlurSetDataFunction}
                                            WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                            items={fee_codeItems}
                                            columnName='queryFeeCodeTabColumn'

                                            // the below is for single selection

                                            columnType='SINGLE_TABLE'
                                            PropsIDArray={[item.fee_code]}
                                            PropsIDArrayValueStr={[item.fee_code_descr]}   
                                            
                                            // the below is for multiple selection

                                            // columnType='MULTIPLE_TABLE'
                                            // PropsIDArray={item.fee_code}
                                            // PropsIDArrayValueStr={item.fee_code_descr}

                                        />
                                    </div>
                                :
                                null
                            }
                        </td>

                        <td className="table_input_th_td_css w-[10%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                            <input className="table_input_inner_page_css text-right"
                                type="text"
                                ref={(ref) => addInputRef(ref, 11)}
                                name="amount"
                                id="amount"
                                value={item.amount}                                                 
                                onChange={(e) => handlerChange(e, index)}
                                disabled = {AllowUpdateTag}                                          
                            ></input>
                        </td>

                        <td className="table_input_th_td_css text-center w-[7%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                            <DropDownTableComponent 
                                ref={(ref) => addInputRef(ref, 14)}
                                paramItems={app_forItems} columnName={'app_for'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.app_for} 
                                saveUpdateTag={AllowUpdateTag}
                            />         
                        </td>

                        <td className="table_input_th_td_css text-left w-[23%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
                            <input className="table_input_inner_page_css"
                                type="text"
                                ref={(ref) => addInputRef(ref, 15)}
                                name="notes"
                                id="notes"
                                value={item.notes}                                                                   
                                onChange={(e) => handlerChange(e, index)}                                
                                disabled = {AllowUpdateTag}
                            ></input>              
                            
                        </td>                        

                        <td className="table_th_td_css text-center w-[3%]" onClick={(e) => onClickTableRowFun(e, index, 'ADD_NEW_ROW', item.isChecked)} onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                            <FontAwesomeIcon className="text-blue-600 text-2xl align-middle" icon={faPlus}/>                            
                        </td>

                    </tr>
                ))
            }
        </tbody>
    </table>

    <nav className="btn_nav_table_sum_inner_css">
    <div className="btn_div_main_css">

        <thead className="table_thead_sum_css">            
            <tr className="table_thead_tr_sum_css">                

                <th className="table_th_td_sum_css text-left w-[4%]"></th>
                <th className="table_th_td_sum_css text-left w-[3%]"></th>
                <th className="table_th_td_sum_css w-[5%]"></th>
                <th className="table_th_td_sum_css text-left w-[15%]"></th>
                <th className="table_th_td_sum_css text-right w-[30%]">Total:</th>
                <th className="table_th_td_sum_css text-right w-[10%]">{SumAmount}</th>
                <th className="table_th_td_sum_css text-left w-[7%]"></th>
                <th className="table_th_td_sum_css text-left w-[23%]"></th>
                <th className="table_th_td_sum_css text-left w-[3%]"></th>

            </tr>
        </thead>

        <div className="btn_div_inner_css">

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_new_css"
                            value = 'ADD_NEW_ROW'
                            onClick={handleButtonValue}>
                            New Row
                        </button>
                    )}
                </div>

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_new_css"
                            value = 'BTN_NEW'
                            onClick={handleButtonValue}>
                            New
                        </button>
                    )}
                </div>

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_save_css"
                            disabled={disableSave}
                            onClick={createRecordFunction}>
                            Save
                        </button>
                    )}
                </div>

                <div>                
                    {userAuthData.allow_delete === 1 && (
                        <button className="btn_delete_css"
                            value = 'DELETE'
                            // disabled={disableDelete}
                            onClick={createRecordFunction}>
                            Delete
                        </button>
                    )}
                </div>               
                    
                {/* <div>
                    {userAuthData.print === 1 && (
                        <button className="btn_print_css"
                            value = 'BTN_PRINT'
                            onClick={handleButtonValue}>
                            Print
                        </button>
                    )}
                </div> */}

                {/* <div>
                    {userAuthData.export_excel === 1 && (
                        <button className="btn_export_excel_css"
                            value = 'BTN_EXPORT_EXCEL'
                            onClick={handleButtonValue}>
                            Export Excel
                        </button>
                    )}
                </div> */}
                
                {/* <div>
                    {userAuthData.export_pdf === 1 && (
                        <button className="btn_export_pdf_css"
                            value = 'BTN_EXPORT_PDF'
                            onClick={handleButtonValue}>
                            Export PDF
                        </button>
                    )}
                </div> */}

                <div>
                    {userAuthData.allow_read === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_FIND'
                            onClick={handleButtonValue}>
                            Find
                        </button>
                    )}
                </div>

                {/* <div>
                    {userAuthData.copy_to_branch === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_COPY_TO_BRANCH'
                            onClick={handleButtonValue}>
                            Copy To Branch
                        </button>
                    )}
                </div> */}

                {/* <div>
                    {userAuthData.assign_branch === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_ASSIGN_BRANCH'
                            onClick={handleButtonValue}>
                            Assigned Branch
                        </button>
                    )}
                </div> */}                

                <div>
                    <button className="btn_back_css"
                        value = 'BTN_BACK'
                        onClick={handleButtonValue}>
                        Back
                    </button>
                </div>

                {/* <div>                
                    <button className="btn_back_css"
                        onClick={goCheckDataFunction}>
                        Check Data
                    </button>
                </div> */}
                
        </div>
        
    </div>
    </nav>

    </form>
:null
}

</>
);};

export default FeeMaster;

{/* <div class="mx-auto max-w-xs">
    <div>
        <div class="relative">
        <input type="text" id="example13" placeholder=" " class="peer block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500" />
        <label for="example13" class="peer-focus:base absolute left-2 top-0 z-10 -translate-y-2 transform bg-white px-1 text-xs text-gray-500 transition-all peer-placeholder-shown:translate-y-3 peer-placeholder-shown:text-sm peer-focus:-translate-y-2 peer-focus:text-xs peer-disabled:bg-transparent">Full name</label>
        </div>
    </div>
</div> */}
