// Table name : comp_group

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import CRUDContext from '../components/CRUDContext';
// import Layout from '../components/Layout';

// import { isAuthenticatedUser } from '../components/isAuthenticated';
import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';

import { useNavigate } from "react-router-dom";

const Cgview = ({ access_token }) => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state);
    const [ShowModelFormDeleteUpdate, setModelFormDeleteUpdate] = useState(false);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);
    const [ipAddess, setIPAddress] = useState('');
    const [updateDelTag, setUpdateDelTag] = useState('');

    const [items, setItems] = useState([]);
    const [ExcelItems, setExcelItems] = useState([]);
    const [filterdata, setFilterdata] = useState([]);

    const [queryGroupCode, setQueryGroupCode] = useState('');
    const [queryGroupName, setQueryGroupName] = useState('');
    const [queryGroupPrintName, setQueryGroupPrintName] = useState('');
    const [queryGroupUnderGroup, setQueryGroupUnderGroup] = useState('');

    const ExcelHeaders = [
        { label: "Code", key: "l_code" },
        { label: "Name", key: "l_name" },
        { label: "Notes", key: "notes" }
    ];

    let list1 = [];
    let checkedinputvalueItems = '';

    // const router = useRouter()

    const navigate = useNavigate();

    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,
        updateRecord,
        deleteRecord,
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenNullViewRecordMessage,
        whenMultipleViewRecordMessage,
    } = useContext(CRUDContext);

    // // ////////////////////////////

    const getAuthroziedUser = async () => {  
        try{
            const token = localStorage.getItem('token');

            const response = await axios.get(`verifyTokenAPI/`,
                  { headers: { Authorization: `Bearer ${token}` } });            
    
            if (response.status === 401 || response.status === 403){
                localStorage.clear();
                navigate('/Login')
            };

        }catch(error){
            localStorage.clear();
            navigate('/Login')
        }
    }    

    useEffect( () => {        
        getAuthroziedUser();    
    }, []);

    // // ////////////////////////////

    useEffect(() => {
        if (error) {
            toast.error(error);
            clearErrors();
            return;
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
            return;
        }

    }, [error, deleted]);
   
    useEffect(() => {
        let response = []
        let updatedata = []

        const getAllTableRecordFunction = async (var_link) => {
            response = await axios.get(`${var_link}/`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data;

            // console.log(updatedata)
            
            setItems(updatedata);
            setFilterdata(updatedata);
            generateExcelData(updatedata);
        }
        getAllTableRecordFunction('url_comp_group_page_view');

    }, [deleted])

    function generateExcelData(data) {
        const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
        setExcelItems(checkedinputvalue);
    }

    // handleChangeCheckBox for checkbox

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;
        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, isChecked: checked } });
            setItems(checkedvalue);
        }
        else {
            const checkedvalue = items.map((user) => user.id.toString() === name ? { ...user, isChecked: checked } : user);
            setItems(checkedvalue);
        }
    }

    const handleDeleteCheckBox = (event) => {
        // event.preventDefault();
        
        // // console.log("checkedinputvalue is : ", checkedinputvalue)        

        // const checkedinputvalue = [];
        // for (let i = 0; i < items.length; i++) {
        //     if (items[i].isChecked === true) {
        //         checkedinputvalue.push(parseInt(items[i].id));
        //     }
        // }

        // if (checkedinputvalue.length === 0) {
        //     toast.error(whenNulldeleteMessage);
        //     clearErrors();
        //     return;
        // }

        // const data = {
        //     id: checkedinputvalue,
        //     last_access_ip : ipAddess,
        //     last_access_by : userData.userData[0].id,    
        // };

        // setQueryCode('');
        // setQueryName('');

        // setUpdateDelTag('DELETE')

        // dispatch(addTempList(data));
        // setModelFormDeleteUpdate(true);
    }

    function showRecordFunction(pk_id_val) {
        setPkID(pk_id_val);
        navigate('/cg')

    }

    const goPrePageFunction = (event) => {
        event.preventDefault();
        setPkID(0);
        navigate('/cg')
    }

    // Start Searching code

    const handleSearchGroupCode = (event) => {
        // event.preventDefault();
        // const getSearch = event.target.value;
        // let searchdata = filterdata;

        // searchdata = searchdata.filter((item) => null ?? item.group_code.toLowerCase().includes(getSearch.toLowerCase()));
        // searchdata = searchdata.filter((item) => null ?? item.group_name.toLowerCase().includes(queryGroupName.toLowerCase()));
        // searchdata = searchdata.filter((item) => null ?? item.print_name.toLowerCase().includes(queryGroupPrintName.toLowerCase()));
        // searchdata = searchdata.filter((item) => null ?? item.under_group_name.toLowerCase().includes(queryGroupUnderGroup.toLowerCase()));

        // setQueryGroupCode(getSearch);
        // setItems(searchdata);
        // generateExcelData(searchdata);
    }

    const handleSearchGroupName = (event) => {
        // event.preventDefault();
        // const getSearch = event.target.value;
        // let searchdata = filterdata;

        // searchdata = searchdata.filter((item) => item.group_code.toLowerCase().includes(queryGroupCode.toLowerCase()));
        // searchdata = searchdata.filter((item) => item.group_name.toLowerCase().includes(getSearch.toLowerCase()));
        // searchdata = searchdata.filter((item) => item.print_name.toLowerCase().includes(queryGroupPrintName.toLowerCase()));
        // searchdata = searchdata.filter((item) => item.under_group_name || ''.toLowerCase().includes(queryGroupUnderGroup.toLowerCase()));

        // setQueryGroupName(getSearch);
        // setItems(searchdata);
        // generateExcelData(searchdata);
    }
   
    const handleSearchPrintName = (event) => {
        // event.preventDefault();
        // const getSearch = event.target.value;
        // let searchdata = filterdata;

        // searchdata = searchdata.filter((item) => item.group_code.toLowerCase().includes(queryGroupCode.toLowerCase()));
        // searchdata = searchdata.filter((item) => item.group_name.toLowerCase().includes(queryGroupName.toLowerCase()));
        // searchdata = searchdata.filter((item) => item.print_name.toLowerCase().includes(getSearch.toLowerCase()));
        // searchdata = searchdata.filter((item) => item.under_group_name || ''.toLowerCase().includes(queryGroupUnderGroup.toLowerCase()));

        // setQueryGroupPrintName(getSearch);
        // setItems(searchdata);
        // generateExcelData(searchdata);
    }

    const handleSearchUnderGroup = (event) => {
        // event.preventDefault();
        // const getSearch = event.target.value;
        // let searchdata = filterdata;

        // searchdata = searchdata.filter((item) => item.group_code.toLowerCase().includes(queryGroupCode.toLowerCase()));
        // searchdata = searchdata.filter((item) => item.group_name.toLowerCase().includes(queryGroupName.toLowerCase()));
        // searchdata = searchdata.filter((item) => item.print_name.toLowerCase().includes(queryGroupPrintName.toLowerCase()));
        // searchdata = searchdata.filter((item) => item.under_group_name || ''.toLowerCase().includes(getSearch.toLowerCase()));

        // setQueryGroupUnderGroup(getSearch);
        // setItems(searchdata);
        // generateExcelData(searchdata);
    }

    return (

        <>

            <div className="pt-PaddingTopAfterMenu max-w-full text-my-main-heading-fontSize bg-my-main-heading-color text-my-main-heading-font-color text-center">Company Group</div>

            <div className="border-my-borderWidth border-my-TableHeadingBorderColor overflow-y-scroll">

                <div className="grid mm:grid-cols-1 md:grid-cols-12 lg:grid-cols-12">

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor                        
                            col-span-2">

                        <input className="py-my-padding-py px-my-padding-px  text-my-fontSize text-TableHeadingFontColor font-my-font-name font-myFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor w-[5%]"
                            type="checkbox" name="allselect" checked={!items.some((user) => user?.isChecked !== true)}
                            onChange={handleChangeCheckBox}
                        />

                        <label className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor                                
                                col-span-2 w-[95%]">
                            Code
                        </label>
                    </div>

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-2">
                        Name
                    </div>

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-3">
                        Print Name
                    </div>

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-2">
                        Under Group
                    </div>

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-3">
                        Notes
                    </div>

                    {/* --------------------------------------------------------------------------- */}
                    {/* Start of Search */}

                    <div className="col-span-2">
                        <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                            w-[6%]"
                            disabled
                        />

                        <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                            col-span-2 w-[94%]"
                            type="text"
                            name="queryGroupCode"
                            value={queryGroupCode}
                            placeholder="Search Code..."
                            onChange={(e) => handleSearchGroupCode(e)}
                            autoComplete="off"
                        />
                    </div>

                    <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                        col-span-2"
                        type="text"
                        name="queryGroupName"
                        value={queryGroupName}
                        placeholder="Search Group Name..."
                        onChange={(e) => handleSearchGroupName(e)}
                        autoComplete="off"
                    />                    

                    <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                        col-span-3"
                        type="text"
                        name="queryGroupPrintName"
                        value={queryGroupPrintName}
                        placeholder="Search Print Name..."
                        onChange={(e) => handleSearchPrintName(e)}
                        autoComplete="off"
                    />

                    <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                        col-span-2"
                        type="text"
                        name="queryGroupUnderGroup"
                        value={queryGroupUnderGroup}
                        placeholder="Search Under Group Name..."
                        onChange={(e) => handleSearchUnderGroup(e)}
                        autoComplete="off"
                    />

                    {/* --------------------------------------------------------------------------- */}
                    {/* End of Search */}

                </div>
            </div>

            <div className="py-my-padding-py px-my-padding-px border-my-borderWidth border-my-border-color h-myTableHeight overflow-y-scroll">
                {
                    items?.map((item) => (                        

                        <div className="grid mm:grid-cols-1 md:grid-cols-12 lg:grid-cols-12" key={item.id}>

                            <div className="col-span-2">
                                <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                    w-[6%]"
                                    type="checkbox" name={item.id} checked={item?.isChecked || false}
                                    onChange={handleChangeCheckBox}
                                />

                                <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                    col-span-2 w-[94%]"
                                    type="text"
                                    name="group_code"
                                    value={item.group_code}
                                    disabled
                                />
                            </div>

                            <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-2">                                                                
                                {item.group_name}
                            </div>    

                            <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-3">                                                                
                                {item.print_name}
                            </div>

                            <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-2">                                                                
                                {item.under_group_name}
                            </div>

                            <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-2">                                                                
                                {item.notes}
                            </div>

                            <button className="bg-AddRowButtonBGColor hover:bg-AddRowButtonHoverColor text-my-fontSize text-AddRowButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={() => showRecordFunction(item.id)}
                            >
                                View
                            </button>

                        </div>
                    ))
                }
            </div>

            <div className="py-my-padding-py px-my-padding-px overflow-y-scroll">
                <div className="grid mm:grid-cols-1 md:grid-cols-12 lg:grid-cols-10 gap-4">

                    <div></div><div></div><div></div>

                    <div>
                        Records: {items.length}
                    </div>                    

                    {/* <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                        onClick={handleDeleteCheckBox}>
                        Delete
                    </button> */}

                    <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                        onClick={goPrePageFunction}>
                        Back
                    </button>                 

                </div>
            </div>

            <ModelFormDeleteUpdate api_type={updateDelTag} api_name={'comp_group_del_Multiple'} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)} access_token={access_token} />

        </>
    );
};

export default Cgview;


