// Form Name is corrrect
// Table name : ac_ledger_branch_wise
// using new css

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';

import Paramtable from "../components/Paramtable";

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

// import { CSVLink } from "react-csv";

const SubjectWiseTeacher = () => {    
    const dispatch = useDispatch();    
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);   
    
    // const router = useRouter(); 

    const navigate = useNavigate();

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);    
    const [defaultdata, setdefaultdata]= useState([]);

    const [apiName, setapiName] = useState('')

    const [st_class, setst_class] = useState('') ;
    const [st_classItems, setst_classItems] = useState([]);
    
    const [st_sec, setst_sec] = useState('') ;
    const [st_secItems, setst_secItems] = useState([]);

    const [emp_master_id, setemp_master_id] = useState('') ;
    const [emp_master_idItems, setemp_master_idItems] = useState([]);

    const [defTeacherid, setdefTeacherid] = useState('') ;

    const [stream, setstream] = useState(0)
    const [streamItems, setstreamItems] = useState([]);

    let list1 = [];
    
    const {
        pk_id,
        loading,                
        error,  
        created,
        updated,
        deleted,    
        createRecord,       
        setPkID,               
        setCreated,
        setUpdated,                                   
        setDeleted,
        clearErrors,

        deletedMessage, 
        createdMessage, 
        updatedMessage,    
        whenNulldeleteMessage,
        whenNoRecordFoundMessage,
    } = useContext(CRUDContext);    

    // ////////////////////////////

    // const getAuthroziedUser = async () => {  
    //     try{
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //               { headers: { Authorization: `Bearer ${token}` } });            
    
    //         if (response.status === 401 || response.status === 403){
    //             localStorage.clear();
    //             navigate('/Login')
    //         };

    //     }catch(error){
    //         localStorage.clear();
    //         navigate('/Login')
    //     }
    // }    

    // useEffect( () => {        
    //     getAuthroziedUser();    
    // }, []);

    // ////////////////////////////

    useEffect(() => { 
        if (error){
          toast.error(error);
          clearErrors();
        }

        if (created){
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
        }

        if (updated){
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
        }

        if (deleted){
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
        }
    }, [error, created, updated, deleted]);    

    useEffect(() =>{       

        let response = []
        let updatedata = []

        const getParamTableRecordFunctionBranch = async (var_link) => {            
            response = await axios.get(`url_param_view_branch_wise_drop_down/${localStorage.getItem('br')}/ST_CLASS`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setst_classItems(updatedata);              

            response = await axios.get(`url_param_view_branch_wise_drop_down/${localStorage.getItem('br')}/ST_SEC`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setst_secItems(updatedata);

            response = await axios.get(`url_drop_down_emp_master_branch_wise_view_fun/${localStorage.getItem('br')}/1`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setemp_master_idItems(updatedata);

            if (localStorage.getItem('stem') === '0'){
                response = await axios.get(`url_param_view_branch_wise_drop_down/${localStorage.getItem('br')}/ST_STREAM`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setstreamItems(updatedata);
            }

        }
        getParamTableRecordFunctionBranch('');              

    }, [])

    useEffect(() =>{
        showRecordFunction();
    }, [created]);
    
    const createRecordFunction = (event) => {
        event.preventDefault();

        let v_stream = 0;

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.error("Entry required for class !");
            clearErrors();
            return;
        }

        if(st_sec === undefined || st_sec.length === undefined || st_sec.length === 0){
            toast.error("Entry required for class section !");
            clearErrors();
            return;
        }

        if(streamItems.length > 0){
            if(stream === undefined || stream.length === undefined || stream.length === 0){
                toast.error("Entry required for Stream !");
                clearErrors();
                return;
            }else{
                v_stream = stream;    
            }       
        }else{
            v_stream = localStorage.getItem('stem');
        }     

        let v_defTeacherid = 0;

        if(defTeacherid === undefined || defTeacherid.length === undefined || defTeacherid.length === 0){
            v_defTeacherid = 0
        }else{
            v_defTeacherid = defTeacherid;
        }        

        const data = {            
            under_comp_branch_id : localStorage.getItem('br'),
            fin_year : localStorage.getItem('fy'),
            st_class,
            st_sec,
            stream:v_stream,
            defTeacherid:v_defTeacherid, 
            toitems: Array(items),
        };

        // console.log("data is : ", data)

        dispatch(emptyCart());
        dispatch(addTempList(data));

        setapiName('url_teacher_subject_branch_wise_new_proc');

        setModelModelFormYesNo(true)
    }

    const handlerChange = (event, i) => {
        event.preventDefault();
        const { name, value } = event.target;

        // const list = [...items];        
        // list[i][name] = value;
        // setItems(list);

        let v_value = value;

        if (name === 'dis_seq' || name === 'exam_dis_seq')
        {
            v_value = event.target.value.replace(/\D/g, "");            
        }

        const list = [...items];
        list[i][name] = v_value;
        setItems(list);
    };

    const showRecordFunction = async () => {
        // console.log("1 is ")        

        let v_stream = 0;

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.error("Entry required for class !");
            clearErrors();
            return;
        }

        if(st_sec === undefined || st_sec.length === undefined || st_sec.length === 0){
            toast.error("Entry required for class section !");
            clearErrors();
            return;
        }

        if(streamItems.length > 0){
            if(stream === undefined || stream.length === undefined || stream.length === 0){
                toast.error("Entry required for Stream !");
                clearErrors();
                return;
            }else{
                v_stream = stream;    
            }       
        }else{
            v_stream = localStorage.getItem('stem');
        }   

        const response = await axios.get(`url_teacher_subject_branch_wise_view_fun/${localStorage.getItem('br')}/${localStorage.getItem('fy')}/${st_class}/${st_sec}/${v_stream}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

        // console.log("2 is ")

        let updatedata = await response.data.v_out_data;                

        // console.log("updatedata is : ", updatedata)

        if (updatedata.length === 0){

            toast(whenNoRecordFoundMessage);
            clearErrors();

            updatedata = structuredClone(defaultdata);
            
            // console.log("When 0 defaultdata is : ", defaultdata)

            setTabID(updatedata.length);
            setItems(updatedata);             
            return;
        }else{                

            // v_tab_id bigint, 
            // v_subject_id bigint, 
            // v_show_in_list boolean, 
            // v_subject_id_name text, 				  
			// v_emp_master_id bigint, 
            // v_notes text

            list1 = updatedata?.map((item, i) => (
                {"id":item.v_tab_id || '', "subject_id" : item.v_subject_id || '', 
                "isChecked" : item.v_show_in_list || '',
                "subject_id_name" : item.v_subject_id_name || '',
                "emp_master_id" : item.v_emp_master_id || '',                    
                "notes" : item.v_notes|| '', 
                "t1_id" : i}
            ));

            setTabID(list1.length);        
            setItems(list1);
        }                        
        
    }

    const handleShowRecordOption = (event) => {
        event.preventDefault();
        showRecordFunction();  
    }
    
    const handlest_classListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setst_class(getId);

        setItems([]);
    }

    const handlest_secListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setst_sec(getId);

        setItems([]);
    }

    const handledefTeacheridOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setdefTeacherid(getId);

        // console.log("Emp id is : ", getId)

        setItems([]);
    }   
    
    const handlestreamListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setstream(getId);

        setItems([])
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();
        setPkID(0);
        navigate('/')
    }

    const goCheckDataFunction = (event) => {
        event.preventDefault();
        
        console.log("Items are : ", items)
    }

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;
        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, isChecked: checked } });
            setItems(checkedvalue);
        }
        else {
            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, isChecked: checked } : user);
            setItems(checkedvalue);
        }
    }

    const handleChangeCheckBox_ShowInExam = (event) => {
        const { name, checked } = event.target;
        
        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, show_in_exam_isChecked: checked } });
            setItems(checkedvalue);
        }
        else {
            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, show_in_exam_isChecked: checked } : user);
            setItems(checkedvalue);
        }
    }

    return (

<>

<div className="pt-PaddingTopAfterMenu pl-1 flex justify-start text-MainHeadingFontSize text-MainHeadingFontColor bg-MainHeadingBGColor">Class Wise Subject Assignment</div>
        <div className="mt-2">            
            
            <label className="max-w-full text-my-font-color font-myFontWeight">Class</label>        
            <select className="w-[6%] col-span-2 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="st_class" value={st_class} onChange={(e) => handlest_classListOption(e)}
                >
                <option></option>
                {
                    st_classItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>

            <label className="max-w-full text-my-font-color font-myFontWeight">Section</label>
            <select className="w-[6%] col-span-2 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="st_sec" value={st_sec} onChange={(e) => handlest_secListOption(e)}
                >
                <option></option>
                {
                    st_secItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>

            {
            streamItems.length > 0 && (
                <>
                    <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Stream</label>
                    <select className="w-[20%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                        name="stream" value={stream} onChange={(e) => handlestreamListOption(e)}
                    >
                        <option></option>
                        {
                            streamItems.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </>
            )}

            <label className="max-w-full text-my-font-color font-myFontWeight">Assign Default Teacher Name</label>
            <select className="col-span-9 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="defTeacherid" value={defTeacherid} onChange={(e) => handledefTeacheridOption(e)}
                >
                <option></option>
                {
                    emp_master_idItems.map((item, index) => (
                        <option key={item.id} value={item.id}>{item.sel_desc}</option>
                    ))
                }
            </select>

            <button className="w-[8%] ml-2 bg-ViewRowBtnBGColor hover:bg-ViewRowBtnHoverColor text-ViewRowBtnFontSize text-ViewRowBtnFontColor font-ViewRowBtnFontName rounded-none"
				onClick={handleShowRecordOption}
			>Show</button>

        </div>

        <div className="mt-2 border-TabBorderWidth border-TabHeadingBorderColor overflow-y-scroll">
            
            <div className="grid lg:grid-cols-12">

                <div className="text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-4">

                    <input className="w-[8%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor"
                             
                        type="checkbox" name="allselect" checked={!items.some((user) => user?.isChecked !== true)}
                        onChange={handleChangeCheckBox}
                    />

                    <label className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight  border-TabHeadingBorderColor bg-TabHeadBGColor
                            col-span-4 w-[92%]">
                        Subject Name
                    </label>
                </div>                       

                <div className="pl-PaddingLeft text-left text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-5">
                    Teacher Name
                </div>
        
                <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-3">
                    Notes
                </div>

            </div>
        </div>

        {/* // v_tab_id bigint, 
        // v_subject_id bigint, 
        // v_show_in_list boolean, 
        // v_subject_id_name text, 				  
        // v_emp_master_id bigint, 
        // v_notes text */}

        {/* // v_tab_id bigint, 
        // v_subject_id bigint, 
        // v_show_in_list boolean, 
        // v_subject_id_name text, 				  
        // v_emp_master_id bigint, 
        // v_notes text */}
        
        <div className="border-TabBorderWidth border-TabBorderColor h-InsertTableHeight overflow-y-scroll">
            {
                items?.map((item, i) => (
                    <div className="grid grid-cols-12" key={item.t1_id}>

                        <div className="col-span-4">
                            <input className="w-[8%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                                "
                                type="checkbox" name={item.t1_id} checked={item?.isChecked || false}
                                onChange={handleChangeCheckBox}
                            />

                            <input className="w-[92%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                                col-span-4"
                                type="text"
                                name="subject_id_name"
                                value={item.subject_id_name}
                                onChange={(e) => handlerChange(e, i)}
                                disabled                                
                            />

                        </div>                        

                        <div className="col-span-5">
                            <Paramtable paramItems={emp_master_idItems} columnName={'emp_master_id'} fun1={(e) => handlerChange(e, i)} columnNameValue={item.emp_master_id} />                        
                        </div>

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-3"
                            type="text"
                            name="notes"
                            value={item.notes}
                            onChange={(e) => handlerChange(e, i)}                                
                        />                      

                    </div>
                ))
            }
        </div>
        
        <div className="mt-2 flex justify-center gap-4">
            <div className="w-[10%] text-center bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                >
                Record Found : {items.length}
            </div>  

            <button className="w-[10%] bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={createRecordFunction}>
                Save
            </button>        
        
            <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={goPrePageFunction}>
                Back
            </button>

            {/* <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={goCheckDataFunction}>
                Check Data
            </button> */}
        
        
        </div>
        
        {/* <ModelFormDeleteUpdate api_type = {'UPDATE'} api_name={'url_fee_code_setting_branch_wise_updel_multiple'} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)} /> */}
       
        {/* <ModelFormYesNo table_name = 'fee_code_setting_branch_wise' api_name = {'url_fee_code_setting_branch_wise_updel_multiple'} isVisible = {ShowModelFormYesNo} onClose = {()=>setModelModelFormYesNo(false)} /> */}

        <ModelFormYesNo table_name = {'fee_code_setting_branch_wise'} api_name = {apiName} isVisible = {ShowModelFormYesNo} onClose = {()=>setModelModelFormYesNo(false)} />

</>
);
};

export default SubjectWiseTeacher;
