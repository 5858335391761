import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
// import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import CRUDContext from '../components/CRUDContext';
// import DropDownTableComponent from "../components/DropDownTableComponent";

import SearchListDropdown from "../components/SearchListDropdown";

import moment from 'moment';

import { emptyTempDataFun, emptyGlobalDataFun, setTempDataFun } from '../components/redux/actions';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import Cookies from 'js-cookie';

const AccountVoucher = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';    
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);    
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);      
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);   

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disableSave, setdisableSave] = useState(0);
    const [disableDelete, setdisableDelete] = useState(0);
    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');
    const [AllowUpdateTag, setAllowUpdateTag] = useState(false);

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData); 
    const ReduxTempData = useSelector((state) => state.TempData);
   
    // define drop downs inputs
    
    const [vou_date, setvou_date] = useState(0);
    const [vou_type_id, setvou_type_id] = useState(0);
    const [vou_type_id_descr, setvou_type_id_descr] = useState('');
    const [vou_no, setvou_no] = useState('');   
    
    const [ac_led_id_1, setac_led_id_1] = useState([]);
    const [ac_led_id_1_descr, setac_led_id_1_descr] = useState('');
    const [ac_led_drcr_1, setac_led_drcr_1] = useState([]);    
        
    // When we will use Multiple selection in Drop Down

    // const [st_class_multiple, setst_class_multiple] = useState([]);
    // const [st_class_descr, setst_class_descr] = useState([]);

    // define drop downs items
 
    const [vou_type_idItems, setvou_type_idItems] = useState([]);
    const [ac_led_idItems, setac_led_idItems] = useState([]);
    
    // define other input
    
    const [ledger_name_label, setledger_name_label] = useState('A/c Ledger');
    const [drcr_label, setdrcr_label] = useState(''); 
    const [notes, setnotes] = useState();

    let v_input_result, v_active_status = 1, v_null_parameter = 'NA';

    // define variable for tables entry (master detail form)    

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);

    const [ShowPopUp, setShowPopUp] = useState(false);
    const [selectedrow, setSelectedrow] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(false);

    const [IDArray, setIDArray] = useState([]);
    const [IDArrayValue, setIDArrayValue] = useState([]);    
    
    const [SumAmount, setSumAmount] = useState(0);
    
    // define standard errors and entry required messages

    // const [errors_message_adm_no, setErrors_message_adm_no] = useState('');
    // const [errors_adm_no, setErrors_adm_no] = useState(false);

    // define form name and navigation details

    const v_urlSaveUpdateDelete = '22_url_ac_ledger_trans_master_new_proc';
    const v_backToNavigateName = '/Dashboard';
    const v_findToNavigateName = '/Vmview';
    const v_main_form_heading = 'Account Voucher Entry !';
    const v_frm_no = 22;

    const {
        deletedMessage,
        createdMessage,
        updatedMessage,        
        whenStudentNotFoundMessage,                     
        WhenRecordNotFoundToDelete,
        WhenRecordNotFoundToUpdate,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);          

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code

                setv_open_frm(true);                

                setdisableSave(false);
                setdisableDelete(true);

                // console.log("Update allow_update >> ", result.allow_update)

                const v_token = Cookies.get('token');

                let v_list_code = 'AC_VOU_TYPE';

                response = await axios.get(`url_fetch_data/32_url_param_view_drop_down_ac_voucher/${v_frm_no}/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
                /${v_list_code}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}        
                `,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data; 
                setvou_type_idItems(await updatedata);               
                
                response = await axios.get(`url_fetch_data/22_url_ac_led_br_wise/${v_frm_no}/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}        
                `,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data; 
                setac_led_idItems(await updatedata);

                setBlankFields();

                if (ReduxTempData.length > 0){
                    FindSavedDetails(ReduxTempData[0].temptableid);
                    if(result.allow_update === 2){setAllowUpdateTag(true)};
                    dispatch(emptyTempDataFun());
                }
            }
        }
        loadData();
    }, []);

    useEffect(() => {
        setBlankFields();
    }, [ReduxGlobalData[0]]);    

    function FindSavedDetails(p_TableRecordID) {    

        const getAllTableRecordFunction = async () => {

            const v_token = Cookies.get('token');           
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/22_vou_master_view/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${p_TableRecordID}`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data;
            const returnVal = AuthenticationCheckDetailFun(updatedata, 'READ');
            if (!returnVal){return};
            updatedata = await response.data.v_out_data.v_data;
            setTableRecordID(p_TableRecordID);

            // console.log("updatedata >> ", updatedata)

            setvou_date(moment(updatedata[0].v_vou_date).format('YYYY-MM-DD'));
            setvou_type_id(updatedata[0].v_vou_type_id); 
            setvou_type_id_descr(updatedata[0].vou_type_id_descr);
            setac_led_id_1(updatedata[0].v_ac_led_id);
            setac_led_id_1_descr(updatedata[0].v_ac_led_name);
            setnotes(updatedata[0].v_notes);
            setvou_no(updatedata[0].v_vou_no);
            setSumAmount(updatedata[0].v_vou_amount);                  

            const getId = updatedata[0].v_vou_type_id;

            // 556	"Payment"
            if (parseInt(getId) === 556){
                setledger_name_label("Cash/Bank");
                setac_led_drcr_1("Cr.")
                setdrcr_label('Dr.')
            }

            // 552	"Contra"
            if (parseInt(getId) === 552){
                setledger_name_label("Deposit/Withdrawal");
                setac_led_drcr_1("Cr.")
                setdrcr_label('Dr.')
            }

            // 557	"Receipt"
            if (parseInt(getId) === 557){
                setledger_name_label("Cash/Bank");
                setac_led_drcr_1("Dr.")
                setdrcr_label('Cr.')
            }

            setdisableSave(true);      

            if (updatedata[0].v_active_status === 1){
                setdisableDelete(false);
            }else
            {
                setdisableDelete(true);
            }

            setsaveUpdateTag('');

            // if (updatedata[0].active_status === 1){
            //     setdisableSave(false);
            //     setdisableDelete(false);
            // }else
            // {
            //     setdisableSave(true);
            //     setdisableDelete(true);
            // }

            // setsaveUpdateTag('UPDATE');

            // show the detail part
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/22_vou_detail_view/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${p_TableRecordID}`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            
            updatedata = await response.data.v_out_data.v_data;

            // console.log("updatedata items >> ", updatedata)
            
            setItems(await updatedata);

            CalculateSumFunction(await updatedata);
        }
        getAllTableRecordFunction();
    }
    
    function setBlankFields() {
        setsaveUpdateTag('SAVE');
        setAllowUpdateTag(false);

        setdisableSave(false);
        setdisableDelete(true);

        setTableRecordID(0);        
        
        setvou_no('');
        setvou_type_id(556);
        setvou_type_id_descr('Payment');
        setledger_name_label("Cash/Bank");
        setac_led_drcr_1("Cr.")
        setdrcr_label('Dr.')
        setac_led_id_1(5512)
        setac_led_id_1_descr("Cash");

        setnotes('');
        setSumAmount('');        

        // Set the default date as today date

        var today = new Date();
        setvou_date(moment(today).format('YYYY-MM-DD'));  
        
        // set the default row 

        let v_temp_list = [];
        for (var i = 0; i <= 10; i++) {
            let v_data = {ischecked : false, t1_id: i * -1 , "id": 0, dr_cr: 0, ac_led_id: '', ac_led_id_descr: '', dr_amt: '', cr_amt: '', notes: ""};
            v_temp_list.push(v_data);
        }       

        setTabID(i);
        setItems(v_temp_list);
    }

    const handleInputValueFun = (name, value) => {

        if (name === 'vou_date') {            
            setvou_date(value);
            return;
        }else if (name === 'vou_type_id_descr') {            
            setvou_type_id_descr(value);
            return;
        }else if (name === 'ac_led_id_1_descr') {
            setac_led_id_1_descr(value);
            return;            
        }else if (name === 'notes') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 500);
            setnotes(capitalizeWordFun(v_input_result.v_value));            
            return;
        }
    }

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target; 
        
        let v_vou_date = null, insupdel_type = '';
        
        if (value === 'DELETE'){

            if (TableRecordID === 0){
                toast.info(WhenRecordNotFoundToDelete, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            insupdel_type = 'DELETE';
        }else{
            if (TableRecordID === 0){
                insupdel_type = 'INSERT';            
            }else{

                if (TableRecordID === 0){
                    toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return;
                }

                if (userAuthData.allow_update === 1){
                    setAllowUpdateTag(false);
                    insupdel_type = 'UPDATE';
                }else{
                    setAllowUpdateTag(true);
                    return;
                }                  
            }
        }

        if (value != 'DELETE'){

            // need to validate the date here
            
            if (vou_date.length === 0){
                v_vou_date = null;
                toast.error('Entry required for Voucher Date !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;                
            }else{
                v_vou_date = vou_date;
            }
    
            if (moment(vou_date, "YYYY-MM-DD", true).isValid())
            {
                v_vou_date = vou_date
            }else{         
                toast.error('Entry required for Voucher Date !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;                
            }

            if(parseInt(vou_type_id) === 0){                
                toast.error('Entry required for Voucher Type !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }

            if(parseInt(ac_led_id_1.length) === 0){
                toast.error('Entry required for Account Ledger !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }
        }

        // to filter data where amount is not entered then save

        const filteredData = items.filter(item => {return item.dr_amt != '';});       

        const data = {
            id: TableRecordID,
            insupdel_type,
            under_comp_group_id : ReduxGlobalData[0].ag,         
            under_comp_branch_id : ReduxGlobalData[0].br,
            fin_year : ReduxGlobalData[0].fy,               
            vou_date, 
            vou_type_id, 
            ac_led_id_1,            
            vou_amount:SumAmount,                               
            notes,       
            show_in_list : 1, 
            active_status : 1,       
            items: JSON.stringify(filteredData),            
        };
        
        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }    
    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);        

        if (p_data.ModelYesNoAnswer === 'YES'){
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async(p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final:p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final:p_last_updel_notes_final,
        }        

        const v_token = Cookies.get('token');

        // response = await axios.post(`${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        // { headers: { Authorization: `Bearer ${v_token}` } });

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });
        
        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }
    
    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }     

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                    
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            // setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);
            
            // console.log("updatedata.TabID.v_data >> ", updatedata.v_data);
            
            if (updatedata.transType === 'INSERT'){
                setdisableDelete(false);             
                setsaveUpdateTag('UPDATE');
                v_toast_msg = createdMessage;

                setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);

                getSavedDetailFunction();
            }else if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;

                getSavedDetailFunction();
            }else if (updatedata.transType === 'DELETE'){
                setdisableSave(true);
                setdisableDelete(true);   
                v_toast_msg = deletedMessage;
                setBlankFields();
            }

            // shiv

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }

    const setNewRecordIDFunction = async(TabID, p_v_data) => {
        
        // console.log("setNewRecordIDFunction >> ", p_v_data[0].p_id, p_v_data[0].p_vou_no);

        setTableRecordID(await p_v_data[0].p_id);
        setvou_no(await p_v_data[0].p_vou_no);
    }

    const getSavedDetailFunction = async () => {        
        
        const v_token = Cookies.get('token');

        // response = await axios.get(`url_fee_detail_view/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}
        // /${v_board}
        // /${st_class}
        // /${v_student_type}
        // /${v_medium} 
        // /${v_stream}
        // /${v_fee_categ}
        // /${v_pay_term}
        // `,
        // { headers: { Authorization: `Bearer ${v_token}` } });
        
        // updatedata = await response.data.v_out_data.v_data;

        // console.log("updatedata >> ", updatedata)
        
        // setItems(updatedata);

        if (userAuthData.allow_update === 1){
            setAllowUpdateTag(false);
        }else{
            setAllowUpdateTag(true);
        }
    }

    const WhenNoDataFoundFunction = (p_data) => {
     
        // console.log("When no data >> ", p_data.selected_items);

        if(p_data.p_calling_fun_type === 'NO_DATA_FOUND'){
            SetDataAfterReturnFromSearchList(p_data);
        }
    }

    const onBlurSetDataFunction = (p_data) => {
        // console.log("On Blur selected_items Data >> ", p_data);

        if(p_data.p_calling_fun_type === 'CALL_WHEN_KEY_PRESS'){
            SetDataAfterReturnFromSearchList(p_data);
        }
    }

    const setPopUpDDFalseFunction = (p_data) => {

        // console.log("Return selected_items Data >> ", p_data.selected_items);
       
        SetDataAfterReturnFromSearchList(p_data);

        setShowPopUp(false);
        setConditionalCSS(false);        
    }

    const SetDataAfterReturnFromSearchList = (p_data) => {
        
        // console.log("Selected_items Data >> ", p_data.SelectedIDArray, p_data.SelectedIDArrayValue);        

        if (p_data.columnType === 'SINGLE_TABLE' || p_data.columnType === 'MULTIPLE_TABLE'){           

            const list = [...items];

            if (selectedColumn === 'ac_led_id_descr'){
                list[selectedrow]['ac_led_id'] = p_data.SelectedIDArray;
                list[selectedrow]['ac_led_id_descr'] = p_data.SelectedIDArrayValue; 

            }
        }

        if (p_data.columnType === 'SINGLE_NOT_TABLE' || p_data.columnType === 'MULTIPLE_NOT_TABLE'){
            if (p_data.selectedColumnName === 'vou_type_id_descr'){
                setvou_type_id(p_data.SelectedIDArray);
                setvou_type_id_descr(p_data.SelectedIDArrayValue);

                // to fill the drop-down
                
                // 556	"Payment"
                // 552	"Contra"
                // 557	"Receipt"

                // 285	"Credit"
                // 286	"Debit"        
                
                // console.log("value : ", value)        

                // 556	"Payment"
                if (parseInt(p_data.SelectedIDArray) === 556){
                    setledger_name_label("Cash/Bank");
                    setac_led_drcr_1("Cr.")
                    setdrcr_label('Dr.')
                    setac_led_id_1(5512)
                }

                // 552	"Contra"
                if (parseInt(p_data.SelectedIDArray) === 552){
                    setledger_name_label("Deposit/Withdrawal");
                    setac_led_drcr_1("Cr.")
                    setdrcr_label('Dr.')
                    setac_led_id_1(5512)
                }

                // 557	"Receipt"
                if (parseInt(p_data.SelectedIDArray) === 557){
                    setledger_name_label("Cash/Bank");
                    setac_led_drcr_1("Dr.")
                    setdrcr_label('Cr.')
                    setac_led_id_1(5512)
                }


            }else if (p_data.selectedColumnName === 'ac_led_id_1_descr'){
                setac_led_id_1(p_data.SelectedIDArray);
                setac_led_id_1_descr(p_data.SelectedIDArrayValue);
            } 

        }        

    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        // console.log("handleInputFocus value >> ", value)

        if (name === 'vou_type_id_descr'){            
            setShowPopUp(true);
            setSelectedColumn('vou_type_id_descr');
            return;
        }else if (name === 'ac_led_id_1_descr'){            
            setShowPopUp(true);
            setSelectedColumn('ac_led_id_1_descr');
            return;
        }else{
            setShowPopUp(false);
            return;
        }
    };

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        // console.log('in handleInputValue function')

        if (value.toString().trim().length === 0){
            if (name === 'vou_type_id_descr'){            
                setvou_type_id(0);                                
            }else if (name === 'ac_led_id_1_descr'){            
                setac_led_id_1(0);                
            }
        }

        handleInputValueFun(name, value);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        if (value === 'ADD_NEW_ROW'){
            
            if(AllowUpdateTag){return;}
            
            addItem();
            return;
        }else if (value === 'BTN_NEW'){            
            setBlankFields();
            return;
        }else if (value === 'BTN_PRINT'){
            
            if (TableRecordID === 0) {
                toast.error("Record not found to be print", {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }            

            const temp_data = {
                temptableid: TableRecordID,
                tempTransType: 'PRINT_AC_VOUCHER',
                vou_type_name:vou_type_id_descr,
                vou_no,
                vou_date:moment(vou_date).format('DD-MM-YYYY'),
                notes,
                vou_amt:SumAmount,
                // setamt_in_word(updatedata[0].amt_in_word)
            }
            
            dispatch(setTempDataFun(temp_data));            

            navigate("/VouPrint");

            return;
        }else if (value === 'BTN_EXPORT_EXCEL'){
                        
            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        }else if (value === 'BTN_EXPORT_PDF'){
            
            return;
        }else if (value === 'BTN_FIND'){            
            navigate(`${v_findToNavigateName}`); 
            return;
        }else if (value === 'BTN_COPY_TO_BRANCH'){
            return;
        }else if (value === 'BTN_ASSIGN_BRANCH'){
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };
    
    const goCheckDataFunction = (event) => {
        event.preventDefault();

        // console.log("TableRecordID >> ", TableRecordID);
        
        // console.log("userAuthData >> ", userAuthData)

        // console.log("Board >> ", board);
        // console.log("Fee Category >> ", fee_categ);
        // console.log("Medium >> ", medium);
        // console.log("Payment Term >> ", pay_term);
        // console.log("Student Type >> ", student_type);
        // console.log("Class >> ", st_class);
        // console.log("Stream >> ", stream);        

        // console.log("vou_type_id >> ", vou_type_id, vou_type_id_descr);
        // console.log("ac_led_drcr_1 >> ", ac_led_id_1, ac_led_id_1_descr);
        
        // if(items.length>0){              
        //     var sum=0;
        //     sum = items.reduce((a,x)=>parseInt(a)+parseInt(x.amount),0);
        //     console.log("result is :",(sum));
        // }

        // let v_sum = 0;

        // for (let i = 0; i < items.length; i++) {
        //     if (items[i].amount.toString().trim().length > 0){
        //         v_sum = parseInt(v_sum) + parseInt(items[i].amount);   
        //     }
        // }

        // console.log("final result is :",v_sum);
    }

    // For details entry (entry in table)
    // For details entry (entry in table)
    
    const handlerChangeTableRowFocus = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;

        // console.log("In Focus >> ", i, name, p_col_no)        

        // if (name === 'querySelDesc' || name === 'fee_descr'){

        setSelectedrow(i);
        setSelectedColumn(p_col_name);

        if (p_col_name === 'ac_led_id_descr'){        
            setShowPopUp(true);
        }else{            
            setShowPopUp(false);
        }

    };

    const addItem = () => {
        setTabID(tabID + 1);
        setItems([...items, {ischecked : false, t1_id: tabID * -1 , "id": 0, dr_cr: 0, ac_led_id: '', ac_led_id_descr: '', dr_amt: '', cr_amt: '', notes: ""}]);
    };

    const handlerChange = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;
        // const list = [...items];        
        // list[i][name] = value;
        // setItems(list);
        // console.log(event.target, " >> ", i);

        let v_value = value;       

        setSelectedrow(i);
        setSelectedColumn(p_col_name);
        const list = [...items];

        if (p_col_name === 'ac_led_id_descr'){        
            
            if (v_value.toString().trim().length === 0){                

                if (p_col_name === 'ac_led_id_descr'){                    
                    list[i]['ac_led_id'] = 0;                    
                }
            }

            // console.log("v_value is >> ", v_value.toString().trim().length)

            setShowPopUp(true);
        }else{
            
            setShowPopUp(false);            

            if (name === 'dr_amt' || name === 'cr_amt'){
                v_input_result = ValidateInput('CHECK_NUMBER_ONLY', v_value, '', 100);
                v_value = v_input_result.v_value;
            }else if (name === 'notes'){                
                v_value = capitalizeWordFun(v_value);
            }
            
        }

        list[i][name] = v_value; 
        setItems(list);

        if (name === 'dr_amt' || name === 'cr_amt'){
            CalculateSumFunction(list);
        }

    };

    const CalculateSumFunction = (p_items) => {
        let v_sum = 0;

        for (let i = 0; i < p_items.length; i++) {
            if (p_items[i].dr_amt.toString().trim().length > 0){
                v_sum = parseInt(v_sum) + parseInt(p_items[i].dr_amt);   
            }
        }
        setSumAmount(v_sum)
    }

    const onClickTableRowFun = (event, p_id, p_value) => {
        event.preventDefault();

        // console.log("When Click on SHOW_RECORD >> ", saveUpdateTag);        

        if (p_value === 'ADD_NEW_ROW') {

            // console.log("When Row ID >> ", p_id);
            
            if(AllowUpdateTag){return};

            setTabID(tabID + 1);
            let v_data = {ischecked : false, t1_id: tabID * -1 , "id": 0, dr_cr: 0, ac_led_id: '', ac_led_id_descr: '', dr_amt: '', cr_amt: '', notes: ""}; 
    
            items.splice(p_id + 1, 0, v_data);

        }else{
            ischeckedTick(p_id);
        }

    };

    const ischeckedTick = (p_id) => {

        let v_check_value = true;

        // console.log("In Table Row Click : ", p_id);

        // console.log("In Row Tick IDArray Before >> ", IDArray);

        // const filteredData = items.filter((item) => item.id.includes(p_id));

        const filteredData = items.find(item => {return item.id === p_id});        

        // console.log("In Table Row Click filteredData : ", filteredData.ischecked);

        if (filteredData.ischecked) {
            v_check_value = false;
            const index = IDArray.indexOf(p_id);
            IDArray.splice(index, 1);
            IDArrayValue.splice(index, 1);
        } else {
            v_check_value = true;
            IDArray.push(filteredData.id);
            // IDArrayValue.push(filteredData.st_class);
        }

        for (let i = 0; i < items.length; i++) {
            const checkedvalue = items.map((user) => user.id.toString() === p_id.toString() ? { ...user, ischecked: v_check_value } : user);
            setItems(checkedvalue);
        }

        SelectedValueConvertToStr(IDArray, IDArrayValue);

    }

    const SelectedValueConvertToStr = (p_tempIDList, p_tempValueList) => {

        // console.log("In Convert >> ", p_tempList.length, ' >> ', p_tempList);

        let tempIDArrayStr = '', tempIDArrayValueStr = '';

        for (let i = 0; i < p_tempIDList.length; i++) {
            if (i === 0) {
                tempIDArrayStr = p_tempIDList[i];
                tempIDArrayValueStr = p_tempValueList[i];
            } else {
                tempIDArrayStr = tempIDArrayStr + "," + p_tempIDList[i];
                tempIDArrayValueStr = tempIDArrayValueStr + "," + p_tempValueList[i];
            }
        }

        setIDArray(p_tempIDList);
        setIDArrayValue(p_tempValueList);
    }

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        if(AllowUpdateTag){return};        

        let tempListID = [];        

        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, ischecked: checked } });
            setItems(checkedvalue);

            setIDArray([]);            

            if (checked) {
                for (let i = 0; i < items.length; i++) {
                    tempListID.push(items[i].id);
                    // tempListValue.push(items[i].st_class);
                }
            }

            setIDArray(tempListID);
        }
        else {                     

            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, ischecked: checked } : user);
            setItems(checkedvalue);

            setIDArray([]);

            for (let i = 0; i < checkedvalue.length; i++) {

                if(checkedvalue[i].ischecked){
                    tempListID.push(checkedvalue[i].id);
                }
            }

            setIDArray(tempListID);

        }

        // SelectedValueConvertToStr(tempListID, tempListValue);
    }

    const setShowPopUpFalseFunction = (event) => {        
        // setShowPopUp(false);
    };

    const handleInputBlur = (event, i, p_col_name) => {
        // event.preventDefault();
        // const { name, value } = event.target;

        // console.log("handleInputBlur >> ", value);

        // // setSelectedrow(i);
        // // setSelectedColumn(p_col_name);

        // if (p_col_name === 'FEE_DETAIL_FEE_MONTH_DESCR' || p_col_name === 'FEE_DETAIL_FEE_CODE_DESCR'){
            

            
        //     setShowPopUp(true);
        // }else{
            
        //     setShowPopUp(false);
        // }

        // console.log('Input field lost focus');

    };

return (

<>
    {
        ShowConfirmModelSave ? 
        <div className="centerWindow_css">
            <ConfirmModelSave transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

    {
        ShowConfirmModelUpdateDelete ? 
        <div className="centerWindow_css w-[32%]">
            <ConfirmModelUpdateDelete transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} onMouseOut={(e) => setShowPopUpFalseFunction(e)} autoComplete="off">

    <div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

    <div className="form_div_main_css">
        <div className="form_div_inner_css grid grid-cols-7">      

            <div>
                <label htmlFor="adm_date" className="label_inner_page_css required_css text-center">Voucher Date</label>
                <input className="input_inner_page_css text-center"
                    ref={(ref) => addInputRef(ref, 7)}    
                    type="date"                            
                    name="vou_date"
                    id="vou_date"
                    value={vou_date}
                    // placeholder="vou_date"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    disabled = {AllowUpdateTag}
                ></input>
            </div>                  

            <div>
                <label className="label_inner_page_css required_css text-center">Voucher Type</label>
                <input className="input_inner_page_css text-center"
                    type="text"
                    ref={(ref) => addInputRef(ref, 5)}
                    name="vou_type_id_descr"
                    id="vou_type_id_descr"
                    value={vou_type_id_descr}                    
                    onChange={handleInputValue}                                    
                    onFocus={handleInputFocus}
                    disabled = {saveUpdateTag === 'SAVE' ? false : true}
                ></input>

                {
                    ShowPopUp & selectedColumn === 'vou_type_id_descr' ? 
                        <div className="absolute w-[14%]">
                            <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                onBlurSetDataFunction = {onBlurSetDataFunction}
                                WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                items={vou_type_idItems}
                                columnName='vou_type_id_descr'

                                // the below code is for SINGLE_NOT_TABLE, single selection.
                                columnType='SINGLE_NOT_TABLE'
                                PropsIDArray={[vou_type_id]}
                                PropsIDArrayValueStr={[vou_type_id_descr]}

                                // the below code is for MULTIPLE_NOT_TABLE, multiple selection.

                                // columnType='MULTIPLE_NOT_TABLE'
                                // PropsIDArray={vou_type_id}
                                // PropsIDArrayValueStr={vou_type_id_descr}
                            />
                        </div>
                    :
                    null
                }
                
            </div>            

            <div className="col-span-4 flex">

                <div className="w-[94%]">
                    <label className="label_inner_page_css required_css">{ledger_name_label}</label>
                    <input className="input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 5)}
                        name="ac_led_id_1_descr"
                        id="ac_led_id_1_descr"
                        value={ac_led_id_1_descr}                    
                        onChange={handleInputValue}                                    
                        onFocus={handleInputFocus}
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}
                    ></input>

                    {
                        ShowPopUp & selectedColumn === 'ac_led_id_1_descr' ? 
                            <div className="absolute w-[53%]">
                                <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                    onBlurSetDataFunction = {onBlurSetDataFunction}
                                    WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                    items={ac_led_idItems}
                                    columnName='ac_led_id_1_descr'


                                    // the below code is for SINGLE_NOT_TABLE, single selection.
                                    columnType='SINGLE_NOT_TABLE'
                                    PropsIDArray={[ac_led_id_1]}
                                    PropsIDArrayValueStr={[ac_led_id_1_descr]}

                                    // the below code is for MULTIPLE_NOT_TABLE, multiple selection.

                                    // columnType='MULTIPLE_NOT_TABLE'
                                    // PropsIDArray={st_class}r No. 
                                    // PropsIDArrayValueStr={ac_led_id_1_descr}
                                />
                            </div>
                        :
                        null
                    }
                    
                </div>

                {/* const [vou_type_idItems, setvou_type_idItems] = useState([]);
                const [ac_led_idItems, setac_led_idItems] = useState([]); */}

                <div className="w-[6%]">                    
                    <label className="input_inner_page_css border-none font-bold ml-2 mt-6">{ac_led_drcr_1}</label>                    
                </div>
            </div>

            <div>
                <label className="label_inner_page_css text-center">Voucher No.</label>
                <div className="input_inner_page_css text-center font-semibold">{vou_no}</div>
            </div>
                       
        </div>

        <div className="form_div_inner_css grid grid-cols-1">         
            <label className="label_inner_page_css">Notes</label>
            <textarea className="input_inner_page_css w-[100%] h-20 resize-none rounded-md"
                ref={(ref) => addInputRef(ref, 7)}                
                name="notes"
                id="notes"
                value={notes}                
                onChange={handleInputValue}                                
                onFocus={handleInputFocus} 
                disabled = {AllowUpdateTag}               
            ></textarea>
        </div>
    </div>

    {/* {
        JSON.stringify(board, fee_categ, medium,  pay_term, student_type, stream, st_class)
    } */}

    <table className="table_main_css">
        <thead className="table_thead_css">            
            <tr className="table_thead_tr_css">                

                <th className="table_th_td_css text-left w-[4%]"></th>
                <th className="table_th_td_css text-left w-[3%]"></th>

                {/* <th className="table_th_td_css w-[5%]">
                    <input className="table_check_inner_page_css"
                        type="checkbox" name="allselect" checked={!items.some((user) => user?.ischecked !== true)}
                        onChange={handleChangeCheckBox}
                        onFocus={handleInputFocus}                        
                    />
                </th> */}
                
                <th className="table_th_td_css text-center w-[6%]">Dr./Cr.</th>
                <th className="table_th_td_css text-left w-[40%]">Account Ledger</th>
                <th className="table_th_td_css text-right w-[12%]">Amount</th>                
                <th className="table_th_td_css text-left w-[32%]">Notes</th>
                <th className="table_th_td_css text-left w-[3%]"></th>
            </tr>
        </thead>

        <tbody className="table_tbody_css h-[42vh]">
        {
            items?.map((item, index) => (                   

            <tr className="table_thead_tr_css table_tbody_hover_css" key={index}>

                <td className="table_th_td_css text-left w-[4%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    {index + 1}
                </td> 

                {
                    (!AllowUpdateTag) ?
                    <td className="table_th_td_css text-center w-[3%]" onClick={() => { setItems(items.filter(a => a.t1_id !== item.t1_id)); }} onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                        <FontAwesomeIcon className="text-blue-600 text-1xl" icon={faTrash}/>
                    </td>                            
                    :  
                    <td className="table_th_td_css text-center w-[3%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                        {/* <FontAwesomeIcon className="text-blue-600 text-1xl" icon={faTrash}/> */}
                    </td>                            
                }  
                
                {/* <td className="table_th_td_css w-[5%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <input className="table_check_inner_page_css"                                
                        ref={(ref) => addInputRef(ref, index + 8)}
                        type="checkbox" name={item.t1_id}
                        checked={item?.ischecked || false}
                        onChange={handleChangeCheckBox}                                                                
                    />
                </td> */}
                
                <td className="table_input_th_td_css w-[6%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <div className="table_input_inner_page_css text-center font-semibold">{drcr_label}</div>
                </td>                                            

                <td className="table_input_th_td_css text-center w-[40%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, 'ac_led_id_descr')}>
                    <input className="table_input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 10)}
                        name="ac_led_id_descr"
                        id="ac_led_id_descr"
                        value={item.ac_led_id_descr}                                                  

                        // the onChange is used SINGLE_TABLE selection, onChange will not be used for MULTIPLE_TABLE

                        onChange={(e) => handlerChange(e, index, 'ac_led_id_descr')}

                        disabled = {AllowUpdateTag}
                    ></input>

                    {
                        ShowPopUp & selectedrow === index & selectedColumn === 'ac_led_id_descr' ? 
                            <div className="absolute w-[40%]"> 
                                <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                    onBlurSetDataFunction = {onBlurSetDataFunction}
                                    WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                    items={ac_led_idItems}
                                    columnName='queryFeeCodeTabColumn'

                                    // the below is for single selection

                                    columnType='SINGLE_TABLE'
                                    PropsIDArray={[item.ac_led_id]}
                                    PropsIDArrayValueStr={[item.ac_led_id_descr]}   
                                    
                                    // the below is for multiple selection

                                    // columnType='MULTIPLE_TABLE'
                                    // PropsIDArray={item.ac_led_id}
                                    // PropsIDArrayValueStr={item.ac_led_id_descr}

                                />
                            </div>
                        :
                        null
                    }
                </td>

                <td className="table_input_th_td_css w-[12%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <input className="table_input_inner_page_css text-right"
                        type="text"
                        ref={(ref) => addInputRef(ref, 11)}
                        name="dr_amt"
                        id="dr_amt"
                        value={item.dr_amt}                                                 
                        onChange={(e) => handlerChange(e, index)}
                        disabled = {AllowUpdateTag}                                          
                    ></input>
                </td>

                <td className="table_input_th_td_css text-left w-[32%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
                    <input className="table_input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 15)}
                        name="notes"
                        id="notes"
                        value={item.notes}                                                                   
                        onChange={(e) => handlerChange(e, index)}                                
                        disabled = {AllowUpdateTag}
                    ></input>              
                    
                </td>                        

                <td className="table_th_td_css text-center w-[3%]" onClick={(e) => onClickTableRowFun(e, index, 'ADD_NEW_ROW', item.isChecked)} onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <FontAwesomeIcon className="text-blue-600 text-2xl align-middle" icon={faPlus}/>                            
                </td>

            </tr>
            ))
        }
        </tbody>
    </table>

    <nav className="btn_nav_table_sum_inner_css">
    <div className="btn_div_main_css">

        <thead className="table_thead_sum_css">            
            <tr className="table_thead_tr_sum_css">                

                <th className="table_th_td_sum_css text-left w-[4%]"></th>
                <th className="table_th_td_sum_css text-left w-[3%]"></th>
                <th className="table_th_td_sum_css w-[5%]"></th>
                <th className="table_th_td_sum_css text-left w-[15%]"></th>
                <th className="table_th_td_sum_css text-right w-[30%]">Total:</th>
                <th className="table_th_td_sum_css text-right w-[10%]">{SumAmount}</th>
                <th className="table_th_td_sum_css text-left w-[7%]"></th>
                <th className="table_th_td_sum_css text-left w-[23%]"></th>
                <th className="table_th_td_sum_css text-left w-[3%]"></th>

            </tr>
        </thead>

        <div className="btn_div_inner_css">

                <div>
                    {!AllowUpdateTag && userAuthData.allow_new === 1 && (
                        <button className="btn_new_css"
                            value = 'ADD_NEW_ROW'
                            onClick={handleButtonValue}>
                            New Row
                        </button>
                    )}
                </div>

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_new_css"
                            value = 'BTN_NEW'
                            onClick={handleButtonValue}>
                            New
                        </button>
                    )}
                </div>

                <div>
                    {!AllowUpdateTag && userAuthData.allow_new === 1 && (
                        <button className="btn_save_css"
                            disabled={disableSave}
                            onClick={createRecordFunction}>
                            Save
                        </button>
                    )}
                </div>
                
                <div>                
                    {userAuthData.allow_delete === 1 && (
                        <button className="btn_delete_css"
                            value = 'DELETE'
                            // disabled={disableDelete}
                            onClick={createRecordFunction}>
                            Delete
                        </button>
                    )}
                </div>
                    
                <div>
                    {userAuthData.print === 1 && (
                        <button className="btn_print_css"
                            value = 'BTN_PRINT'
                            onClick={handleButtonValue}>
                            Print
                        </button>
                    )}
                </div>

                {/* <div>
                    {userAuthData.export_excel === 1 && (
                        <button className="btn_export_excel_css"
                            value = 'BTN_EXPORT_EXCEL'
                            onClick={handleButtonValue}>
                            Export Excel
                        </button>
                    )}
                </div> */}
                
                {/* <div>
                    {userAuthData.export_pdf === 1 && (
                        <button className="btn_export_pdf_css"
                            value = 'BTN_EXPORT_PDF'
                            onClick={handleButtonValue}>
                            Export PDF
                        </button>
                    )}
                </div> */}

                <div>
                    {userAuthData.allow_read === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_FIND'
                            onClick={handleButtonValue}>
                            Find
                        </button>
                    )}
                </div>

                {/* <div>
                    {userAuthData.copy_to_branch === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_COPY_TO_BRANCH'
                            onClick={handleButtonValue}>
                            Copy To Branch
                        </button>
                    )}
                </div> */}

                {/* <div>
                    {userAuthData.assign_branch === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_ASSIGN_BRANCH'
                            onClick={handleButtonValue}>
                            Assigned Branch
                        </button>
                    )}
                </div> */}                

                <div>
                    <button className="btn_back_css"
                        value = 'BTN_BACK'
                        onClick={handleButtonValue}>
                        Back
                    </button>
                </div>

                {/* <div>                
                    <button className="btn_back_css"
                        onClick={goCheckDataFunction}>
                        Check Data
                    </button>
                </div> */}
                
        </div>
        
    </div>
    </nav>

    </form>
:null
}

</>
);};

export default AccountVoucher;

{/* <div class="mx-auto max-w-xs">
    <div>
        <div class="relative">
        <input type="text" id="example13" placeholder=" " class="peer block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500" />
        <label for="example13" class="peer-focus:base absolute left-2 top-0 z-10 -translate-y-2 transform bg-white px-1 text-xs text-gray-500 transition-all peer-placeholder-shown:translate-y-3 peer-placeholder-shown:text-sm peer-focus:-translate-y-2 peer-focus:text-xs peer-disabled:bg-transparent">Full name</label>
        </div>
    </div>
</div> */}
