// Table name : searching functionality for student_master depends on under_branch and fin year wise

import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import axios from 'axios';

import CRUDContext from '../components/CRUDContext';
// import Layout from '../components/Layout';

import { useNavigate } from "react-router-dom";

// import { isAuthenticatedUser } from '../components/isAuthenticated';
// import { addTempList, emptyCart } from '../components/redux/actions';

const Cb = ({ isVisible, onClose, api_name, table_name }) => {
    
    // if (!isVisible) return null;   

    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0);
    const [fy, setfy] = useState(0);  
    
    const [branchName, setBranchName] = useState();
    const [fyName, setfyName] = useState();

    const [board, setboard] = useState();
    const [student_type, setstudent_type] = useState();
    const [stream, setstream] = useState();
    const [medium, setmedium] = useState();
    const [fee_categ, setfee_categ] = useState();
    const [pay_term, setpay_term] = useState();
    const [def_pay_mode, setdef_pay_mode] = useState(0);   

    const [param_st_fee_coll_group_id, setparam_st_fee_coll_group_id] = useState();

    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);
    const [fyItems, setfyItems] = useState([]);

    let list1 = [];

    // const router = useRouter()

    const navigate = useNavigate();

    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        viewRecord,
        setviewRecord,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenPageRefreshMessage
    } = useContext(CRUDContext);


    useEffect(() => {

        let response = []
        let updatedata = []       

        // to fill the drop-down        

        const getTableRecordFunctionBranch = async (var_link) => {
            response = await axios.get(`${var_link}/${localStorage.getItem('id')}`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_comp_branchItems(updatedata);

            response = await axios.get(`url_param_view_comp_group_branch_wise_drop_down/${localStorage.getItem('ag')}/FY`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setfyItems(updatedata);
        }
        getTableRecordFunctionBranch('url_user_wise_branch_view_drop_down');      
    
    }, []);

    const createRecordFunction = (event) => {
        event.preventDefault();

        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.error("Entry required for Branch Name !");
            clearErrors();
            return;
        }

        if(fy === undefined || fy.length === undefined || fy.length === 0){
            toast.error("Entry required for academic year !");
            clearErrors();
            return;
        }

        const getAllTableRecordFunction= async(var_link)=>{            
            const response = await axios.get(`${var_link}/${under_comp_branch_id}/${fy}`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            const updatedata = await response.data.v_out_data

            // console.log("updatedata default : ", updatedata)

            if (updatedata.length > 0)
            {   
                setBranchName(updatedata[0].v_branchname);
                setfyName(updatedata[0].v_fyname); 
                setboard(updatedata[0].v_board); 
                setstudent_type(updatedata[0].v_student_type); 
                setmedium(updatedata[0].v_medium); 
                setstream(updatedata[0].v_stream); 
                setfee_categ(updatedata[0].v_fee_categ); 
                setpay_term(updatedata[0].v_pay_term);
                setparam_st_fee_coll_group_id(updatedata[0].v_param_st_fee_coll_group_id);
                setdef_pay_mode(updatedata[0].v_def_pay_mode);                               
            }             
        }
        getAllTableRecordFunction('url_set_br_default_fun');
    }
    
    const handleUnderBranchOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;         
        setunder_comp_branch_id(getId);    
    }

    const handleFYOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        setfy(getId);
    }
    
    const goPrePageFunction = (event) => {
        event.preventDefault();        

        localStorage.setItem('br', under_comp_branch_id); // branch_id
        localStorage.setItem('fy', fy); // financial year        

        localStorage.setItem('br_name', branchName); // branch name
        localStorage.setItem('fy_name', fyName); // financial year name

        localStorage.setItem('brd', board); // board
        localStorage.setItem('st', student_type); // student_type
        localStorage.setItem('stem', stream); // stream
        localStorage.setItem('medi', medium); // medium
        localStorage.setItem('fcat', fee_categ); // fee_categ
        localStorage.setItem('ptm', pay_term); // pay_term
        localStorage.setItem('fcg', param_st_fee_coll_group_id); // param_st_fee_coll_group_id from br_wise_fee_group_mat_view
        localStorage.setItem('paym', def_pay_mode); // payment mode

        const v_form_name = localStorage.getItem('fm');
        
        // router.push(`/${v_form_name}`);    
        
        navigate(`/${v_form_name}`);
        
    }
    

return (

    // <div className="justify-center items-center fixed inset-0 bg-my-when-focus-bg-color bg-opacity-100 backdrop-blur-3xl flex"></div>

<>

<div className="mt-FromTopMenuMargin flex justify-center items-center fixed inset-0 bg-my-when-focus-bg-color bg-opacity-100 backdrop-blur-3xl ">
	
    <div className="continer">
        <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
            name="under_comp_branch_id" value={under_comp_branch_id} onChange={(e) => handleUnderBranchOption(e)}
            // onKeyDown={handleEnter} {...props}
            >
            <option></option>
            {
                under_comp_branchItems.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
        </select>

        <select className="mt-6 w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
            name="fy" value={fy} onChange={(e) => handleFYOption(e)}
            // onKeyDown={handleEnter} {...props}
            >
            <option></option>
            {
                fyItems.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
        </select>

        <div className="flex justify-evenly mt-5">

            <button className="w-[48%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BtnFontSize text-BackBtnFontColor font-BtnFontName rounded-none"                
                onClick={createRecordFunction}
            >Save</button>

            <button className="w-[48%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BtnFontSize text-BackBtnFontColor font-BtnFontName rounded-none"
                onClick={goPrePageFunction}
            >Back</button>

        </div>

    </div>

    
    


</div>
</>
);};

export default Cb;
