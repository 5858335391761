// Table name : fee_master and fee_detail

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
// import cb from '../pages/cb';

// import cb from './Cb';

import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

const Fmcopydel = () => {
    const dispatch = useDispatch();
    const [newbtnshowhide, setnewbtnshowhide] = useState(1);
    const [savebtnshowhide, setsavebtnshowhide] = useState(1);
    const [deletebtnshowhide, setdeletebtnshowhide] = useState(1);
    const [printbtnshowhide, setprintbtnshowhide] = useState(1);
    const [exportexcelbtnshowhide, setexportexcelbtnshowhide] = useState(1);
    const [exportpdfbtnshowhide, setexportpdfbtnshowhide] = useState(1);
    const [findbtnshowhide, setfindbtnshowhide] = useState(1);

    const [disableSave, setdisableSave] = useState(0);
    const [disableDelete, setdisableDelete] = useState(0);

    const [ShowModelFormDeleteUpdate, setModelFormDeleteUpdate] = useState(false);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);    
    const [updateDelTag, setUpdateDelTag] = useState('');
    const [updateDelapiName, setUpdateDelapiName] = useState('');

    const [apiName, setapiName] = useState('');
    
    // const router = useRouter();

    const navigate = useNavigate();

    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);

    const [board, setboard] = useState(0)
    const [student_type, setstudent_type] = useState(0)
    const [medium, setmedium] = useState(0)
    const [stream, setstream] = useState(0)
    const [fee_categ, setfee_categ] = useState(0)
    const [pay_term, setpay_term] = useState(0)
    const [st_class, setst_class] = useState(0)
    const [notes, setnotes] = useState();


    const [copyboard, setcopyboard] = useState(0)
    const [copystudent_type, setcopystudent_type] = useState(0)
    const [copymedium, setcopymedium] = useState(0)
    const [copystream, setcopystream] = useState(0)
    const [copyfee_categ, setcopyfee_categ] = useState(0)
    const [copypay_term, setcopypay_term] = useState(0)
    const [copyst_class, setcopyst_class] = useState(0)

    const [show_in_list, setShow_in_list] = useState(1)

    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);
    const [boardItems, setboardItems] = useState([]);
    const [student_typeItems, setstudent_typeItems] = useState([]);
    const [mediumItems, setmediumItems] = useState([]);
    const [streamItems, setstreamItems] = useState([]);
    const [fee_categItems, setfee_categItems] = useState([]);
    const [pay_termItems, setpay_termItems] = useState([]);
    const [st_classItems, setst_classItems] = useState([]);

    let list1 = [];

    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        viewRecord,
        setviewRecord,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenPageRefreshMessage
    } = useContext(CRUDContext);

    // ////////////////////////////

    const getAuthroziedUser = async () => {
        try {
            const token = localStorage.getItem('token');

            const response = await axios.get(`verifyTokenAPI/`,
                { headers: { Authorization: `Bearer ${token}` } });

            if (response.status === 401 || response.status === 403) {
                localStorage.clear();
                // router.push('/Login')
                navigate("/Login");
            };

        } catch (error) {
            localStorage.clear();
            // router.push('/Login')
            navigate("/Login");
        }
    }

    useEffect(() => {
        getAuthroziedUser();
    }, []);

    // ////////////////////////////

    useEffect(() => {

        setnewbtnshowhide(1);
        setsavebtnshowhide(1);
        setdeletebtnshowhide(1);
        setprintbtnshowhide(2);
        setexportexcelbtnshowhide(2);
        setexportpdfbtnshowhide(2);
        setfindbtnshowhide(1);

        setdisableSave(false);
        setdisableDelete(true);        

        // to fill the drop-down

        let response = []
        let updatedata = []

        // to fill the drop-down  

        const getParamTableRecordFunctionBranch = async (var_link) => {

            // localStorage.setItem('brd', board); // board
            // localStorage.setItem('st', student_type); // student_type
            // localStorage.setItem('stem', stream); // stream
            // localStorage.setItem('medi', medium); // medium
            // localStorage.setItem('fcat', fee_categ); // fee_categ
            // localStorage.setItem('ptm', pay_term); // pay_term            
           
            if (localStorage.getItem('brd') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/BOARD`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setboardItems(updatedata);
            }

            if (localStorage.getItem('st') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/ST_TYPE`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setstudent_typeItems(updatedata);
            }

            if (localStorage.getItem('medi') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/MEDIUM`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setmediumItems(updatedata);
            }

            if (localStorage.getItem('stem') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/ST_STREAM`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setstreamItems(updatedata);
            }

            if (localStorage.getItem('fcat') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/FEE_CATEG`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setfee_categItems(updatedata);
            }

            if (localStorage.getItem('ptm') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/FEE_PAY_TERM`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setpay_termItems(updatedata);
            }

            response = await axios.get(`${var_link}/${localStorage.getItem('br')}/ST_CLASS`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setst_classItems(updatedata);
        }
        getParamTableRecordFunctionBranch('url_param_view_branch_wise_drop_down');        

    }, []);

    useEffect(() => {
        if (error) {
            toast.error(error);
            clearErrors();
        }

        if (created) {
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
            setdisableDelete(false);
            setviewRecord(true);

            setsaveUpdateTag('UPDATE');
        }

        if (updated) {
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
            setviewRecord(true);
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
            setviewRecord(true);

            setdisableSave(true);
            setdisableDelete(true);
        }
    }, [error, created, updated, deleted]);

    const handleDeleteUpdateFunction = (event) => {
        event.preventDefault();

        if (pk_id === 0) {
            toast.info("Record not found !")
            clearErrors();
            return;
        }

        const data = {
            id: pk_id,
        };

        setUpdateDelTag('DELETE')
        setUpdateDelapiName('url_fee_master_detail_updel_fee_master_fee_detail')

        dispatch(emptyCart());
        dispatch(addTempList(data));
        setModelFormDeleteUpdate(true);
    }

    const FindInTablePage = (event) => {
        event.preventDefault();
        setviewRecord(false);
        // router.push('/fm_view')
        navigate("/fm_view");
    }

    const handleboardListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setboard(getId);
    }

    const handlefee_categListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setfee_categ(getId);
    }

    const handlepay_termOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setpay_term(getId);
    }

    const handlestudent_typeListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setstudent_type(getId);
    }

    const handlemediumListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setmedium(getId);
    }

    const handlest_classListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setst_class(getId);
    }

    const handlestreamListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setstream(getId);
    }

    // start copy to function

    const handlecopyboardListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setcopyboard(getId);
    }

    const handlecopyfee_categListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setcopyfee_categ(getId);
    }

    const handlecopypay_termOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setcopypay_term(getId);
    }

    const handlecopystudent_typeListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setcopystudent_type(getId);
    }

    const handlecopymediumListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setcopymedium(getId);
    }

    const handlestcopy_classListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setcopyst_class(getId);
    }

    const handlecopystreamListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setcopystream(getId);
    }

    // end copy to function

    const handleCopyToRecordOption = (event) => {
        event.preventDefault();
        
        // if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
        //     toast.error("Entry required for Under Branch !");
        //     clearErrors();
        //     return;
        // }

        // if(copy_under_comp_branch_id === undefined || copy_under_comp_branch_id.length === undefined || copy_under_comp_branch_id.length === 0){
        //     toast.error("Entry required for Copy Under Branch !");
        //     clearErrors();
        //     return;
        // }

        // if (under_comp_branch_id === copy_under_comp_branch_id){
        //     toast.error("From Branch and To Branch can not be same !");
        //     clearErrors();
        //     return;
        // }

        let v_board = 0;        
        let v_student_type = 0;
        let v_medium = 0;
        let v_stream = 0;
        let v_fee_categ = 0;
        let v_pay_term = 0;        

        if (boardItems.length > 0){
            if(board === undefined || board.length === undefined || board.length === 0){
                toast.error("Entry required for Board !");
                clearErrors();
                return;
            }else{
                v_board = board;
            }            
        }else{            
            v_board = localStorage.getItem('brd');
        }

        if (student_typeItems.length > 0){
            if(student_type === undefined || student_type.length === undefined || student_type.length === 0){
                toast.error("Entry required for Student Type !");
                clearErrors();
                return;
            }else{
                v_student_type = student_type;
            }
        }else{            
            v_student_type = localStorage.getItem('st');
        }

        if (mediumItems.length > 0){
            if(medium === undefined || medium.length === undefined || medium.length === 0){
                toast.error("Entry required for Medium !");
                clearErrors();
                return;
            }else{
                v_medium = medium; 
            }                       
        }else{
            v_medium = localStorage.getItem('medi');
        }

        if(streamItems.length > 0){
            if(stream === undefined || stream.length === undefined || stream.length === 0){
                toast.error("Entry required for Stream !");
                clearErrors();
                return;
            }else{
                v_stream = stream;    
            }       
        }else{
            v_stream = localStorage.getItem('stem');
        }        

        if (fee_categItems.length > 0){
            if(fee_categ === undefined || fee_categ.length === undefined || fee_categ.length === 0){
                toast.error("Entry required for Fee Category !");
                clearErrors();
                return;
            }else{
                v_fee_categ = fee_categ;    
            }          
        }else{
            v_fee_categ = localStorage.getItem('fcat');
        }

        if (pay_termItems.length > 0){  
            if(pay_term === undefined || pay_term.length === undefined || pay_term.length === 0){
                toast.error("Entry required for Payment Term !");
                clearErrors();
                return;
            }else{
                v_pay_term = pay_term; 
            }
        }else{
            v_pay_term = localStorage.getItem('ptm');
        }


        let v_copyboard = 0;        
        let v_copystudent_type = 0;
        let v_copymedium = 0;
        let v_copystream = 0;
        let v_copyfee_categ = 0;
        let v_copypay_term = 0;    

        if (boardItems.length > 0){
            if(copyboard === undefined || copyboard.length === undefined || copyboard.length === 0){
                toast.error("Entry required for Board !");
                clearErrors();
                return;
            }else{
                v_copyboard = copyboard;
            }            
        }else{            
            v_copyboard = localStorage.getItem('brd');
        }

        if (student_typeItems.length > 0){
            if(copystudent_type === undefined || copystudent_type.length === undefined || copystudent_type.length === 0){
                toast.error("Entry required for Student Type !");
                clearErrors();
                return;
            }else{
                v_copystudent_type = copystudent_type;
            }
        }else{            
            v_copystudent_type = localStorage.getItem('st');
        }

        if (mediumItems.length > 0){
            if(copymedium === undefined || copymedium.length === undefined || copymedium.length === 0){
                toast.error("Entry required for Medium !");
                clearErrors();
                return;
            }else{
                v_copymedium = copymedium; 
            }                       
        }else{
            v_copymedium = localStorage.getItem('medi');
        }

        if(streamItems.length > 0){
            if(copystream === undefined || copystream.length === undefined || copystream.length === 0){
                toast.error("Entry required for Stream !");
                clearErrors();
                return;
            }else{
                v_copystream = copystream;    
            }       
        }else{
            v_copystream = localStorage.getItem('stem');
        }        

        if (fee_categItems.length > 0){
            if(copyfee_categ === undefined || copyfee_categ.length === undefined || copyfee_categ.length === 0){
                toast.error("Entry required for Fee Category !");
                clearErrors();
                return;
            }else{
                v_copyfee_categ = copyfee_categ;    
            }          
        }else{
            v_copyfee_categ = localStorage.getItem('fcat');
        }

        if (pay_termItems.length > 0){  
            if(copypay_term === undefined || copypay_term.length === undefined || copypay_term.length === 0){
                toast.error("Entry required for Payment Term !");
                clearErrors();
                return;
            }else{
                v_copypay_term = copypay_term; 
            }
        }else{
            v_copypay_term = localStorage.getItem('ptm');
        }
        
        const data = {

            

            under_comp_branch_id : localStorage.getItem('br'),
            fin_year : localStorage.getItem('fy'),

            board:v_board,
            fee_categ:v_fee_categ,
            pay_term:v_pay_term,
            student_type:v_student_type,
            medium:v_medium,
            st_class,
            stream:v_stream,

            // let  = 0;        
            // let  = 0;
            // let  = 0;
            // let  = 0;
            // let  = 0;
            // let  = 0;     

            copyboard:v_copyboard,
            copyfee_categ:v_copyfee_categ,
            copypay_term:v_copypay_term,
            copystudent_type:v_copystudent_type,
            copymedium:v_copymedium,
            copyst_class,
            copystream:v_copystream,
            
        };

        // console.log("data is : ", data)

        dispatch(emptyCart());
        dispatch(addTempList(data));      

        // setapiName('copy_to_url_fee_month_setting_branch_wise_new')

        setapiName('copy_to_url_fee_master_class_wise_new')
        
        setModelModelFormYesNo(true);
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();
        setPkID(0);
        // router.push('/')
        navigate("/fm");
    }

    const handleChangeBrachFY = (event) => {
        event.preventDefault();
        setviewRecord(false);

        localStorage.setItem('fm', 'fm'); // form_name

        // router.push('/cb');

        navigate("/cb");
    }

return (

<>

<div className="pt-PaddingTopAfterMenu pl-1 flex justify-start text-MainHeadingFontSize text-MainHeadingFontColor bg-MainHeadingBGColor">Fee Master</div>

<div className="grid lg:grid-cols-5 gap-2">    

    {boardItems.length > 0 && (
        <div className="col-span-1">
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Board</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="board" value={board} onChange={(e) => handleboardListOption(e)}
            >
                <option></option>
                {
                    boardItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
    )}

    {fee_categItems.length > 0 && (
        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Fee Category</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="fee_categ" value={fee_categ} onChange={(e) => handlefee_categListOption(e)}
            >
                <option></option>
                {
                    fee_categItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
    )}

    {pay_termItems.length > 0 && (
        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Payment Term</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="pay_term" value={pay_term} onChange={(e) => handlepay_termOption(e)}
            >
                <option></option>
                {
                    pay_termItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
    )}

    {student_typeItems.length > 0 && (
        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Student Type</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="student_type" value={student_type} onChange={(e) => handlestudent_typeListOption(e)}
            >
                <option></option>
                {
                    student_typeItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
    )}
    
    {mediumItems.length > 0 && (                    
        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Medium</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="medium" value={medium} onChange={(e) => handlemediumListOption(e)}
            >
                <option></option>
                {
                    mediumItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
    )}           

    <div>
        <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Class</label>
        <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
            name="st_class" value={st_class} onChange={(e) => handlest_classListOption(e)}
        >
            <option></option>
            {
                st_classItems.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
        </select>
    </div> 

    {streamItems.length > 0 && (
        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Stream</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="stream" value={stream} onChange={(e) => handlestreamListOption(e)}
            >
                <option></option>
                {
                    streamItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
    )}  
</div>

{/* Start copy to function */}

<div className="pt-PaddingTopAfterMenu mt-5 mb-5 pl-1 flex justify-start text-MainHeadingFontSize text-MainHeadingFontColor bg-MainHeadingBGColor">Copy/ Delete To </div>

<div className="grid lg:grid-cols-5 gap-2">    

    {boardItems.length > 0 && (
        <div className="col-span-1">
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Board</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="copyboard" value={copyboard} onChange={(e) => handlecopyboardListOption(e)}
            >
                <option></option>
                {
                    boardItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
    )}

    {fee_categItems.length > 0 && (
        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Fee Category</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="copyfee_categ" value={copyfee_categ} onChange={(e) => handlecopyfee_categListOption(e)}
            >
                <option></option>
                {
                    fee_categItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
    )}

    {pay_termItems.length > 0 && (
        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Payment Term</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="copypay_term" value={copypay_term} onChange={(e) => handlecopypay_termOption(e)}
            >
                <option></option>
                {
                    pay_termItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
    )}

    {student_typeItems.length > 0 && (
        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Student Type</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="copystudent_type" value={copystudent_type} onChange={(e) => handlecopystudent_typeListOption(e)}
            >
                <option></option>
                {
                    student_typeItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
    )}
    
    {mediumItems.length > 0 && (                    
        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Medium</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="copymedium" value={copymedium} onChange={(e) => handlecopymediumListOption(e)}
            >
                <option></option>
                {
                    mediumItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
    )}           

    <div>
        <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Class</label>
        <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
            name="copyst_class" value={copyst_class} onChange={(e) => handlestcopy_classListOption(e)}
        >
            <option></option>
            {
                st_classItems.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
        </select>
    </div> 

    {streamItems.length > 0 && (
        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Stream</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="copystream" value={copystream} onChange={(e) => handlecopystreamListOption(e)}
            >
                <option></option>
                {
                    streamItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
    )}  
</div>

{/* end copy to function */}



    <div className="py-my-padding-py mt-10 px-my-padding-px overflow-y-scroll">

        <div className="grid grid-cols-4 gap-4">

            {newbtnshowhide === 1 && (
                <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    onClick={handleChangeBrachFY}>
                    Year/Branch
                </button>
            )}
            
            {/* {deletebtnshowhide === 1 && (
                <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    disabled={disableDelete}
                    onClick={handleDeleteUpdateFunction}>
                    Delete
                </button>
            )} */}

            {findbtnshowhide === 1 && (
                <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    onClick={handleCopyToRecordOption}>
                    Copy To
                </button>
            )}

            <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                onClick={goPrePageFunction}>
                Back
            </button>

            {/* {newbtnshowhide === 1 && (
                <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    onClick={CopyFeeFunction}>
                    Copy To
                </button>
            )} */}

        </div>
    </div>

    <ModelFormDeleteUpdate api_type={updateDelTag} api_name={updateDelapiName} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)} />

    <ModelFormYesNo table_name='FEE_MASTER' api_name={'copy_to_url_fee_master_class_wise_new'} isVisible={ShowModelFormYesNo} onClose={() => setModelModelFormYesNo(false)} />
    
</>
);
};

export default Fmcopydel;


