// Table name : Fee Defaulter Month Wise

// pending work

import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { CSVLink} from 'react-csv';

import CRUDContext from '../components/CRUDContext';

// import Layout from '../components/Layout';
// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { toast } from "react-toastify";

// import { isAuthenticatedUser } from './isAuthenticated';

// import { addTempList, emptyCart } from '../components/redux/actions';

const MonthWiseSummary = () => {  

    // const router = useRouter();

    const navigate = useNavigate();

    const conponentPDF = useRef();
    
    // const dispatch = useDispatch();
    // const ReduxData = useSelector((state) => state);
    
    const [newbtnshowhide, setnewbtnshowhide] = useState(1);
    const [savebtnshowhide, setsavebtnshowhide] = useState(1);
    const [deletebtnshowhide, setdeletebtnshowhide] = useState(1);
    const [printbtnshowhide, setprintbtnshowhide] = useState(1);
    const [exportexcelbtnshowhide, setexportexcelbtnshowhide] = useState(1);
    const [exportpdfbtnshowhide, setexportpdfbtnshowhide] = useState(1);
    const [findbtnshowhide, setfindbtnshowhide] = useState(1);

    const [disableSave, setdisableSave] = useState(0);
    const [disableDelete, setdisableDelete] = useState(0);

    const [ShowModelFormDeleteUpdate, setModelFormDeleteUpdate] = useState(false);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);    
    const [updateDelTag, setUpdateDelTag] = useState('');
    const [updateDelapiName, setUpdateDelapiName] = useState('');
    
    // const router = useRouter();

    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);

    const [board, setboard] = useState(0)
    const [student_type, setstudent_type] = useState(0)
    const [medium, setmedium] = useState(0)
    const [stream, setstream] = useState(0)
    const [fee_categ, setfee_categ] = useState(0)
    const [pay_term, setpay_term] = useState(0)    
    const [notes, setnotes] = useState();

    const [show_in_list, setShow_in_list] = useState(1)

    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);
    const [boardItems, setboardItems] = useState([]);
    const [student_typeItems, setstudent_typeItems] = useState([]);
    const [mediumItems, setmediumItems] = useState([]);
    const [streamItems, setstreamItems] = useState([]);
    const [fee_categItems, setfee_categItems] = useState([]);
    const [pay_termItems, setpay_termItems] = useState([]);
    const [st_classItems, setst_classItems] = useState([]);
    
    const [fee_monthSeqItems, setfee_monthSeqItems] = useState([]);

    const [fee_codeItems, setfee_codeItems] = useState([]);
    const [app_forItems, setapp_forItems] = useState([]);    
    
    const [column, setcolumn] = useState([]);        

    const [st_secItems, setst_secItems] = useState([]);    

    const [to_st_class, setto_st_class] = useState(0)

    const [feetypeshowhide, setfeetypeshowhide] = useState(1);

    const [param_st_fee_coll_group_id, setparam_st_fee_coll_group_id] = useState();
    const [param_st_fee_coll_group_idItems, setparam_st_fee_coll_group_idItems] = useState([]);    

    const [last_col_index, setlast_col_index] = useState(7);


    const [report_head_1, setreport_head_1] = useState('') ;
    const [report_head_2, setreport_head_2] = useState('') ;
    const [report_head_3, setreport_head_3] = useState('') ;
    const [from_month, setfrom_month] = useState('') ;
	const [to_month , setto_month] = useState('');
    const [st_class, setst_class] = useState('') ;
    const [st_sec , setst_sec] = useState('');
    const [fee_code, setfee_code] = useState('');
	const [fee_group , setfee_group] = useState('');

    const [from_monthprint, setfrom_monthprint] = useState('') ;
	const [to_monthprint , setto_monthprint] = useState('');
    const [st_classprint, setst_classprint] = useState('') ;
    const [st_secprint , setst_secprint] = useState('');
    const [fee_codeprint, setfee_codeprint] = useState('');
	const [fee_groupprint , setfee_groupprint] = useState('');

    const [st_streamprint, setst_streamprint] = useState('') ;    
    
    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,
        updateRecord,
        deleteRecord,
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenNullViewRecordMessage,
        whenMultipleViewRecordMessage,
    } = useContext(CRUDContext);

    // ////////////////////////////

    // const getAuthroziedUser = async () => {
    //     try {
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //             { headers: { Authorization: `Bearer ${token}` } });

    //         if (response.status === 401 || response.status === 403) {
    //             localStorage.clear();
    //             // router.push('/Login')
    //             navigate("/Login");
    //         };

    //     } catch (error) {
    //         localStorage.clear();
    //         // router.push('/Login')
    //         navigate("/Login");
    //     }
    // }

    // useEffect(() => {
    //     getAuthroziedUser();
    // }, []);

    // ////////////////////////////

    useEffect(() => {

        setnewbtnshowhide(1);
        setsavebtnshowhide(1);
        setdeletebtnshowhide(1);
        setprintbtnshowhide(2);
        setexportexcelbtnshowhide(2);
        setexportpdfbtnshowhide(2);
        setfindbtnshowhide(1);

        setdisableSave(false);
        setdisableDelete(true);        

        // to fill the drop-down

        let response = []
        let updatedata = []

        // to fill the drop-down  

        const getParamTableRecordFunctionBranch = async (var_link) => {

            // localStorage.setItem('brd', board); // board
            // localStorage.setItem('st', student_type); // student_type
            // localStorage.setItem('stem', stream); // stream
            // localStorage.setItem('medi', medium); // medium
            // localStorage.setItem('fcat', fee_categ); // fee_categ
            // localStorage.setItem('ptm', pay_term); // pay_term            
           
            if (localStorage.getItem('brd') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/BOARD`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setboardItems(updatedata);
            }

            if (localStorage.getItem('st') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/ST_TYPE`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setstudent_typeItems(updatedata);
            }

            if (localStorage.getItem('medi') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/MEDIUM`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setmediumItems(updatedata);
            }

            if (localStorage.getItem('stem') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/ST_STREAM`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setstreamItems(updatedata);
            }

            if (localStorage.getItem('fcat') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/FEE_CATEG`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setfee_categItems(updatedata);
            }

            if (localStorage.getItem('ptm') === '0'){
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/FEE_PAY_TERM`,
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setpay_termItems(updatedata);
            }            

            response = await axios.get(`${var_link}/${localStorage.getItem('br')}/FEE_CODE`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setfee_codeItems(updatedata);

            response = await axios.get(`${var_link}/${localStorage.getItem('br')}/APP_FOR`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setapp_forItems(updatedata);

            response = await axios.get(`${var_link}/${localStorage.getItem('br')}/ST_CLASS`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setst_classItems(updatedata);

            response = await axios.get(`${var_link}/${localStorage.getItem('br')}/ST_SEC`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setst_secItems(updatedata);

            response = await axios.get(`url_FeeMonthSeqFun/${localStorage.getItem('br')}`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setfee_monthSeqItems(updatedata);

            // fee_code

            // param_st_fee_coll_group_id

            if (parseInt(localStorage.getItem('fcg')) === 0){
                response = await axios.get(`url_param_st_fee_coll_group_id_fun/${localStorage.getItem('br')}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                updatedata = await response.data.v_out_data
                setparam_st_fee_coll_group_idItems(updatedata);

                // console.log("localStorage.getItem('fcg') === 0")

                setfeetypeshowhide(true);

            }else{

                // console.log("else localStorage.getItem('fcg') === 0")

                setfeetypeshowhide(false);
                setparam_st_fee_coll_group_id(localStorage.getItem('fcg'))
            }

        }
        getParamTableRecordFunctionBranch('url_param_view_branch_wise_drop_down');

        // setBlankFields();

    }, []);


    const handleboardListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setboard(getId);
    }

    const handlefee_categListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setfee_categ(getId);
    }

    const handlepay_termOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setpay_term(getId);
    }

    const handlestudent_typeListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setstudent_type(getId);
    }

    const handlemediumListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setmedium(getId);
    }

    const handlest_classListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setst_class(getId);

        setItems([]);
    }

    const handleFromMonthListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setfrom_month(getId);

        setItems([]);
    }

    const handleToMonthListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setto_month(getId);

        setItems([]);
    }

    const handlestreamListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setstream(getId);

        setItems([]);
    }

    const handlefee_codeListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setfee_code(getId);

        setItems([]);
    }    

    const PrintPDF = useReactToPrint({
        // content: () => conponentPDF.current,
        // documentTitle: "Userdata",
        // onAfterPrint: () => alert("Data saved in PDF")

        content: () => conponentPDF.current,
        documentTitle: "Userdata",
        // pageStyle : "35px"
        // pageStyle : "@page { size: 2.5in 4in }"        
        // pageStyle : "1080px"

        pageStyle : "size: A4 landscape"

        // pageStyle : "size: A4 portrait"        
    });

    const goPrePageFunction = (event) => {
        event.preventDefault();        
        const v_form_name = localStorage.getItem('fm');
        // navigate(`/${v_form_name}`);               

        navigate('/');
    }

    const handleparam_st_fee_coll_group_idOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setparam_st_fee_coll_group_id(getId);

        // setBlankFields();

        setItems([]);
        
    }

    const GenerateReport = (event) => {
        event.preventDefault();        
                
        const getReport = async (var_link) => {   
            
            let v_board = 0;        
            let v_student_type = 0;
            let v_medium = 0;
            let v_stream = 0;
            let v_fee_categ = 0;
            let v_pay_term = 0;    
            let v_fee_code = 0;
            let v_param_st_fee_coll_group_id = 0, v_st_class = 0, v_st_sec = 0;

            if (boardItems.length > 0){
                if(board === undefined || board.length === undefined || board.length === 0){
                    toast.error("Entry required for Board !");
                    clearErrors();
                    return;
                }else{
                    v_board = board;
                }            
            }else{            
                v_board = localStorage.getItem('brd');
            }

            if (student_typeItems.length > 0){
                if(student_type === undefined || student_type.length === undefined || student_type.length === 0){
                    toast.error("Entry required for Student Type !");
                    clearErrors();
                    return;
                }else{
                    v_student_type = student_type;
                }
            }else{            
                v_student_type = localStorage.getItem('st');
            }

            if (mediumItems.length > 0){
                if(medium === undefined || medium.length === undefined || medium.length === 0){
                    toast.error("Entry required for Medium !");
                    clearErrors();
                    return;
                }else{
                    v_medium = medium; 
                }                       
            }else{
                v_medium = localStorage.getItem('medi');
            }

            // if(streamItems.length > 0){
            //     if(stream === undefined || stream.length === undefined || stream.length === 0){
            //         toast.error("Entry required for Stream !");
            //         clearErrors();
            //         return;
            //     }else{
            //         v_stream = stream;    
            //     }       
            // }else{
            //     v_stream = localStorage.getItem('stem');
            // }        

            if (fee_categItems.length > 0){
                if(fee_categ === undefined || fee_categ.length === undefined || fee_categ.length === 0){
                    toast.error("Entry required for Fee Category !");
                    clearErrors();
                    return;
                }else{
                    v_fee_categ = fee_categ;    
                }          
            }else{
                v_fee_categ = localStorage.getItem('fcat');
            }

            if (pay_termItems.length > 0){  
                if(pay_term === undefined || pay_term.length === undefined || pay_term.length === 0){
                    toast.error("Entry required for Payment Term !");
                    clearErrors();
                    return;
                }else{
                    v_pay_term = pay_term; 
                }
            }else{
                v_pay_term = localStorage.getItem('ptm');
            }

            // if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            //     toast.error("Entry required for Student Class !");
            //     clearErrors();
            //     return;
            // }            

            if(from_month === undefined || from_month.length === undefined || from_month.length === 0){
                toast.error("Entry required for From Month !");
                clearErrors();
                return;
            }

            if(to_month === undefined || to_month.length === undefined || to_month.length === 0){
                toast.error("Entry required for To Month !");
                clearErrors();
                return;
            }

            if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
                toast.error("Entry required for class !");
                clearErrors();
                return;
            }

            if(st_sec === undefined || st_sec.length === undefined || st_sec.length === 0){
                toast.error("Entry required for section !");
                clearErrors();
                return;
            }

            if (fee_code === undefined || fee_code.length === undefined || fee_code.length === 0){
                v_fee_code = 0
            }else{
                v_fee_code = fee_code
            }

            if (st_class === undefined || st_class.length === undefined || st_class.length === 0){
                v_st_class = 0
            }else{
                v_st_class = st_class
            }

            if (stream === undefined || stream.length === undefined || stream.length === 0){
                v_stream = 0
            }else{
                v_stream = stream
            }

            if(st_sec === undefined || st_sec.length === undefined || st_sec.length === 0){                
                v_st_sec = 0;
            }else{
                v_st_sec = st_sec;
            }            

            // 

            if (param_st_fee_coll_group_id === undefined || param_st_fee_coll_group_id.length === undefined || param_st_fee_coll_group_id.length === 0){
                v_param_st_fee_coll_group_id = 0
            }else{
                v_param_st_fee_coll_group_id = param_st_fee_coll_group_id
            }

            // console.log("Before Report")            

            // console.log("Fee defaulter heading updatedata : ", updatedata)

            // console.log("Before Detail Report")
            
            // let response = await axios.get(`${var_link}/${localStorage.getItem('br')}/${localStorage.getItem('fy')}
            //     /${v_board}
            //     /${v_student_type}
            //     /${v_medium} 
            //     /${v_stream}
            //     /${v_fee_categ}
            //     /${v_pay_term}
            //     /${v_st_class}
            //     /${v_fee_code}
            //     /${from_month}
            //     /${to_month}
            //     /${v_param_st_fee_coll_group_id}
            //     /${v_st_sec}
            //     `,
            //     { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });


            let response = await axios.get(`${var_link}/${localStorage.getItem('br')}/${localStorage.getItem('fy')}                           
                /${v_param_st_fee_coll_group_id}                
                `,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            // console.log("After Detail Report")
                
            let updatedata = await response.data.v_out_data;                        
            setItems(await updatedata);

            // console.log(updatedata)

            const column1 = Object.keys(await updatedata[0]);

            setcolumn(column1)

            // console.log("column1 is : ", column1.length);

            setlast_col_index(column1.length - 1);

            // ////////////////////////////////////////////////////////////////////////


            response = await axios.get(`url_fee_defaulter_print_format_heading_fun/${localStorage.getItem('br')}/${localStorage.getItem('fy')}
            /${v_board}
            /${v_student_type}
            /${v_medium} 
            /${v_stream}
            /${v_fee_categ}
            /${v_pay_term}
            /${v_st_class}
            /${v_fee_code}
            /${from_month}
            /${to_month}
            /${v_param_st_fee_coll_group_id}
            /${v_st_sec}
            `,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });    
        
            updatedata = await response.data.v_out_data;

            setreport_head_1(updatedata[0].report_head_1)
            setreport_head_2(updatedata[0].report_head_2)
            setreport_head_3(updatedata[0].report_head_3)
            
            setfrom_monthprint(updatedata[0].from_month)
            setto_monthprint(updatedata[0].to_month)
            setst_classprint(updatedata[0].st_class)
            setst_secprint(updatedata[0].st_sec)
            setfee_codeprint(updatedata[0].fee_code)
            setfee_groupprint(updatedata[0].fee_group)
            setst_streamprint(updatedata[0].st_stream)

        }
        // getReport('url_student_list_view_fun');
        getReport('url_MonthWiseSummary_fun');

    }

    // get table heading data
    const ThData =()=>{
    
        return column.map((data, index)=>{            

            // return <td className = "text-right pr-1 border-InputBorderWidth border-InputBorderColor text-InputFontSize text-InputFontColor font-InputFontName"
            //         key={data}>{data}</td>

            if (index === 0){
                return <td className = "w-[5%] font-myFontWeight text-center pr-1 border-InputBorderWidth border-InputBorderColor text-InputFontColor font-InputFontName"
                key={data}>{data}
                </td>
            
            }else if (index === 3){
                return <td className = "w-[7%] font-myFontWeight text-center pr-1 border-InputBorderWidth border-InputBorderColor text-InputFontColor font-InputFontName"
                key={data}>{data}
                </td>
            
            }else if (index > 0 && index < 3){
                return <td className = "w-[14%] font-myFontWeight text-left pr-1 border-InputBorderWidth border-InputBorderColor text-InputFontColor font-InputFontName"
                key={data}>{data}
                </td>
            
            }else{

                return <td className = "font-myFontWeight text-right pr-1 border-InputBorderWidth border-InputBorderColor text-InputFontColor font-InputFontName"
                key={data}>{data}
                </td>

            }

        })
    }

    // get table row data
    const tdData =() =>{

        return items.map((data)=>{

            let v_total = 0;

        return(
            <tr>
                {

                column.map((v_data, index)=>{                    

                    // if (index >= 4 && index < last_col_index && parseFloat(data[v_data]) > 0){                        
                    //     v_total = parseFloat(v_total) + parseFloat(data[v_data])
                    //     // console.log("v_total is : >> ", v_total);
                    // }

                if (index === 0){
                        return <td className = "w-[5%] text-center pr-1 border-InputBorderWidth border-InputBorderColor text-InputFontColor font-InputFontName">
                        {data[v_data]}</td>
                    
                    }else if (index === 3){
                        return <td className = "w-[7%] text-center pr-1 border-InputBorderWidth border-InputBorderColor text-InputFontColor font-InputFontName">
                        {data[v_data]}</td>
                    
                    }else if (index > 0 && index < 3){
                        return <td className = "w-[14%] text-left pr-1 border-InputBorderWidth border-InputBorderColor text-InputFontColor font-InputFontName">
                        {data[v_data]}</td>
                    
                    }else{

                        return <td className = "text-right pr-1 border-InputBorderWidth border-InputBorderColor text-InputFontColor font-InputFontName">
                        {data[v_data]}</td>

                    }

                    // return <td className = "py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName">
                    //     {data[v_data]}</td>
                    
                })
                }
            </tr>
        )
        })
    }

    const handleChangeBrachFY = (event) => {
        event.preventDefault();
        
        localStorage.setItem('fm', 'MonthWiseSummary'); // form_name MonthWiseSummary

        // router.push('/cb');

        navigate("/cb");
    }

    const handlest_secListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setst_sec(getId);

        setItems([]);
    }

    const handlest_toclassListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setto_st_class(getId);
        setItems([])
    } 

return (
<>
    {/* <div className="mt-32 grid justify-center cols-4 gap-4"> */}

    <div className="pt-PaddingReportTopAfterMenu pl-1 flex justify-start text-MainHeadingFontSize text-MainHeadingFontColor bg-MainHeadingBGColor">Month Wise Fee Summary</div>

    <div className="pt-2 grid grid-cols-12 gap-1">

        {feetypeshowhide === true && (
            <div className="col-span-1 h-DropDownHeight">
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Fee Type</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="param_st_fee_coll_group_id" value={param_st_fee_coll_group_id} onChange={(e) => handleparam_st_fee_coll_group_idOption(e)}
                    >
                    <option></option>
                    {
                        param_st_fee_coll_group_idItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>  
        )}    

        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">From Month</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="from_month" value={from_month} onChange={(e) => handleFromMonthListOption(e)}
            >
                <option></option>
                {
                    fee_monthSeqItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>

        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">To Month</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="to_month" value={to_month} onChange={(e) => handleToMonthListOption(e)}
            >
                <option></option>
                {
                    fee_monthSeqItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>        

        {/* ------------------------------------------------------------------------------------ */}

        {boardItems.length > 0 && (
        <div className="col-span-1">
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Board</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="board" value={board} onChange={(e) => handleboardListOption(e)}
            >
                <option></option>
                {
                    boardItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
        )}

        {fee_categItems.length > 0 && (
            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Fee Category</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="fee_categ" value={fee_categ} onChange={(e) => handlefee_categListOption(e)}
                >
                    <option></option>
                    {
                        fee_categItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>
        )}

        {pay_termItems.length > 0 && (
            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Payment Term</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="pay_term" value={pay_term} onChange={(e) => handlepay_termOption(e)}
                >
                    <option></option>
                    {
                        pay_termItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>
        )}

        {student_typeItems.length > 0 && (
            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Student Type</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="student_type" value={student_type} onChange={(e) => handlestudent_typeListOption(e)}
                >
                    <option></option>
                    {
                        student_typeItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>
        )}
        
        {mediumItems.length > 0 && (                    
            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Medium</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="medium" value={medium} onChange={(e) => handlemediumListOption(e)}
                >
                    <option></option>
                    {
                        mediumItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>
        )}                   

        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">From Class</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="st_class" value={st_class} onChange={(e) => handlest_classListOption(e)}
            >
                <option></option>
                {
                    st_classItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>

        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">To Class</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="to_st_class" value={to_st_class} onChange={(e) => handlest_toclassListOption(e)}
            >
                <option></option>
                {
                    st_classItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>

        <div>
            <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Section</label>
            <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="st_sec" value={st_sec} onChange={(e) => handlest_secListOption(e)}
            >
                <option></option>
                {
                    st_secItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>        

        <div>
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Fee Code</label>
            <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="fee_code" value={fee_code} onChange={(e) => handlefee_codeListOption(e)}
            >
                <option></option>
                {
                    fee_codeItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>     

        {streamItems.length > 0 && (
            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Stream</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="stream" value={stream} onChange={(e) => handlestreamListOption(e)}
                >
                    <option></option>
                    {
                        streamItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>
        )}

        {/* ------------------------------------------------------------------------------------ */}





        {/* ------------------------------------------------------------------------------------ */}

        <button className="h-DropDownHeight mt-4 bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
            onClick={GenerateReport}>   
            Show Report
        </button>

        <button className="h-DropDownHeight mt-4 bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
            onClick={handleChangeBrachFY}>   
            Year/Branch
        </button>

        <button className="h-DropDownHeight mt-4 bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
            onClick={PrintPDF}>   
            Print
        </button>

        <CSVLink className="h-DropDownHeight mt-4 text-center bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
            data={items} filename="DailyCollection">Export</CSVLink>           
        
        <button className="h-DropDownHeight mt-4 bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BtnFontSize text-BackBtnFontColor font-BtnFontName rounded-none"
            onClick={goPrePageFunction}
        >Back</button>
    </div>    

    <div ref={conponentPDF}>  
        
        {/* shiv */}

        {/* <table className="w-[100%] mt-2 py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"> */}

        <table className="table-fixed border ml-[25px] w-[1080px] mt-5 font-my-font-name text-rec-fontSize">               
            <thead> 

                <tr><th className="font-my-font-name text-FeeRecFontSize-1 font-FeeRecFontWeight-1 pl-1 text-center">{report_head_1}</th></tr>
                <tr><th className="font-my-font-name text-FeeRecFontSize-2 font-FeeRecFontWeight-2 pl-1 text-center">{report_head_2}</th></tr>
                <tr><th className="font-my-font-name text-FeeRecFontSize-3 font-FeeRecFontWeight-3 pl-1 text-center">{report_head_3}</th></tr>     
                <tr><th className="font-my-font-name text-FeeRecFontSize-3 font-FeeRecFontWeight-3 pl-1 text-center">Month Wise Fee Summary</th></tr>     

                
                
            </thead>
        </table>  

        <table className="table-fixed border ml-[25px] w-[1080px] mt-0 font-my-font-name text-rec-fontSize">               
            <thead>                
                
                <tr className="border-x-[1px]">

                    <th className="pl-2 w-[1px]  text-left">Type:</th>
                    <td className="pl-1 w-[9px] text-left">{fee_groupprint}</td>

                    <th className="pl-2 w-[1px]  text-left">From Month:</th>
                    <td className="pl-1 w-[5px] text-left">{from_monthprint}</td>

                    <th className="pl-2 w-[1px]  text-left">To Month:</th>
                    <td className="pl-1 w-[5px] text-left">{to_monthprint}</td>

                    <th className="pl-2 w-[1px]  text-left">Class:</th>
                    <td className="pl-1 w-[5px] text-left">{st_classprint}</td>

                    <th className="pl-2 w-[1px]  text-left">Sec.:</th>
                    <td className="pl-1 w-[5px] text-left">{st_secprint}</td>

                    <th className="pl-2 w-[1px]  text-left">Stream:</th>
                    <td className="pl-1 w-[5px] text-left">{st_streamprint}</td>

                    <th className="pl-2 w-[1px]  text-left">Fee Code:</th>
                    <td className="pl-1 w-[5px] text-left">{fee_codeprint}</td>

                    
                </tr>

            </thead>
        </table>     

        <table className="table-fixed border ml-[25px] w-[1080px] mt-0 font-my-font-name text-rec-fontSize"> 

            <thead>
                <tr>
                    {ThData()}
                </tr>
            </thead>

        </table>

        <table className="table-fixed border ml-[25px] w-[1080px] mt-0 font-my-font-name text-rec-fontSize"> 

            {/* <tbody> */}
                {tdData()}
            {/* </tbody> */}

        </table>

        {/* <table className="table-fixed ml-[20px] w-[1000px] mt-0 font-my-font-name text-rec-fontSize"> 
            <thead>
                <tr>
                    {ThData()}
                </tr>
            </thead>
            
            <tbody>
                {tdData()}
            </tbody>

        </table> */}

    </div>
</>
);};

export default MonthWiseSummary;
