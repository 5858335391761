// Table name : comp_branch

import React, { useContext, useState, useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';
// import Layout from './Layout';

import Moment from 'moment';

const Br = (props) => {
    const dispatch = useDispatch();
    const ReduxData = useSelector((state) => state);

    const [newbtnshowhide, setnewbtnshowhide]= useState(1);    
    const [savebtnshowhide, setsavebtnshowhide]= useState(1);
    const [deletebtnshowhide, setdeletebtnshowhide]= useState(1);
    const [printbtnshowhide, setprintbtnshowhide]= useState(1);
    const [exportexcelbtnshowhide, setexportexcelbtnshowhide]= useState(1);
    const [exportpdfbtnshowhide, setexportpdfbtnshowhide]= useState(1);
    const [findbtnshowhide, setfindbtnshowhide]= useState(1);

    const [disableSave, setdisableSave] = useState(0);
    const [disableDelete, setdisableDelete] = useState(0);

    const [ShowModelFormDeleteUpdate, setModelFormDeleteUpdate] = useState(false);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);
    const [updateDelTag, setUpdateDelTag] = useState('');
    const [updateDelapiName, setUpdateDelapiName] = useState('');
    
    // const router = useRouter();   

    const navigate = useNavigate();

    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');    

    const [branch_code, setbranch_code] = useState('') 
    const [branch_name, setbranch_name] = useState('') 
    const [print_name, setprint_name] = useState('')  
    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0) 
    const [under_comp_group_id, setunder_comp_group_id] = useState(0) 
    const [br_type, setbr_type] = useState(0)
    const [fin_year_from, setfin_year_from] = useState('') 
    const [book_com_from, setbook_com_from ] = useState('')
    const [cur_sym, setcur_sym] = useState(0)
    const [no_decimal_place , setno_decimal_place] = useState(0)
    const [juris_city, setjuris_city] = useState(0) 
    const [juris_detail, setjuris_detail] = useState('')
    const [sms_send_id, setsms_send_id] = useState('') 
    const [juris_country, setjuris_country] = useState(0) 
    const [juris_state, setjuris_state] = useState(0)
    const [notes, setnotes] = useState('')
    const [show_in_list, setShow_in_list] = useState(1)
    const [active_status, setactive_status] = useState(1)

    const [startDate, setStartDate] = useState(new Date());
    
    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);
    const [under_comp_groupItems, setunder_comp_groupItems] = useState([]);

    const YesNoList = [
        // { id: 0, sel_val: 0, sel_desc: "" },
        { id: 1, sel_val: 1, sel_desc: "Yes" },
        { id: 2, sel_val: 2, sel_desc: "No" }
    ];

    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,        
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        viewRecord,
        setviewRecord,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenPageRefreshMessage,
        whenNoRecordFoundMessage,
    } = useContext(CRUDContext);

    // ////////////////////////////

    const getAuthroziedUser = async () => {  
        try{
            const token = localStorage.getItem('token');

            const response = await axios.get(`verifyTokenAPI/`,
                  { headers: { Authorization: `Bearer ${token}` } });            
    
            if (response.status === 401 || response.status === 403){
                localStorage.clear();
                navigate('/Login')
            };

        }catch(error){
            localStorage.clear();
            navigate('/Login')
        }
    }    

    useEffect( () => {        
        getAuthroziedUser();    
    }, []);

    // ////////////////////////////

    useEffect(() => {        
        
        setnewbtnshowhide(1);        
        setsavebtnshowhide(1);
        setdeletebtnshowhide(1);
        setprintbtnshowhide(2);
        setexportexcelbtnshowhide(2);
        setexportpdfbtnshowhide(2);
        setfindbtnshowhide(1);

        setdisableSave(false);
		setdisableDelete(true);

        // to fill the drop-down

        let response = []
        let updatedata = []

        const getTableRecordFunction = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_comp_branchItems(updatedata);
        }
        getTableRecordFunction('url_comp_branch_view_drop_down');

        const getTableRecordFunctionCompGroup = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_comp_groupItems(updatedata);
        }
        getTableRecordFunctionCompGroup('url_comp_group_other_view_drop_down');
        
        // url_comp_group_other_view_drop_down

    }, []);

    useEffect(() => {
        if (error) {
            toast.error(error);
            clearErrors();
        }

        if (created) {
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();            
		    setdisableDelete(false);
            setviewRecord(false);

            setsaveUpdateTag('UPDATE');
        }

        if (updated) {
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
            setviewRecord(false);
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
            setviewRecord(false);

            setdisableSave(true);
            setdisableDelete(true);
        }
    }, [error, created, updated, deleted]);

    useEffect(() => {            

        // to get the data when user click on view to show the existing record (from cg_view page)

        if (pk_id > 0 && viewRecord) {

            let response = []
            let updatedata = []

            // console.log("in pk_id is : ", pk_id)

            const getAllTableRecordFunction = async (var_link) => {
                response = await axios.get(`${var_link}/${pk_id}`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

                updatedata = await response.data.v_out_data;

                // console.log("updatedata is : ", updatedata) 

                if(updatedata.length === 0){
                    toast.info(whenNoRecordFoundMessage);
                    clearErrors();
                    return;
                }

                
                setPkID(pk_id);                

                setbranch_code(updatedata[0].branch_code) 
                setbranch_name(updatedata[0].branch_name) 
                setprint_name(updatedata[0].print_name)  
                setunder_comp_branch_id(updatedata[0].under_comp_branch_id) 
                setunder_comp_group_id(updatedata[0].under_comp_group_id) 
                setbr_type(updatedata[0].br_type)
                setfin_year_from(updatedata[0].fin_year_from) 
                setbook_com_from(updatedata[0].book_com_from)
                setcur_sym(updatedata[0].cur_sym)
                setno_decimal_place(updatedata[0].no_decimal_place)
                setjuris_city(updatedata[0].juris_city) 
                setjuris_detail(updatedata[0].juris_detail)
                setsms_send_id(updatedata[0].sms_send_id) 
                setjuris_country(updatedata[0].juris_country) 
                setjuris_state(updatedata[0].juris_state)
                setnotes(updatedata[0].notes)
                setShow_in_list(updatedata[0].show_in_list)
                setactive_status(updatedata[0].active_status)

                if (updatedata[0].active_status === 1){
                    setdisableSave(false);
                    setdisableDelete(false);
                }else
                {
                    setdisableSave(true);
                    setdisableDelete(true);
                }

                setsaveUpdateTag('UPDATE');

             }
             getAllTableRecordFunction('url_comp_branch_view');
        }

    }, [pk_id])

    function setBlankFields()
    {
        setPkID(0);

        setsaveUpdateTag('SAVE');        

        setbranch_code('') 
        setbranch_name('') 
        setprint_name('')  
        setunder_comp_branch_id(0) 
        setunder_comp_group_id(0) 
        setbr_type(0)
        setfin_year_from('') 
        setbook_com_from('')
        setcur_sym(0) 
        setno_decimal_place(0)
        setjuris_city(0) 
        setjuris_detail('')
        setsms_send_id('') 
        setjuris_country(0) 
        setjuris_state(0)
        setnotes('')
        setShow_in_list(1)
        setactive_status(1)          
    }

    const newBlankFieldHandler = (e) => {
        e.preventDefault();
        setBlankFields();

        setdisableSave(false);
		setdisableDelete(true);
		setviewRecord(false);
    }

    const createRecordFunction = (event) => {
        event.preventDefault();

        if (branch_name.trim().length === 0){
            toast.info("Entry reuired for Branch name !")
            clearErrors();
            return;
        }        

        if (show_in_list < 1){
            toast.info("Entry reuired for show in list !")
            clearErrors();
            return;
        }

        const data = {
            id: pk_id,

            branch_code, 
            branch_name, 
            print_name, 
            under_comp_branch_id,            
            under_comp_group_id,
            br_type,
            fin_year_from : Moment(fin_year_from).format('YYYY-MM-DD'), 
            book_com_from : Moment(book_com_from).format('YYYY-MM-DD'), 
            cur_sym, 
            no_decimal_place, 
            juris_city, 
            juris_detail, 
            sms_send_id, 
            juris_country, 
            juris_state,
            notes,
            active_status, 
            show_in_list,
        };

        dispatch(emptyCart());
        dispatch(addTempList(data));

        // console.log("pk_id value is : ", pk_id)

        // used when updation is not allow. we will disable the save button after first time click.
        // setdisableSave(true); 

        if (pk_id === 0) {                                
            setUpdateDelTag('')
            setModelModelFormYesNo(true);
        }
        else {
            setdisableDelete(false);
            setsaveUpdateTag('UPDATE')
            setUpdateDelTag('UPDATE')
            setUpdateDelapiName('url_comp_branch_updel')
            setModelFormDeleteUpdate(true);           
        }
    }

    const FindInTablePage = (event) => {
        event.preventDefault();
        setviewRecord(false);
        navigate('/br_view')
    }

    const handleDeleteUpdateFunction = (event) => {
        event.preventDefault();

        if (pk_id === 0) {
            toast.info("Record not found !")
            clearErrors();
            return;
        }

        const data = {
            id: pk_id,
        };

        setUpdateDelTag('DELETE')
        setUpdateDelapiName('url_comp_branch_updel')

        dispatch(emptyCart());
        dispatch(addTempList(data));
        setModelFormDeleteUpdate(true);
    }    

    const goPrePageFunction = (event) => {
        event.preventDefault();
        setPkID(0);
        navigate('/brms')
    }

    const handlePrintButtonFunction = (event) => {
        event.preventDefault();
        
        if (pk_id === 0) {
            toast.info("Record not found !")
            clearErrors();
            return;
        }
    }

    const handleExportExcelButtonFunction = (event) => {
        event.preventDefault();
        
        if (pk_id === 0) {
            toast.info("Record not found !")
            clearErrors();
            return;
        }
    }

    const handleExportPDFButtonFunction = (event) => {
        event.preventDefault();

        if (pk_id === 0) {
            toast.info("Record not found !")
            clearErrors();
            return;
        }
    }

    const handleunder_comp_group_id = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setunder_comp_group_id(getId);
    }

    const handleunder_comp_branch_id = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setunder_comp_branch_id(getId);
    }

    const handleShowInListOption = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setShow_in_list(getId);
    }

    const handlecur_sym = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setcur_sym(getId);
    }

    const handleno_decimal_place = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setno_decimal_place(getId);
    }

    const handlebr_type = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setbr_type(getId);
    }

    const handlejuris_country = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setjuris_country(getId);
    }

    const handlejuris_state = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setjuris_state(getId);
    }

    const handlejuris_city = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setjuris_city(getId);
    }

    function handleEnter(event) {
        if (event.keyCode === 13) {
          const form = event.target.form;
          const index = Array.prototype.indexOf.call(form, event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
        }
    }

    return (
        <>
            <form>
            <div className="pt-8 max-w-full text-my-main-heading-fontSize bg-my-main-heading-color text-my-main-heading-font-color text-center">Branch Master</div>
        
            <div className="justify-center items-center inset-0 flex">

                {/* <div className="pt-1 px-2"> */}

                <div className = "pt-2 px-2 grid mm:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-1">


                    <div>
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Branch Code</label>
                        <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                            name="branch_code"
                            id="branch_code"
                            type="text"                            
                            value={branch_code}
                            onChange={(e) => setbranch_code(e.target.value)}
                            // onKeyDown={handleEnter} {...props}
                        ></input>
                    </div>

                    <div>
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">SMS ID</label>
                        <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                            name="sms_send_id"
                            id="sms_send_id"
                            type="text"                            
                            value={sms_send_id}
                            onChange={(e) => setsms_send_id(e.target.value)}
                            // onKeyDown={handleEnter} {...props}
                        ></input>
                    </div>

                    <div>
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Show-in-List</label>
                        <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                            name="show_in_list" value={show_in_list} onChange={(e) => handleShowInListOption(e)}
                            // onKeyDown={handleEnter} {...props}
                            >
                            {/* <option>--Select Option--</option> */}
                            
                            <option></option>
                            {
                                YesNoList.map((item, index) => (
                                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                                ))
                            }
                        </select>
                    </div>
                    
                    <div className="col-span-2">
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Branch Name</label>
                        <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                            col-span-2"

                            name="branch_name"
                            id="branch_name"
                            type="text"                            
                            value={branch_name}
                            onChange={(e) => setbranch_name(e.target.value)}
                            // onKeyDown={handleEnter} {...props}
                        ></input>
                    </div>

                    <div className="col-span-2">
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Print Name</label>
                        <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                            col-span-2"

                            name="print_name"
                            id="print_name"
                            type="text"                            
                            value={print_name}
                            onChange={(e) => setprint_name(e.target.value)}
                            // onKeyDown={handleEnter} {...props}
                        ></input>
                    </div>

                    <div className="col-span-2">
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Under Company Group</label>
                        <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                            name="under_comp_group_id" value={under_comp_group_id} onChange={(e) => handleunder_comp_group_id(e)}
                            // onKeyDown={handleEnter} {...props}
                            >
                            <option></option>
                            {
                                under_comp_groupItems.map((item, index) => (
                                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="col-span-2">
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Under Branch</label>
                        <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                            name="under_comp_branch_id" value={under_comp_branch_id} onChange={(e) => handleunder_comp_branch_id(e)}
                            // onKeyDown={handleEnter} {...props}
                            >
                            <option></option>
                            {
                                under_comp_branchItems.map((item, index) => (
                                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div>
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Currency Symbol (P)</label>
                        <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                            name="cur_sym" value={cur_sym} onChange={(e) => handlecur_sym(e)}
                            // onKeyDown={handleEnter} {...props}
                            >
                            <option></option>
                            {
                                // under_comp_groupItems.map((item, index) => (
                                //     <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                                // ))
                            }
                        </select>
                    </div>                   

                    <div>
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">No.of Decimal Places (P)</label>
                        <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                            name="no_decimal_place" value={no_decimal_place} onChange={(e) => handleno_decimal_place(e)}
                            // onKeyDown={handleEnter} {...props}
                            >
                            <option></option>
                            {
                                // under_comp_groupItems.map((item, index) => (
                                //     <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                                // ))
                            }
                        </select>
                    </div>

                    <div>
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Financial Year From (P)</label>
                        <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                            "
                            name="fin_year_from"
                            id="fin_year_from"
                            type="date"                            
                            value={fin_year_from}
                            onChange={(e) => setfin_year_from(e.target.value)}
                            // onKeyDown={handleEnter} {...props}                            
                        ></input>
                    </div>

                    <div>
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Books Commencing From (P)</label>
                        <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                            "
                            name="book_com_from"
                            id="book_com_from"
                            type="date"                            
                            value={book_com_from}
                            onChange={(e) => setbook_com_from(e.target.value)}
                            // onKeyDown={handleEnter} {...props}
                        ></input>
                    </div>                    

                    <div>
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Branch Type (P)</label>
                        <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                            name="br_type" value={br_type} onChange={(e) => handlebr_type(e)}
                            // onKeyDown={handleEnter} {...props}
                            >
                            <option></option>
                            {
                                // under_comp_groupItems.map((item, index) => (
                                //     <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                                // ))
                            }
                        </select>
                    </div>                   

                    <div>
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Jurisdiction Country (P)</label>
                        <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                            name="juris_country" value={juris_country} onChange={(e) => handlejuris_country(e)}
                            // onKeyDown={handleEnter} {...props}
                            >
                            <option></option>
                            {
                                // under_comp_groupItems.map((item, index) => (
                                //     <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                                // ))
                            }
                        </select>
                    </div>        

                    <div>
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Jurisdiction State (P)</label>
                        <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                            name="juris_state" value={juris_state} onChange={(e) => handlejuris_state(e)}
                            // onKeyDown={handleEnter} {...props}
                            >
                            <option></option>
                            {
                                // under_comp_groupItems.map((item, index) => (
                                //     <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                                // ))
                            }
                        </select>
                    </div>

                    <div>
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Jurisdiction City (P)</label>
                        <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                            name="juris_city" value={juris_city} onChange={(e) => handlejuris_city(e)}
                            // onKeyDown={handleEnter} {...props}
                            >
                            <option></option>
                            {
                                // under_comp_groupItems.map((item, index) => (
                                //     <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                                // ))
                            }
                        </select>
                    </div>

                    <div className="col-span-2">
                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Under Jurisdiction Details</label>
                        <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                            "
                            name="juris_detail"
                            id="juris_detail"
                            type="text"                            
                            value={juris_detail}
                            onChange={(e) => setjuris_detail(e.target.value)}
                            // onKeyDown={handleEnter} {...props}
                        ></input>
                    </div>

                    <div className="col-span-4">

                        <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Notes</label>
                        <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                            name="notes"
                            id="notes"
                            type="text"                            
                            value={notes}
                            onChange={(e) => setnotes(e.target.value)}
                            // onKeyDown={handleEnter} {...props}
                        ></input>
                    </div>

                    <div></div>                    

                    <div className="pt-4 py-my-padding-py px-my-padding-px grid grid-cols-5 gap-4">

                        { newbtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={newBlankFieldHandler}>
                                New
                            </button>
                        )}                        

                        { savebtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                disabled={disableSave}
                                onClick={createRecordFunction}>
                                Save
                            </button>
                        )}
                            
                        { deletebtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                disabled={disableDelete}
                                onClick={handleDeleteUpdateFunction}>
                                Delete
                            </button>
                        )} 
                        
                        { printbtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={handlePrintButtonFunction}>
                                Print
                            </button>
                        )} 

                        { exportexcelbtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={handleExportExcelButtonFunction}>
                                Export Excel
                            </button>
                        )}

                        { exportpdfbtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={handleExportPDFButtonFunction}>
                                Export PDF
                            </button>
                        )} 
                        
                        { findbtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={FindInTablePage}>
                                Find
                            </button>
                        )} 

                        <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={goPrePageFunction}>
                            Back
                        </button>

                    </div>

                    <ModelFormDeleteUpdate api_type={updateDelTag} api_name={updateDelapiName} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)} />

                    <ModelFormYesNo table_name='COMP_BRANCH' api_name={'url_comp_branch_new'} isVisible={ShowModelFormYesNo} onClose={() => setModelModelFormYesNo(false)} />

                </div>
            </div>
            </form>
</>
    );
};

export default Br;

