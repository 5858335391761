// table : ac_ledger_trans_master and ac_ledger_trans

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useDispatch, useSelector } from 'react-redux';

import { useDispatch} from 'react-redux';

import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';

// import cb from '../pages/cb';

// import cb from './cb';

import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

import moment, { invalid } from 'moment';

// import Paramtable from "./Paramtable";

const Vm = () => {
    const dispatch = useDispatch();
    const [newbtnshowhide, setnewbtnshowhide] = useState(1);
    const [savebtnshowhide, setsavebtnshowhide] = useState(1);
    const [deletebtnshowhide, setdeletebtnshowhide] = useState(1);
    const [printbtnshowhide, setprintbtnshowhide] = useState(1);
    
    // const [exportexcelbtnshowhide, setexportexcelbtnshowhide] = useState(1);    
    // const [exportpdfbtnshowhide, setexportpdfbtnshowhide] = useState(1);

    const [findbtnshowhide, setfindbtnshowhide] = useState(1);

    const [disableSave, setdisableSave] = useState(0);
    const [disableDelete, setdisableDelete] = useState(0);

    const [ShowModelFormDeleteUpdate, setModelFormDeleteUpdate] = useState(false);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);    
    const [updateDelTag, setUpdateDelTag] = useState('');
    const [updateDelapiName, setUpdateDelapiName] = useState('');
    
    // const router = useRouter();

    const navigate = useNavigate();

    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);    

    const [vou_date, setvou_date] = useState(0);
    const [vou_type_id, setvou_type_id] = useState(0);
    const [notes, setnotes] = useState();

    const [vou_type_idItems, setvou_type_idItems] = useState([]);

    const [ac_led_idItems, setac_led_idItems] = useState([]);
    const [dr_crItems, setdr_crItems] = useState([]);

    const [vou_no, setvou_no] = useState(0);

    let list1 = [];

    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        viewRecord,
        setviewRecord,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenPageRefreshMessage
    } = useContext(CRUDContext);

    // ////////////////////////////

    // const getAuthroziedUser = async () => {
    //     try {
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //             { headers: { Authorization: `Bearer ${token}` } });

    //         if (response.status === 401 || response.status === 403) {
    //             localStorage.clear();
    //             // router.push('/Login')

    //             navigate("/Login");
    //         };

    //     } catch (error) {
    //         localStorage.clear();
    //         // router.push('/Login')
    //         navigate("/Login");
    //     }
    // }

    // useEffect(() => {
    //     getAuthroziedUser();
    // }, []);

    // ////////////////////////////

    useEffect(() => {

        setnewbtnshowhide(1);
        setsavebtnshowhide(1);
        setdeletebtnshowhide(1);
        setprintbtnshowhide(2);
        // setexportexcelbtnshowhide(2);
        // setexportpdfbtnshowhide(2);
        setfindbtnshowhide(1);

        // setdisableSave(false);
        // setdisableDelete(true);        

        // to fill the drop-down

        let response = []
        let updatedata = []

        // to fill the drop-down

        const getParamTableRecordFunctionBranch = async (var_link) => {
            response = await axios.get(`${var_link}/AC_VOU_TYPE`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setvou_type_idItems(updatedata);

            response = await axios.get(`${var_link}/DR_CR`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setdr_crItems(updatedata);

            response = await axios.get(`url_ac_led_br_wise/${localStorage.getItem('br')}`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setac_led_idItems(updatedata);

            // console.log("updatedata is : ", updatedata);

        }
        getParamTableRecordFunctionBranch('url_param_view_drop_down');

        // shiv

        setBlankFields();

        var today = new Date();
        setvou_date(moment(today).format('YYYY-MM-DD'));

    }, []);

    useEffect(() => {
        if (error) {
            toast.error(error);
            clearErrors();
        }

        if (created) {
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
            
            setdisableDelete(false);
            setviewRecord(true);
            setdisableSave(true);    

            setsaveUpdateTag('UPDATE');

            setPkID(pk_id.p_id);
            setvou_no(pk_id.p_vou_no);

            // console.log("pk_id.p_id >> ", pk_id.p_id)
            // console.log("pk_id.p_vou_no >> ", pk_id.p_vou_no)

        }

        if (updated) {
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();

            setPkID(pk_id.p_id);
            setvou_no(pk_id.p_vou_no);

            setviewRecord(true);
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
            setviewRecord(true);

            setPkID(pk_id.p_id);
            setvou_no(pk_id.p_vou_no);

            setdisableSave(true);
            setdisableDelete(true);
        }
    }, [error, created, updated, deleted]);

    useEffect(() => {       

        if (pk_id > 0 && viewRecord) {
            let response = []
            let updatedata = []

            // console.log("in pk_id is : ", pk_id)

            // p_under_comp_branch_id bigint,
            // p_fin_year bigint,
            // p_id bigint

            const getAllTableRecordFunction = async (var_link) => {
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/${localStorage.getItem('fy')}/${pk_id}`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                
                updatedata = await response.data.v_out_data;                  

                // console.log("updatedata is : ", updatedata) 

                setPkID(pk_id);
                
                setvou_date(moment(updatedata[0].v_vou_date).format('YYYY-MM-DD'));
                setvou_type_id(updatedata[0].v_vou_type_id); 
                setnotes(updatedata[0].v_notes);

                setvou_no(updatedata[0].v_vou_no)
                
                setdisableSave(true);
                
                if (updatedata[0].v_active_status === 1){
                    // setdisableSave(true);
                    setdisableDelete(false);
                }else
                {
                    // setdisableSave(false);
                    setdisableDelete(true);
                }

                setsaveUpdateTag('');

                // get the value of fee_detail on this fee_master id               

                response = await axios.get(`url_ac_ledger_trans_view_all_vou_fun/${localStorage.getItem('br')}/${localStorage.getItem('fy')}/${pk_id}`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                
                updatedata = await response.data.v_out_data;    

                // console.log("pk_id is : ", pk_id) 
                // console.log("updatedata is : ", updatedata)
                
                setItems(updatedata);

             }
             getAllTableRecordFunction('url_ac_ledger_trans_master_view_fun');
        }
        
    }, [created, deleted]);

    function setBlankFields() {
        setPkID(0);

        setvou_no('');

        setsaveUpdateTag('SAVE');

        setvou_type_id('');
        setvou_date('');
        setnotes('');

        setItems([]);
    }

    const newBlankFieldHandler = (e) => {
        e.preventDefault();
        setBlankFields();

        setdisableSave(false);
        setdisableDelete(true);
        setviewRecord(false);

        var today = new Date();
        setvou_date(moment(today).format('YYYY-MM-DD'));
    }

    const createRecordFunction = (e) => {
        e.preventDefault();       

        let v_vou_date = null

        if (vou_date.length === 0){
            v_vou_date = null;
            toast.error("Entry required for voucher date !");
            clearErrors();
            return
        }else{
            v_vou_date = vou_date;
        }

        if (moment(vou_date, "YYYY-MM-DD", true).isValid())
        {
            v_vou_date = vou_date
        }else{         
            toast.error("Entry required for voucher date !");
            clearErrors();
            return
        }

        let insupdel_type = '';

        if (pk_id === 0){
            insupdel_type = 'INSERT';               
        }else{
            insupdel_type = 'UPDATE';            
        }

        const data = {
            id: pk_id,

            ac_led_id_1 : 0,
            vou_amount:0,
            insupdel_type,
            under_comp_group_id : localStorage.getItem('ag'),
            under_comp_branch_id : localStorage.getItem('br'),
            fin_year : localStorage.getItem('fy'), 
            vou_date, 
            vou_type_id, 
            notes, 
            show_in_list : 1, 
            active_status : 1,  
            
            items: items,
        };

        // console.log("data is : ", pk_id);

        // console.log("items is : ", items)

        dispatch(emptyCart());
        dispatch(addTempList(data));

        // console.log("pk_id value is : ", pk_id)

        // used when updation is not allow. we will disable the save button after first time click.
        
        setdisableSave(true);       
        
        if (pk_id === 0) {
            setUpdateDelTag('');
            setUpdateDelapiName('url_ac_ledger_trans_master_new_proc');
            setModelModelFormYesNo(true);
        }
        else {
            setdisableDelete(false);
            setsaveUpdateTag('UPDATE');
            setUpdateDelTag('UPDATE');
            setUpdateDelapiName('url_ac_ledger_trans_master_new_proc');
            setModelFormDeleteUpdate(true);
        }
    }

    const handleDeleteUpdateFunction = (event) => {
        event.preventDefault();

        if (pk_id === 0) {
            toast.info("Record not found !")
            clearErrors();
            return;
        }

        let insupdel_type = '';
        
        insupdel_type = 'DELETE';                    

        const data = {
            id: pk_id,

            insupdel_type,
            under_comp_group_id : localStorage.getItem('ag'),
            under_comp_branch_id : localStorage.getItem('br'),
            fin_year : localStorage.getItem('fy'), 
            vou_date, 
            vou_type_id, 
            notes, 
            show_in_list : 1, 
            active_status : 1,  
            
            items: items,
        };

        setUpdateDelTag('DELETE')
        setUpdateDelapiName('url_ac_ledger_trans_master_new_proc')

        dispatch(emptyCart());
        dispatch(addTempList(data));
        setModelFormDeleteUpdate(true);
    }

    const handlePrintButtonFunction = (event) => {
        event.preventDefault();

        if (pk_id === 0) {
            toast.info("Record not found !")
            clearErrors();
            return;
        }
    }

    const FindInTablePage = (event) => {
        event.preventDefault();
        setviewRecord(false);

        localStorage.setItem('fm', 'vm'); // form_name

        // router.push('/cb');

        navigate("/Vmview");
    }

    // Add row. when click on add row button

    const addItem = () => {
        setTabID(tabID + 1);
        setItems([...items, { "id": 0, ac_led_id: '', dr_cr: '', dr_amt: '', cr_amt: '', notes: "", t1_id: tabID }]);        
    };

    const handlerChange = (event, i) => {
        // const { name, value } = event.target;
        // const list = [...items];
        // list[i][name] = value;
        // setItems(list);

        event.preventDefault();
        const { name, value } = event.target;
        let v_value = value;

        if (name === 'dr_amt' || name === 'cr_amt')
        {
            v_value = event.target.value.replace(/\D/g, "");            
        }

        const list = [...items];
        list[i][name] = v_value;
        setItems(list);
    };   

    const handvou_type_idOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setvou_type_id(getId);
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();
        setPkID(0);
        // router.push('/')
        navigate("/");
    }

    
    // const goCheckDataFunction = (event) => {
    //     event.preventDefault();
        
    //     console.log()
    // }

    const handleChangeBrachFY = (event) => {
        event.preventDefault();
        setviewRecord(false);

        localStorage.setItem('fm', 'vm'); // form_name

        // router.push('/cb');

        navigate("/cb");
    }

return (

<>

<div className="pt-PaddingTopAfterMenu pl-1 flex justify-start text-MainHeadingFontSize text-MainHeadingFontColor bg-MainHeadingBGColor">Voucher Entry</div>

<div className="grid lg:grid-cols-12 gap-2">    

    <div className="col-span-2">
        <label className="mb-1 max-w-full h-DropDownInputHeight text-InputFontColor font-LabelFontWeight font-InputFontName">Voucher Type</label>
        <select className="w-[100%] py-PaddingPY h-DropDownInputHeight border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
            name="vou_type_id" value={vou_type_id} onChange={(e) => handvou_type_idOption(e)}
        >
            <option></option>
            {
                vou_type_idItems.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
        </select>
    </div>

    <div className="col-span-2">
        <label className="mb-5 max-w-full h-DropDownInputHeight text-my-font-color font-myFontWeight">Date</label>
        <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color
            "
            name="vou_date"
            id="vou_date"
            type="date"                            
            value={vou_date}
            onChange={(e) => setvou_date(e.target.value)}
            // onKeyDown={handleEnter} {...props}                            
        ></input>
    </div>   

    <label className="mb-1 max-w-full h-DropDownInputHeight text-InputFontColor font-LabelFontWeight font-InputFontName">{vou_no}</label>
    

    <div className="col-span-12">
            <label className="mb-1 max-w-full h-DropDownInputHeight text-InputFontColor font-LabelFontWeight font-InputFontName">Notes</label>
            <input className="w-[100%] h-DropDownInputHeight py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="notes"
            id="notes"
            type="text"
            value={notes}
            onChange={(e) => setnotes(e.target.value)}
            // onKeyDown={handleEnter} {...props}
        ></input>
    </div>

</div>

<div className="mt-2 border-TabBorderWidth border-TabHeadingBorderColor overflow-y-scroll">          
            
    <div className="grid lg:grid-cols-12">        
      
        <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
            col-span-3">
            Ledger Name
        </div>

        <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
            col-span-1">
            Dr./Cr.
        </div>

        <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
            col-span-1">
            Debit
        </div>   

        <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
            col-span-1">
            Credit
        </div>                       

        <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
            col-span-6">
            Notes
        </div>                

    </div>
</div>

<div className="border-TabBorderWidth border-TabBorderColor h-InsertTableHeight overflow-y-scroll">
{
    items?.map((item, i) => (

        <div className="h-DropDownInputHeight grid grid-cols-12" key={item.t1_id}>
            
            <select className="text-InputFontColor h-DropDownHeight font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                col-span-3"
                name="ac_led_id" value={item.ac_led_id} onChange={(e) => handlerChange(e, i)}                            
                >
                <option></option>
                {
                    ac_led_idItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>

            <select className="text-InputFontColor h-DropDownHeight font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                col-span-1"
                name="dr_cr" value={item.dr_cr} onChange={(e) => handlerChange(e, i)}                            
                >
                {/* <option></option> */}
                {
                    dr_crItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>

            <input className="w-[100%] h-DropDownInputHeight text-right pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                col-span-1"
                type="text"
                name="dr_amt"
                value={item.dr_amt}
                onChange={(e) => handlerChange(e, i)}                                
            />

            <input className="w-[100%] h-DropDownInputHeight text-right pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                col-span-1"
                type="text"
                name="cr_amt"
                value={item.cr_amt}
                onChange={(e) => handlerChange(e, i)}                                
            />
            
            <input className="w-[100%] h-DropDownInputHeight pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                col-span-5"
                type="text"
                name="notes"
                value={item.notes}
                onChange={(e) => handlerChange(e, i)}                                
            />

            {
                item.id > 0 ? (
                    <>
                        <button className="bg-AddRowButtonBGColor hover:bg-AddRowButtonHoverColor text-my-fontSize text-AddRowButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={addItem}>New</button>
                    </>
                ) : (
                    <div>
                        {/* {item.id} */}
                        <button className="w-[50%] bg-AddRowButtonBGColor hover:bg-AddRowButtonHoverColor text-my-fontSize text-AddRowButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            disabled={disableSave}
                            onClick={addItem}>New</button>

                        <button className="w-[50%] bg-DeleteRowButtonBGColor hover:bg-DeleteRowButtonHoverColor text-my-fontSize text-DeleteRowButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={() => { setItems(items.filter(a => a.t1_id !== item.t1_id)); }}>
                            Delete
                        </button>
                    </div>
                )
            }

        </div>
    ))
}
</div>    

    <div className="py-my-padding-py px-my-padding-px overflow-y-scroll">

        <div className="grid grid-cols-7 gap-4">

            {newbtnshowhide === 1 && (
                <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    disabled={disableSave}
                    onClick={addItem}>
                    New Row
                </button>
            )}
            
            {newbtnshowhide === 1 && (
                <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    onClick={handleChangeBrachFY}>
                    Year/Branch
                </button>
            )}

            {newbtnshowhide === 1 && (
                <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    onClick={newBlankFieldHandler}>
                    New
                </button>
            )}

            {savebtnshowhide === 1 && (
                <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    disabled={disableSave}
                    onClick={createRecordFunction}>
                    Save
                </button>
            )}

            {deletebtnshowhide === 1 && (
                <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    disabled={disableDelete}
                    onClick={handleDeleteUpdateFunction}>
                    Delete
                </button>
            )}

            {printbtnshowhide === 1 && (
                <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    onClick={handlePrintButtonFunction}>
                    Print
                </button>
            )}

            {findbtnshowhide === 1 && (
                <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    onClick={FindInTablePage}>
                    Find
                </button>                
            )}

            {/* <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                onClick={goCheckDataFunction}>
                Check Data
            </button> */}

            <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                onClick={goPrePageFunction}>
                Back
            </button>

        </div>
    </div>

    <ModelFormDeleteUpdate api_type={updateDelTag} api_name={updateDelapiName} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)} />

    <ModelFormYesNo table_name='STANDARD' api_name={updateDelapiName} isVisible={ShowModelFormYesNo} onClose={() => setModelModelFormYesNo(false)} />
    
</>
);
};

export default Vm;


