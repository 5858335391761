import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import moment from 'moment';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import CRUDContext from '../components/CRUDContext';

import { emptyTempDataFun, emptyGlobalDataFun, setTempDataFun } from '../components/redux/actions';

import Cookies from 'js-cookie';

const AccountLedger = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';    
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);      
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);   

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disableSave, setdisableSave] = useState(0);
    const [disableDelete, setdisableDelete] = useState(0);    
    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData); 
    const ReduxTempData = useSelector((state) => state.TempData);    
    
    // define drop downs inputs

    const [led_name, setled_name] = useState('');
    const [print_name, setprint_name] = useState('');
    const [under_ac_group_id, setunder_ac_group_id] = useState('');
    const [is_sub_led, setis_sub_led] = useState('');
    const [used_sale_inv, setused_sale_inv] = useState('');
    const [used_pur_inv, setused_pur_inv] = useState('');
    const [open_bal, setopen_bal] = useState('');
    const [dr_cr, setdr_cr] = useState('');
    const [as_on_date, setas_on_date] = useState('');
    const [notes, setnotes] = useState('');

    // define drop downs items

    const [dr_crItems, setdr_crItems] = useState([]);
    const [under_ac_group_idItems, setunder_ac_group_idItems] = useState([]);
    
    // define form name and navigation details

    const [v_urlSaveUpdateDelete, setv_urlSaveUpdateDelete] = useState('');

    const v_backToNavigateName = '/brms';
    const v_findToNavigateName = '/al_view';
    const v_main_form_heading = 'Account Ledger';
    const v_frm_no = 3;    

    let v_input_result, v_active_status = 1, v_null_parameter = 'NA';

    const YesNoList = [
        // { id: 0, sel_val: 0, sel_desc: "" },
        { id: 1, sel_val: 1, sel_desc: "Yes" },
        { id: 2, sel_val: 2, sel_desc: "No" }
    ];
    
    // define standard errors and entry required messages

    const {        
        deletedMessage,
        createdMessage,
        updatedMessage,        
        whenStudentNotFoundMessage,                     
        WhenRecordNotFoundToDelete,
        WhenRecordNotFoundToUpdate,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);          

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code

                setv_open_frm(true);                

                setdisableSave(false);
                setdisableDelete(true);

                setBlankFields();

                const v_token = Cookies.get('token');               

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_get_data/38/0/0/DR_CR`,
                    { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setdr_crItems(updatedata);

                response = await axios.get(`url_fetch_data/url_ac_group_view/${v_frm_no}/${ReduxGlobalData[0].ag}/0/0/1
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}        
                `,
                { headers: { Authorization: `Bearer ${v_token}` } });             
                updatedata = await response.data.v_out_data.v_data;
                setunder_ac_group_idItems(updatedata);

                if (ReduxTempData.length > 0){
                    FindSavedDetails(ReduxTempData[0].tempuserid);
                    dispatch(emptyTempDataFun());
                }
            }

        }
        loadData();
    }, [ReduxGlobalData[0]]); 

    useEffect(() => {
        setBlankFields();
    }, [ReduxGlobalData[0]]);       

    function FindSavedDetails(p_TableRecordID) {

        const getAllTableRecordFunction = async (var_link) => {

            const v_token = Cookies.get('token');            

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/3_url_ac_led_id_wise/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${p_TableRecordID}`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data;     
            
            // console.log("updatedata >> ", updatedata)            

            const returnVal = AuthenticationCheckDetailFun(updatedata, 'READ');
            if (!returnVal){return};

            updatedata = await response.data.v_out_data.v_data;

            setTableRecordID(p_TableRecordID); 

            setled_name(updatedata[0].v_led_name);
            setprint_name(updatedata[0].v_print_name);
            setunder_ac_group_id(updatedata[0].v_under_ac_group_id);
            setis_sub_led(updatedata[0].v_is_sub_led);
            setused_sale_inv(updatedata[0].v_used_sale_inv);
            setused_pur_inv(updatedata[0].v_used_pur_inv);
            setopen_bal(updatedata[0].v_open_bal);
            setdr_cr(updatedata[0].v_dr_cr);

            setas_on_date(moment(updatedata[0].v_as_on_date).format('YYYY-MM-DD'));            

            setnotes(updatedata[0].v_notes);

            setsaveUpdateTag('UPDATE');

        }
        getAllTableRecordFunction();        
    }

    function setBlankFields() {
        setsaveUpdateTag('SAVE');
        setdisableSave(false);
        setdisableDelete(true);         
        setTableRecordID(0);
        
        setled_name('');
        setprint_name('');
        setunder_ac_group_id(0);
        setis_sub_led();
        setused_sale_inv(0);
        setused_pur_inv(0);
        setopen_bal('');
        setdr_cr(0);

        setas_on_date('');

        setnotes('');
    }

    const handleInputValueFun = (name, value) => {

        // led_name
        // print_name
        // under_ac_group_id
        // is_sub_led
        // used_sale_inv
        // used_pur_inv
        // open_bal
        // dr_cr
        // as_on_date
        // notes

        // shiv

        if (name === 'led_name') {            
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setled_name(capitalizeWordFun(v_input_result.v_value));
            return;
        }else if (name === 'print_name') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setprint_name(capitalizeWordFun(v_input_result.v_value));
            return;    
        }else if (name === 'under_ac_group_id') {
            setunder_ac_group_id(value);
            return;
        }else if (name === 'is_sub_led') {
            setis_sub_led(value);
            return;
        }else if (name === 'used_sale_inv') {
            setused_sale_inv(value);
            return;            
        }else if (name === 'used_pur_inv') {
            setused_pur_inv(value);
            return;
        }else if (name === 'open_bal') {
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);
            setopen_bal(capitalizeWordFun(v_input_result.v_value));
            return;
        }else if (name === 'dr_cr') {
            setdr_cr(value);
            return;            
        }else if (name === 'as_on_date') {
            setas_on_date(value);
            return;
        }else if (name === 'notes') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setnotes(capitalizeWordFun(v_input_result.v_value));            
            return;
        }

    }

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        let v_is_sub_led = 0, v_used_sale_inv = 0, v_used_pur_inv = 0, v_open_bal = 0, v_dr_cr = 0, v_as_on_date = null, insupdel_type = '';

        if (value === 'DELETE'){

            if (TableRecordID === 0){
                toast.info(WhenRecordNotFoundToDelete, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            insupdel_type = 'DELETE';
        }else{
            if (TableRecordID === 0){
                insupdel_type = 'INSERT';            
            }else{

                if (TableRecordID === 0){
                    toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return;
                }

                insupdel_type = 'UPDATE';            
            }
        }

        if (value != 'DELETE'){

            if(led_name.trim().length === 0){
                toast.error('Entry required for Ledger name !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }

            if(print_name.trim().length === 0){
                toast.error('Entry required for Print name !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }

            if(parseInt(under_ac_group_id) < 1){
                toast.error('Entry required for Under group !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }            
    
            if (is_sub_led > 0){                    
                v_is_sub_led = is_sub_led;
            }
    
            if (used_sale_inv > 0){           
                v_used_sale_inv = used_sale_inv;
            }
    
            if (used_pur_inv > 0){           
                v_used_pur_inv = used_pur_inv;
            }
    
            if (open_bal.length > 0){           
                v_open_bal = open_bal;
            }
    
            if (dr_cr.length > 0){           
                v_dr_cr = dr_cr;
            }
    
            if (as_on_date.length > 0){
                v_as_on_date = as_on_date
            }else{
                v_as_on_date = null
            }

        }

        const data = {
            // id: TableRecordID,
            // insupdel_type,            
            // under_comp_branch_id : ReduxGlobalData[0].br,
            // fin_year : ReduxGlobalData[0].fy,

            insupdel_type,

            id: TableRecordID,

            under_comp_group_id : ReduxGlobalData[0].ag,
            under_comp_branch_id : ReduxGlobalData[0].br,            
            led_name,
            print_name,
            under_ac_group_id, 
            is_sub_led:v_is_sub_led, 
            used_sale_inv:v_used_sale_inv, 
            used_pur_inv:v_used_pur_inv,
            open_bal:v_open_bal, 
            dr_cr:v_dr_cr,
            as_on_date:v_as_on_date,
            notes,            
            
            // items: Array(items),
        };
        
        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        setv_urlSaveUpdateDelete('3_url_ac_led_new');

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }    
    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);        

        if (p_data.ModelYesNoAnswer === 'YES'){
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async(p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final:p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final:p_last_updel_notes_final,
        }        

        const v_token = Cookies.get('token');       

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });
        
        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }
    
    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }     

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then           

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                    
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            // console.log("ID >> ", updatedata.v_data[0].v_id);

            setNewRecordIDFunction(updatedata.v_data[0].v_id);
            
            if (updatedata.transType === 'INSERT'){
                setdisableDelete(false);             
                setsaveUpdateTag('UPDATE');
                v_toast_msg = createdMessage;
            }else if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;
            }else if (updatedata.transType === 'DELETE'){
                setdisableSave(true);
                setdisableDelete(true);   
                v_toast_msg = deletedMessage;
            }

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }

    const setNewRecordIDFunction = async(p_v_data) => {        

        // console.log("TabID >> ", p_v_data0]);

        setTableRecordID(await p_v_data);        
    }

    const setPopUpDDFalseFunction = (p_data) => {        
        setConditionalCSS(false);
    }    

    const handleInputFocus = (event) => {
        event.preventDefault();

        // to be used for show pop single or multi select

        // const { name, value } = event.target;        
    };

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        // console.log('in handleInputValue function')

        handleInputValueFun(name, value);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        
        
        if (value === 'BTN_NEW'){            
            setBlankFields();
            return;
        }else if (value === 'BTN_PRINT'){
            
            if (TableRecordID === 0) {
                toast.error(whenStudentNotFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            return;
        }else if (value === 'BTN_EXPORT_EXCEL'){
                        
            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        }else if (value === 'BTN_EXPORT_PDF'){
            
            return;
        }else if (value === 'BTN_FIND'){            
            navigate(`${v_findToNavigateName}`); 
            return;
        }else if (value === 'BTN_COPY_TO_BRANCH'){
            return;
        }else if (value === 'BTN_ASSIGN_BRANCH'){
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }else if(value === 'BTN_BRANCH_WISE_ASSIGNMENT'){
            navigate('/bwal');
            return;
        }

    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    
    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

       
    }

    const goCheckDataFunction = (event) => {
        event.preventDefault();
        dispatch(emptyGlobalDataFun());
    }

return (

<>
    {
        ShowConfirmModelSave ? 
        <div className="centerWindow_css">
            <ConfirmModelSave transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

    {
        ShowConfirmModelUpdateDelete ? 
        <div className="centerWindow_css w-[32%]">
            <ConfirmModelUpdateDelete transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">

    <div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

    <div className="form_div_main_css p-28 bg-slate-200">
        <div className="form_div_inner_css grid grid-cols-3 bg-slate-200">
            
            <div>
                <label htmlFor="led_name" className="label_inner_page_css required_css">Ledger Name</label>
                <input className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 6)}
                    type="text"                                
                    name="led_name"
                    id="led_name"
                    value={led_name}                                    
                    onChange={handleInputValue}                    
                    onFocus={handleInputFocus}                    
                ></input>
            </div>

            <div>
                <label htmlFor="vech_no" className="label_inner_page_css required_css">Print Name</label>
                <input className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 6)}
                    type="text"                                
                    name="print_name"
                    id="print_name"
                    value={print_name}                                    
                    onChange={handleInputValue}                    
                    onFocus={handleInputFocus}                    
                ></input>
            </div>


            <div>        
                <label htmlFor="under_ac_group_id" className="label_inner_page_css required_css">Under Group</label>
                <select className="input_inner_page_css"                
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="under_ac_group_id"
                    id="under_ac_group_id"
                    value={under_ac_group_id}                                   
                    onChange={handleInputValue}               
                    onFocus={handleInputFocus}                    
                >
                    <option></option>
                    {
                        under_ac_group_idItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>  

            <div>        
                <label htmlFor="is_sub_led" className="label_inner_page_css">Sub-Ledger ?</label>
                <select className="input_inner_page_css"                
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="is_sub_led"
                    id="is_sub_led"
                    value={is_sub_led}                   
                    onChange={handleInputValue} 
                    onFocus={handleInputFocus}
                >
                    <option></option>
                    {
                        YesNoList?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>        
                <label htmlFor="used_sale_inv" className="label_inner_page_css">Used in Sales Invoice Entry</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="used_sale_inv"
                    id="used_sale_inv"
                    value={used_sale_inv}                   
                    onChange={handleInputValue} 
                    onFocus={handleInputFocus} 
                >
                    <option></option>
                    {
                        YesNoList?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>  

            <div>        
                <label htmlFor="used_pur_inv" className="label_inner_page_css">Used in Purchase Invoice Entry</label>
                <select className="input_inner_page_css"                
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="used_pur_inv"
                    id="used_pur_inv"
                    value={used_pur_inv}                    
                    onChange={handleInputValue} 
                    onFocus={handleInputFocus}
                >
                    <option></option>
                    {
                        YesNoList?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>
                <label htmlFor="open_bal" className="label_inner_page_css required_css">Opening Balance</label>
                <input className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 6)}
                    type="text"                                
                    name="open_bal"
                    id="open_bal"
                    value={open_bal}                                    
                    onChange={handleInputValue}                    
                    onFocus={handleInputFocus}                    
                ></input>
            </div>            
            
            <div>        
                <label htmlFor="dr_cr" className="label_inner_page_css">Debit/Credit</label>
                <select className="input_inner_page_css"                
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="dr_cr"
                    id="dr_cr"
                    value={dr_cr}                   
                    onChange={handleInputValue} 
                    onFocus={handleInputFocus}                    
                >
                    <option></option>
                    {
                        dr_crItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>
            
            <div>
                <label htmlFor="as_on_date" className="label_inner_page_css">As-on-Date</label>
                <input className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 7)}    
                    type="date"                            
                    name="as_on_date"
                    id="as_on_date"
                    value={as_on_date}                   
                    onChange={handleInputValue}   
                    onFocus={handleInputFocus}
                ></input>
            </div>
            
            <div className="col-span-3">
                <label className="label_inner_page_css">Notes</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 33)}
                    name="notes"
                    id="notes"
                    value={notes}                    
                    onChange={handleInputValue}                                    
                    onFocus={handleInputFocus}                    
                ></input>              
            </div>
            
        </div>
    </div>  

    <nav className="btn_nav_inner_css">
    <div className="btn_div_main_css">

        <div className="btn_div_inner_css">

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_new_css"
                            value = 'BTN_BRANCH_WISE_ASSIGNMENT'
                            onClick={handleButtonValue}>
                            Branch Wise Assignment
                        </button>
                    )}
                </div>

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_new_css"
                            value = 'BTN_NEW'
                            onClick={handleButtonValue}>
                            New
                        </button>
                    )}
                </div>

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_save_css"
                            disabled={disableSave}
                            onClick={createRecordFunction}>
                            Save
                        </button>
                    )}
                </div>

                {/* <div>                
                    {userAuthData.allow_delete === 1 && (
                        <button className="btn_delete_css"
                            value = 'DELETE'
                            disabled={disableDelete}
                            onClick={createRecordFunction}>
                            Delete
                        </button>
                    )}
                </div>                */}

                <div>
                    {userAuthData.allow_read === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_FIND'
                            onClick={handleButtonValue}>
                            Find
                        </button>
                    )}
                </div>                             

                <div>
                    <button className="btn_back_css"
                        value = 'BTN_BACK'
                        onClick={handleButtonValue}>
                        Back
                    </button>
                </div>               

                {/* <div>
                    <button className="btn_back_css"
                        onClick={goCheckDataFunction}>
                        Check Data
                    </button>
                </div> */}
                
        </div>
        
    </div>
    </nav>

    </form>
:null
}

</>
);};

export default AccountLedger;