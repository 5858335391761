import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Moment from 'moment';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import CRUDContext from '../components/CRUDContext';
// import DropDownTableComponent from "../components/DropDownTableComponent";

import SearchStudentDetails from "../components/SearchStudentDetails";

import { emptyTempDataFun, setTempDataFun, emptyGlobalDataFun } from '../components/redux/actions';

import Cookies from 'js-cookie';

const StudentFeeCollection = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';    
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);    
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);      
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);   

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disableSave, setdisableSave] = useState(0);    
    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');
    const [AllowUpdateTag, setAllowUpdateTag] = useState(false);

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData); 
    const ReduxTempData = useSelector((state) => state.TempData);
    const ReduxDDFeeGroupTypeData = useSelector((state) => state.DDFeeGroupTypeData);    
   
    // define drop downs inputs  

    const [rec_date, setrec_date] = useState(0);    
    const [st_id, setst_id] = useState('');
    const [adm_no, setadm_no] = useState('');
    const [st_name_display, setst_name_display] = useState('');
    const [st_father_display, setst_father_display] = useState('');
    const [st_class_display, setst_class_display] = useState('');
    const [st_sec_display, setst_sec_display] = useState('');
    const [st_stream_display, setst_stream_display] = useState('');
    const [rec_no, setrec_no] = useState();

    const [totaldueamt, settotaldueamt] = useState();
    const [discountfeeamt, setdiscountfeeamt] = useState();
    const [totalfineamt, settotalfineamt] = useState();
    const [discountfineamt, setdiscountfineamt] = useState();
    const [totalamtpaid, settotalamtpaid] = useState(); 
    const [ref_no, setref_no] = useState();  
    const [chequedate, setchequedate] = useState();
    const [chequeno, setchequeno] = useState(); 
    const [totalbalanceamt, settotalbalanceamt] = useState();
    const [totaladvancedamt, settotaladvancedamt] = useState();       
    
    const [checktillmonth, setchecktillmonth] = useState(0);

    const [actualFeeAmt, setactualFeeAmt] = useState(0);
    const [actualFeeFineAmt, setactualFeeFineAmt] = useState(0);

    const [headingcolumn, setheadingcolumn] = useState([]);
        
    // When we will use Multiple selection in Drop Down

    const [ShowSearchStudentDetailsPopUp, setSearchStudentDetailsPopUp] = useState(false);      

    // define drop downs items    

    const [param_st_fee_coll_group_id, setparam_st_fee_coll_group_id] = useState(0);
    const [param_st_fee_coll_group_idItems, setparam_st_fee_coll_group_idItems] = useState([]);

    const [bank_param_id, setbank_param_id] = useState();    
    const [bankitems, setbankitems] = useState([]);    

    const [paymode_param_id, setpaymode_param_id] = useState();
    const [paymodeitems, setpaymodeitems] = useState([]);    
    
    // define other input    
    
    const [notes, setnotes] = useState();    

    const [p_till_month, setp_till_month] = useState(0);

    let v_input_result;

    // define variable for tables entry (master detail form)
    
    const [items, setItems] = useState([]);
    const [origionalData, setOrigionalData] = useState([]);    
    const [StudentListitems, setStudentListitems] = useState([]);

    // define standard errors and entry required messages

    // const [errors_message_adm_no, setErrors_message_adm_no] = useState('');
    // const [errors_adm_no, setErrors_adm_no] = useState(false);

    // define form name and navigation details

    const v_urlSaveUpdateDelete = '10_url_fee_coll_master_new';
    const v_backToNavigateName = '/Dashboard';
    const v_findToNavigateName = '/Fcview';
    const v_main_form_heading = 'Fee Collection !';
    const v_frm_no = 10;

    const {
        deletedMessage,
        createdMessage,
        updatedMessage,        
        whenStudentNotFoundMessage,
        WhenRecordNotFoundToUpdate,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);      

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code

                setv_open_frm(true);                

                setdisableSave(false);                

                // console.log("Update allow_update >> ", result.allow_update)
                
                const v_token = Cookies.get('token');

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_drop_down/38/${ReduxGlobalData[0].br}/0/PAY_MODE`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data                
                setpaymodeitems(updatedata);

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/9_url_student_master_find_fun_brfy/9/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/0`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;                
                setStudentListitems(await updatedata);

                if (ReduxGlobalData[0].fcg === 0 && ReduxDDFeeGroupTypeData.length > 0){
                    setparam_st_fee_coll_group_idItems(ReduxDDFeeGroupTypeData[0]);
                }else{
                    setparam_st_fee_coll_group_id(ReduxGlobalData[0].fcg);
                }

                setBlankFields();

                if (ReduxTempData.length > 0){                    
                    if(result.allow_update === 2){setAllowUpdateTag(true)};
                    dispatch(emptyTempDataFun());
                }
            }
        }
        loadData();
    }, [ReduxGlobalData[0]]);

    useEffect(() => {
        setBlankFields();
    }, [ReduxGlobalData[0]]);
       
    function setBlankFields() {
        setsaveUpdateTag('SAVE');
        setAllowUpdateTag(false);

        setdisableSave(false);             

        setTableRecordID(0);
        
        setst_id('');
        setadm_no('');
        setst_name_display('');
        setst_father_display('');
        setst_class_display('');
        setst_sec_display('');
        setst_stream_display('');
        setrec_no('');

        settotaldueamt('');
        setdiscountfeeamt('');
        settotalfineamt('');
        setdiscountfineamt('');
        settotalamtpaid('');
        setref_no('');
        setbank_param_id('');
        setchequedate('');
        setchequeno('');
        settotalbalanceamt('');
        settotaladvancedamt('');        

        setItems([]);
        setOrigionalData([]);
        setheadingcolumn([]);
        setp_till_month(0);
        
        setchecktillmonth(0);
        setactualFeeAmt(0);
        setactualFeeFineAmt(0);
        setnotes('');
        setpaymode_param_id(ReduxGlobalData[0].paym);

        var today = new Date();
        setrec_date(Moment(today).format('YYYY-MM-DD'));

    }

    const handleInputValueFun = (name, value) => {

        let v_pre_value = 0;        
        

        if (name === 'param_st_fee_coll_group_id') {
            setparam_st_fee_coll_group_id(value);        
            setBlankFields();
            return;
        }else if (name === 'paymode_param_id') {
            setpaymode_param_id(value);        
            return;
        }else if (name === 'discountfeeamt') {            
            
            if (totaldueamt === undefined || totaldueamt.toString().trim().length === 0){
                setdiscountfeeamt('');
                return;
            }

            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);

            // check if entered fee amount is not > then actual fee amount

            if (parseFloat(value) <= parseFloat(actualFeeAmt)){
                setdiscountfeeamt(v_input_result.v_value);
                CalculateAndSetFinalAmount(v_input_result.v_value, discountfineamt, totalamtpaid, 'N');
            }else if (value.length === 0) {
                setdiscountfeeamt('');
                CalculateAndSetFinalAmount(0, discountfineamt, totalamtpaid, 'N');
            }
            
            return;
        }else if (name === 'discountfineamt') {
            
            if (totalfineamt === undefined || totalfineamt.toString().trim().length === 0){
                setdiscountfineamt('');
                return;
            }
            
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);
            
            // check if entered discount amount is not > then discount fee amount            

            if (parseFloat(value) <= parseFloat(actualFeeFineAmt)){
                setdiscountfineamt(v_input_result.v_value);
                CalculateAndSetFinalAmount(discountfeeamt, v_input_result.v_value, totalamtpaid, 'N');
            }else if (value.length === 0) {
                setdiscountfineamt('');
                CalculateAndSetFinalAmount(discountfeeamt, 0, totalamtpaid, 'N');
            }
                      
            return;
        }else if (name === 'totalamtpaid') {
            
            if (totaldueamt === undefined || totaldueamt.toString().trim().length === 0){
                settotalamtpaid('');
                return;
            }

            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);           
            settotalamtpaid(v_input_result.v_value);
            CalculateAndSetFinalAmount(discountfeeamt, discountfineamt, v_input_result.v_value, 'Y');
            return;
        }else if (name === 'ref_no') {            
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);       
            setref_no(v_input_result.v_value);            
            return;
        }else if (name === 'notes') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setnotes(capitalizeWordFun(v_input_result.v_value));            
            return;
        }        

    }
    
    const CalculateAndSetFinalAmount = (p_disc_fee, p_disc_fine, p_amt_paid, p_amt_paid_yes_no) => {

        // shiv

        // console.log("In CalculateAndSetFinalAmount");

        let v_fee = 0, v_actual_fee = 0, v_disc_fee = 0, v_fine = 0, v_disc_fine = 0, v_amt_paid = 0, v_actual_amt_paid = 0, v_balance = 0, v_adv_amt = 0;

        if (parseFloat(totaldueamt) > 0){v_fee = totaldueamt;}
        if (parseFloat(totalfineamt) > 0){v_fine = totalfineamt;}
        if (parseFloat(p_disc_fee) > 0){v_disc_fee = p_disc_fee;}
        if (parseFloat(p_disc_fine) > 0){v_disc_fine = p_disc_fine;}
        if (parseFloat(p_amt_paid) > 0){v_amt_paid = p_amt_paid;}

        v_actual_fee = parseFloat(v_fee) - parseFloat(v_fine);
        v_actual_amt_paid = parseFloat(v_fee) - (parseFloat(v_disc_fee) + parseFloat(v_disc_fine));
        
        // console.log("v_fee >> ", v_fee);
        // console.log("v_actual_fee >> ", v_actual_fee);
        // console.log("v_disc_fee >> ", v_disc_fee);
        // console.log("v_fine >> ", v_fine);
        // console.log("v_disc_fine >> ", v_disc_fine);
        // console.log("v_amt_paid >> ", v_amt_paid);
        // console.log("v_actual_amt_paid >> ", v_actual_amt_paid);

        if (p_amt_paid_yes_no === 'N'){
            settotalamtpaid(v_actual_amt_paid);
            settotalbalanceamt('');
            settotaladvancedamt('');
        }
        
        if (p_amt_paid_yes_no === 'Y'){

            // console.log(v_amt_paid, ' >> ', v_actual_amt_paid)

            if(parseFloat(v_amt_paid) === parseFloat(v_actual_amt_paid)){
                settotalbalanceamt('');
                settotaladvancedamt('');
            }else if(parseFloat(v_amt_paid) < parseFloat(v_actual_amt_paid)){
                settotaladvancedamt('');
                settotalbalanceamt(parseFloat(v_actual_amt_paid) - parseFloat(v_amt_paid));
            }else{
                settotalbalanceamt('');
                settotaladvancedamt(parseFloat(v_amt_paid) - parseFloat(v_actual_amt_paid));
            }
        }

    }

    // const CalculateAndSetFinalAmount = (p_disc_fee, p_fine_fee, p_amt_paid) => {

    //     // shiv

    //     let v_fee = 0, v_actual_fee = 0, v_disc_fee = 0, v_fine = 0, v_disc_fine = 0, v_amt_paid = 0, v_balance = 0, v_adv_amt = 0;




    //     return;

    //     let v_fine_fee = 0, v_totalamtpaid = 0;
        
    //     if (p_disc_fee === undefined || p_disc_fee.toString().length === 0){
    //         v_disc_fee = 0;
    //     }else{
    //         v_disc_fee = p_disc_fee;
    //     }

    //     if (p_fine_fee === undefined || p_fine_fee.toString().length === 0){
    //         v_fine_fee = 0;
    //     }else{
    //         v_fine_fee = p_fine_fee;
    //     }

    //     if (p_amt_paid === undefined || p_amt_paid.toString().length === 0){
    //         v_amt_paid = 0;
    //     }else{
    //         v_amt_paid = p_amt_paid;
    //     }            

    //     // console.log("v_totaldueamt >> ", totaldueamt);
    //     // console.log("v_totalfineamt >> ", totalfineamt);
    //     // console.log("v_disc_fee >> ", v_disc_fee);
    //     // console.log("v_fine_fee >> ", v_fine_fee);
    //     // console.log("v_amt_paid >> ", v_amt_paid);        

    //     // settotaldueamt(v_totaldueamt);
    //     // settotalfineamt(v_totalfineamt);
    //     // settotalamtpaid(v_totaldueamt);

    //     // shiv
        
    //     v_totalamtpaid = parseFloat(totaldueamt) - (parseFloat(v_disc_fee) + parseFloat(v_fine_fee));
        
    //     settotalamtpaid(v_totalamtpaid);   
        
    //     let v_balance_advance_amt = parseFloat(v_totalamtpaid) - parseFloat(v_amt_paid)

    //     if (parseFloat(v_balance_advance_amt) > 0){
    //         settotaladvancedamt('');
    //         settotalbalanceamt(v_balance_advance_amt);
    //     }else{
    //         settotalbalanceamt('');
    //         settotaladvancedamt(Math.abs(v_balance_advance_amt));
    //     }
        
    //     // settotalbalanceamt(parseFloat(v_totalamtpaid) - parseFloat(v_amt_paid));

    //     // settotaladvancedamt(parseFloat(v_amt_paid) - parseFloat(v_totalamtpaid));


    // }

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target; 

        let v_param_st_fee_coll_group_id = 0, v_totalbalanceamt = 0, v_totaladvancedamt = 0, v_discountfineamt = 0, v_totalfineamt = 0, v_bank_param_id = 0, v_discountfeeamt = 0;
        let v_rec_date = null;   
        
        let insupdel_type = '';       
        
        if (value === 'DELETE'){
            insupdel_type = 'DELETE';
        }else{
            if (TableRecordID === 0){
                insupdel_type = 'INSERT';            
            }else{

                if (TableRecordID === 0){
                    toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return;
                }

                if (userAuthData.allow_update === 1){
                    setAllowUpdateTag(false);
                    insupdel_type = 'UPDATE';
                }else{
                    setAllowUpdateTag(true);
                    return;
                }                  
            }
        }

        if (value != 'DELETE'){

            // need to validate the date here

            if (parseInt(ReduxGlobalData[0].fcg) === 0){
                if(param_st_fee_coll_group_id === undefined || param_st_fee_coll_group_id.length === undefined || param_st_fee_coll_group_id.length === 0){
                    toast.error('Entry required for Fee Type !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return; 
                }     
                v_param_st_fee_coll_group_id =  param_st_fee_coll_group_id;           
            }else{            
                v_param_st_fee_coll_group_id = ReduxGlobalData[0].fcg;
            }

            if (st_id.trim().length === 0){
                toast.info('Entry required for Student !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }    

            if (parseFloat(totalamtpaid) > parseFloat(totaldueamt)){                
                toast.error('Invalid received amount. Received amount should be less or equal to Due Amount !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;                
            }

            if (rec_date.length === 0){
                toast.info('Entry required for Receipt date !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;                
            }else{
                v_rec_date = rec_date;
            }
    
            if (Moment(rec_date, "YYYY-MM-DD", true).isValid())
            {
                v_rec_date = rec_date
            }else{         
                v_rec_date = null;
            }

            if (totalamtpaid.length === 0 || totalamtpaid === 0){
                toast.info('Entry required for Amount paid !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            // console.log("paymode_param_id >> ", parseInt(paymode_param_id))
            
            if(parseInt(paymode_param_id) > 0){
            }
            else{
                toast.info('Entry required for Payment mode !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            if(bank_param_id === undefined || bank_param_id.length === undefined || bank_param_id.length === 0){
                v_bank_param_id = 0;        
            }else{
                v_bank_param_id = bank_param_id;
            }

            if (totalfineamt.length === 0 || totalfineamt === 0){
                v_totalfineamt = 0;            
            }else{
                v_totalfineamt = totalfineamt;
            }

            if (discountfineamt.length === 0 || discountfineamt === 0){
                v_discountfineamt = 0;            
            }else{
                v_discountfineamt = discountfineamt;
            }

            if (totalbalanceamt.length === 0 || totalbalanceamt === 0){
                v_totalbalanceamt = 0;           
            }else{
                v_totalbalanceamt = totalbalanceamt;
            }
    
            if (totaladvancedamt.length === 0 || totaladvancedamt === 0){
                v_totaladvancedamt = 0;            
            }else{
                v_totaladvancedamt = totaladvancedamt;
            }
            
            // if (TableRecordID === 0){
            //     toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
            //         hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
            //         draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            //     });
            //     return;
            // }

        }
        
        // console.log("insupdel_type >> ", insupdel_type

        const data = {
            
            id: TableRecordID,
            insupdel_type,
            under_comp_group_id : ReduxGlobalData[0].ag,
            under_comp_branch_id : ReduxGlobalData[0].br,
            fin_year : ReduxGlobalData[0].fy,
            param_st_fee_coll_group_id : v_param_st_fee_coll_group_id,
            rec_date: Moment(rec_date).format('YYYY-MM-DD'),
            st_id,
            rec_no : 0,             
            totalamtpaid, 
            totaldueamt,
            totalfineamt : v_totalfineamt, 
            discountfineamt : v_discountfineamt,
            discountfeeamt : v_discountfeeamt, 
            totalbalanceamt : v_totalbalanceamt, 
            totaladvancedamt : v_totaladvancedamt, 
            paymode_param_id,
            ref_no, 
            bank_param_id : v_bank_param_id, 
            chequedate : null,
            chequeno:null,                           
            notes,
            active_status:1,

            feegroupoption :null,
            checktillmonth,
        };
        
        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }    
    }    

    const setStudentModelFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setSearchStudentDetailsPopUp(false);
        setConditionalCSS(false);        

        setTableRecordID(0);
        setst_id(p_data.tempStudentid);
        setadm_no(p_data.tempAdmNo);
        setst_name_display(p_data.tempStudentName);
        setst_father_display(p_data.tempFatherName);
        setst_class_display(p_data.tempClass);
        setst_sec_display(p_data.tempSec);
        setst_stream_display(p_data.tempStream);

        getSavedDetailFunction(p_data.tempStudentid);
    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);        

        if (p_data.ModelYesNoAnswer === 'YES'){
            setdisableSave(true);
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async(p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final:p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final:p_last_updel_notes_final,
        }        

        const v_token = Cookies.get('token');

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });
        
        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }
    
    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }     

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                    
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);
            
            // console.log("updatedata.TabID.v_data >> ", updatedata.v_data);
            
            if (updatedata.transType === 'INSERT'){                         
                setsaveUpdateTag('UPDATE');
                setdisableSave(true);   
                v_toast_msg = createdMessage;                
            }else if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;
                setdisableSave(true);   
            }else if (updatedata.transType === 'DELETE'){
                setdisableSave(true);                   
                v_toast_msg = deletedMessage;
            }

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }

    const setNewRecordIDFunction = async(TabID, p_v_data) => {

        // console.log("TabID >> ", p_v_data[0].p_v_id, p_v_data[0].p_rec_no);

        setTableRecordID(await p_v_data[0].p_v_id);
        setrec_no(await p_v_data[0].p_rec_no);      
    }

    const getSavedDetailFunction = async (p_id) => {        
        
        const v_token = Cookies.get('token');        

        response = await axios.get(`url_fee_bal_amt_fun/10_url_fee_bal_amt_crosstab_fun/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${p_id}/${param_st_fee_coll_group_id}/${rec_date}`,
            { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;

        // console.log("crosstab updatedata >> ", updatedata, p_id);
        
        if (updatedata.length === 0){

            toast.info("No pending due find by student !", {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,
            });

            return;
        }

        // create the empty list items

        let emptyListItems = [];

        for (let i = 0; i < updatedata.length; i++) {
            let v_temp = {}
            for (const [key, value] of Object.entries(updatedata[i])) {
                if(key === 'Particulars'){
                    v_temp[key] = value
                }else{
                    v_temp[key] = null
                }
            }
            emptyListItems.push(v_temp)            
        }

        setItems(emptyListItems);        
        setOrigionalData(updatedata);
        
        const column1 = Object.keys(await updatedata[0]);

        setheadingcolumn(column1);

        if (userAuthData.allow_update === 1){
            setAllowUpdateTag(false);
        }else{
            setAllowUpdateTag(true);
        }

    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        // console.log("handleInputFocus value >> ", value)

        if (name === 'adm_no'){
            setBlankFields();

            if (parseInt(ReduxGlobalData[0].fcg) === 0){
                if(param_st_fee_coll_group_id === undefined || param_st_fee_coll_group_id.length === undefined || param_st_fee_coll_group_id.length === 0){
                    toast.error('Entry required for Fee Type !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return; 
                }     
                
            }

            setSearchStudentDetailsPopUp(true);            
            return;
        }else{
            setSearchStudentDetailsPopUp(false);
        }

    };

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        handleInputValueFun(name, value);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        if (value === 'ADD_NEW_ROW'){
            
            if(AllowUpdateTag){return;}
            return;
        }else if (value === 'BTN_NEW'){            
            setBlankFields();
            return;
        }else if (value === 'BTN_PRINT'){
            
            if (TableRecordID === 0) {
                toast.error(whenStudentNotFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            // console.log('BTN_PRINT TableRecordID >> ', TableRecordID)
            
            const temp_data = {
                temptableid: TableRecordID,                
                back_url: 'Fc'
            }
            
            dispatch(setTempDataFun(temp_data));

            // here we are printing the fee receipt

            navigate("/FcprFkg");

        }else if (value === 'BTN_EXPORT_EXCEL'){
                        
            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        }else if (value === 'BTN_EXPORT_PDF'){
            
            return;
        }else if (value === 'BTN_FIND'){            
            navigate(`${v_findToNavigateName}`); 
            return;
        }else if (value === 'BTN_COPY_TO_BRANCH'){
            return;
        }else if (value === 'BTN_ASSIGN_BRANCH'){
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };
    
    const goCheckDataFunction = (event) => {
        event.preventDefault();
        
        console.log("headingcolumn >> ", headingcolumn);

        console.log("Items >> ", items);

        setp_till_month(6);
        
        ThData();

        // setItems(emptyListItems);

    }

    // For details entry (entry in table)
    // For details entry (entry in table)    
    
    const handleChangeCheckBox = (event, p_index, p_data) => {
        const { name, checked } = event.target;

        // console.log("In checkbox >> ", name, checked);        
        
        CalculateAndSetFeeDetail(p_data.substring(2,4), p_index, checked, p_data);

        if(AllowUpdateTag){return};        
    }

    const CalculateAndSetFeeDetail = (p_till_month, p_index, p_checked, p_data) => {
        
        // console.log("p_till_month >> ", p_till_month)

        settotaldueamt('');
        setdiscountfeeamt('');
        settotalfineamt('');
        setdiscountfineamt('');
        settotalamtpaid('');
        settotalbalanceamt('');
        settotaladvancedamt('');

        // reset the checkbox on table header (fee month display section)

        if (p_checked){
            setp_till_month(p_index);    
        }else{
            setp_till_month(p_index - 1);
            p_till_month = p_till_month - 1; 
        }

        let v_select_month = ['Particulars'];

        for (let i = 0; i < 1; i++) {
            for (const [key, value] of Object.entries(origionalData[i])) { 
                if(parseInt(key.substring(2,4)) <= p_till_month){
                    // console.log("Key >> ", key)
                    v_select_month.push(key)
                }
            }
        }

        // console.log(v_select_month);

        // v_select_month = ['Particulars', '__04July', '__05August'];
        const v_result = origionalData.map(data => v_select_month.reduce((o, k) => (o[k] = data[k], o), {}));
        
        // console.log(v_result);

        setItems(v_result);

        // calculate the total fee amount till month (which is clicked by user)

        let v_totaldueamt = 0, v_totalfineamt = 0;

        for (let i = 0; i < origionalData.length -1; i++) {
            for (const [key, value] of Object.entries(origionalData[i])) {
                if(parseInt(key.substring(2,4)) <= p_till_month){                   
                    if (parseFloat(value?.toString()) > 0){
                        v_totaldueamt = parseInt(v_totaldueamt) + parseInt(value);
                    }
                }
            }
        }

        // calculate the sum of value where fine Particulars = fine 

        const totalfineamt_item = v_result.filter(item => {
            return item.Particulars != null && item.Particulars.indexOf('Fine') > 0;
        });

        for (let i = 0; i < totalfineamt_item.length; i++) {
            for (const [key, value] of Object.entries(totalfineamt_item[i])) {
                if(parseInt(key.substring(2,4)) <= p_till_month){                   
                    if (parseFloat(value?.toString()) > 0){
                        v_totalfineamt = parseInt(v_totalfineamt) + parseInt(value);
                    }
                }
            }
        }

        // console.log("v_result >> ", v_result);

        setchecktillmonth(p_till_month);
        
        settotaldueamt(v_totaldueamt);
        settotalfineamt(v_totalfineamt);
        settotalamtpaid(v_totaldueamt);

        // set the actual fee amd fine amount

        setactualFeeAmt(v_totaldueamt - v_totalfineamt);
        setactualFeeFineAmt(v_totalfineamt);
        
        // setrec_no(v_totaldueamt - v_totalfineamt);

    }    

    const ThData =()=>{
        
        // let p_till_month = 8;

        // console.log("in heading function >> ", p_till_month);
    
        return headingcolumn.map((data, index)=>{

            if (index === 0){
                return <th className = "table_th_td_css border-none text-left w-[22%]"
                key={data}>{data}
            </th> 
            
            }else{
                return <th className = "table_th_td_css text-center w-[6.5%]"
                    key={data}
                    >{data.substring(4, 10)}

                    { parseInt(index) <= p_till_month ?
                    
                        <th className="table_th_td_css w-[6.5%] border-none">
                            
                            <input className="table_check_inner_page_css"                            
                                type="checkbox" name={data}
                                checked={true}
                                // value={ischecked}
                                onChange={(e) => handleChangeCheckBox(e, index, data)}
                                disabled = {saveUpdateTag === 'SAVE' ? false : true}                                                              
                            />

                            {/* {console.log(index)} */}
                        </th>                         

                        :                                

                        <th className="table_th_td_css w-[6.5%] border-none">
                            <input className="table_check_inner_page_css"                            
                                type="checkbox" name={data}
                                checked={false}
                                // value={ischecked}
                                onChange={(e) => handleChangeCheckBox(e, index, data)}
                                disabled = {saveUpdateTag === 'SAVE' ? false : true}                                                              
                            />

                            {/* {console.log("In False")} */}

                        </th>

                    
                    }

                </th>  
            } 

        })
    }
    
    const tdData =() =>{

        return items.map((data, i)=>{
            return (
                <tr className="table_thead_tr_css table_tbody_hover_css">
                    {

                        headingcolumn.map((v_data, index)=>{  
                            
                            if (index === 0){
                                return <th className = "table_th_td_css text-left w-[22%]">
                                {data[v_data]}</th> 
                            
                            }else{
                                return <th className = "table_th_td_css text-center w-[6.5%]">
                                {data[v_data]}</th>
                            }

                        })
                    }
                </tr>
            )
        })      
            
    }    

return (

<>

    {
        ShowSearchStudentDetailsPopUp ?
            <div className="w-[100%] h-[12%] absolute">
                <SearchStudentDetails transType={TransType} p_st_items={StudentListitems} setStudentModelFalseFunction={setStudentModelFalseFunction} />
            </div>
            : null
    }

    {
        ShowConfirmModelSave ? 
        <div className="centerWindow_css">
            <ConfirmModelSave transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

    {
        ShowConfirmModelUpdateDelete ? 
        <div className="centerWindow_css w-[32%]">
            <ConfirmModelUpdateDelete transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">

    <div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

    <div className="form_div_main_css h-[8vh]">
        <div className="form_div_inner_css grid grid-cols-10">

            {/* <div>
                <label className="label_inner_page_css text-center">Student ID</label>
                <div className="input_inner_page_css text-center font-semibold">{st_id}</div>
            </div>            
            */}

            {param_st_fee_coll_group_idItems.length > 0 && (
                <div>
                    <label className="label_inner_page_css required_css">Fee Type</label>
                    <select className="input_inner_page_css"
                        ref={(ref) => addInputRef(ref, 0)}       
                        // autoFocus         
                        name="param_st_fee_coll_group_id"
                        id="param_st_fee_coll_group_id"
                        value={param_st_fee_coll_group_id}                                      
                        onChange={handleInputValue}
                        onFocus={handleInputFocus}                        
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}
                    >
                        <option></option>
                        {
                            param_st_fee_coll_group_idItems?.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </div>
            )}  

            <div className="col-span-1">
                <label className="label_inner_page_css required_css text-center">Rec.Date</label>
                <input className="input_inner_page_css text-center"
                    name="rec_date"
                    id="rec_date"
                    type="date"                            
                    value={rec_date}
                    onChange={(e) => setrec_date(e.target.value)}
                    disabled = {saveUpdateTag === 'SAVE' ? false : true}                          
                ></input>
            </div>  

            <div>
                <label className="label_inner_page_css required_css text-center">Adm.No.</label>
                <input className="input_inner_page_css text-center font-semibold"
                    type="text"
                    ref={(ref) => addInputRef(ref, 5)}
                    name="adm_no"
                    id="adm_no"
                    value={adm_no}                    
                    onChange={handleInputValue}                                    
                    onFocus={handleInputFocus}
                    disabled = {saveUpdateTag === 'SAVE' ? false : true}
                ></input>                
            </div>                  

            <div className="col-span-2">
                <label className="label_inner_page_css">Name</label>
                <div className="input_inner_page_css font-semibold">{st_name_display}</div>
            </div>

            <div className="col-span-2">
                <label className="label_inner_page_css">Father Name</label>
                <div className="input_inner_page_css font-semibold">{st_father_display}</div>
            </div>

            <div>
                <label className="label_inner_page_css text-center">Class & Sec.</label>
                <div className="input_inner_page_css text-center font-semibold">{st_class_display} - {st_sec_display}</div>
            </div>            

            <div>
                <label className="label_inner_page_css text-center">Stream</label>
                <div className="input_inner_page_css text-center font-semibold">{st_stream_display}</div>
            </div>

            <div>
                <label className="label_inner_page_css text-center">Receipt No.</label>
                <div className="input_inner_page_css text-center font-semibold">{rec_no}</div>
            </div>
                       
        </div>        
        
    </div>

    <table className="table_main_css">
        <thead className="table_thead_css">            
            <tr className="table_thead_tr_css"> 
                {ThData()}
            </tr>
        </thead>     

        <tbody className="table_tbody_css h-[60vh]">
            {tdData()}
        </tbody>
        
    </table>

    <nav className="btn_nav_table_fee_coll_sum_inner_css">
        <thead className="table_thead_css">            
            <tr className="table_thead_tr_css">
                <th className="table_th_td_css text-center w-[7%]">Fee</th>
                <th className="table_th_td_css text-center w-[7%]">Disc.Fee</th>
                <th className="table_th_td_css text-center w-[7%]">Fine</th>
                <th className="table_th_td_css text-center w-[7%]">Disc.Fine</th>
                <th className="table_th_td_css text-center w-[7%]">Amt.Paid</th>
                <th className="table_th_td_css text-left w-[10%]">Pay.Mode</th>
                <th className="table_th_td_css text-center w-[7%]">Ref.No.</th>
                <th className="table_th_td_css text-left w-[20%]">Bank</th>
                <th className="table_th_td_css text-center w-[7%]">Cheq.Date</th>
                <th className="table_th_td_css text-center w-[7%]">Cheq.No.</th>
                <th className="table_th_td_css text-center w-[7%]">Balance</th>
                <th className="table_th_td_css text-center w-[7%]">Adv.Amt.</th>                
            </tr>
        </thead>

        <thead className="table_thead_css">            
            <tr className="table_thead_tr_css">                
                
                <th className="table_input_th_td_css text-center w-[7%]">
                    <div className="table_div_disable_inner_page_css text-center">{totaldueamt}</div>
                </th>

                <th className="table_input_th_td_css text-center w-[7%]">
                    <input className="table_input_inner_page_css text-center"
                        type="text"
                        ref={(ref) => addInputRef(ref, 11)}
                        name="discountfeeamt"
                        id="discountfeeamt"
                        value={discountfeeamt}                                                 
                        onChange={handleInputValue}
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}                                          
                    ></input>
                </th>

                <th className="table_input_th_td_css text-center w-[7%]">
                    <div className="table_div_disable_inner_page_css text-center">{totalfineamt}</div>
                </th>

                <th className="table_input_th_td_css text-center w-[7%]">
                    <input className="table_input_inner_page_css text-center"
                        type="text"
                        ref={(ref) => addInputRef(ref, 11)}
                        name="discountfineamt"
                        id="discountfineamt"
                        value={discountfineamt}                                                 
                        onChange={handleInputValue}
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}                                          
                    ></input>
                </th>               

                <th className="table_input_th_td_css text-center w-[7%]">
                    <input className="table_input_inner_page_css text-center"
                        type="text"
                        ref={(ref) => addInputRef(ref, 11)}
                        name="totalamtpaid"
                        id="totalamtpaid"
                        value={totalamtpaid}                                                 
                        onChange={handleInputValue}
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}                                          
                    ></input>
                </th>                
                
                <th className="table_input_th_td_css text-center w-[10%]">
                    <select className="table_input_inner_page_css"
                            ref={(ref) => addInputRef(ref, 0)}       
                            // autoFocus         
                            name="paymode_param_id"
                            id="paymode_param_id"
                            value={paymode_param_id}                                              
                            onChange={handleInputValue}
                            onFocus={handleInputFocus}
                            disabled = {saveUpdateTag === 'SAVE' ? false : true}
                    > 
                        {
                            paymodeitems?.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </th>

                <th className="table_input_th_td_css text-center w-[7%]">
                    <input className="table_input_inner_page_css text-center"
                        type="text"
                        ref={(ref) => addInputRef(ref, 11)}
                        name="ref_no"
                        id="ref_no"
                        value={ref_no}                                                 
                        onChange={handleInputValue}
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}                                          
                    ></input>
                </th>

                <th className="table_input_th_td_css text-center w-[20%]">
                    <input className="table_div_disable_inner_page_css text-center"
                        type="text"
                        ref={(ref) => addInputRef(ref, 11)}
                        name="bank_param_id"
                        id="bank_param_id"
                        value={bank_param_id}                                                 
                        onChange={handleInputValue}
                        disabled                                      
                    ></input>
                </th>

                <th className="table_input_th_td_css text-center w-[7%]">
                    <input className="table_div_disable_inner_page_css text-center"
                        type="text"
                        ref={(ref) => addInputRef(ref, 11)}
                        name="chequedate"
                        id="chequedate"
                        value={chequedate}                                                 
                        onChange={handleInputValue}
                        disabled                                        
                    ></input>
                </th>

                <th className="table_input_th_td_css text-center w-[7%]">
                    <input className="table_div_disable_inner_page_css text-center"
                        type="text"
                        ref={(ref) => addInputRef(ref, 11)}
                        name="chequeno"
                        id="chequeno"
                        value={chequeno}                                                 
                        onChange={handleInputValue}
                        disabled                                      
                    ></input>
                </th>

                <th className="table_input_th_td_css text-center w-[7%]">
                    <div className="table_div_disable_inner_page_css text-center">{totalbalanceamt}</div>
                </th>

                <th className="table_input_th_td_css text-center w-[7%]">
                    <div className="table_div_disable_inner_page_css text-center">{totaladvancedamt}</div>
                </th>
                       
            </tr>
        </thead>

        <label className="label_inner_page_css">Notes</label>
        <thead className="table_thead_css">
            <tr className="table_thead_tr_css">
                <th className="table_input_th_td_css w-[100%]">
                    <input className="table_input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 11)}
                        name="notes"
                        id="notes"
                        value={notes}                                                 
                        onChange={handleInputValue}
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}                                          
                    ></input>
                </th>               
                                
            </tr>
        </thead>

    </nav>

    <nav className="btn_nav_inner_css">

    <div className="btn_div_main_css">       

        <div className="btn_div_inner_css">                

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_new_css"
                            value = 'BTN_NEW'
                            onClick={handleButtonValue}>
                            New
                        </button>
                    )}
                </div>

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_save_css"
                            disabled={disableSave}
                            onClick={createRecordFunction}>
                            Save
                        </button>
                    )}
                </div>
                
                    
                <div>
                    {userAuthData.print === 1 && (
                        <button className="btn_print_css"
                            value = 'BTN_PRINT'
                            onClick={handleButtonValue}>
                            Print
                        </button>
                    )}
                </div>
              

                <div>
                    {userAuthData.allow_read === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_FIND'
                            onClick={handleButtonValue}>
                            Find
                        </button>
                    )}
                </div>
                            

                <div>
                    <button className="btn_back_css"
                        value = 'BTN_BACK'
                        onClick={handleButtonValue}>
                        Back
                    </button>
                </div>

                {/* <div>                
                    <button className="btn_back_css"
                        onClick={goCheckDataFunction}>
                        Check Data
                    </button>
                </div> */}
                
        </div>
        
    </div>
    </nav>

    </form>
:null
}

</>
);};

export default StudentFeeCollection;

{/* <div class="mx-auto max-w-xs">
    <div>
        <div class="relative">
        <input type="text" id="example13" placeholder=" " class="peer block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500" />
        <label for="example13" class="peer-focus:base absolute left-2 top-0 z-10 -translate-y-2 transform bg-white px-1 text-xs text-gray-500 transition-all peer-placeholder-shown:translate-y-3 peer-placeholder-shown:text-sm peer-focus:-translate-y-2 peer-focus:text-xs peer-disabled:bg-transparent">Full name</label>
        </div>
    </div>
</div> */}
