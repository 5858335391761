// Table name : comp_group

import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';

// import Layout from './Layout';

const Cg = (props) => {
    const dispatch = useDispatch();
    const ReduxData = useSelector((state) => state);

    const [ShowModelFormDeleteUpdate, setModelFormDeleteUpdate] = useState(false);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);
    const [updateDelTag, setUpdateDelTag] = useState('');
    const [updateDelapiName, setUpdateDelapiName] = useState('');
    // const router = useRouter();

    const navigate = useNavigate();
    
    const [group_code, setgroup_code] = useState('') 
    const [group_name, setgroup_name] = useState('')
    const [print_name, setprint_name] = useState('')
    const [under_group_code, setunder_group_code] = useState(0)
    const [notes, setnotes] = useState('') 
    const [show_in_list, setShow_in_list] = useState(1)
    const [active_status, setactive_status] = useState(1)
    
    const [under_group_codeItems, setunder_group_codeItems] = useState([]);

    const YesNoList = [
        // { id: 0, sel_val: 0, sel_desc: "" },
        { id: 1, sel_val: 1, sel_desc: "Yes" },
        { id: 2, sel_val: 2, sel_desc: "No" }
    ];

    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,        
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenPageRefreshMessage
    } = useContext(CRUDContext);

    // // ////////////////////////////

    const getAuthroziedUser = async () => {  
        try{
            const token = localStorage.getItem('token');

            const response = await axios.get(`verifyTokenAPI/`,
                  { headers: { Authorization: `Bearer ${token}` } });            
    
            if (response.status === 401 || response.status === 403){
                localStorage.clear();
                navigate('/Login')
            };

        }catch(error){
            localStorage.clear();
            navigate('/Login')
        }
    }    

    useEffect( () => {        
        getAuthroziedUser();    
    }, []);

    // // ////////////////////////////

    useEffect(() => {
        if (error) {
            toast.error(error);
            clearErrors();
        }

        if (created) {
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
        }

        if (updated) {
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
        }
    }, [error, created, updated, deleted]);

    useEffect(() => {      
        let response = []
        let updatedata = []

        // to fill the drop-down

        const getTableRecordFunction = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_group_codeItems(updatedata);
        }
        getTableRecordFunction('url_comp_group_view_drop_down');    

        // to get the data when user click on view to show the existing record (from cg_view page)

        if (pk_id > 0) {

            // console.log("pk_id is : ", pk_id)

            const getAllTableRecordFunction = async (var_link) => {
                 response = await axios.get(`${var_link}/${pk_id}`,
                 { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

                 updatedata = await response.data.v_out_data;

                 setPkID(pk_id);

                //  console.log("updatedata is : ", updatedata)

                 setgroup_code(updatedata[0].group_code);
                 setgroup_name(updatedata[0].group_name);
                 setprint_name(updatedata[0].print_name);
                 setunder_group_code(updatedata[0].under_group_code);
                 setnotes(updatedata[0].notes); 
                 setShow_in_list(updatedata[0].show_in_list);
                 setactive_status(updatedata[0].active_status);
             }
             getAllTableRecordFunction('url_comp_group_view');
        }

    }, [pk_id])

    const newBlankFieldHandler = (e) => {
        e.preventDefault();        

        setPkID(0);

        setgroup_code('');
        setgroup_name('');
        setprint_name('');
        setunder_group_code(0);
        setnotes(''); 
        setShow_in_list(1);
    }

    const createRecordFunction = (event) => {
        event.preventDefault();

        if (group_name.trim().length === 0){
            toast.info("Entry reuired for group name !")
            clearErrors();
            return;
        }

        if (print_name.trim().length === 0){
            toast.info("Entry reuired for print name !")
            clearErrors();
            return;
        }

        const data = {
            id: pk_id,
            group_code,
            group_name,
            print_name,
            under_group_code,
            notes,
            show_in_list,
            active_status,
        };

        dispatch(emptyCart());
        dispatch(addTempList(data));

        // console.log("pk_id value is : ", pk_id)

        if (pk_id === 0) {
            setUpdateDelTag('')
            setModelModelFormYesNo(true);
        }
        else {
            setUpdateDelTag('UPDATE')
            setUpdateDelapiName('url_comp_group_updel')
            setModelFormDeleteUpdate(true);
        }
    }

    const FindInTablePage = (event) => {
        event.preventDefault();
        navigate('/cg_view')
    }

    const handleDeleteUpdateFunction = (event) => {
        // event.preventDefault();

        // if (userData.userData[0].id.length === 0){
        //     toast.info(whenPageRefreshMessage)
        //     clearErrors();
        //     return;
        // }

        // const data = {
        //     id: pk_id,
        //     last_access_ip : ipAddess,
        //     last_access_by : userData.userData[0].id,    
        // };

        // setUpdateDelTag('DELETE')
        // setUpdateDelapiName('comp_group_delete')

        // dispatch(emptyCart());
        // dispatch(addTempList(data));
        // setModelFormDeleteUpdate(true);
    }

    const handleUnderGroupCodeOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setunder_group_code(getId);
    }

    const handleShowInListOption = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setShow_in_list(getId);
    }

    const handleactive_statusOption = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setactive_status(getId);
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();
        setPkID(0);
        navigate('/brms')
    }

    function handleEnter(event) {
        if (event.keyCode === 13) {
          const form = event.target.form;
          const index = Array.prototype.indexOf.call(form, event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
        }
    }

    return (
        <>
            <form>
            <div className="pt-PaddingTopAfterMenu max-w-full text-my-main-heading-fontSize bg-my-main-heading-color text-my-main-heading-font-color text-center">Company Group</div>
        
            <div className="justify-center items-center inset-0 flex">

                <div className="pt-1 px-2">                   

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Group Code</label>
                    <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                        name="group_code"
                        id="group_code"
                        type="text"
                        // placeholder="Group Code"
                        value={group_code}
                        onChange={(e) => setgroup_code(e.target.value)}
                        onKeyDown={handleEnter} {...props}
                    ></input>

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Group Name</label>
                    <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                        name="group_name"
                        id="group_name"
                        type="text"
                        // placeholder="Group Name"
                        value={group_name}
                        onChange={(e) => setgroup_name(e.target.value)}
                        onKeyDown={handleEnter} {...props}
                    ></input>

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Print Name</label>
                    <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                        name="print_name"
                        id="print_name"
                        type="text"
                        // placeholder="Print Name"
                        value={print_name}
                        onChange={(e) => setprint_name(e.target.value)}
                        onKeyDown={handleEnter} {...props}
                    ></input>                    

                    {/* Start Add Drop-Down For Group Code */}

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Under Group</label>
                    <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                        name="under_group_code" value={under_group_code} onChange={(e) => handleUnderGroupCodeOption(e)}
                        onKeyDown={handleEnter} {...props}>
                        <option>--Select Under Group--</option>
                        {
                            under_group_codeItems.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>

                    {/* End Add Drop-Down For Group Code */}

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Notes</label>
                    <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                        name="notes"
                        id="notes"
                        type="text"
                        // placeholder="Notes"
                        value={notes}
                        onChange={(e) => setnotes(e.target.value)}
                        onKeyDown={handleEnter} {...props}
                    ></input>


                    {/* Start Add Drop-Down For yes/ no */}

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Show-in-List</label>
                    <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                        name="show_in_list" value={show_in_list} onChange={(e) => handleShowInListOption(e)}
                        onKeyDown={handleEnter} {...props}>
                        <option>--Select Option--</option>
                        {
                            YesNoList.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>

                    {/* End Add Drop-Down For yes/ no */}
                    
                    {/* Start Add Drop-Down For yes/ no */}

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Active Status</label>
                    <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                        name="active_status" value={active_status} onChange={(e) => handleactive_statusOption(e)}
                        onKeyDown={handleEnter} {...props}>
                        <option>--Select Option--</option>
                        {
                            YesNoList.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>

                    {/* End Add Drop-Down For yes/ no */}                               

                    <div className="pt-4 py-my-padding-py px-my-padding-px grid grid-cols-4 gap-4">

                        <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={newBlankFieldHandler}>
                            New
                        </button>

                        <button className="bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-my-fontSize text-SaveButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={createRecordFunction}>
                            Save
                        </button>

                        {/* <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={handleDeleteUpdateFunction}>
                            Delete
                        </button> */}

                        <button className="bg-FindButtonBGColor hover:bg-FindButtonHoverColor text-my-fontSize text-FindButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={FindInTablePage}
                        >
                            Find
                        </button>

                        <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={goPrePageFunction}>
                            Back
                        </button>

                    </div>

                    <ModelFormDeleteUpdate api_type={updateDelTag} api_name={updateDelapiName} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)} />

                    <ModelFormYesNo table_name='COMP_GROUP' api_name={'url_comp_group_new'} isVisible={ShowModelFormYesNo} onClose={() => setModelModelFormYesNo(false)} />

                </div>
            </div>
            </form>
        </>
    );
};

export default Cg;

