// Table name : print preview functionality when user will press print button at the time of fee collection

import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import moment, { invalid } from 'moment';

import CRUDContext from '../components/CRUDContext';

// import Layout from '../components/Layout';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

import { useReactToPrint } from "react-to-print";

// import { isAuthenticatedUser } from './isAuthenticated';

import { addTempList, emptyCart } from '../components/redux/actions';

const Fcpr = ({ isVisible, onClose, form_name}) => {  

    // const router = useRouter();

    const navigate = useNavigate();

    const conponentPDF = useRef();
    const dispatch = useDispatch();
    const ReduxData = useSelector((state) => state);    
    
    const [items, setItems] = useState([]);    

    const[v_report_head_1, setv_report_head_1] = useState('')
    const[v_report_head_2, setv_report_head_2] = useState('')
    const[v_report_head_3, setv_report_head_3] = useState('')
    const[v_f_session, setv_f_session] = useState('')
    const[v_rec_date, setv_rec_date] = useState('')
    const[v_rec_no, setv_rec_no] = useState('')
    const[v_st_name, setv_st_name] = useState('')
    const[v_fat_name, setv_fat_name] = useState('')
    const[v_st_class, setv_st_class] = useState('')
    const[v_st_section, setv_st_section] = useState('')
    const[v_adm_no, setv_adm_no] = useState('')
    const[v_fat_mobile, setv_fat_mobile] = useState('')
    const[v_pay_mode, setv_pay_mode] = useState('')
    const[v_totalamtpaid, setv_totalamtpaid] = useState('')
    const[v_totaldueamt, setv_totaldueamt] = useState('')    
    const[v_discountfineamt, setv_discountfineamt] = useState('')
    const[v_totalbalanceamt, setv_totalbalanceamt] = useState('')
    const[v_user_name, setv_user_name] = useState('')
    const[v_notes, setv_notes] = useState('')
    const[v_amttoword, setv_amttoword] = useState('')

    const[v_sumfeeamtpaid, setv_sumfeeamtpaid] = useState(0)
    const[v_sumfeeamtfine, setv_sumfeeamtfine] = useState(0)

    const[v_sumv_tot_due, setv_sumv_tot_due] = useState(0)
    const[v_sumv_adj_amt, setv_sumv_adj_amt] = useState(0)
    const[v_sumv_bal_amt, setv_sumv_bal_amt] = useState(0)  

    useEffect(() => {
        const registerUserdata = async () => {
            let updatedata = [], response = [], v_monthitems = [];

            response = await axios.get(`url_fee_coll_rec_print/${localStorage.getItem('br')}/${localStorage.getItem('fy')}/${localStorage.getItem('tm')}`,
                 { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data;                        
            setItems(updatedata);



            
            // console.log("updatedata is : ", updatedata)
            
            // calculate column wise sum

            let v_due_amt_sum = 0, v_fine_amt_fine = 0, v_tot_due = 0, v_adj_amt = 0, v_bal_amt = 0;
            for (let i = 0; i < updatedata.length; i++){
                v_due_amt_sum = parseFloat(v_due_amt_sum) + parseFloat(updatedata[i].v_due_amt)
                v_fine_amt_fine = parseFloat(v_fine_amt_fine) + parseFloat(updatedata[i].v_fine_amt)

                v_tot_due = parseFloat(v_tot_due) + parseFloat(updatedata[i].v_tot_due)
                v_adj_amt = parseFloat(v_adj_amt) + parseFloat(updatedata[i].v_adj_amt)
                v_bal_amt = parseFloat(v_bal_amt) + parseFloat(updatedata[i].v_bal_amt)
            }

            setv_sumfeeamtpaid(v_due_amt_sum);
            setv_sumfeeamtfine(v_fine_amt_fine);

            setv_sumv_tot_due(v_tot_due);
            setv_sumv_adj_amt(v_adj_amt);
            setv_sumv_bal_amt(v_bal_amt);
            
            response = await axios.get(`url_fee_coll_rec_print_heading_fun/${localStorage.getItem('br')}/${localStorage.getItem('fy')}/${localStorage.getItem('tm')}`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }); 
            updatedata = await response.data.v_out_data;

            // console.log(updatedata)

            setv_report_head_1(updatedata[0].v_report_head_1);
            setv_report_head_2(updatedata[0].v_report_head_2);
            setv_report_head_3(updatedata[0].v_report_head_3);
            setv_f_session(updatedata[0].v_f_session);
            setv_rec_date(moment(updatedata[0].v_rec_date).format('DD-MM-YYYY'));
            setv_rec_no(updatedata[0].v_rec_no);
            setv_st_name(updatedata[0].v_st_name);
            setv_fat_name(updatedata[0].v_fat_name);
            setv_st_class(updatedata[0].v_st_class);
            setv_st_section(updatedata[0].v_st_section);
            setv_adm_no(updatedata[0].v_adm_no);
            setv_fat_mobile(updatedata[0].v_fat_mobile);
            setv_pay_mode(updatedata[0].v_pay_mode);
            setv_totalamtpaid(updatedata[0].v_totalamtpaid);
            setv_discountfineamt(updatedata[0].v_discountfineamt);
            setv_totalbalanceamt(updatedata[0].v_totalbalanceamt);
            setv_user_name(updatedata[0].v_user_name);
            setv_notes(updatedata[0].v_notes);
            setv_amttoword(updatedata[0].v_amttoword);
            setv_totaldueamt(updatedata[0].v_totaldueamt)
            
            // console.log(updatedata);
        }
        registerUserdata();
    }, []);

     const PrintPDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "Userdata",
        // onAfterPrint: () => alert("Data saved in PDF")
    });

    const goPrePageFunction = (event) => {
        event.preventDefault();        
        const v_form_name = localStorage.getItem('fm');
        navigate(`/${v_form_name}`);               
    }

return (

<>

{/* <div className="mt-FromTopMenuMargin justify-center items-center fixed inset-0 bg-my-when-focus-bg-color bg-opacity-100 backdrop-blur-3xl"> */}

    <div className="mt-10" ref={conponentPDF}>       
                        

            {/* <table className="border print-border-color" >
                <thead className="bg-light"> 

                    <tr className="border pl-1 print-border-color text-center">{v_report_head_1}</tr>      
                    <tr className="border pl-1 print-border-color">{v_report_head_2}</tr>      
                    <tr className="border pl-1 print-border-color">{v_report_head_3}</tr>      
                    
                    <tr>                        
                        <th className="border pl-1 print-border-color text-left">Session</th>
                        <td className="border pl-1 print-border-color text-center">{v_f_session}</td>
                        <th className="border pl-1 print-border-color text-left">Rec.Date</th>
                        <td className="border pl-1 print-border-color text-center">{v_rec_date}</td>
                        <th className="border pl-1 print-border-color text-left">Rec.No.</th>                        
                        <td className="border pl-1 print-border-color text-center">{v_rec_no}</td>
                    </tr>

                    <tr>
                        <th className="border pl-1 print-border-color text-left">Name</th>
                        <td className="border pl-1 print-border-color text-left">{v_st_name}</td>
                        <th className="border pl-1 print-border-color text-left">Class</th>     
                        <td className="border pl-1 print-border-color text-left">{v_st_class}</td>                   
                        <th className="border pl-1 print-border-color text-left">Adm.No.</th>   
                        <td className="border pl-1 print-border-color text-left">{v_adm_no}</td>                     
                    </tr>

                    <tr>
                        <th className="border pl-1 print-border-color text-left">Father's Name</th>
                        <td className="border pl-1 print-border-color text-left">{v_fat_name}</td>      
                        <th className="border pl-1 print-border-color text-left">Mobile.No.</th>
                        <td className="border pl-1 print-border-color text-left">{v_fat_mobile}</td>      
                    </tr>

                </thead>                
            </table> */}

            <table className="table-fixed border ml-[200px] w-96 mt-10 font-my-font-name text-rec-fontSize print-border-color">                
                <thead>                                      
                    <tr><th className="border w-[80px]  pl-1 print-border-color text-center">{v_report_head_1}</th></tr>
                    <tr><th className="border w-[60px] pl-1 print-border-color text-center">{v_report_head_2}</th></tr>
                    <tr><th className="border w-[60px] pl-1 print-border-color text-center">{v_report_head_3}</th></tr>
                </thead>
            </table>

            <table className="table-fixed border ml-[200px] w-96 font-my-font-name text-rec-fontSize print-border-color">                   
                
                <thead>                                      
                    <tr>
                        <th className="border w-[50px]  pl-1 print-border-color text-left">Session</th>
                        <td className="border w-[35px] pl-1 print-border-color text-left">{v_f_session}</td>      
                        <th className="border w-[30px] pl-1 print-border-color text-left">Date</th>
                        <td className="border w-[50px] pl-1 print-border-color text-left">{v_rec_date}</td>
                        <th className="border w-[40px] pl-1 print-border-color text-left">Rec.No.</th>
                        <td className="border w-[100px] pl-1 print-border-color text-left">{v_rec_no}</td>
                    </tr>

                </thead>

            </table>

            <table className="table-fixed border ml-[200px] w-96 font-my-font-name text-rec-fontSize print-border-color">               
                
                <thead>                                                         

                    <tr>
                        <th className="border w-[40px]  pl-1 print-border-color text-left">Name</th>
                        <td className="border w-[120px] pl-1 print-border-color text-left">{v_st_name}</td>      
                        <th className="border w-[40px] pl-1 print-border-color text-left">Class</th>
                        <td className="border w-[30px] pl-1 print-border-color text-left">{v_st_class}</td>
                        <th className="border w-[60px] pl-1 print-border-color text-left">Adm.No.</th>
                        <td className="border w-[60px] pl-1 print-border-color text-left">{v_adm_no}</td>
                    </tr> 

                </thead>
            </table>           

            <table className="table-fixed border ml-[200px] w-96 font-my-font-name text-rec-fontSize print-border-color">                
                <thead>                                      
                    <tr>
                        <th className="border w-[80px]  pl-1 print-border-color text-left">Father's Name</th>
                        <td className="border w-[120px] pl-1 print-border-color text-left">{v_fat_name}</td>      
                        <th className="border w-[60px] pl-1 print-border-color text-left">Mobile.No.</th>
                        <td className="border w-[60px] pl-1 print-border-color text-left">{v_fat_mobile}</td>
                    </tr>    
                </thead>
            </table>

            <table className="table-fixed border ml-[200px] w-96 font-my-font-name text-rec-fontSize print-border-color">
                <thead>                            

                    <tr>
                        <th className="border w-20 pl-1 print-border-color text-left">Particulars</th>
                        <th className="border w-7 pr-1 print-border-color text-right">Amt.</th>
                        <th className="border w-7 pr-1 print-border-color text-right">Fine</th>      

                        <th className="border w-7 pr-1 print-border-color text-right">Total Due</th>
                        <th className="border w-7 pr-1 print-border-color text-right">Amt. Paid</th>
                        <th className="border w-7 pr-1 print-border-color text-right">Bal. Paid</th>                        
                    </tr>
                </thead>

                <tbody>
                    {
                        items.map((item, index) => (
                            <tr key={index}>                                
                                <td className="border w-20 pl-1 print-border-color text-left">{item.v_particulars}</td>
                                <td className="border w-7 pr-1 print-border-color text-right">{item.v_due_amt}</td>                          
                                <td className="border w-7 pr-1 print-border-color text-right">{item.v_fine_amt}</td>                                

                                <td className="border w-7 pr-1 print-border-color text-right">{item.v_tot_due}</td>
                                <td className="border w-7 pr-1 print-border-color text-right">{item.v_adj_amt}</td>
                                <td className="border w-7 pr-1 print-border-color text-right">{item.v_bal_amt}</td>

                            </tr>                            
                    ))} 

                    <tr>
                        <th className="border print-border-color text-left">Total</th> 
                        <td className="border print-border-color text-right">{v_sumfeeamtpaid}</td>
                        <td className="border print-border-color text-right">{v_sumfeeamtfine}</td>        

                        <td className="border print-border-color text-right">{v_sumv_tot_due}</td>  
                        <td className="border print-border-color text-right">{v_sumv_adj_amt}</td>  
                        <td className="border print-border-color text-right">{v_sumv_bal_amt}</td>  

                    </tr>


                </tbody>
            </table>

            <table className="table-fixed border ml-[200px] w-96 font-my-font-name text-rec-fontSize print-border-color">
                <thead>                                                         
                    <tr>
                        <th className="border w-[40px]  pl-1 print-border-color text-left">Dues</th>
                        <td className="border w-[40px] pl-1 print-border-color text-left">{v_totaldueamt}</td>      
                        <th className="border w-[55px] pl-1 print-border-color text-left">Paid Amt.</th>
                        <td className="border w-[30px] pl-1 print-border-color text-left">{v_totalamtpaid}</td>
                        <th className="border w-[60px] pl-1 print-border-color text-left">Pay.Mode</th>
                        <td className="border w-[50px] pl-1 print-border-color text-left">{v_pay_mode}</td>
                        <th className="border w-[25px] pl-1 print-border-color text-left">Bal.</th>
                        <td className="border w-[40px] pl-1 print-border-color text-left">{v_totalbalanceamt}</td>
                    </tr> 
                </thead>
            </table>     

            <table className="table-fixed border ml-[200px] w-96 font-my-font-name text-rec-fontSize print-border-color">
                <thead>                                                         
                    <tr>
                        <th className="border w-[50px]  pl-1 print-border-color text-left">Amount in Words</th>
                        <td className="border w-[130px] pl-1 print-border-color text-left">{v_amttoword}</td>                              
                    </tr> 
                </thead>
            </table>

            <table className="table-fixed border ml-[200px] w-96 font-my-font-name text-rec-fontSize print-border-color">
                <thead>                                                         
                    <tr>
                        <th className="border w-[90px]  pl-1 print-border-color text-left">Payment Notes</th>
                        <td className="border w-[130px] pl-1 print-border-color text-left">{v_notes}</td>
                        <th className="border w-[50px]  pl-1 print-border-color text-left">Rcd.by</th>
                        <td className="border w-[50px] pl-1 print-border-color text-left">{v_user_name}</td>                                  
                    </tr> 
                </thead>
            </table>

    </div>      

    <div className="mt-2 flex justify-center gap-4">

        <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
            onClick={PrintPDF}>   
            Print
        </button>           
        
        <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BtnFontSize text-BackBtnFontColor font-BtnFontName rounded-none"
            onClick={goPrePageFunction}
        >Back</button>

    </div>


</>
);};

export default Fcpr;
