import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { ValidateInput, capitalizeWordFun, GetFormAuthDetails } from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import CRUDContext from '../components/CRUDContext';
import DropDownTableComponent from "../components/DropDownTableComponent";

import { emptyGlobalDataFun } from '../components/redux/actions';

import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import Cookies from 'js-cookie';

const MenuJoins = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData);

    let v_input_result, v_active_status = 1, v_null_parameter = 'NA';

    // define variable for tables entry (master detail form)    

    const [tabID, setTabID] = useState(1);
    const [items, setItems] = useState([]);
    const [menu_items, setmenu_items] = useState([]);
    
    // define form name and navigation details

    const v_urlSaveUpdateDelete = '3_url_menu_items_joins_updel_multiple';
    const v_backToNavigateName = '/brms';
    // const v_findToNavigateName = '/';
    const v_main_form_heading = 'Menu-Joins';
    const v_frm_no = 3;

    const {
        deletedMessage,
        createdMessage,
        updatedMessage,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if (result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR') {

                if (result.res_status === 'UN_AUTHROISED') { v_toast_msg = v_access_denied_msg; }
                if (result.res_status === 'ERROR') { v_toast_msg = v_session_expire_msg; }

                toast.info(v_toast_msg, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                navigate(`${v_backToNavigateName}`);
                return;
            } else {

                // if load allow then execute the below code

                setv_open_frm(true);

                // console.log("Update allow_update >> ", result.allow_update)

                const v_token = Cookies.get('token');

                response = await axios.get(`url_fetch_data/3_url_menu_items_drop_down/${v_frm_no}/${ReduxGlobalData[0].ag}/0/0/${v_active_status}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}        
                `,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;             
                setmenu_items(await updatedata);  

                FindSavedTableDetail();

            }
        }
        loadData();
    }, []);

    const FindSavedTableDetail = async () => {        

        const v_token = Cookies.get('token');

        // show the detail part        

        response = await axios.get(`url_fetch_data/3_url_menu_joins_view_fun/${v_frm_no}/${ReduxGlobalData[0].ag}/0/0/${v_active_status}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
            { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;
        setItems(await updatedata);   
    }

    function setBlankFields() {
        setTableRecordID(0);
        setItems([]);
    }

    const handleInputValueFun = async (name, value) => {       
    }

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        let insupdel_type = 'INSERT';

        if (value != 'DELETE') {            

            if (items.length === 0) {
                toast.info('Record not found to be saved', {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                return;
            }
        }

        const data = {
            id: 0,
            insupdel_type: 'INSERT',                                 
            items: JSON.stringify(items)
        };

        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);        

        if (insupdel_type === 'INSERT') {            
            setShowConfirmModelSave(true);
        }

    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {

        setShowConfirmModelSave(false);        
        setConditionalCSS(false);

        if (p_data.ModelYesNoAnswer === 'YES') {
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async (p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final: p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final: p_last_updel_notes_final,
        }

        const v_token = Cookies.get('token');

        // response = await axios.post(`${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        // { headers: { Authorization: `Bearer ${v_token}` } });

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData,
            { headers: { Authorization: `Bearer ${v_token}` } });

        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }

    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if (updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405') {

            if (updatedata.v_data[0].res_status === 'UN_AUTHROISED') { v_toast_msg = v_access_denied_msg; }
            if (updatedata.v_data[0].res_status === 'ERROR') { v_toast_msg = v_session_expire_msg; }

            toast.info(v_toast_msg, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ') {

            // check if read allow then

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                return false;
            }

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            // setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);

            // console.log("updatedata.TabID.v_data >> ", updatedata.v_data);

            if (updatedata.transType === 'INSERT') {
                v_toast_msg = createdMessage;

                setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);

                getSavedDetailFunction(0);
            } else if (updatedata.transType === 'UPDATE') {
                v_toast_msg = updatedMessage;

                getSavedDetailFunction(0);
            } else if (updatedata.transType === 'DELETE') {
                v_toast_msg = deletedMessage;
                setBlankFields();
            }

            toast.success(v_toast_msg, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
        }
    }

    const setNewRecordIDFunction = async () => {

        // console.log("setNewRecordIDFunction >> ", p_v_data[0].p_id, p_v_data[0].p_vou_no);

        // setTableRecordID(await p_v_data[0].p_id);        
    }

    const getSavedDetailFunction = async (p_v_data) => {
        FindSavedTableDetail(p_v_data);
    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

    };

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        handleInputValueFun(name, value);
    }

    const handleButtonValue = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        if (value === 'ADD_NEW_ROW'){
            addItem();
            return;
        }else if (value === 'BTN_FIND') {
            FindSavedTableDetail();
            return;
        } else if (value === 'BTN_BACK') {
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    const goCheckDataFunction = (event) => {
        event.preventDefault();

        // console.log("TableRecordID >> ", TableRecordID);

        console.log("items >> ", items);

    }

    // For details entry (entry in table)
    // For details entry (entry in table)

    const addItem = () => {
        setTabID(tabID + 1);
        setItems([...items, {ischecked:false, t1_id: tabID * -1 , "menu_items_id" : 0, child_id: 0,  path: "", notes:''}]);
    };

    const onClickTableRowFun = (event, p_id, p_value) => {
        event.preventDefault();

        // console.log("When Click on SHOW_RECORD >> ", saveUpdateTag);        

        if (p_value === 'ADD_NEW_ROW') {           

            setTabID(tabID + 1);
            
            let v_data = {ischecked:false, t1_id: tabID * -1 , "menu_items_id" : 0, child_id: 0,  path: "", notes:''};

            items.splice(p_id + 1, 0, v_data);

        }
    };

    const handlerChangeTableRowFocus = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;
    };

    const handlerChange = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;

        let v_value = value;
        const list = [...items];

        if (name === 'dis_seq') {
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', v_value, '', 100);
            v_value = v_input_result.v_value;
        } else if (name === 'print_name') {
            v_value = capitalizeWordFun(v_value);
        } else if (name === 'notes') {
            v_value = capitalizeWordFun(v_value);
        }

        list[i][name] = v_value;
        setItems(list);
    };

    const handleChangeCheckBox = (event, p_column) => {
        const { name, checked } = event.target;

        let tempListID = [];

        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, [p_column]: checked } });
            setItems(checkedvalue);
        }
        else {
            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, [p_column]: checked } : user);
            setItems(checkedvalue);
        }
    }
   
return (

<>
{
    ShowConfirmModelSave ?
        <div className="centerWindow_css">
            <ConfirmModelSave transType={TransType} setModelSaveUpdateDeleteFalseFunction={setModelSaveUpdateDeleteFalseFunction} />
        </div>
        : null
}

{
v_open_frm ?

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">

<div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

<table className="table_main_css">
<thead className="table_thead_css">
    <tr className="table_thead_tr_css">

        <th className="table_th_td_css w-[3%] text-left"></th>

        <th className="table_th_td_css w-[4%]">
            <input className="table_check_inner_page_css"                            
                type="checkbox" name="allselect" checked={!items.some((user) => user?.['ischecked'] !== true)}
                onChange={(e) => handleChangeCheckBox(e, 'ischecked')}
            />
        </th>
        
        <th className="table_th_td_css w-[25%] text-left">Parent Menu</th>
        <th className="table_th_td_css w-[25%] text-left">Child Menu</th>
        <th className="table_th_td_css w-[40%] text-left">Notes</th>
        <th className="table_th_td_css w-[3%] text-left"></th>

    </tr>
</thead>

<tbody className="table_tbody_css h-[65vh]">
{
items?.map((item, index) => (        

    <tr className="table_thead_tr_css table_tbody_hover_css" key={item.t1_id}>          

        {
            (item.t1_id < 0) ?
            <td className="table_th_td_css text-center w-[3%]" onClick={() => { setItems(items.filter(a => a.t1_id !== item.t1_id)); }} onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                <FontAwesomeIcon className="text-blue-600 text-1xl" icon={faTrash}/>
            </td>                            
            :  
            <td className="table_th_td_css text-center w-[3%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}></td>
        }

        <td className="table_th_td_css w-[4%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
            <input className="table_check_inner_page_css"
                type="checkbox" name={item.t1_id} checked={item?.['ischecked'] || false}                        
                onChange={(e) => handleChangeCheckBox(e, 'ischecked')}
            />
        </td>

        <td className="table_input_th_td_css text-center w-[25%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <DropDownTableComponent 
                ref={(ref) => addInputRef(ref, 14)}
                paramItems={menu_items} columnName={'menu_items_id'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.menu_items_id}                     
            />         
        </td>        

        <td className="table_input_th_td_css text-center w-[25%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <DropDownTableComponent 
                ref={(ref) => addInputRef(ref, 14)}
                paramItems={menu_items} columnName={'child_id'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.child_id}                     
            />         
        </td>            

        <td className="table_input_th_td_css text-left w-[40%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <input className="table_input_inner_page_css text-left"
                type="text"
                ref={(ref) => addInputRef(ref, 15)}
                name="notes"
                id="notes"
                value={item.notes}
                onChange={(e) => handlerChange(e, index)}
            ></input>
        </td>

        <td className="table_th_td_css text-center w-[3%]" onClick={(e) => onClickTableRowFun(e, index, 'ADD_NEW_ROW', item.isChecked)} onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <FontAwesomeIcon className="text-blue-600 text-2xl align-middle" icon={faPlus}/>                            
        </td>
        
    </tr>
))
}
</tbody>
</table>

<nav className="btn_nav_table_sum_inner_css">
<div className="btn_div_main_css">

    <div className="btn_div_inner_css">

        <div>        
            <button className="btn_new_css"
                value = 'ADD_NEW_ROW'
                onClick={handleButtonValue}>
                New Row
            </button>    
        </div>

        <div>
            <button className="btn_save_css"
                onClick={createRecordFunction}>
                Save
            </button>
        </div>

        <div>
            <button className="btn_back_css"
                value='BTN_BACK'
                onClick={handleButtonValue}>
                Back
            </button>
        </div>

        {/* <div>                
        <button className="btn_back_css"
        onClick={goCheckDataFunction}>
        Check Data
        </button>
        </div> */}

    </div>

</div>
</nav>

</form>
: null
}

</>
);
};

export default MenuJoins;
