// Table name : usergroup

import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';

// import Layout from './Layout';

const Ug = (props) => {
    const dispatch = useDispatch();
    const ReduxData = useSelector((state) => state);

    const [ShowModelFormDeleteUpdate, setModelFormDeleteUpdate] = useState(false);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);
    const [updateDelTag, setUpdateDelTag] = useState('');
    const [updateDelapiName, setUpdateDelapiName] = useState('');
    
    // const router = useRouter();

    const navigate = useNavigate();
    
    const [g_name, setG_name] = useState('');    
    const [notes, setNotes] = useState('');

    const [under_usergroup, setUnder_usergroup] = useState(0);
    const [under_usergroupItems, setUnder_usergroupItems] = useState([]);   

    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,        
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenPageRefreshMessage,        
    } = useContext(CRUDContext);

    // ////////////////////////////

    const getAuthroziedUser = async () => {  
        try{
            const token = localStorage.getItem('token');

            const response = await axios.get(`verifyTokenAPI/`,
                  { headers: { Authorization: `Bearer ${token}` } });            
    
            if (response.status === 401 || response.status === 403){
                localStorage.clear();
                navigate('/Login')
            };

        }catch(error){
            localStorage.clear();
            navigate('/Login')
        }
    }    

    useEffect( () => {        
        getAuthroziedUser();    
    }, []);

    // ////////////////////////////

    useEffect(() => {
        if (error) {
            toast.error(error);
            clearErrors();
        }

        if (created) {
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
        }

        if (updated) {
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
        }
    }, [error, created, updated, deleted]);    

    useEffect(() => {      
        let response = []
        let updatedata = []

        // to fill the drop-down under_Group

        const getTableRecordFunction = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setUnder_usergroupItems(updatedata);
        }
        getTableRecordFunction('url_usergroup_view');        
        
        // to get the data when user click on view to show the existing record (from ug_view page)

        if (pk_id > 0) {

            // console.log("In 2");

            const getAllTableRecordFunction = async (var_link) => {
                response = await axios.get(`${var_link}/${pk_id}`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

                updatedata = await response.data.v_out_data;

                setPkID(pk_id);
                setG_name(updatedata[0].g_name);
                setUnder_usergroup(updatedata[0].under_usergroup);
                setNotes(updatedata[0].notes);
            }
            getAllTableRecordFunction('url_usergroup_id_wise');
        }

    }, [pk_id])
    
    const newBlankFieldHandler = (e) => {
        e.preventDefault();

        setPkID(0);
        setG_name("");
        setUnder_usergroup(0);
        setNotes(""); 
    }

    const createRecordFunction = (event) => {
        event.preventDefault();

        if (g_name.trim().length === 0){
            toast.info("Entry reuired for group name !")
            clearErrors();
            return;
        }                
        
        const data = {
            id: pk_id,
            g_name, 
            under_usergroup, 
            notes, 
            active_status : 1, 
            show_in_list : 1,
        }
        
        dispatch(emptyCart());
        dispatch(addTempList(data));

        // console.log("pk_id value is : ", pk_id)

        if (pk_id === 0) {            
            setUpdateDelTag('')
            setModelModelFormYesNo(true);
        }
        else {            
            setUpdateDelTag('UPDATE')
            // setUpdateDelapiName('usergroup_updel')
            setUpdateDelapiName('url_usergroup_updel')
            setModelFormDeleteUpdate(true);
        }
    }

    const FindInTablePage = (event) => {
        event.preventDefault();
        navigate('/ug_view')
    }

    const handleDeleteUpdateFunction = (event) => {
        event.preventDefault();

        if (ReduxData.userData[0].id.length === 0){
            toast.info(whenPageRefreshMessage)
            clearErrors();
            return;
        }

        const data = {
            id: pk_id,
            // last_access_ip : ipAddess,
            last_access_ip : '',
            last_access_by : ReduxData.userData[0].id,    
        };

        setUpdateDelTag('DELETE')
        setUpdateDelapiName('usergroup_delete')

        dispatch(emptyCart());
        dispatch(addTempList(data));
        setModelFormDeleteUpdate(true);
    }

    const handleSearchUnderGroup = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setUnder_usergroup(getId);
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();   
        setPkID(0);  
        navigate('/brms');
    }

    function handleEnter(event) {
        if (event.keyCode === 13) {
          const form = event.target.form;
          const index = Array.prototype.indexOf.call(form, event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
        }
    }

    return (
        <>
            <form>
            <div className="pt-PaddingTopAfterMenu max-w-full text-my-main-heading-fontSize bg-my-main-heading-color text-my-main-heading-font-color text-center">User Group</div>
        
            <div className="justify-center items-center inset-0 flex">

                <div className="pt-1 px-2">                  

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Group Name</label>
                    <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                        name="g_name"
                        id="g_name"
                        type="text"
                        placeholder="Group Name"
                        value={g_name}
                        onChange={(e) => setG_name(e.target.value)}
                        onKeyDown={handleEnter} {...props}
                    ></input>

                    {/* Start Add Drop-Down For List Code */}

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Under Group</label>
                    <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                        name="lcode" value={under_usergroup} onChange={(e) => handleSearchUnderGroup(e)}
                        onKeyDown={handleEnter} {...props}>
                        <option>--Select Under Group--</option>
                        {
                            under_usergroupItems.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>

                    {/* End Add Drop-Down For List Code */}

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Notes</label>
                    <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                        name="notes"
                        id="notes"
                        type="text"
                        placeholder="Notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        onKeyDown={handleEnter} {...props}
                    ></input>

                    <div className="pt-4 py-my-padding-py px-my-padding-px grid grid-cols-4 gap-4">

                        <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={newBlankFieldHandler}>
                            New
                        </button>

                        <button className="bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-my-fontSize text-SaveButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={createRecordFunction}>
                            Save
                        </button>

                        {/* <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={handleDeleteUpdateFunction}>
                            Delete
                        </button> */}

                        <button className="bg-FindButtonBGColor hover:bg-FindButtonHoverColor text-my-fontSize text-FindButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={FindInTablePage}
                        >
                            Find
                        </button>

                        <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={goPrePageFunction}>
                            Back
                        </button>

                    </div>

                    <ModelFormDeleteUpdate api_type={updateDelTag} api_name={updateDelapiName} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)}/>

                    <ModelFormYesNo table_name='UNDERGROUP' api_name={'url_usergroup_new'} isVisible={ShowModelFormYesNo} onClose={() => setModelModelFormYesNo(false)}/>
                    
                    
                </div>
            </div>
            </form>
        </>
    );
};

export default Ug;
