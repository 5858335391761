import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import SelectRecordPopUpSingleColumn from '../components/SelectRecordPopUpSingleColumn';
import CRUDContext from '../components/CRUDContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useNavigate } from "react-router-dom";

import { faSortAlphaDownAlt, faSortAlphaDown, faEye, faFilePdf, faFileExcel, faPrint } from '@fortawesome/free-solid-svg-icons';
import { emptyTempDataFun, setTempDataFun, emptyGlobalDataFun } from '../components/redux/actions';

const VoucherDetails = () => {
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';
    const [TransType, setTransType] = useState('');
    const [userAuthData, setUserAuthData] = useState([]);
    const [TablePerPageItems, setTablePerPageItems] = useState([]);
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const [rowsLimit, setRowsLimit] = useState(100);
    const [TablePerPage, setTablePerPage] = useState('100');
    const [customPagination, setCustomPagination] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    // const [totalPage, setTotalPage] = useState(Math.ceil(items?.length / rowsLimit));    
    const [currentPage, setCurrentPage] = useState(0);
    // const [activeColumn, setActiveColumn] = useState(["st_name"]);    
    const [ascWise, setAscWise] = useState(false);

    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);
    const [conditionalCSS, setConditionalCSS] = useState(false);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // standard items define for table populate

    const [items, setItems] = useState([]);
    const [origionalData, setOrigionalData] = useState([]);
    const [filterItems, setFilterItems] = useState([]);
    const [FilterStatus, setFilterStatus] = useState(false);

    // items search list items
    
    const [st_classItems, setst_classItems] = useState([]);
    const [st_secItems, setst_secItems] = useState([]);

    // define query input

    const [queryVouDate, setqueryVouDate] = useState('');
    const [queryVouType, setqueryVouType] = useState([]);
    const [queryNotes, setqueryNotes] = useState('');
    
    // show and hide pop-up

    const [ShowPopUpSt_Class, setShowPopUpSt_Class] = useState(false);
    const [ShowPopUpSt_Sec, setShowPopUpSt_Sec] = useState(false);   

    // save ID and Desc

    const [IDArraySt_Class, setIDArraySt_Class] = useState([]);
    const [IDArraySt_Sec, setIDArraySt_Sec] = useState([]);

    const [IDArray, setIDArray] = useState([]);
    const [IDArrayValue, setIDArrayValue] = useState([]);

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData);
    const ReduxDDStudentClassData = useSelector((state) => state.DDStudentClassData);
    const ReduxDDStudentSectionData = useSelector((state) => state.DDStudentSectionData);
    const ReduxDDTablePerPageData = useSelector((state) => state.DDTablePerPageData);

    // define form name and navigation details

    const [sortingColumn, setSortingColumn] = useState(["vou_type_id_name"]);

    const v_urlShowReportData = '22_url_ac_ledger_entry_view_fun';
    const v_urlSaveUpdateDelete = '';
    const v_backToNavigateName = '/Vmkg';
    const v_findToNavigateName = '';
    const v_main_form_heading = 'Voucher Details !';
    const v_frm_no = 22;

    let v_input_result, v_active_status = 1, v_null_parameter = 'NA';    

    const [SumAmount, setSumAmount] = useState(0);

    // define standard errors and entry required messages

    const {
        deletedMessage,
        whenNoRecordFoundMessage,        
        whenTablePaginationLastPage,
        whenTablePaginationFirstPage,
        WhenRecordNotFoundToDelete,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,
    } = useContext(CRUDContext);

    useEffect(() => {

        async function loadData() {
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);            
            setUserAuthData(result);

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());
                
                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_read === 2) {
                toast.info(v_allow_open, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                navigate(`${v_backToNavigateName}`);
                return;
            } else {
                ShowRecordFun();
            }
        }
        loadData();
    }, [ReduxGlobalData[0]]);

    const ShowRecordFun = async () => {

        // console.log("In API Part")

        // edit in token
        // delete token
        // if user is not active
        // check if permission is allow for new, update and delete

        setst_classItems(ReduxDDStudentClassData[0]);
        setst_secItems(ReduxDDStudentSectionData[0]);
        setTablePerPageItems(ReduxDDTablePerPageData[0]);

        const v_token = Cookies.get('token');

        // SetPaginationItems([]);
        // setOrigionalData([]);
        // setItems([]);

        // response = await axios.get(`url_ac_ledger_entry_view_fun/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}`,
        // { headers: { Authorization: `Bearer ${v_token}` } });

        v_active_status = 1;
    
        response = await axios.get(`url_fetch_data/${v_urlShowReportData}/${v_frm_no}/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data;        

        const returnVal = AuthenticationCheckDetailFun(updatedata, 'READ');
        
        if (!returnVal){
            setItems([]);
            setOrigionalData([]);
            return;
        };        

        SetPaginationItems(updatedata.v_data, rowsLimit);
        setOrigionalData(updatedata.v_data);        
    }

    const handleInputValueFun = (name, value) => {
        let v_col_length = 0, searchdata = origionalData;

        if (name === 'TablePerPage') {

            let p_value = 0;

            v_col_length = queryVouDate.toString().trim().length + queryVouType.toString().trim().length +
                            queryNotes.toString().trim().length;

            setTablePerPage(value);

            if (value === 'All') {
                p_value = origionalData.length;
            } else {
                p_value = value;
            }

            if (v_col_length > 0) {
                setFilterStatus(true);
                SetPaginationItems(filterItems, p_value);
            } else {
                setFilterStatus(false);
                SetPaginationItems(origionalData, p_value);
            }

            return;


        } else if (name === 'queryVouDate') {            

            v_input_result = value;
            setqueryVouDate(v_input_result);

            v_col_length = queryNotes.toString().trim().length + v_input_result.toString().trim().length +
                            queryVouType.toString().trim().length;           

            let v_date = moment(v_input_result).subtract(1, 'day').toDate();
            v_date = moment(v_date).format('YYYY-MM-DD');            

            if (v_input_result.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.vou_date.includes(v_date));
            }

            if (queryNotes.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.notes.toString().toLowerCase().includes(queryNotes.toString().toLowerCase()));
            }

            if (queryVouType.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.vou_type_id_name.toString().toLowerCase().includes(queryVouType.toString().toLowerCase()));
            } 

            // console.log("After searchdata length >> ", searchdata.length);

        } else if (name === 'queryNotes') {

            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 2000);
            setqueryNotes(capitalizeWordFun(v_input_result.v_value));

            v_col_length = v_input_result.v_value.toString().trim().length + queryVouDate.toString().trim().length +
                            queryVouType.toString().trim().length;

            if (queryVouDate.toString().trim().length > 0) {
                let v_date = moment(queryVouDate).subtract(1, 'day').toDate();
                v_date = moment(v_date).format('YYYY-MM-DD');   

                searchdata = searchdata.filter((item) => item.vou_date.includes(v_date));
            }
            
            if (v_input_result.v_value.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.notes.toString().toLowerCase().includes(v_input_result.v_value.toString().toLowerCase()));
            }

            if (queryVouType.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.vou_type_id_name.toString().toLowerCase().includes(queryVouType.toString().toLowerCase()));
            }            

            // console.log("After searchdata length >> ", searchdata.length);

        } else if (name === 'queryVouType') {

            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 2000);            
            setqueryVouType(capitalizeWordFun(v_input_result.v_value));

            v_col_length = v_input_result.v_value.toString().trim().length + queryVouDate.toString().trim().length +
                            queryVouType.toString().trim().length;

            if (queryVouDate.toString().trim().length > 0) {
                let v_date = moment(queryVouDate).subtract(1, 'day').toDate();
                v_date = moment(v_date).format('YYYY-MM-DD');   

                searchdata = searchdata.filter((item) => item.vou_date.includes(v_date));
            }
            
            if (queryNotes.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.notes.toString().toLowerCase().includes(queryNotes.toString().toLowerCase()));
            }

            if (v_input_result.v_value.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.vou_type_id_name.toString().toLowerCase().includes(v_input_result.v_value.toString().toLowerCase()));
            }            

            // console.log("After searchdata length >> ", searchdata.length);            
        }

        setFilterItems(searchdata);

        if (v_col_length > 0) {
            setFilterStatus(true);
            SetPaginationItems(searchdata, rowsLimit);
        } else {
            setFilterStatus(false);
            SetPaginationItems(origionalData, rowsLimit);
        }

    }

    const handleInputFocus = (event) => {
        event.preventDefault();
    };

    const setPopUpDDFalseFunction = (p_data) => { 

    }

    const handleEnter = (event) => {        
    }

    const handleFocus = (event) => {
        event.preventDefault();
    }
 
    const handleButtonValue = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        if (value === 'BTN_DELETE_ROW') {            
            createRecordFunction();
            return;
        } else if (value === 'BTN_COPY_TO_BRANCH') {

            return;
        } else if (value === 'BTN_ASSIGN_BRANCH') {

            return;
        } else if (value === 'BTN_BACK') {            
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const handleButtonExportValue = (e, p_value) => {
        e.preventDefault();

        if (p_value === 'BTN_PRINT') {

            console.log("PRINT")

            // if (TableRecordID === 0) {
            //     toast.error(whenStudentNotFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
            //         hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
            //         draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            //     });
            //     return;
            // }

            return;
        } else if (p_value === 'BTN_EXPORT_EXCEL') {

            console.log("EXCEL")

            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        } else if (p_value === 'BTN_EXPORT_PDF') {

            console.log("PDF")

            return;
        }

    }

    const createRecordFunction = () => {

        if (IDArray.length === 0) {
            toast.error(WhenRecordNotFoundToDelete, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        let insupdel_type = 'DELETE';

        setConditionalCSS(true);
        setTransType(insupdel_type);

        if (userAuthData.allow_delete === 1) {
            setConfirmModelUpdateDelete(true);
        }
    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {

        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);

        if (p_data.ModelYesNoAnswer === 'YES') {
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async (p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData: IDArray,
            under_comp_group_id: ReduxGlobalData[0].br,
            fin_year: ReduxGlobalData[0].fy,
            insupdel_type: 'DELETE',
            last_access_ip_final: p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final: p_last_updel_notes_final,
        }       

        const v_token = Cookies.get('token');

        response = await axios.post(`${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData,
            { headers: { Authorization: `Bearer ${v_token}` } });

        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }

    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }     

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then            
            
            if (updatedata.v_data.length === 0) {                
                toast.info(whenNoRecordFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }      

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then       

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            // setNewRecordIDFunction(updatedata.TabID);  
            
            if (updatedata.transType === 'DELETE'){                
                v_toast_msg = deletedMessage;
            }

            ShowRecordFun();

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }    

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        handleInputValueFun(name, value);
    }

    const SetPaginationItems = (p_items, p_rowsLimit) => {
        setCustomPagination(Array(Math.ceil(p_items?.length / parseInt(p_rowsLimit))).fill(null));
        setTotalPage(Math.ceil(p_items?.length / parseInt(p_rowsLimit)));
        setCurrentPage(0);
        setTotalRecordFound(p_items.length);
        setRowsLimit(parseInt(p_rowsLimit));
        setItems(p_items.slice(0, parseInt(p_rowsLimit)));

        CalculateSumFunction(p_items);

        // ResetCheckBoxFunction(items);      
    }

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        let tempListID = [], tempListValue = [];

        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, ischecked: checked } });
            setItems(checkedvalue);

            setIDArray([]);
            setIDArrayValue([]);

            if (checked) {
                for (let i = 0; i < items.length; i++) {
                    tempListID.push(items[i].id);
                    // tempListValue.push(items[i].st_class);
                }
            }
        }
        else {
            const checkedvalue = items.map((user) => user.id.toString() === name ? { ...user, ischecked: checked } : user);
            setItems(checkedvalue);

            tempListID = Object.assign(IDArray);
            tempListValue = Object.assign(IDArrayValue);

            // const filteredData = items.filter((item) => item.id.includes(name));

            const filteredData = items.find(item => {return item.id === name});          

            // console.log("In handleChangeCheckBox Click filteredData : ", filteredData);

            if (filteredData.ischecked) {
                const index = tempListID.indexOf(name);
                tempListID.splice(index, 1);
                tempListValue.splice(index, 1);
            } else {
                tempListID.push(filteredData.id);
                // tempListValue.push(filteredData[0]["st_class"]);
            }
        }

        SelectedValueConvertToStr(tempListID, tempListValue);
    }

    const onClickTableRowFun = (event, p_id, p_value) => {
        event.preventDefault();

        // console.log("When Click on SHOW_RECORD >> ", p_value, p_id);

        if (p_value === 'SHOW_RECORD') {

            const temp_data = {
                temptableid: p_id,
                tempTransType: p_value
            }
            
            dispatch(setTempDataFun(temp_data));

            navigate(`${v_backToNavigateName}`);
        } else {
            ischeckedTick(p_id);
        }
    };

    const ischeckedTick = (p_id) => {

        let v_check_value = true;

        // console.log("In Table Row Click : ", p_id);

        // console.log("In Row Tick IDArray Before >> ", IDArray);

        // const filteredData = items.filter((item) => item.id.includes(p_id));

        const filteredData = items.find(item => {return item.id === p_id});        

        // console.log("In Table Row Click filteredData : ", filteredData.ischecked);

        if (filteredData.ischecked) {
            v_check_value = false;
            const index = IDArray.indexOf(p_id);
            IDArray.splice(index, 1);
            IDArrayValue.splice(index, 1);
        } else {
            v_check_value = true;
            IDArray.push(filteredData.id);
            // IDArrayValue.push(filteredData.st_class);
        }

        for (let i = 0; i < items.length; i++) {
            const checkedvalue = items.map((user) => user.id.toString() === p_id.toString() ? { ...user, ischecked: v_check_value } : user);
            setItems(checkedvalue);
        }

        SelectedValueConvertToStr(IDArray, IDArrayValue);
    }

    const SelectedValueConvertToStr = (p_tempIDList, p_tempValueList) => {

        // console.log("In Convert >> ", p_tempList.length, ' >> ', p_tempList);

        let tempIDArrayStr = '', tempIDArrayValueStr = '';

        for (let i = 0; i < p_tempIDList.length; i++) {
            if (i === 0) {
                tempIDArrayStr = p_tempIDList[i];
                tempIDArrayValueStr = p_tempValueList[i];
            } else {
                tempIDArrayStr = tempIDArrayStr + "," + p_tempIDList[i];
                tempIDArrayValueStr = tempIDArrayValueStr + "," + p_tempValueList[i];
            }
        }

        setIDArray(p_tempIDList);
        setIDArrayValue(p_tempValueList);
    }

    const addInputRef = (ref, index) => {
        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    const sortByColumn = (column) => {

        let tempData = [];

        if (FilterStatus) {
            tempData = filterItems;
        } else {
            tempData = origionalData;
        }

        let sortData = [];

        if (sortingColumn?.includes(column)) {
            sortData = tempData.slice().sort((a, b) => b[column].toString().trim().localeCompare(a[column].toString().trim()));
            setItems(sortData);
            setSortingColumn([]);
            setAscWise(true);
        } else {
            sortData = tempData.slice().sort((a, b) => a[column].toString().trim().localeCompare(b[column].toString().trim()));
            setItems(sortData);
            setSortingColumn([`${column}`]);
            setAscWise(false);
        }

        // setActiveColumn([`${column}`]);
        // setCurrentPage(0);
        ResetCheckBoxFunction(sortData);
    }

    const ResetCheckBoxFunction = (p_sortData) => {
        const newItems = p_sortData.map(obj => {

            // if (obj.id === '490') {
            //     return {...obj, ischecked: true};
            // }            

            if (IDArray.find((element) => element === obj.id) != undefined) {
                return { ...obj, ischecked: true };
            }
            return obj;
        });

        setItems(newItems);

        // console.log(newState);
    }

    const nextPage = (e) => {
        e.preventDefault();

        let tempData = [];

        if (FilterStatus) {
            tempData = filterItems;
        } else {
            tempData = origionalData;
        }

        // console.log("nextPage items >> ", items);

        // console.log(Math.trunc(tempData.length / parseInt(rowsLimit)));

        // console.log("currentPage: ", currentPage, " >> === : ", Math.trunc(tempData.length / parseInt(rowsLimit)), " >> in nextPage");        

        // if (currentPage === Math.trunc(tempData.length / parseInt(rowsLimit)) || Math.trunc(tempData.length / parseInt(rowsLimit)) === 1) 

        if (currentPage >= totalPage - 1) {
            toast.info(whenTablePaginationLastPage, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        const startIndex = parseInt(rowsLimit) * (currentPage + 1);
        const endIndex = startIndex + parseInt(rowsLimit);

        // console.log("rowsLimit: ", rowsLimit, " >> currentPage: ", currentPage, " >> startIndex: ", startIndex, " >> endIndex: ", endIndex , " >> in nextPage");

        const newArray = tempData.slice(startIndex, endIndex);
        // ResetCheckBoxFunction(newArray); 
        setItems(newArray);
        setCurrentPage(currentPage + 1);
        // ResetCheckBoxFunction(items);
    };

    const previousPage = (e) => {
        e.preventDefault();

        let tempData = [];

        if (FilterStatus) {
            tempData = filterItems;
        } else {
            tempData = origionalData;
        }

        if (currentPage === 0) {
            toast.info(whenTablePaginationFirstPage, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });

            return;
        }

        const startIndex = (currentPage - 1) * parseInt(rowsLimit);
        const endIndex = startIndex + parseInt(rowsLimit);
        const newArray = tempData.slice(startIndex, endIndex);
        setItems(newArray);
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else {
            setCurrentPage(0);
        }
        // ResetCheckBoxFunction(items);

    };

    const changePage = (value) => {
        const startIndex = value * rowsLimit;
        const endIndex = startIndex + rowsLimit;
        const newArray = origionalData.slice(startIndex, endIndex);
        setItems(newArray);
        setCurrentPage(value);
    };

    const CalculateSumFunction = (p_items) => {
        let v_sum = 0;

        for (let i = 0; i < p_items.length; i++) {
            if (p_items[i].amount.toString().trim().length > 0){
                v_sum = parseInt(v_sum) + parseInt(p_items[i].amount);   
            }
        }
        setSumAmount(v_sum)
    }

    const CheckFun = () => {

        // console.log("in Check Function");

        // ResetCheckBoxFunction(items);        

        // const searchdata =origionalData.filter((item) => queryStClass.includes(item.st_class));

        // setItems(searchdata)

    };    

    const goCheckDataFunction = (event) => {
        event.preventDefault();

        // router.push('/')

        // console.log("ReduxTempData[0].tempuserid >> ", ReduxTempData[0].tempuserid);

        // console.log("ReduxDDBranchBoardData >> ", ReduxDDBranchBoardData);

        // navigate("/");


        // const v_token = Cookies.get('token');

        // console.log("From Cookies is >> ", Cookies.get('token'));

        // console.log("userAuthData Open >> ", userAuthData.allow_open);
        // console.log("userAuthData New >> ", userAuthData.allow_new);
        // console.log("userAuthData Read >> ", userAuthData.allow_read);
        // console.log("userAuthData Update >> ", userAuthData.allow_update);
        // console.log("userAuthData Delete >> ", userAuthData.allow_delete);        

    }

return (

    // <form className="border-none opacity-75 pointer-events-none" autoComplete="off" >
    <>

    <form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">

        <div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

        {/* <div className="top_heading_all_full_page_css">{IDArray}</div>
            <div className="top_heading_all_full_page_css">{IDArrayStr}</div>
            <div className="top_heading_all_full_page_css">{IDArrayValue}</div>
            <div className="top_heading_all_full_page_css">{IDArrayValueStr}</div> */}

        {/* <table className="table_main_css" onMouseDown={() => CheckFun()}> */}

        <table className="table_main_css">
            <thead className="table_thead_css">
                <tr className="table_thead_tr_css">

                    {userAuthData.export_pdf === 1 && ( 
                        <th className="table_th_td_css text-center w-[4%]" onClick={(e) => handleButtonExportValue(e, 'BTN_EXPORT_PDF')}>
                            <FontAwesomeIcon className="faFilePdf_css" icon={faFilePdf} />
                        </th> 
                    )}

                    {userAuthData.export_pdf === 2 && ( 
                        <th className="table_th_td_css text-left w-[4%]"></th>
                    )}

                    {userAuthData.export_excel === 1 && ( 
                        <th className="table_th_td_css text-center w-[4%]" onClick={(e) => handleButtonExportValue(e, 'BTN_EXPORT_EXCEL')}>
                            <FontAwesomeIcon className="faFileExcel_css" icon={faFileExcel} />
                        </th> 
                    )}

                    {userAuthData.export_excel === 2 && ( 
                        <th className="table_th_td_css text-left w-[4%]"></th>
                    )}
                        
                    {userAuthData.print === 1 && ( 
                        <th className="table_th_td_css text-center w-[5%]" onClick={(e) => handleButtonExportValue(e, 'BTN_PRINT')}>
                            <FontAwesomeIcon className="faPrint_css" icon={faPrint} />
                        </th> 
                    )}

                    {userAuthData.print === 2 && ( 
                        <th className="table_th_td_css text-left w-[5%]"></th>
                    )}                    

                    <th className="table_th_td_css text-left w-[11%]" onClick={() => sortByColumn("vou_date")}>
                        {
                            ascWise ? <FontAwesomeIcon className="fai_css" icon={faSortAlphaDownAlt} />
                                :
                                <FontAwesomeIcon className="fai_css" icon={faSortAlphaDown} />
                        }
                        Voucher Date
                    </th>

                    <th className="table_th_td_css text-left w-[8%]" onClick={() => sortByColumn("vou_type_id_name")}>
                        {
                            ascWise ? <FontAwesomeIcon className="fai_css" icon={faSortAlphaDownAlt} />
                                :
                                <FontAwesomeIcon className="fai_css" icon={faSortAlphaDown} />
                        }
                        Vou.Type
                    </th>

                    <th className="table_th_td_css text-left w-[7%]" onClick={() => sortByColumn("amount")}>
                        {
                            ascWise ? <FontAwesomeIcon className="fai_css" icon={faSortAlphaDownAlt} />
                                :
                                <FontAwesomeIcon className="fai_css" icon={faSortAlphaDown} />
                        }
                        Amount
                    </th>

                    <th className="table_th_td_css text-left w-[61%]" onClick={() => sortByColumn("notes")}>
                        {
                            ascWise ? <FontAwesomeIcon className="fai_css" icon={faSortAlphaDownAlt} />
                                :
                                <FontAwesomeIcon className="fai_css" icon={faSortAlphaDown} />
                        }
                        Notes
                    </th>                

                </tr>
            </thead>

            <thead className="table_thead_css">
                <tr className="table_thead_tr_css">

                    <th className="table_th_td_css w-[4%]"></th>

                    <th className="table_th_td_css w-[4%]">
                        <input className="table_check_inner_page_css"
                            type="checkbox" name="allselect" checked={!items.some((user) => user?.ischecked !== true)}
                            onChange={handleChangeCheckBox}
                            onFocus={handleInputFocus}
                        />
                    </th>

                    <th className="table_th_td_css w-[5%]"></th>

                    <th className="table_th_td_css w-[11%]">
                        <input className="input_inner_page_css text-center"
                            type="date"
                            ref={(ref) => addInputRef(ref, 1)}
                            autoFocus
                            name="queryVouDate"
                            id="queryVouDate"
                            value={queryVouDate}
                            // placeholder="queryVouDate"
                            onChange={handleInputValue}
                            // onKeyDown={handleEnter}
                            onFocus={handleInputFocus}
                            // onBlur={handleInputBlur}
                        ></input>
                    </th>

                    <th className="table_th_td_css w-[8%]">
                        <input className="input_inner_page_css"
                            type="text"
                            ref={(ref) => addInputRef(ref, 0)}                            
                            name="queryVouType"
                            id="queryVouType"
                            value={queryVouType}
                            // placeholder="queryVouType"                    
                            onChange={handleInputValue}
                            // onKeyDown={handleEnter}
                            onFocus={handleInputFocus}
                            // onBlur={handleInputBlur}
                            // disabled
                        ></input>
                    </th>

                    <th className="table_th_td_css w-[7%]"></th>

                    <th className="table_th_td_css w-[61%]">
                        <input className="input_inner_page_css"
                            type="text"
                            ref={(ref) => addInputRef(ref, 3)}
                            name="queryNotes"
                            id="queryNotes"
                            value={queryNotes}
                            // placeholder="queryNotes"                    
                            onChange={handleInputValue}
                            // onKeyDown={handleEnter}
                            onFocus={handleInputFocus}
                            // onBlur={handleInputBlur}
                        ></input> 
                    </th>                   

                </tr>
            </thead>

            <tbody className="table_tbody_css">
            {
                items?.map((item, index) => (
                    <tr className="table_thead_tr_css table_tbody_hover_css" key={item.id} >

                        {/* TabRowCSS */}

                        <td className="table_th_td_css text-left w-[4%]" onClick={(e) => onClickTableRowFun(e, item.id, '', item.isChecked)}>
                            {currentPage == 0 ? index + 1 : currentPage * rowsLimit + 1 + index}
                        </td>

                        <td className="table_th_td_css w-[4%]">
                            <input className="table_check_inner_page_css"
                                // ref={(ref) => addInputRef(ref, index + 4)}
                                ref={(ref) => addInputRef(ref, index + 4)}
                                type="checkbox" name={item.id}
                                checked={item?.ischecked || false}
                                onChange={handleChangeCheckBox}
                            // onFocus={handleFocus}                            
                            />
                        </td>

                        <td className="table_th_td_css text-center w-[5%]" onClick={(e) => onClickTableRowFun(e, item.id, 'SHOW_RECORD', item.isChecked)}>
                            <FontAwesomeIcon className="faEye_css" icon={faEye} style={{ color: "#203c7e", }} />
                        </td>

                        <td className="table_th_td_css text-center w-[11%]" onClick={(e) => onClickTableRowFun(e, item.id, '', item.isChecked)}>{moment(item.vou_date).format('DD-MM-YYYY')}</td>

                        <td className="table_th_td_css text-left w-[8%]" onClick={(e) => onClickTableRowFun(e, item.id, '', item.isChecked)}>{item.vou_type_id_name}</td>
                        <td className="table_th_td_css text-right w-[7%]" onClick={(e) => onClickTableRowFun(e, item.id, '', item.isChecked)}>{item.amount}</td>
                        <td className="table_th_td_css text-left w-[61%]" onClick={(e) => onClickTableRowFun(e, item.id, '', item.isChecked)}>{item.notes}</td>                                    

                    </tr>
                ))
            }
            </tbody>
        </table>

        {/* <div className="btn_div_main_css bottom-0">                     */}

        <nav className="btn_nav_table_sum_inner_css">
            <div className="btn_div_main_css">

            <thead className="table_thead_sum_css">            
                <tr className="table_thead_tr_sum_css">                

                    <th className="table_th_td_sum_css text-left w-[4%]"></th>
                    <th className="table_th_td_sum_css text-left w-[4%]"></th>
                    <th className="table_th_td_sum_css w-[5%]"></th>
                    <th className="table_th_td_sum_css text-left w-[11%]"></th>
                    <th className="table_th_td_sum_css text-right w-[8%]">Total:</th>
                    <th className="table_th_td_sum_css text-right w-[7%]">{SumAmount}</th>
                    <th className="table_th_td_sum_css text-left w-[61%]"></th>                   

                </tr>
            </thead>


                <div className="btn_div_inner_css">

                    <div>
                        <span className="div_table_record_show_1_css">Record found </span>
                        <span className="div_table_record_show_2_css">{totalRecordFound}</span>
                    </div>

                    <div>
                        {/* <label htmlFor="TablePerPage" className="required_css label_inner_page_css">Class</label>    */}
                        <select className="input_inner_page_css"
                            name="TablePerPage" value={TablePerPage}
                            defaultValue={rowsLimit}
                            onChange={(e) => handleInputValue(e)}
                        >
                            {
                                TablePerPageItems?.map((item, index) => (
                                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div>
                        <button className="btn_table_pre_css"
                            onClick={previousPage}>
                            Previous
                        </button>
                    </div>

                    <div>
                        <span className="div_table_record_show_1_css">Pages </span>
                        <span className="div_table_record_show_2_css">{currentPage == 0 ? 1 : currentPage + 1}</span>
                        <span className="div_table_record_show_1_css"> of </span>
                        <span className="div_table_record_show_2_css">{totalPage}</span>
                    </div>

                    <div>
                        <button className="btn_table_next_css"
                            onClick={nextPage}>
                            Next
                        </button>
                    </div>

                    <div>
                        <span className="div_table_record_show_1_css">Showing </span>
                        <span className="div_table_record_show_2_css">{currentPage == 0 ? 1 : currentPage * parseInt(rowsLimit) + 1}</span>
                        <span className="div_table_record_show_1_css"> to {" "}</span>

                        {
                            TablePerPage === 'All' ?
                                <span className="div_table_record_show_2_css">{totalRecordFound}</span>
                                :
                                <span className="div_table_record_show_2_css">
                                    {currentPage == 0 ? parseInt(rowsLimit) : parseInt(currentPage + 1) * parseInt(rowsLimit)}
                                </span>
                        }
                        <span className="div_table_record_show_1_css"> of </span>
                        <span className="div_table_record_show_2_css"> {origionalData?.length} </span>
                        <span className="div_table_record_show_1_css"> results</span>
                    </div>

                    {/* <div>
                        {userAuthData.allow_delete === 1 && (
                            <button className="btn_delete_css"
                                value='BTN_DELETE_ROW'
                                onClick={handleButtonValue}>
                                Delete
                            </button>
                        )}
                    </div> */}

                    {/* <div>
                        {userAuthData.copy_to_branch === 1 && (
                            <button className="btn_find_css"
                                value = 'BTN_COPY_TO_BRANCH'
                                onClick={handleButtonValue}>
                                Copy To Branch
                            </button>
                        )}
                    </div> */}

                    {/* <div>
                        {userAuthData.assign_branch === 1 && (
                            <button className="btn_find_css"
                                value = 'BTN_ASSIGN_BRANCH'
                                onClick={handleButtonValue}>
                                Assigned Branch
                            </button>
                        )}
                    </div> */}


                    <div>
                        <button className="btn_back_css"
                            value='BTN_BACK'
                            onClick={handleButtonValue}>
                            Back
                        </button>
                    </div>

                    {/* <div>
                        <button className="btn_back_css"
                            onClick={goCheckDataFunction}>
                            Check
                        </button>
                    </div> */}
                    
                </div>
            </div>
        </nav>

    </form>
    </>
);
};

export default VoucherDetails;
