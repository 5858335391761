// old code

import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { ValidateInput, capitalizeWordFun, GetFormAuthDetails } from '../components/GlobalFunction';
import { useDispatch, useSelector } from 'react-redux';
import SearchListDropdown from "../components/SearchListDropdown";
import CRUDContext from '../components/CRUDContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

import { useReactToPrint } from "react-to-print";

import { faFilePdf, faFileExcel, faPrint, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import { emptyGlobalDataFun } from '../components/redux/actions';

const DailyCollectionReport = () => {
    const inputRefs = useRef([]);
    const conponentPDF = useRef();

    const [v_open_frm, setv_open_frm] = useState(false);
    let response = [], updatedata = [], v_toast_msg = '';
    const [userAuthData, setUserAuthData] = useState([]);
    const [conditionalCSS, setConditionalCSS] = useState(false);

    const [disableShow, setdisableShow] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // standard items define for table populate

    const [items, setItems] = useState([]);
    // const [origionalData, setOrigionalData] = useState([]);

    const [headingcolumn, setheadingcolumn] = useState([]);

    // save ID and Desc
    const [from_month, setfrom_month] = useState(1);
    const [to_month, setto_month] = useState(0);

    const [from_month_name, setfrom_month_name] = useState('');
    const [to_month_name, setto_month_name] = useState('');

    const [param_st_fee_coll_group_id, setparam_st_fee_coll_group_id] = useState(0);

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData);
    const ReduxDDStudentSectionData = useSelector((state) => state.DDStudentSectionData);
    const ReduxDDFeeGroupTypeData = useSelector((state) => state.DDFeeGroupTypeData);
    const ReduxDDStudentTypeData = useSelector((state) => state.DDStudentTypeData);
    const ReduxDDStStreamData = useSelector((state) => state.DDStStreamData);
    const ReduxDDStudentClassData = useSelector((state) => state.DDStudentClassData);
    const ReduxDDFeeCodeData = useSelector((state) => state.DDFeeCodeData);
    const ReduxDDFeeMonthSeqWiseData = useSelector((state) => state.DDFeeMonthSeqWiseData);

    // define drop downs inputs

    const [student_type, setstudent_type] = useState(0);
    const [stream, setstream] = useState(0);
    const [st_class, setst_class] = useState(0);
    const [st_sec, setst_sec] = useState(0);
    const [fee_code, setfee_code] = useState(0);

    const [student_type_descr, setstudent_type_descr] = useState('');
    const [stream_descr, setstream_descr] = useState('');
    const [st_class_descr, setst_class_descr] = useState('');
    const [st_sec_descr, setst_sec_descr] = useState('');
    const [fee_code_descr, setfee_code_descr] = useState('');

    const [student_type_descrstr, setstudent_type_descrstr] = useState('');
    const [stream_descrstr, setstream_descrstr] = useState('');
    const [st_class_descrstr, setst_class_descrstr] = useState('');
    const [st_sec_descrstr, setst_sec_descrstr] = useState('');
    const [fee_code_descrstr, setfee_code_descrstr] = useState('');

    const [ShowPopUp, setShowPopUp] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(false);

    const [print_datetime, setprint_datetime] = useState('');
    const [fee_group_id_name, setfee_group_id_name] = useState('');
    const [last_col_index, setlast_col_index] = useState(7);

    const [h1, seth1] = useState();
    const [h2, seth2] = useState();
    const [h3, seth3] = useState();

    const [notes, setnotes] = useState('');

    const [showBalance, setshowBalance] = useState('Y');

    // define form name and navigation details
    
    const v_urlShowReportData = '29_url_StudentAdmitCardDeskSlip';
    const v_backToNavigateName = '/Dashboard';

    const v_main_form_heading = 'Student Admit Card/ Desk Slip';
    const v_frm_no = 29;

    let v_input_result;

    // define standard errors and entry required messages

    const {
        deletedMessage,
        whenNoRecordFoundMessage,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,
    } = useContext(CRUDContext);

    useEffect(() => {

        async function loadData() {
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if (result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR') {

                if (result.res_status === 'UN_AUTHROISED') { v_toast_msg = v_access_denied_msg; }
                if (result.res_status === 'ERROR') { v_toast_msg = v_session_expire_msg; }

                toast.info(v_toast_msg, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_read === 2) {
                toast.info(v_allow_open, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                navigate(`${v_backToNavigateName}`);
                return;
            } else {

                // if load allow then execute the below code

                setv_open_frm(true);

                setBlankFields();
            }
        }
        loadData();
    }, [ReduxGlobalData[0]]);

    function setBlankFields() {

        setparam_st_fee_coll_group_id(0);

        // var today = new Date();
        // setfrom_date(Moment(today).format('YYYY-MM-DD'));
        // setto_date(Moment(today).format('YYYY-MM-DD'));

        setstudent_type_descr('');
        setstudent_type(0);
        setst_class(0);
        setst_class_descr('');
        setst_sec(0);
        setst_sec_descr('');
        setstream(0);
        setstream_descr('');
        setfee_code(0);
        setfee_code_descr('');

        setstudent_type_descrstr('');
        setstream_descrstr('');
        setst_class_descrstr('');
        setst_sec_descrstr('');
        setfee_code_descrstr('');

        setprint_datetime('');
        setfee_group_id_name('');

        setItems([]);
        setdisableShow(false);
    }

    const ShowRecordFun = async (p_report_type) => {

        if (p_report_type === 'ADM_CARD'){
            seth1('Cashier/Accountant');
            seth2('Class Teacher');
            seth3('Principal');

            setshowBalance('Y');
        }

        if (p_report_type === 'DESK_SLIP'){
            seth1('Class Teacher');
            seth2('');
            seth3('Principal');

            setshowBalance('N');
        }        

        let v_active_status = 1, v_null_parameter = 'NA'; // for active records

        let v_param_st_fee_coll_group_id = 0;

        // if (parseInt(ReduxGlobalData[0].fcg) === 0) {
        //     if (param_st_fee_coll_group_id === undefined || param_st_fee_coll_group_id.length === undefined || param_st_fee_coll_group_id.length === 0) {
        //         v_param_st_fee_coll_group_id = 0;
        //     } else {
        //         v_param_st_fee_coll_group_id = param_st_fee_coll_group_id;
        //     }
        // } else {
        //     v_param_st_fee_coll_group_id = parseInt(ReduxGlobalData[0].fcg);
        // }        

        const v_token = Cookies.get('token');

        let v_ctr = 0, v_stream = 0;
        
        if(to_month === undefined || to_month.length === undefined || to_month.length === 0){
            toast.info("Entry required for Till month", {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });     
            return;
        }

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.info("Entry required for Class", {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });     
            return;
        }

        if(st_sec === undefined || st_sec.length === undefined || st_sec.length === 0){
            toast.info("Entry required for Section", {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });     
            return;
        }

        if (parseInt(ReduxGlobalData[0].fcg) === 0){
            if (param_st_fee_coll_group_id === undefined || param_st_fee_coll_group_id.length === undefined || param_st_fee_coll_group_id.length === 0) {
                v_param_st_fee_coll_group_id = 0;                
            } else {
                v_param_st_fee_coll_group_id = param_st_fee_coll_group_id;                
            }
        }else{
            v_param_st_fee_coll_group_id = parseInt(ReduxGlobalData[0].fcg);
        }

        if (parseInt(ReduxGlobalData[0].stem) === 0){
            if (stream === undefined || stream.length === undefined || stream.length === 0) {
                v_stream = 0;                
            } else {
                v_stream = stream;                
            }
        }else{
            v_stream = parseInt(ReduxGlobalData[0].stem);
        }

        setdisableShow(true);

        response = await axios.get(`url_fetch_data/${v_urlShowReportData}/${v_frm_no}/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
        /${p_report_type}
        /${ReduxGlobalData[0].brd}
        /${v_ctr}
        /${ReduxGlobalData[0].medi}
        /${stream}
        /${ReduxGlobalData[0].fcat}
        /${ReduxGlobalData[0].ptm}
        /${st_class}
        /${v_ctr} 
        /${from_month}
        /${to_month}
        /${v_param_st_fee_coll_group_id}
        /${st_sec}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
            { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data;

        const returnVal = AuthenticationCheckDetailFun(await updatedata, 'READ');

        if (!returnVal) {
            setItems([]);
            setdisableShow(false);
            return;
        };

        setItems(await updatedata.v_data);

        const column1 = Object.keys(await updatedata.v_data[0]);

        setheadingcolumn(column1);

        const last_col = column1.length - 5;

        setlast_col_index(last_col);

        if (updatedata.v_data.length === 1) {
            toast.info(whenNoRecordFoundMessage, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });

            setdisableShow(false);
            return;
        }

        // console.log("Heading columns >> ", column1[12])

        // Getting the first row of specific column

        const row1 = Object.values(await updatedata.v_data[0]);

        // setprint_datetime(await row1[0]);
        // setfee_group_id_name(await row1[last_col + 1]);
        // setprint_datetime(await row1[last_col + 2]);
        // setfrom_month_name(await row1[last_col + 3]);
        // setto_month_name(await row1[last_col + 4]);

        // console.log("Heading columns >> ", date_column[12])

        // console.log("updatedata.v_data >> ", await updatedata.v_data[1].length)

        setdisableShow(false);
    }

    const handleInputValueFun = (name, value) => {

        if (name === 'param_st_fee_coll_group_id') {
            setparam_st_fee_coll_group_id(value);
            setItems([]);
            setdisableShow(false);
            return;
        } else if (name === 'from_month') {
            setfrom_month(value);
            setItems([]);
            setdisableShow(false);
            return;
        } else if (name === 'to_month') {
            setto_month(value);
            setItems([]);
            setdisableShow(false);
            return;
        } else if (name === 'student_type_descr') {
            setstudent_type_descr(value);
            setItems([]);
            setdisableShow(false);
            return;
        } else if (name === 'st_class_descr') {
            setst_class_descr(value);
            setItems([]);
            setdisableShow(false);
            return;
        } else if (name === 'st_sec_descr') {
            setst_sec_descr(value);
            setItems([]);
            setdisableShow(false);
            return;
        } else if (name === 'stream_descr') {
            setstream_descr(value);
            setItems([]);
            setdisableShow(false);
            return;
        } else if (name === 'fee_code_descr') {
            setfee_code_descr(value);
            setItems([]);
            setdisableShow(false);
            return;
        }else if (name === 'notes') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setnotes(capitalizeWordFun(v_input_result.v_value));            
            return;
        }

    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        // console.log("handleInputFocus value >> ", value)

        if (name === 'student_type_descr') {
            setShowPopUp(true);
            setSelectedColumn('student_type_descr');
            return;
        } else if (name === 'st_class_descr') {
            setShowPopUp(true);
            setSelectedColumn('st_class_descr');
            return;
        } else if (name === 'st_sec_descr') {
            setShowPopUp(true);
            setSelectedColumn('st_sec_descr');
            return;
        } else if (name === 'stream_descr') {
            setShowPopUp(true);
            setSelectedColumn('stream_descr');
            return;
        } else if (name === 'fee_code_descr') {
            setShowPopUp(true);
            setSelectedColumn('fee_code_descr');
            return;
        } else {
            setShowPopUp(false);
            return;
        }

    };

    const PrintPDF = useReactToPrint({
        // content: () => conponentPDF.current,
        // documentTitle: "Userdata",
        // onAfterPrint: () => alert("Data saved in PDF")

        content: () => conponentPDF.current,
        documentTitle: "",
        // pageStyle : "35px"
        // pageStyle : "@page { size: 2.5in 4in }"        
        // pageStyle : "1080px"

        pageStyle: "size: A4 landscape"

        // pageStyle : "size: A4 portrait"        
    });

    const handleButtonExportValue = (e, p_value) => {
        e.preventDefault();

        const { name, value } = e.target;


        if (p_value === 'BTN_FIND') {
            // console.log("Find BTN")
            ShowRecordFun(e.target.value);
            return;
        } else if (p_value === 'BTN_BACK') {
            navigate(`${v_backToNavigateName}`);
            return;
        } else if (p_value === 'BTN_PRINT') {
            PrintPDF();
            return;
        } else if (p_value === 'BTN_EXPORT_EXCEL') {

            // console.log("EXCEL")

            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        } else if (p_value === 'BTN_EXPORT_PDF') {

            // console.log("PDF")

            return;
        }

    }

    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if (updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405') {

            if (updatedata.v_data[0].res_status === 'UN_AUTHROISED') { v_toast_msg = v_access_denied_msg; }
            if (updatedata.v_data[0].res_status === 'ERROR') { v_toast_msg = v_session_expire_msg; }

            toast.info(v_toast_msg, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ') {

            // check if read allow then            

            if (updatedata.v_data.length === 0) {
                toast.info(whenNoRecordFoundMessage, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                return false;
            }

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                return false;
            }

            return true;
        }

        // below code to check if CRUD allow then       

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        if (updatedata.v_status.toString().trim() === '200') {

            if (updatedata.transType === 'DELETE') {
                v_toast_msg = deletedMessage;
            }

            // ShowRecordFun();

            toast.success(v_toast_msg, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
        }

    }

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        // console.log("event.target >> ", event.target)

        handleInputValueFun(name, value);
    }

    const addInputRef = (ref, index) => {
        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    const goCheckDataFunction = (event) => {
        event.preventDefault();

        console.log("st_class >> ", st_class)
        console.log("st_class_descr >> ", st_class_descr)

        // console.log("Fee Type >> ", param_st_fee_coll_group_id)
        // console.log("Class >> ", st_class.length, st_class)
        // console.log("Section >> ", st_sec.length, st_sec)
        // console.log("Stream >> ", stream.length, stream)
    }    

    const WhenNoDataFoundFunction = (p_data) => {

        // console.log("When no data >> ", p_data.selected_items);

        if (p_data.p_calling_fun_type === 'NO_DATA_FOUND') {
            SetDataAfterReturnFromSearchList(p_data);
        }
    }

    const onBlurSetDataFunction = (p_data) => {
        // console.log("On Blur selected_items Data >> ", p_data);

        if (p_data.p_calling_fun_type === 'CALL_WHEN_KEY_PRESS') {
            SetDataAfterReturnFromSearchList(p_data);
        }
    }

    const setPopUpDDFalseFunction = (p_data) => {

        // console.log("Return selected_items Data >> ", p_data.selected_items);

        SetDataAfterReturnFromSearchList(p_data);

        setShowPopUp(false);
        setConditionalCSS(false);
    }

    const SetDataAfterReturnFromSearchList = (p_data) => {

        if (p_data.columnType === 'SINGLE_NOT_TABLE' || p_data.columnType === 'MULTIPLE_NOT_TABLE') {
            if (p_data.selectedColumnName === 'student_type_descr') {
                setstudent_type(p_data.SelectedIDArray);
                setstudent_type_descr(p_data.SelectedIDArrayValue);
                setstudent_type_descrstr(p_data.SelectedIDArrayValueStr);
            } else if (p_data.selectedColumnName === 'st_class_descr') {
                setst_class(p_data.SelectedIDArray);
                setst_class_descr(p_data.SelectedIDArrayValue);
                setst_class_descrstr(p_data.SelectedIDArrayValueStr);
            } else if (p_data.selectedColumnName === 'st_sec_descr') {
                setst_sec(p_data.SelectedIDArray);
                setst_sec_descr(p_data.SelectedIDArrayValue);
                setst_sec_descrstr(p_data.SelectedIDArrayValueStr);
            } else if (p_data.selectedColumnName === 'stream_descr') {
                setstream(p_data.SelectedIDArray);
                setstream_descr(p_data.SelectedIDArrayValue);
                setstream_descrstr(p_data.SelectedIDArrayValueStr);
            } else if (p_data.selectedColumnName === 'fee_code_descr') {
                setfee_code(p_data.SelectedIDArray);
                setfee_code_descr(p_data.SelectedIDArrayValue);
                setfee_code_descrstr(p_data.SelectedIDArrayValueStr);
            }

            setItems([]);
            setdisableShow(false);
        }
    }

    const handlerChangeTableRowFocus = (event) => {
        event.preventDefault();

        setShowPopUp(false);
    };

    return (
        <>

            <form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">

                <div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

                <div className="form_div_main_css h-[6vh]">
                    <div className="form_div_inner_css grid grid-cols-12">

                        {parseInt(ReduxGlobalData[0].fcg) === 0 && (
                            <div>
                                <label className="label_inner_page_css required_css">Fee Type</label>
                                <select className="input_inner_page_css"
                                    ref={(ref) => addInputRef(ref, 0)}
                                    // autoFocus         
                                    name="param_st_fee_coll_group_id"
                                    id="param_st_fee_coll_group_id"
                                    value={param_st_fee_coll_group_id}
                                    onChange={handleInputValue}
                                    onFocus={handleInputFocus}
                                >
                                    <option></option>
                                    {
                                        ReduxDDFeeGroupTypeData[0]?.map((item, index) => (
                                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        )}                        

                        <div className="col-span-1">
                            <label className="label_inner_page_css required_css">Till Month</label>
                            <select className="input_inner_page_css"
                                ref={(ref) => addInputRef(ref, 0)}
                                // autoFocus         
                                name="to_month"
                                id="to_month"
                                value={to_month}
                                onChange={handleInputValue}
                                onFocus={handleInputFocus}
                            >
                                <option></option>
                                {
                                    ReduxDDFeeMonthSeqWiseData[0]?.map((item, index) => (
                                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                                    ))
                                }
                            </select>
                        </div>                        

                        {/* {parseInt(ReduxGlobalData[0].st) === 0 && (
                            <div>
                                <label className="label_inner_page_css">Student Type</label>
                                <input className="input_inner_page_css"
                                    type="text"
                                    ref={(ref) => addInputRef(ref, 5)}
                                    name="student_type_descr"
                                    id="student_type_descr"
                                    value={student_type_descr}
                                    onChange={handleInputValue}                                    
                                    onFocus={handleInputFocus}
                                ></input>

                                {
                                    ShowPopUp & selectedColumn === 'student_type_descr' ?
                                        <div className="absolute w-[14%]">
                                            <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                                onBlurSetDataFunction={onBlurSetDataFunction}
                                                WhenNoDataFoundFunction={WhenNoDataFoundFunction}
                                                items={ReduxDDStudentTypeData[0]}
                                                columnName='student_type_descr'

                                                // the below code is for SINGLE_NOT_TABLE, single selection.
                                                columnType='SINGLE_NOT_TABLE'
                                                PropsIDArray={[student_type]}
                                                PropsIDArrayValueStr={[student_type_descr]}
                                            />
                                        </div>
                                        :
                                        null
                                }

                            </div>
                        )} */}

                        <div>
                            <label className="label_inner_page_css">Class</label>
                            <input className="input_inner_page_css"
                                type="text"
                                ref={(ref) => addInputRef(ref, 5)}
                                name="st_class_descr"
                                id="st_class_descr"
                                value={st_class_descr}
                                onChange={handleInputValue}                                    
                                onFocus={handleInputFocus}
                            ></input>

                            {
                                ShowPopUp & selectedColumn === 'st_class_descr' ?
                                    <div className="absolute w-[14%]">
                                        <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                            onBlurSetDataFunction={onBlurSetDataFunction}
                                            WhenNoDataFoundFunction={WhenNoDataFoundFunction}
                                            items={ReduxDDStudentClassData[0]}
                                            columnName='st_class_descr'

                                            // the below code is for SINGLE_NOT_TABLE, single selection.
                                            columnType='SINGLE_NOT_TABLE'
                                            PropsIDArray={[st_class]}
                                            PropsIDArrayValueStr={[st_class_descr]}
                                        />
                                    </div>
                                    :
                                    null
                            }

                        </div>

                        <div>
                            <label className="label_inner_page_css">Sec.</label>
                            <input className="input_inner_page_css"
                                type="text"
                                ref={(ref) => addInputRef(ref, 5)}
                                name="st_sec_descr"
                                id="st_sec_descr"
                                value={st_sec_descr}
                                onChange={handleInputValue}                                    
                                onFocus={handleInputFocus}
                            ></input>

                            {
                                ShowPopUp & selectedColumn === 'st_sec_descr' ?
                                    <div className="absolute w-[14%]">
                                        <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                            onBlurSetDataFunction={onBlurSetDataFunction}
                                            WhenNoDataFoundFunction={WhenNoDataFoundFunction}
                                            items={ReduxDDStudentSectionData[0]}
                                            columnName='st_sec_descr'

                                            // the below code is for SINGLE_NOT_TABLE, single selection.
                                            columnType='SINGLE_NOT_TABLE'
                                            PropsIDArray={[st_sec]}
                                            PropsIDArrayValueStr={[st_sec_descr]}
                                        />
                                    </div>
                                    :
                                    null
                            }

                        </div>

                        {parseInt(ReduxGlobalData[0].stem) === 0 && (
                            <div>
                                <label className="label_inner_page_css">Stream</label>
                                <input className="input_inner_page_css"
                                    type="text"
                                    ref={(ref) => addInputRef(ref, 6)}
                                    name="stream_descr"
                                    id="stream_descr"
                                    value={stream_descr}
                                    onChange={handleInputValue}                                        
                                    onFocus={handleInputFocus}
                                ></input>

                                {
                                    ShowPopUp & selectedColumn === 'stream_descr' ?
                                        <div className="absolute w-[14%]">
                                            <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                                onBlurSetDataFunction={onBlurSetDataFunction}
                                                WhenNoDataFoundFunction={WhenNoDataFoundFunction}
                                                items={ReduxDDStStreamData[0]}
                                                columnName='stream_descr'

                                                columnType='SINGLE_NOT_TABLE'
                                                PropsIDArray={[stream]}
                                                PropsIDArrayValueStr={[stream_descr]}
                                            />
                                        </div>
                                        :
                                        null
                                }

                            </div>

                        )}

                        <div>
                            <label className="label_inner_page_css">Fee Code</label>
                            <input className="input_inner_page_css"
                                type="text"
                                ref={(ref) => addInputRef(ref, 5)}
                                name="fee_code_descr"
                                id="fee_code_descr"
                                value={fee_code_descr}
                                onChange={handleInputValue}                                    
                                onFocus={handleInputFocus}
                            ></input>

                            {
                                ShowPopUp & selectedColumn === 'fee_code_descr' ?
                                    <div className="absolute w-[14%]">
                                        <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                            onBlurSetDataFunction={onBlurSetDataFunction}
                                            WhenNoDataFoundFunction={WhenNoDataFoundFunction}
                                            items={ReduxDDFeeCodeData[0]}
                                            columnName='fee_code_descr'
                                            
                                            columnType='SINGLE_NOT_TABLE'
                                            PropsIDArray={[stream]}
                                            PropsIDArrayValueStr={[stream_descr]}
                                        />
                                    </div>
                                    :
                                    null
                            }

                        </div>
                             
                        <div className="col-span-2">
                            <label className="label_inner_page_css">Notes</label>
                            <input className="input_inner_page_css"
                                type="text"
                                ref={(ref) => addInputRef(ref, 5)}
                                name="notes"
                                id="notes"
                                value={notes}
                                onChange={handleInputValue}                                    
                                onFocus={handleInputFocus}
                            ></input>
                        </div>

                        <div className="col-span-4 mt-6 flex gap-2">

                            {(userAuthData.allow_read === 1 && !disableShow) && (
                            <>

                                <div>
                                    <button className="btn_back_css"
                                        value='ADM_CARD'
                                        onClick={(e) => handleButtonExportValue(e, 'BTN_FIND')}>
                                        Adm.Card
                                    </button>
                                </div>

                                <div>
                                    <button className="btn_back_css"
                                        value='DESK_SLIP'
                                        onClick={(e) => handleButtonExportValue(e, 'BTN_FIND')}>
                                        Desk
                                    </button>
                                </div>                        

                            </>
                            )}

                            <div>
                                {userAuthData.export_excel === 1 && (
                                    <div className="report_button_css" onClick={(e) => handleButtonExportValue(e, 'BTN_EXPORT_EXCEL')}>
                                        <FontAwesomeIcon className="faFileExcel_css" icon={faFileExcel} />
                                    </div>
                                )}
                            </div>

                            <div>
                                {userAuthData.export_pdf === 1 && (
                                    <div className="report_button_css" onClick={(e) => handleButtonExportValue(e, 'BTN_EXPORT_PDF')}>
                                        <FontAwesomeIcon className="faFilePdf_css" icon={faFilePdf} />
                                    </div>
                                )}
                            </div>

                            <div>
                                {userAuthData.print === 1 && (
                                    <div className="report_button_css" onClick={(e) => handleButtonExportValue(e, 'BTN_PRINT')}>
                                        <FontAwesomeIcon className="faPrint_css" icon={faPrint} />
                                    </div>
                                )}
                            </div>

                            <div>
                                <button className="btn_back_css"
                                    value='BTN_BACK'
                                    onClick={(e) => handleButtonExportValue(e, 'BTN_BACK')}>
                                    Back
                                </button>
                            </div>

                        </div> 

                    </div>

                </div>

                

<div ref={conponentPDF} onMouseEnter={() => setShowPopUp(false)}>
<div className="grid grid-cols-2 gap-4 p-0 ml-[20px] w-[750px] mt-2 font-my-font-name text-rec-fontSize">            
{
items.map((item, index) => (

    <div className="border border-black h-[97%]">

        <div className="mt-2 text-my-LoginfontSize font-myFontWeight flex justify-center items-center font-bold">{notes}</div>

        <div className="border-t-[1px] mt-2 border-black">
            <div className="inline-block ml-1 text-TabHeadFontSize font-myFontWeight">Adm.No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</div>
            <div className="inline-block ml-2 text-TabHeadFontSize">{item.adm_no}</div>                                                     
        </div>

        <div>
            <div className="inline-block ml-1 text-TabHeadFontSize font-myFontWeight">Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</div>
            <div className="inline-block ml-2 text-TabHeadFontSize">{item.st_name}</div>                                                     
        </div>

        <div>
            <div className="inline-block ml-1 text-TabHeadFontSize font-myFontWeight">Father Name:</div>
            <div className="inline-block ml-2 text-TabHeadFontSize">{item.fat_name}</div>                                                     
        </div>

        <div>
            <div className="inline-block ml-1 text-TabHeadFontSize font-myFontWeight">Class&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</div>
            <div className="inline-block ml-2 text-TabHeadFontSize">{item.st_class}</div>  
            <div className="inline-block ml-12 text-TabHeadFontSize font-myFontWeight">Sec.&nbsp;:</div>
            <div className="inline-block ml-2 text-TabHeadFontSize">{item.st_sec}</div>
        </div>

        <div>
            <div className="inline-block ml-1 text-TabHeadFontSize font-myFontWeight">Stream&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</div>
            <div className="inline-block ml-2 text-TabHeadFontSize">{item.st_stream}</div>                                                     
        </div>                        

        {/* border-x-[1px] border-b-[1px] */}

        {/* showBalance */}

        {
            showBalance === 'Y'?(
            <>

                <div>
                    <div className="inline-block ml-1 text-TabHeadFontSize font-myFontWeight">Balance&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</div>
                    <div className="inline-block ml-2 text-TabHeadFontSize">{item.bal_amt}</div>
                </div>

            </>
            ):(
                <div className="inline-block ml-2 text-TabHeadFontSize"></div>
            )                        
        }

        {
            showBalance === 'Y'?(

                <div className="w-[100%] mt-1 border-black border-t-[1px] flex justify-between ">

                        <div className="mt-3 mb-1 ml-1 text-TabHeadFontSize">{h1}</div>  
                        <div className="mt-3 mb-1 text-TabHeadFontSize">{h2}</div>  
                        <div className="mt-3 mb-1 mr-1 text-TabHeadFontSize">{h3}</div> 

                        {/* <div className="inline-block mt-5 mb-1 ml-1 text-TabHeadFontSize">{h1}&nbsp;&nbsp;{h2}&nbsp;&nbsp;{h3}</div> */}

                        {/* <div className="inline-block mt-5 mb-1 text-TabHeadFontSize">{h1}</div>  
                        <div className="inline-block mt-5 mb-1 text-TabHeadFontSize">{h2}</div>  
                        <div className="inline-block mt-5 mb-1 text-TabHeadFontSize">{h3}</div>                                                  */}
                </div>

            ):(
                <div className="w-[100%] mt-1 border-t-[1px] flex justify-between border-black">

                        <div className="mt-4 mb-1 ml-1 text-TabHeadFontSize">{h1}</div>  
                        <div className="mt-4 mb-1 text-TabHeadFontSize">{h2}</div>  
                        <div className="mt-4 mb-1 mr-1 text-TabHeadFontSize">{h3}</div> 

                        {/* <div className="inline-block mt-5 mb-1 ml-1 text-TabHeadFontSize">{h1}&nbsp;&nbsp;{h2}&nbsp;&nbsp;{h3}</div> */}

                        {/* <div className="inline-block mt-5 mb-1 text-TabHeadFontSize">{h1}</div>  
                        <div className="inline-block mt-5 mb-1 text-TabHeadFontSize">{h2}</div>  
                        <div className="inline-block mt-5 mb-1 text-TabHeadFontSize">{h3}</div>                                                  */}
                </div>
            )                        
        }

    </div>                 
    
))
}
</div>      
    
</div>

</form>
</>
);
};

export default DailyCollectionReport;
