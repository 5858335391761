import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import CRUDContext from '../components/CRUDContext';

import SearchListDropdown from "../components/SearchListDropdown";

import moment from 'moment';

import { emptyTempDataFun, emptyGlobalDataFun, setTempDataFun } from '../components/redux/actions';

import Cookies from 'js-cookie';

const UserMaster = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';    
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);    
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);      
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);   

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disableSave, setdisableSave] = useState(0);
    const [disableDelete, setdisableDelete] = useState(0);
    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');
    const [AllowUpdateTag, setAllowUpdateTag] = useState(false);

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData); 
    const ReduxTempData = useSelector((state) => state.TempData);
   

    let v_input_result, v_active_status = 1, v_null_parameter = 'NA';

    const [options, setOptions] = useState({ length: 8 });

    // define variable for tables entry (master detail form)    

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);
    const [origionalData, setOrigionalData] = useState([]);

    const [ShowPopUp, setShowPopUp] = useState(false);
    const [selectedrow, setSelectedrow] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(false);

    const [IDArray, setIDArray] = useState([]);
    const [IDArrayValue, setIDArrayValue] = useState([]);    
    
    // define standard errors and entry required messages

    // const [errors_message_adm_no, setErrors_message_adm_no] = useState('');
    // const [errors_adm_no, setErrors_adm_no] = useState(false);

    const [user_name, setuser_name] = useState('');
    const [user_group_id, setuser_group_id] = useState(0);
    const [emp_master_id, setemp_master_id] = useState(0);
    const [emp_master_id_descr, setemp_master_id_descr] = useState('');
    const [password, setpassword] = useState('');
    const [show_in_list, setshow_in_list] = useState(0);
    const [active_status, setactive_status] = useState(0);
    const [under_comp_group_id, setunder_comp_group_id] = useState(0);
    const [under_comp_group_id_descr, setunder_comp_group_id_descr] = useState('');
    const [notes, setnotes] = useState();   
    
    const [user_group_idItems, setuser_group_idItems] = useState([]);
    const [emp_master_idItems, setemp_master_idItems] = useState([]);    

    const [under_group_codeItems, setunder_group_codeItems] = useState([]);

    const YesNoList = [
        // { id: 0, sel_val: 0, sel_desc: "" },
        { id: 1, sel_val: 1, sel_desc: "Yes" },
        { id: 2, sel_val: 2, sel_desc: "No" }
    ];

    // define form name and navigation details

    const v_urlSaveUpdateDelete = '3_url_auth_user_new_userbranchwise';
    const v_backToNavigateName = '/brms';
    const v_findToNavigateName = '/nugb_view';
    const v_main_form_heading = 'User Registration (Company and Branch Wise Assignment)';
    const v_frm_no = 3;

    const {
        deletedMessage,
        createdMessage,
        updatedMessage,        
        whenStudentNotFoundMessage,                     
        WhenRecordNotFoundToDelete,
        WhenRecordNotFoundToUpdate,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);          

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code

                setv_open_frm(true);                

                setdisableSave(false);
                setdisableDelete(true);

                // console.log("Update allow_update >> ", result.allow_update)

                setBlankFields();

                const v_token = Cookies.get('token');                

                response = await axios.get(`url_fetch_data/3_url_usergroup_drop_down/32/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}        
                `,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data; 
                setuser_group_idItems(await updatedata);               
                
                response = await axios.get(`url_fetch_data/3_url_emp_master_drop_down/32/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}        
                `,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data; 
                setemp_master_idItems(await updatedata);

                response = await axios.get(`url_fetch_data/3_url_comp_group_other_view_drop_down/32/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}        
                `,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data; 
                setunder_group_codeItems(await updatedata);                

                response = await axios.get(`url_fetch_data/3_userwise_branch_wise_view_fun/${v_frm_no}/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
                /${TableRecordID}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}        
                `,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data; 
                setOrigionalData(await updatedata);
                setItems(await updatedata);

                if (ReduxTempData.length > 0){
                    FindSavedTableMaster(ReduxTempData[0].temptableid);
                    FindSavedTableDetail(ReduxTempData[0].temptableid);
                    if(result.allow_update === 2){setAllowUpdateTag(true)};
                    dispatch(emptyTempDataFun());
                }
            }
        }
        loadData();
    }, []);

    useEffect(() => {
        setBlankFields();
    }, [ReduxGlobalData[0]]);    

    const FindSavedTableMaster = async(p_TableRecordID) => {            

        const v_token = Cookies.get('token');
        
        response = await axios.get(`url_fetch_data/3_url_auth_user_view/${v_frm_no}/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
        /${p_TableRecordID}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;
        
        setTableRecordID(p_TableRecordID);
        setuser_name(updatedata[0].user_name);
        setuser_group_id(updatedata[0].user_group_id);
        setemp_master_id(updatedata[0].emp_master_id);
        setemp_master_id_descr(updatedata[0].emp_master_id_descr);
        setpassword('');
        setshow_in_list(updatedata[0].show_in_list);
        setactive_status(updatedata[0].active_status);
        setunder_comp_group_id(updatedata[0].under_comp_group_id);
        setunder_comp_group_id_descr(updatedata[0].under_comp_group_id_descr);                        
        setnotes(updatedata[0].notes);
    }

    const FindSavedTableDetail = async(p_TableRecordID) => {

        const v_token = Cookies.get('token');
        
        // show the detail part
        
        response = await axios.get(`url_fetch_data/3_userwise_branch_wise_view_fun/${v_frm_no}/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
        /${p_TableRecordID}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;             
        setItems(await updatedata);        
    }

    const generateRandomPassword = () => {

        // event.preventDefault();

        const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
        const numberChars = "0123456789";
        const symbolChars = '^!<*&@>+=';

        let passwordChars = "";
        let password = "";

        passwordChars += uppercaseChars;
        passwordChars += lowercaseChars;
        passwordChars += numberChars;
        passwordChars += symbolChars;

        const passwordLength = options.length;

        for (let i = 0; i < passwordLength; i++) {
            const randomIndex = Math.floor(Math.random() * passwordChars.length);
            password += passwordChars[randomIndex];
        }

        return password;        
    };
    
    function setBlankFields() {
        setsaveUpdateTag('SAVE');
        setAllowUpdateTag(false);

        setdisableSave(false);
        setdisableDelete(true);

        setTableRecordID(0);
        
        setuser_name('');
        setuser_group_id(0);
        setemp_master_id(0);
        setemp_master_id_descr('');
        setpassword(generateRandomPassword());
        setshow_in_list(0);
        setunder_comp_group_id(0);
        setunder_comp_group_id_descr('');
        setnotes('');

        setItems(origionalData);
    }

    const handleInputValueFun = (name, value) => {

        if (name === 'user_name') {            
            setuser_name(value);
            return;
        }else if (name === 'user_group_id') {            
            setuser_group_id(value);
            return;
        }else if (name === 'emp_master_id_descr') {            
            setemp_master_id_descr(value);
            return;
        }else if (name === 'password') {
            setpassword(value);
            return;            
        }else if (name === 'show_in_list') {
            setshow_in_list(value);
            return;    
        }else if (name === 'active_status') {
            setactive_status(value);
            return;    
        }else if (name === 'under_comp_group_id_descr') {
            setunder_comp_group_id_descr(value);
            return;
        }else if (name === 'notes') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 500);
            setnotes(capitalizeWordFun(v_input_result.v_value));            
            return;
        }

    }

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target; 
        
        let v_vou_date = null, insupdel_type = '';
        
        if (value === 'DELETE'){

            if (TableRecordID === 0){
                toast.info(WhenRecordNotFoundToDelete, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            insupdel_type = 'DELETE';
        }else{
            if (TableRecordID === 0){
                insupdel_type = 'INSERT';            
            }else{

                if (TableRecordID === 0){
                    toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return;
                }

                if (userAuthData.allow_update === 1){
                    setAllowUpdateTag(false);
                    insupdel_type = 'UPDATE';
                }else{
                    setAllowUpdateTag(true);
                    return;
                }                  
            }
        }

        if (value != 'DELETE'){

            // need to validate the date here
            
            if (user_name.length === 0){                
                toast.error('Entry required for User name !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;                
            }

            if(parseInt(user_group_id) === 0){                
                toast.error('Entry required for Usnder user-group  !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }

            if(parseInt(emp_master_id) === 0){                
                toast.error('Entry required for Employee name  !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }

            if (password.length === 0){                
                toast.error('Entry required for Password !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;                
            }

            if (show_in_list === undefined || show_in_list === 0 || show_in_list === '0') {
                toast.error('Entry required for Show-in-List  !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            if (active_status === undefined || active_status === 0 || active_status === '0') {
                toast.error('Entry required for Active Status  !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            if(parseInt(under_comp_group_id) === 0){                
                toast.error('Entry required for Under company group  !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }
            
        }        

        const data = {
            id: TableRecordID,
            insupdel_type,                  
            under_comp_branch_id : 1,
            user_name,
            user_group_id,
            emp_master_id,           
            password,   
            show_in_list,         
            active_status,            
            under_comp_group_id,  
            notes,
            items: JSON.stringify(items)
        };
        
        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }    
    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);        

        if (p_data.ModelYesNoAnswer === 'YES'){
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async(p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final:p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final:p_last_updel_notes_final,
        }        

        const v_token = Cookies.get('token');

        // response = await axios.post(`${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        // { headers: { Authorization: `Bearer ${v_token}` } });

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });
        
        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }
    
    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }     

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                    
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            // setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);
            
            // console.log("updatedata.TabID.v_data >> ", updatedata.v_data);
            
            if (updatedata.transType === 'INSERT'){
                setdisableDelete(false);             
                setsaveUpdateTag('UPDATE');
                v_toast_msg = createdMessage;

                setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);

                getSavedDetailFunction(updatedata.v_data[0].p_id);
            }else if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;

                getSavedDetailFunction(TableRecordID);
            }else if (updatedata.transType === 'DELETE'){
                setdisableSave(true);
                setdisableDelete(true);   
                v_toast_msg = deletedMessage;
                setBlankFields();
            }

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }

    const setNewRecordIDFunction = async(TabID, p_v_data) => {
        
        // console.log("setNewRecordIDFunction >> ", p_v_data[0].p_id, p_v_data[0].p_vou_no);

        setTableRecordID(await p_v_data[0].p_id);        
    }

    const getSavedDetailFunction = async (p_v_data) => {     

        if (userAuthData.allow_update === 1){
            setAllowUpdateTag(false);
        }else{
            setAllowUpdateTag(true);
        }

        FindSavedTableDetail(p_v_data);
    }

    const WhenNoDataFoundFunction = (p_data) => {
     
        // console.log("When no data >> ", p_data.selected_items);

        if(p_data.p_calling_fun_type === 'NO_DATA_FOUND'){
            SetDataAfterReturnFromSearchList(p_data);
        }
    }

    const onBlurSetDataFunction = (p_data) => {
        // console.log("On Blur selected_items Data >> ", p_data);

        if(p_data.p_calling_fun_type === 'CALL_WHEN_KEY_PRESS'){
            SetDataAfterReturnFromSearchList(p_data);
        }
    }

    const setPopUpDDFalseFunction = (p_data) => {

        // console.log("Return selected_items Data >> ", p_data.selected_items);
       
        SetDataAfterReturnFromSearchList(p_data);

        setShowPopUp(false);
        setConditionalCSS(false);        
    }

    const SetDataAfterReturnFromSearchList = (p_data) => {
        
        // console.log("Selected_items Data >> ", p_data.SelectedIDArray, p_data.SelectedIDArrayValue);        

        if (p_data.columnType === 'SINGLE_TABLE' || p_data.columnType === 'MULTIPLE_TABLE'){
        }

        if (p_data.columnType === 'SINGLE_NOT_TABLE' || p_data.columnType === 'MULTIPLE_NOT_TABLE'){
            if (p_data.selectedColumnName === 'emp_master_id_descr'){
                setemp_master_id(p_data.SelectedIDArray);
                setemp_master_id_descr(p_data.SelectedIDArrayValue);
            }else if (p_data.selectedColumnName === 'under_comp_group_id_descr'){
                setunder_comp_group_id(p_data.SelectedIDArray);
                setunder_comp_group_id_descr(p_data.SelectedIDArrayValue);
            }
        }
    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        // console.log("handleInputFocus value >> ", value)

        if (name === 'emp_master_id_descr'){            
            setShowPopUp(true);
            setSelectedColumn('emp_master_id_descr');
            return;
        }else if (name === 'under_comp_group_id_descr'){            
            setShowPopUp(true);
            setSelectedColumn('under_comp_group_id_descr');
            return;
        }else{
            setShowPopUp(false);
            return;
        }
    };

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        // console.log('in handleInputValue function')

        if (value.toString().trim().length === 0){
            if (name === 'emp_master_id_descr'){            
                setemp_master_id(0);                                
            }else if (name === 'under_comp_group_id_descr'){            
                setunder_comp_group_id(0);
            }
        }

        handleInputValueFun(name, value);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        if (value === 'GENERATE_NEW_PASSWORD'){            
            setpassword(generateRandomPassword());            
            return;
        }else if (value === 'ADD_NEW_ROW'){
            
            if(AllowUpdateTag){return;}
            
            addItem();
            return;
        }else if (value === 'BTN_NEW'){            
            setBlankFields();
            return;
        }else if (value === 'BTN_PRINT'){
            
            // if (TableRecordID === 0) {
            //     toast.error(whenStudentNotFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
            //         hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
            //         draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            //     });
            //     return;
            // }           

            return;
        }else if (value === 'BTN_EXPORT_EXCEL'){
                        
            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        }else if (value === 'BTN_EXPORT_PDF'){
            
            return;
        }else if (value === 'BTN_FIND'){            
            navigate(`${v_findToNavigateName}`); 
            return;
        }else if (value === 'BTN_COPY_TO_BRANCH'){
            return;
        }else if (value === 'BTN_ASSIGN_BRANCH'){
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };
    
    const goCheckDataFunction = (event) => {
        event.preventDefault();

        console.log("TableRecordID >> ", TableRecordID);

        console.log("items >> ", items);

    }

    // For details entry (entry in table)
    // For details entry (entry in table)
    
    const handlerChangeTableRowFocus = (event, i, p_col_name) => {
        event.preventDefault();       
        setShowPopUp(false);
    };

    const addItem = () => {
        // setTabID(tabID + 1);
        // setItems([...items, {ischecked : false, t1_id: tabID * -1 , "id": 0, dr_cr: 0, ac_led_id: '', ac_led_id_descr: '', dr_amt: '', cr_amt: '', notes: ""}]);
    };

    const handlerChange = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;
        // const list = [...items];        
        // list[i][name] = value;
        // setItems(list);
        // console.log(event.target, " >> ", i);

        let v_value = value;       

        setSelectedrow(i);
        setSelectedColumn(p_col_name);
        const list = [...items];
        
        if (name === 'notes'){                
            v_value = capitalizeWordFun(v_value);
        }

        list[i][name] = v_value; 
        setItems(list); 
    };

    const onClickTableRowFun = (event, p_id, p_value) => {
        event.preventDefault();

        // console.log("When Click on SHOW_RECORD >> ", saveUpdateTag);        

        if (p_value === 'ADD_NEW_ROW') {

            // console.log("When Row ID >> ", p_id);
            
            if(AllowUpdateTag){return};

            setTabID(tabID + 1);
            let v_data = {ischecked : false, t1_id: tabID * -1 , "id": 0, dr_cr: 0, ac_led_id: '', ac_led_id_descr: '', dr_amt: '', cr_amt: '', notes: ""}; 
    
            items.splice(p_id + 1, 0, v_data);

        }else{
            ischeckedTick(p_id);
        }

    };

    const ischeckedTick = (p_id) => {

        let v_check_value = true;

        // console.log("In Table Row Click : ", p_id);

        // console.log("In Row Tick IDArray Before >> ", IDArray);

        // const filteredData = items.filter((item) => item.id.includes(p_id));

        const filteredData = items.find(item => {return item.id === p_id});        

        // console.log("In Table Row Click filteredData : ", filteredData.ischecked);

        if (filteredData.ischecked) {
            v_check_value = false;
            const index = IDArray.indexOf(p_id);
            IDArray.splice(index, 1);
            IDArrayValue.splice(index, 1);
        } else {
            v_check_value = true;
            IDArray.push(filteredData.id);
            // IDArrayValue.push(filteredData.st_class);
        }

        for (let i = 0; i < items.length; i++) {
            const checkedvalue = items.map((user) => user.id.toString() === p_id.toString() ? { ...user, ischecked: v_check_value } : user);
            setItems(checkedvalue);
        }

        SelectedValueConvertToStr(IDArray, IDArrayValue);

    }

    const SelectedValueConvertToStr = (p_tempIDList, p_tempValueList) => {

        // console.log("In Convert >> ", p_tempList.length, ' >> ', p_tempList);

        let tempIDArrayStr = '', tempIDArrayValueStr = '';

        for (let i = 0; i < p_tempIDList.length; i++) {
            if (i === 0) {
                tempIDArrayStr = p_tempIDList[i];
                tempIDArrayValueStr = p_tempValueList[i];
            } else {
                tempIDArrayStr = tempIDArrayStr + "," + p_tempIDList[i];
                tempIDArrayValueStr = tempIDArrayValueStr + "," + p_tempValueList[i];
            }
        }

        setIDArray(p_tempIDList);
        setIDArrayValue(p_tempValueList);
    }

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        if(AllowUpdateTag){return};        

        let tempListID = [];        

        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, ischecked: checked } });
            setItems(checkedvalue);

            setIDArray([]);            

            if (checked) {
                for (let i = 0; i < items.length; i++) {
                    tempListID.push(items[i].id);
                    // tempListValue.push(items[i].st_class);
                }
            }

            setIDArray(tempListID);
        }
        else {                     

            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, ischecked: checked } : user);
            setItems(checkedvalue);

            setIDArray([]);

            for (let i = 0; i < checkedvalue.length; i++) {

                if(checkedvalue[i].ischecked){
                    tempListID.push(checkedvalue[i].id);
                }
            }

            setIDArray(tempListID);

        }

        // SelectedValueConvertToStr(tempListID, tempListValue);
    }

    const setShowPopUpFalseFunction = (event) => {        
        // setShowPopUp(false);
    };

    const handleInputBlur = (event, i, p_col_name) => {
        // event.preventDefault();
        // const { name, value } = event.target;

        // console.log("handleInputBlur >> ", value);

        // // setSelectedrow(i);
        // // setSelectedColumn(p_col_name);

        // if (p_col_name === 'FEE_DETAIL_FEE_MONTH_DESCR' || p_col_name === 'FEE_DETAIL_FEE_CODE_DESCR'){
            

            
        //     setShowPopUp(true);
        // }else{
            
        //     setShowPopUp(false);
        // }

        // console.log('Input field lost focus');

    };

return (

<>
    {
        ShowConfirmModelSave ? 
        <div className="centerWindow_css">
            <ConfirmModelSave transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

    {
        ShowConfirmModelUpdateDelete ? 
        <div className="centerWindow_css w-[32%]">
            <ConfirmModelUpdateDelete transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} onMouseOut={(e) => setShowPopUpFalseFunction(e)} autoComplete="off">

<div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

<div className="form_div_main_css">
    <div className="form_div_inner_css grid grid-cols-5">      

        <div>
            <label className="label_inner_page_css required_css">User Name</label>
            <input className="input_inner_page_css"
                type="text"
                ref={(ref) => addInputRef(ref, 5)}
                name="user_name"
                id="user_name"
                value={user_name}                    
                onChange={handleInputValue}                                    
                onFocus={handleInputFocus}
                disabled = {saveUpdateTag === 'SAVE' ? false : true}
            ></input>
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css required_css">Under User Group</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="user_group_id"
                id="user_group_id"
                value={user_group_id}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                {
                    user_group_idItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>           

        <div>
            <label className="label_inner_page_css required_css">Employee Name</label>
            <input className="input_inner_page_css"
                type="text"
                ref={(ref) => addInputRef(ref, 5)}
                name="emp_master_id_descr"
                id="emp_master_id_descr"
                value={emp_master_id_descr}                    
                onChange={handleInputValue}                                    
                onFocus={handleInputFocus}
                disabled = {saveUpdateTag === 'SAVE' ? false : true}
            ></input>
            {
                ShowPopUp & selectedColumn === 'emp_master_id_descr' ? 
                    <div className="absolute w-[20%]">
                        <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                            onBlurSetDataFunction = {onBlurSetDataFunction}
                            WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                            items={emp_master_idItems}
                            columnName='emp_master_id_descr'                           
                            columnType='SINGLE_NOT_TABLE'
                            PropsIDArray={[emp_master_id]}
                            PropsIDArrayValueStr={[emp_master_id_descr]}                           
                        />
                    </div>
                :
                null
            }            
        </div>

        <div>
            <label className="label_inner_page_css required_css">Password</label>
            <input className="input_inner_page_css"
                type="text"
                ref={(ref) => addInputRef(ref, 5)}
                name="password"
                id="password"
                value={password}                    
                onChange={handleInputValue}                                    
                onFocus={handleInputFocus}
                disabled
            ></input>
        </div>

        <div className="col-span-1 flex space-x-2">

            <div className="w-[48%]">
                <label className="label_inner_page_css required_css">Show-in-List</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 0)}
                    // autoFocus         
                    name="show_in_list"
                    id="show_in_list"
                    value={show_in_list}
                    onChange={handleInputValue}
                    onFocus={handleInputFocus}
                >
                    <option></option>
                    {
                        YesNoList?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div className="w-[48%]">
                <label className="label_inner_page_css required_css">Active Status</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 0)}
                    // autoFocus         
                    name="active_status"
                    id="active_status"
                    value={active_status}
                    onChange={handleInputValue}
                    onFocus={handleInputFocus}
                >
                    <option></option>
                    {
                        YesNoList?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>
        </div> 

        <div className="col-span-3">
            <label className="label_inner_page_css required_css">Under Company-Group</label>
            <input className="input_inner_page_css"
                type="text"
                ref={(ref) => addInputRef(ref, 5)}
                name="under_comp_group_id_descr"
                id="under_comp_group_id_descr"
                value={under_comp_group_id_descr}                    
                onChange={handleInputValue}                                    
                onFocus={handleInputFocus}
                // disabled = {saveUpdateTag === 'SAVE' ? false : true}
            ></input>
            {
                ShowPopUp & selectedColumn === 'under_comp_group_id_descr' ? 
                    <div className="absolute w-[60%]">
                        <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                            onBlurSetDataFunction = {onBlurSetDataFunction}
                            WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                            items={under_group_codeItems}
                            columnName='under_comp_group_id_descr'                           
                            columnType='SINGLE_NOT_TABLE'
                            PropsIDArray={[under_comp_group_id]}
                            PropsIDArrayValueStr={[under_comp_group_id_descr]}                           
                        />
                    </div>
                :
                null
            }            
        </div>

        <div className="col-span-2">
            <label className="label_inner_page_css">Notes</label>
            <input className="input_inner_page_css"
                type="text"
                ref={(ref) => addInputRef(ref, 5)}
                name="notes"
                id="notes"
                value={notes}                    
                onChange={handleInputValue}                                    
                onFocus={handleInputFocus}
                // disabled = {saveUpdateTag === 'SAVE' ? false : true}
            ></input>
        </div>       
                       
    </div>
</div>  

    <table className="table_main_css">
        <thead className="table_thead_css">            
            <tr className="table_thead_tr_css">                

                <th className="table_th_td_css text-left w-[4%]"></th>               

                <th className="table_th_td_css w-[5%]">
                    <input className="table_check_inner_page_css"
                        type="checkbox" name="allselect" checked={!items.some((user) => user?.ischecked !== true)}
                        onChange={handleChangeCheckBox}
                        onFocus={handleInputFocus}                        
                    />
                </th>
                
                <th className="table_th_td_css w-[30%] text-left">Branch Name</th>
                <th className="table_th_td_css w-[30%] text-left">Branch Print Name</th>
                <th className="table_th_td_css w-[31%] text-left">Notes</th>    
            </tr>
        </thead>

        <tbody className="table_tbody_css h-[42vh]">
        {
            items?.map((item, index) => (                   

            <tr className="table_thead_tr_css table_tbody_hover_css" key={index}>

                <td className="table_th_td_css text-left w-[4%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    {index + 1}
                </td>                
                
                <td className="table_th_td_css w-[5%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <input className="table_check_inner_page_css"                                
                        ref={(ref) => addInputRef(ref, index + 8)}
                        type="checkbox" name={item.t1_id}
                        checked={item?.ischecked || false}
                        onChange={handleChangeCheckBox}                                                                
                    />
                </td>                
                
                <td className="table_input_th_td_css w-[30%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <div className="table_input_inner_page_css">{item.branch_name}</div>
                </td>            

                <td className="table_input_th_td_css w-[30%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <div className="table_input_inner_page_css">{item.print_name}</div>
                </td>

                {/* shiv */}

                <td className="table_input_th_td_css text-left w-[31%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, 'notes')}> 
                    <input className="table_input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 15)}
                        name="notes"
                        id="notes"
                        value={item.notes}                                                                   
                        onChange={(e) => handlerChange(e, index)}                                
                        disabled = {AllowUpdateTag}
                    ></input>              
                    
                </td>                 

            </tr>
            ))
        }
        </tbody>
    </table>

    <nav className="btn_nav_table_sum_inner_css">
    <div className="btn_div_main_css">

        <div className="btn_div_inner_css">

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_new_css"
                            value = 'GENERATE_NEW_PASSWORD'
                            onClick={handleButtonValue}>
                            Generate New Password
                        </button>
                    )}
                </div>
                
                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_new_css"
                            value = 'BTN_NEW'
                            onClick={handleButtonValue}>
                            New
                        </button>
                    )}
                </div>

                <div>
                    {!AllowUpdateTag && userAuthData.allow_new === 1 && (
                        <button className="btn_save_css"
                            disabled={disableSave}
                            onClick={createRecordFunction}>
                            Save
                        </button>
                    )}
                </div>
                    
                <div>
                    {AllowUpdateTag && userAuthData.print === 1 && (
                        <button className="btn_print_css"
                            value = 'BTN_PRINT'
                            onClick={handleButtonValue}>
                            Print
                        </button>
                    )}
                </div>

                <div>                
                    {userAuthData.allow_delete === 1 && (
                        <button className="btn_delete_css"
                            value = 'DELETE'
                            // disabled={disableDelete}
                            onClick={createRecordFunction}>
                            Delete
                        </button>
                    )}
                </div>

                <div>
                    {userAuthData.allow_read === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_FIND'
                            onClick={handleButtonValue}>
                            Find
                        </button>
                    )}
                </div>  

                <div>
                    <button className="btn_back_css"
                        value = 'BTN_BACK'
                        onClick={handleButtonValue}>
                        Back
                    </button>
                </div>

                {/* <div>                
                    <button className="btn_back_css"
                        onClick={goCheckDataFunction}>
                        Check Data
                    </button>
                </div> */}
                
        </div>
        
    </div>
    </nav>

    </form>
:null
}

</>
);};

export default UserMaster;
