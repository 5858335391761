// Table name : searching functionality for student_master depends on under_branch and fin year wise
import React, { useState, useContext, useEffect } from "react";
// import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import CRUDContext from './CRUDContext';
// import Layout from './Layout';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

// import { isAuthenticatedUser } from '../pages/isAuthenticated';
// import { addTempList, emptyCart } from './redux/actions';

const ModelFormFindStudentAll = ({ isVisible, onClose, form_name}) => {
    
    // const dispatch = useDispatch();
    // const ReduxData = useSelector((state) => state);
    
    const [items, setItems] = useState([]);
    const [filterdata, setFilterdata] = useState([]);
    
    const [searchadmno, setsearchadmno] = useState('');
    const [searchst_class, setsearchst_class] = useState('');
    const [searchst_name, setsearchst_name] = useState('');    

    let list1 = [];

    // const router = useRouter()

    const navigate = useNavigate();

    

    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,
        updateRecord,
        deleteRecord,
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        viewRecord,
        setviewRecord,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenNullViewRecordMessage,
        whenMultipleViewRecordMessage,
    } = useContext(CRUDContext);

    

    // // ////////////////////////////

    // const getAuthroziedUser = async () => {  
    //     try{
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //               { headers: { Authorization: `Bearer ${token}` } });            
    
    //         if (response.status === 401 || response.status === 403){
    //             localStorage.clear();
    //             navigate('/Login')
    //         };

    //     }catch(error){
    //         localStorage.clear();
    //         navigate('/Login')
    //     }
    // }    

    // useEffect( () => {        
    //     getAuthroziedUser();    
    // }, []);

    // // ////////////////////////////   

    useEffect(() => {
        const getAllTableRecordFunction = async (var_link) => {
            let updatedata = []
            let response = []            

            response = await axios.get(`${var_link}/${localStorage.getItem('br')}/${localStorage.getItem('fy')}`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            // response = await axios.get(`${var_link}/`,
            // { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data;

            // console.log(updatedata)

            setItems(updatedata);
            setFilterdata(updatedata);
        }
        getAllTableRecordFunction('url_student_master_scholer_view');
    }, [])

    if (!isVisible) return null;

    function showRecordFunction(p_id, p_adm_no, p_st_class, p_st_name, p_fat_name, p_mot_name, p_under_comp_branch_id_name, p_fin_year_name,
        p_under_comp_branch_id, p_fin_year) {

        localStorage.setItem('tm', p_id);  // student id
        localStorage.setItem('tm_an', p_adm_no); // temporary value admission number
        localStorage.setItem('tm_cn', p_st_class); // temporary value student class name
        localStorage.setItem('tm_sn', p_st_name); // temporary value student student name
        localStorage.setItem('tm_fn', p_fat_name); // temporary value student father name
        localStorage.setItem('tm_mn', p_mot_name); // temporary value student mother name
        localStorage.setItem('tm_bn', p_under_comp_branch_id_name); // temporary value student branch name
        localStorage.setItem('tm_fyn', p_fin_year_name); // temporary value student financial year name
        localStorage.setItem('tm_brno', p_under_comp_branch_id); // temporary value student branch no
        localStorage.setItem('tm_fyno', p_fin_year); // temporary value student financial year no
        
        onClose();
    }

    const handleSearchAdmNo=(event)=>{
        event.preventDefault();
        const getSearch= event.target.value;        
        let searchdata = filterdata;

        searchdata= searchdata.filter( (item)=> item.adm_no.toLowerCase().includes(getSearch.toLowerCase()));
        searchdata= searchdata.filter( (item)=> item.st_class.toLowerCase().includes(searchst_class.toLowerCase()));        
        searchdata= searchdata.filter( (item)=> item.st_name.toLowerCase().includes(searchst_name.toLowerCase()));
           
        setsearchadmno(getSearch);
        setItems(searchdata);        
    }

    const handleSearchStClass=(event)=>{
        event.preventDefault();
        const getSearch= event.target.value;        
        let searchdata = filterdata;
        
        searchdata= searchdata.filter( (item)=> item.adm_no.toLowerCase().includes(searchadmno.toLowerCase()));
        searchdata= searchdata.filter( (item)=> item.st_class.toLowerCase().includes(getSearch.toLowerCase()));        
        searchdata= searchdata.filter( (item)=> item.st_name.toLowerCase().includes(searchst_name.toLowerCase()));
        
        setsearchst_class(getSearch);
        setItems(searchdata);        
    }

    const handleSearchStName=(event)=>{
        event.preventDefault();
        const getSearch= event.target.value;        
        let searchdata = filterdata;
        
        searchdata= searchdata.filter( (item)=> item.adm_no.toLowerCase().includes(searchadmno.toLowerCase()));
        searchdata= searchdata.filter( (item)=> item.st_class.toLowerCase().includes(searchst_class.toLowerCase()));        
        searchdata= searchdata.filter( (item)=> item.st_name.toLowerCase().includes(getSearch.toLowerCase()));       
                
        setsearchst_name(getSearch);

        setItems(searchdata);        
    }

return (

// <div className="justify-center items-center fixed inset-0 bg-my-when-focus-bg-color bg-opacity-100 backdrop-blur-3xl flex"></div>

<>

<div className="mt-FromTopMenuMargin justify-center items-center fixed inset-0 bg-my-when-focus-bg-color bg-opacity-100 backdrop-blur-3xl">
        <div className="mt-20 border-TabBorderWidth border-TabHeadingBorderColor overflow-y-scroll">
            <div className="grid lg:grid-cols-12">
                
                <div className="pl-PaddingLeft text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Adm.No.
                </div>
        
                <div className="pr-PaddingRight text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Class
                </div>

                <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-2">
                    Name
                </div>

                <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-2">
                    Father's Name
                </div>
                
                <div className="pr-PaddingRight text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-2">
                    Mother's Name
                </div>

                <div className="pr-PaddingRight text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Fin Year
                </div>

                <div className="pr-PaddingRight text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-3">
                    Under Branch
                </div>
        
            </div>
        </div>

        <div className="border-TabBorderWidth border-TabHeadingBorderColor overflow-y-scroll">
            <div className="grid lg:grid-cols-12">
                
                <input className="w-[100%] text-center text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth 
                    border-InputBorderColor focus:bg-InputFocusBGColor"

                    type="text"
                    name="adm_no"
                    value={searchadmno}                        
                    onChange={(e)=>handleSearchAdmNo(e)}
                    autoComplete="off"
                ></input>

                <input className="w-[100%] text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth 
                    border-InputBorderColor focus:bg-InputFocusBGColor
                    col-span-1"
                    type="text"
                    name="st_class"
                    value={searchst_class}                        
                    onChange={(e)=>handleSearchStClass(e)}
                    autoComplete="off"                                                
                ></input>

                <input className="w-[100%] text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth 
                    border-InputBorderColor focus:bg-InputFocusBGColor
                    col-span-2"

                    type="text"
                    name="st_name"
                    value={searchst_name}                        
                    onChange={(e)=>handleSearchStName(e)}
                    autoComplete="off"
                ></input>
                
        
            </div>
        </div>
        
        <div className="border-TabBorderWidth border-TabBorderColor h-InsertTableHeight overflow-y-scroll">
            {
                items?.map((item, i) => (
        
                    <div className="grid grid-cols-12" key={item.id}>
        
                        <input className="w-[100%] pl-PaddingLeft text-center text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="adm_no"
                            value={item.adm_no}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />
        
                        <input className="w-[100%] pl-PaddingLeft text-left text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="st_class"
                            value={item.st_class}                            
                            disabled  
                        />
        
                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-2"
                            type="text"
                            name="st_name"
                            value={item.st_name}                            
                            disabled                            
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-2"
                            type="text"
                            name="fat_name"
                            value={item.fat_name}                            
                            disabled                              
                        />

                        <input className="w-[100%] pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-2"
                            type="text"
                            name="mot_name"
                            value={item.mot_name}                            
                            disabled                             
                        />

                        <input className="w-[100%] text-center pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="fin_year_name"
                            value={item.fin_year_name}                                                           
                            disabled
                        />

                        <input className="w-[100%] pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-2"
                            type="text"
                            name="under_comp_branch_id_name"
                            value={item.under_comp_branch_id_name}                                                           
                            disabled
                        />

                        <div> 
                            <button className="w-full bg-ViewRowBtnBGColor hover:bg-ViewRowBtnHoverColor text-ViewRowBtnFontSize text-ViewRowBtnFontColor font-ViewRowBtnFontName rounded-none"
                                onClick={() => showRecordFunction(item.id, item.adm_no, item.st_class, item.st_name, item.fat_name, item.mot_name,
                                    item.under_comp_branch_id_name, item.fin_year_name, item.under_comp_branch_id, item.fin_year)}
                            >View</button>
                        </div>                             
                        
                    </div>
                ))
            }
        </div>

        <div className="mt-2 flex justify-center gap-4">
            <div className="w-[15%] text-center bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                >
                Record Found : {items.length}
            </div>
           
            <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={() => onClose()}>   
                Back
            </button>

        </div>

</div>
</>
);};

export default ModelFormFindStudentAll;
