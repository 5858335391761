import React, { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import ShowShivMarquee from './ShowShivMarquee';

import ShowMarqueeMainWebPage from './ShowMarqueeMainWebPage';

import ShowMarqueeBranchWise from './ShowMarqueeBranchWise';
import {toast} from 'react-toastify';
import { useNavigate } from "react-router-dom";

import Cookies from 'js-cookie';

import axios from "axios";

import ChangePassword from './ChangePassword';

import MenuItem from './MenuItem';

import ChangeBranchFinYear from './ChangeBranchFinYear';

import { useDispatch, useSelector } from 'react-redux';
import { setGlobalDataFun, emptyGlobalDataFun, setTempDataFun, emptyTempDataFun } from './redux/actions';

const MainWebPageCustomNavbar = () => {

    let response = [], updatedata = [];

    const dispatch = useDispatch();
    const router = useNavigate();

    const [menu_items, setmenu_items] = useState([]);

    const v_token = Cookies.get('token')

    const [conditionalCSS, setConditionalCSS] = useState(false);

    const [ShowChangeBranchFinYear, setShowChangeBranchFinYear] = useState(false);

    const [ShowChangePassword, setShowChangePassword] = useState(false);

    // const [ReduxGlobalData, setReduxGlobalData] = useSelector((state) => state.GlobalData);    
    // const [ReduxTempData, setReduxTempData] = useSelector((state) => state.TempData);

    const ReduxGlobalData = useSelector((state) => state.GlobalData);
    // const ReduxTempData = useSelector((state) => state.TempData);

    // const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);
    // const [fyItems, setfyItems] = useState([]);

    // const [under_comp_branch_id, setunder_comp_branch_id] = useState(0);
    // const [fy, setfy] = useState(0); 

    const getLoad = async() => {       

        try{
            response = await axios.get(`url_menu_items_view_fun/${ReduxGlobalData[0].id}`,
                { headers: { Authorization: `Bearer ${v_token}` } });

            updatedata = await response.data.v_out_data.tree;        
            setmenu_items(Array(await updatedata));
        }catch(error){            
            
            // toast.error("Error in getLoad NavBar ! " || error, {position:"top-center",});
            // navigate('/Login');
        }

    };

    useEffect(() => {

        if (ReduxGlobalData[0] === undefined){
            Cookies.set('token', '');
            localStorage.clear();            
            dispatch(emptyGlobalDataFun());
            
            router('/');
        }else{

            const CheckLoginFun = async() => {
                try{
                    response = await axios.get(`url_getlogindetail/${ReduxGlobalData[0].id}`,
                    { headers: { Authorization: `Bearer ${v_token}` } });

                    updatedata = await response.data.v_out_data;        
                    
                    // console.log("No of login >> ", updatedata.noflogin)

                    if (updatedata.noflogin === 0){
                        setShowChangePassword(true); 
                    }else{
                        getLoad();

                        if (ReduxGlobalData[0].br_name === ''){
                            setShowChangeBranchFinYear(true);
                            setConditionalCSS(true);
                        }
                    }
                }catch(error){            
            
                    // toast.error("Error in No of login NavBar ! " || error, {position:"top-center",});
                    // navigate('/Login');
                }
            }

            CheckLoginFun();
        }

        // console.log("In menu ReduxGlobalData[0] >> ", ReduxGlobalData[0]);
        
    }, [ReduxGlobalData[0]]);

    async function LogoutFun(){
        try{
            
            Cookies.set('token', '');
            localStorage.clear();            
            dispatch(emptyGlobalDataFun());

            setShowChangePassword(false);
            
            router('/');
            
        }catch(error){
            toast.error("Logout Error !" || error, {position:"top-center",});
        }
    }

    async function ChangeBranchFinYearFun(){
        setShowChangeBranchFinYear(true);
        setConditionalCSS(true);
    }
    
    const setModelFalseFunction = (p_data) => {
        
        setShowChangePassword(false);
        setShowChangeBranchFinYear(false);       
        setConditionalCSS(false);     
    }
    
    return (
    
    <>
    
    {
        ShowChangeBranchFinYear ? 
        <div className="centerWindow_css">
            <ChangeBranchFinYear setModelFalseFunction = {setModelFalseFunction}/>                               
        </div>
        : null
    }
    
    {
        ShowChangePassword ? <ChangePassword setModelFalseFunction = {setModelFalseFunction}/> : null
    }

    <div className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">
        {   
            ReduxGlobalData[0] === undefined ?             
            <div>

                <nav className="flex justify-between items-center top-0 fixed w-full nav_bar_css">

                <div>            
                    <h1 className="text-1xl font-bold"><Link href="#!">TeamsApp</Link></h1>
                </div>                    

                <div>
                    <ul className="flex space-x-4">
                        
                        <li><h1><Link to ="/" className=""></Link></h1></li>
                        <li><h1><Link to ="/Login" className="">Login</Link></h1></li>
                        
                        {/* <li><h1><Link to ="/SignUp" className="">SignUp</Link></h1></li> */}


                        
                    </ul>                
                </div>            

                </nav>       

                {/* <nav className="marq_css top-8 fixed w-full">

                <ShowMarqueeMainWebPage />

                </nav> */}

            </div>
            :
            <div>
                <nav className="flex justify-between items-center top-0 fixed w-full nav_bar_css">

                    <div>                
                        {menu_items.map((item, index) => {
                            return <MenuItem item={item} key={index} depth={1} />
                        })}
                    </div>                    
                    
                    {/* {ReduxGlobalData[0] === undefined ? null : <div className="flex space-x-4">{localStorage.getItem('br_name')}</div>}
                    {ReduxGlobalData[0] === undefined ? null : <div className="flex space-x-4">{localStorage.getItem('fy_name')}</div>} */}

                    {ReduxGlobalData[0] === undefined ? null : <div className="flex space-x-4">{ReduxGlobalData[0].br_name}</div>}
                    {ReduxGlobalData[0] === undefined ? null : <div className="flex space-x-4">{ReduxGlobalData[0].fy_name}</div>}

                    {
                        ShowChangePassword ? null 
                        :
                        <div>
                            <ul className="flex space-x-4">
                                <li><h1><button className="hover:text-red-800 hover:bg-white" onClick={ChangeBranchFinYearFun} >Change Branch</button></h1></li>                    
                            </ul>                
                        </div>
                    }

                    {ReduxGlobalData[0] === undefined ? null : <div className="flex space-x-4">Welcome-{ReduxGlobalData[0].user}</div>}

                    <div>
                        <ul className="flex space-x-4">
                            <li><h1><button className="hover:text-red-800 hover:bg-white" onClick={LogoutFun} >Logout</button></h1></li>                    
                        </ul>                
                    </div>            

                </nav>

                <div className="w-full mt-8"></div>
                
                {/* <div className="flex">
                    <div className="marq_css ml-0 w-[10%]"></div>                
                    <div className="marq_css ml-0 w-[90%]">                        
                        <ShowMarqueeBranchWise />
                    </div>                
                    <div className="marq_css ml-0 w-[10%]"></div>                
                </div> */}
               

                {/* <nav className="marq_css top-8 fixed w-full">
                    <ShowMarqueeBranchWise />
                </nav>

                <nav className="bg-Main-Marq-BG text-Main-Marq-Text bottom-0 fixed w-full">
                    <ShowShivMarquee />
                </nav> */}

                {/* <nav className="bg-Main-Marq-BG text-Main-Marq-Text bottom-0 fixed w-full">
                    <ShowShivMarquee />
                </nav> */}

            </div>
        }
    </div>            
    </>
    );
};

export default MainWebPageCustomNavbar;
