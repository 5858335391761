// Table name : ac_ledger

import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';

// import Layout from './Layout';

import moment from 'moment';

const TransportMaster = (props) => {
    const dispatch = useDispatch();
    const ReduxData = useSelector((state) => state);

    const [ShowModelFormDeleteUpdate, setModelFormDeleteUpdate] = useState(false);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);
    const [updateDelTag, setUpdateDelTag] = useState('');
    const [updateDelapiName, setUpdateDelapiName] = useState('');
    
    // const router = useRouter();

    const navigate = useNavigate();

    const [vech_no, setvech_no] = useState('');    
    const [notes, setnotes] = useState('');

    const [desig_noItems, setdesig_noItems] = useState([]);     
    
    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,        
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenPageRefreshMessage,        
    } = useContext(CRUDContext);

    // ////////////////////////////

    // const getAuthroziedUser = async () => {  
    //     try{
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //               { headers: { Authorization: `Bearer ${token}` } });            
    
    //         if (response.status === 401 || response.status === 403){
    //             localStorage.clear();
    //             navigate('/Login')
    //         };

    //     }catch(error){
    //         localStorage.clear();
    //         navigate('/Login')
    //     }
    // }    

    // useEffect( () => {        
    //     getAuthroziedUser();    
    // }, []);

    // ////////////////////////////

    useEffect(() => {
        if (error) {
            toast.error(error);
            clearErrors();
        }

        if (created) {
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
        }

        if (updated) {
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
        }
    }, [error, created, updated, deleted]); 
    
    useEffect(() => {  

        let response = []
        let updatedata = []

        // const getParamTableRecordFunctionBranch = async (var_link) => {                        

        //     response = await axios.get(`${var_link}/DESIG`,
        //         { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        //     updatedata = await response.data.v_out_data
        //     setdesig_noItems(updatedata);
        // }
        // getParamTableRecordFunctionBranch('url_param_view_drop_down');

    }, []);
    
    useEffect(() => {      
        let response = []
        let updatedata = []

        // to get the data when user click on view to show the existing record (from ug_view page)

        // console.log("pk_id 1: ", pk_id);

        if (pk_id > 0) {

            // console.log("pk_id 2 : ", pk_id);

            const getAllTableRecordFunction = async (var_link) => {
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/${pk_id}`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

                updatedata = await response.data.v_out_data;

                // console.log("updatedata is : ", updatedata)                

                setPkID(pk_id);               

                setvech_no(updatedata[0].vech_no);                           
                setnotes(updatedata[0].notes);
            }
            getAllTableRecordFunction('url_emp_master_view_id_wise_fun');
        }

    }, [pk_id])
    
    const newBlankFieldHandler = (e) => {
        e.preventDefault();

        setPkID(0);
        
        setvech_no('');        
        setnotes('');  
    }

    // const BranchWiseAssigmentHandler = (event) => {
    //     event.preventDefault();
    //     navigate('/EmployeeMasterBranchWise');
    // }

    const createRecordFunction = (event) => {
        event.preventDefault();

        handleInputTitleCase();

        let v_insupdel_type = '';

        // console.log("As on date is : ", as_on_date)
        // console.log("As on date length is : ", as_on_date.length)     
        
        // return

        if (vech_no.trim().length === 0){
            toast.info("Entry reuired for Vechicle No. !")
            clearErrors();
            return;
        }        

        if (localStorage.getItem('br') === '0' || localStorage.getItem('br').length === 0){
            toast.error("Please select branch to continiue !!!")
            clearErrors();
            return;
        }       
        

        if (pk_id === 0) {
            v_insupdel_type = 'INSERT'
        };

        if (pk_id > 0) {
            v_insupdel_type = 'UPDATE'
        };
      
        const data = {

            // p_v_id,                
            // req.body.ListData.insupdel_type,
            // req.body.ListData.under_comp_group_id,
            // req.body.ListData.under_comp_branch_id,		            
            // req.body.ListData.vech_no,                               
            // req.body.ListData.notes ,                 
            // req.body.ListData.active_status,               
            // req.body.last_access_ip_final ,
            // req.body.last_access_by_final  ,
            // req.body.last_updel_notes_final

            id: pk_id,

            insupdel_type:v_insupdel_type,

            under_comp_group_id : localStorage.getItem('ag'),
            under_comp_branch_id : localStorage.getItem('br'),            

            vech_no,            
            notes,
            active_status:1
        }
        
        dispatch(emptyCart());
        dispatch(addTempList(data));

        // console.log("pk_id value is : ", pk_id)

        setUpdateDelapiName('url_vechicle_master_new_proc')

        if (pk_id === 0) {            
            setModelModelFormYesNo(true);
        }
        else {            
            setUpdateDelTag('UPDATE')            
            // setUpdateDelapiName('url_emp_master_new_proc')
            setModelFormDeleteUpdate(true);
        }

    }

    const FindInTablePage = (event) => {
        event.preventDefault();
        navigate('/EmployeeMasterView')
    }

    // const handleDeleteUpdateFunction = (event) => {
    //     event.preventDefault();

    //     if (ReduxData.userData[0].id.length === 0){
    //         toast.info(whenPageRefreshMessage)
    //         clearErrors();
    //         return;
    //     }

    //     const data = {
    //         id: pk_id,
    //         last_access_ip : ipAddess,
    //         last_access_by : ReduxData.userData[0].id,    
    //     };

    //     setUpdateDelTag('DELETE')
    //     setUpdateDelapiName('usergroup_delete')

    //     dispatch(emptyCart());
    //     dispatch(addTempList(data));
    //     setModelFormDeleteUpdate(true);
    // }    

    const goPrePageFunction = (event) => {
        event.preventDefault();   
        setPkID(0);  
        navigate('/');
    }

    function handleEnter(event) {
        if (event.keyCode === 13) {
          const form = event.target.form;
          const index = Array.prototype.indexOf.call(form, event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
        }
    }

    function settitleCase(str) {
        return str.toLowerCase().split(' ').map(function(word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }

    function handleInputTitleCase() {
        setvech_no(settitleCase(vech_no));
        setnotes(settitleCase(notes));
    }    
    
return (
<>
    <form>
    <div className="pt-PaddingTopAfterMenu max-w-full text-my-main-heading-fontSize bg-my-main-heading-color text-my-main-heading-font-color text-left">Employee Master</div>

    <div className="pt-4 grid grid-cols-2 gap-2">

        <div>
            <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Vechicle No.</label>
            <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                name="vech_no"
                id="vech_no"
                type="text"
                // placeholder="Group Name"
                value={vech_no}
                onChange={(e) => setvech_no(e.target.value)}
                onBlur={handleInputTitleCase}
                onKeyDown={handleEnter} {...props}
            ></input>
        </div>        

        <div>
            <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Notes</label>
            <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                name="notes"
                id="notes"
                type="text"
                // placeholder="Notes"
                value={notes}
                onChange={(e) => setnotes(e.target.value)}
                onBlur={handleInputTitleCase}
                onKeyDown={handleEnter} {...props}
            ></input>
        </div>                  
    </div>

    <div className="pt-4 py-my-padding-py px-my-padding-px grid grid-cols-4 gap-4">

        {/* <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
            onClick={BranchWiseAssigmentHandler}>
            Branch Wise Assignment
        </button> */}
        
        <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
            onClick={newBlankFieldHandler}>
            New
        </button>

        <button className="bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-my-fontSize text-SaveButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
            onClick={createRecordFunction}>
            Save
        </button>

        {/* <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
            onClick={handleDeleteUpdateFunction}>
            Delete
        </button> */}

        <button className="bg-FindButtonBGColor hover:bg-FindButtonHoverColor text-my-fontSize text-FindButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
            onClick={FindInTablePage}
        >
            Find
        </button>

        <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
            onClick={goPrePageFunction}>
            Back
        </button>

    </div>
    
    {/* <ModelFormDeleteUpdate api_type={updateDelTag} api_name={updateDelapiName} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)}/> */}

    <ModelFormDeleteUpdate api_type={updateDelTag} api_name={updateDelapiName} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)}/>

    <ModelFormYesNo table_name='STANDARD' api_name={updateDelapiName} isVisible={ShowModelFormYesNo} onClose={() => setModelModelFormYesNo(false)}/>
                
    </form>
</>
);
};

export default TransportMaster;
