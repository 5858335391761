import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { ValidateInput, capitalizeWordFun, GetFormAuthDetails } from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import CRUDContext from '../components/CRUDContext';
import DropDownTableComponent from "../components/DropDownTableComponent";

import { emptyGlobalDataFun } from '../components/redux/actions';

import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import Cookies from 'js-cookie';

const ExamReportCardMasterBW = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData);

    let v_input_result, v_active_status = 1, v_null_parameter = 'NA';

    // define variable for tables entry (master detail form)    

    const [tabID, setTabID] = useState(1);
    const [items, setItems] = useState([]);
    
    const [param_st_fee_coll_group_idItems, setparam_st_fee_coll_group_idItems] = useState([]);
    const [fee_codeItems, setfee_codeItems] = useState([]);
    const [fee_monthItems, setfee_monthItems] = useState([]);    

    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0);
    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);

    const [fin_year, setfin_year] = useState(0);
    const [fin_yearItems, setfin_yearItems] = useState([]);

    const [copy_under_comp_branch_id, copy_setunder_comp_branch_id] = useState(0);
    const [copy_fin_year, copy_setfin_year] = useState(0);

    const [IDArray, setIDArray] = useState([]);
    const [IDArrayValue, setIDArrayValue] = useState([]);   

    // define form name and navigation details

    const [v_urlSaveUpdateDelete, setv_urlSaveUpdateDelete] = useState('');

    const v_backToNavigateName = '/brms';
    // const v_findToNavigateName = '/';
    const v_main_form_heading = 'Student Transfer Master (Branch & Financial Year Wise)';
    const v_frm_no = 3;

    const {
        deletedMessage,
        createdMessage,
        updatedMessage,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if (result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR') {

                if (result.res_status === 'UN_AUTHROISED') { v_toast_msg = v_access_denied_msg; }
                if (result.res_status === 'ERROR') { v_toast_msg = v_session_expire_msg; }

                toast.info(v_toast_msg, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                navigate(`${v_backToNavigateName}`);
                return;
            } else {

                // if load allow then execute the below code

                setv_open_frm(true);

                // console.log("Update allow_update >> ", result.allow_update)

                setBlankFields();

                const v_token = Cookies.get('token');

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_user_wise_branch_view_drop_down/38/0/0/${ReduxGlobalData[0].id}`,
                    { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setunder_comp_branchItems(updatedata);

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_comp_group_branch_wise_drop_down/38/${ReduxGlobalData[0].ag}/0/FY`,
                    { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setfin_yearItems(updatedata);
            }
        }
        loadData();
    }, []);

    useEffect(() => {
        setBlankFields();
    }, [ReduxGlobalData[0]]);

    const handleCopyToRecordOption = () => {

        if (under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0) {
            toast.info('Entry required for Under branch', {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        if (fin_year === undefined || fin_year.length === undefined || fin_year.length === 0) {
            toast.info('Entry required for Financial year', {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }       

        if (copy_under_comp_branch_id === undefined || copy_under_comp_branch_id.length === undefined || copy_under_comp_branch_id.length === 0) {
            toast.info('Entry required for Copy Under branch', {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        if (copy_fin_year === undefined || copy_fin_year.length === undefined || copy_fin_year.length === 0) {
            toast.info('Entry required for Copy Financial year', {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        if (under_comp_branch_id + fin_year === copy_under_comp_branch_id + copy_fin_year) {
            toast.info('Copy From and To are same', {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        const data = {
            insupdel_type: 'INSERT',
            copy_from_under_comp_branch_id: under_comp_branch_id,
            copy_to_under_comp_branch_id: copy_under_comp_branch_id,
            copy_from_fin_year: fin_year,
            copy_to_fin_year: copy_fin_year,           
        };

        let insupdel_type = 'INSERT';

        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        if (insupdel_type === 'INSERT') {
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }

        setv_urlSaveUpdateDelete('3_copy_to_url_student_transfer_master');
    }

    const FindSavedTableDetail = async (p_TableRecordID) => {

        if (under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0) {
            toast.info('Entry required for Under branch', {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        if (fin_year === undefined || fin_year.length === undefined || fin_year.length === 0) {
            toast.info('Entry required for Financial year', {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }       

        const v_token = Cookies.get('token');

        response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/MONTH`,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;
        setfee_monthItems(updatedata);
        
        response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/FEE_CODE`,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;
        setfee_codeItems(updatedata);

        response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_st_fee_coll_group_id_fun/38/${under_comp_branch_id}/0/'NA`,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data
        setparam_st_fee_coll_group_idItems(updatedata);

        // show the detail part

        // url_examcard_branch_wise_view_fun

        response = await axios.get(`url_fetch_data/3_url_student_transfer_master_view_fun/${v_frm_no}/${ReduxGlobalData[0].ag}/0/0/${v_active_status}
        /${under_comp_branch_id}
        /${fin_year}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
            { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;
        setItems(await updatedata);   
    }

    function setBlankFields() {

        // shiv

        setTableRecordID(0);
        setIDArray([]);
        setItems([]);
        
    }
    
    const handleInputValueFun = async (name, value) => {

        const v_token = Cookies.get('token');

        if (name === 'under_comp_branch_id') {

            setunder_comp_branch_id(value);

            setItems([]);
            return;
        } else if (name === 'fin_year') {
            setfin_year(value);
            setItems([]);
        }else if (name === 'copy_under_comp_branch_id') {
            copy_setunder_comp_branch_id(value);
            setItems([]);
            return;
        } else if (name === 'copy_fin_year') {
            copy_setfin_year(value);
            setItems([]);
        }

    }

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        let insupdel_type = 'INSERT';

        if (value != 'DELETE') {
            if (under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0) {
                toast.info('Entry required for Under branch', {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                return;
            }

            if (fin_year === undefined || fin_year.length === undefined || fin_year.length === 0) {
                toast.info('Entry required for Financial year', {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                return;
            }                  

            if (items.length === 0) {
                toast.info('Record not found to be saved', {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                return;
            }
        }

        const data = {
            id: 0,
            insupdel_type: 'INSERT',
            under_comp_branch_id,
            fin_year,                        
            items: JSON.stringify(items)
        };

        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);
        setv_urlSaveUpdateDelete('3_url_student_transfer_master_new_proc');

        if (insupdel_type === 'INSERT') {
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        } else {
            setShowConfirmModelSave(false);

            if (userAuthData.allow_update === 1) {
                setConfirmModelUpdateDelete(true);
            }

            if (userAuthData.allow_delete === 1) {
                setConfirmModelUpdateDelete(true);
            }
        }
    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {

        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);

        if (p_data.ModelYesNoAnswer === 'YES') {
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async (p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final: p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final: p_last_updel_notes_final,
        }

        const v_token = Cookies.get('token');

        // response = await axios.post(`${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        // { headers: { Authorization: `Bearer ${v_token}` } });

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData,
            { headers: { Authorization: `Bearer ${v_token}` } });

        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }

    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if (updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405') {

            if (updatedata.v_data[0].res_status === 'UN_AUTHROISED') { v_toast_msg = v_access_denied_msg; }
            if (updatedata.v_data[0].res_status === 'ERROR') { v_toast_msg = v_session_expire_msg; }

            toast.info(v_toast_msg, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ') {

            // check if read allow then

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                return false;
            }

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            // setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);

            // console.log("updatedata.TabID.v_data >> ", updatedata.v_data);

            if (updatedata.transType === 'INSERT') {
                v_toast_msg = createdMessage;

                setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);

                getSavedDetailFunction(0);
            } else if (updatedata.transType === 'UPDATE') {
                v_toast_msg = updatedMessage;

                getSavedDetailFunction(0);
            } else if (updatedata.transType === 'DELETE') {
                v_toast_msg = deletedMessage;
                setBlankFields();
            }

            toast.success(v_toast_msg, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
        }
    }

    const setNewRecordIDFunction = async () => {

        // console.log("setNewRecordIDFunction >> ", p_v_data[0].p_id, p_v_data[0].p_vou_no);

        // setTableRecordID(await p_v_data[0].p_id);        
    }

    const getSavedDetailFunction = async (p_v_data) => {
        FindSavedTableDetail(p_v_data);
    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

    };

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        handleInputValueFun(name, value);
    }

    const handleButtonValue = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        if (value === 'ADD_NEW_ROW'){

            if (under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0) {
                toast.info('Entry required for Under branch', {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                return;
            }

            if (fin_year === undefined || fin_year.length === undefined || fin_year.length === 0) {
                toast.info('Entry required for Financial year', {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                return;
            }       

            addItem();
            return;
        }else if (value === 'BTN_FIND') {
            FindSavedTableDetail(0);            
            return;
        } else if (value === 'BTN_COPY') {
            handleCopyToRecordOption();
            return;
        } else if (value === 'BTN_BACK') {
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    const goCheckDataFunction = (event) => {
        event.preventDefault();

        // console.log("TableRecordID >> ", TableRecordID);

        console.log("items >> ", items);

    }

    // For details entry (entry in table)
    // For details entry (entry in table)

    const addItem = () => {
        setTabID(tabID + 1);
        setItems([...items, {ischecked:false, t1_id: tabID * -1, with_fine:false, param_st_fee_coll_group_id:0, to_next_session:0, to_fee_code:0, to_fee_month:'', notes:''}]);               
    };

    const onClickTableRowFun = (event, p_id, p_value) => {
        event.preventDefault();

        // console.log("When Click on SHOW_RECORD >> ", saveUpdateTag);        

        if (p_value === 'ADD_NEW_ROW') {           

            setTabID(tabID + 1);
            
            let v_data = {ischecked:false, t1_id: tabID * -1, with_fine:false, param_st_fee_coll_group_id:0, to_next_session:0, to_fee_code:0, to_fee_month:'', notes:''};

            items.splice(p_id + 1, 0, v_data);

        }else{
            ischeckedTick(p_id);
        }

    };

    const ischeckedTick = (p_id) => {

        let v_check_value = true;

        // console.log("In Table Row Click : ", p_id);

        // console.log("In Row Tick IDArray Before >> ", IDArray);

        // const filteredData = items.filter((item) => item.id.includes(p_id));

        const filteredData = items.find(item => {return item.id === p_id});        

        // console.log("In Table Row Click filteredData : ", filteredData.ischecked);

        if (filteredData.ischecked) {
            v_check_value = false;
            const index = IDArray.indexOf(p_id);
            IDArray.splice(index, 1);
            IDArrayValue.splice(index, 1);
        } else {
            v_check_value = true;
            IDArray.push(filteredData.id);
            // IDArrayValue.push(filteredData.st_class);
        }

        for (let i = 0; i < items.length; i++) {
            const checkedvalue = items.map((user) => user.id.toString() === p_id.toString() ? { ...user, ischecked: v_check_value } : user);
            setItems(checkedvalue);
        }

        SelectedValueConvertToStr(IDArray, IDArrayValue);

    }

    const SelectedValueConvertToStr = (p_tempIDList, p_tempValueList) => {

        // console.log("In Convert >> ", p_tempList.length, ' >> ', p_tempList);

        let tempIDArrayStr = '', tempIDArrayValueStr = '';

        for (let i = 0; i < p_tempIDList.length; i++) {
            if (i === 0) {
                tempIDArrayStr = p_tempIDList[i];
                tempIDArrayValueStr = p_tempValueList[i];
            } else {
                tempIDArrayStr = tempIDArrayStr + "," + p_tempIDList[i];
                tempIDArrayValueStr = tempIDArrayValueStr + "," + p_tempValueList[i];
            }
        }

        setIDArray(p_tempIDList);
        setIDArrayValue(p_tempValueList);
    }

    const handlerChangeTableRowFocus = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;
    };

    const handlerChange = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;

        let v_value = value;
        const list = [...items];

        if (name === 'notes') {
            v_value = capitalizeWordFun(v_value);
        }

        list[i][name] = v_value;
        setItems(list);
    };

    const setShowPopUpFalseFunction = (event) => {
        // setShowPopUp(false);
    };

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;          

        let tempListID = [];        

        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, ischecked: checked } });
            setItems(checkedvalue);
        }
        else {
            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, ischecked: checked } : user);
            setItems(checkedvalue);
        }        
    }

    const handleInputBlur = (event, i, p_col_name) => {
        // event.preventDefault();
        // const { name, value } = event.target;

        // console.log("handleInputBlur >> ", value);

        // // setSelectedrow(i);
        // // setSelectedColumn(p_col_name);

        // if (p_col_name === 'FEE_DETAIL_FEE_MONTH_DESCR' || p_col_name === 'FEE_DETAIL_FEE_CODE_DESCR'){



        //     setShowPopUp(true);
        // }else{

        //     setShowPopUp(false);
        // }

        // console.log('Input field lost focus');

    };   

return (

<>
{
    ShowConfirmModelSave ?
        <div className="centerWindow_css">
            <ConfirmModelSave transType={TransType} setModelSaveUpdateDeleteFalseFunction={setModelSaveUpdateDeleteFalseFunction} />
        </div>
        : null
}

{
    ShowConfirmModelUpdateDelete ?
        <div className="centerWindow_css w-[32%]">
            <ConfirmModelUpdateDelete transType={TransType} setModelSaveUpdateDeleteFalseFunction={setModelSaveUpdateDeleteFalseFunction} />
        </div>
        : null
}

{
v_open_frm ?

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} onMouseOut={(e) => setShowPopUpFalseFunction(e)} autoComplete="off">

<div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

<div className="form_div_main_css h-[10vh]">
    <div className="form_div_inner_css grid grid-cols-8">

        <div className="col-span-2">
            <label className="label_inner_page_css required_css">Under Branch</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="under_comp_branch_id"
                id="under_comp_branch_id"
                value={under_comp_branch_id}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    under_comp_branchItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css required_css">Fin.Year</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="fin_year"
                id="fin_year"
                value={fin_year}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    fin_yearItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>    

        <div className="col-span-1">
            <label className="label_inner_page_css text-white">.</label>
            <button className="btn_back_css w-[80%]"
                value='BTN_FIND'
                onClick={handleButtonValue}>
                Show
            </button>
        </div>

        <div className="col-span-2">
            <label className="label_inner_page_css required_css">Copy to Branch</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="copy_under_comp_branch_id"
                id="copy_under_comp_branch_id"
                value={copy_under_comp_branch_id}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    under_comp_branchItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css required_css">To Fin./Year</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="copy_fin_year"
                id="copy_fin_year"
                value={copy_fin_year}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    fin_yearItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css text-white">.</label>
            <button className="btn_back_css w-[80%]"
                value='BTN_COPY'
                onClick={handleButtonValue}>
                Copy
            </button>
        </div>

    </div>
</div>

<table className="table_main_css">
<thead className="table_thead_css">
    <tr className="table_thead_tr_css">

        <th className="table_th_td_css w-[3%] text-left"></th>   

        {/* fee_group, to_next_session, to_fee_code, to_fee_month, notes,       */}

        {/* with_fine:false,  */}

        <th className="table_th_td_css w-[8%]">
            With-Fine
            <input className="table_check_inner_page_css"
                type="checkbox" name="allselect" checked={!items.some((user) => user?.ischecked !== true)}
                onChange={handleChangeCheckBox}
                onFocus={handleInputFocus}                        
            />           
        </th>

        <th className="table_th_td_css w-[20%] text-left">Fee-Group</th>        
        <th className="table_th_td_css w-[13%] text-left">To Next Session</th>
        <th className="table_th_td_css w-[25%] text-left">To Fee Code</th>
        <th className="table_th_td_css w-[18%] text-left">To Fee Month</th>
        <th className="table_th_td_css w-[10%] text-left">Notes</th>

        <th className="table_th_td_css w-[3%] text-left"></th>

    </tr>
</thead>

<tbody className="table_tbody_css h-[45vh]">
{
items?.map((item, index) => (        

    <tr className="table_thead_tr_css table_tbody_hover_css" key={item.t1_id}>

        {
            (item.t1_id < 0) ?
            <td className="table_th_td_css text-center w-[3%]" onClick={() => { setItems(items.filter(a => a.t1_id !== item.t1_id)); }} onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                <FontAwesomeIcon className="text-blue-600 text-1xl" icon={faTrash}/>
            </td>                            
            :  
            <td className="table_th_td_css text-center w-[3%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}></td>
        }

        <td className="table_th_td_css w-[8%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <input className="table_check_inner_page_css"
                type="checkbox" name={item.t1_id}
                checked={item?.ischecked || false}
                onChange={handleChangeCheckBox}                                                                
            />
        </td>

        <td className="table_input_th_td_css w-[4%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <div className="table_input_inner_page_css">{item.param_st_fee_coll_group_id}</div>
        </td>              

        <td className="table_input_th_td_css text-center w-[16%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <DropDownTableComponent 
                ref={(ref) => addInputRef(ref, 14)}
                paramItems={param_st_fee_coll_group_idItems} columnName={'param_st_fee_coll_group_id'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.param_st_fee_coll_group_id}                     
            />         
        </td>        

        <td className="table_input_th_td_css w-[4%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <div className="table_input_inner_page_css">{item.to_next_session}</div>
        </td>

        <td className="table_input_th_td_css text-center w-[9%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <DropDownTableComponent 
                ref={(ref) => addInputRef(ref, 14)}
                paramItems={fin_yearItems} columnName={'to_next_session'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.to_next_session}                     
            />         
        </td>  

        <td className="table_input_th_td_css w-[4%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <div className="table_input_inner_page_css">{item.to_fee_code}</div>
        </td>

        <td className="table_input_th_td_css text-center w-[21%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <DropDownTableComponent 
                ref={(ref) => addInputRef(ref, 14)}
                paramItems={fee_codeItems} columnName={'to_fee_code'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.to_fee_code}                     
            />         
        </td>

        <td className="table_input_th_td_css w-[4%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <div className="table_input_inner_page_css">{item.to_fee_month}</div>
        </td>

        <td className="table_input_th_td_css text-center w-[14%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <DropDownTableComponent 
                ref={(ref) => addInputRef(ref, 14)}
                paramItems={fee_monthItems} columnName={'to_fee_month'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.to_fee_month}                     
            />         
        </td>        

        <td className="table_input_th_td_css text-left w-[10%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <input className="table_input_inner_page_css text-left"
                type="text"
                ref={(ref) => addInputRef(ref, 15)}
                name="notes"
                id="notes"
                value={item.notes}
                onChange={(e) => handlerChange(e, index)}
            ></input>
        </td>

        <td className="table_th_td_css text-center w-[3%]" onClick={(e) => onClickTableRowFun(e, index, 'ADD_NEW_ROW', item.isChecked)} onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <FontAwesomeIcon className="text-blue-600 text-2xl align-middle" icon={faPlus}/>                            
        </td>
        
    </tr>
))
}
</tbody>
</table>

<nav className="btn_nav_table_sum_inner_css">
<div className="btn_div_main_css">

    <div className="btn_div_inner_css">

        <div>        
            <button className="btn_new_css"
                value = 'ADD_NEW_ROW'
                onClick={handleButtonValue}>
                New Row
            </button>    
        </div>

        <div>
            <button className="btn_save_css"
                onClick={createRecordFunction}>
                Save
            </button>
        </div>

        <div>
            <button className="btn_back_css"
                value='BTN_BACK'
                onClick={handleButtonValue}>
                Back
            </button>
        </div>

        {/* <div>                
        <button className="btn_back_css"
        onClick={goCheckDataFunction}>
        Check Data
        </button>
        </div> */}

    </div>

</div>
</nav>

</form>
: null
}

</>
);
};

export default ExamReportCardMasterBW;
