import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const SelectRecordPopUpSingleColumn = (props) => {
    const inputRefs = useRef([]);

    const [IDArray, setIDArray] = useState([]);
    const [IDArrayValue, setIDArrayValue] = useState([]);    

    const [items, setItems] = useState([]);
    const [origionalData, setOrigionalData] = useState([]);    

    const [querySelDesc, setQuerySelDesc] = useState('');    

    useEffect(() => {
        // set the check box tick
        
        const newItems = props.items.map(obj => {
            if (props.PropsIDArray.find((element) => element === obj.id) != undefined) {
                return { ...obj, ischecked: true };
            }
            return obj;
        });

        setItems(newItems);
        setOrigionalData(props.items);        
        setIDArray(props.PropsIDArray);
        setIDArrayValue(props.PropsIDArrayValueStr);
        
    }, []);

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        let v_col_length = 0, searchdata = [];
       
        if (name === 'querySelDesc') {
            v_col_length = value.toString().trim().length;
            searchdata = origionalData.filter((item) => item.sel_desc.toLowerCase().includes(value.toLowerCase()));                     
            setQuerySelDesc(value);
        }

        if (v_col_length > 0){
            SetPaginationItems(searchdata); 
        }else{
            SetPaginationItems(origionalData);                      
        }
    }

    const SetPaginationItems = (p_items) => {
        setItems(p_items);     
        ResetCheckBoxFunction(p_items);        
    }
       
    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        let tempListID = [], tempListValue = [];

        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, ischecked: checked } });
            setItems(checkedvalue);

            setIDArray([]);
            setIDArrayValue([]);            

            if (checked) {
                for (let i = 0; i < items.length; i++) {
                    tempListID.push(items[i].id);
                    tempListValue.push(items[i].sel_desc);
                }
            }
        }
        else {
            const checkedvalue = items.map((user) => user.id.toString() === name ? { ...user, ischecked: checked } : user);
            setItems(checkedvalue);

            tempListID = Object.assign(IDArray);
            tempListValue = Object.assign(IDArrayValue);

            const filteredData = items.filter((item) => item.id.includes(name));

            // console.log("In Table Row Click filteredData : ", filteredData);

            if (filteredData[0]["ischecked"]) {
                const index = tempListID.indexOf(name);
                tempListID.splice(index, 1);
                tempListValue.splice(index, 1);
            } else {
                tempListID.push(filteredData[0]["id"]);
                tempListValue.push(filteredData[0]["sel_desc"]);
            }
        }        
    }

    const onClickTableRowFun = (event, p_id) => {
        event.preventDefault();        
        ischeckedTick(p_id);
    };

    const ischeckedTick = (p_id) => {

        let v_check_value = true;

        const filteredData = items.filter((item) => item.id.includes(p_id));

        if (filteredData[0]["ischecked"]) {
            v_check_value = false;
            const index = IDArray.indexOf(p_id);
            IDArray.splice(index, 1);
            IDArrayValue.splice(index, 1);
        } else {
            v_check_value = true;
            IDArray.push(filteredData[0]["id"]);
            IDArrayValue.push(filteredData[0]["sel_desc"]);
        }

        for (let i = 0; i < items.length; i++) {
            const checkedvalue = items.map((user) => user.id.toString() === p_id.toString() ? { ...user, ischecked: v_check_value } : user);
            setItems(checkedvalue);
        }     
    }

    const addInputRef = (ref, index) => {
        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };   

    const ResetCheckBoxFunction = (p_sortData) => {

        // console.warn("ResetCheckBoxFunction >> ", IDArray)

        const newItems = p_sortData.map(obj => {

            // if (obj.id === '490') {
            //     return {...obj, ischecked: true};
            // }            

            if (IDArray.find((element) => element === obj.id) != undefined) {
                return { ...obj, ischecked: true };
            }
            return obj;
        });

        setItems(newItems);

        // console.log(newState);
    }     
    
    const sendDataToParentFun = (e) => {
        e.preventDefault();

        SelectedDataFunction();
    }

    const CheckValue = (event) => {
        event.preventDefault();

        // SelectedDataFunction();

        console.log("When lost focus");

        // inputRefs.current[4].focus();

        // console.warn("IDArray >> ", IDArray);
        // console.warn("IDArrayValue >> ", IDArrayValue);        

        // ResetCheckBoxFunction(items);
    }

    const SelectedDataFunction = () => {
        
        const data = {SelectedIDArray:IDArray, SelectedIDArrayValue : IDArrayValue, selectedColumnName:props.columnName};

        props.setPopUpDDFalseFunction(data);
    }
    
return (

<form className='border-none h-[100%]' autoComplete="off" >         

        <table className="table_main_css h-[100%]">            
                            
            <thead className="table_thead_css">
                <tr className="table_thead_tr_css">

                    {/* <th className="table_th_td_css w-[10%]"></th> */}

                    <th className="table_th_td_css w-[14%]">
                        <input className="table_check_inner_page_css"
                            type="checkbox" name="allselect" checked={!items.some((user) => user?.ischecked !== true)}
                            onChange={handleChangeCheckBox}
                            // onFocus={handleInputFocus}
                        />
                    </th>

                    <th className="table_th_td_css w-[80%]">

                        <input className="input_inner_page_css"
                            type="text"
                            ref={(ref) => addInputRef(ref, 0)}
                            autoFocus
                            name="querySelDesc"
                            id="querySelDesc"
                            value={querySelDesc}
                            // placeholder="Adm.No."                    
                            onChange={handleInputValue}
                            // onKeyDown={handleEnter}
                            // onFocus={handleInputFocus}
                            // onBlur={handleInputBlur}
                        ></input>
                    </th> 

                    {/* <th className="table_th_td_css w-[10%]"><FontAwesomeIcon icon={faCheck} size="2xl" onClick={(e) => props.fun1(IDArrayStr, IDArrayValueStr)} /></th> */}

                    <th className="table_th_td_css w-[17%]"><FontAwesomeIcon icon={faCheck} size="2xl" onClick={sendDataToParentFun} /></th>  

                </tr>
            </thead>

            <tbody className="table_tbody_css bg-yellow-100 h-[100%]">
                {
                    items?.map((item, index) => (                            
                        <tr className="table_thead_tr_css table_tbody_hover_css" key={item.id} >

                            {/* <td className="table_th_td_css text-left w-[10%]" onClick={(e) => onClickTableRowFun(e, item.id, item.isChecked)}>
                                {index + 1}                                    
                            </td> */}

                            <td className="table_th_td_css w-[14%]">
                                <input className="table_check_inner_page_css"
                                    ref={(ref) => addInputRef(ref, index + 1)}
                                    type="checkbox" name={item.id}
                                    checked={item?.ischecked || false}
                                    onChange={handleChangeCheckBox}
                                    // onFocus={handleFocus}                            
                                />
                            </td>

                            <td className="table_th_td_css text-left w-[86%]" onClick={(e) => onClickTableRowFun(e, item.id, item.isChecked)}>{item.sel_desc}</td>
                            
                        </tr>
                    ))
                }
            </tbody>
        </table>                   

</form>
);
};

export default SelectRecordPopUpSingleColumn;
