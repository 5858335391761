import React, { useRef } from "react";

import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { useSelector } from 'react-redux';

import ExamReportCardPrintPreview from "./ExamReportCardPrintPreview";

const ExamReportCardPrint = () => {  
    const conponentPDF = useRef();
    const navigate = useNavigate();

    const ReduxTempData = useSelector((state) => state.TempData);

    const PrintPDF = useReactToPrint({
      
        content: () => conponentPDF.current,
        documentTitle: "",
        // pageStyle : "35px"
        pageStyle : "@page { size: 8.27in 11.69in }"        
        // pageStyle : "1080px"

        // pageStyle: `@media print {
        //     @page {
        //       size: 100mm 100mm;
        //       margin: 0;
        //     }
        //   }`,



        
    });

// const ExamReportCardPrint = () => {  
//     const conponentPDF = useRef();
//     const navigate = useNavigate();

//     const ReduxTempData = useSelector((state) => state.TempData);

//     const PrintPDF = useReactToPrint({
//         // content: () => conponentPDF.current,
//         // documentTitle: "Userdata",
//         // onAfterPrint: () => alert("Data saved in PDF")

//         content: () => conponentPDF.current,
//         documentTitle: "",
//         // pageStyle : "35px"
//         // pageStyle : "@page { size: 2.5in 4in }"        
//         // pageStyle : "1080px"

//         pageStyle : "size: A4 landscape"

//         // pageStyle : "size: A4 portrait" 
        
//         // ///////////////////////////////////////////////////////////////////////////////////////////////////////
         
//         // content: () => conponentPDF.current,
//         // documentTitle: "Daily Fee Collecion",        
//         // pageStyle: "size: A4 landscape"
        
//     });

    const goPrePageFunction = (event) => {
        event.preventDefault();        
        const v_form_name = localStorage.getItem('fm');
        // navigate(`/${v_form_name}`);                       

        navigate("/ExamReportCardPrintView");
    }
    
return (
<>        
<div className="btn_div_inner_css">

    <div>               
        <button className="btn_save_css"                    
            onClick={PrintPDF}>
            Print
        </button>                
    </div>                                          

    <div>
        <button className="btn_back_css"
            value = 'BTN_BACK'
            onClick={goPrePageFunction}>
            Back
        </button>
    </div>

</div>        

<div ref={conponentPDF}>
{
    ReduxTempData[0]?.map((item) => (
        <>
            <ExamReportCardPrintPreview st_id = {item}/>
        </>
    ))
}
</div>     
</>
);};

export default ExamReportCardPrint;
