export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const USER_LIST = "USER_LIST";
export const SET_USER_DATA_SAGA = "SET_USER_DATA_SAGA";

export const SET_GLOBAL_DATA = "SET_GLOBAL_DATA";
export const EMPTY_GLOBAL_DATA = "EMPTY_GLOBAL_DATA";

// to store any temporary value

export const SET_TEMP_DATA = "SET_TEMP_DATA";
export const EMPTY_TEMP_DATA = "EMPTY_TEMP_DATA";

// to store school board like U.P. Board, C.B.S.E

export const SET_DD_BRANCH_BOARD = "SET_DD_BRANCH_BOARD";
export const EMPTY_DD_BRANCH_BOARD = "EMPTY_DD_BRANCH_BOARD";

export const SET_DD_MONTH = "SET_DD_MONTH";
export const EMPTY_DD_MONTH = "EMPTY_DD_MONTH";

export const SET_DD_STUDENT_TYPE = "SET_DD_STUDENT_TYPE";
export const EMPTY_DD_STUDENT_TYPE = "EMPTY_DD_STUDENT_TYPE";

export const SET_DD_MEDIUM = "SET_DD_MEDIUM";
export const EMPTY_DD_MEDIUM = "EMPTY_DD_MEDIUM";

export const SET_DD_ST_STREAM = "SET_DD_ST_STREAM";
export const EMPTY_DD_ST_STREAM = "EMPTY_DD_ST_STREAM";

export const SET_DD_FEE_CATEG = "SET_DD_FEE_CATEG";
export const EMPTY_DD_FEE_CATEG = "EMPTY_DD_FEE_CATEG";



export const SET_DD_REL_TYPE = "SET_DD_REL_TYPE";
export const EMPTY_DD_REL_TYPE  = "EMPTY_DD_REL_TYPE";

export const SET_DD_JOB_NATURE = "SET_DD_JOB_NATURE";
export const EMPTY_DD_JOB_NATURE  = "EMPTY_DD_JOB_NATURE";

export const SET_DD_OCCUP = "SET_DD_OCCUP";
export const EMPTY_DD_OCCUP  = "EMPTY_DD_OCCUP";

export const SET_DD_APP_FOR = "SET_DD_APP_FOR";
export const EMPTY_DD_APP_FOR  = "EMPTY_DD_APP_FOR";

export const SET_DD_ST_SEC = "SET_DD_ST_SEC";
export const EMPTY_DD_ST_SEC  = "EMPTY_DD_ST_SEC";

export const SET_DD_PRE_SCH_CL = "SET_DD_PRE_SCH_CL";
export const EMPTY_DD_PRE_SCH_CL  = "EMPTY_DD_PRE_SCH_CL";

export const SET_DD_PRE_SCH = "SET_DD_PRE_SCH";
export const EMPTY_DD_PRE_SCH  = "EMPTY_DD_PRE_SCH";

export const SET_DD_CASTE = "SET_DD_CASTE";
export const EMPTY_DD_CASTE  = "EMPTY_DD_CASTE";

export const SET_DD_CAST_CATEG= "SET_DD_CAST_CATEG";
export const EMPTY_DD_CAST_CATEG = "EMPTY_DD_CAST_CATEG";

export const SET_DD_RELIGION= "SET_DD_RELIGION";
export const EMPTY_DD_RELIGION = "EMPTY_DD_RELIGION";

export const SET_DD_GENDER= "SET_DD_GENDER";
export const EMPTY_DD_GENDER = "EMPTY_DD_GENDER";

export const SET_DD_ST_CLASS= "SET_DD_ST_CLASS";
export const EMPTY_DD_ST_CLASS = "EMPTY_DD_ST_CLASS";

export const SET_DD_FEE_PAY_TERM = "SET_DD_FEE_PAY_TERM";
export const EMPTY_DD_FEE_PAY_TERM = "EMPTY_DD_FEE_PAY_TERM";

export const SET_DD_TABLE_PER_PAGE = "SET_DD_TABLE_PER_PAGE";
export const EMPTY_DD_TABLE_PER_PAGE = "EMPTY_DD_TABLE_PER_PAGE";

export const SET_DD_FEE_CODE = "SET_DD_FEE_CODE";
export const EMPTY_DD_FEE_CODE = "EMPTY_DD_FEE_CODE";

// export const SET_REPORT_HEADING = "SET_REPORT_HEADING";
// export const EMPTY_REPORT_HEADING = "EMPTY_REPORT_HEADING";

export const SET_DD_SEQ_WISE_MONTH = "SET_DD_SEQ_WISE_MONTH";
export const EMPTY_DD_SEQ_WISE_MONTH = "EMPTY_DD_SEQ_WISE_MONTH";

export const SET_DD_FEE_GROUP_TYPE = "SET_DD_FEE_GROUP_TYPE";
export const EMPTY_DD_FEE_GROUP_TYPE = "EMPTY_DD_FEE_GROUP_TYPE";



// Below is the from old code

export const EMPTY_CART = 'EMPTY_CART'

export const PRODUCT_LIST = 'PRODUCT_LIST'
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST'
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT'

export const TEMP_LIST = 'TEMP_LIST'
export const TEMP_PATH = 'TEMP_PATH'

export const USER_DATA = 'USER_DATA'
export const EMPTY_USER_DATA = 'EMPTY_USER_DATA'