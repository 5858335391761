// Table name : print preview functionality when user will press print button at the time of fee collection

import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { CSVLink} from 'react-csv';

import CRUDContext from '../components/CRUDContext';

// import Layout from '../components/Layout';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

import { useReactToPrint } from "react-to-print";

// import { isAuthenticatedUser } from './isAuthenticated';

import { addTempList, emptyCart } from '../components/redux/actions';

const DynamicTablePivot = ({ isVisible, onClose, form_name}) => {  

    // const router = useRouter();

    const navigate = useNavigate();

    const conponentPDF = useRef();
    const dispatch = useDispatch();
    const ReduxData = useSelector((state) => state);    
    
    const [items, setItems] = useState([]);
    
    const [column, setcolumn] = useState([]);

    const [from_month, setfrom_month] = useState('')
    const [to_month, setto_month] = useState('')   

    // useEffect(() => {
    //     const registerUserdata = async () => {
    //         let updatedata = [];

    //         const response = await axios.get(`url_temp_issue_fields/${from_month}/${to_month}`,
    //              { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    //         updatedata = await response.data.v_out_data;                        
    //         setItems(updatedata);

    //         // console.log(updatedata)

    //         // updatedata = [
    //         //     {id:1, fullName:"Noor Khan", age:25, city:"Patna", address:"Patna"},
    //         //     {id:2, fullName:"Rapsan Jani", age:26, city:"Noida", address:"Patna"},
    //         //     {id:3, fullName:"Monika Singh", age:18, city:"New Delhi", address:"Patna"},
    //         //     {id:4, fullName:"Sunil Kumar", age:22, city: "Jaipur", address:"Patna"},
    //         //     {id:5, fullName:"Kajol Kumari", age: 21, city: "Chennai", address:"Delhi"}
    //         // ]

    //         setItems(updatedata)
        
    //         const column1 = Object.keys(updatedata[0]);

    //         setcolumn(column1)
    //     }
    //     registerUserdata();

    // }, []);

     const PrintPDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "Userdata",
        // onAfterPrint: () => alert("Data saved in PDF")
    });

    const goPrePageFunction = (event) => {
        event.preventDefault();        
        const v_form_name = localStorage.getItem('fm');
        navigate(`/${v_form_name}`);               
    }

    const GenerateReport = (event) => {
        event.preventDefault();        
        
        const registerUserdata = async () => {
            let updatedata = [];

            const response = await axios.get(`url_temp_issue_fields/${from_month}/${to_month}`,
                 { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data;                        
            setItems(updatedata);

            // console.log(updatedata)

            setItems(updatedata)
        
            const column1 = Object.keys(updatedata[0]);

            setcolumn(column1)
        }
        registerUserdata();
    }

    

    // get table heading data
    const ThData =()=>{
    
        return column.map((data)=>{
            // return <th key={data}>{data}</th>

            return <th className = "py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
            key={data}>{data}</th>
        })
    }

    // get table row data
    const tdData =() =>{

        return items.map((data)=>{
        return(
            <tr>
                {
                column.map((v)=>{
                    // return <td>{data[v]}</td>
                    return <td className = "py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName">
                        {data[v]}</td>
                })
                }
            </tr>
        )
        })
    }
   

return (
<>
    {/* <div className="mt-32 grid justify-center cols-4 gap-4"> */}

    <div className="pt-PaddingReportTopAfterMenu grid grid-cols-6 gap-2">

        <div className="col-span-1">
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">From Month</label>
            <input className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="from_month"
                id="from_month"
                type="text"
                value={from_month}
                onChange={(e) => setfrom_month(e.target.value)}
            ></input>
        </div>

        <div className="col-span-1">
            <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">To Month</label>
            <input className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                name="to_month"
                id="to_month"
                type="text"
                value={to_month}
                onChange={(e) => setto_month(e.target.value)}
            ></input>
        </div>

        <button className="h-DropDownHeight mt-6 bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
            onClick={GenerateReport}>   
            Show Report
        </button>    

        <button className="h-DropDownHeight mt-6 bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
            onClick={PrintPDF}>   
            Print
        </button>

        {/* <button className="h-DropDownHeight mt-6 bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
            onClick={PrintPDF}>   
            Export
        </button>     */}

        <CSVLink className="h-DropDownHeight mt-6 text-center bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
            data={items} filename="DailyCollection">Export</CSVLink>           
        
        <button className="h-DropDownHeight mt-6 bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BtnFontSize text-BackBtnFontColor font-BtnFontName rounded-none"
            onClick={goPrePageFunction}
        >Back</button>
    </div>    

    <div ref={conponentPDF}>  
        
        <table className="w-[100%] mt-2 py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName">
             

         {/* <table className="table"> */}
            
            {/* <thead className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"> */}
            <thead>
                <tr>
                    {ThData()}
                </tr>
            </thead>
            
            <tbody>
                {tdData()}
            </tbody>

        </table>

    </div>
</>
);};

export default DynamicTablePivot;
