import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import moment from 'moment';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import CRUDContext from '../components/CRUDContext';

import { emptyTempDataFun, emptyGlobalDataFun, setTempDataFun } from '../components/redux/actions';

import Cookies from 'js-cookie';

const VehicleMaster = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';    
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);      
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);   

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disableSave, setdisableSave] = useState(0);
    const [disableDelete, setdisableDelete] = useState(0);    
    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData); 
    const ReduxTempData = useSelector((state) => state.TempData);    
    const ReduxDDStudentClassData = useSelector((state) => state.DDStudentClassData);    

    // define drop downs inputs

    const [vech_no, setvech_no] = useState('');
    const [vech_type, setvech_type] = useState(0);
    const [vech_under_categ, setvech_under_categ] = useState(0);
    const [vech_manu_name, setvech_manu_name] = useState(0);
    const [vech_fuel_used, setvech_fuel_used] = useState(0);
    const [vech_model, setvech_model] = useState(0);
    const [chasis_no, setchasis_no] = useState('');
    const [engine_no, setengine_no] = useState('');
    const [reg_date, setreg_date] = useState(0);
    const [reg_valid_upto, setreg_valid_upto] = useState(0);
    const [reg_place, setreg_place] = useState(0);
    const [ref_state, setref_state] = useState(0);
    const [permit, setpermit] = useState(0);
    const [no_of_seats, setno_of_seats] = useState('');
    const [gps_enabled, setgps_enabled] = useState(false);
    const [notes, setnotes] = useState('');
    const [active_status, setactive_status] = useState(false);

    // define drop downs items
    
    const [vech_typeItems, setvech_typeItems] = useState([]);
    const [vech_under_categItems, setvech_under_categItems] = useState([]);
    const [vech_manu_nameItems, setvech_manu_nameItems] = useState([]);
    const [vech_fuel_usedItems, setvech_fuel_usedItems] = useState([]);
    
    // define other input

    // define form name and navigation details

    const [v_urlSaveUpdateDelete, setv_urlSaveUpdateDelete] = useState('');

    const v_backToNavigateName = '/Dashboard';
    const v_findToNavigateName = '/VehicleMasterDetails';
    const v_main_form_heading = 'Vehicle Master';
    const v_frm_no = 40;    

    let v_input_result, v_input_value;
    
    // define standard errors and entry required messages

    const {        
        deletedMessage,
        createdMessage,
        updatedMessage,        
        whenStudentNotFoundMessage,                     
        WhenRecordNotFoundToDelete,
        WhenRecordNotFoundToUpdate,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);          

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code

                setv_open_frm(true);                

                setdisableSave(false);
                setdisableDelete(true);

                setBlankFields();

                const v_token = Cookies.get('token');

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_get_data/38/0/0/CONVEY_CATEG`,
                    { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setvech_typeItems(updatedata);

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_get_data/38/0/0/VECH_CATEG`,
                    { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setvech_under_categItems(updatedata);

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_get_data/38/0/0/VECH_MAKER`,
                    { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setvech_manu_nameItems(updatedata);

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_get_data/38/0/0/FUAL`,
                    { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setvech_fuel_usedItems(updatedata);

                if (ReduxTempData.length > 0){
                    FindSavedDetails(ReduxTempData[0].tempuserid);
                    dispatch(emptyTempDataFun());
                }
            }

        }
        loadData();
    }, [ReduxGlobalData[0]]); 

    useEffect(() => {
        setBlankFields();
    }, [ReduxGlobalData[0]]);       

    function FindSavedDetails(p_TableRecordID) {        

        const getAllTableRecordFunction = async (var_link) => {

            const v_token = Cookies.get('token');

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/40_vehicle_master_id_wise/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${p_TableRecordID}`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data;     
            
            console.log("updatedata >> ", updatedata)

            // setvou_date(moment(updatedata[0].v_vou_date).format('YYYY-MM-DD'));

            const returnVal = AuthenticationCheckDetailFun(updatedata, 'READ');
            if (!returnVal){return};

            updatedata = await response.data.v_out_data.v_data;

            setTableRecordID(p_TableRecordID);          

            setvech_no(updatedata[0].vech_no);
            setvech_type(updatedata[0].vech_type);
            setvech_under_categ(updatedata[0].vech_under_categ);
            setvech_manu_name(updatedata[0].vech_manu_name);
            setvech_fuel_used(updatedata[0].vech_fuel_used);
            setvech_model(updatedata[0].vech_model);
            setchasis_no(updatedata[0].chasis_no);
            setengine_no(updatedata[0].engine_no);

            setreg_date(moment(updatedata[0].reg_date).format('YYYY-MM-DD'));
            setreg_valid_upto(moment(updatedata[0].reg_valid_upto).format('YYYY-MM-DD'));            

            setreg_place(updatedata[0].reg_place);
            setref_state(updatedata[0].ref_state);
            setpermit(updatedata[0].permit);
            setno_of_seats(updatedata[0].no_of_seats);
            
            if (updatedata[0].gps_enabled === 1){
                setgps_enabled(true);
            }else{
                setgps_enabled(false);
            }

            setnotes(updatedata[0].notes);

            if (updatedata[0].active_status === 1){
                setactive_status(true);
            }else{
                setactive_status(false);
            }

            setsaveUpdateTag('UPDATE');

        }
        getAllTableRecordFunction();        
    }

    function setBlankFields() {
        setsaveUpdateTag('SAVE');
        setdisableSave(false);
        setdisableDelete(true);         
        setTableRecordID(0);
        
        setvech_no('');
        setvech_type(0);
        setvech_under_categ(0);
        setvech_manu_name(0);
        setvech_fuel_used(0);
        setvech_model(0);
        setchasis_no('');
        setengine_no('');
        setreg_date(0);
        setreg_valid_upto(0);
        setreg_place(0);
        setref_state(0);
        setpermit(0);
        setno_of_seats('');
        setgps_enabled(false);
        setnotes('');
        setactive_status(false);             
    }    

    const handleInputValueFun = (name, value) => {

        if (name === 'vech_no') {            
            setvech_no(value.toUpperCase());           
            return;
        }else if (name === 'vech_type') {
            setvech_type(value);
            return;            
        }else if (name === 'vech_under_categ') {
            setvech_under_categ(value);
            return;            
        }else if (name === 'vech_manu_name') {
            setvech_manu_name(value);
            return;            
        }else if (name === 'vech_fuel_used') {
            setvech_fuel_used(value);
            return;            
        }else if (name === 'vech_model') {
            setvech_model(value);
            return;  
        }else if (name === 'chasis_no') {            
            setchasis_no(value);            
            return;
        }else if (name === 'engine_no') {
            setengine_no(value);
            return;            
        }else if (name === 'reg_date') {
            setreg_date(value);
            return;
        }else if (name === 'reg_valid_upto') {
            setreg_valid_upto(value);
            return;            
        }else if (name === 'reg_place') {
            setreg_place(value);
            return;
        }else if (name === 'ref_state') {
            setref_state(value);
            return;     
        }else if (name === 'permit') {
            setpermit(value);
            return;            
        }else if (name === 'no_of_seats') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setno_of_seats(capitalizeWordFun(v_input_result.v_value));
            return;        
        }else if (name === 'notes') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setnotes(capitalizeWordFun(v_input_result.v_value));            
            return;
        }

    }

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        let v_vech_under_categ = 0, v_vech_manu_name = 0, v_vech_fuel_used = 0, v_vech_model = 0, v_reg_date = null, 
        v_reg_valid_upto = null, v_reg_place = 0, 
        v_ref_state = 0, v_permit = 0, v_no_of_seats = 0, insupdel_type = '', v_gps_enabled = 2, v_active_status = 2; 

        if (value === 'DELETE'){

            if (TableRecordID === 0){
                toast.info(WhenRecordNotFoundToDelete, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            insupdel_type = 'DELETE';
        }else{
            if (TableRecordID === 0){
                insupdel_type = 'INSERT';            
            }else{

                if (TableRecordID === 0){
                    toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return;
                }

                insupdel_type = 'UPDATE';            
            }
        }

        if (value != 'DELETE'){

            if(vech_no.trim().length === 0){
                toast.error('Entry required for Vehicle No. !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }

            console.log("vech_type >> ", vech_type)

            if(parseInt(vech_type) === 0){
                toast.error('Entry required for Vehicle type !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }
            
            if(vech_under_categ.length === undefined || vech_under_categ.length === 0){                
                v_vech_under_categ = 0;                
            }else{
                v_vech_under_categ = vech_under_categ;
            }

            if(vech_manu_name.length === undefined || vech_manu_name.length === 0){                
                v_vech_manu_name = 0;                
            }else{
                v_vech_manu_name = vech_manu_name;
            }

            if(vech_fuel_used.length === undefined || vech_fuel_used.length === 0){
                v_vech_fuel_used = 0;                
            }else{
                v_vech_fuel_used = vech_fuel_used;
            }

            if(vech_model.length === undefined || vech_model.length === 0){
                v_vech_model = 0;                
            }else{
                v_vech_model = vech_fuel_used;
            }

            if (moment(reg_date, "YYYY-MM-DD", true).isValid())
            {            
                v_reg_date = reg_date
            }else{            
                v_reg_date = null;
            }

            if (moment(reg_valid_upto, "YYYY-MM-DD", true).isValid())
            {            
                v_reg_valid_upto = reg_valid_upto
            }else{            
                v_reg_valid_upto = null;
            }

            if(reg_place.length === undefined || reg_place.length === 0){
                v_reg_place = 0;                
            }else{
                v_reg_place = vech_fuel_used;
            }

            if(ref_state.length === undefined || ref_state.length === 0){
                v_ref_state = 0;                
            }else{
                v_ref_state = ref_state;
            }

            if(permit.length === undefined || permit.length === 0){
                v_permit = 0;                
            }else{
                v_permit = permit;
            }

            if (no_of_seats === ''){
                v_no_of_seats = 0;        
            }else{
                v_no_of_seats = no_of_seats;        
            }

            if (gps_enabled){
                v_gps_enabled = 1;
            }else{
                v_gps_enabled = 2;
            }

            if (active_status){
                v_active_status = 1;
            }else{
                v_active_status = 2;
            }

        }

        const data = {
            id: TableRecordID,
            insupdel_type,            
            under_comp_branch_id : ReduxGlobalData[0].br,
            fin_year : ReduxGlobalData[0].fy,

            vech_no,
            vech_type,
            vech_under_categ : v_vech_under_categ, 
            vech_manu_name : v_vech_manu_name, 
            vech_fuel_used : v_vech_fuel_used, 
            vech_model : v_vech_model,
            chasis_no,
            engine_no,
            reg_date : v_reg_date, 
            reg_valid_upto : v_reg_valid_upto, 
            reg_place : v_reg_place, 
            ref_state : v_ref_state, 
            permit : v_permit, 
            no_of_seats : v_no_of_seats,            
            gps_enabled:v_gps_enabled, 
            notes, 
            active_status:v_active_status, 
            
            // items: Array(items),
        };
        
        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        setv_urlSaveUpdateDelete('40_url_vehicle_master_new_proc');

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }    
    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);        

        if (p_data.ModelYesNoAnswer === 'YES'){
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async(p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final:p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final:p_last_updel_notes_final,
        }        

        const v_token = Cookies.get('token');       

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });
        
        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }
    
    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }     

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then           

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                    
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            // console.log("ID >> ", updatedata.v_data[0].p_id);

            setNewRecordIDFunction(updatedata.v_data[0].p_id);
            
            if (updatedata.transType === 'INSERT'){
                setdisableDelete(false);             
                setsaveUpdateTag('UPDATE');
                v_toast_msg = createdMessage;
            }else if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;
            }else if (updatedata.transType === 'DELETE'){
                setdisableSave(true);
                setdisableDelete(true);   
                v_toast_msg = deletedMessage;
            }

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }

    const setNewRecordIDFunction = async(p_v_data) => {        

        // console.log("TabID >> ", p_v_data0]);

        setTableRecordID(await p_v_data);        
    }

    const setPopUpDDFalseFunction = (p_data) => {        
        setConditionalCSS(false);
    }    

    const handleInputFocus = (event) => {
        event.preventDefault();

        // to be used for show pop single or multi select

        // const { name, value } = event.target;        
    };

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        // console.log('in handleInputValue function')

        handleInputValueFun(name, value);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        
        
        if (value === 'BTN_NEW'){            
            setBlankFields();
            return;
        }else if (value === 'BTN_PRINT'){
            
            if (TableRecordID === 0) {
                toast.error(whenStudentNotFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            return;
        }else if (value === 'BTN_EXPORT_EXCEL'){
                        
            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        }else if (value === 'BTN_EXPORT_PDF'){
            
            return;
        }else if (value === 'BTN_FIND'){            
            navigate(`${v_findToNavigateName}`); 
            return;
        }else if (value === 'BTN_COPY_TO_BRANCH'){
            return;
        }else if (value === 'BTN_ASSIGN_BRANCH'){
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }

    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    
    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        if (name === 'gps_enabled'){
            setgps_enabled(checked);
        }else if (name === "active_status"){
            setactive_status(checked);
        }
    }

    const goCheckDataFunction = (event) => {
        event.preventDefault();
        dispatch(emptyGlobalDataFun());
    }

return (

<>
    {
        ShowConfirmModelSave ? 
        <div className="centerWindow_css">
            <ConfirmModelSave transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

    {
        ShowConfirmModelUpdateDelete ? 
        <div className="centerWindow_css w-[32%]">
            <ConfirmModelUpdateDelete transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">

    <div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

    <div className="form_div_main_css p-28 bg-slate-200">
        <div className="form_div_inner_css grid grid-cols-3 bg-slate-200">
            
            <div>
                <label htmlFor="vech_no" className="label_inner_page_css required_css">Vehicle No.</label>
                <input className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 6)}
                    type="text"                                
                    name="vech_no"
                    id="vech_no"
                    value={vech_no}                                    
                    onChange={handleInputValue}                    
                    onFocus={handleInputFocus}                    
                ></input>
            </div>

            <div>        
                <label htmlFor="vech_type" className="label_inner_page_css required_css">Type</label>
                <select className="input_inner_page_css"                
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="vech_type"
                    id="vech_type"
                    value={vech_type}                                   
                    onChange={handleInputValue}               
                    onFocus={handleInputFocus}                    
                >
                    <option></option>
                    {
                        vech_typeItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>  

            <div>        
                <label htmlFor="vech_under_categ" className="label_inner_page_css">Category</label>
                <select className="input_inner_page_css"                
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="vech_under_categ"
                    id="vech_under_categ"
                    value={vech_under_categ}                   
                    onChange={handleInputValue} 
                    onFocus={handleInputFocus}
                >
                    <option></option>
                    {
                        vech_under_categItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>        
                <label htmlFor="vech_manu_name" className="label_inner_page_css">Manufacture Name</label>
                <select className="input_inner_page_css"                
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="vech_manu_name"
                    id="vech_manu_name"
                    value={vech_manu_name}                   
                    onChange={handleInputValue} 
                    onFocus={handleInputFocus} 
                >
                    <option></option>
                    {
                        vech_manu_nameItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>  

            <div>        
                <label htmlFor="vech_fuel_used" className="label_inner_page_css">Fuel Used</label>
                <select className="input_inner_page_css"                
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="vech_fuel_used"
                    id="vech_fuel_used"
                    value={vech_fuel_used}                    
                    onChange={handleInputValue} 
                    onFocus={handleInputFocus}
                >
                    <option></option>
                    {
                        vech_fuel_usedItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>        
                <label htmlFor="vech_model" className="label_inner_page_css">Model</label>
                <select className="input_inner_page_css"                
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="vech_model"
                    id="vech_model"
                    value={vech_model}                   
                    onChange={handleInputValue} 
                    onFocus={handleInputFocus}
                    disabled
                >
                    <option></option>
                    {
                        vech_fuel_usedItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>
                <label htmlFor="chasis_no" className="label_inner_page_css">Chasis No.</label>        
                <input className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 8)}   
                    type="text"                             
                    name="chasis_no"
                    id="chasis_no"
                    value={chasis_no}                  
                    onChange={handleInputValue}
                    onFocus={handleInputFocus}
                ></input>            
            </div> 

            <div>
                <label htmlFor="engine_no" className="label_inner_page_css">Engine No.</label>        
                <input className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 8)}   
                    type="text"                             
                    name="engine_no"
                    id="engine_no"
                    value={engine_no}                  
                    onChange={handleInputValue} 
                    onFocus={handleInputFocus}
                ></input>            
            </div>
            
            <div>
                <label htmlFor="reg_date" className="label_inner_page_css">Registration Date</label>
                <input className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 7)}    
                    type="date"                            
                    name="reg_date"
                    id="reg_date"
                    value={reg_date}                   
                    onChange={handleInputValue}   
                    onFocus={handleInputFocus}
                ></input>
            </div>

            <div>
                <label htmlFor="reg_valid_upto" className="label_inner_page_css">Registration Valid UP</label>
                <input className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 7)}    
                    type="date"                            
                    name="reg_valid_upto"
                    id="reg_valid_upto"
                    value={reg_valid_upto}                                   
                    onChange={handleInputValue} 
                    onFocus={handleInputFocus}
                ></input>
            </div>

            <div>        
                <label htmlFor="reg_place" className="label_inner_page_css">Registration Place</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="reg_place"
                    id="reg_place"
                    value={reg_place}                 
                    onChange={handleInputValue} 
                    onFocus={handleInputFocus}
                    disabled
                >
                    <option></option>
                    {
                        vech_fuel_usedItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>        
                <label htmlFor="ref_state" className="label_inner_page_css">Registration State</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="ref_state"
                    id="ref_state"
                    value={ref_state}                                    
                    onChange={handleInputValue}
                    onFocus={handleInputFocus}
                    disabled         
                >
                    <option></option>
                    {
                        vech_fuel_usedItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>        
                <label htmlFor="permit" className="label_inner_page_css">Registration Permit</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="permit"
                    id="permit"
                    value={permit}                 
                    onChange={handleInputValue}  
                    onFocus={handleInputFocus}
                    disabled
                >
                    <option></option>
                    {
                        vech_fuel_usedItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>
                <label htmlFor="no_of_seats" className="label_inner_page_css required_css">No.of Seats</label>
                <input className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 8)}   
                    type="text"                             
                    name="no_of_seats"
                    id="no_of_seats"
                    value={no_of_seats}                           
                    onChange={handleInputValue} 
                    onFocus={handleInputFocus}
                ></input>            
            </div>

            <div className="col-span-1 flex">

                <div className="w-[50%]">           
                    <label className="label_inner_page_css">GPS Enabled</label>            
                    <input className="table_check_inner_page_css w-10"
                        type="checkbox" name="gps_enabled" checked={gps_enabled}
                        onChange={handleChangeCheckBox}
                        onFocus={handleInputFocus}
                    />        
                </div>

                <div className="w-[50%]">
                    <label className="label_inner_page_css">Status</label>
                    <input className="table_check_inner_page_css w-10"
                        type="checkbox" name="active_status" checked={active_status}
                        onChange={handleChangeCheckBox}
                        onFocus={handleInputFocus}
                    />        
                </div>

            </div>
            
            <div className="col-span-4">
                <label className="label_inner_page_css">Notes</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 33)}
                    name="notes"
                    id="notes"
                    value={notes}                    
                    onChange={handleInputValue}                                    
                    onFocus={handleInputFocus}                    
                ></input>              
            </div>
            
        </div>
    </div>  

    <nav className="btn_nav_inner_css">
    <div className="btn_div_main_css">

        <div className="btn_div_inner_css">

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_new_css"
                            value = 'BTN_NEW'
                            onClick={handleButtonValue}>
                            New
                        </button>
                    )}
                </div>

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_save_css"
                            disabled={disableSave}
                            onClick={createRecordFunction}>
                            Save
                        </button>
                    )}
                </div>

                {/* <div>                
                    {userAuthData.allow_delete === 1 && (
                        <button className="btn_delete_css"
                            value = 'DELETE'
                            disabled={disableDelete}
                            onClick={createRecordFunction}>
                            Delete
                        </button>
                    )}
                </div>                */}

                <div>
                    {userAuthData.allow_read === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_FIND'
                            onClick={handleButtonValue}>
                            Find
                        </button>
                    )}
                </div>                             

                <div>
                    <button className="btn_back_css"
                        value = 'BTN_BACK'
                        onClick={handleButtonValue}>
                        Back
                    </button>
                </div>               

                {/* <div>
                    <button className="btn_back_css"
                        onClick={goCheckDataFunction}>
                        Check Data
                    </button>
                </div> */}
                
        </div>
        
    </div>
    </nav>

    </form>
:null
}

</>
);};

export default VehicleMaster;