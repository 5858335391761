import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { GetFormAuthDetails} from '../components/GlobalFunction';
import CRUDContext from '../components/CRUDContext';

import { emptyGlobalDataFun } from '../components/redux/actions';

import Cookies from 'js-cookie';

const DefaultSetting = () => {
    const [v_open_frm, setv_open_frm] = useState(false);  
    const [userAuthData, setUserAuthData] = useState([]);   

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData);
 
    // const v_urlSaveUpdateDelete = '';
    const v_backToNavigateName = '/Dashboard';
    // const v_findToNavigateName = '/';
    const v_main_form_heading = 'Default Setting';
    const v_frm_no = 3;

    let v_toast_msg = '';
    
    // define standard errors and entry required messages

    const {   
        v_allow_open,       
        v_session_expire_msg,
        v_access_denied_msg,        
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);          

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code   
                
                setv_open_frm(true);
            }
        }
        loadData();
    }, [ReduxGlobalData[0]]);     
    
    const handleButtonValue = (e, v_backToNavigateName) => {
        e.preventDefault();

        navigate('/' + `${v_backToNavigateName}`);        
    }

    const goCheckDataFunction = (event) => {
        event.preventDefault();

        dispatch(emptyGlobalDataFun());   

    }

return (

<>
{
v_open_frm ? 

<form className="form_popup_show_css_false" autoComplete="off">

<div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

<div className="form_div_main_css mt-2 border-none">
    
<table>
<tbody>

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, 'sfl')}>Student Fee Ledger</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, 'StudentFullFeeConcession')}>Student Full Fee Concession</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'lc')}>List Code</td>
    </tr> 

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'pa')}>List of Values</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, 'ug')}>User Group</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'UserGroupPageMasterBranchWise')}>User Group and Page Permission Branch Wise</td>
    </tr>  

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, '#')}>Currency - P</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, '#')}>Period (Session)  - P</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, '#')}>Country  - P</td>
    </tr>  

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, '#')}>State  - P</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, '#')}>City  - P</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, '#')}>Department - P</td>
    </tr>

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, '#')}>Bank Details - P</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, 'cg')}>Company Group</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, 'nu')}>User Registration Group Wise <p>(Only One For One Client)</p></td>
    </tr>  

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, 'br')}>Branch</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'nugb')}>User Registration <p>(Company Group and Branch Wise Assignment)</p></td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'fcg')}>Fee Code Group</td>
    </tr>  

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'ffg')}>Fee Fine Group</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'fcs')}>Fee Code</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'fms')}>Fee Month</td>
    </tr>  

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'br_pa')}>List of Values-Branch Wise</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'frsbw')}>Fee Receipt No.</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, 'frfomatbw')}>Fee Receipt Print Format</td>
    </tr>

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, 'FeeFineMaster')}>Fee Fine Master</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'avt')}>Account's Voucher Master</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, 'ag')}>Account's Group Master</td>
    </tr>  

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'al')}>Account's Ledger Master</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, 'Sdt')}>Student Import From Excel For New School</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, 'FeeCollLed')}>Fee Collection Ledger Setting</td>
    </tr> 

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'menuitems')}>Menu Item Master</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'menulink')}>Menu Item Joins</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, '#')}></td>
    </tr>  

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'ExamGroup')}>Exam Group</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'ExamGrade')}>Exam Grade</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'ExamTerm')}>Exam Term</td>
    </tr>  

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'ExamType')}>Exam Type</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'SubjectMasterBranchWise')}>Subject Master Branch Wise</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'ExamReportCardMasterBW')}>Report Card Structure Branch and Fin-Year Wise</td>
    </tr> 

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'SchoolReopenAfterExam')}>School Re-Open After Exam</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'StudentTransferMaster')}>Student Transfer Master</td>
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, '#')}></td>
    </tr>

    <tr className="table_thead_tr_css mb-1 gap-1">
        <td className="table_th_td_default_css" onClick={(e) => handleButtonValue(e, 'EmployeeMasterBranchWise')}>Employee Master Branch Wise</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'CopyToFinYearBranchWise')}>Copy All Data To New Financial Year and Branch Wise</td>
        <td className="table_th_td_default_css bg-yellow-100 text-neutral-950" onClick={(e) => handleButtonValue(e, 'BranchWiseDefault')}>Branch Wise Default</td>
    </tr>    

</tbody>
</table>
</div>  
</form>
:null
}

</>
);};

export default DefaultSetting ;
