// Table name : ac_group

import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';
// import Layout from './Layout';

const Ag = (props) => {
    const dispatch = useDispatch();
    const ReduxData = useSelector((state) => state);

    const [ShowModelFormDeleteUpdate, setModelFormDeleteUpdate] = useState(false);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);
    const [updateDelTag, setUpdateDelTag] = useState('');
    const [updateDelapiName, setUpdateDelapiName] = useState('');
    
    // const router = useRouter();

    const navigate = useNavigate();
    
    const [group_name, setgroup_name] = useState('');
    const [print_name, setprint_name] = useState('');
    const [under_ac_group_id, setunder_ac_group_id] = useState(0);
    const [group_type, setgroup_type] = useState(0);
    const [group_belog_to, setgroup_belog_to] = useState(0);
    const [notes, setnotes] = useState('');
    const [show_in_list,setshow_in_list] = useState(0);
    
    const [under_ac_group_idItems, setunder_ac_group_idItems] = useState([]);    
    const [group_typeItems, setgroup_typeItems] = useState([]);
    const [group_belog_toItems, setgroup_belog_toItems] = useState([]);

    const YesNoList = [
        // { id: 0, sel_val: 0, sel_desc: "" },
        { id: 1, sel_val: 1, sel_desc: "Yes" },
        { id: 2, sel_val: 2, sel_desc: "No" }
    ];

    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,        
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenPageRefreshMessage,        
    } = useContext(CRUDContext);

    // ////////////////////////////

    // const getAuthroziedUser = async () => {  
    //     try{
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //               { headers: { Authorization: `Bearer ${token}` } });            
    
    //         if (response.status === 401 || response.status === 403){
    //             localStorage.clear();
    //             // router.push('/Login')
    //             navigate("/Login");
    //         };

    //     }catch(error){
    //         localStorage.clear();
    //         // router.push('/Login')
    //         navigate("/Login");
    //     }
    // }    

    // useEffect( () => {        
    //     getAuthroziedUser();    
    // }, []);

    // ////////////////////////////

    useEffect(() => {
        if (error) {
            toast.error(error);
            clearErrors();
        }

        if (created) {
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
        }

        if (updated) {
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
        }
    }, [error, created, updated, deleted]); 
    
    useEffect(() => {  

        let response = []
        let updatedata = []

        const getParamTableRecordFunctionBranch = async (var_link) => {            

            response = await axios.get(`${var_link}/ACGRPTYPE`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setgroup_typeItems(updatedata);

            response = await axios.get(`${var_link}/ACGRPBELG`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setgroup_belog_toItems(updatedata);
        }
        getParamTableRecordFunctionBranch('url_param_view_drop_down');
    }, [])
    
    useEffect(() => {      
        let response = []
        let updatedata = []

        // to fill the drop-down under_Group

        const getTableRecordFunction = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_ac_group_idItems(updatedata);
        }
        getTableRecordFunction('url_ac_group_view'); 

        // to get the data when user click on view to show the existing record (from ug_view page)

        if (pk_id > 0) {
            const getAllTableRecordFunction = async (var_link) => {
                response = await axios.get(`${var_link}/${pk_id}`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

                updatedata = await response.data.v_out_data;

                // console.log("updatedata is : ", updatedata)

                // shiv

                setPkID(pk_id);
                setgroup_name(updatedata[0].group_name);
                setprint_name(updatedata[0].print_name);
                setunder_ac_group_id(updatedata[0].under_ac_group_id);
                setgroup_type(updatedata[0].group_type);
                setgroup_belog_to(updatedata[0].group_belog_to);
                setnotes(updatedata[0].notes);
                setshow_in_list(updatedata[0].show_in_list);
            }
            getAllTableRecordFunction('url_ac_group_id_wise');
        }

    }, [pk_id])
    
    const newBlankFieldHandler = (e) => {
        e.preventDefault();

        setPkID(0);
        
        setgroup_name('');
        setprint_name('');
        setunder_ac_group_id('');
        setgroup_type('');
        setgroup_belog_to('');
        setnotes('');
        setshow_in_list('');

        // setactive_status('');
    }

    const BranchWiseAssigmentHandler = (event) => {
        event.preventDefault();
        // router.push('/bwag');
        navigate("/bwag");
    }

    const createRecordFunction = (event) => {
        event.preventDefault();

        if (group_name.trim().length === 0){
            toast.info("Entry reuired for group name !")
            clearErrors();
            return;
        }

        let v_under_ac_group_id = 0;

        // console.log("group_type is : ", under_ac_group_id.length);

        if(under_ac_group_id === undefined || under_ac_group_id.length === undefined || under_ac_group_id.length === 0){            
            v_under_ac_group_id = 0;            
        }else{
            v_under_ac_group_id = under_ac_group_id;
        }

        if (group_type < 1){
            toast.error("Entry required for Group Type !");
            clearErrors();
            return;
        }

        if (group_belog_to < 1){
            toast.error("Entry required for Group Belong To !");
            clearErrors();
            return;
        }

        if (show_in_list < 1){
            toast.error("Entry required for Show in List !");
            clearErrors();
            return;
        }

        // console.log("group_type is : ", group_type.length);
        
        // if(group_type === undefined || group_type.length === undefined || group_type.length === 0){
        //     toast.error("Entry required for Group Type !");
        //     clearErrors();
        //     return;
        // }        

        // if(group_belog_to === undefined || group_belog_to.length === undefined || group_belog_to.length === 0){
        //     toast.error("Entry required for Group Belong To !");
        //     clearErrors();
        //     return;
        // }

        // if(show_in_list === undefined || show_in_list.length === undefined || show_in_list.length === 0){
        //     toast.error("Entry required for Show in List !");
        //     clearErrors();
        //     return;
        // }
        
        const data = {
            id: pk_id,

            group_name, 
            print_name, 
            under_ac_group_id : v_under_ac_group_id, 
            group_type, 
            group_belog_to, 
            notes, 
            active_status : 1, 
            show_in_list,
        }
        
        dispatch(emptyCart());
        dispatch(addTempList(data));

        // console.log("pk_id value is : ", pk_id)

        if (pk_id === 0) {            
            setUpdateDelTag('')
            setModelModelFormYesNo(true);
        }
        else {            
            setUpdateDelTag('UPDATE')            
            setUpdateDelapiName('url_ac_group_updel')
            setModelFormDeleteUpdate(true);
        }
    }

    const FindInTablePage = (event) => {
        event.preventDefault();
        // router.push('/ag_view')
        navigate("/ag_view");
    }

    // const handleDeleteUpdateFunction = (event) => {
    //     event.preventDefault();

    //     if (ReduxData.userData[0].id.length === 0){
    //         toast.info(whenPageRefreshMessage)
    //         clearErrors();
    //         return;
    //     }

    //     const data = {
    //         id: pk_id,
    //         last_access_ip : ipAddess,
    //         last_access_by : ReduxData.userData[0].id,    
    //     };

    //     setUpdateDelTag('DELETE')
    //     setUpdateDelapiName('usergroup_delete')

    //     dispatch(emptyCart());
    //     dispatch(addTempList(data));
    //     setModelFormDeleteUpdate(true);
    // }

    const handleunder_ac_group_id = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setunder_ac_group_id(getId);
    }

    const handlegroup_type = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setgroup_type(getId);
    }

    const handlegroup_belog_to = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setgroup_belog_to(getId);
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();   
        setPkID(0);  
        // router.push('/brms');
        navigate("/brms");
    }

    function handleEnter(event) {
        if (event.keyCode === 13) {
          const form = event.target.form;
          const index = Array.prototype.indexOf.call(form, event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
        }
    }

    const handleShowInListOption = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setshow_in_list(getId);
    }

    return (
        <>
            <form>
            <div className="pt-PaddingTopAfterMenu max-w-full text-my-main-heading-fontSize bg-my-main-heading-color text-my-main-heading-font-color text-left">Account Group</div>
        
            <div className="justify-center items-center inset-0 flex">

                <div className="pt-1 px-2">                  

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Group Name</label>
                    <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                        name="group_name"
                        id="group_name"
                        type="text"
                        // placeholder="Group Name"
                        value={group_name}
                        onChange={(e) => setgroup_name(e.target.value)}
                        onKeyDown={handleEnter} {...props}
                    ></input>

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Print Name</label>
                    <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                        name="print_name"
                        id="print_name"
                        type="text"
                        // placeholder="Print Name"
                        value={print_name}
                        onChange={(e) => setprint_name(e.target.value)}
                        onKeyDown={handleEnter} {...props}
                    ></input>

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Under Group</label>
                    <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                        name="under_ac_group_id" value={under_ac_group_id} onChange={(e) => handleunder_ac_group_id(e)}
                        onKeyDown={handleEnter} {...props}>
                        <option></option>
                        {
                            under_ac_group_idItems.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Group Type</label>
                    <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                        name="group_type" value={group_type} onChange={(e) => handlegroup_type(e)}
                        onKeyDown={handleEnter} {...props}>
                        <option></option>
                        {
                            group_typeItems.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Group Belong To</label>
                    <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                        name="group_belog_to" value={group_belog_to} onChange={(e) => handlegroup_belog_to(e)}
                        onKeyDown={handleEnter} {...props}>
                        <option></option>
                        {
                            group_belog_toItems.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>

                    {/* group_name, print_name, under_ac_group_id, group_type, group_belog_to, notes, show_in_list,  */}

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Notes</label>
                    <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                        name="notes"
                        id="notes"
                        type="text"
                        // placeholder="Notes"
                        value={notes}
                        onChange={(e) => setnotes(e.target.value)}
                        onKeyDown={handleEnter} {...props}
                    ></input>

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Show-in-List</label>
                    <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                        name="show_in_list" value={show_in_list} onChange={(e) => handleShowInListOption(e)}
                        onKeyDown={handleEnter} {...props}>
                        <option></option>
                        {
                            YesNoList.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>

                    <div className="pt-4 py-my-padding-py px-my-padding-px grid grid-cols-5 gap-4">

                        <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={BranchWiseAssigmentHandler}>
                            Branch Wise Assignment
                        </button>

                        <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={newBlankFieldHandler}>
                            New
                        </button>

                        <button className="bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-my-fontSize text-SaveButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={createRecordFunction}>
                            Save
                        </button>

                        {/* <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={handleDeleteUpdateFunction}>
                            Delete
                        </button> */}

                        <button className="bg-FindButtonBGColor hover:bg-FindButtonHoverColor text-my-fontSize text-FindButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={FindInTablePage}
                        >
                            Find
                        </button>

                        <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={goPrePageFunction}>
                            Back
                        </button>

                    </div>

                    <ModelFormDeleteUpdate api_type={updateDelTag} api_name={updateDelapiName} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)}/>

                    <ModelFormYesNo table_name='AC_GROUP' api_name={'url_ac_group_new'} isVisible={ShowModelFormYesNo} onClose={() => setModelModelFormYesNo(false)}/>
                    
                    
                </div>
            </div>
            </form>
        </>
    );
};

export default Ag;
