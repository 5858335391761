import React from "react";

const ConfirmModelSave = (props) => {
    
    const sendDataToParentFun = (event) => {
        event.preventDefault();
        
        const { name, value } = event.target; 

        SelectedDataFunction(value);
    }

    const SelectedDataFunction = (p_value) => {

        let last_access_ip_final = '', last_updel_notes_final = '';

        if (p_value === 'YES'){
            last_access_ip_final = 'shiv';
            last_updel_notes_final = 'Update value';
        }
        
        const data = {ModelYesNoAnswer:p_value, transType:props.transType, last_access_ip_final, last_updel_notes_final};

        props.setModelSaveUpdateDeleteFalseFunction(data);
    }
    
return (

<div>           
    <h1 className="h1_model_saveupdel_yes_css">Confirm Save</h1>
    <h1 className="h2_model_saveupdel_yes_css">Are you sure you want to save this record ?</h1>

    <div className="btn_div_model_inner_css">
        <div className="btn_div_model_saveupdel_css">
            <button className="btn_model_saveupdel_yes_css" type="submit" value = 'YES' onClick={sendDataToParentFun}>Yes</button>
        </div>

        <div className="btn_div_model_saveupdel_css">
            <button className="btn_model_saveupdel_no_css" type="submit" value = 'NO' onClick={sendDataToParentFun}>No</button>
        </div>
    </div>
</div>
);
};

export default ConfirmModelSave;
