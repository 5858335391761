import { useState, createContext, useEffect } from "react";
import axios from "axios";

// import { useRouter } from 'next/router';

import { useDispatch } from 'react-redux';
import { assignUserData, emptyUserData } from './redux/actions';
const CRUDContext = createContext();

export const CRUDContextProvider = ({ children }) => {

    const dispatch = useDispatch();

    const [pk_id, setPkID] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [created, setCreated] = useState(null);
    const [updated, setUpdated] = useState(null);
    const [deleted, setDeleted] = useState(null);
    const [viewRecord, setviewRecord] = useState(false);
    
    const [user, setUser] = useState('');    
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [deletedMessage, setDeletedMessage] = useState("Record has been delete successfully !!!");
    const [createdMessage, setCreatedMessage] = useState("Record has been Saved successfully !!!");
    const [updatedMessage, setUpdatedMessage] = useState("Record has been Updated successfully !!!");

    const whenNulldeleteMessage = "Please select value, which you want to delete...";
    const whenNullViewRecordMessage = "Please select value, which you want to view...";
    const whenMultipleViewRecordMessage = "Please select only one records, which you want to view...";
    const whenPageRefreshMessage = "We observed you refreshed the page...";

    const whenStudentNotFoundMessage = "Student not found...";
    const whenNoRecordFoundMessage = "Record not found...";

    const WhenRecordNotFoundToDelete = "Record not found to delete...";
    const WhenRecordNotFoundToUpdate = "Record not found to update...";

    const whenTablePaginationLastPage = "Last page ...";
    const whenTablePaginationFirstPage = "First page ...";

    const v_toast_position = "top-center";
    const v_toast_autoClose = 5000;
    const v_toast_hideProgressBar =  false;
    const v_toast_closeOnClick =  true;
    const v_toast_pauseOnHover =  true;
    const v_toast_draggable =  true;
    const v_toast_progress = undefined;
    const v_toast_theme =  "light";
    
    const v_allow_open = 'Your are not authorized to Open this form';
    const v_allow_read = 'Your are not authorized to view this record';
    
    const v_session_expire_msg = "Your session is expired ! Please login again to continue !!!";
    const v_access_denied_msg = "You are not authorized to access !!!";

    const v_new_open = 'Your are not authorized to Save this Record';
    const v_update_open = 'Your are not authorized to Update this Record';
    const v_delete_open = 'Your are not authorized to Delete this Record';

    // const v_allow_new = 'Your are not authorized to New this form';
    // const v_allow_read = 'Your are not authorized to Rpen this form';
    // const v_allow_update = 'Your are not authorized to Update this form';
    // const v_allow_delete = 'Your are not authorized to Delete this form';
    
    const clearErrors = () => {
        setError(null);
    };

    // Code is ok using Authecation

    const createRecord = async (data, var_link, api_type) => {
        try {
            setLoading(true);
            setCreated(false);
            setUpdated(false);
            setDeleted(false);

            // console.log(`${var_link}/`)
            
            const res = await axios.post(`${var_link}/`,
                data,
                {
                    // headers: {
                    //     Authorization: `Bearer ${access_token}`,
                    // },

                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },

                    

                }
            );

            setPkID(res.data.v_out_data);

            // console.log("In CRUD >> " , res.data.v_out_data)

            if (res.data) {
                setLoading(false);

                if (api_type === 'NEW') {
                    setCreated(true);
                }

                if (api_type === 'UPDATE') {
                    setUpdated(true);
                }

                if (api_type === 'DELETE') {
                    setDeleted(true);
                }
            }
        } catch (error) {

            // console.log("In CRUD Error : ", error.response.data.error_descr);

            setLoading(false);

            setCreated(false);
            setUpdated(false);
            setDeleted(false);

            setError(error.response.data.error_descr)

            // setError(error.response && (error.response.data.detail || error.response.data.error));

        }
    };

    // new user registration

    const register = async ({ firstName, lastName, email, password, userName, is_active, ipAddess, last_access_by, items }, access_token) => {
        try {
            setLoading(true)

            const res = await axios.post(`${process.env.API_URL}/api/register/`, {
                first_name: firstName,
                last_name: lastName,
                email,
                password,
                userName,
                is_active,
                ipAddess,
                last_access_by,
                items,
            });

            setPkID(res.data);
            
            if (res.data) {
                setLoading(false);
                setCreated(true);
                // router.push('/')
            }

            // if (res.data.message) {
            //     setLoading(false);
            //     setCreated(true);
            //     // router.push('/')
            // }
        } catch (error) {
            setLoading(false);
            setError(error.response && (error.response.data.detail || error.response.data.error));
        }
    };

    const registerForMe = async ({ firstName, lastName, email, password, userName, is_active }, access_token) => {
        try {
            setLoading(true)

            const res = await axios.post(`${process.env.API_URL}/api/registerMe/`, {
                first_name: firstName,
                last_name: lastName,
                email,
                password,
                userName,
                is_active,                
            });

            setPkID(res.data);            

            if (res.data) {
                setLoading(false);
                setCreated(true);
                // router.push('/')
            }

            // if (res.data.message) {
            //     setLoading(false);
            //     setCreated(true);
            //     // router.push('/')
            // }
        } catch (error) {
            setLoading(false);
            setError(error.response && (error.response.data.detail || error.response.data.error));
        }
    };

    // v - 69 update user profile

    const updateProfile = async ({ firstName, lastName, email, password, userName, is_active, ipAddess, last_access_by, items }, access_token) => {
        try {
            setLoading(true)

            const res = await axios.put(`${process.env.API_URL}/api/me/update/`, {
                first_name: firstName,
                last_name: lastName,
                email,
                password,
                userName,
                is_active,
                ipAddess,
                last_access_by,
                items,
            }, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                }
            });

            if (res.data) {
                setLoading(false);
                setUpdated(true);
                setUser(res.data);
            }
        } catch (error) {

            // console.log(error.response);

            setLoading(false);
            setError(error.response &&
                (error.response.data.detail || error.response.data.error)
            );
        }
    };  
    
    const updateProfileForMe = async ({ firstName, lastName, email, password, userName, is_active}, access_token) => {
        try {
            setLoading(true)

            const res = await axios.put(`${process.env.API_URL}/api/me/updateme/`, {
                first_name: firstName,
                last_name: lastName,
                email,
                password,
                userName,
                is_active                
            }, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                }
            });

            if (res.data) {
                setLoading(false);
                setUpdated(true);
                setUser(res.data);
            }
        } catch (error) {

            // console.log(error.response);

            setLoading(false);
            setError(error.response &&
                (error.response.data.detail || error.response.data.error)
            );
        }
    };    


    // v - 59
    // login user

    const login = async ({ username, password }) => {
        try {
            setLoading(true)

            const res = await axios.post('/api/auth/login', {
                username,
                password
            })

            if (res.data.success) {
                loadUser({ username });
                setIsAuthenticated(true);
                setLoading(false);
                // router.push('/login')               
            }
        } catch (error) {
            setLoading(false);
            setError(error.response && (error.response.data.detail || error.response.data.error));
        }
    };

    // v - 61 - get logged in USER
    // Load user

    const loadUser = async ({ username }) => {
        try {
            setLoading(true)
            const res = await axios.get(`${process.env.API_URL}/api/auth_user_nu_view_login_url/${username}`);
            if (res.data) {
                setIsAuthenticated(true);
                setLoading(false);
                setUser(res.data);

                // console.log(res.data)

                dispatch(emptyUserData());
                dispatch(assignUserData(res.data));
            }

        } catch (error) {
            setLoading(false);
            setIsAuthenticated(false);
            setUser(null);

            setError(error.response && (error.response.data.detail || error.response.data.error))
        }
    };

    // v - 63 - Logout USER
    // logout user

    const logout = async () => {
        try {

            const res = await axios.post('/api/auth/logout');

            if (res.data.success) {
                setIsAuthenticated(false);
                setUser(null);
            }
        } catch (error) {
            setLoading(false);
            setIsAuthenticated(false);
            setUser(null);

            setError(error.response &&
                (error.response.data.detail || error.response.data.error))
        }
    };







    // // Code is ok using Authecation
    // const updateRecord = async (id, data, access_token, var_link) => {
    //     try{
    //         setLoading(true)

    //         const res = await axios.put(`${process.env.API_URL}/api/${var_link}/${id}/update/`,
    //         data,
    //             {
    //                 headers : {
    //                     Authorization : `Bearer ${access_token}`,
    //                 },
    //             }
    //         );

    //         if (res.data){                
    //             setLoading(false);      
    //             setUpdated(true); 

    //             // setUpdatedMessage("Record Updated !!!")
    //         }
    //     }catch(error){

    //         setLoading(false);
    //         setUpdated(false);  

    //         setError(error.response && 
    //             (error.response.data.detail || error.response.data.error)
    //         );
    //     }
    // };    

    // // Code is ok using Authecation    
    // const deleteRecord = async (id, access_token, var_link) => {
    //     try{
    //         setLoading(true)            

    //         const res = await axios.put(`${process.env.API_URL}/api/${var_link}/${id}/delete/`,
    //         {},
    //             {
    //                 headers : {
    //                     Authorization : `Bearer ${access_token}`,
    //                 },
    //             }
    //         );

    //         if (res.status == 200){                
    //             setLoading(false);      
    //             setDeleted(true);  

    //             // setDeletedMessage("Record Deleted !!!")
    //         }
    //     }catch(error){
    //         setLoading(false);
    //         setDeleted(false);     

    //         setError(error.response && 
    //             (error.response.data.detail || error.response.data.error)
    //         );
    //     }
    // };

    // Code is ok using Authecation - Not Tested

    // const deleteRecordMultiple = async (id, access_token, var_link) => {
    //     try{
    //         setLoading(true)            

    //         const res = await axios.put(`${process.env.API_URL}/api/${var_link}/${id}/delete/`,
    //         {},
    //             {
    //                 headers : {
    //                     Authorization : `Bearer ${access_token}`,
    //                 },
    //             }
    //         );

    //         if (res.status == 200){                
    //             setLoading(false);      
    //             setDeleted(true);  

    //             setDeletedMessage("Record Deleted !!!")
    //         }
    //     }catch(error){
    //         setLoading(false);
    //         setDeleted(false);     

    //         setError(error.response && 
    //             (error.response.data.detail || error.response.data.error)
    //         );
    //     }
    // };

    return (

        <CRUDContext.Provider
            value={{
                pk_id,
                loading,
                error,
                created,
                updated,
                deleted,
                createRecord,
                setPkID,
                setCreated,
                setUpdated,
                setDeleted,
                clearErrors,

                deletedMessage,
                createdMessage,
                updatedMessage,

                user,
                isAuthenticated,
                login,
                register,
                updateProfile,
                logout,
                updateProfileForMe,
                registerForMe,

                viewRecord,
                setviewRecord,

                whenNulldeleteMessage,
                whenNullViewRecordMessage,
                whenMultipleViewRecordMessage,
                whenPageRefreshMessage,   
                whenStudentNotFoundMessage, 
                whenNoRecordFoundMessage,
                
                whenTablePaginationLastPage,
                whenTablePaginationFirstPage,

                WhenRecordNotFoundToDelete,
                WhenRecordNotFoundToUpdate,

                v_allow_open,
                v_allow_read,
                v_session_expire_msg,
                v_access_denied_msg,
                v_new_open,
                v_update_open,
                v_delete_open,

                v_toast_position,
                v_toast_autoClose,
                v_toast_hideProgressBar,
                v_toast_closeOnClick,
                v_toast_pauseOnHover,
                v_toast_draggable,
                v_toast_progress,
                v_toast_theme,
    
            }}
        >
            {children}
        </CRUDContext.Provider>
    )
}

export default CRUDContext;