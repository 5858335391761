import {combineReducers} from 'redux';
import {reducer, GlobalData, TempData, DDBranchBoardData, DDMonthData, DDStudentTypeData, DDMediumData, DDStStreamData, DDFeeCategData,
    DDFeePaymentTermData,
    DDStudentClassData,
    DDGenderData,
    DDReligionData,
    DDCasteCategData,
    DDCasteData,
    DDPreviousSchoolData,
    DDPreviousClassData,
    DDStudentSectionData,
    DDAppForData,
    DDOccupationData,
    DDJobNatureData,
    DDRelationTypeData,
    DDTablePerPageData,    
    DDFeeCodeData,
    DDFeeMonthSeqWiseData,
    DDFeeGroupTypeData,
} from './reducer';


// import { userreducer } from './userreducer';

export default combineReducers({
    reducer,
    GlobalData,
    TempData,
    DDBranchBoardData,
    DDMonthData,
    DDStudentTypeData,
    DDMediumData,
    DDStStreamData,
    DDFeeCategData,
    DDFeePaymentTermData,
    DDStudentClassData,
    DDGenderData,
    DDReligionData,
    DDCasteCategData,
    DDCasteData,
    DDPreviousSchoolData,
    DDPreviousClassData,
    DDStudentSectionData,
    DDAppForData,
    DDOccupationData,
    DDJobNatureData,
    DDRelationTypeData,
    DDTablePerPageData,
    DDFeeCodeData,
    DDFeeMonthSeqWiseData,
    DDFeeGroupTypeData,
})
