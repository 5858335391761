// Table name : auth_user

import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';

// import Layout from '../components/Layout';

const Nu = (props) => {
    const dispatch = useDispatch();
    const ReduxData = useSelector((state) => state);

    const [newbtnshowhide, setnewbtnshowhide]= useState(1);    
    const [savebtnshowhide, setsavebtnshowhide]= useState(1);
    const [deletebtnshowhide, setdeletebtnshowhide]= useState(1);
    const [printbtnshowhide, setprintbtnshowhide]= useState(1);
    const [exportexcelbtnshowhide, setexportexcelbtnshowhide]= useState(1);
    const [exportpdfbtnshowhide, setexportpdfbtnshowhide]= useState(1);
    const [findbtnshowhide, setfindbtnshowhide]= useState(1);

    const [disableSave, setdisableSave] = useState(0);
    const [disableDelete, setdisableDelete] = useState(0);

    const [ShowModelFormDeleteUpdate, setModelFormDeleteUpdate] = useState(false);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);
    const [updateDelTag, setUpdateDelTag] = useState('');
    const [updateDelapiName, setUpdateDelapiName] = useState('');
    // const router = useRouter();   

    const navigate = useNavigate();

    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');
    
    const [user_name, setuser_name] = useState('') 
    const [under_comp_group_id, setunder_comp_group_id] = useState(0)
    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0)
    const [password, setpassword] = useState('')
    const [notes, setnotes] = useState('') 
    const [show_in_list, setShow_in_list] = useState(1)
    const [active_status, setactive_status] = useState(1)
    
    const [under_group_codeItems, setunder_group_codeItems] = useState([]);
    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);

    const YesNoList = [
        // { id: 0, sel_val: 0, sel_desc: "" },
        { id: 1, sel_val: 1, sel_desc: "Yes" },
        { id: 2, sel_val: 2, sel_desc: "No" }
    ];

    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,        
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        viewRecord,
        setviewRecord,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenPageRefreshMessage
    } = useContext(CRUDContext);

    // ////////////////////////////

    const getAuthroziedUser = async () => {  
        try{
            const token = localStorage.getItem('token');

            const response = await axios.get(`verifyTokenAPI/`,
                  { headers: { Authorization: `Bearer ${token}` } });            
    
            if (response.status === 401 || response.status === 403){
                localStorage.clear();
                navigate('/Login')
            };

        }catch(error){
            localStorage.clear();
            navigate('/Login')
        }
    }    

    useEffect( () => {
        getAuthroziedUser();    
    }, []);

    // ////////////////////////////

    useEffect(() => { 
        
        setnewbtnshowhide(1);        
        setsavebtnshowhide(1);
        setdeletebtnshowhide(1);
        setprintbtnshowhide(2);
        setexportexcelbtnshowhide(2);
        setexportpdfbtnshowhide(2);
        setfindbtnshowhide(1);

        setdisableSave(false);
		setdisableDelete(true);

        // to fill the drop-down

        let response = []
        let updatedata = []

        const getTableRecordFunction = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_group_codeItems(updatedata);
        }
        getTableRecordFunction('url_comp_group_other_view_drop_down');

        // to fill the drop-down        

        const getTableRecordFunctionBranch = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_comp_branchItems(updatedata);
        }
        getTableRecordFunctionBranch('url_comp_branch_view_drop_down');

    }, []);

    useEffect(() => {

        if (error) {
            toast.error(error);
            clearErrors();
        }

        if (created) {
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();            
		    setdisableDelete(false);
            setviewRecord(false);

            setsaveUpdateTag('UPDATE');
        }

        if (updated) {
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
            setviewRecord(false);
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
            setviewRecord(false);

            setdisableSave(true);
            setdisableDelete(true);
        }
    }, [error, created, updated, deleted]);

    useEffect(() => {            

        // to get the data when user click on view to show the existing record (from cg_view page)

        if (pk_id > 0 && viewRecord) {           

            let response = []
            let updatedata = []

            // console.log("in pk_id is : ", pk_id)

            const getAllTableRecordFunction = async (var_link) => {
                response = await axios.get(`${var_link}/${pk_id}`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

                updatedata = await response.data.v_out_data;

                // console.log("updatedata is : ", updatedata) 
                
                setPkID(pk_id);

                setuser_name(updatedata[0].user_name);
                setunder_comp_group_id(updatedata[0].under_comp_group_id);
                setunder_comp_branch_id(updatedata[0].under_comp_branch_id);
                setpassword(updatedata[0].password);
                setnotes(updatedata[0].notes);
                setShow_in_list(updatedata[0].Show_in_list);
                setactive_status(updatedata[0].active_status);

                if (updatedata[0].active_status === 1){
                    setdisableSave(false);
                    setdisableDelete(false);
                }else
                {
                    setdisableSave(true);
                    setdisableDelete(true);
                }

                setsaveUpdateTag('UPDATE');

             }
             getAllTableRecordFunction('url_auth_user_view');
        }

    }, [pk_id])

    function setBlankFields()
    {
        setPkID(0);

        setsaveUpdateTag('SAVE');

        setuser_name('') 
        setunder_comp_group_id(0)
        setunder_comp_branch_id(0)
        setpassword('')
        setnotes('') 
        setShow_in_list(1);        
    }

    const newBlankFieldHandler = (e) => {
        e.preventDefault();
        setBlankFields();

        setdisableSave(false);
		setdisableDelete(true);
		setviewRecord(false);
    }

    const createRecordFunction = (event) => {
        event.preventDefault();

        if (user_name.trim().length === 0) {
            toast.info("Entry reuired for user name !")
            clearErrors();
            return;
        }

        // console.log("under_comp_group_id : ", under_comp_group_id)
        // console.log("show_in_list : ", show_in_list)

        if (under_comp_group_id === 0 || under_comp_group_id.length === 0) {
            toast.info("Entry reuired for group name !")
            clearErrors();
            return;
        }

        if (password.trim().length === 0) {
            toast.info("Entry reuired for password !")
            clearErrors();
            return;
        }

        if (show_in_list === undefined || show_in_list.length === 0) {
            toast.info("Entry reuired for show in list !")
            clearErrors();
            return;
        }      

        const data = {
            id: pk_id,

            user_name, 
            under_comp_group_id, 
            under_comp_branch_id : 0, 
            password, 
            notes, 
            active_status, 
            show_in_list,
        };

        dispatch(emptyCart());
        dispatch(addTempList(data));

        // console.log("pk_id value is : ", pk_id)

        // used when updation is not allow. we will disable the save button after first time click.
        // setdisableSave(true); 

        if (pk_id === 0) {                                
            setUpdateDelTag('')
            setModelModelFormYesNo(true);
        }
        else {
            setdisableDelete(false);
            setsaveUpdateTag('UPDATE')
            setUpdateDelTag('UPDATE')
            setUpdateDelapiName('url_auth_user_updel')
            setModelFormDeleteUpdate(true);           
        }
    }

    const FindInTablePage = (event) => {
        event.preventDefault();
        setviewRecord(false);
        navigate('/nu_view')
    }

    const handleDeleteUpdateFunction = (event) => {
        event.preventDefault();

        if (pk_id === 0) {
            toast.info("Record not found !")
            clearErrors();
            return;
        }

        const data = {
            id: pk_id,
        };

        setUpdateDelTag('DELETE')
        setUpdateDelapiName('url_auth_user_updel')

        dispatch(emptyCart());
        dispatch(addTempList(data));
        setModelFormDeleteUpdate(true);
    }

    const handleUnderGroupCodeOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setunder_comp_group_id(getId);
    }

    const handleUnderBranchOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setunder_comp_branch_id(getId);
    }

    const handleShowInListOption = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setShow_in_list(getId);
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();
        setPkID(0);
        navigate('/brms')
    }

    const handlePrintButtonFunction = (event) => {
        event.preventDefault();
        
        if (pk_id === 0) {
            toast.info("Record not found !")
            clearErrors();
            return;
        }
    }

    const handleExportExcelButtonFunction = (event) => {
        event.preventDefault();
        
        if (pk_id === 0) {
            toast.info("Record not found !")
            clearErrors();
            return;
        }
    }

    const handleExportPDFButtonFunction = (event) => {
        event.preventDefault();

        if (pk_id === 0) {
            toast.info("Record not found !")
            clearErrors();
            return;
        }
    }

    function handleEnter(event) {
        if (event.keyCode === 13) {
          const form = event.target.form;
          const index = Array.prototype.indexOf.call(form, event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
        }
    }

    return (
        <>
            <form>
            <div className="pt-PaddingTopAfterMenu max-w-full text-my-main-heading-fontSize bg-my-main-heading-color text-my-main-heading-font-color text-center">Admin (Single) User Registration For Each New Client</div>
        
            <div className="justify-center items-center inset-0 flex">

                <div className="pt-1 px-2">

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">User Name</label>
                    <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                        name="user_name"
                        id="user_name"
                        type="text"
                        // placeholder="Group Code"
                        value={user_name}
                        onChange={(e) => setuser_name(e.target.value)}
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}
                        onKeyDown={handleEnter} {...props}
                    ></input>

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Under Company Group</label>
                    <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                        name="under_comp_group_id" value={under_comp_group_id} onChange={(e) => handleUnderGroupCodeOption(e)}
                        onKeyDown={handleEnter} {...props}>
                        {/* <option>--Select Under Company Group--</option> */}
                        <option></option>
                        {
                            under_group_codeItems.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>                    

                    {/* <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Under Branch</label>
                    <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                        name="under_comp_branch_id" value={under_comp_branch_id} onChange={(e) => handleUnderBranchOption(e)}
                        onKeyDown={handleEnter} {...props}>                        
                        <option></option>
                        {
                            under_comp_branchItems.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select> */}
                    
                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Password</label>
                    <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                        name="password"
                        id="password"
                        type="text"
                        // placeholder="Password"
                        value={password}
                        onChange={(e) => setpassword(e.target.value)}
                        onKeyDown={handleEnter} {...props}
                    ></input>

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Notes</label>
                    <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                        name="notes"
                        id="notes"
                        type="text"
                        // placeholder="Notes"
                        value={notes}
                        onChange={(e) => setnotes(e.target.value)}
                        onKeyDown={handleEnter} {...props}
                    ></input>                    

                    <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Show-in-List</label>
                    <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                        name="show_in_list" value={show_in_list} onChange={(e) => handleShowInListOption(e)}
                        onKeyDown={handleEnter} {...props}>                            
                        {/* <option>--Select Option--</option> */}
                        <option></option>
                        {
                            YesNoList.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>

                                                

                    <div className="pt-4 py-my-padding-py px-my-padding-px grid grid-cols-5 gap-4">
                        
                        { newbtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={newBlankFieldHandler}>
                                New
                            </button>
                        )}                        

                        { savebtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                disabled={disableSave}
                                onClick={createRecordFunction}>
                                Save
                            </button>
                        )}
                            
                        { deletebtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                disabled={disableDelete}
                                onClick={handleDeleteUpdateFunction}>
                                Delete
                            </button>
                        )} 
                        
                        { printbtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={handlePrintButtonFunction}>
                                Print
                            </button>
                        )} 

                        { exportexcelbtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={handleExportExcelButtonFunction}>
                                Export Excel
                            </button>
                        )}

                        { exportpdfbtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={handleExportPDFButtonFunction}>
                                Export PDF
                            </button>
                        )} 
                        
                        { findbtnshowhide === 1 && (
                            <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={FindInTablePage}>
                                Find
                            </button>
                        )} 

                        <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                            onClick={goPrePageFunction}>
                            Back
                        </button>

                    </div>

                    <ModelFormDeleteUpdate api_type={updateDelTag} api_name={updateDelapiName} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)} />

                    <ModelFormYesNo table_name='AUTH_USER' api_name={'url_auth_user_new'} isVisible={ShowModelFormYesNo} onClose={() => setModelModelFormYesNo(false)} />

                </div>
            </div>
            </form>
        </>
    );
};

export default Nu;

