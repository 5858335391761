import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import CRUDContext from '../components/CRUDContext';

import Cookies from 'js-cookie';

import { emptyGlobalDataFun, setGlobalDataFun, emptyDDBranchBoardFun, setDDBranchBoardFun, emptyDDMonthFun, setDDMonthFun, emptyDDStudentTypeFun, setDDStudentTypeFun,
    emptyDDMediumFun, setDDMediumFun, setDDStStreamFun, emptyDDStStreamFun, setDDFeeCategFun, emptyDDFeeCategFun,
    setDDFeePaymentTermFun, 
    emptyDDFeePaymentTermFun,
    setDDStudentClassFun,
    emptyDDStudentClassFun,
    setDDGenderFun,
    emptyDDGenderFun,
    setDDReligionFun,
    emptyDDReligionFun,
    setDDCasteCategFun,
    emptyDDCasteCategFun,
    setDDCasteFun,
    emptyDDCasteFun,
    setDDPreviousSchoolFun,
    emptyDDPreviousSchoolFun,
    setDDPreviousClassFun,
    emptyDDPreviousClassFun,
    setDDStudentSectionFun,
    emptyDDStudentSectionFun,
    setDDAppForFun,
    emptyDDAppForFun,
    setDDOccupationFun,
    emptyDDOccupationFun,
    setDDJobNatureFun,
    emptyDDJobNatureFun,
    setDDRelationTypeFun,
    emptyDDRelationTypeFun,
    emptyDDTablePerPageFun,
    setDDTablePerPageFun, 
    setDDFeeCodeFun,
    emptyDDFeeCodeFun,
    setDDMonthSeqWiseFun,
    emptyDDMonthSeqWiseFun,    
    setDDFeeGroupTypeFun,
    emptyDDFeeGroupTypeFun
} from '../components/redux/actions';

const ChangeBranchFinYear = (props) => {
    const v_token = Cookies.get('token');

    let response = [], updatedata = [];
    const dispatch = useDispatch();   

    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0);
    const [fy, setfy] = useState(0);     

    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);
    const [fyItems, setfyItems] = useState([]);

    const ReduxGlobalData = useSelector((state) => state.GlobalData);

    const {                
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,
    } = useContext(CRUDContext); 

    useEffect(() => {        

        const getTableRecordFunctionBranch = async () => {            

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_user_wise_branch_view_drop_down/38/0/0/${ReduxGlobalData[0].id}`,
                { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            setunder_comp_branchItems(updatedata);

            // console.log("updatedata branch >> ", response.data)

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_comp_group_branch_wise_drop_down/38/${ReduxGlobalData[0].ag}/0/FY`,
                { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            setfyItems(updatedata);

            // console.log("updatedata setfyItems >> ", response.data)
            
        }
        getTableRecordFunctionBranch();
    
    }, []);
    
    const sendDataToParentFun = (event) => {
        event.preventDefault();
        
        const { name, value } = event.target;
        
        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.error('Entry required for Branch name !', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(fy === undefined || fy.length === undefined || fy.length === 0){
            toast.error('Entry required for Financial year !', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        const getAllTableRecordFunction= async()=>{
            
            // store the data in redux for global uses
              
            // url_param_view_branch_wise_drop_down

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/MONTH`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            dispatch(emptyDDMonthFun());
            dispatch(setDDMonthFun(updatedata));
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/BOARD`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDBranchBoardFun());
            dispatch(setDDBranchBoardFun(updatedata));
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/ST_TYPE`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDStudentTypeFun());
            dispatch(setDDStudentTypeFun(updatedata));

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/MEDIUM`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDMediumFun());
            dispatch(setDDMediumFun(updatedata));

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/ST_STREAM`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDStStreamFun());
            dispatch(setDDStStreamFun(updatedata));

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/FEE_CATEG`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDFeeCategFun());
            dispatch(setDDFeeCategFun(updatedata));

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/FEE_PAY_TERM`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDFeePaymentTermFun());
            dispatch(setDDFeePaymentTermFun(updatedata));

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/ST_CLASS`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDStudentClassFun());
            dispatch(setDDStudentClassFun(updatedata));
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/GENDER`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDGenderFun());
            dispatch(setDDGenderFun(updatedata));
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/RELIGION`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDReligionFun());
            dispatch(setDDReligionFun(updatedata));

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/CAST_CATEG`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDCasteCategFun());
            dispatch(setDDCasteCategFun(updatedata));
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/CASTE`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDCasteFun());
            dispatch(setDDCasteFun(updatedata));
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/PRE_SCH`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDPreviousSchoolFun());
            dispatch(setDDPreviousSchoolFun(updatedata));
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/PRE_SCH_CL`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDPreviousClassFun());
            dispatch(setDDPreviousClassFun(updatedata));
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/ST_SEC`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDStudentSectionFun());
            dispatch(setDDStudentSectionFun(updatedata));
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${under_comp_branch_id}/0/FEE_CODE`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDFeeCodeFun());
            dispatch(setDDFeeCodeFun(updatedata));

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_FeeMonthSeqFun/38/${under_comp_branch_id}/0/0`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            dispatch(emptyDDMonthSeqWiseFun());
            dispatch(setDDMonthSeqWiseFun(updatedata));
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_get_dd_value/38/${under_comp_branch_id}/0/TAB_PER_PAGE`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDTablePerPageFun());
            dispatch(setDDTablePerPageFun(updatedata));

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_only_new_old_drop_down/38/${under_comp_branch_id}/0/APP_FOR`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDAppForFun());
            dispatch(setDDAppForFun(updatedata));

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_drop_down/38/${under_comp_branch_id}/0/OCCUP`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDOccupationFun());
            dispatch(setDDOccupationFun(updatedata));

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_drop_down/38/${under_comp_branch_id}/0/JOB_NATURE`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDJobNatureFun());
            dispatch(setDDJobNatureFun(updatedata));

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_drop_down/38/${under_comp_branch_id}/0/REL_TYPE`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDRelationTypeFun());
            dispatch(setDDRelationTypeFun(updatedata));
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_st_fee_coll_group_id_fun/38/${under_comp_branch_id}/0/'NA`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            dispatch(emptyDDFeeGroupTypeFun());
            dispatch(setDDFeeGroupTypeFun(updatedata));            

            // end of push like global data for dropdown and student list

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_set_br_default_fun/38/${under_comp_branch_id}/${fy}/0`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;

            if (updatedata.length > 0)
            {   
                localStorage.setItem('br', under_comp_branch_id); // branch_id
                localStorage.setItem('fy', fy); // financial year        

                localStorage.setItem('br_name', updatedata[0].v_branchname); // branch name
                localStorage.setItem('fy_name', updatedata[0].v_fyname); // financial year name

                localStorage.setItem('brd', updatedata[0].v_board); // board
                localStorage.setItem('st', updatedata[0].v_student_type); // student_type
                localStorage.setItem('medi', updatedata[0].v_medium); // medium
                localStorage.setItem('stem', updatedata[0].v_stream); // stream                
                localStorage.setItem('fcat', updatedata[0].v_fee_categ); // fee_categ
                localStorage.setItem('ptm', updatedata[0].v_pay_term); // pay_term
                localStorage.setItem('fcg', updatedata[0].v_param_st_fee_coll_group_id); // param_st_fee_coll_group_id from br_wise_fee_group_mat_view
                localStorage.setItem('paym', updatedata[0].v_def_pay_mode); // payment mode

                // push the data to redux

                // get the existing data from redux then push new data

                // console.log("ReduxGlobalData >> ", ReduxGlobalData[0].id);
                // console.log("ReduxGlobalData >> ", ReduxGlobalData[0].user);
                // console.log("ReduxGlobalData >> ", ReduxGlobalData[0].ag);
                // console.log("ReduxGlobalData >> ", ReduxGlobalData[0].token);

                const global_data = {
                    id: ReduxGlobalData[0].id, // user_id
                    user: ReduxGlobalData[0].user, // user_name
                    ag: ReduxGlobalData[0].ag, // company group id
                    token: ReduxGlobalData[0].token, // token

                    br:under_comp_branch_id, // branch_id
                    fy:fy, // financial year id

                    br_name:updatedata[0].v_branchname, // branch name
                    fy_name:updatedata[0].v_fyname, // financial year name

                    brd:updatedata[0].v_board, // board
                    st:updatedata[0].v_student_type, // student_type
                    medi:updatedata[0].v_medium, // medium
                    stem:updatedata[0].v_stream, // stream 
                    fcat:updatedata[0].v_fee_categ, // fee_categ
                    ptm:updatedata[0].v_pay_term, // pay_term
                    fcg:updatedata[0].v_param_st_fee_coll_group_id, // param_st_fee_coll_group_id from br_wise_fee_group_mat_view
                    paym:updatedata[0].v_def_pay_mode, // payment mode
                    report_head_1:updatedata[0].v_report_head_1, 
                    report_head_2:updatedata[0].v_report_head_2, 
                    report_head_3:updatedata[0].v_report_head_3, 
                    report_head_4:updatedata[0].v_report_head_4, 
				    exam_report_head_1:updatedata[0].v_exam_report_head_1, 
                    exam_report_head_2:updatedata[0].v_exam_report_head_2, 
                    exam_report_head_3:updatedata[0].v_exam_report_head_3, 
				    exam_report_head_4:updatedata[0].v_exam_report_head_4,
                }

                // console.log("ReduxGlobalData global_data >> ", global_data);
    
                dispatch(emptyGlobalDataFun());
                dispatch(setGlobalDataFun(global_data));

            }
        }
        getAllTableRecordFunction();

        SelectedDataFunction(value);
    }

    const SelectedDataFunction = (p_value) => {
        
        // const data = {ModelYesNoAnswer:p_value, transType:props.transType};

        const data = {};

        props.setModelFalseFunction(data);
    }

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        // console.log('in handleInputValue function')

        handleInputValueFun(name, value);        
    }

    const handleInputValueFun = (name, value) => {

        if (name === 'under_comp_branch_id') {
            setunder_comp_branch_id(value);
            return;  
        }else if (name === 'fy') {
            setfy(value);
            return;      
        }
    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        // to be used for show pop single or multi select

        // const { name, value } = event.target;        
    };
    
return (

<div>           
    <h1 className="h2_model_saveupdel_yes_css">Please select required option !</h1>

    <div>        
        <label htmlFor="under_comp_branch_id" className="label_inner_page_css required_css">Branch</label>
        <select className="input_inner_page_css"
            // ref={(ref) => addInputRef(ref, 9)}                
            autoFocus
            name="under_comp_branch_id"
            id="under_comp_branch_id"
            value={under_comp_branch_id}
            // placeholder="under_comp_branch_id"                    
            onChange={handleInputValue}                
            // onKeyDown={handleEnter}
            onFocus={handleInputFocus}
            // onBlur={handleInputBlur}                            
        >
            <option></option>
            {
                under_comp_branchItems?.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
        </select>
    </div>

    <div>        
        <label htmlFor="under_comp_branch_id" className="label_inner_page_css required_css">Financial Year</label>
        <select className="input_inner_page_css"                
            // ref={(ref) => addInputRef(ref, 9)}                
            // autoFocus
            name="fy"
            id="fy"
            value={fy}
            // placeholder="fy"                    
            onChange={handleInputValue}                
            // onKeyDown={handleEnter}
            onFocus={handleInputFocus}
            // onBlur={handleInputBlur}                            
        >
            <option></option>
            {
                fyItems?.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
        </select>
    </div>  

    <div className="btn_div_model_inner_css">
        <div className="btn_div_model_saveupdel_css">
            <button className="btn_model_saveupdel_yes_css" type="submit" value = 'YES' onClick={sendDataToParentFun}>Save</button>
        </div>      
    </div>
    
</div>

);
};

export default ChangeBranchFinYear;
