import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from './GlobalFunction';
import { useDispatch, useSelector } from 'react-redux';
import SelectRecordPopUpSingleColumn from './SelectRecordPopUpSingleColumn';
import CRUDContext from './CRUDContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

import { faSortAlphaDownAlt, faSortAlphaDown } from '@fortawesome/free-solid-svg-icons';
import { emptyGlobalDataFun } from './redux/actions';

const SearchStudentDetails = (props) => {
    const inputRefs = useRef([]);
    let v_toast_msg = '';    
    const [userAuthData, setUserAuthData] = useState([]);    
    const [ascWise, setAscWise] = useState(false);
    
    const [conditionalCSS, setConditionalCSS] = useState(false);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // standard items define for table populate

    const [items, setItems] = useState([]);
    const [origionalData, setOrigionalData] = useState([]);
    const [filterItems, setFilterItems] = useState([]);
    const [FilterStatus, setFilterStatus] = useState(false);

    // items search list items
    
    const [st_classItems, setst_classItems] = useState([]);
    const [st_secItems, setst_secItems] = useState([]);

    // define query input

    const [queryAdmNo, setQueryAdmNo] = useState('');
    const [queryStName, setQueryStName] = useState('');
    const [queryStClass, setQueryStClass] = useState([]);
    const [queryStSec, setQueryStSec] = useState([]);
    
    // show and hide pop-up

    const [ShowPopUpSt_Class, setShowPopUpSt_Class] = useState(false);
    const [ShowPopUpSt_Sec, setShowPopUpSt_Sec] = useState(false);   

    // save ID and Desc

    const [IDArraySt_Class, setIDArraySt_Class] = useState([]);
    const [IDArraySt_Sec, setIDArraySt_Sec] = useState([]);

    const [IDArray, setIDArray] = useState([]);
    const [IDArrayValue, setIDArrayValue] = useState([]);

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData);
    const ReduxDDStudentClassData = useSelector((state) => state.DDStudentClassData);
    const ReduxDDStudentSectionData = useSelector((state) => state.DDStudentSectionData);
    
    // define form name and navigation details

    const [sortingColumn, setSortingColumn] = useState(["st_name"]);    
    const v_backToNavigateName = '/Dashboard';
    const v_frm_no = 9;

    let v_input_result;

    // define standard errors and entry required messages

    const {       
        v_allow_open,        
        v_session_expire_msg,
        v_access_denied_msg,        
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,
    } = useContext(CRUDContext);

    useEffect(() => {

        async function loadData() {
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);          

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());
                
                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_read === 2) {
                toast.info(v_allow_open, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                navigate(`${v_backToNavigateName}`);
                return;
            } else {
                ShowRecordFun();
            }
        }
        loadData();
    }, [ReduxGlobalData[0]]);

    const ShowRecordFun = async () => {

        setst_classItems(ReduxDDStudentClassData[0]);
        setst_secItems(ReduxDDStudentSectionData[0]);                               

        SetPaginationItems(props.p_st_items);
        setOrigionalData(props.p_st_items);
    }

    const handleInputValueFun = (name, value) => {
        let v_col_length = 0, searchdata = origionalData;        
        
        if (name === 'queryAdmNo') {

            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);
            setQueryAdmNo(v_input_result.v_value);

            v_col_length = v_input_result.toString().trim().length + queryStClass.toString().trim().length +
                queryStSec.toString().trim().length + queryStName.toString().trim().length;

            // console.log("Before searchdata length >> ", searchdata.length);            

            if (v_input_result.v_value.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.adm_no.toString().toLowerCase().includes(v_input_result.v_value.toString().toLowerCase()));
            }

            if (queryStClass.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => queryStClass.includes(item.st_class));
            }

            if (queryStSec.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => queryStSec.includes(item.st_sec));
            }

            if (queryStName.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.st_name.toString().toLowerCase().includes(queryStName.toString().toLowerCase()));
            }

            // console.log("After searchdata length >> ", searchdata.length);
        } else if (name === 'queryStClass') {

            v_col_length = queryAdmNo.toString().trim().length + value.toString().trim().length +
                queryStSec.toString().trim().length + queryStName.toString().trim().length;

            // console.log("St Class Before searchdata length >> ", value.length);

            if (queryAdmNo.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.adm_no.toString().toLowerCase().includes(queryAdmNo.toString().toLowerCase()));
            }

            if (value.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => value.includes(item.st_class));
            }

            if (queryStSec.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => queryStSec.includes(item.st_sec));
            }

            if (queryStName.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.st_name.toString().toLowerCase().includes(queryStName.toString().toLowerCase()));
            }

            // console.log("After searchdata length >> ", searchdata.length);

        } else if (name === 'queryStSec') {

            v_col_length = queryAdmNo.toString().trim().length + queryStClass.toString().trim().length +
                value.toString().trim().length + queryStName.toString().trim().length;

            // console.log("Before searchdata length >> ", searchdata.length);

            // console.log("Before queryStSec >> ", value);

            if (queryAdmNo.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.adm_no.toString().toLowerCase().includes(queryAdmNo.toString().toLowerCase()));
            }

            if (queryStClass.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => queryStClass.includes(item.st_class));
            }

            if (value.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => value.includes(item.st_sec));
            }

            if (queryStName.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.st_name.toString().toLowerCase().includes(queryStName.toString().toLowerCase()));
            }

            // console.log("After searchdata length >> ", searchdata.length);

        } else if (name === 'queryStName') {

            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setQueryStName(capitalizeWordFun(v_input_result.v_value));

            v_col_length = queryAdmNo.toString().trim().length + queryStClass.toString().trim().length +
                queryStSec.toString().trim().length + v_input_result.v_value.toString().trim().length;

            // console.log("Before searchdata length >> ", searchdata.length);            

            if (queryAdmNo.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.adm_no.toString().toLowerCase().includes(queryAdmNo.toString().toLowerCase()));
            }

            if (queryStClass.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => queryStClass.includes(item.st_class));
            }

            if (queryStSec.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => queryStSec.includes(item.st_sec));
            }

            if (v_input_result.v_value.toString().trim().length > 0) {
                searchdata = searchdata.filter((item) => item.st_name.toString().toLowerCase().includes(v_input_result.v_value.toString().toLowerCase()));
            }

            // console.log("After searchdata length >> ", searchdata.length);            
        }

        setFilterItems(searchdata);

        if (v_col_length > 0) {
            setFilterStatus(true);
            SetPaginationItems(searchdata);
        } else {
            setFilterStatus(false);
            SetPaginationItems(origionalData);
        }

    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        // to be used for show pop single or multi select

        const { name, value } = event.target;

        if (name === 'queryStClass') {
            setShowPopUpSt_Class(true);
            setShowPopUpSt_Sec(false);

            setConditionalCSS(true);

            return;
        } else if (name === 'queryStSec') {
            setShowPopUpSt_Sec(true);
            setShowPopUpSt_Class(false);

            setConditionalCSS(true);

            return;
        } else {
            setShowPopUpSt_Sec(false);
            setShowPopUpSt_Class(false);
            return;
        }

    };

    const setPopUpDDFalseFunction = (p_data) => {
        // console.log("Set False >> ", p_data);      

        if (p_data.selectedColumnName === "queryStClass") {
            setIDArraySt_Class(p_data.SelectedIDArray);
            setQueryStClass(p_data.SelectedIDArrayValue);
            setShowPopUpSt_Class(false);

            setConditionalCSS(false);

            handleInputValueFun("queryStClass", p_data.SelectedIDArrayValue);
        }

        if (p_data.selectedColumnName === "queryStSec") {
            setIDArraySt_Sec(p_data.SelectedIDArray);
            setQueryStSec(p_data.SelectedIDArrayValue);
            setShowPopUpSt_Sec(false);

            setConditionalCSS(false);

            handleInputValueFun("queryStSec", p_data.SelectedIDArrayValue);
        }

    }


    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        handleInputValueFun(name, value);
    }

    const SetPaginationItems = (p_items) => {      
        setItems(p_items);
    }

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        let tempListID = [], tempListValue = [];

        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, ischecked: checked } });
            setItems(checkedvalue);

            setIDArray([]);
            setIDArrayValue([]);

            if (checked) {
                for (let i = 0; i < items.length; i++) {
                    tempListID.push(items[i].id);
                    // tempListValue.push(items[i].st_class);
                }
            }
        }
        else {
            const checkedvalue = items.map((user) => user.id.toString() === name ? { ...user, ischecked: checked } : user);
            setItems(checkedvalue);

            tempListID = Object.assign(IDArray);
            tempListValue = Object.assign(IDArrayValue);

            // const filteredData = items.filter((item) => item.id.includes(name));

            const filteredData = items.find(item => {return item.id === name});          

            // console.log("In handleChangeCheckBox Click filteredData : ", filteredData);

            if (filteredData.ischecked) {
                const index = tempListID.indexOf(name);
                tempListID.splice(index, 1);
                tempListValue.splice(index, 1);
            } else {
                tempListID.push(filteredData.id);
                // tempListValue.push(filteredData[0]["st_class"]);
            }
        }        
    }

    const onClickTableRowFun = (event, p_id, p_adm_no, p_st_name, p_fat_name, p_st_class, p_st_sec, p_st_stream) => {
        event.preventDefault();        

        // console.log("When Click on SHOW_RECORD >> ", p_value, p_id);        

        const temp_data = {
            tempStudentid: p_id,
            tempAdmNo: p_adm_no,
            tempStudentName: p_st_name,
            tempFatherName: p_fat_name,
            tempClass: p_st_class,
            tempSec: p_st_sec,
            tempStream: p_st_stream,
        }        

        props.setStudentModelFalseFunction(temp_data);

    };   

    const addInputRef = (ref, index) => {
        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    const sortByColumn = (column) => {

        let tempData = [];

        if (FilterStatus) {
            tempData = filterItems;
        } else {
            tempData = origionalData;
        }

        let sortData = [];

        if (sortingColumn?.includes(column)) {
            sortData = tempData.slice().sort((a, b) => b[column].toString().trim().localeCompare(a[column].toString().trim()));
            setItems(sortData);
            setSortingColumn([]);
            setAscWise(true);
        } else {
            sortData = tempData.slice().sort((a, b) => a[column].toString().trim().localeCompare(b[column].toString().trim()));
            setItems(sortData);
            setSortingColumn([`${column}`]);
            setAscWise(false);
        }

        // setActiveColumn([`${column}`]);
        // setCurrentPage(0);        
    }    

    const CheckFilterOptions = (event) => {
        event.preventDefault();

        // -------------------------------------------------------------------------------------------------

        // Get one column 

        // let extractedValue = items.map(function(item) {return item["st_name"]});
        // console.log(extractedValue)

        // -------------------------------------------------------------------------------------------------

        // const filteredData = origionalData.filter((item) => item.st_class.includes(['K.G.']));

        // const filteredData = origionalData.find(obj => {return obj.st_class === 'K.G.'});

        // const filtered2 = employees.filter(employee => {
        //     return employee.country === 'Canada' && employee.id === 3;
        // });

        // --------------------------------------------------------------------------------------------------

        // var  people =[
        //     { "id": 1, "name": "abc", "gender": "m","age": "15" },
        //     { "id": 2, "name": "a", "gender": "m", "age": "25"  },
        //     { "id": 3,"name": "efg", "gender": "f","age": "5" },
        //     { "id": 4,"name": "hjk","gender": "m","age": "35" },
        //     {  "id": 5, "name": "ikly","gender": "m","age": "41" },
        //     { "id": 6, "name": "ert", "gender": "f", "age": "30" },
        //     { "id": 7, "name": "qwe", "gender": "f", "age": "31" },
        //     { "id":8, "name": "bdd",  "gender": "m", "age": " 8" }
        //   ];
        //   var id_filter = [1,4,5,8];
        //   var filtered = people.filter(function(item) {
        //       return id_filter.indexOf(item.id) !== -1 && item.gender==='m';
        //   });
        //   console.log(filtered);

        // --------------------------------------------------------------------------------------------------

        // Filter data using multiple condition (1 method - using include)

        // const selectedClass = ['K.G.', 'P.Nur.', 'I'];
        // const selectedSec = ['A', 'B'];      

        // var filtered = origionalData.filter((item) => selectedClass.includes(item.st_class) && selectedSec.includes(item.st_sec));

        // console.log(filtered);

        // --------------------------------------------------------------------------------------------------

        // Filter data using multiple condition (2 method - using filter)

        // Filter data using multiple condition

        // const selectedClass = ['K.G.', 'P.Nur.'];
        // const selectedSec = ['A', 'B'];      

        // var filtered = origionalData.filter(function(item) {
        //     return selectedClass.indexOf(item.st_class) !== -1 && selectedSec.indexOf(item.st_sec) !== -1;
        // });
        // console.log(filtered);

        // --------------------------------------------------------------------------------------------------      

    }

return (        
<>

    {
        ShowPopUpSt_Class ?
            <div className="Show_SelectRecordPopUpSingleColumn_css">
                <SelectRecordPopUpSingleColumn setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                    items={st_classItems}
                    columnName='queryStClass'
                    PropsIDArray={IDArraySt_Class}
                    PropsIDArrayValueStr={queryStClass} />
            </div>
            : null
    }

    {
        ShowPopUpSt_Sec ?
            <div className="Show_SelectRecordPopUpSingleColumn_css">
                <SelectRecordPopUpSingleColumn setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                    items={st_secItems}
                    columnName='queryStSec'
                    PropsIDArray={IDArraySt_Sec}
                    PropsIDArrayValueStr={queryStSec} />
            </div>
            : null
    }

    <form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off"> 
        <table className="table_main_css">
            <thead className="table_thead_css">
                <tr className="table_thead_tr_css">                  
                    
                    <th className="table_th_td_css text-center w-[10%]">Adm.No.</th>

                    <th className="table_th_td_css text-left w-[24%]" onClick={() => sortByColumn("st_name")}>
                        {
                            ascWise ? <FontAwesomeIcon className="fai_css" icon={faSortAlphaDownAlt} />
                                :
                                <FontAwesomeIcon className="fai_css" icon={faSortAlphaDown} />
                        }
                        Name
                    </th>

                    <th className="table_th_td_css text-left w-[25%]" onClick={() => sortByColumn("fat_name")}>
                        {
                            ascWise ? <FontAwesomeIcon className="fai_css" icon={faSortAlphaDownAlt} />
                                :
                                <FontAwesomeIcon className="fai_css" icon={faSortAlphaDown} />
                        }
                        Father Name
                    </th>

                    <th className="table_th_td_css text-left w-[8%]" onClick={() => sortByColumn("st_class")}>
                        {
                            ascWise ? <FontAwesomeIcon className="fai_css" icon={faSortAlphaDownAlt} />
                                :
                                <FontAwesomeIcon className="fai_css" icon={faSortAlphaDown} />
                        }
                        Class
                    </th>

                    <th className="table_th_td_css text-left w-[6%]" onClick={() => sortByColumn("st_sec")}>
                        {
                            ascWise ? <FontAwesomeIcon className="fai_css" icon={faSortAlphaDownAlt} />
                                :
                                <FontAwesomeIcon className="fai_css" icon={faSortAlphaDown} />
                        }
                        Sec.
                    </th>

                    <th className="table_th_td_css text-left w-[10%]" onClick={() => sortByColumn("st_stream")}>
                        {
                            ascWise ? <FontAwesomeIcon className="fai_css" icon={faSortAlphaDownAlt} />
                                :
                                <FontAwesomeIcon className="fai_css" icon={faSortAlphaDown} />
                        }
                        Stream
                    </th>

                    <th className="table_th_td_css text-left w-[17%]">Status</th>

                </tr>
            </thead>

            <thead className="table_thead_css">
                <tr className="table_thead_tr_css">                   

                    <th className="table_th_td_css w-[10%]">

                        <input className="input_inner_page_css text-center"
                            type="text"
                            ref={(ref) => addInputRef(ref, 0)}
                            autoFocus
                            name="queryAdmNo"
                            id="queryAdmNo"
                            value={queryAdmNo}
                            // placeholder="Adm.No."                    
                            onChange={handleInputValue}
                            // onKeyDown={handleEnter}
                            onFocus={handleInputFocus}
                            // onBlur={handleInputBlur}
                            // disabled
                        ></input>
                    </th>                    

                    <th className="table_th_td_css w-[24%]">
                        <input className="input_inner_page_css"
                            type="text"
                            ref={(ref) => addInputRef(ref, 1)}
                            name="queryStName"
                            id="queryStName"
                            value={queryStName}
                            // placeholder="Student Name"                    
                            onChange={handleInputValue}
                            // onKeyDown={handleEnter}
                            onFocus={handleInputFocus}
                        // onBlur={handleInputBlur}
                        ></input>
                    </th>                    

                    <th className="table_th_td_css w-[25%]"></th>

                    <th className="table_th_td_css w-[8%]">
                        <input className="input_inner_page_css"
                            type="text"
                            ref={(ref) => addInputRef(ref, 2)}
                            name="queryStClass"
                            id="queryStClass"
                            value={queryStClass}
                            // placeholder="Class"                    
                            onChange={handleInputValue}
                            // onKeyDown={handleEnter}
                            onFocus={handleInputFocus}
                        // onBlur={handleInputBlur}
                        ></input>
                    </th>

                    <th className="table_th_td_css w-[6%]">
                        <input className="input_inner_page_css"
                            type="text"
                            ref={(ref) => addInputRef(ref, 3)}
                            name="queryStSec"
                            id="queryStSec"
                            value={queryStSec}
                            // placeholder="Sec."                    
                            onChange={handleInputValue}
                            // onKeyDown={handleEnter}
                            onFocus={handleInputFocus}
                        // onBlur={handleInputBlur}
                        ></input> 
                    </th>

                    <th className="table_th_td_css w-[10%]"></th>
                    <th className="table_th_td_css w-[17%]"></th>

                </tr>
            </thead>

            <tbody className="table_tbody_css bg-yellow-100">
            {
                items?.map((item, index) => (
                    <tr className="table_thead_tr_css table_tbody_hover_css" key={item.id} >

                        {/* <td className="table_th_td_css text-center w-[10%]" 
                            onClick={(e) => onClickTableRowFun(e, item.id, item.adm_no, item.st_name, item.fat_name, item.st_class, item.st_sec, item.st_stream)}>
                        {index + 1}</td> */}


                        {/* <td className="table_th_td_css text-center w-[10%]" 
                            onClick={(e) => onClickTableRowFun(e, item.id, item.adm_no, item.st_name, item.fat_name, item.st_class, item.st_sec, item.st_stream)}>
                        {item.id}</td> */}

                        <td className="table_th_td_css text-center w-[10%]" 
                            onClick={(e) => onClickTableRowFun(e, item.id, item.adm_no, item.st_name, item.fat_name, item.st_class, item.st_sec, item.st_stream)}>
                        {item.adm_no}</td>

                        <td className="table_th_td_css text-left w-[24%]" 
                            onClick={(e) => onClickTableRowFun(e, item.id, item.adm_no, item.st_name, item.fat_name, item.st_class, item.st_sec, item.st_stream)}>
                        {item.st_name}</td>
                        
                        <td className="table_th_td_css text-left w-[25%]" 
                            onClick={(e) => onClickTableRowFun(e, item.id, item.adm_no, item.st_name, item.fat_name, item.st_class, item.st_sec, item.st_stream)}>
                        {item.fat_name}</td>

                        <td className="table_th_td_css text-left w-[8%]" 
                            onClick={(e) => onClickTableRowFun(e, item.id, item.adm_no, item.st_name, item.fat_name, item.st_class, item.st_sec, item.st_stream)}>
                        {item.st_class}</td>
                        
                        <td className="table_th_td_css text-center w-[6%]" 
                            onClick={(e) => onClickTableRowFun(e, item.id, item.adm_no, item.st_name, item.fat_name, item.st_class, item.st_sec, item.st_stream)}>
                        {item.st_sec}</td>                        
                        
                        <td className="table_th_td_css text-left w-[10%]" 
                            onClick={(e) => onClickTableRowFun(e, item.id, item.adm_no, item.st_name, item.fat_name, item.st_class, item.st_sec, item.st_stream)}>
                        {item.st_stream}</td>
                        
                        <td className="table_th_td_css text-left w-[17%]" 
                            onClick={(e) => onClickTableRowFun(e, item.id, item.adm_no, item.st_name, item.fat_name, item.st_class, item.st_sec, item.st_stream)}>
                        {item.left_status}</td>

                    </tr>
                ))
            }
            </tbody>
        </table>

</form>
</>
);
};

export default SearchStudentDetails;
