import React from "react";

const Dashboard = () =>{
    return (
        <div className="mt-64 flex justify-center bg-slate-400 text-black font-bold">
            
            {/* <div>
                <picture>
                    <source media="(min-width:650px)" srcset="img_pink_flowers.jpg">
                    <source media="(min-width:465px)" srcset="img_white_flower.jpg">
                    <img src="img_orange_flowers.jpg" alt="Flowers" style="width:auto;">
                </picture>
            </div> */}

        </div>
    )
}

export default Dashboard;


