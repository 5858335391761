import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const SearchListDropdown = (props) => {

    // Single Selection for Both Table and Individual
    // Multiple Selection for Both Table and Individual

    // props.columnType should be as below

    // SINGLE_TABLE    

    // SINGLE_NOT_TABLE

    // MULTIPLE_TABLE

    // MULTIPLE_NOT_TABLE

    const inputRefs = useRef([]);

    const [IDArray, setIDArray] = useState([]);
    const [IDArrayValue, setIDArrayValue] = useState([]);
    
    // const [ID, setID] = useState(0);
    // const [IDValue, setIDValue] = useState('');    

    const [items, setItems] = useState([]);
    const [origionalData, setOrigionalData] = useState([]);    

    const [querySelDesc, setQuerySelDesc] = useState('');

    useEffect(() => {

        // console.log("In Selection Box")

        if (props.columnType === 'SINGLE_TABLE' || props.columnType === 'SINGLE_NOT_TABLE'){
            handleInputValue('querySelDesc', props.PropsIDArrayValueStr[0]);
        }

        if (props.columnType === 'MULTIPLE_TABLE' || props.columnType === 'MULTIPLE_NOT_TABLE'){
            if(props.PropsIDArrayValueStr.toString().trim().length > 0){
                const newItems = props.items.map(obj => {
                    if (props.PropsIDArray.find((element) => element === obj.id) != undefined) {
                        return { ...obj, ischecked: true };
                    }
                    return obj;
                });
        
                setItems(newItems);
                setIDArray(props.PropsIDArray);
                setIDArrayValue(props.PropsIDArrayValueStr);
            }else{
                setItems(props.items);                
            }
            setOrigionalData(props.items);
        }
    
    }, [props.PropsIDArrayValueStr[0]]);

    const handleInputValue = (name, value) => {
        // event.preventDefault();
        // const { name, value } = event.target;

        let v_col_length = 0, searchdata = [];
       
        if (name === 'querySelDesc') {
            v_col_length = value.toString().trim().length;
            searchdata = props.items.filter((item) => item.sel_desc.toLowerCase().includes(value.toLowerCase()));                     
            setQuerySelDesc(value);
        }

        // //////////////////////////////////////////////////////////

        if (v_col_length > 0 && searchdata.length === 0){
            SelectedDataFunction(0, '', 'NO_DATA_FOUND');

            // const v_str = value.toString().substr(0, value.toString().length -1);

            // console.log("v_str >> ", v_str);

            // SelectedDataFunction(0, v_str, 'NO_DATA_FOUND');
        }

        if (searchdata.length === 1){            
            SelectedDataFunction(searchdata[0].id, searchdata[0].sel_desc, 'CALL_WHEN_KEY_PRESS');            
        }

        if (searchdata.length > 1){            
            SelectedDataFunction(0, value, 'CALL_WHEN_KEY_PRESS');            
        }
        
        // //////////////////////////////////////////////////////////

        if (v_col_length > 0){
            SetPaginationItems(searchdata); 
        }else{
            SetPaginationItems(props.items);                                 
        }

        // console.log("searchdata >> ", searchdata)

        // SetPaginationItems(props.items);
    }

    const SetPaginationItems = (p_items) => {
        setItems(p_items);     
        ResetCheckBoxFunction(p_items);        
    }
       
    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        let tempListID = [], tempListValue = [];

        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, ischecked: checked } });
            setItems(checkedvalue);

            setIDArray([]);
            setIDArrayValue([]);            

            if (checked) {
                for (let i = 0; i < items.length; i++) {
                    tempListID.push(items[i].id);
                    tempListValue.push(items[i].sel_desc);
                }
            }
        }
        else {
            const checkedvalue = items.map((user) => user.id.toString() === name ? { ...user, ischecked: checked } : user);
            setItems(checkedvalue);

            tempListID = Object.assign(IDArray);
            tempListValue = Object.assign(IDArrayValue);

            const filteredData = items.filter((item) => item.id.includes(name));

            // console.log("In Table Row Click filteredData : ", filteredData);

            if (filteredData[0]["ischecked"]) {
                const index = tempListID.indexOf(name);
                tempListID.splice(index, 1);
                tempListValue.splice(index, 1);
            } else {
                tempListID.push(filteredData[0]["id"]);
                tempListValue.push(filteredData[0]["sel_desc"]);
            }
        }        
    }

    const onClickTableRowFun = (event, p_id, p_sel_desc) => {
        event.preventDefault();        
        ischeckedTick(p_id, p_sel_desc);
    };

    const ischeckedTick = (p_id, p_sel_desc) => {

        let v_check_value = true;

        // console.log("p_id >> ", p_id, items);

        const filteredData = items.filter((item) => item.id.toString().includes(p_id));

        if (filteredData[0]["ischecked"]) {
            v_check_value = false;
            const index = IDArray.indexOf(p_id);
            IDArray.splice(index, 1);
            IDArrayValue.splice(index, 1);
        } else {
            v_check_value = true;
            IDArray.push(filteredData[0]["id"]);
            IDArrayValue.push(filteredData[0]["sel_desc"]);
        }

        // console.log("p_id >> ", p_id, p_sel_desc);

        for (let i = 0; i < items.length; i++) {
            const checkedvalue = items.map((user) => user.id.toString() === p_id.toString() ? { ...user, ischecked: v_check_value } : user);
            setItems(checkedvalue);
        }

        setQuerySelDesc(p_sel_desc);
        
        // setID(p_id);
        // setIDValue(p_sel_desc);

        SelectedDataFunction(p_id, p_sel_desc, 'WHEN_MOUSE_CLICK_ON_TABLE_ROW');

    }     

    const ResetCheckBoxFunction = (p_sortData) => {

        // console.warn("ResetCheckBoxFunction >> ", IDArray)

        const newItems = p_sortData.map(obj => {

            // if (obj.id === '490') {
            //     return {...obj, ischecked: true};
            // }            

            if (IDArray.find((element) => element === obj.id) != undefined) {
                return { ...obj, ischecked: true };
            }
            return obj;
        });

        setItems(newItems);

        // console.log(newState);
    }     
    
    const sendDataToParentFun = (e) => {
        e.preventDefault();

        SelectedDataFunction(0, '', 'WHEN_MOUSE_CLICK_ON_TABLE_ROW');
    }

    const SelectedDataFunction = (p_id, p_sel_desc, p_calling_fun_type) => {        

        let data, tempIDArrayStr = '', tempIDArrayValueStr = '';

        // console.log("IDArray >> ", IDArray)
        // console.log("IDArrayValue >> ", IDArrayValue)

        if (props.columnType === 'SINGLE_TABLE' || props.columnType === 'SINGLE_NOT_TABLE'){
            data = {SelectedIDArray:p_id, SelectedIDArrayValue : p_sel_desc, selectedColumnName:props.columnName, columnType: props.columnType, p_items:items, p_calling_fun_type};
        }
        
        if (props.columnType === 'MULTIPLE_TABLE' || props.columnType === 'MULTIPLE_NOT_TABLE'){

            for (let i = 0; i < IDArray.length; i++) {
                if (i === 0) {
                    tempIDArrayStr = IDArray[i];
                    tempIDArrayValueStr = IDArrayValue[i];
                } else {
                    tempIDArrayStr = tempIDArrayStr + "," + IDArray[i];
                    tempIDArrayValueStr = tempIDArrayValueStr + "," + IDArrayValue[i];
                }
            }

            data = {SelectedIDArray:IDArray, SelectedIDArrayValue : IDArrayValue, selectedColumnName:props.columnName, columnType: props.columnType, p_items:items, p_calling_fun_type,
                    SelectedIDArrayStr:tempIDArrayStr, SelectedIDArrayValueStr : tempIDArrayValueStr};
        }        

        if (p_calling_fun_type === 'NO_DATA_FOUND'){
            props.WhenNoDataFoundFunction(data);
        }        

        if (p_calling_fun_type === 'CALL_WHEN_KEY_PRESS'){
            props.onBlurSetDataFunction(data);
        }
        
        if (p_calling_fun_type === 'WHEN_MOUSE_CLICK_ON_TABLE_ROW'){
            props.setPopUpDDFalseFunction(data);
        }

    }

    const addInputRef = (ref, index) => {
        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    }; 

    const CheckValue = (event) => {
        event.preventDefault();

        // SelectedDataFunction();

        // console.log("When lost focus");

        // inputRefs.current[4].focus();

        // console.warn("IDArray >> ", IDArray);
        // console.warn("IDArrayValue >> ", IDArrayValue);        

        // ResetCheckBoxFunction(items);
    }

    const handleBlur = (event) => {
        console.log('Input field lost focus');
    };
    
return (

<div className='border-none'>         

    {/* <table className="table_main_css h-[30%] absolute"> */}

    <table className="table_main_css">
        {
            (props.columnType === 'MULTIPLE_TABLE' || props.columnType === 'MULTIPLE_NOT_TABLE') ? 
            <thead className="table_thead_dd_css w-[100%]">
                <tr className="table_thead_tr_css">                
                    
                    <th className="table_th_td_css w-[12%]">
                        <input className="table_check_inner_page_css"
                            type="checkbox" name="allselect" checked={!items.some((user) => user?.ischecked !== true)}
                            onChange={handleChangeCheckBox}
                            // onFocus={handleInputFocus}
                        />
                    </th>

                    <th className="table_th_td_css w-[82%]"><FontAwesomeIcon icon={faCheck} size="2xl" onClick={sendDataToParentFun} /></th>
                    
                </tr>
            </thead>
            :
            null
        }

        <tbody className="table_tbody_css bg-yellow-100 h-[200px] w-[100%] overflow-scroll">
            {
                items?.map((item, index) => (                            
                <tr className="table_thead_tr_css table_tbody_hover_css" key={item.id}>

                    {
                        (props.columnType === 'MULTIPLE_TABLE' || props.columnType === 'MULTIPLE_NOT_TABLE') ? 
                        <>
                        <td className="table_th_td_css w-[14%]">
                            <input className="table_check_inner_page_css"
                                ref={(ref) => addInputRef(ref, index + 1)}
                                type="checkbox" name={item.id}
                                checked={item?.ischecked || false}
                                onChange={handleChangeCheckBox}
                                // onFocus={handleFocus}                            
                            />
                        </td>
                            <td className="table_th_td_css text-left w-[100%]">{item.sel_desc}</td>
                        </>
                        :
                        <td className="table_th_td_css text-left w-[100%]" onClick={(e) => onClickTableRowFun(e, item.id, item.sel_desc, item.isChecked)}>{item.sel_desc}</td>
                    }
                        
                </tr>
                ))
            }
        </tbody>
    </table>                   

</div>
);
};

export default SearchListDropdown;
