// student ac_vou_no_setting_branch_wise
// using new css

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ModelFormYesNo from '../components/ModelFormYesNo';

import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';

// import Layout from '../components/Layout';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

import Paramtable from "../components/Paramtable";

// import { CSVLink } from "react-csv";

const Frsbw = () => {
    const dispatch = useDispatch();
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);  
    
    const navigate = useNavigate();

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);    

    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0);
    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);
    
    const [reset_monthItems, setreset_monthItems] = useState([]);

    const [copy_under_comp_branch_id, copy_setunder_comp_branch_id] = useState(0);

    const [apiName, setapiName] = useState('')

    const YesNoList = [
        { id: 0, SEL_VAL: 0, sel_desc: "" },
        { id: 1, SEL_VAL: 1, sel_desc: "Yes" },
        { id: 2, SEL_VAL: 2, sel_desc: "No" }
    ];

    let list1 = [], v_calculate_list = [];
    
    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,        
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        viewRecord,
        setviewRecord,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenPageRefreshMessage,
        whenNoRecordFoundMessage,
        whenStudentNotFoundMessage,
    } = useContext(CRUDContext);


    // ////////////////////////////

    // const getAuthroziedUser = async () => {  
    //     try{
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //               { headers: { Authorization: `Bearer ${token}` } });            
    
    //         if (response.status === 401 || response.status === 403){
    //             localStorage.clear();
    //             navigate('/Login')
    //         };

    //     }catch(error){
    //         localStorage.clear();
    //         navigate('/Login')
    //     }
    // }    

    // useEffect( () => {        
    //     getAuthroziedUser();    
    // }, []);

    // ////////////////////////////

    useEffect(() => {
        if (error) {
            toast.error(error);
            clearErrors();
        }

        if (created) {
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
            // setdisableDelete(false);
            setviewRecord(false);

            // setsaveUpdateTag('UPDATE');
        }

        if (updated) {
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
            setviewRecord(false);
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
            setviewRecord(false);

            // setdisableSave(true);
            // setdisableDelete(true);
        }
    }, [error, created, updated, deleted]);
    
    useEffect(() => {
        // to fill the drop-down

        let response = []
        let updatedata = []

        // to fill the drop-down  
        
        const getTableRecordFunctionBranch = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_comp_branchItems(updatedata);
        }
        getTableRecordFunctionBranch('url_comp_branch_view_drop_down');
        
        const getParamTableRecordFunctionBranch = async (var_link) => {            
            
            response = await axios.get(`${var_link}/AC_VOU_TYPE`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setreset_monthItems(updatedata);
        }
        getParamTableRecordFunctionBranch('url_param_view_drop_down');

        setBlankFields();

    }, []);
    
    useEffect(() => {
        handleShowRecordOption();        
    }, [error, created, updated, deleted]);

    const handleShowRecordOption = async () => {
        
        let response = [];
        let updatedata = [];        

        response = await axios.get(`url_fee_fine_setting_branch_wise/${under_comp_branch_id}/`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

        updatedata = await response.data.v_out_data;

        if (updatedata.length === 0){
            toast.error(whenNoRecordFoundMessage);
            clearErrors();
            return;
        }

        // console.log("updatedata >> ", updatedata)
   
        list1 = updatedata?.map((item, i) => (
            {"id":item.id, "under_comp_branch_id" : item.under_comp_branch_id  || '',             
            "cal_seq" : item.cal_seq || '', 
            "reset_month" : item.reset_month || '', 
            "from_day" : item.from_day || '',  
            "to_day" : item.to_day || '',  
            "amount" : item.amount || '',              
            "notes" : item.notes || '',
            "active_status" : item.active_status || '', 
            "t1_id" : i}
        ));

        // under_comp_branch_id, reset_month, from_day, to_day, amount, notes, active_status,

        setTabID(list1.length);        
        setItems(list1);       
    } 
    
    function setBlankFields() {
        setPkID(0);

        setItems([]);
    }

    const createRecordFunction = (event) => {
        event.preventDefault();

        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.error("Entry required for under branch !");
            clearErrors();
            return;
        }

        // console.log("data is : ", items)

        setapiName('url_fee_fine_setting_branch_wise_new');
        
        dispatch(emptyCart());
        dispatch(addTempList(items));
        setModelModelFormYesNo(true);
    }
    
    const handleUnderBranchOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        setunder_comp_branch_id(getId);
        
        setItems([]);
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();
        navigate('/brms')
    }

    const CheckDataFunction = (event) => {
        event.preventDefault();
        
        console.log("Items is : ", items)

    }    

    // Add row. when click on add row button

    const addItem = () => {
        setTabID(tabID + 1);
        setItems([...items, { "id": 0, cal_seq : '' ,reset_month: '', from_day : '', to_day : '', amount : '',
                            notes: '', 
                            active_status: 1 , under_comp_branch_id : under_comp_branch_id, fin_year : 0,
                            t1_id: tabID }]);
    };

    const handlerChange = (event, i) => {
        event.preventDefault();
        const { name, value } = event.target;
        // const list = [...items];        
        // list[i][name] = value;
        // setItems(list);

        let v_value = value;        

        if (name === 'cal_seq' || name === 'from_day' || name === 'to_day' || name === 'amount')
        {
            v_value = event.target.value.replace(/\D/g, "");
        }

        const list = [...items];
        list[i][name] = v_value;
        setItems(list);
    };

    const handleCopyToRecordOption = (event) => {
        event.preventDefault();
        
        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.error("Entry required for Under Branch !");
            clearErrors();
            return;
        }

        if(copy_under_comp_branch_id === undefined || copy_under_comp_branch_id.length === undefined || copy_under_comp_branch_id.length === 0){
            toast.error("Entry required for Copy Under Branch !");
            clearErrors();
            return;
        }

        if (under_comp_branch_id === copy_under_comp_branch_id){
            toast.error("From Branch and To Branch can not be same !");
            clearErrors();
            return;
        }
        
        const data = {

            copy_from_under_comp_branch_id : under_comp_branch_id, 
            copy_to_under_comp_branch_id : copy_under_comp_branch_id,
            
        };

        dispatch(emptyCart());
        dispatch(addTempList(data));      

        // used when updation is not allow. we will disable the save button after first time click.
        // setdisableSave(true);

        setapiName('copy_to_url_fee_fine_setting_branch_wise_new_proc')
        
        setModelModelFormYesNo(true);
    }

    const handleDeleteToRecordOption = (event) => {
        event.preventDefault();
        
        if(copy_under_comp_branch_id === undefined || copy_under_comp_branch_id.length === undefined || copy_under_comp_branch_id.length === 0){
            toast.error("Entry required for Delete Under Branch !");
            clearErrors();
            return;
        }        
        
        const data = {            
            delete_from_under_comp_branch_id : copy_under_comp_branch_id,            
        };

        dispatch(emptyCart());
        dispatch(addTempList(data));      

        // used when updation is not allow. we will disable the save button after first time click.
        // setdisableSave(true);

        setapiName('delete_from_url_fee_fine_setting_branch_wise_new_proc')
        
        setModelModelFormYesNo(true);
    }

    const copy_handleUnderBranchOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        copy_setunder_comp_branch_id(getId);        
    }

    return (

<>

<div className="pt-PaddingTopAfterMenu pl-1 flex justify-start text-MainHeadingFontSize text-MainHeadingFontColor bg-MainHeadingBGColor">Fee Fine Master Branch Wise</div>
        <div className="mt-2 grid lg:grid-cols-12">

            <label className="max-w-full text-my-font-color font-myFontWeight">Under Branch</label>        
            <select className="w-full col-span-3 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="under_comp_branch_id" value={under_comp_branch_id} onChange={(e) => handleUnderBranchOption(e)}
                >
                <option></option>
                {
                    under_comp_branchItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>

            <button className="w-full ml-2 bg-ViewRowBtnBGColor hover:bg-ViewRowBtnHoverColor text-ViewRowBtnFontSize text-ViewRowBtnFontColor font-ViewRowBtnFontName rounded-none"
				onClick={handleShowRecordOption}
			>Show</button> 

            {/* start coding for copy and delete */}

            <label className="col-span-2 ml-3 max-w-full text-my-font-color font-myFontWeight">Copy/ Delete To Branch</label>
            <select className="col-span-3 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="copy_under_comp_branch_id" value={copy_under_comp_branch_id} onChange={(e) => copy_handleUnderBranchOption(e)}
                >
                <option></option>
                {
                    under_comp_branchItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>

            <button className="ml-2 bg-ViewRowBtnBGColor hover:bg-ViewRowBtnHoverColor text-ViewRowBtnFontSize text-ViewRowBtnFontColor font-ViewRowBtnFontName rounded-none"
				onClick={handleCopyToRecordOption}
			>Copy To</button>

            <button className="ml-2 bg-ViewRowBtnBGColor hover:bg-ViewRowBtnHoverColor text-ViewRowBtnFontSize text-ViewRowBtnFontColor font-ViewRowBtnFontName rounded-none"
				onClick={handleDeleteToRecordOption}
			>Delete</button>

            {/* end coding for copy and delete */}
               
        </div>
        
        <div className="mt-2 border-TabBorderWidth border-TabHeadingBorderColor overflow-y-scroll">          
            
            <div className="grid lg:grid-cols-12">

                <div className="pl-PaddingLeft text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Cal.Seq.
                </div>

                <div className="pl-PaddingLeft text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Reset on Month
                </div>
                
                <div className="pl-PaddingLeft text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    From Day
                </div>
        
                <div className="pl-PaddingLeft text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    To Day
                </div>

                <div className="pl-PaddingLeft text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Amount
                </div>
                
                <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-7">
                    Notes
                </div>

            </div>
        </div>
        
        <div className="border-TabBorderWidth border-TabBorderColor h-InsertTableHeight overflow-y-scroll">
            {
                items?.map((item, i) => (
        
                    <div className="grid grid-cols-12" key={item.t1_id}>

                        {/* under_comp_branch_id, reset_month, from_day, to_day, amount, notes, active_status, */}

                        <input className="w-[100%] text-center pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="cal_seq"
                            value={item.cal_seq}
                            onChange={(e) => handlerChange(e, i)}                                
                        />   

                        <Paramtable paramItems={YesNoList} columnName={'reset_month'} fun1={(e) => handlerChange(e, i)} columnNameValue={item.reset_month} />
                        
                        <input className="w-[100%] text-center pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="from_day"
                            value={item.from_day}
                            onChange={(e) => handlerChange(e, i)}                                
                        />                        

                        <input className="w-[100%] text-center pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="to_day"
                            value={item.to_day}
                            onChange={(e) => handlerChange(e, i)}                                
                        />

                        <input className="w-[100%] text-center pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="amount"
                            value={item.amount}
                            onChange={(e) => handlerChange(e, i)}                                
                        />                        
                        
                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-6"
                            type="text"
                            name="notes"
                            value={item.notes}
                            onChange={(e) => handlerChange(e, i)}                                
                        />

                        {
                            item.id > 0 ? (
                                <>
                                    <button className="bg-AddRowButtonBGColor hover:bg-AddRowButtonHoverColor text-my-fontSize text-AddRowButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                        onClick={addItem}>New</button>
                                </>
                            ) : (
                                <div>
                                    {/* {item.id} */}
                                    <button className="w-[50%] bg-AddRowButtonBGColor hover:bg-AddRowButtonHoverColor text-my-fontSize text-AddRowButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                        onClick={addItem}>New</button>

                                    <button className="w-[50%] bg-DeleteRowButtonBGColor hover:bg-DeleteRowButtonHoverColor text-my-fontSize text-DeleteRowButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                        onClick={() => { setItems(items.filter(a => a.t1_id !== item.t1_id)); }}>
                                        Delete
                                    </button>
                                </div>
                            )
                        }

                    </div>
                ))
            }
        </div>
        
        <div className="mt-2 flex justify-center gap-4">        
            
            <button className="w-[10%] bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={addItem}>
                Add Row
            </button>             
            
            <button className="w-[10%] bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={createRecordFunction}>
                Save
            </button>        
        
            <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={goPrePageFunction}>
                Back
            </button>

            <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={CheckDataFunction}>
                Check Data
            </button>

        </div>     

        <ModelFormYesNo table_name='STANDARD' api_name={apiName} isVisible={ShowModelFormYesNo} onClose={() => setModelModelFormYesNo(false)} />

</>
);
};

export default Frsbw;
