// Form Name is corrrect
// Table name : ac_ledger_branch_wise
// using new css

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

// import { CSVLink } from "react-csv";

const EmployeeMasterBranchWise = () => {    
    const dispatch = useDispatch();    
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);   
    
    // const router = useRouter(); 

    const navigate = useNavigate();

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);    
    const [defaultdata, setdefaultdata]= useState([]);

    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0);
    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);    

    const [fin_year, setfin_year] = useState(0);
    const [fin_yearItems, setfin_yearItems] = useState([]);    

    const [apiName, setapiName] = useState('')

    const [copy_under_comp_branch_id, copy_setunder_comp_branch_id] = useState(0);
    const [copy_fin_year, copy_setfin_year] = useState(0);
  
    let list1 = [];
    
    const {
        pk_id,
        loading,                
        error,  
        created,
        updated,
        deleted,    
        createRecord,       
        setPkID,               
        setCreated,
        setUpdated,                                   
        setDeleted,
        clearErrors,

        deletedMessage, 
        createdMessage, 
        updatedMessage,    
        whenNulldeleteMessage,
        whenNoRecordFoundMessage,
    } = useContext(CRUDContext);    

    // ////////////////////////////

    // const getAuthroziedUser = async () => {  
    //     try{
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //               { headers: { Authorization: `Bearer ${token}` } });            
    
    //         if (response.status === 401 || response.status === 403){
    //             localStorage.clear();
    //             navigate('/Login')
    //         };

    //     }catch(error){
    //         localStorage.clear();
    //         navigate('/Login')
    //     }
    // }    

    // useEffect( () => {        
    //     getAuthroziedUser();    
    // }, []);

    // ////////////////////////////


    useEffect(() => { 
        if (error){
          toast.error(error);
          clearErrors();
        }

        if (created){
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
        }

        if (updated){
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
        }

        if (deleted){
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
        }
    }, [error, created, updated, deleted]);    

    useEffect(() =>{       

        let response = []
        let updatedata = []

        const getTableRecordFunctionBranch = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_comp_branchItems(updatedata);
        }
        getTableRecordFunctionBranch('url_comp_branch_view_drop_down');

        // const getParamTableRecordFunctionBranch = async (var_link) => {
        //     response = await axios.get(`${var_link}/FY`,
        //     { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        //     updatedata = await response.data.v_out_data
        //     setfin_yearItems(updatedata);           
        // }
        // getParamTableRecordFunctionBranch('url_param_view_drop_down');

    }, [])

    useEffect(() =>{
        showRecordFunction();
    }, [created]);
    
    const createRecordFunction = (event) => {
        event.preventDefault();

        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.error("Entry required for under branch !");
            clearErrors();
            return;
        }

        const data = {            
            under_comp_branch_id,
            fin_year,
            toitems: Array(items),
        };

        // console.log("data is : ", data)

        dispatch(emptyCart());
        dispatch(addTempList(data));

        setapiName('url_emp_master_branch_wise_new');

        setModelModelFormYesNo(true)
    }

    const handlerChange = (event, i) => {
        event.preventDefault();
        const { name, value } = event.target;
        const list = [...items];        
        list[i][name] = value;
        setItems(list);

        // let v_value = value;

        // if (name === 'calculation_seq' || name === 'dis_seq' || name === 'row_insert')
        // {
        //     v_value = event.target.value.replace(/\D/g, "");            
        // }

        // const list = [...items];
        // list[i][name] = v_value;
        // setItems(list);
    };

    const showRecordFunction = async () => {
        if(under_comp_branch_id.length > 0)
        {

            // console.log("1 is ")

            const response = await axios.get(`url_emp_master_branch_wise_view_fun/${under_comp_branch_id}`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            // console.log("2 is ")

            let updatedata = await response.data.v_out_data;                

            console.log("updatedata is : ", updatedata)

            if (updatedata.length === 0){

                toast(whenNoRecordFoundMessage);
                clearErrors();

                updatedata = structuredClone(defaultdata);
                
                // console.log("When 0 defaultdata is : ", defaultdata)

                setTabID(updatedata.length);
                setItems(updatedata);             
                return;
            }else{                

                list1 = updatedata?.map((item, i) => (
                    {"id":item.v_tab_id || '', "emp_master_id" : item.v_emp_master_id || '',
                    "emp_master_id_name" : item.v_emp_master_id_name || '',
                    "dis_seq" : item.v_dis_seq || '',                    
                    "notes" : item.v_notes|| '', 
                    "isChecked" : item.v_show_in_list || '', 
                    "t1_id" : i}
                ));
    
                setTabID(list1.length);        
                setItems(list1);
            }            
            
        }else{
            setItems(defaultdata);
        }       
    }

    const handleShowRecordOption = (event) => {
        event.preventDefault();
        showRecordFunction();  
    }

    const copy_handleUnderBranchOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        copy_setunder_comp_branch_id(getId);        
    }

    const handleCopyToRecordOption = (event) => {
        event.preventDefault();
        
        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.error("Entry required for Under Branch !");
            clearErrors();
            return;
        }

        if(copy_under_comp_branch_id === undefined || copy_under_comp_branch_id.length === undefined || copy_under_comp_branch_id.length === 0){
            toast.error("Entry required for Copy Under Branch !");
            clearErrors();
            return;
        }

        if (under_comp_branch_id === copy_under_comp_branch_id){
            toast.error("From Branch and To Branch can not be same !");
            clearErrors();
            return;
        }

        // if (fin_year === copy_fin_year){
        //     toast.error("From Financial Year and To Financial Year can not be same !");
        //     clearErrors();
        //     return;
        // }
        
        const data = {

            copy_from_under_comp_branch_id : under_comp_branch_id, 
            copy_to_under_comp_branch_id : copy_under_comp_branch_id,

        };

        dispatch(emptyCart());
        dispatch(addTempList(data));      

        // used when updation is not allow. we will disable the save button after first time click.
        // setdisableSave(true);

        setapiName('copy_to_url_emp_master_branch_wise_proc')
        
        setModelModelFormYesNo(true);
    }

    const handleDeleteToRecordOption = (event) => {
        event.preventDefault();
        
        if(copy_under_comp_branch_id === undefined || copy_under_comp_branch_id.length === undefined || copy_under_comp_branch_id.length === 0){
            toast.error("Entry required for Delete Under Branch !");
            clearErrors();
            return;
        }        
        
        const data = {            
            delete_to_under_comp_branch_id : copy_under_comp_branch_id,   
            copy_to_fin_year : copy_fin_year,
        };

        dispatch(emptyCart());
        dispatch(addTempList(data));      

        // used when updation is not allow. we will disable the save button after first time click.
        // setdisableSave(true);
        
        setapiName('delete_to_url_emp_master_branch_wise')
        
        setModelModelFormYesNo(true);
    }
    
    const handleUnderBranchOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        setunder_comp_branch_id(getId);
        
        setItems([]);
    }

    const handleFinYearOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        setfin_year(getId);
        
        setItems([]);
    }
    
    const copy_handleFinYearOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        copy_setfin_year(getId);
        
        // setItems([]);
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();
        setPkID(0);
        navigate('/brms')
    }

    const goCheckDataFunction = (event) => {
        event.preventDefault();
        
        console.log("Items are : ", items)
    }

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;
        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, isChecked: checked } });
            setItems(checkedvalue);
        }
        else {
            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, isChecked: checked } : user);
            setItems(checkedvalue);
        }
    }

    return (

<>

<div className="pt-PaddingTopAfterMenu pl-1 flex justify-start text-MainHeadingFontSize text-MainHeadingFontColor bg-MainHeadingBGColor">Employee Master Branch Wise</div>
        <div className="mt-2">
            <label className="max-w-full text-my-font-color font-myFontWeight">Under Branch</label>        
            <select className="w-[20%] col-span-2 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="under_comp_branch_id" value={under_comp_branch_id} onChange={(e) => handleUnderBranchOption(e)}
                >
                <option></option>
                {
                    under_comp_branchItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>            

            <button className="w-[8%] ml-2 bg-ViewRowBtnBGColor hover:bg-ViewRowBtnHoverColor text-ViewRowBtnFontSize text-ViewRowBtnFontColor font-ViewRowBtnFontName rounded-none"
				onClick={handleShowRecordOption}
			>Show</button>

            {/* start coding for copy and delete */}

            <label className="max-w-full text-my-font-color font-myFontWeight">Copy/ Delete To Branch</label>
            <select className="w-[20%] col-span-2 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="copy_under_comp_branch_id" value={copy_under_comp_branch_id} onChange={(e) => copy_handleUnderBranchOption(e)}
                >
                <option></option>
                {
                    under_comp_branchItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
            
            <button className="w-[5%] ml-2 bg-ViewRowBtnBGColor hover:bg-ViewRowBtnHoverColor text-ViewRowBtnFontSize text-ViewRowBtnFontColor font-ViewRowBtnFontName rounded-none"
				onClick={handleCopyToRecordOption}
			>Copy To</button>

            <button className="w-[4%] ml-2 bg-ViewRowBtnBGColor hover:bg-ViewRowBtnHoverColor text-ViewRowBtnFontSize text-ViewRowBtnFontColor font-ViewRowBtnFontName rounded-none"
				onClick={handleDeleteToRecordOption}
			>Delete</button>

            {/* end coding for copy and delete */}

        </div>

        <div className="mt-2 border-TabBorderWidth border-TabHeadingBorderColor overflow-y-scroll">
            
            <div className="grid lg:grid-cols-12">

                <div className="text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-5">

                    <input className="w-[8%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor"
                             
                        type="checkbox" name="allselect" checked={!items.some((user) => user?.isChecked !== true)}
                        onChange={handleChangeCheckBox}
                    />

                    <label className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight  border-TabHeadingBorderColor bg-TabHeadBGColor
                            col-span-4 w-[92%]">
                        Exam Group Heading
                    </label>
                </div>

                <div className="pr-PaddingRight text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Dis.Seq.
                </div>
        
                <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-6">
                    Notes
                </div>

            </div>
        </div>
        
        <div className="border-TabBorderWidth border-TabBorderColor h-InsertTableHeight overflow-y-scroll">
            {
                items?.map((item, i) => (
                    <div className="grid grid-cols-12" key={item.t1_id}>

                        <div className="col-span-5">
                            <input className="w-[8%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                                "
                                type="checkbox" name={item.t1_id} checked={item?.isChecked || false}
                                onChange={handleChangeCheckBox}
                            />

                            <input className="w-[92%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                                col-span-4"
                                type="text"
                                name="emp_master_id_name"
                                value={item.emp_master_id_name}
                                onChange={(e) => handlerChange(e, i)}
                                disabled                                
                            />

                        </div>


                        <input className="w-[100%] text-center pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="dis_seq"
                            value={item.dis_seq}
                            onChange={(e) => handlerChange(e, i)}                                
                        />  

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-6"
                            type="text"
                            name="notes"
                            value={item.notes}
                            onChange={(e) => handlerChange(e, i)}                                
                        />                      

                    </div>
                ))
            }
        </div>
        
        <div className="mt-2 flex justify-center gap-4">
            <div className="w-[10%] text-center bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                >
                Record Found : {items.length}
            </div>  

            <button className="w-[10%] bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={createRecordFunction}>
                Save
            </button>        
        
            <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={goPrePageFunction}>
                Back
            </button>

            <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={goCheckDataFunction}>
                Check Data
            </button>
        
        
        </div>
        
        {/* <ModelFormDeleteUpdate api_type = {'UPDATE'} api_name={'url_fee_code_setting_branch_wise_updel_multiple'} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)} /> */}
       
        {/* <ModelFormYesNo table_name = 'fee_code_setting_branch_wise' api_name = {'url_fee_code_setting_branch_wise_updel_multiple'} isVisible = {ShowModelFormYesNo} onClose = {()=>setModelModelFormYesNo(false)} /> */}

        <ModelFormYesNo table_name = {'STANDARD'} api_name = {apiName} isVisible = {ShowModelFormYesNo} onClose = {()=>setModelModelFormYesNo(false)} />

</>
);
};

export default EmployeeMasterBranchWise;
