import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { GetFormAuthDetails } from '../components/GlobalFunction';
import { useDispatch, useSelector } from 'react-redux';
import SearchListDropdown from "../components/SearchListDropdown";
import CRUDContext from '../components/CRUDContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

import { useReactToPrint } from "react-to-print";

import { faFilePdf, faFileExcel, faPrint, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import { emptyGlobalDataFun } from '../components/redux/actions';

const StudentList = () => {
    const inputRefs = useRef([]);
    const conponentPDF = useRef();

    const [v_open_frm, setv_open_frm] = useState(false);
    let response = [], updatedata = [], v_toast_msg = '';
    const [userAuthData, setUserAuthData] = useState([]);
    const [conditionalCSS, setConditionalCSS] = useState(false);

    const [disableShow, setdisableShow] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // standard items define for table populate

    const [items, setItems] = useState([]);
    // const [origionalData, setOrigionalData] = useState([]);

    const [headingcolumn, setheadingcolumn] = useState([]);

    // save ID and Desc   


    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData);
    const ReduxDDStudentSectionData = useSelector((state) => state.DDStudentSectionData);
    const ReduxDDStudentTypeData = useSelector((state) => state.DDStudentTypeData);
    const ReduxDDStStreamData = useSelector((state) => state.DDStStreamData);
    const ReduxDDStudentClassData = useSelector((state) => state.DDStudentClassData);

    // define drop downs inputs

    const [stream, setstream] = useState([]);
    const [st_class, setst_class] = useState([]);
    const [st_sec, setst_sec] = useState([]);
    const [student_type, setstudent_type] = useState([]);

    const [stream_descr, setstream_descr] = useState('');
    const [st_class_descr, setst_class_descr] = useState('');
    const [st_sec_descr, setst_sec_descr] = useState('');
    const [student_type_descr, setstudent_type_descr] = useState('');

    const [stream_descrstr, setstream_descrstr] = useState('');
    const [st_class_descrstr, setst_class_descrstr] = useState('');
    const [st_sec_descrstr, setst_sec_descrstr] = useState('');
    const [student_type_descrstr, setstudent_type_descrstr] = useState('');

    const [ShowPopUp, setShowPopUp] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(false);

    const [print_datetime, setprint_datetime] = useState('');    

    // define form name and navigation details

    const v_urlShowReportData = '20_url_student_list_view_fun';
    const v_backToNavigateName = '/Dashboard';

    const v_main_form_heading = 'Student List (Selection)';
    const v_frm_no = 20;

    // define standard errors and entry required messages

    const {
        deletedMessage,
        whenNoRecordFoundMessage,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,
    } = useContext(CRUDContext);

    useEffect(() => {

        async function loadData() {
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if (result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR') {

                if (result.res_status === 'UN_AUTHROISED') { v_toast_msg = v_access_denied_msg; }
                if (result.res_status === 'ERROR') { v_toast_msg = v_session_expire_msg; }

                toast.info(v_toast_msg, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_read === 2) {
                toast.info(v_allow_open, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                navigate(`${v_backToNavigateName}`);
                return;
            } else {

                // if load allow then execute the below code

                setv_open_frm(true);

                setBlankFields();
            }
        }
        loadData();
    }, [ReduxGlobalData[0]]);

    function setBlankFields() {      

        setstudent_type_descr('');
        setstudent_type([]);
        setst_class([]);
        setst_class_descr('');
        setst_sec([]);
        setst_sec_descr('');
        setstream([]);
        setstream_descr('');

        setstream_descrstr('');
        setst_class_descrstr('');
        setst_sec_descrstr('');
        setstudent_type_descrstr('');

        setprint_datetime('');        

        setItems([]);
        setdisableShow(false);
    }

    const ShowRecordFun = async () => {

        setdisableShow(true);      

        let v_active_status = 1, v_null_parameter = 'NA'; // for active records
        
        // let v_student_type = 0;        

        // if (parseInt(ReduxGlobalData[0].st) === 0){
        //     if (student_type === undefined || student_type.length === undefined || student_type.length === 0) {
        //         v_student_type = 0;                
        //     } else {
        //         v_param_st_fee_coll_group_id = param_st_fee_coll_group_id;                
        //     }
        // }else{
        //     v_param_st_fee_coll_group_id = parseInt(ReduxGlobalData[0].fcg);
        // }
        
        const v_token = Cookies.get('token');        

        response = await axios.get(`url_fetch_data/${v_urlShowReportData}/${v_frm_no}/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
        /${Array(student_type)}
        /${Array(stream)}
        /${Array(st_class)}
        /${Array(st_sec)}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data;
      
        const returnVal = AuthenticationCheckDetailFun(await updatedata, 'READ');

        if (!returnVal) {
            setItems([]);
            setdisableShow(false);
            return;
        };

        setItems(await updatedata.v_data);

        const column1 = Object.keys(await updatedata.v_data[0]);

        setheadingcolumn(column1);

        if (updatedata.v_data.length === 1) {
            toast.info(whenNoRecordFoundMessage, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });

            setdisableShow(false);
            return;
        }

        // console.log("Heading columns >> ", column1[12])

        // Getting the first row of specific column

        const row1 = Object.values(await updatedata.v_data[0]);

        setprint_datetime(await row1[0]);
        
        // setfee_group_id_name(await row1[1]);

        // console.log("Heading columns >> ", date_column[12])

        // console.log("updatedata.v_data >> ", await updatedata.v_data[1].length)

        setdisableShow(false);
    }

    const handleInputValueFun = (name, value) => {

        if (name === 'student_type_descr') {
            setstudent_type_descr(value);
            setItems([]);
            setdisableShow(false);
            return;
        }else if (name === 'st_class_descr') {
            setst_class_descr(value);
            setItems([]);
            setdisableShow(false);
            return;
        } else if (name === 'st_sec_descr') {
            setst_sec_descr(value);
            setItems([]);
            setdisableShow(false);
            return;
        } else if (name === 'stream_descr') {
            setstream_descr(value);
            setItems([]);
            setdisableShow(false);
            return;
        }

    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        // console.log("handleInputFocus value >> ", value)

        if (name === 'student_type_descr') {
            setShowPopUp(true);
            setSelectedColumn('student_type_descr');
            return;
        }else if (name === 'st_class_descr') {
            setShowPopUp(true);
            setSelectedColumn('st_class_descr');
            return;
        } else if (name === 'st_sec_descr') {
            setShowPopUp(true);
            setSelectedColumn('st_sec_descr');
            return;
        } else if (name === 'stream_descr') {
            setShowPopUp(true);
            setSelectedColumn('stream_descr');
            return;
        } else {
            setShowPopUp(false);
            return;
        }

    };

    const PrintPDF = useReactToPrint({
        // content: () => conponentPDF.current,
        // documentTitle: "Userdata",
        // onAfterPrint: () => alert("Data saved in PDF")

        content: () => conponentPDF.current,
        documentTitle: "Daily Fee Collecion",
        // pageStyle : "35px"
        // pageStyle : "@page { size: 2.5in 4in }"        
        // pageStyle : "1080px"

        pageStyle: "size: A4 landscape"

        // pageStyle : "size: A4 portrait"        
    });

    const handleButtonExportValue = (e, p_value) => {
        e.preventDefault();

        const { name, value } = e.target;

        if (p_value === 'BTN_FIND') {
            console.log("Find BTN");
            ShowRecordFun();
            return;
        } else if (p_value === 'BTN_BACK') {
            navigate(`${v_backToNavigateName}`);
            return;
        } else if (p_value === 'BTN_PRINT') {
            PrintPDF();
            return;
        } else if (p_value === 'BTN_EXPORT_EXCEL') {

            // console.log("EXCEL")

            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        } else if (p_value === 'BTN_EXPORT_PDF') {

            // console.log("PDF")

            return;
        }

    }

    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if (updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405') {

            if (updatedata.v_data[0].res_status === 'UN_AUTHROISED') { v_toast_msg = v_access_denied_msg; }
            if (updatedata.v_data[0].res_status === 'ERROR') { v_toast_msg = v_session_expire_msg; }

            toast.info(v_toast_msg, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ') {

            // check if read allow then            

            if (updatedata.v_data.length === 0) {
                toast.info(whenNoRecordFoundMessage, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                return false;
            }

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                return false;
            }

            return true;
        }

        // below code to check if CRUD allow then       

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }

        if (updatedata.v_status.toString().trim() === '200') {

            if (updatedata.transType === 'DELETE') {
                v_toast_msg = deletedMessage;
            }

            // ShowRecordFun();

            toast.success(v_toast_msg, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
        }

    }

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        handleInputValueFun(name, value);
    }

    const addInputRef = (ref, index) => {
        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    const goCheckDataFunction = (event) => {
        event.preventDefault();

        console.log("st_class >> ", st_class)
        console.log("st_class_descr >> ", st_class_descr)

        // console.log("Fee Type >> ", param_st_fee_coll_group_id)
        // console.log("Class >> ", st_class.length, st_class)
        // console.log("Section >> ", st_sec.length, st_sec)
        // console.log("Stream >> ", stream.length, stream)
    }

    const ThData = () => {

        return headingcolumn.map((data, index) => {           

            if (index === 1){
                return <td className = "report_table_th_td_css text-center w-[4%]" key={data}>{data}</td>            
            }else if (index === 2){
                return <td className = "report_table_th_td_css text-center w-[8%]" key={data}>{data}</td>            
            }else if (index === 3){
                return <td className = "report_table_th_td_css text-center w-[6%]" key={data}>{data}</td>            
            }else if (index === 4 || index === 5 || index === 6){
                return <td className = "report_table_th_td_css text-left w-[16%]" key={data}>{data}</td>           
            }else if (index === 7){
                return <td className = "report_table_th_td_css text-left w-[6%]" key={data}>{data}</td>
            }else if (index === 8){
                return <td className = "report_table_th_td_css text-center w-[2%]" key={data}>{data}</td>            
            }else if (index === 9){
                return <td className = "report_table_th_td_css text-left w-[23%]" key={data}>{data}</td>
            }else if (index === 10){
                return <td className = "report_table_th_td_css text-center w-[9%]" key={data}>{data}</td>
            }else if (index === 11){
                return <td className = "report_table_th_td_css text-center w-[7%]" key={data}>{data}</td>            
            }else if (index === 12){
                return <td className = "report_table_th_td_css text-center w-[8%]" key={data}>{data}</td>            
            }else if (index === 13){
                return <td className = "report_table_th_td_css text-center w-[5%]" key={data}>{data}</td>              
            }else if (index === 14){
                return <td className = "report_table_th_td_css text-center w-[5%]" key={data}>{data}</td>              
            }

        })

    }

    const tdData = () => {

        return items.map((data, i) => {
            return (
            <tr className="table_thead_tr_css table_tbody_hover_css">
                {

                headingcolumn.map((v_data, index) => {                  

                    if (index === 1){
                        return <td className = "report_table_th_td_body_css text-center w-[4%]">{data[v_data]}</td>
                        // return <td className = "report_table_th_td_body_css text-center w-[4%]">{i + 1}</td>
                    }else if (index === 2){
                        return <td className = "report_table_th_td_body_css text-center w-[8%]">{data[v_data]}</td>            
                    }else if (index === 3 && (data[v_data] === 'S.Total' || data[v_data] === 'G.Total')){
                         return <td className = "report_table_th_td_body_css text-center font-bold w-[6%] bg-yellow-200">{data[v_data]}</td>              
                    }else if (index === 3){
                        return <td className = "report_table_th_td_body_css text-center w-[6%]">{data[v_data]}</td>            
                    }else if (index === 4 || index === 5 || index === 6){
                        return <td className = "report_table_th_td_body_css text-left w-[16%]">{data[v_data]}</td>           
                    }else if (index === 7){
                        return <td className = "report_table_th_td_body_css text-left w-[6%]">{data[v_data]}</td>
                    }else if (index === 8){
                        return <td className = "report_table_th_td_body_css text-center w-[2%]">{data[v_data]}</td>            
                    }else if (index === 9){
                        return <td className = "report_table_th_td_body_css text-left w-[23%]">{data[v_data]}</td>
                    }else if (index === 10){
                        return <td className = "report_table_th_td_body_css text-center w-[9%]">{data[v_data]}</td>
                    }else if (index === 11){
                        return <td className = "report_table_th_td_body_css text-center w-[7%]">{data[v_data]}</td>            
                    }else if (index === 12){
                        return <td className = "report_table_th_td_body_css text-center w-[8%]">{data[v_data]}</td>            
                    }else if (index === 13){
                        return <td className = "report_table_th_td_body_css text-center w-[5%]">{data[v_data]}</td>              
                    }else if (index === 14){
                        return <td className = "report_table_th_td_body_css text-center w-[5%]">{data[v_data]}</td>              
                    }

                })
                }
            </tr>
            )
        })
    }

    const WhenNoDataFoundFunction = (p_data) => {

        // console.log("When no data >> ", p_data.selected_items);

        if (p_data.p_calling_fun_type === 'NO_DATA_FOUND') {
            SetDataAfterReturnFromSearchList(p_data);
        }
    }

    const onBlurSetDataFunction = (p_data) => {
        // console.log("On Blur selected_items Data >> ", p_data);

        if (p_data.p_calling_fun_type === 'CALL_WHEN_KEY_PRESS') {
            SetDataAfterReturnFromSearchList(p_data);
        }
    }

    const setPopUpDDFalseFunction = (p_data) => {

        // console.log("Return selected_items Data >> ", p_data.selected_items);

        SetDataAfterReturnFromSearchList(p_data);

        setShowPopUp(false);
        setConditionalCSS(false);
    }

    const SetDataAfterReturnFromSearchList = (p_data) => {

        if (p_data.columnType === 'SINGLE_NOT_TABLE' || p_data.columnType === 'MULTIPLE_NOT_TABLE') {


            if (p_data.selectedColumnName === 'student_type_descr') {
                setstudent_type(p_data.SelectedIDArray);
                setstudent_type_descr(p_data.SelectedIDArrayValue);
                setstudent_type_descrstr(p_data.SelectedIDArrayValueStr);

            }else if (p_data.selectedColumnName === 'st_class_descr') {
                setst_class(p_data.SelectedIDArray);
                setst_class_descr(p_data.SelectedIDArrayValue);
                setst_class_descrstr(p_data.SelectedIDArrayValueStr);
            } else if (p_data.selectedColumnName === 'st_sec_descr') {
                setst_sec(p_data.SelectedIDArray);
                setst_sec_descr(p_data.SelectedIDArrayValue);
                setst_sec_descrstr(p_data.SelectedIDArrayValueStr);
            } else if (p_data.selectedColumnName === 'stream_descr') {
                setstream(p_data.SelectedIDArray);
                setstream_descr(p_data.SelectedIDArrayValue);
                setstream_descrstr(p_data.SelectedIDArrayValueStr);
            }

            setItems([]);
            setdisableShow(false);
        }
    }

    const handlerChangeTableRowFocus = (event) => {
        event.preventDefault();

        setShowPopUp(false);
    };

return (
<>

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">

    <div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

    <div className="form_div_main_css h-[8vh]">
        <div className="form_div_inner_css grid grid-cols-10">

            {parseInt(ReduxGlobalData[0].st) === 0 && (
            <div>
                <label className="label_inner_page_css">Student Type</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 5)}
                    name="student_type_descr"
                    id="student_type_descr"
                    value={student_type_descr}
                    // onChange={handleInputValue}                                    
                    onFocus={handleInputFocus}
                ></input>

                {
                    ShowPopUp & selectedColumn === 'student_type_descr' ?
                        <div className="absolute w-[14%]">
                            <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                onBlurSetDataFunction={onBlurSetDataFunction}
                                WhenNoDataFoundFunction={WhenNoDataFoundFunction}
                                items={ReduxDDStudentTypeData[0]}
                                columnName='student_type_descr'

                                // the below code is for SINGLE_NOT_TABLE, single selection.
                                // columnType='SINGLE_NOT_TABLE'
                                // PropsIDArray={[student_type]}
                                // PropsIDArrayValueStr={[student_type_descr]}

                                // the below code is for MULTIPLE_NOT_TABLE, multiple selection.

                                columnType='MULTIPLE_NOT_TABLE'
                                PropsIDArray={student_type}
                                PropsIDArrayValueStr={student_type_descr}
                            />
                        </div>
                        :
                        null
                }

            </div>                                            
            )}

            <div>
                <label className="label_inner_page_css">Class</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 5)}
                    name="st_class_descr"
                    id="st_class_descr"
                    value={st_class_descr}
                    // onChange={handleInputValue}                                    
                    onFocus={handleInputFocus}
                ></input>

                {
                    ShowPopUp & selectedColumn === 'st_class_descr' ?
                        <div className="absolute w-[14%]">
                            <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                onBlurSetDataFunction={onBlurSetDataFunction}
                                WhenNoDataFoundFunction={WhenNoDataFoundFunction}
                                items={ReduxDDStudentClassData[0]}
                                columnName='st_class_descr'

                                // the below code is for SINGLE_NOT_TABLE, single selection.
                                // columnType='SINGLE_NOT_TABLE'
                                // PropsIDArray={[st_class]}
                                // PropsIDArrayValueStr={[st_class_descr]}

                                // the below code is for MULTIPLE_NOT_TABLE, multiple selection.

                                columnType='MULTIPLE_NOT_TABLE'
                                PropsIDArray={st_class}
                                PropsIDArrayValueStr={st_class_descr}
                            />
                        </div>
                        :
                        null
                }

            </div>

            <div>
                <label className="label_inner_page_css">Sec.</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 5)}
                    name="st_sec_descr"
                    id="st_sec_descr"
                    value={st_sec_descr}
                    // onChange={handleInputValue}                                    
                    onFocus={handleInputFocus}
                ></input>

                {
                    ShowPopUp & selectedColumn === 'st_sec_descr' ?
                        <div className="absolute w-[14%]">
                            <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                onBlurSetDataFunction={onBlurSetDataFunction}
                                WhenNoDataFoundFunction={WhenNoDataFoundFunction}
                                items={ReduxDDStudentSectionData[0]}
                                columnName='st_sec_descr'


                                // the below code is for SINGLE_NOT_TABLE, single selection.
                                // columnType='SINGLE_NOT_TABLE'
                                // PropsIDArray={[st_sec]}
                                // PropsIDArrayValueStr={[st_sec_descr]}

                                // the below code is for MULTIPLE_NOT_TABLE, multiple selection.

                                columnType='MULTIPLE_NOT_TABLE'
                                PropsIDArray={st_sec}
                                PropsIDArrayValueStr={st_sec_descr}
                            />
                        </div>
                        :
                        null
                }

            </div>

            {parseInt(ReduxGlobalData[0].stem) === 0 && (
                <div>
                    <label className="label_inner_page_css">Stream</label>
                    <input className="input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 6)}
                        name="stream_descr"
                        id="stream_descr"
                        value={stream_descr}
                        // onChange={handleInputValue}                                        
                        onFocus={handleInputFocus}
                    ></input>

                    {
                        ShowPopUp & selectedColumn === 'stream_descr' ?
                            <div className="absolute w-[14%]">
                                <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                    onBlurSetDataFunction={onBlurSetDataFunction}
                                    WhenNoDataFoundFunction={WhenNoDataFoundFunction}
                                    items={ReduxDDStStreamData[0]}
                                    columnName='stream_descr'

                                    // columnType='SINGLE_NOT_TABLE'
                                    // PropsIDArray={[stream]}
                                    // PropsIDArrayValueStr={[stream_descr]}

                                    columnType='MULTIPLE_NOT_TABLE'
                                    PropsIDArray={stream}
                                    PropsIDArrayValueStr={stream_descr}
                                />
                            </div>
                            :
                            null
                    }

                </div>

            )}

            <div className="col-span-2 mt-4 flex gap-4">                            

                <div>
                    {(userAuthData.allow_read === 1 && !disableShow) && (
                        <div className="report_button_css" onClick={(e) => handleButtonExportValue(e, 'BTN_FIND')}>
                            <FontAwesomeIcon className="faPrint_css " icon={faMagnifyingGlass} />
                        </div>
                    )}
                </div>

                <div>
                    {userAuthData.export_excel === 1 && (
                        <div className="report_button_css" onClick={(e) => handleButtonExportValue(e, 'BTN_EXPORT_EXCEL')}>
                            <FontAwesomeIcon className="faFileExcel_css" icon={faFileExcel} />
                        </div>
                    )}
                </div>

                <div>
                    {userAuthData.export_pdf === 1 && (
                        <div className="report_button_css" onClick={(e) => handleButtonExportValue(e, 'BTN_EXPORT_PDF')}>
                            <FontAwesomeIcon className="faFilePdf_css" icon={faFilePdf} />
                        </div>
                    )}
                </div>

                <div>
                    {userAuthData.print === 1 && (
                        <div className="report_button_css" onClick={(e) => handleButtonExportValue(e, 'BTN_PRINT')}>
                            <FontAwesomeIcon className="faPrint_css" icon={faPrint} />
                        </div>
                    )}
                </div>

                <div>
                    <button className="btn_back_css"
                        value='BTN_BACK'
                        onClick={(e) => handleButtonExportValue(e, 'BTN_BACK')}>
                        Back
                    </button>
                </div>

            </div>

        </div>

    </div>

    <div ref={conponentPDF} onMouseEnter={() => setShowPopUp(false)}>

        <div className="text-center text-2xl font-bold">{ReduxGlobalData[0].report_head_1}</div>
        <div className="text-center text-1xl font-bold">{ReduxGlobalData[0].report_head_2}</div>
        <div className="text-center text-1xl font-bold">{ReduxGlobalData[0].report_head_3}</div>
        <div className="text-center text-1xl font-bold">{v_main_form_heading} - Academic Year ({ReduxGlobalData[0].fy_name})</div>

        <div className="text-1xl border border-black flex justify-center gap-5">

            <div>
                {parseInt(ReduxGlobalData[0].st) === 0 && (
                    <div className="flex gap-1">
                        <div className="text-center text-1xl font-bold">Student Type:</div>
                        <div className="text-center">{student_type_descrstr}</div>
                    </div>
                )}
            </div>

            <div className="flex">
                <div className="text-center text-1xl font-bold">Class:</div>
                {
                    st_class_descr.length > 0 ? <div className="text-center">{st_class_descrstr}</div>
                        :
                        <div className="text-center">All</div>
                }
            </div>

            <div className="flex">
                <div className="text-center text-1xl font-bold">Sec:</div>
                {
                    st_sec_descr.length > 0 ? <div className="text-center">{st_sec_descrstr}</div>
                        :
                        <div className="text-center">All</div>
                }
            </div>

            {parseInt(ReduxGlobalData[0].stem) === 0 && (
                <div className="flex gap-1">
                    <div className="text-center text-1xl font-bold">Stream:</div>

                    {
                        stream_descr.length > 0 ? <div className="text-center">{stream_descrstr}</div>
                            :
                            <div className="text-center">All</div>
                    }

                </div>
            )}

            <div className="flex">
                <div className="text-center text-1xl font-bold">Generated by:</div>
                <div className="text-center">{ReduxGlobalData[0].user}</div>
            </div>

            <div className="flex">
                <div className="text-center text-1xl font-bold">Date & Time:</div>
                <div className="text-center">{print_datetime}</div>
            </div>

        </div>

        <table className="table_main_css">
            <thead className="table_tbody_report_css">
                <tr className="table_thead_tr_css">
                    {ThData()}
                </tr>
            </thead>

            <tbody className="table_tbody_report_css">
                {tdData()}
            </tbody>

        </table>
    </div>

</form>
</>
);
};

export default StudentList;
