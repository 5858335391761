// With persist

import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import SagaData from './saga';
import rootReducer from './rootReducer';

import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';

const sagaMiddleware = createSagaMiddleware();

let persistConfig = {
    key:'root', storage,
}

let persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer:persistedReducer,
    middleware:()=>[sagaMiddleware]
});

sagaMiddleware.run(SagaData);
export default store;

// /////////////////////////////////////////////////////////////////////////////////////////////////

// With-out persist


// import {configureStore} from '@reduxjs/toolkit';
// import createSagaMiddleware from 'redux-saga';
// import SagaData from './saga';
// import rootReducer from './rootReducer';

// const sagaMiddleware = createSagaMiddleware();

// const store = configureStore({
//     reducer:rootReducer,
//     middleware:()=>[sagaMiddleware]
// });

// sagaMiddleware.run(SagaData);
// export default store;