// Branch wise usergroup
// Table name : usergroup_branch_wise

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';
// import Layout from './Layout';
// import {isAuthenticatedUser} from '../components/isAuthenticated';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

const Brug = ({access_token}) => {    
    const dispatch = useDispatch();
    const ReduxData = useSelector((state) => state);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);       
    const navigate = useNavigate();

    // const router = useRouter();

    const [fromitems, setfromitems] = useState([]);    
    const [toitems, settoitems] = useState([]);

    const [under_comp_branch, setunder_comp_branch] = useState(0);
    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);    
    
    const [origionaltoitems, setorigionaltoitems]= useState([]);
    const [origionalfromitems, setorigionalfromitems]= useState([]);    

    const YesNoList = [
        // { id: 0, sel_val: 0, sel_desc: "" },
        { id: 1, sel_val: 1, sel_desc: "Y" },
        { id: 2, sel_val: 2, sel_desc: "N" }
    ];    

    let list1 = [];

    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,        
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenPageRefreshMessage
    } = useContext(CRUDContext);
   
    useEffect(() => {
        if (error) {
            toast.error(error);
            clearErrors();
        }

        if (created) {
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
        }

        if (updated) {
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
        }

    }, [error, created, updated, deleted]); 

    useEffect(()=>{
        
        let response = []
        let updatedata = []

        // to fill the drop-down under_comp_branch        

        const getTableRecordFunction = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_comp_branchItems(updatedata);
        }
        getTableRecordFunction('url_comp_branch_view_drop_down');
        
        const getTableRecordFunctionLeft = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data

            list1 = updatedata?.map((item, i) => (
                {
                    id:item.id, 
                    sel_desc : item.sel_desc, 
                }
            ));
            setorigionalfromitems(list1);           
        }
        getTableRecordFunctionLeft('url_usergroup_view');               
        
    }, []);

    // useEffect(() => {

        // console.log('updatedata useEffect New')

        // let response = []
        // let updatedata = []         

        // const getAllTableRecordFunctionToItems= async(var_link)=>{            
		// 	response = await axios.get(`${var_link}/`,
        //     { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

        //     updatedata = await response.data.v_out_data

        //     list1 = updatedata?.map((item, i) => (
        //         {                    
        //             id : item.usergroup_id,                
        //             comp_branch_id : item.comp_branch_id,                                     
        //             display_seq : item.dis_seq, 
        //             sel_desc:item.sel_desc,                   
        //             show_in_list:item.show_in_list                          
        //         }
        //     ))                      
        //     setorigionaltoitems(list1);            
		// }
        // getAllTableRecordFunctionToItems('url_usergroup_branch_wise_view');

    // }, [created, deleted]);
    
    // handleChangeCheckBox for checkbox
    
    const handleChangeCheckBox=(event)=>
	{
        const { name, checked}= event.target;				
		if(name==="allselect")
		{
			const checkedvalue = fromitems.map( (user)=>{ return {...user, isChecked:checked}});			
			setfromitems(checkedvalue);
		} 
		else
		{		 		
		    const checkedvalue= fromitems.map( (user)=>user.id.toString() === name ? {...user, isChecked:checked}:user);            
		    setfromitems(checkedvalue);
		}
    }

    const handleChangeCheckBoxTo=(event)=>
	{
        const { name, checked}= event.target;				
		if(name==="allselect")
		{
			const checkedvalue = toitems.map( (user)=>{ return {...user, isChecked:checked}});			
			settoitems(checkedvalue);
		} 
		else
		{		 		
		    const checkedvalue= toitems.map( (user)=>user.id.toString() === name ? {...user, isChecked:checked}:user);            
		    settoitems(checkedvalue);
		}
    }

    const createRecordFunction = (event) => {        
        event.preventDefault();
        
        // setQueryCode('');
        // setQueryName('');

        const data = {
            
            under_comp_branch,
            toitems: Array(toitems),

        };

        // console.log("toitems is : ", toitems)

        dispatch(emptyCart());
        dispatch(addTempList(data));
        setModelModelFormYesNo(true);
    }

    const handlerChange = (event, i) => {
        event.preventDefault();

        const { name, value } = event.target;
        const list = [...toitems];
        list[i][name] = value;
        settoitems(list);
    };

    const handleSearchCode=(event)=>{
        // event.preventDefault();
        // const getSearch= event.target.value;        
        // let searchdata = filterdata;
        
        // searchdata= searchdata.filter( (item)=> item.l_code.toLowerCase().includes(getSearch.toLowerCase()));
        // searchdata= searchdata.filter( (item)=> item.l_name.toLowerCase().includes(queryName.toLowerCase()));            
        
        
        // setQueryCode(getSearch);
        // setItems(searchdata);
        // generateExcelData(searchdata);
    }

    // const handleunder_comp_branch = (event) => {
    //     event.preventDefault();
    //     const getId = event.target.value;
    //     setunder_comp_branch(getId);

    //     // ======================================================================================

    //     console.log("getId : ", getId)

    //     let response = []
    //     let updatedata = []         

    //     const getAllTableRecordFunctionToItems= async(var_link)=>{            
	// 		response = await axios.get(`${var_link}/${getId}`,
    //         { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

    //         updatedata = await response.data.v_out_data

    //         console.log(updatedata)

    //         list1 = updatedata?.map((item, i) => (
    //             {                    
    //                 id : item.usergroup_id,                
    //                 comp_branch_id : item.comp_branch_id,                                     
    //                 display_seq : item.dis_seq, 
    //                 sel_desc:item.sel_desc,                   
    //                 show_in_list:item.show_in_list                          
    //             }
    //         ))                      
    //         setorigionaltoitems(list1);            
		

    //             // ======================================================================================
                
    //             let searchtoitems = origionaltoitems;

    //             if (getId > 0)
    //             {
    //                 const getSearch = event.target.value;
    //                 searchtoitems = searchtoitems.filter((item) => item.comp_branch_id.toString().includes(getSearch.toString()));            
    //                 settoitems(searchtoitems);            
    //             }else{            
    //                 settoitems([]);            
    //             }

    //             if (getId > 0)
    //             {
    //                 let fromsearchitems;
    //                 let tosearchitems;

    //                 fromsearchitems = origionalfromitems?.map((item, i) => (
    //                     {
    //                         id:item.id,
    //                         sel_desc : item.sel_desc,                    
    //                         comp_branch_id : getId,                    
    //                     }
    //                 ))

    //                 tosearchitems = searchtoitems?.map((item, i) => (
    //                     {
    //                         id:item.id, 
    //                         sel_desc : item.sel_desc, 
    //                         display_seq : item.dis_seq,                   
    //                         comp_branch_id : getId,    
    //                     }
    //                 ))

    //                 let resultB = fromsearchitems.filter(elm => !tosearchitems.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm)));  
            
    //                 setfromitems(resultB)                   
    //             }else{            
    //                 setfromitems([]);
    //                 settoitems([]);
    //             }       
                
    //     }
    //     getAllTableRecordFunctionToItems('url_usergroup_branch_wise_view');

    //     // Example for reference (to get differnt rows from to array)

    //     // let a = [
    //     //     { value: "0", display: "Jamsheer" },
    //     //     { value: "1", display: "Muhammed" },
    //     //     { value: "2", display: "Ravi" },
    //     //     { value: "3", display: "Ajmal" },
    //     //     { value: "4", display: "Ryan" }
    //     // ]
        
    //     // let b = [
    //     //     { value: "0", display: "Jamsheer" },
    //     //     { value: "1", display: "Muhammed" },
    //     //     { value: "2", display: "Ravi" },
    //     //     { value: "3", display: "Ajmal" }
    //     // ]
        
    //     // // b diff a
    //     // let resultA = b.filter(elm => !a.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm)));
        
    //     // // a diff b
    //     // let resultB = a.filter(elm => !b.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm)));  
        
    //     // // show merge 
    //     // console.log([...resultA, ...resultB]);

    // }

    const handleunder_comp_branch = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setunder_comp_branch(getId);

        // ======================================================================================

        // console.log("getId : ", getId)

        let response = []
        let updatedata = []         

        const getAllTableRecordFunctionToItems= async(var_link)=>{            
			response = await axios.get(`${var_link}/${getId}`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data

            // console.log(updatedata)

            list1 = updatedata?.map((item, i) => (
                {                    
                    id : item.usergroup_id,                
                    comp_branch_id : item.comp_branch_id,                                     
                    display_seq : item.dis_seq, 
                    sel_desc:item.sel_desc,                   
                    show_in_list:item.show_in_list                          
                }
            ))                      
            setorigionaltoitems(list1);            
		

            // ======================================================================================
            
            let searchtoitems = list1;

            if (getId > 0)
            {
                const getSearch = event.target.value;
                searchtoitems = searchtoitems.filter((item) => item.comp_branch_id.toString().includes(getSearch.toString()));            
                settoitems(searchtoitems);            
            }else{            
                settoitems([]);            
            }

            if (getId > 0)
            {
                let fromsearchitems;
                let tosearchitems;

                fromsearchitems = origionalfromitems?.map((item, i) => (
                    {
                        id:item.id,
                        sel_desc : item.sel_desc,                    
                        comp_branch_id : getId,                    
                    }
                ))

                tosearchitems = searchtoitems?.map((item, i) => (
                    {
                        id:item.id, 
                        sel_desc : item.sel_desc, 
                        display_seq : item.dis_seq,                   
                        comp_branch_id : getId,    
                    }
                ))

                let resultB = fromsearchitems.filter(elm => !tosearchitems.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm)));  
        
                setfromitems(resultB)                   
            }else{            
                setfromitems([]);
                settoitems([]);
            }   
        }
        getAllTableRecordFunctionToItems('url_usergroup_branch_wise_view');

        if (getId === '--Select Under Branch--'){
            settoitems([]);
        }

    }

    const handleSearchName=(event)=>{
        // event.preventDefault();
        // const getSearch= event.target.value;        
        // let searchdata = filterdata;        
        
        // searchdata= searchdata.filter( (item)=> item.l_name.toLowerCase().includes(getSearch.toLowerCase()));
        // searchdata= searchdata.filter( (item)=> item.l_code.toLowerCase().includes(queryCode.toLowerCase()));           
        
        // setQueryName(getSearch);
        // setItems(searchdata);
        // generateExcelData(searchdata);
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();
        navigate('/brms')
    }

    const selAllToFunction = (event) => {
        event.preventDefault();       

        if (under_comp_branch === 0)
        {           
            toast.info("Entry reuired for branch name !")
            clearErrors();
            return;           
        }        

        const checkedinputvalue = toitems;

        let tempList = [];

        for(let i=0; i<fromitems.length; i++)        
		{
            tempList = {                
                id : fromitems[i].id, 
                sel_desc : fromitems[i].sel_desc,                
                comp_branch_id : under_comp_branch,
                display_seq : 1,                                              
                show_in_list : 1,                 
            }	           

            checkedinputvalue.push(tempList);
        }

        setfromitems([])        
    }    

    const selCheckedToFunction = (event) => {
        event.preventDefault();
        
        if (under_comp_branch === 0)
        {           
            toast.info("Entry reuired for branch name !")
            clearErrors();
            return;           
        }        
        
        const mainItems = [];

        const checkedinputvalue = toitems;

        let tempList = [];

        for(let i=0; i<fromitems.length; i++)        
		{
            tempList = {                
                id : fromitems[i].id,                
                comp_branch_id : under_comp_branch,
                display_seq : 1,
                sel_desc : fromitems[i].sel_desc,                               
                show_in_list : 1,
            }

			if(fromitems[i].isChecked===true)
			{   
            	checkedinputvalue.push(tempList);                
			}else{
                mainItems.push(tempList);
            }			
        }

        setfromitems(mainItems);
        settoitems(checkedinputvalue);
    }

    const selCheckedFromFunction = (event) => {               
        
        event.preventDefault();    
        
        if (under_comp_branch === 0)
        {           
            toast.info("Entry reuired for branch name !")
            clearErrors();
            return;           
        }
        
        let tempList = [];
        const tempToItems = [];
        const checkedinputvalue = fromitems;

        for(let i=0; i<toitems.length; i++)
		{
            tempList = { 
                id : toitems[i].id,                
                comp_branch_id : under_comp_branch,
                display_seq : toitems[i].display_seq,
                sel_desc : toitems[i].sel_desc,                               
                show_in_list : 1,  
            }

			if(toitems[i].isChecked===true)
			{   
            	checkedinputvalue.push(tempList);                
			}else{
                tempToItems.push(tempList);
            }
        }
        settoitems(tempToItems);     
    }

    const selAllFromFunction = (event) => {
        event.preventDefault();   

        if (under_comp_branch === 0)
        {           
            toast.info("Entry reuired for branch name !")
            clearErrors();
            return;           
        }

        const checkedinputvalue = fromitems;

        let tempList = [];

        for(let i=0; i<toitems.length; i++)
		{
            tempList = {
                id : toitems[i].id,        
                sel_desc : toitems[i].sel_desc,        
                comp_branch_id : under_comp_branch,            
            }

            checkedinputvalue.push(tempList); 			
        }
        settoitems([]);
    }
    
    return (
                
        <>
            
            <div className="pt-8 max-w-full text-my-main-heading-fontSize bg-my-main-heading-color text-my-main-heading-font-color text-center">Branch Wise User Group</div>
                    
                    <div className="border-my-borderWidth border-my-TableHeadingBorderColor bg-my-main-heading-color">
                        <label className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor"                         
                            >Branch Name : 
                        </label>

                        <select name="under_comp_branch" onChange={(e) => handleunder_comp_branch(e)}>
                            <option>--Select Under Branch--</option>
                            {
                                under_comp_branchItems.map((item, index) => (
                                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                                ))
                            }
                        </select>
                    </div>
                    
                    <div className = "grid grid-cols-12 border-my-borderWidth border-my-TableHeadingBorderColor">
                       
                        <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-7">                                                                            
                            Name
                        </div>   

                        <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor                        
                            col-span-2">
                            Display Seq.                    
                        </div>   
                        
                        <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-3">                                                                            
                            Name
                        </div>                        
                        
                    </div>
                    
                    <span className="w-[42%]  display: inline-block h-myTableHeight overflow-y-scroll">
                        {
                            fromitems?.map((item, i) => (
                            
                                <div className = "grid grid-cols-12" key={item.id}>
                                    
                                    <div className="col-span-1">
                                        <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                            w-[60%]"                                    
                                            type="checkbox" name={ item.id} checked={item ?.isChecked || false }
                                            onChange={ handleChangeCheckBox }                                        
                                        /> 
                                    </div>

                                    <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                        col-span-11"                                    
                                        type="text"
                                        name="sel_desc"
                                        value={item.sel_desc}                                
                                        onChange={(e) => handlerChange(e, i)}
                                        disabled                               
                                    />        
                                
                                </div>
                            ))
                        }                        
                    </span>

                    
                    <span className = "w-[15%] display: inline-block space-y-12">

                        <div>
                            <button className="w-[55%] pm-10 bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={selAllToFunction}>                                
                                &#62;&#62;
                            </button>
                        </div>

                        <div>
                            <button className="w-[55%] bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={selCheckedToFunction}>
                                &#62;
                            </button>
                        </div>

                        <div>
                            <button className="w-[55%] bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={selCheckedFromFunction}>
                                    &#60;
                            </button>
                        </div>

                        <div>
                            <button className="w-[55%] bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={selAllFromFunction}>
                                    &#60;&#60;
                            </button>
                        </div>

                        <div>
                            <button className="w-[55%] bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={createRecordFunction}>
                                    Save
                            </button>
                        </div>

                        <div>
                            <button className="w-[55%] bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={goPrePageFunction}>
                                    Back
                            </button>
                        </div>

                    </span>              

                    <span className="w-[42%] display: inline-block h-myTableHeight overflow-y-scroll">
                        {
                            toitems?.map((item, i) => (
                            
                                <div className = "grid grid-cols-12" key={item.id}>
                                    
                                    <div className="col-span-2">
                                        <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                            w-[50%]"                                    
                                            type="checkbox" name={ item.id} checked={item ?.isChecked || false }
                                            onChange={ handleChangeCheckBoxTo }                                        
                                        />
                            
                                        <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                            col-span-2 w-[50%]"
                                            type="text"
                                            name="display_seq"
                                            value={item.display_seq}   
                                            onChange={(e) => handlerChange(e, i)}            
                                        />
                                    </div>

                                    <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                        col-span-10"                                    
                                        type="text"
                                        name="sel_desc"
                                        value={item.sel_desc}
                                        disabled                               
                                    />                                                                       

                                </div>
                            ))
                        }
                    </span>
            

            <div className = "grid grid-cols-12 border-my-borderWidth border-my-TableHeadingBorderColor">
                        
                <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor                        
                    col-span-7">
                    Records: {fromitems.length}                  
                </div>              

                <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor                        
                    col-span-2">
                    Records: {toitems.length}                   
                </div>                   
            </div>

            <ModelFormYesNo table_name = 'usergroup_branch_wise' api_name = {'url_usergroup_branch_wise_new_multiple'} isVisible = {ShowModelFormYesNo} onClose = {()=>setModelModelFormYesNo(false)} access_token = {access_token} />
               

        </>        
    );
};

export default Brug;

