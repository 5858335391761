import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {GetFormAuthDetails} from '../components/GlobalFunction';
import { useDispatch, useSelector } from 'react-redux';
import CRUDContext from '../components/CRUDContext';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { emptyTempDataFun, emptyGlobalDataFun } from '../components/redux/actions';

import { useReactToPrint } from "react-to-print";

const AccountVouPrint = () => {    
    const conponentPDF = useRef();

    let response = [], updatedata = [], v_toast_msg = '';
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // standard items define for table populate

    const [items, setItems] = useState([]); 

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData);
    const ReduxTempData = useSelector((state) => state.TempData);
    
    // define form name and navigation details

    const v_backToNavigateName = '/Vmkg';    
    // const v_main_form_heading = 'Voucher Details !';
    const v_frm_no = 22;    
    
    const[vou_type_name, setvou_type_name] = useState('')
    const[vou_no, setvou_no] = useState('')

    const[vou_date, setvou_date] = useState('')
    const[notes, setnotes] = useState('')
    const[vou_amt, setvou_amt] = useState('')
    const[amt_in_word, setamt_in_word] = useState('')

    const[BlankLine, setBlankLine] = useState([]);

    // define standard errors and entry required messages

    const {        
        v_allow_open,        
        v_session_expire_msg,
        v_access_denied_msg,        
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,
    } = useContext(CRUDContext);

    useEffect(() => {

        async function loadData() {
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);            
            // setUserAuthData(result);

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());
                
                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.print === 2) {
                toast.info(v_allow_open, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                navigate(`${v_backToNavigateName}`);
                return;
            } else {
                if (ReduxTempData.length > 0){
                    ShowRecordFun(ReduxTempData[0].temptableid);

                    setvou_type_name(ReduxTempData[0].vou_type_name)
                    setvou_no(ReduxTempData[0].vou_no)            
                    setvou_date(ReduxTempData[0].vou_date);   
                    setnotes(ReduxTempData[0].notes)
                    setvou_amt(ReduxTempData[0].vou_amt)
                    // setamt_in_word(updatedata[0].amt_in_word)

                    dispatch(emptyTempDataFun());
                }                
            }
        }
        loadData();
    }, [ReduxGlobalData[0]]);

    const ShowRecordFun = async (p_TableRecordID) => {       

        const v_token = Cookies.get('token');

        response = await axios.get(`url_fetch_data_branch_finyear_id_wise/22_vou_print_format/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${p_TableRecordID}`,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;

        setItems(await updatedata);

        // console.log("updatedata.length : ", updatedata.length)

        // we are considering total 26 rows in A5 size print

        let v_obj = [];
        let v_list = [];
        
        for (var i = 0; i < 6 - updatedata.length; i++) {
            v_obj = [];
            for (var j = 0; j < 4; j++) {
                let key = "col_" + (j + 1);
                let value = '';
                v_obj[key] = value;
            }                
            v_list.push(v_obj);
        }
        setBlankLine(v_list);

    }
 
    const handleButtonValue = (e) => {
        e.preventDefault();

        const { name, value } = e.target;
 
        if (value === 'BTN_BACK') {            
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const handleButtonExportValue = (e, p_value) => {
        e.preventDefault();

        if (p_value === 'BTN_EXPORT_EXCEL') {

            console.log("EXCEL")

            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        } else if (p_value === 'BTN_EXPORT_PDF') {

            console.log("PDF")

            return;
        }

    }

    const PrintPDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "Userdata",
        // onAfterPrint: () => alert("Data saved in PDF")
    });        

    const goCheckDataFunction = (event) => {
        event.preventDefault();

    }

return (

<>

{/* <div className="mt-FromTopMenuMargin justify-center items-center fixed inset-0 bg-my-when-focus-bg-color bg-opacity-100 backdrop-blur-3xl"> */}

    <div className="mt-10" ref={conponentPDF}> 
            
            <table className="table-fixed border ml-[20px] w-[750px] mt-2 font-my-font-name text-rec-fontSize">                
                <thead>                                      
                    <tr><th className="font-my-font-name text-FeeRecFontSize-1 font-FeeRecFontWeight-1 pl-1 text-center">{ReduxGlobalData[0].report_head_1}</th></tr>
                    <tr><th className="font-my-font-name text-FeeRecFontSize-2 font-FeeRecFontWeight-2 pl-1 text-center">{ReduxGlobalData[0].report_head_2}</th></tr>
                    <tr><th className="font-my-font-name text-FeeRecFontSize-3 font-FeeRecFontWeight-3 pl-1 text-center">{ReduxGlobalData[0].report_head_3}</th></tr>
                    <tr><th className="underline font-my-font-name text-FeeRecFontSize-3 font-FeeRecFontWeight-3 pl-1 text-center">{vou_type_name}</th></tr>
                    
                </thead>
            </table>

            <table className="border-b-[0px] table-fixed ml-[20px] w-[750px] mt-0 font-my-font-name text-rec-fontSize">                
                <thead>                                      
                    <tr className="border-x-[1px]">
                        <th className="pl-2 w-[1px]  text-left">Voucher No.:</th>
                        <td className="pl-1 w-[52px] text-left">{vou_no}</td>

                        {/* <th className="pl-2 w-[1px]  text-left"></th>
                        <td className="pl-1 w-[20px] text-left"></td>                         */}

                        <th className="pl-1 w-[10px] text-left">Date:</th>
                        <td className="pl-1 w-[10px] text-left">{vou_date}</td>
                    </tr>
                </thead>
            </table>

            <table className="border-b-[0px] table-fixed ml-[20px] w-[750px] mt-0 font-my-font-name text-rec-fontSize">       
           
                <thead>                            

                    <tr>

                        <th className="border w-5 pl-1 print-border-color text-left">Dr./Cr.</th>
                        <th className="border w-40 pr-1 print-border-color text-left">Particular</th>

                        <th className="border w-8 pr-1 print-border-color text-right">Debit</th>
                        <th className="border w-8 pr-1 print-border-color text-right">Credit</th>

                    </tr>
                </thead>  
                             

                {
                    items.map((item, index) => (
                        <tr key={index}>                                
                            <td className="border w-5 pl-1 print-border-color text-left">{item.v_dr_cr}</td>
                            <td className="border w-40 pr-1 print-border-color text-left">{item.v_led_name}</td> 
                            
                            <td className="border w-8 pr-1 print-border-color text-right">{item.v_dr_amt}</td>
                            <td className="border w-8 pr-1 print-border-color text-right">{item.v_cr_amt}</td>

                        </tr>                            
                ))}

                <tr>   

                    <td className="border w-5 pl-1 print-border-color text-left"></td>
                    <td className="ml-2 border w-40 pr-1 print-border-color text-left">{notes}</td>

                    <td className="border w-8 pr-1 print-border-color text-right"></td>
                    <td className="border w-8 pr-1 print-border-color text-right"></td>
                </tr>   
                
            </table>           
            
            <table className="table-fixed ml-[20px] w-[750px] mt-0 font-my-font-name text-rec-fontSize">                
                <thead>                                      
                    <tr className="border-x-[1px]">

                        <td className="border w-5 pl-1 print-border-color text-left"></td>
                        {/* <td className="border w-40 pr-1 print-border-color text-left">{amt_in_word}</td> */}

                        <td className="border w-40 pr-1 print-border-color text-left font-my-font-name text-FeeRecFontSize-4 font-FeeRecFontWeight-3 pl-1">{amt_in_word}</td>                        

                        <td className="border w-8 pr-1 print-border-color text-right font-my-font-name text-FeeRecFontSize-4 font-FeeRecFontWeight-3 pl-1">{vou_amt}</td>                        

                        <td className="border w-8 pr-1 print-border-color text-right font-my-font-name text-FeeRecFontSize-4 font-FeeRecFontWeight-3 pl-1">{vou_amt}</td>                        
                
                    </tr>
                </thead>
            </table>

            <table className="table-fixed ml-[20px] w-[750px] mt-0 font-my-font-name text-rec-fontSize">
                <thead> 
                    <tr className="border-x-[1px]">
                        <td className="w-[100%] p-2.5 print-border-color text-left"></td>
                    </tr>
                </thead>
            </table>

            <table className="table-fixed ml-[20px] w-[750px] mt-0 font-my-font-name text-rec-fontSize">
                <thead> 
                    <tr className="border-x-[1px]">
                        <td className="w-[100%] p-2.5 print-border-color text-left"></td>
                    </tr>
                </thead>
            </table>

            <table className="border-b-[1px] table-fixed ml-[20px] w-[750px] mt-0 font-my-font-name text-rec-fontSize">                
                <thead>                                      
                    <tr className="border-x-[1px]">
                        
                        <th className="w-10 pr-1 print-border-color text-center">Cashier/ Accountant</th>

                        <th className="w-8 pr-1 print-border-color text-center">Authorised Signatory</th>
                        <th className="w-8 pr-1 print-border-color text-center">Received By</th>
                
                    </tr>
                </thead>
            </table>

    </div>
   
    <div className="w-[40%] flex justify-center">
        <div className="btn_div_model_saveupdel_css w-[20%]">
            <button className="btn_model_saveupdel_yes_css" type="submit" value = 'YES' onClick={PrintPDF}>Print</button>
        </div>  

        <div className="btn_div_model_saveupdel_css w-[20%]">
            <button className="btn_model_saveupdel_yes_css" type="submit" value = 'BTN_BACK' onClick={handleButtonValue}>Back</button>
        </div>
    </div>
    
</>
);};

export default AccountVouPrint;
