import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
// import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import CRUDContext from '../components/CRUDContext';
// import DropDownTableComponent from "../components/DropDownTableComponent";

import SearchListDropdown from "../components/SearchListDropdown";
import SearchStudentDetails from "../components/SearchStudentDetails";

import { emptyTempDataFun, emptyGlobalDataFun } from '../components/redux/actions';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import Cookies from 'js-cookie';

const StudentFeeConcession = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';    
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);    
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);      
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);   

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disableSave, setdisableSave] = useState(0);
    const [disableDelete, setdisableDelete] = useState(0);
    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');
    const [AllowUpdateTag, setAllowUpdateTag] = useState(false);

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData); 
    const ReduxTempData = useSelector((state) => state.TempData);    
    const ReduxDDFeeCodeData = useSelector((state) => state.DDFeeCodeData);
    const ReduxDDFeeMonthSeqWiseData = useSelector((state) => state.DDFeeMonthSeqWiseData);    
   
    // define drop downs inputs  

    const [st_id, setst_id] = useState('');
    const [adm_no, setadm_no] = useState('');
    const [st_name_display, setst_name_display] = useState('');
    const [st_father_display, setst_father_display] = useState('');
    const [st_class_display, setst_class_display] = useState('');
    const [st_sec_display, setst_sec_display] = useState('');
    const [st_stream_display, setst_stream_display] = useState('');
        
    // When we will use Multiple selection in Drop Down

    const [ShowSearchStudentDetailsPopUp, setSearchStudentDetailsPopUp] = useState(false);      

    // const [st_class_multiple, setst_class_multiple] = useState([]);
    // const [st_class_descr, setst_class_descr] = useState([]);

    // define drop downs items

    const [pay_fee_monthItems, setfee_monthItems] = useState([]);
    const [fee_codeItems, setfee_codeItems] = useState([]);
    
    // define other input    
    
    const [notes, setnotes] = useState();

    let v_input_result;   

    // define variable for tables entry (master detail form)    

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);
    const [StudentListitems, setStudentListitems] = useState([]);

    const [ShowPopUp, setShowPopUp] = useState(false);
    const [selectedrow, setSelectedrow] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(false);

    const [IDArray, setIDArray] = useState([]);
    const [IDArrayValue, setIDArrayValue] = useState([]);    
    
    const [SumAmount, setSumAmount] = useState(0);
    
    // define standard errors and entry required messages

    // const [errors_message_adm_no, setErrors_message_adm_no] = useState('');
    // const [errors_adm_no, setErrors_adm_no] = useState(false);

    // define form name and navigation details

    const v_urlSaveUpdateDelete = '13_url_fee_concession_new_proc';
    const v_backToNavigateName = '/Dashboard';
    // const v_findToNavigateName = '/Vmview';
    const v_main_form_heading = 'Fee Concession !';
    const v_frm_no = 13;

    const {
        deletedMessage,
        createdMessage,
        updatedMessage,        
        whenStudentNotFoundMessage,                     
        WhenRecordNotFoundToDelete,
        WhenRecordNotFoundToUpdate,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);      

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code

                setv_open_frm(true);                

                setdisableSave(false);
                setdisableDelete(true);

                // console.log("Update allow_update >> ", result.allow_update)

                setfee_monthItems(ReduxDDFeeMonthSeqWiseData[0]);
                setfee_codeItems(ReduxDDFeeCodeData[0]);

                const v_token = Cookies.get('token');

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/9_url_student_master_find_fun_brfy/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/0`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;     
                setStudentListitems(await updatedata);

                setBlankFields();

                if (ReduxTempData.length > 0){
                    FindSavedDetails(ReduxTempData[0].temptableid);
                    if(result.allow_update === 2){setAllowUpdateTag(true)};
                    dispatch(emptyTempDataFun());
                }
            }
        }
        loadData();
    }, []);

    useEffect(() => {
        setBlankFields();
    }, [ReduxGlobalData[0]]);    

    function FindSavedDetails(p_TableRecordID) {    

        const getAllTableRecordFunction = async () => {

        }
        getAllTableRecordFunction();
    }
    
    function setBlankFields() {
        setsaveUpdateTag('SAVE');
        setAllowUpdateTag(false);

        setdisableSave(false);
        setdisableDelete(true);        

        setTableRecordID(0);        
        
        setst_id('');
        setadm_no('');
        setst_name_display('');
        setst_father_display('');
        setst_class_display('');
        setst_sec_display('');
        setst_stream_display('');
        
        setSumAmount('');
        
        // set the default row 

        let v_temp_list = [];
        for (var i = 0; i <= 1; i++) {
            let v_data = {ischecked : false, t1_id: i * -1 , "id": 0,
                            from_fee_month:0, from_fee_month_descr:'', to_fee_month:0, to_fee_month_descr:'', fee_code_id:0, fee_code_id_descr:'', discount_amt:'', discount_reason:0, discount_reason_descr:0, notes:''                        
                         };
            v_temp_list.push(v_data);
        }        

        setTabID(i);
        setItems(v_temp_list);
    }

    const handleInputValueFun = (name, value) => {
        if (name === 'notes') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 500);
            setnotes(capitalizeWordFun(v_input_result.v_value));            
            return;
        }
    }

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target; 
        
        let insupdel_type = '';       
        
        if (value === 'DELETE'){

            // console.log("IDArray.length >> ", IDArray.length);

            if (IDArray.length === 0){
                toast.info(WhenRecordNotFoundToDelete, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            insupdel_type = 'DELETE';
        }else{
            if (TableRecordID === 0){
                insupdel_type = 'INSERT';            
            }else{

                if (TableRecordID === 0){
                    toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return;
                }

                if (userAuthData.allow_update === 1){
                    setAllowUpdateTag(false);
                    insupdel_type = 'UPDATE';
                }else{
                    setAllowUpdateTag(true);
                    return;
                }                  
            }
        }

        if (value != 'DELETE'){

            // need to validate the date here
            
            if (TableRecordID === 0){
                toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

        }

        // to filter data where amount is not entered then save

        const filteredData = items.filter(item => {return item.dr_amt != '';});       

        const data = {
            id: TableRecordID,
            insupdel_type, 
            // under_comp_group_id : ReduxGlobalData[0].ag,         
            under_comp_branch_id : ReduxGlobalData[0].br,
            fin_year : ReduxGlobalData[0].fy,                                                         
            student_id: st_id,
            // notes:'', 
            // show_in_list : 1, 
            active_status : 1,       
            items: JSON.stringify(filteredData),            
        };
        
        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }    
    }    

    const setStudentModelFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setSearchStudentDetailsPopUp(false);
        setConditionalCSS(false);

        // console.log(p_data)

        setTableRecordID(p_data.tempStudentid);

        setst_id(p_data.tempStudentid);
        setadm_no(p_data.tempAdmNo);
        setst_name_display(p_data.tempStudentName);
        setst_father_display(p_data.tempFatherName);
        setst_class_display(p_data.tempClass);
        setst_sec_display(p_data.tempSec);
        setst_stream_display(p_data.tempStream);

        getSavedDetailFunction(p_data.tempStudentid);
    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);        

        if (p_data.ModelYesNoAnswer === 'YES'){
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async(p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final:p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final:p_last_updel_notes_final,
        }        

        const v_token = Cookies.get('token');

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });
        
        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }
    
    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }     

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                    
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);
            
            // console.log("updatedata.TabID.v_data >> ", updatedata.v_data);
            
            if (updatedata.transType === 'INSERT'){
                setdisableDelete(false);             
                setsaveUpdateTag('UPDATE');
                v_toast_msg = createdMessage;

                getSavedDetailFunction(st_id);
            }else if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;

                getSavedDetailFunction(st_id);
            }else if (updatedata.transType === 'DELETE'){
                setdisableSave(true);
                setdisableDelete(true);   
                v_toast_msg = deletedMessage;
                // setBlankFields();
                getSavedDetailFunction(st_id);
            }

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }

    const setNewRecordIDFunction = async(TabID, vou_no) => {
        setTableRecordID(await TabID);        
    }

    const getSavedDetailFunction = async (p_id) => {        
        
        const v_token = Cookies.get('token');        

        response = await axios.get(`url_fetch_data_branch_finyear_id_wise/13_url_fee_concession_view_fun/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${p_id}`,
            { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;

        // console.log("updatedata >> ", updatedata);

        setItems(updatedata);        

        if (userAuthData.allow_update === 1){
            setAllowUpdateTag(false);
        }else{
            setAllowUpdateTag(true);
        }

    }

    const WhenNoDataFoundFunction = (p_data) => {
     
        // console.log("When no data >> ", p_data.selected_items);

        if(p_data.p_calling_fun_type === 'NO_DATA_FOUND'){
            SetDataAfterReturnFromSearchList(p_data);
        }
    }

    const onBlurSetDataFunction = (p_data) => {
        // console.log("On Blur selected_items Data >> ", p_data);

        if(p_data.p_calling_fun_type === 'CALL_WHEN_KEY_PRESS'){
            SetDataAfterReturnFromSearchList(p_data);
        }
    }

    const setPopUpDDFalseFunction = (p_data) => {

        // console.log("Return selected_items Data >> ", p_data.selected_items);
       
        SetDataAfterReturnFromSearchList(p_data);

        setShowPopUp(false);
        setConditionalCSS(false);        
    }

    const SetDataAfterReturnFromSearchList = (p_data) => {
        
        // console.log("Selected_items Data >> ", p_data.SelectedIDArray, p_data.SelectedIDArrayValue);        

        if (p_data.columnType === 'SINGLE_TABLE' || p_data.columnType === 'MULTIPLE_TABLE'){           

            const list = [...items];

            if (selectedColumn === 'from_fee_month_descr'){
                list[selectedrow]['from_fee_month'] = p_data.SelectedIDArray;
                list[selectedrow]['from_fee_month_descr'] = p_data.SelectedIDArrayValue; 

            }

            if (selectedColumn === 'to_fee_month_descr'){
                list[selectedrow]['to_fee_month'] = p_data.SelectedIDArray;
                list[selectedrow]['to_fee_month_descr'] = p_data.SelectedIDArrayValue; 

            }

            if (selectedColumn === 'fee_code_id_descr'){
                list[selectedrow]['fee_code_id'] = p_data.SelectedIDArray;
                list[selectedrow]['fee_code_id_descr'] = p_data.SelectedIDArrayValue; 

            }

            if (selectedColumn === 'discount_reason_descr'){
                list[selectedrow]['discount_reason'] = p_data.SelectedIDArray;
                list[selectedrow]['discount_reason_descr'] = p_data.SelectedIDArrayValue; 

            }

        }

        if (p_data.columnType === 'SINGLE_NOT_TABLE' || p_data.columnType === 'MULTIPLE_NOT_TABLE'){
            // if (p_data.selectedColumnName === 'vou_type_id_descr'){
            //     setvou_type_id(p_data.SelectedIDArray);
            //     setvou_type_id_descr(p_data.SelectedIDArrayValue);
            // }
        }
    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        // console.log("handleInputFocus value >> ", value)

        if (name === 'adm_no'){            
            setSearchStudentDetailsPopUp(true);
            setSelectedColumn('adm_no');
            return;
        }else{
            setShowPopUp(false);
            return;
        }
    };

    const handleInputValue = (event) => {
        event.preventDefault();
        // const { name, value } = event.target;

        // console.log('in handleInputValue function')

        // if (value.toString().trim().length === 0){
        //     if (name === 'adm_no'){            
        //         setvou_type_id(0);                                
        //     }else if (name === 'adm_no'){            
        //         setac_led_id_1(0);                
        //     }
        // }

        // handleInputValueFun(name, value);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        if (value === 'ADD_NEW_ROW'){
            
            if(AllowUpdateTag){return;}
            
            addItem();
            return;
        }else if (value === 'BTN_NEW'){            
            setBlankFields();
            return;
        }else if (value === 'BTN_PRINT'){
            
            if (TableRecordID === 0) {
                toast.error(whenStudentNotFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }
            
            return;
        }else if (value === 'BTN_EXPORT_EXCEL'){
                        
            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        }else if (value === 'BTN_EXPORT_PDF'){
            
            return;
        }else if (value === 'BTN_FIND'){            
            // navigate(`${v_findToNavigateName}`); 
            return;
        }else if (value === 'BTN_COPY_TO_BRANCH'){
            return;
        }else if (value === 'BTN_ASSIGN_BRANCH'){
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };
    
    const goCheckDataFunction = (event) => {
        event.preventDefault();        
        
        console.log("Selected IDArray >> ", IDArray);
       
        // console.log("final result is :",v_sum);
    }

    // For details entry (entry in table)
    // For details entry (entry in table)
    
    const handlerChangeTableRowFocus = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;

        // console.log("In Focus >> ", i, name, p_col_no)        

        // if (name === 'querySelDesc' || name === 'fee_descr'){

        setSelectedrow(i);
        setSelectedColumn(p_col_name);

        if (p_col_name === 'from_fee_month_descr' || p_col_name === 'to_fee_month_descr' || p_col_name === 'fee_code_id_descr'){
            setShowPopUp(true);
        }else{            
            setShowPopUp(false);
        }

    };

    const addItem = () => {
        setTabID(tabID + 1);
        setItems([...items, {ischecked : false, t1_id: tabID * -1 , "id": 0, 
                    from_fee_month:0, from_fee_month_descr:'', to_fee_month:0, to_fee_month_descr:'', fee_code_id:0, fee_code_id_descr:'', discount_amt:'', discount_reason:0, discount_reason_descr:0, notes:''
                }]);        
    };

    const handlerChange = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;
        // const list = [...items];        
        // list[i][name] = value;
        // setItems(list);
        // console.log(event.target, " >> ", i);

        let v_value = value;       

        setSelectedrow(i);
        setSelectedColumn(p_col_name);
        const list = [...items];

        if (p_col_name === 'from_fee_month_descr' || p_col_name === 'to_fee_month_descr' || p_col_name === 'fee_code_id_descr'){        
            
            if (v_value.toString().trim().length === 0){                

                if (p_col_name === 'from_fee_month_descr'){                    
                    list[i]['from_fee_month'] = 0;                    
                }

                if (p_col_name === 'to_fee_month_descr'){                    
                    list[i]['to_fee_month'] = 0;                    
                }

                if (p_col_name === 'fee_code_id_descr'){                    
                    list[i]['fee_code_id'] = 0;                    
                }

            }

            // console.log("v_value is >> ", v_value.toString().trim().length)

            setShowPopUp(true);
        }else{
            
            setShowPopUp(false);            

            if (name === 'discount_amt'){
                v_input_result = ValidateInput('CHECK_NUMBER_ONLY', v_value, '', 100);
                v_value = v_input_result.v_value;
            }else if (name === 'notes'){                
                v_value = capitalizeWordFun(v_value);
            }
            
        }

        list[i][name] = v_value; 
        setItems(list);

        if (name === 'discount_amt'){
            CalculateSumFunction(list);
        }

    };

    const CalculateSumFunction = (p_items) => {
        // let v_sum = 0;

        // for (let i = 0; i < p_items.length; i++) {
        //     if (p_items[i].discount_amt.toString().trim().length > 0){
        //         v_sum = parseInt(v_sum) + parseInt(p_items[i].discount_amt);   
        //     }
        // }
        // setSumAmount(v_sum)
    }

    const onClickTableRowFun = (event, p_id, p_value) => {
        event.preventDefault();

        // console.log("When Click on SHOW_RECORD >> ", saveUpdateTag);        

        if (p_value === 'ADD_NEW_ROW') {

            // console.log("When Row ID >> ", p_id);
            
            if(AllowUpdateTag){return};

            setTabID(tabID + 1);
            let v_data = {ischecked : false, t1_id: tabID * -1 , "id": 0, 
                            from_fee_month:0, from_fee_month_descr:'', to_fee_month:0, to_fee_month_descr:'', fee_code_id:0, fee_code_id_descr:'', 
                            discount_amt:'', discount_reason:0, discount_reason_descr:0, notes:''                        
                        };
    
            items.splice(p_id + 1, 0, v_data);

        }else{
            ischeckedTick(p_id);
        }

    };

    const ischeckedTick = (p_id) => {

        let v_check_value = true;

        // console.log("In Table Row Click : ", p_id);

        // console.log("In Row Tick IDArray Before >> ", IDArray);

        // const filteredData = items.filter((item) => item.id.includes(p_id));

        const filteredData = items.find(item => {return item.id === p_id});        

        // console.log("In Table Row Click filteredData : ", filteredData.ischecked);

        if (filteredData.ischecked) {
            v_check_value = false;
            const index = IDArray.indexOf(p_id);
            IDArray.splice(index, 1);
            IDArrayValue.splice(index, 1);
        } else {
            v_check_value = true;
            IDArray.push(filteredData.id);
            // IDArrayValue.push(filteredData.st_class);
        }

        for (let i = 0; i < items.length; i++) {
            const checkedvalue = items.map((user) => user.id.toString() === p_id.toString() ? { ...user, ischecked: v_check_value } : user);
            setItems(checkedvalue);
        }

        SelectedValueConvertToStr(IDArray, IDArrayValue);

    }

    const SelectedValueConvertToStr = (p_tempIDList, p_tempValueList) => {

        // console.log("In Convert >> ", p_tempList.length, ' >> ', p_tempList);

        let tempIDArrayStr = '', tempIDArrayValueStr = '';

        for (let i = 0; i < p_tempIDList.length; i++) {
            if (i === 0) {
                tempIDArrayStr = p_tempIDList[i];
                tempIDArrayValueStr = p_tempValueList[i];
            } else {
                tempIDArrayStr = tempIDArrayStr + "," + p_tempIDList[i];
                tempIDArrayValueStr = tempIDArrayValueStr + "," + p_tempValueList[i];
            }
        }

        setIDArray(p_tempIDList);
        setIDArrayValue(p_tempValueList);
    }

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        if(AllowUpdateTag){return};        

        let tempListID = [];        

        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, ischecked: checked } });
            setItems(checkedvalue);

            setIDArray([]);            

            if (checked) {
                for (let i = 0; i < items.length; i++) {
                    tempListID.push(items[i].id);
                    // tempListValue.push(items[i].st_class);
                }
            }

            setIDArray(tempListID);
        }
        else {                     

            const checkedvalue = items.map((user) => user.t1_id.toString() === name ? { ...user, ischecked: checked } : user);
            setItems(checkedvalue);

            setIDArray([]);

            for (let i = 0; i < checkedvalue.length; i++) {

                if(checkedvalue[i].ischecked){
                    tempListID.push(checkedvalue[i].id);
                }
            }

            setIDArray(tempListID);

        }

        // SelectedValueConvertToStr(tempListID, tempListValue);
    }

    const setShowPopUpFalseFunction = (event) => {        
        // setShowPopUp(false);
    };

    const handleInputBlur = (event, i, p_col_name) => {
        // event.preventDefault();
        // const { name, value } = event.target;

        // console.log("handleInputBlur >> ", value);

        // // setSelectedrow(i);
        // // setSelectedColumn(p_col_name);

        // if (p_col_name === 'FEE_DETAIL_FEE_MONTH_DESCR' || p_col_name === 'FEE_DETAIL_FEE_CODE_DESCR'){
            

            
        //     setShowPopUp(true);
        // }else{
            
        //     setShowPopUp(false);
        // }

        // console.log('Input field lost focus');

    };

return (

<>

    {
        ShowSearchStudentDetailsPopUp ?
            <div className="w-[100%] h-[12%] absolute">
                <SearchStudentDetails transType={TransType} p_st_items={StudentListitems} setStudentModelFalseFunction={setStudentModelFalseFunction} />
            </div>
            : null
    }

    {
        ShowConfirmModelSave ? 
        <div className="centerWindow_css">
            <ConfirmModelSave transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

    {
        ShowConfirmModelUpdateDelete ? 
        <div className="centerWindow_css w-[32%]">
            <ConfirmModelUpdateDelete transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} onMouseOut={(e) => setShowPopUpFalseFunction(e)} autoComplete="off">

    <div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

    <div className="form_div_main_css">
        <div className="form_div_inner_css grid grid-cols-7">

            {/* <div>
                <label className="label_inner_page_css text-center">Student ID</label>
                <div className="input_inner_page_css text-center font-semibold">{st_id}</div>
            </div>                                     */}

            <div>
                <label className="label_inner_page_css required_css text-center">Adm.No.</label>
                <input className="input_inner_page_css text-center font-semibold"
                    type="text"
                    ref={(ref) => addInputRef(ref, 5)}
                    name="adm_no"
                    id="adm_no"
                    value={adm_no}                    
                    onChange={handleInputValue}                                    
                    onFocus={handleInputFocus}
                    disabled = {saveUpdateTag === 'SAVE' ? false : true}
                ></input>                
            </div>                  

            <div className="col-span-2">
                <label className="label_inner_page_css">Name</label>
                <div className="input_inner_page_css font-semibold">{st_name_display}</div>
            </div>

            <div className="col-span-2">
                <label className="label_inner_page_css">Father Name</label>
                <div className="input_inner_page_css font-semibold">{st_father_display}</div>
            </div>

            <div>
                <label className="label_inner_page_css text-center">Class & Sec.</label>
                <div className="input_inner_page_css text-center font-semibold">{st_class_display} - {st_sec_display}</div>
            </div>            

            <div>
                <label className="label_inner_page_css text-center">Stream</label>
                <div className="input_inner_page_css text-center font-semibold">{st_stream_display}</div>
            </div>
                       
        </div>        
        
    </div>

    <table className="table_main_css">
        <thead className="table_thead_css">            
            <tr className="table_thead_tr_css">                

                <th className="table_th_td_css text-left w-[4%]"></th>
                <th className="table_th_td_css text-left w-[3%]"></th>

                <th className="table_th_td_css w-[5%]">
                    <input className="table_check_inner_page_css"
                        type="checkbox" name="allselect" checked={!items.some((user) => user?.ischecked !== true)}
                        onChange={handleChangeCheckBox}
                        onFocus={handleInputFocus}                        
                    />
                </th>
                
                <th className="table_th_td_css w-[12%]">From Month</th>
                <th className="table_th_td_css w-[12%]">To Month</th>
                <th className="table_th_td_css text-left w-[22%]">Fee Description</th>                
                <th className="table_th_td_css text-right w-[10%]">Amount</th>
                <th className="table_th_td_css text-left w-[29%]">Notes</th>
                <th className="table_th_td_css text-left w-[3%]"></th>
            </tr>
        </thead>

        <tbody className="table_tbody_css h-[32vh]">
        {
            items?.map((item, index) => (                   

            <tr className="table_thead_tr_css table_tbody_hover_css" key={index}>

                <td className="table_th_td_css text-left w-[4%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    {index + 1}
                    {/* {item.t1_id} */}
                </td>

                {
                    (item.t1_id <= 0) ?
                    <td className="table_th_td_css text-center w-[3%]" onClick={() => { setItems(items.filter(a => a.t1_id !== item.t1_id)); }} onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                        <FontAwesomeIcon className="text-blue-600 text-1xl" icon={faTrash}/>
                    </td>                            
                    :  
                    <td className="table_th_td_css text-center w-[3%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                        {/* <FontAwesomeIcon className="text-blue-600 text-1xl" icon={faTrash}/> */}
                    </td>                            
                }  
                
                <td className="table_th_td_css w-[5%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <input className="table_check_inner_page_css"                                
                        ref={(ref) => addInputRef(ref, index + 8)}
                        type="checkbox" name={item.t1_id}
                        checked={item?.ischecked || false}
                        onChange={handleChangeCheckBox}                                                                
                    />
                </td>

                {/* <th className="table_th_td_css text-center w-[4%]">{item.from_fee_month}</th>                 */}

                <td className="table_input_th_td_css text-center w-[12%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, 'from_fee_month_descr')}>
                    <input className="table_input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 10)}
                        name="from_fee_month_descr"
                        id="from_fee_month_descr"
                        value={item.from_fee_month_descr}
                        onChange={(e) => handlerChange(e, index, 'from_fee_month_descr')}
                        disabled = {AllowUpdateTag}
                    ></input>
                    {
                        ShowPopUp & selectedrow === index & selectedColumn === 'from_fee_month_descr' ? 
                            <div className="absolute w-[12%]"> 
                                <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                    onBlurSetDataFunction = {onBlurSetDataFunction}
                                    WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                    items={pay_fee_monthItems}
                                    columnName='from_fee_monthTabColumn'
                                    columnType='SINGLE_TABLE'
                                    PropsIDArray={[item.from_fee_month]}
                                    PropsIDArrayValueStr={[item.from_fee_month_descr]}
                                />
                            </div>
                        :null
                    }
                </td>

                {/* <th className="table_th_td_css text-center w-[4%]">{item.to_fee_month}</th> */}

                <td className="table_input_th_td_css text-center w-[12%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, 'to_fee_month_descr')}>
                    <input className="table_input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 10)}
                        name="to_fee_month_descr"
                        id="to_fee_month_descr"
                        value={item.to_fee_month_descr}
                        onChange={(e) => handlerChange(e, index, 'to_fee_month_descr')}
                        disabled = {AllowUpdateTag}
                    ></input>
                    {
                        ShowPopUp & selectedrow === index & selectedColumn === 'to_fee_month_descr' ? 
                            <div className="absolute w-[12%]"> 
                                <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                    onBlurSetDataFunction = {onBlurSetDataFunction}
                                    WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                    items={pay_fee_monthItems}
                                    columnName='to_fee_monthTabColumn'
                                    columnType='SINGLE_TABLE'
                                    PropsIDArray={[item.to_fee_month]}
                                    PropsIDArrayValueStr={[item.to_fee_month_descr]}
                                />
                            </div>
                        :null
                    }
                </td> 

                {/* <th className="table_th_td_css text-center w-[4%]">{item.fee_code_id}</th>                                      */}

                <td className="table_input_th_td_css text-center w-[22%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, 'fee_code_id_descr')}>
                    <input className="table_input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 10)}
                        name="fee_code_id_descr"
                        id="fee_code_id_descr"
                        value={item.fee_code_id_descr}
                        onChange={(e) => handlerChange(e, index, 'fee_code_id_descr')}
                        disabled = {AllowUpdateTag}
                    ></input>

                    {
                        ShowPopUp & selectedrow === index & selectedColumn === 'fee_code_id_descr' ? 
                            <div className="absolute w-[22%]"> 
                                <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                    onBlurSetDataFunction = {onBlurSetDataFunction}
                                    WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                    items={fee_codeItems}
                                    columnName='fee_code_idTabColumn'
                                    columnType='SINGLE_TABLE'
                                    PropsIDArray={[item.fee_code_id]}
                                    PropsIDArrayValueStr={[item.fee_code_id_descr]}
                                />
                            </div>
                        :null
                    }
                </td>

                <td className="table_input_th_td_css w-[10%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <input className="table_input_inner_page_css text-right"
                        type="text"
                        ref={(ref) => addInputRef(ref, 11)}
                        name="discount_amt"
                        id="discount_amt"
                        value={item.discount_amt}                                                 
                        onChange={(e) => handlerChange(e, index)}
                        disabled = {AllowUpdateTag}                                          
                    ></input>
                </td>
                
                {/* <td className="table_input_th_td_css text-center w-[22%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, 'discount_reason_descr')}>
                    <input className="table_input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 10)}
                        name="discount_reason_descr"
                        id="discount_reason_descr"
                        value={item.discount_reason_descr}
                        onChange={(e) => handlerChange(e, index, 'discount_reason_descr')}
                        disabled = {AllowUpdateTag}
                    ></input>

                    {
                        ShowPopUp & selectedrow === index & selectedColumn === 'discount_reason_descr' ? 
                            <div className="absolute w-[22%]"> 
                                <SearchListDropdown setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                                    onBlurSetDataFunction = {onBlurSetDataFunction}
                                    WhenNoDataFoundFunction = {WhenNoDataFoundFunction}
                                    items={fee_codeItems}
                                    columnName='discount_reasonTabColumn'
                                    columnType='SINGLE_TABLE'
                                    PropsIDArray={[item.discount_reason]}
                                    PropsIDArrayValueStr={[item.discount_reason_descr]}
                                />
                            </div>
                        :null
                    }
                </td> */}

                <td className="table_input_th_td_css text-left w-[29%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <input className="table_input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 15)}
                        name="notes"
                        id="notes"
                        value={item.notes}                                                                   
                        onChange={(e) => handlerChange(e, index)}                                
                        disabled = {AllowUpdateTag}
                    ></input>
                </td>                

                <td className="table_th_td_css text-center w-[3%]" onClick={(e) => onClickTableRowFun(e, index, 'ADD_NEW_ROW', item.isChecked)} onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <FontAwesomeIcon className="text-blue-600 text-2xl align-middle" icon={faPlus}/>                            
                </td>

            </tr>
            ))
        }
        </tbody>
    </table>

    <nav className="btn_nav_inner_css">
    <div className="btn_div_main_css">       

        <div className="btn_div_inner_css">

                <div>
                    {!AllowUpdateTag && userAuthData.allow_new === 1 && (
                        <button className="btn_new_css"
                            value = 'ADD_NEW_ROW'
                            onClick={handleButtonValue}>
                            New Row
                        </button>
                    )}
                </div>

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_new_css"
                            value = 'BTN_NEW'
                            onClick={handleButtonValue}>
                            New
                        </button>
                    )}
                </div>

                <div>
                    {!AllowUpdateTag && userAuthData.allow_new === 1 && (
                        <button className="btn_save_css"
                            disabled={disableSave}
                            onClick={createRecordFunction}>
                            Save
                        </button>
                    )}
                </div>

                <div>              
                    {userAuthData.allow_delete === 1 && (
                        <button className="btn_delete_css"
                            value = 'DELETE'
                            // disabled={disableDelete}
                            onClick={createRecordFunction}>
                            Delete
                        </button>
                    )}
                </div>               
                    
                {/* <div>
                    {AllowUpdateTag && userAuthData.print === 1 && (
                        <button className="btn_print_css"
                            value = 'BTN_PRINT'
                            onClick={handleButtonValue}>
                            Print
                        </button>
                    )}
                </div> */}

                {/* <div>
                    {userAuthData.export_excel === 1 && (
                        <button className="btn_export_excel_css"
                            value = 'BTN_EXPORT_EXCEL'
                            onClick={handleButtonValue}>
                            Export Excel
                        </button>
                    )}
                </div> */}
                
                {/* <div>
                    {userAuthData.export_pdf === 1 && (
                        <button className="btn_export_pdf_css"
                            value = 'BTN_EXPORT_PDF'
                            onClick={handleButtonValue}>
                            Export PDF
                        </button>
                    )}
                </div> */}

                {/* <div>
                    {userAuthData.allow_read === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_FIND'
                            onClick={handleButtonValue}>
                            Find
                        </button>
                    )}
                </div> */}

                {/* <div>
                    {userAuthData.copy_to_branch === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_COPY_TO_BRANCH'
                            onClick={handleButtonValue}>
                            Copy To Branch
                        </button>
                    )}
                </div> */}

                {/* <div>
                    {userAuthData.assign_branch === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_ASSIGN_BRANCH'
                            onClick={handleButtonValue}>
                            Assigned Branch
                        </button>
                    )}
                </div> */}        

                <div>
                    <button className="btn_back_css"
                        value = 'BTN_BACK'
                        onClick={handleButtonValue}>
                        Back
                    </button>
                </div>

                {/* <div>                
                    <button className="btn_back_css"
                        onClick={goCheckDataFunction}>
                        Check Data
                    </button>
                </div> */}
                
        </div>
        
    </div>
    </nav>

    </form>
:null
}

</>
);};

export default StudentFeeConcession;

{/* <div class="mx-auto max-w-xs">
    <div>
        <div class="relative">
        <input type="text" id="example13" placeholder=" " class="peer block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500" />
        <label for="example13" class="peer-focus:base absolute left-2 top-0 z-10 -translate-y-2 transform bg-white px-1 text-xs text-gray-500 transition-all peer-placeholder-shown:translate-y-3 peer-placeholder-shown:text-sm peer-focus:-translate-y-2 peer-focus:text-xs peer-disabled:bg-transparent">Full name</label>
        </div>
    </div>
</div> */}
