import axios from 'axios';
import Cookies from 'js-cookie';

export function addNumbers(a, b) {
    return a + b;
}

export function capitalizeWordFun (str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
};

export function ValidateInput (p_type = null, p_value = null, p_1 = null, p_length = 0) {   

        let v_error;

        v_error = {v_truefalse : false, v_value : p_value, v_msg : ''};
        
        if (p_type === 'ENTRY_REQUIRED'){
            if(parseInt(p_value.length) === 0){
                v_error = {v_truefalse : true, v_value : p_value, v_msg : 'Entry required for this field !'}                
            }
        }else if (p_type === 'MINIMUM_LENGTH'){
            if(parseInt(p_value.length) < p_length){
                v_error = {v_truefalse : true, v_value : p_value, v_msg : 'Minimum length ' + p_length + ' required for this field !'}                
            }
        }else if (p_type === 'MAX_LENGTH'){
            if(parseInt(p_value.length) > p_length){
                v_error = {v_truefalse : true, v_value : p_value, v_msg : 'Maximum length ' + p_length + ' required for this field !'}
            }
        }else if (p_type === 'CHECK_ALPHA_NUMBER'){

            let v_check_list = " 0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz@#$(){}[]\><,./";
            let value1 = '';

            if (p_value.length > 0){

                for (var i=0; i < p_value.length; i++) {
                    let result = v_check_list.includes(p_value[i]);

                    if (result){
                        value1 = value1 + p_value[i];
                    }
                }                
                v_error = {v_truefalse : true, v_value : value1, v_msg : ''}
            }
        }else if (p_type === 'CHECK_NUMBER_ONLY'){            

            let v_check_list = "0123456789";           

            if (p_value.length > 0){

                for (var i=0; i < p_value.length; i++) {
                    let result = v_check_list.includes(p_value[i]);

                    if (!result){
                        const value1 = p_value.replace(/\D/g, "");            
                        v_error = {v_truefalse : true, v_value : value1, v_msg : 'Only number is allow for this field !'}
                    }
                }
            }
        }

        return v_error;
}

export const GetFormAuthDetails = async (p_user_id, p_frm_no) => {        

        const v_token = Cookies.get('token');

        let response = [], updatedata = [];

        response = await axios.get(`url_getAuthUserFun/${p_frm_no}/${p_user_id}`,
        { headers: { Authorization: `Bearer ${v_token}` } });       

        updatedata = await response.data.v_out_data;

        // console.log("updatedata >> ", updatedata)

        if (updatedata.v_status.toString().trim() === '401' || updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405') { 
            Cookies.set('token', '');
            localStorage.clear();
        }
         
        // console.log("After Authorized User in Global Function ! ", userAuthData);        

        return updatedata.v_data[0];

}
