import React from 'react';
import styles from './Menu.module.css';
import MenuItem from './MenuItem';

function SubMenuDropdown({submenu, depth, showmenu}){
    return(
        <>
            <ul className={`${styles.subMenuDropdown} ${depth > 1 ? styles.subMenuDropdownStyle:""} ${showmenu?styles.showMenu:styles.hideMenu}`}>
                {submenu.map((menu) => (
                    <MenuItem item = {menu} key = {menu.id} depth = {depth + 1} />
                ))}
            </ul>        
        </>
    )
}

export default SubMenuDropdown;

