import React, { useContext, useState, useRef } from "react";
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import CRUDContext from './CRUDContext';

import ConfirmModelSave from './ConfirmModelSave';

import PasswordChecklist from "react-password-checklist";
import validator from 'validator';

import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { emptyGlobalDataFun } from './redux/actions';

const ChangePassword = (props) => {
    const dispatch = useDispatch();
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_transType = 'UPDATE', v_toast_msg = '';
    const [ListData, setListData] = useState([]);
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);    
    const [last_access_ip_final, setlast_access_ip_final] = useState("");
    const [last_updel_notes_final, setlast_updel_notes_final] = useState("");    
    const router = useNavigate();

    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");

    const {
        updatedMessage,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,
    } = useContext(CRUDContext); 
    
    const createRecordFunction = (event) => {
        event.preventDefault();

        if (password === passwordAgain){
            // console.log("Match")

            // alert("Password is match !")

        }else{
            
            v_toast_msg = "New password and confirm password is not match !";

            toast.error(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            return;
        }

        if (validator.isStrongPassword(passwordAgain, {minLength: 6, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1})) 
        {
            // console.log("Strong Password")
        } else {                    
            // console.log("Not Strong Password")            
            
            v_toast_msg = "Password is not valid, please check the minimum requirment !";

            toast.error(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });            

            return;
        }

        const data = {                
            user_id: localStorage.getItem('id'),
            newpassword:password
        };

        setListData(data);
        setShowConfirmModelSave(true);
    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        
        if (p_data.ModelYesNoAnswer === 'YES'){

            setlast_access_ip_final(p_data.last_access_ip_final);
            setlast_updel_notes_final(p_data.last_updel_notes_final);

            SaveRecordFunction();
        }
    }

    const SaveRecordFunction = async() => {

        // console.log("In SaveRecordFunction")        

        const listFinalData = {
            ListData,
            last_access_ip_final,
            'last_access_by_final': localStorage.getItem('id'),
            last_updel_notes_final,
        }        

        response = await axios.post(`url_passwordchanged_proc/`, listFinalData, 
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        
        updatedata = await response.data.v_out_data;

        // console.log("1. updatedata >> ", updatedata.v_status, updatedata.v_status.toString().trim().length)
        
        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }else{

            // set the ID of this record

            setTableRecordID(await updatedata.TabID);      
            
            if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;
            }

            v_toast_msg = "Password has been changed successfully. Please login again to continue !";

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });


            // clear all the cookies and redux data            

            Cookies.set('token', '');
            localStorage.clear();            
            dispatch(emptyGlobalDataFun());            

            if (props.setModelFalseFunction !== undefined) {
                props.setModelFalseFunction();
            }

            // if (props.setModelFalseFunction !== undefined) {
            //     console.log('1 prop was passed');
            // } else {
            //     console.log('1 prop was NOT passed');
            // }

            // if (props.hasOwnProperty("setModelFalseFunction")) {
            //     console.log('prop was passed');
            // } else {
            //     console.log('prop was NOT passed');
            // }
                        
            router('/Login');

            // BillKps@5001
        }
    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        // to be used for show pop single or multi select

        // const { name, value } = event.target;        
    };
    
    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        // console.log('in handleInputValue function')

        handleInputValueFun(name, value);
    }

    const handleInputValueFun = (name, value) => {

        if (name === 'password') {
            let v_pre_val = password;

            // const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            // const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
            // const numberChars = "0123456789";
            // const symbolChars = '!@#$%&*+=<>?/';

            let v_ok = '', v_str = value;

            let v_validate_char = '^!<*&@>+=ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

            for (let i in (v_str)){

                let char_count = v_validate_char.split(v_str[i]).length - 1;            

                if (char_count === 0){
                    v_ok = 0;                

                    // toast.error("This special character is not allow in the password. Please use only suggested special character only." + v_str.slice(-1));

                    // toast.error(v_str.slice(-1) + " This special character is not allow in the password. Please use only suggested special characters.");

                    v_toast_msg = v_str.slice(-1) + " This special character is not allow in the password. Please use only suggested special characters.";

                    toast.error(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });                    

                }else{
                    v_ok = 1;
                }
            }

            if (v_ok === 1){
                setPassword(value);
            }else if (v_ok === ''){
                setPassword('');
            }else{
                setPassword(v_pre_val);
            }        

            return;            
        }else if (name === 'passwordAgain'){

            let v_pre_val = passwordAgain;

            // const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            // const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
            // const numberChars = "0123456789";
            // const symbolChars = '!@#$%&*+=<>?/';
    
            let v_ok = '', v_str = value;
    
            let v_validate_char = '^!<*&@>+=ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    
            for (let i in (v_str)){
    
                let char_count = v_validate_char.split(v_str[i]).length - 1;            
    
                if (char_count === 0){
                    v_ok = 0;                
    
                    // toast.error("This special character is not allow in the password. Please use only suggested special character only." + v_str.slice(-1));
    
                    // toast.error(v_str.slice(-1) + " This special character is not allow in the password. Please use only suggested special characters.");

                    v_toast_msg = v_str.slice(-1) + " This special character is not allow in the password. Please use only suggested special characters.";

                    toast.error(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    
    
                }else{
                    v_ok = 1;
                }
            }
    
            if (v_ok === 1){
                setPasswordAgain(value);
            }else if (v_ok === ''){
                setPasswordAgain('');
            }else{
                setPasswordAgain(v_pre_val);
            }   

        }        
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };
    
return (
<>
{
    ShowConfirmModelSave ? 
    <div className="centerWindow_css">
        <ConfirmModelSave transType = {v_transType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>
    </div>
    : null
}

<div className="centerWindow_inner_form_css">
    <h1 className="h1_model_saveupdel_yes_css">Change Password !</h1>

    <div>
        <label htmlFor="adm_no" className="label_inner_page_css required_css">New Password</label>
        <input className="input_inner_page_css"
            ref={(ref) => addInputRef(ref, 0)}
            type="text"                                
            name="password"
            id="password"
            value={password}
            // placeholder="password"                    
            onChange={handleInputValue}
            // onKeyDown={handleEnter}
            onFocus={handleInputFocus}
            // onBlur={handleInputBlur}
            // disabled
        ></input>
    </div>

    <div>
        <label htmlFor="adm_no" className="label_inner_page_css required_css">Confirm New-Password</label>
        <input className="input_inner_page_css"
            ref={(ref) => addInputRef(ref, 0)}
            type="text"                                
            name="passwordAgain"
            id="passwordAgain"
            value={passwordAgain}
            // placeholder="passwordAgain"                    
            onChange={handleInputValue}
            // onKeyDown={handleEnter}
            onFocus={handleInputFocus}
            // onBlur={handleInputBlur}
            // disabled
        ></input>
    </div>

    <div>
        <PasswordChecklist rules={["minLength","specialChar","number","capital","match"]}
            minLength={6}
            value={password}
            valueAgain={passwordAgain}
        />
    </div>

    <div>
        <label className = "label_inner_page_css">Please use below special character only for creating strong password</label>
        <label className = "label_inner_page_css text-center">(you can take one or more also)</label>
        <label className = "label_inner_page_css text-center">&#62;  ^  !  *  & &#60; @  +  = </label>
    </div>
    
    <div className="btn_div_model_inner_css">
        <div className="btn_div_model_saveupdel_css">
            <button className="btn_model_saveupdel_yes_css" type="submit" value = 'YES' onClick={createRecordFunction}>Change Password</button>
        </div>      
    </div>
        
</div>

</>
);
};

export default ChangePassword;

{/* <div>           
    <h1 className="h2_model_saveupdel_yes_css">Change Password !</h1>

    <div>
        <label htmlFor="password" className="label_inner_page_css required_css">Adm.No.</label>
        <input className="input_inner_page_css"
            ref={(ref) => addInputRef(ref, 0)}
            type="text"                                
            name="password"
            id="password"
            value={password}
            // placeholder="Adm.No."                    
            onChange={handleInputValue}
            // onKeyDown={handleEnter}
            onFocus={handleInputFocus}
            // onBlur={handleInputBlur}
            // disabled
        ></input>
    </div>

    <div>
        <label htmlFor="passwordAgain" className="label_inner_page_css required_css">Adm.No.</label>
        <input className="input_inner_page_css"
            ref={(ref) => addInputRef(ref, 1)}
            type="text"                                
            name="passwordAgain"
            id="passwordAgain"
            value={passwordAgain}
            // placeholder="Adm.No."                    
            onChange={handleInputValue}
            // onKeyDown={handleEnter}
            onFocus={handleInputFocus}
            // onBlur={handleInputBlur}
            // disabled
        ></input>
    </div>

    <div>
        <PasswordChecklist rules={["minLength","specialChar","number","capital","match"]}
            minLength={6}
            value={password}
            valueAgain={passwordAgain}
        />
    </div>

    <div className="btn_div_model_inner_css">
        <div className="btn_div_model_saveupdel_css">
            <button className="btn_model_saveupdel_yes_css" type="submit" value = 'YES' onClick={sendDataToParentFun}>Yes</button>
        </div>

        <div className="btn_div_model_saveupdel_css">
            <button className="btn_model_saveupdel_no_css" type="submit" value = 'NO' onClick={sendDataToParentFun}>No</button>
        </div>
    </div>

    <div className="text-BackBtnHoverColor mt-5 w-[20%] flex flex-col items-center text-my-fontSize" >
        <label className = "mb-5 back max-w-full font-myFontWeight">Please use below special character only for creating strong password (you can take one or more also)</label>
        <label className = "mb-5 back max-w-full font-myFontWeight">&#62;  ^  !  *  & &#60; @  +  = </label>
    </div>

</div> */}