// Table name : ac_ledger

import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';

// import Layout from './Layout';

import moment from 'moment';

const EmployeeMaster = (props) => {
    const dispatch = useDispatch();
    const ReduxData = useSelector((state) => state);

    const [ShowModelFormDeleteUpdate, setModelFormDeleteUpdate] = useState(false);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);
    const [updateDelTag, setUpdateDelTag] = useState('');
    const [updateDelapiName, setUpdateDelapiName] = useState('');
    
    // const router = useRouter();

    const navigate = useNavigate();

    const [emp_name, setemp_name] = useState('');
    const [fat_name, setfat_name] = useState('');
    const [desig_no, setdesig_no] = useState('');
    const [salary, setsalary] = useState('');
    const [mobile_no, setmobile_no] = useState('');    
    const [notes, setnotes] = useState('');   

    const [desig_noItems, setdesig_noItems] = useState([]);     
    
    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,        
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenPageRefreshMessage,        
    } = useContext(CRUDContext);

    // ////////////////////////////

    // const getAuthroziedUser = async () => {  
    //     try{
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //               { headers: { Authorization: `Bearer ${token}` } });            
    
    //         if (response.status === 401 || response.status === 403){
    //             localStorage.clear();
    //             navigate('/Login')
    //         };

    //     }catch(error){
    //         localStorage.clear();
    //         navigate('/Login')
    //     }
    // }    

    // useEffect( () => {        
    //     getAuthroziedUser();    
    // }, []);

    // ////////////////////////////

    useEffect(() => {
        if (error) {
            toast.error(error);
            clearErrors();
        }

        if (created) {
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
        }

        if (updated) {
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
        }
    }, [error, created, updated, deleted]); 
    
    useEffect(() => {  

        let response = []
        let updatedata = []

        const getParamTableRecordFunctionBranch = async (var_link) => {                        

            response = await axios.get(`${var_link}/DESIG`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setdesig_noItems(updatedata);
        }
        getParamTableRecordFunctionBranch('url_param_view_drop_down');
    }, []);
    
    useEffect(() => {      
        let response = []
        let updatedata = []

        // to get the data when user click on view to show the existing record (from ug_view page)

        // console.log("pk_id 1: ", pk_id);

        if (pk_id > 0) {

            // console.log("pk_id 2 : ", pk_id);

            const getAllTableRecordFunction = async (var_link) => {
                response = await axios.get(`${var_link}/${localStorage.getItem('br')}/${pk_id}`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

                updatedata = await response.data.v_out_data;

                // console.log("updatedata is : ", updatedata)                

                setPkID(pk_id);               

                setemp_name(updatedata[0].emp_name);
                setfat_name(updatedata[0].fat_name);
                setdesig_no(updatedata[0].desig_no);
                setsalary(updatedata[0].salary);
                setmobile_no(updatedata[0].mobile_no);                
                setnotes(updatedata[0].notes);
            }
            getAllTableRecordFunction('url_emp_master_view_id_wise_fun');
        }

    }, [pk_id])
    
    const newBlankFieldHandler = (e) => {
        e.preventDefault();

        setPkID(0);
        
        setemp_name('');
        setfat_name('');
        setdesig_no('');
        setsalary('');
        setmobile_no('');                
        setnotes('');  
    }

    // const BranchWiseAssigmentHandler = (event) => {
    //     event.preventDefault();
    //     navigate('/EmployeeMasterBranchWise');
    // }

    const createRecordFunction = (event) => {
        event.preventDefault();

        handleInputTitleCase();

        // console.log("As on date is : ", as_on_date)
        // console.log("As on date length is : ", as_on_date.length)     
        
        // return

        if (emp_name.trim().length === 0){
            toast.info("Entry reuired for Employee Name !")
            clearErrors();
            return;
        }

        let v_desig_no = 0, v_insupdel_type = '', v_salary = 0;        

        if(desig_no === undefined || desig_no.length === undefined || desig_no.length === 0){            
            v_desig_no = 0;            
        }else{
            v_desig_no = desig_no;
        }

        if (localStorage.getItem('br') === '0' || localStorage.getItem('br').length === 0){
            toast.error("Please select branch to continiue !!!")
            clearErrors();
            return;
        }
        
        if(salary.trim().length === 0){
            v_salary = 0;        
        }else{
            v_salary = salary;
        }
        

        if (pk_id === 0) {
            v_insupdel_type = 'INSERT'
        };

        if (pk_id > 0) {
            v_insupdel_type = 'UPDATE'
        };
      
        const data = {
            id: pk_id,

            insupdel_type:v_insupdel_type,

            under_comp_group_id : localStorage.getItem('ag'),
            under_comp_branch_id : localStorage.getItem('br'),            

            emp_name,
            fat_name,
            desig_no:v_desig_no,
            salary:v_salary,
            mobile_no,
            notes,
            active_status:1
        }
        
        dispatch(emptyCart());
        dispatch(addTempList(data));

        // console.log("pk_id value is : ", pk_id)

        setUpdateDelapiName('url_emp_master_new_proc')

        if (pk_id === 0) {            
            setModelModelFormYesNo(true);
        }
        else {            
            setUpdateDelTag('UPDATE')            
            // setUpdateDelapiName('url_emp_master_new_proc')
            setModelFormDeleteUpdate(true);
        }

    }

    const FindInTablePage = (event) => {
        event.preventDefault();
        navigate('/EmployeeMasterView')
    }

    // const handleDeleteUpdateFunction = (event) => {
    //     event.preventDefault();

    //     if (ReduxData.userData[0].id.length === 0){
    //         toast.info(whenPageRefreshMessage)
    //         clearErrors();
    //         return;
    //     }

    //     const data = {
    //         id: pk_id,
    //         last_access_ip : ipAddess,
    //         last_access_by : ReduxData.userData[0].id,    
    //     };

    //     setUpdateDelTag('DELETE')
    //     setUpdateDelapiName('usergroup_delete')

    //     dispatch(emptyCart());
    //     dispatch(addTempList(data));
    //     setModelFormDeleteUpdate(true);
    // }

    const handledesig_no = (event) => {
        event.preventDefault();

        const getId = event.target.value;
        setdesig_no(getId);
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();   
        setPkID(0);  
        navigate('/');
    }

    function handleEnter(event) {
        if (event.keyCode === 13) {
          const form = event.target.form;
          const index = Array.prototype.indexOf.call(form, event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
        }
    }

    function settitleCase(str) {
        return str.toLowerCase().split(' ').map(function(word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }

    function handleInputTitleCase() {
        setemp_name(settitleCase(emp_name));
        setfat_name(settitleCase(fat_name));
        // setdesig_no(settitleCase(desig_no));
        setsalary(settitleCase(salary));
        setmobile_no(settitleCase(mobile_no));    
        setnotes(settitleCase(notes));
    }

    const handlesalaryFun = (e) => {

        // number only validation

        // const value = e.target.value.replace(/\D/g, "");
        // setopen_bal(value);

        // float only validation

        let input = e.target.value
        if(input.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/))
        setsalary(input)
    };

    const handlemobile_noFun = (e) => {

        // number only validation

        // const value = e.target.value.replace(/\D/g, "");
        // setopen_bal(value);

        // float only validation

        let input = e.target.value
        if(input.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/))
        setmobile_no(input)
    };

return (
<>
    <form>
    <div className="pt-PaddingTopAfterMenu max-w-full text-my-main-heading-fontSize bg-my-main-heading-color text-my-main-heading-font-color text-left">Employee Master</div>

    <div className="pt-4 grid grid-cols-2 gap-2">

        <div>
            <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Name</label>
            <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                name="emp_name"
                id="emp_name"
                type="text"
                // placeholder="Group Name"
                value={emp_name}
                onChange={(e) => setemp_name(e.target.value)}
                onBlur={handleInputTitleCase}
                onKeyDown={handleEnter} {...props}
            ></input>
        </div>

        <div>
            <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Father Name</label>
            <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                name="fat_name"
                id="fat_name"
                type="text"
                // placeholder="Print Name"
                value={fat_name}
                onChange={(e) => setfat_name(e.target.value)}
                onBlur={handleInputTitleCase}
                onKeyDown={handleEnter} {...props}
            ></input>
        </div>

        <div>
            <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Designation</label>
            <select className="w-[100%] border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="desig_no" value={desig_no} onChange={(e) => handledesig_no(e)}
                onKeyDown={handleEnter} {...props}>
                {/* <option></option> */}
                {
                    desig_noItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>           
        
        <div>
            <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Salary Per Month</label>
            <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                name="salary"
                id="salary"
                type="text"
                // placeholder="Notes"
                value={salary}
                // onChange={(e) => setopen_bal(e.target.value)}
                onChange={handlesalaryFun}
                onKeyDown={handleEnter} {...props}
            ></input>
        </div> 

        <div>
            <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Mobile No.</label>
            <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                name="mobile_no"
                id="mobile_no"
                type="text"
                // placeholder="mobile_no"
                value={mobile_no}
                onChange={handlemobile_noFun}
                onBlur={handleInputTitleCase}
                onKeyDown={handleEnter} {...props}
            ></input>
        </div>         

        <div>
            <label className="mb-5 max-w-full text-my-font-color font-myFontWeight">Notes</label>
            <input className="w-[100%] py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-borderWidth border-my-border-color focus:bg-my-when-focus-bg-color"
                name="notes"
                id="notes"
                type="text"
                // placeholder="Notes"
                value={notes}
                onChange={(e) => setnotes(e.target.value)}
                onBlur={handleInputTitleCase}
                onKeyDown={handleEnter} {...props}
            ></input>
        </div>                  
    </div>

    <div className="pt-4 py-my-padding-py px-my-padding-px grid grid-cols-4 gap-4">

        {/* <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
            onClick={BranchWiseAssigmentHandler}>
            Branch Wise Assignment
        </button> */}
        
        <button className="bg-NewButtonBGColor hover:bg-NewButtonHoverColor text-my-fontSize text-NewButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
            onClick={newBlankFieldHandler}>
            New
        </button>

        <button className="bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-my-fontSize text-SaveButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
            onClick={createRecordFunction}>
            Save
        </button>

        {/* <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
            onClick={handleDeleteUpdateFunction}>
            Delete
        </button> */}

        <button className="bg-FindButtonBGColor hover:bg-FindButtonHoverColor text-my-fontSize text-FindButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
            onClick={FindInTablePage}
        >
            Find
        </button>

        <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
            onClick={goPrePageFunction}>
            Back
        </button>

    </div>
    
    {/* <ModelFormDeleteUpdate api_type={updateDelTag} api_name={updateDelapiName} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)}/> */}

    <ModelFormDeleteUpdate api_type={updateDelTag} api_name={updateDelapiName} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)}/>

    <ModelFormYesNo table_name='STANDARD' api_name={updateDelapiName} isVisible={ShowModelFormYesNo} onClose={() => setModelModelFormYesNo(false)}/>
                
    </form>
</>
);
};

export default EmployeeMaster;
