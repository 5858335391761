import React, { useState } from "react";
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { ValidateInput } from '../components/GlobalFunction';

import { useDispatch } from 'react-redux';
import { emptyGlobalDataFun, setGlobalDataFun } from '../components/redux/actions';

const Login = () => {

    let v_input_result, v_input_value;
    const router = useNavigate();
    const dispatch = useDispatch();

    const [passwordShown, setPasswordShown] = useState(false);

    const [ShowChangeBranchFinYear, setShowChangeBranchFinYear] = useState(false);

    const [errors_message_username, setErrors_message_username] = useState('');
    const [errors_message_password, setErrors_message_password] = useState('');

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const [user_name, setUser_Name] = useState('');
    const [errors_user_name, setErrors_user_name] = useState(false);

    const [password, setPassword] = useState('');
    const [errors_password, setErrors_password] = useState(false);

    function checkInpurError() {

        let v_err = false;

        v_input_value = user_name;
        v_input_result = ValidateInput('MINIMUM_LENGTH', v_input_value, '', 2);
        setErrors_user_name(v_input_result.v_truefalse);
        setErrors_message_username(v_input_result.v_msg);
        if (v_input_result.v_truefalse) { v_err = v_input_result.v_truefalse };

        v_input_value = password;
        v_input_result = ValidateInput('MINIMUM_LENGTH', v_input_value, '', 1);
        setErrors_password(v_input_result.v_truefalse);
        setErrors_message_password(v_input_result.v_msg);
        if (v_input_result.v_truefalse) { v_err = v_input_result.v_truefalse };

        return v_err;
    }

    const handleSetUserName = (event) => {
        event.preventDefault();
        const value = event.target.value;
        setUser_Name(value);

        // v_input_value = value;
        // v_input_result = ValidateInput('MINIMUM_LENGTH', v_input_value, '', 2);
        // setErrors_user_name(v_input_result.v_truefalse);
        // setUser_Name(v_input_result.v_value);
        // setErrors_message_username(v_input_result.v_msg);
    };

    const handleSetPassword = (event) => {
        event.preventDefault();

        const value = event.target.value;
        setPassword(value);

        // v_input_value = value;
        // v_input_result = ValidateInput('MINIMUM_LENGTH', v_input_value, '', 1);
        // setErrors_password(v_input_result.v_truefalse);
        // setPassword(v_input_result.v_value);
        // setErrors_message_password(v_input_result.v_msg);
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        if (checkInpurError()) { return };

        const response = await axios.get(`login/${user_name}/${password}`);

        // console.log("response is >> ", response.data.message)

        if (response.data.status === 401) {
            toast.success(response.data.message, { position: "top-center", });
            return;
        } else {

            // toast.success(response.data.message, {position:"top-center",});  

            // console.log(response.data.token)

            // console.log(response.data);

            // let date = new Date();
            // date.setTime(date.getTime() + (1 * 60 * 1000)); // 10 minutes
            // Cookies.set('token', response.data.token, { expires: date });

            Cookies.set('token', response.data.token);

            // Cookies.set('token', response.data.token, { expires: 1/1440 })            

            const global_data = {
                id: response.data.id,
                user: response.data.un,
                ag: response.data.ucg,
                token: response.data.token,
                br_name:''
            }

            // console.log("in login response.data.token >> ", response.data.token)

            dispatch(emptyGlobalDataFun());
            dispatch(setGlobalDataFun(global_data));

            // const temp_data = {
            //     tempuserid:response.data.id,
            //     tempusername:response.data.un,
            // }

            // dispatch(emptyTempDataFun());
            // dispatch(setTempDataFun(temp_data));             

            localStorage.setItem('id', response.data.id); // user_id
            localStorage.setItem('user', response.data.un); // user_name
            // localStorage.setItem('token', response.data.token); // token
            localStorage.setItem('ag', response.data.ucg); // company group id
            localStorage.setItem('br', 0); // branch_id
            localStorage.setItem('fy', 0); // financial year
            localStorage.setItem('br_name', ''); // branch name
            localStorage.setItem('fy_name', ''); // financial year name
            localStorage.setItem('brd', ''); // board
            localStorage.setItem('st', ''); // student_type
            localStorage.setItem('stem', ''); // stream
            localStorage.setItem('medi', ''); // medium
            localStorage.setItem('fcat', ''); // fee_categ
            localStorage.setItem('ptm', ''); // pay_term
            localStorage.setItem('paym', ''); // payment mode
            localStorage.setItem('fm', ''); // form_name 
            localStorage.setItem('tm', ''); // any temporary value
            localStorage.setItem('fcg', ''); // param_st_fee_coll_group_id from br_wise_fee_group_mat_view
            localStorage.setItem('tm_an', ''); // temporary value admission number
            localStorage.setItem('tm_cn', ''); // temporary value student class name
            localStorage.setItem('tm_sn', ''); // temporary value student student name
            localStorage.setItem('tm_fn', ''); // temporary value student father name
            localStorage.setItem('tm_mn', ''); // temporary value student mother name
            localStorage.setItem('tm_bn', ''); // temporary value student branch name
            localStorage.setItem('tm_fyn', ''); // temporary value student financial year name
            localStorage.setItem('tm_brno', ''); // temporary value student branch no
            localStorage.setItem('tm_fyno', ''); // temporary value student financial year no

            // localStorage.setItem('id', response.data.id); // user_id
            // localStorage.setItem('user', response.data.un); // user_name
            // localStorage.setItem('token', response.data.token); // token
            // localStorage.setItem('ag', response.data.ucg); // company group id
            // localStorage.setItem('br', 0); // branch_id
            // localStorage.setItem('fy', 0); // financial year
            // localStorage.setItem('br_name', ''); // branch name
            // localStorage.setItem('fy_name', ''); // financial year name
            // localStorage.setItem('brd', ''); // board
            // localStorage.setItem('st', ''); // student_type
            // localStorage.setItem('stem', ''); // stream
            // localStorage.setItem('medi', ''); // medium
            // localStorage.setItem('fcat', ''); // fee_categ
            // localStorage.setItem('ptm', ''); // pay_term
            // localStorage.setItem('paym', ''); // payment mode
            // localStorage.setItem('fm', ''); // form_name 
            // localStorage.setItem('tm', ''); // any temporary value
            // localStorage.setItem('fcg', ''); // param_st_fee_coll_group_id from br_wise_fee_group_mat_view
            // localStorage.setItem('tm_an', ''); // temporary value admission number
            // localStorage.setItem('tm_cn', ''); // temporary value student class name
            // localStorage.setItem('tm_sn', ''); // temporary value student student name
            // localStorage.setItem('tm_fn', ''); // temporary value student father name
            // localStorage.setItem('tm_mn', ''); // temporary value student mother name
            // localStorage.setItem('tm_bn', ''); // temporary value student branch name
            // localStorage.setItem('tm_fyn', ''); // temporary value student financial year name
            // localStorage.setItem('tm_brno', ''); // temporary value student branch no
            // localStorage.setItem('tm_fyno', ''); // temporary value student financial year no

            const getIPData = async () => {
                const res = await axios.get('https://geolocation-db.com/json/')
                const ipAddess = await res.data;
                localStorage.setItem('ipadd', JSON.stringify(ipAddess));
            }
            getIPData();

            router('/');
        }
    }

    return (
    <>

        <div className="min-h-screen grid grid-cols-12 justify-center items-center">

            <div className="border col-span-4 col-start-5 p-2 shadow-blue-950 shadow-md">

                <h1 className="text-3xl text-center py-2.5 text-red-800 font-semibold">Sign in to your account</h1>

                <form className="border-none" action="#!" onSubmit={submitHandler} autoComplete="off">
                    <div>
                        <label htmlFor="user_name" className="required label_css">User Name</label>
                        <input type="text" className="input_css focus:bg-white"
                            placeholder=""
                            id="user_name" name="user_name"
                            value={user_name}
                            onChange={handleSetUserName}
                        >
                        </input>
                    </div>

                    <div className="error_css">{errors_user_name ? errors_message_username : null}</div>

                    <label htmlFor="user_password" className="required label_css">Password</label>
                    <div className="flex">
                        <input type={passwordShown ? "text" : "password"}
                            className="input_css focus:bg-white border-r-0 rounded-r-none"
                            placeholder=""
                            id="password" name="password"
                            value={password}
                            onChange={handleSetPassword}
                        >
                        </input>

                        <div className="w-[50px] p-2 input_css border-l-0 rounded-l-none" onClick={togglePasswordVisiblity}>
                            {
                                passwordShown ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />
                            }
                        </div>
                    </div>
                    <div className="error_css">{errors_password ? errors_message_password : null}</div>

                    <div>
                        <button type="submit" className="w-full mt-2 text-white text-2xl bg-cyan-700 hover:bg-blue-800 rounded-md px-5 py-2.5 mr-2 mb-2">Sign in</button>
                    </div>

                    {/* {
                            JSON.stringify(emp)
                        } */}

                </form>

            </div>
        </div>
    </>

    )
}

export default Login;
