import {ADD_TO_CART, REMOVE_FROM_CART, USER_LIST, SET_GLOBAL_DATA, EMPTY_GLOBAL_DATA, SET_TEMP_DATA, EMPTY_TEMP_DATA,
    EMPTY_CART, TEMP_LIST, TEMP_PATH, USER_DATA, EMPTY_USER_DATA, SET_DD_BRANCH_BOARD, EMPTY_DD_BRANCH_BOARD, SET_DD_MONTH, EMPTY_DD_MONTH,
    SET_DD_STUDENT_TYPE, EMPTY_DD_STUDENT_TYPE, SET_DD_MEDIUM, EMPTY_DD_MEDIUM, SET_DD_ST_STREAM, EMPTY_DD_ST_STREAM, SET_DD_FEE_CATEG, EMPTY_DD_FEE_CATEG,
    SET_DD_REL_TYPE, EMPTY_DD_REL_TYPE, SET_DD_JOB_NATURE, EMPTY_DD_JOB_NATURE, SET_DD_OCCUP, EMPTY_DD_OCCUP, 
    SET_DD_APP_FOR, EMPTY_DD_APP_FOR, SET_DD_ST_SEC, EMPTY_DD_ST_SEC, SET_DD_PRE_SCH_CL, EMPTY_DD_PRE_SCH_CL,
    SET_DD_PRE_SCH, EMPTY_DD_PRE_SCH, SET_DD_CASTE, EMPTY_DD_CASTE,
    SET_DD_CAST_CATEG, EMPTY_DD_CAST_CATEG,
    SET_DD_RELIGION, EMPTY_DD_RELIGION,
    SET_DD_GENDER, EMPTY_DD_GENDER,
    SET_DD_ST_CLASS, EMPTY_DD_ST_CLASS,
    SET_DD_FEE_PAY_TERM, EMPTY_DD_FEE_PAY_TERM,    
    SET_DD_TABLE_PER_PAGE, EMPTY_DD_TABLE_PER_PAGE,
    SET_DD_FEE_CODE, EMPTY_DD_FEE_CODE,
    SET_DD_SEQ_WISE_MONTH, EMPTY_DD_SEQ_WISE_MONTH,
    SET_DD_FEE_GROUP_TYPE, EMPTY_DD_FEE_GROUP_TYPE
 } from './constants';

export function addToCart(item){
    return{type:ADD_TO_CART, data:item}
}

export function removeFromCart(item){
    return{type:REMOVE_FROM_CART, data:item}
}

export function getUserListFromSaga(){
    return{type:USER_LIST}
}

export function setGlobalDataFun(item){
    return{type:SET_GLOBAL_DATA, data:item}
}

export const emptyGlobalDataFun = () => {
    return {type: EMPTY_GLOBAL_DATA}
}

export function setTempDataFun(item){
    return{type:SET_TEMP_DATA, data:item}
}

export function emptyTempDataFun(){
    return{type:EMPTY_TEMP_DATA}
}

export function setDDBranchBoardFun(item){
    return{type:SET_DD_BRANCH_BOARD, data:item}
}

export function emptyDDBranchBoardFun(){
    return{type:EMPTY_DD_BRANCH_BOARD}
}

export function setDDMonthFun(item){
    return{type:SET_DD_MONTH, data:item}
}

export function emptyDDMonthFun(){
    return{type:EMPTY_DD_MONTH}
}

export function setDDStudentTypeFun(item){
    return{type:SET_DD_STUDENT_TYPE, data:item}
}

export function emptyDDStudentTypeFun(){
    return{type:EMPTY_DD_STUDENT_TYPE}
}

export function setDDMediumFun(item){
    return{type:SET_DD_MEDIUM, data:item}
}

export function emptyDDMediumFun(){
    return{type:EMPTY_DD_MEDIUM}
}

export function setDDStStreamFun(item){
    return{type:SET_DD_ST_STREAM, data:item}
}

export function emptyDDStStreamFun(){
    return{type:EMPTY_DD_ST_STREAM}
}

export function setDDFeeCategFun(item){
    return{type:SET_DD_FEE_CATEG, data:item}
}

export function emptyDDFeeCategFun(){
    return{type:EMPTY_DD_FEE_CATEG}
}

// shiv kumar

export function setDDFeePaymentTermFun(item){
    return{type:SET_DD_FEE_PAY_TERM, data:item}
}

export function emptyDDFeePaymentTermFun(){
    return{type:EMPTY_DD_FEE_PAY_TERM}
}

export function setDDStudentClassFun(item){
    return{type:SET_DD_ST_CLASS, data:item}
}

export function emptyDDStudentClassFun(){
    return{type:EMPTY_DD_ST_CLASS}
}

export function setDDGenderFun(item){
    return{type:SET_DD_GENDER, data:item}
}

export function emptyDDGenderFun(){
    return{type:EMPTY_DD_GENDER}
}

export function setDDReligionFun(item){
    return{type:SET_DD_RELIGION, data:item}
}

export function emptyDDReligionFun(){
    return{type:EMPTY_DD_RELIGION}
}

export function setDDCasteCategFun(item){
    return{type:SET_DD_CAST_CATEG, data:item}
}

export function emptyDDCasteCategFun(){
    return{type:EMPTY_DD_CAST_CATEG}
}

export function setDDCasteFun(item){
    return{type:SET_DD_CASTE, data:item}
}

export function emptyDDCasteFun(){
    return{type:EMPTY_DD_CASTE}
}

export function setDDPreviousSchoolFun(item){
    return{type:SET_DD_PRE_SCH, data:item}
}

export function emptyDDPreviousSchoolFun(){
    return{type:EMPTY_DD_PRE_SCH}
}

export function setDDPreviousClassFun(item){
    return{type:SET_DD_PRE_SCH_CL, data:item}
}

export function emptyDDPreviousClassFun(){
    return{type:EMPTY_DD_PRE_SCH_CL}
}

export function setDDStudentSectionFun(item){
    return{type:SET_DD_ST_SEC, data:item}
}

export function emptyDDStudentSectionFun(){
    return{type:EMPTY_DD_ST_SEC}
}

export function setDDAppForFun(item){
    return{type:SET_DD_APP_FOR, data:item}
}

export function emptyDDAppForFun(){
    return{type:EMPTY_DD_APP_FOR}
}

export function setDDOccupationFun(item){
    return{type:SET_DD_OCCUP, data:item}
}

export function emptyDDOccupationFun(){
    return{type:EMPTY_DD_OCCUP}
}

export function setDDJobNatureFun(item){
    return{type:SET_DD_JOB_NATURE, data:item}
}

export function emptyDDJobNatureFun(){
    return{type:EMPTY_DD_JOB_NATURE}
}

export function setDDRelationTypeFun(item){
    return{type:SET_DD_REL_TYPE, data:item}
}

export function emptyDDRelationTypeFun(){
    return{type:EMPTY_DD_REL_TYPE}
}

export function setDDTablePerPageFun(item){
    return{type:SET_DD_TABLE_PER_PAGE, data:item}
}

export function emptyDDTablePerPageFun(){
    return{type:EMPTY_DD_TABLE_PER_PAGE}
}

export function setDDFeeCodeFun(item){
    return{type:SET_DD_FEE_CODE, data:item}
}

export function emptyDDFeeCodeFun(){
    return{type:EMPTY_DD_FEE_CODE}
}


export function setDDMonthSeqWiseFun(item){
    return{type:SET_DD_SEQ_WISE_MONTH, data:item}
}

export function emptyDDMonthSeqWiseFun(){
    return{type:EMPTY_DD_SEQ_WISE_MONTH}
}



export function setDDFeeGroupTypeFun(item){
    return{type:SET_DD_FEE_GROUP_TYPE, data:item}
}

export function emptyDDFeeGroupTypeFun(){
    return{type:EMPTY_DD_FEE_GROUP_TYPE}
}


// Taken from old code

export const addTempList = (data) => {
    // console.warn("In action addTempList : ", data)

    return {
        type: TEMP_LIST,
        data: data
    }
}

export const addTempPath = (data) => {
    // console.warn("In action addTempPath : ", data)

    return {
        type: TEMP_PATH,
        data: data
    }
}



export const emptyCart = () => {
    // console.warn("In action emptyCart : ", data)

    return {
        type: EMPTY_CART,
    }
}

export const assignUserData = (data) => {    

    return {
        type: USER_DATA,
        data: data
    }
}

export const emptyUserData = () => {
    // console.warn("In action emptyCart : ", data)

    return {
        type: EMPTY_USER_DATA,
    }
}



