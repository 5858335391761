import React, {useEffect, useRef, useState} from 'react';
import styles from './Menu.module.css';
import SubMenuDropdown from './SubMenuDropdown';

import { Link } from 'react-router-dom';

function MenuItem({item, depth}){

    const myref = useRef();

    const [showmenu, setShowmenu] = useState(false);

    const handleToggleMenu = () => {
        setShowmenu((prev) => !prev);
    }

    useEffect(() =>{
        const myhandler = (event) =>{
            if(showmenu && myref.current && !myref.current.contains(event.target))
            {
                setShowmenu(false);
            }
        }

        document.addEventListener('mousedown', myhandler);        
    }, [showmenu]);

    const onMouseEnter = () =>{
        setShowmenu(true);
    }

    const onMouseLeave = () =>{
        setShowmenu(false);
    }

    return(
        item.submenu && item.submenu.length > 0 ? (
            <li className={styles.parent} ref = {myref}>                
                
                <button className={styles.btnStyle} onClick = {handleToggleMenu} 
                // onMouseEnter = {onMouseEnter} // onMouseLeave={onMouseLeave}
                >
                
                    {item.title}

                    {depth > 1 ? <span className={styles.ArrowRight}></span> : <span className={styles.ArrowDown}></span>}

                </button>                

                <SubMenuDropdown submenu = {item.submenu} depth = {depth} showmenu = {showmenu}/>

            </li>

        ):(

            <li className={styles.parent}>                                
                <button className={styles.btnStyle}><Link to = {item.path} className='text-left'>{item.title}</Link></button>
            </li>

            // <li className={styles.parent}>                                
            // <button className={styles.btnStyle}><Link to = {item.path} className='text-left'>{item.title}</Link></button>
            // </li>

            // <Link to = {item.path}><li>{item.title}</li></Link>

            // <li className={styles.parent}>                                
            //     <button className={styles.btnStyle}><Link to = {item.path} className='text-left'>{item.title}</Link></button>
            // </li>
            
        )
    )
}

export default MenuItem;
