import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import CRUDContext from '../components/CRUDContext';
import DropDownTableComponent from "../components/DropDownTableComponent";

import { emptyGlobalDataFun } from '../components/redux/actions';
import Cookies from 'js-cookie';

import SearchStudentDetails from "../components/SearchStudentDetails";

const StudentTransport = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';    
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);    
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);      
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);   

    const dispatch = useDispatch();
    const navigate = useNavigate();
        
    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData); 

    let v_input_result, v_active_status = 1, v_null_parameter = 'NA';

    // define variable for tables entry (master detail form)    

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);
    const [StudentListitems, setStudentListitems] = useState([]);

    const [vehicle_id, setvehicle_id] = useState(0);
    const [vehicle_idItems, setvehicle_idItems] = useState([]);

    const [route_id, setroute_id] = useState(0);
    
    const [param_used_for_idItems, setparam_used_for_idItems] = useState([]);
    const [convey_stoppage_place_idItems, setconvey_stoppage_place_idItems] = useState([]);
    const [convey_route_idItems, setconvey_route_idItems] = useState([]);

    const [display_param_used_for_idItems, display_setparam_used_for_idItems] = useState([]);
    const [display_convey_stoppage_place_idItems, display_setconvey_stoppage_place_idItems] = useState([]);
    const [display_convey_route_idItems, display_setconvey_route_idItems] = useState([]);    

    const [display_due_amt	, setdisplay_due_amt] = useState('');
    const [display_convey_route_id	, setdisplay_convey_route_id] = useState(0);    
    const [display_convey_stoppage_place_id	, setdisplay_convey_stoppage_place_id] = useState(0);
    const [display_param_used_for_id, setdisplay_param_used_for_id] = useState(0);    

    const [st_id, setst_id] = useState('');
    const [adm_no, setadm_no] = useState('');
    const [st_name_display, setst_name_display] = useState('');
    const [st_father_display, setst_father_display] = useState('');
    const [st_class_display, setst_class_display] = useState('');
    const [st_sec_display, setst_sec_display] = useState('');
    const [st_stream_display, setst_stream_display] = useState('');

    // When we will use Multiple selection in Drop Down

    const [ShowSearchStudentDetailsPopUp, setSearchStudentDetailsPopUp] = useState(false);
    
    const [ShowPopUp, setShowPopUp] = useState(false);
    const [selectedrow, setSelectedrow] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(false);

    const [AllowUpdateTag, setAllowUpdateTag] = useState(false);

    const [Checkedusedefault, setCheckedusedefault] = useState(false);  

    // define form name and navigation details

    const [v_urlSaveUpdateDelete, setv_urlSaveUpdateDelete] = useState('');
    
    const v_backToNavigateName = '/Dashboard';
    // const v_findToNavigateName = '/';
    const v_main_form_heading = 'Student Transport Details';
    const v_frm_no = 11;

    const {
        deletedMessage,
        createdMessage,
        updatedMessage,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);          

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code

                setv_open_frm(true);

                // console.log("Update allow_update >> ", result.allow_update)

                setBlankFields();

                const v_token = Cookies.get('token');                

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/9_url_student_master_find_fun_brfy/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/0`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;     
                setStudentListitems(await updatedata);

                setBlankFields();
                
                response = await axios.get(`url_fetch_data/11_url_transport_route_lov_fun/${v_frm_no}/${ReduxGlobalData[0].ag}/0/0/${v_active_status}
                /${ReduxGlobalData[0].br}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}        
                `,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;             
                setconvey_route_idItems(await updatedata);

                display_setconvey_route_idItems(await updatedata);
               
                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${ReduxGlobalData[0].br}/0/VECH_USED`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setparam_used_for_idItems(updatedata);

                display_setparam_used_for_idItems(updatedata);                

                
                response = await axios.get(`url_fetch_data/11_url_transport_route_stoppage_lov_fun/${v_frm_no}/${ReduxGlobalData[0].ag}/0/0/${v_active_status}
                /${ReduxGlobalData[0].br}
                /${0}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}        
                `,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;

                setconvey_stoppage_place_idItems(await updatedata);

                display_setconvey_stoppage_place_idItems(await updatedata);
                
            }
        }
        loadData();
    }, [ReduxGlobalData[0]]);
   
    const FindSavedTableDetail = async(p_TableRecordID) => {

        if (vehicle_id === undefined || vehicle_id.length === undefined || vehicle_id.length === 0){
            toast.info('Entry required for Vehicle Detail', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;  
        }

        if (route_id === undefined || route_id.length === undefined || route_id.length === 0){
            toast.info('Entry required for Route Detail', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;  
        }      

        const v_token = Cookies.get('token');
        
        // show the detail part
        
        response = await axios.get(`url_fetch_data/43_url_transport_route_stoppage_view_fun/${v_frm_no}/${ReduxGlobalData[0].ag}/0/0/${v_active_status}
        /${ReduxGlobalData[0].br}
        /${vehicle_id}
        /${route_id}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;             
        setItems(await updatedata);        
    }
    
    function setBlankFields() {      
        setTableRecordID(0);        
    }

    const handleInputValueFun = async(name, value) => {        

        if (name === 'vehicle_id') {
            setvehicle_id(value);
            setItems([]);
            return;
        }else if (name === 'route_id') {             
            setroute_id(value);            
            setItems([]);
            return;
        }

    }

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target; 
        
        let insupdel_type = 'INSERT';

        if (value != 'DELETE'){            

            if(items.length === 0){
                toast.info('Record not found to be saved', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }
        }

        if (Checkedusedefault === true){

            if(display_due_amt.length === 0){
                toast.info('Youe have selected use default option, Entry required for Due Amt.', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
            }

            if(display_convey_route_id === undefined || display_convey_route_id.length === undefined || display_convey_route_id.length === 0){
                toast.info('Youe have selected use default option, Entry required for Route', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;  
            }

            if(display_convey_stoppage_place_id === undefined || display_convey_stoppage_place_id.length === undefined || display_convey_stoppage_place_id.length === 0){
                toast.info('Youe have selected use default option, Entry required for Stoppage', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;  
            }

            if(display_param_used_for_id === undefined || display_param_used_for_id.length === undefined || display_param_used_for_id.length === 0){
                toast.info('Youe have selected use default option, Entry required for Used for', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

        }


        const data = {
            id: 0,
            insupdel_type:'INSERT',
            under_comp_branch_id:ReduxGlobalData[0].br,
            fin_year:ReduxGlobalData[0].fy,            
            
            display_due_amt,
            display_convey_route_id,
            display_convey_stoppage_place_id,
            display_param_used_for_id,
            Checkedusedefault,

            items: JSON.stringify(items)
        };
        
        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);
        setv_urlSaveUpdateDelete('11_url_update_transport_fee_new_proc');

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }
    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);        

        if (p_data.ModelYesNoAnswer === 'YES'){
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async(p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final:p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final:p_last_updel_notes_final,
        }        

        const v_token = Cookies.get('token');

        // response = await axios.post(`${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        // { headers: { Authorization: `Bearer ${v_token}` } });

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });
        
        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }
    
    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                    
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            // setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);
            
            // console.log("updatedata.TabID.v_data >> ", updatedata.v_data);
            
            if (updatedata.transType === 'INSERT'){                
                v_toast_msg = createdMessage;

                setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);

                getSavedDetailFunction(st_id);
            }else if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;

                getSavedDetailFunction(st_id);
            }else if (updatedata.transType === 'DELETE'){                
                v_toast_msg = deletedMessage;
                setBlankFields();
            }

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }

    const setNewRecordIDFunction = async() => {
        
        // console.log("setNewRecordIDFunction >> ", p_v_data[0].p_id, p_v_data[0].p_vou_no);

        // setTableRecordID(await p_v_data[0].p_id);        
    }

    const getSavedDetailFunction = async (p_id) => {           
        const v_token = Cookies.get('token');        

        response = await axios.get(`url_fetch_data_branch_finyear_id_wise/11_url_st_convey_master_view/${v_frm_no}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${p_id}`,
            { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;

        // console.log("updatedata >> ", updatedata);

        setItems(updatedata);        

        if (userAuthData.allow_update === 1){
            setAllowUpdateTag(false);
        }else{
            setAllowUpdateTag(true);
        }


        // FindSavedTableDetail(p_v_data);
    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        // console.log("handleInputFocus value >> ", value)

        if (name === 'adm_no'){            
            setSearchStudentDetailsPopUp(true);
            setSelectedColumn('adm_no');
            return;
        }else{
            setShowPopUp(false);
            return;
        }
    };

    const handleInputValue = async(event) => {
        event.preventDefault();
        const { name, value } = event.target;

        let v_value = value;

        if (name === 'display_due_amt'){
            setdisplay_due_amt(value);

            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', v_value, '', 100);            
            setdisplay_due_amt(v_input_result.v_value);

        } else if (name === 'display_convey_route_id'){
            setdisplay_convey_route_id(value);
        } else if (name === 'display_convey_stoppage_place_id'){
            setdisplay_convey_stoppage_place_id(value);
        } else if (name === 'display_param_used_for_id'){
            setdisplay_param_used_for_id(value);
        }
        
    }

    const setStudentModelFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setSearchStudentDetailsPopUp(false);
        setConditionalCSS(false);

        // console.log(p_data)

        setTableRecordID(p_data.tempStudentid);

        setst_id(p_data.tempStudentid);
        setadm_no(p_data.tempAdmNo);
        setst_name_display(p_data.tempStudentName);
        setst_father_display(p_data.tempFatherName);
        setst_class_display(p_data.tempClass);
        setst_sec_display(p_data.tempSec);
        setst_stream_display(p_data.tempStream);

        getSavedDetailFunction(p_data.tempStudentid);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        if (value === 'BTN_FIND'){
            FindSavedTableDetail(0);
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };
    
    const goCheckDataFunction = (event) => {
        event.preventDefault();

        console.info(items);

        // console.log("st_id >> ", st_id);

        // shiv

        // let v_size = JSON.stringify(items).size();

        // console.info(new Blob([JSON.stringify(items)]).size);

        // console.log("items >> ", v_size);

    }

    // For details entry (entry in table)
    // For details entry (entry in table)
    
    const handlerChangeTableRowFocus = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;
    };

    const handlerChange = async(event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;        

        let v_value = value;        
        const list = [...items];

        if (name === 'due_amt'){
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', v_value, '', 100);
            v_value = v_input_result.v_value;
        }else if (name === 'convey_route_id'){

                const v_token = Cookies.get('token');

                response = await axios.get(`url_fetch_data/11_url_transport_route_vehicle_lov_fun/${v_frm_no}/${ReduxGlobalData[0].ag}/0/0/${v_active_status}
                /${ReduxGlobalData[0].br}
                /${value}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}        
                `,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                
                list[i]['vehicle_no_desc'] = updatedata[0].vech_no;

                // fill the dropdown list for stoppage place

                // response = await axios.get(`url_fetch_data/11_url_transport_route_stoppage_lov_fun/${v_frm_no}/${ReduxGlobalData[0].ag}/0/0/${v_active_status}
                // /${ReduxGlobalData[0].br}
                // /${value}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}
                // /${v_null_parameter}        
                // `,
                // { headers: { Authorization: `Bearer ${v_token}` } });
                // updatedata = await response.data.v_out_data.v_data;
                // setconvey_stoppage_place_idItems(await updatedata);

        }else if (name === 'notes'){
            v_value = capitalizeWordFun(v_value);
        }

        list[i][name] = v_value; 
        setItems(list);
    };

    const handleChangeCheckBox = (event) => {
        // const { name, checked } = event.target;          

        setCheckedusedefault(event.target.checked);
    }

    const setShowPopUpFalseFunction = (event) => {        
        // setShowPopUp(false);
    };

    const handleInputBlur = (event, i, p_col_name) => {
        // event.preventDefault();
        // const { name, value } = event.target;

        // console.log("handleInputBlur >> ", value);

        // // setSelectedrow(i);
        // // setSelectedColumn(p_col_name);

        // if (p_col_name === 'FEE_DETAIL_FEE_MONTH_DESCR' || p_col_name === 'FEE_DETAIL_FEE_CODE_DESCR'){
            

            
        //     setShowPopUp(true);
        // }else{
            
        //     setShowPopUp(false);
        // }

        // console.log('Input field lost focus');

    };

return (

<>

    {
        ShowSearchStudentDetailsPopUp ?
            <div className="w-[100%] h-[12%] absolute">
                <SearchStudentDetails transType={TransType} p_st_items={StudentListitems} setStudentModelFalseFunction={setStudentModelFalseFunction} />
            </div>
            : null
    }

    {
        ShowConfirmModelSave ? 
        <div className="centerWindow_css">
            <ConfirmModelSave transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

    {
        ShowConfirmModelUpdateDelete ? 
        <div className="centerWindow_css w-[32%]">
            <ConfirmModelUpdateDelete transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} onMouseOut={(e) => setShowPopUpFalseFunction(e)} autoComplete="off">

<div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

<div className="form_div_main_css">
<div className="form_div_inner_css grid grid-cols-7">

    {/* <div>
        <label className="label_inner_page_css text-center">Student ID</label>
        <div className="input_inner_page_css text-center font-semibold">{st_id}</div>
    </div>                                     */}

    <div>
        <label className="label_inner_page_css required_css text-center">Adm.No.</label>
        <input className="input_inner_page_css text-center font-semibold"
            type="text"
            ref={(ref) => addInputRef(ref, 5)}
            name="adm_no"
            id="adm_no"
            value={adm_no}                    
            onChange={handleInputValue}                                    
            onFocus={handleInputFocus}
            // disabled = {saveUpdateTag === 'SAVE' ? false : true}
        ></input>                
    </div>                  

    <div className="col-span-2">
        <label className="label_inner_page_css">Name</label>
        <div className="input_inner_page_css font-semibold">{st_name_display}</div>
    </div>

    <div className="col-span-2">
        <label className="label_inner_page_css">Father Name</label>
        <div className="input_inner_page_css font-semibold">{st_father_display}</div>
    </div>

    <div>
        <label className="label_inner_page_css text-center">Class & Sec.</label>
        <div className="input_inner_page_css text-center font-semibold">{st_class_display} - {st_sec_display}</div>
    </div>            

    <div>
        <label className="label_inner_page_css text-center">Stream</label>
        <div className="input_inner_page_css text-center font-semibold">{st_stream_display}</div>
    </div>
                
</div>        

</div>


<table className="table_main_css">
<thead className="table_thead_css">        
    <tr className="table_thead_tr_css">

        <th className="table_th_td_css w-[10%] text-center"></th>
        
        <th className="table_th_td_css w-[8%] text-center">
            <input className="input_inner_page_css text-center"
                type="text"
                ref={(ref) => addInputRef(ref, 0)}
                autoFocus
                name="display_due_amt"
                id="display_due_amt"
                value={display_due_amt}            
                onChange={handleInputValue}                
                onFocus={handleInputFocus}            
            ></input>
        </th>

        <th className="table_th_td_css w-[20%] text-left">
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}       
                autoFocus         
                name="display_convey_route_id"
                id="display_convey_route_id"
                value={display_convey_route_id}                                    
                onChange={handleInputValue}                                
                onFocus={handleInputFocus}                                
            >
                <option></option>
                {
                    display_convey_route_idItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </th>

        
        <th className="table_th_td_css w-[20%] text-left"></th>

        <th className="table_th_td_css w-[20%] text-left">
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}       
                autoFocus         
                name="display_convey_stoppage_place_id"
                id="display_convey_stoppage_place_id"
                value={display_convey_stoppage_place_id}                                    
                onChange={handleInputValue}                                
                onFocus={handleInputFocus}                                
            > 
                <option></option>
                {
                    display_convey_stoppage_place_idItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </th>

        <th className="table_th_td_css w-[10%] text-left">
            <select className="input_inner_page_css text-left"
                ref={(ref) => addInputRef(ref, 0)}
                autoFocus         
                name="display_param_used_for_id"
                id="display_param_used_for_id"
                value={display_param_used_for_id}                                    
                onChange={handleInputValue}                                
                onFocus={handleInputFocus}                                
            > 
                <option></option>
                {
                    display_param_used_for_idItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </th>

        <th className="table_th_td_css w-[12%] text-left">            
                 
            <label className="label_inner_page_css text-white">Use Default</label>            
            <input className="table_check_inner_page_css w-10"
                type="checkbox" name="Checkedusedefault" checked={Checkedusedefault}
                onChange={handleChangeCheckBox}
                onFocus={handleInputFocus}
            />

        </th>

    </tr>


    {/* <th className="table_th_td_css w-[6%]">

        <input className="input_inner_page_css"
            type="text"
            ref={(ref) => addInputRef(ref, 0)}
            autoFocus
            name="queryAdmNo"
            id="queryAdmNo"
            value={queryAdmNo}
            // placeholder="Adm.No."                    
            onChange={handleInputValue}
            // onKeyDown={handleEnter}
            onFocus={handleInputFocus}
        // onBlur={handleInputBlur}
        // disabled
        ></input>
    </th>
     */}
</thead>

<thead className="table_thead_css">       
    <tr className="table_thead_tr_css"> 
        <th className="table_th_td_css w-[10%] text-left">Month</th>
        <th className="table_th_td_css w-[8%] text-center">Amount</th>
        <th className="table_th_td_css w-[20%] text-left">Route Name</th>
        <th className="table_th_td_css w-[20%] text-left">Vehicle No.</th>
        <th className="table_th_td_css w-[20%] text-left">Stoppage</th> 
        <th className="table_th_td_css w-[10%] text-left">Used For</th>
        <th className="table_th_td_css w-[12%] text-left">Notes</th>

    </tr>
</thead>

<tbody className="table_tbody_css h-[50vh]">
{
    items?.map((item, index) => (

    <tr className="table_thead_tr_css table_tbody_hover_css" key={item.t1_id}>

        <td className="table_input_th_td_css w-[10%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <div className="table_input_inner_page_css">{item.fee_month_desc}</div>
        </td>

        <td className="table_input_th_td_css text-left w-[8%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
            <input className="table_input_inner_page_css text-center"
                type="text"
                ref={(ref) => addInputRef(ref, 15)}
                name="due_amt"
                id="due_amt"
                value={item.due_amt}                                                                   
                onChange={(e) => handlerChange(e, index)}
            ></input>
        </td>

        <td className="table_input_th_td_css w-[20%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <DropDownTableComponent 
                ref={(ref) => addInputRef(ref, 14)}
                paramItems={convey_route_idItems} columnName={'convey_route_id'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.convey_route_id}                     
            />         
        </td> 

        <td className="table_input_th_td_css w-[20%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <div className="table_input_inner_page_css">{item.vehicle_no_desc}</div>      
        </td> 

        <td className="table_input_th_td_css w-[20%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <DropDownTableComponent 
                ref={(ref) => addInputRef(ref, 14)}
                paramItems={convey_stoppage_place_idItems} columnName={'convey_stoppage_place_id'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.convey_stoppage_place_id}                     
            />         
        </td>

        <td className="table_input_th_td_css w-[10%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
            <DropDownTableComponent 
                ref={(ref) => addInputRef(ref, 14)}
                paramItems={param_used_for_idItems} columnName={'param_used_for_id'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.param_used_for_id}                     
            />         
        </td>

        <td className="table_input_th_td_css text-left w-[12%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
            <input className="table_input_inner_page_css text-left"
                type="text"
                ref={(ref) => addInputRef(ref, 15)}
                name="notes"
                id="notes"
                value={item.notes}                                                                   
                onChange={(e) => handlerChange(e, index)}
            ></input>
        </td>

    </tr>
    ))
}
</tbody>
</table>

<nav className="btn_nav_table_sum_inner_css">
<div className="btn_div_main_css">

    <div className="btn_div_inner_css">                

        <div>
            <button className="btn_save_css"                    
                onClick={createRecordFunction}>
                Save
            </button>                
        </div>                
        
        <div>
            <button className="btn_back_css"
                value = 'BTN_BACK'
                onClick={handleButtonValue}>
                Back
            </button>
        </div>

        {/* <div>                
            <button className="btn_back_css"
                onClick={goCheckDataFunction}>
                Check Data
            </button>
        </div> */}
            
    </div>
    
</div>
</nav>

</form>
:null
}

</>
);};

export default StudentTransport;
