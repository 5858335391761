import React, { useState, useEffect } from "react";
import axios from 'axios';

const DemoTableForm = () => {
    const [items, setItems] = useState([]);    
    
    useEffect(() => {
        
        // console.log('s-1')
        
        const getAllTableRecordFunction = async (var_link) => {
            let updatedata = []
            let response = []            

            response = await axios.get(`${var_link}/${localStorage.getItem('br')}/${localStorage.getItem('fy')}`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data;

            console.log(updatedata)

            setItems(updatedata);    
        }
        getAllTableRecordFunction('url_student_master_view');
    }, [])

return (

	<table className="mt-40 w-full">
		
		{/* <thead className="bg-TabHeadBGColor text-TabHeadFontColor flex w-full">
			<tr className="flex w-full">

				<th className="p-TabPadP w-1/6">Admission No.</th>
				<th className="p-TabPadP w-1/6">Class</th>
				<th className="p-TabPadP w-1/6">Section</th>
				<th className="p-TabPadP w-1/6">Name</th>
				<th className="p-TabPadP w-1/6">Father's Name</th>
				<th className="p-TabPadP w-1/6">Mother's Name</th>
				<th className="text-center p-TabPadP w-1/6">Actions</th>
							
			</tr>
		</thead>		 */}

		{/* <tbody className="max-h-96 max-w-20 break-words flex flex-col overflow-y-scroll"> */}

        <tbody className="max-h-96 max-w-20 break-words overflow-y-scroll">

            {items?.map((item) => (

                // <tr className="flex w-full" key={item.id}>

                <tr className="w-full" key={item.id}>

                    <td className="p-1 w-[50px]">{item.adm_no}</td>
                    <td className="p-1 w-[60px]">{item.st_class}</td>
                    <td className="p-1 w-[90px]">{item.st_sec}</td>
                    <td className="p-1 w-[150px]">{item.st_name}</td>
                    <td className="p-1 w-[180px]">{item.fat_name}</td>	
                    <td className="p-1 w-[180px]">{item.mot_name}</td>

                </tr>
            ))}
		
		</tbody>
        
	</table>

);};

export default DemoTableForm;
