import React, { useEffect, useState } from "react";

import axios from 'axios';

import Cookies from 'js-cookie';

import { useDispatch, useSelector } from 'react-redux';

// axios.defaults.baseURL = "http://localhost:3001/api/";


function App() {

    let response = [], updatedata = [], v_toast_msg = '';
    
    let v_input_result, v_active_status = 1, v_null_parameter = 'NA';
    
    const ReduxGlobalData = useSelector((state) => state.GlobalData); 

    const [image, setImage] = useState();
    const [allImage, setallImage] = useState([]);

    useEffect(() => {
        getImageFun();
    }, []);

    const submitImage = async(e) => {

        e.preventDefault();

        const formData = new FormData();

        formData.append("image", image);          

        let response = await axios.post("uploadimage", formData,
        { headers: { "Content-Type": "multipart/form-data" }, });
        
    //   response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_user_wise_branch_view_drop_down/38/0/0/${ReduxGlobalData[0].id}`,
    //   { headers: { Authorization: `Bearer ${v_token}` } });
    //   updatedata = await response.data.v_out_data.v_data;
    //   setunder_comp_branchItems(updatedata);            

    }

    const getImageFun = async() => {  
        
        return;
        
        const v_token = Cookies.get('token');        

        response = await axios.get(`url_fetch_data/38_url_getimage/38/${ReduxGlobalData[0].ag}/0/0/${v_active_status}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data; 
        setallImage(updatedata);
    }

const onInputChange= (e) => {

        e.preventDefault();

        console.log(e.target.files[0])

        setImage(e.target.files[0])

}

const createRecordFunction = async(event) => {
        event.preventDefault();

        // const formData = new FormData();

        console.log("before formData >> ", formData)

        const formData = new FormData();
        formData.append("image", image);
        formData.append("id", '5555');            

        console.log("before formData >> ", formData)

        // let response = await axios.post("uploadimage", formData,
        // { headers: { "Content-Type": "multipart/form-data" }, });

        const v_token = Cookies.get('token');

        const v_urlSaveUpdateDelete = 'uploadimage';

        let response = await axios.post(`url_save_data_new_proc_image/${v_urlSaveUpdateDelete}/3`, formData, 
        { headers: { Authorization: `Bearer ${v_token}` } });

        updatedata = await response.data.v_out_data;

}

return (
<div>
              
{/* <form onSubmit={submitImage} enctype="multipart/form-data"> */}

<form onSubmit={submitImage}>
    
    <input type = "file" accept="image/*" onChange={onInputChange}></input>

    <div className="col-span-1">
        <label className="label_inner_page_css">Notes</label>
        <input className="input_inner_page_css"
            type="text"            
            name="notes"
            id="notes"
            value={'shivsharma'}                
            // onChange={handleInputValue}                
            // onFocus={handleInputFocus}
        ></input>              
    </div>

    <button type = "submit">Submit- submitImage</button>
</form>

<button className="btn_save_css"                
    onClick={createRecordFunction}>
    Save
</button>

{
    allImage.map((data) => {
    return(

    <div>

        {/* <img src = {data.emp_img} /> */}

        <img src = {require(`../images/${data.emp_img}`)} />

    </div>

    )
    
})}             

</div>
          
          
)      
}

export default App;
