// Table name : print preview functionality when user will press print button at the time of fee collection
// we are designing this print form for another print script format

import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import Cookies from 'js-cookie';

import FeeRecPrint from '../FeeRecPrint.css';

import CRUDContext from '../components/CRUDContext';

// import Layout from '../components/Layout';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

import { useReactToPrint } from "react-to-print";

import moment, { invalid } from 'moment';

// import { isAuthenticatedUser } from './isAuthenticated';

import { addTempList, emptyCart } from '../components/redux/actions';

const Pspr = ({ isVisible, onClose, form_name}) => {  

    // const router = useRouter();

    const navigate = useNavigate();

    const conponentPDF = useRef();
    const dispatch = useDispatch();
    const ReduxData = useSelector((state) => state);    
    
    const [items, setItems] = useState([]);    

    const[v_report_head_1, setv_report_head_1] = useState('')
    const[v_report_head_2, setv_report_head_2] = useState('')
    const[v_report_head_3, setv_report_head_3] = useState('')
    const[v_f_session, setv_f_session] = useState('')
    const[v_rec_date, setv_rec_date] = useState('')
    const[v_rec_no, setv_rec_no] = useState('')
    const[v_st_name, setv_st_name] = useState('')
    const[v_fat_name, setv_fat_name] = useState('')
    const[v_st_class, setv_st_class] = useState('')
    const[v_st_section, setv_st_section] = useState('')
    const[v_adm_no, setv_adm_no] = useState('')
    const[v_fat_mobile, setv_fat_mobile] = useState('')
    const[v_pay_mode, setv_pay_mode] = useState('')
    const[v_totalamtpaid, setv_totalamtpaid] = useState('')
    const[v_totaldueamt, setv_totaldueamt] = useState('')    
    const[v_discountfineamt, setv_discountfineamt] = useState('')
    const[v_totalbalanceamt, setv_totalbalanceamt] = useState('')
    const[v_user_name, setv_user_name] = useState('')
    const[v_notes, setv_notes] = useState('')
    const[v_amttoword, setv_amttoword] = useState('')

    const[v_sumfeeamtpaid, setv_sumfeeamtpaid] = useState(0)
    const[v_sumfeeamtfine, setv_sumfeeamtfine] = useState(0)

    const[v_sumv_tot_due, setv_sumv_tot_due] = useState(0)
    const[v_sumv_adj_amt, setv_sumv_adj_amt] = useState(0)
    const[v_sumv_bal_amt, setv_sumv_bal_amt] = useState(0)

    const[v_st_stream, setv_st_stream] = useState('')

    const[v_report_head_4, setv_report_head_4] = useState('')
    const[v_sec_label, setv_sec_label] = useState('')
    const[v_group_label, setv_group_label] = useState('')

    const[BlankLine, setBlankLine] = useState([])

    useEffect(() => {
        const registerUserdata = async () => {
            let updatedata = [], response = [], v_monthitems = [];

            // response = await axios.get(`url_fee_coll_rec_print_format2/${localStorage.getItem('br')}/${localStorage.getItem('fy')}/${localStorage.getItem('tm')}`,
            //      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            // updatedata = await response.data.v_out_data;                        
            // setItems(updatedata);

            const v_token = Cookies.get('token');

            // console.log("p_TableRecordID >> ", p_TableRecordID);
    
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/10_url_fee_coll_rec_print_format2/8/${localStorage.getItem('br')}/${localStorage.getItem('fy')}/${localStorage.getItem('tm')}`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            setItems(updatedata);

            // /////////////////////////////////////////////////////////////////////////////////////////

            // console.log("updatedata.length : ", updatedata.length)

            // we are considering total 26 rows in A5 size print

            let v_obj = [];
            let v_list = [];
            
            for (var i = 0; i < 18 - updatedata.length; i++) {
                v_obj = [];
                for (var j = 0; j < 4; j++) {
                    let key = "col_" + (j + 1);
                    let value = '';
                    v_obj[key] = value;
                }                
                v_list.push(v_obj);
            }
            setBlankLine(v_list);
            
            // /////////////////////////////////////////////////////////////////////////////////////////

            // response = await axios.get(`url_prospectus_sale_rec_print_heading_fun/${localStorage.getItem('br')}/${localStorage.getItem('fy')}/${localStorage.getItem('tm')}`,
            //     { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }); 
            // updatedata = await response.data.v_out_data;

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/8_url_prospectus_sale_rec_print_heading_fun/8/${localStorage.getItem('br')}/${localStorage.getItem('fy')}/${localStorage.getItem('tm')}`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            // setItems(updatedata);

            // console.log(updatedata)

            // console.log("header print receipt updatedata is : ", updatedata)

            setv_report_head_1(updatedata[0].v_report_head_1);
            setv_report_head_2(updatedata[0].v_report_head_2);
            setv_report_head_3(updatedata[0].v_report_head_3);
            setv_f_session(updatedata[0].v_f_session);
            setv_rec_date(moment(updatedata[0].v_rec_date).format('DD-MM-YYYY'));   
            setv_rec_no(updatedata[0].v_rec_no);
            setv_st_name(updatedata[0].v_st_name);
            setv_fat_name(updatedata[0].v_fat_name);
            setv_st_class(updatedata[0].v_st_class);
            
            setv_adm_no(updatedata[0].v_adm_no);
            setv_fat_mobile(updatedata[0].v_fat_mobile);
            setv_pay_mode(updatedata[0].v_pay_mode);
            setv_totalamtpaid(updatedata[0].v_rec_amount);
            setv_discountfineamt(updatedata[0].v_discountfineamt);
            setv_totalbalanceamt(updatedata[0].v_totalbalanceamt);
            setv_user_name(updatedata[0].v_user_name);
            setv_notes(updatedata[0].v_notes);
            setv_amttoword(updatedata[0].v_amttoword);
            setv_totaldueamt(updatedata[0].v_totaldueamt)

            setv_report_head_4(updatedata[0].v_report_head_4)
            setv_sec_label(updatedata[0].v_sec_label)
            setv_group_label(updatedata[0].v_group_label)
            
            // if(updatedata[0].v_sec_label.length === 0){
            //     setv_st_section("");
            // }else{
            //     setv_st_section(updatedata[0].v_st_section);
            // }

            // if(updatedata[0].v_group_label.length === 0){
            //     setv_st_stream("");
            // }else{
            //     setv_st_stream(updatedata[0].v_st_stream)
            // }

            setv_st_stream(updatedata[0].v_st_stream)
            
            // console.log(updatedata);
        }
        registerUserdata();
    }, []);

    const PrintPDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "Userdata",
        // onAfterPrint: () => alert("Data saved in PDF")
    });

    const goPrePageFunction = (event) => {
        event.preventDefault();        
        const v_form_name = localStorage.getItem('fm');
        navigate(`/${v_form_name}`);               
    }

return (

<>

{/* <div className="mt-FromTopMenuMargin justify-center items-center fixed inset-0 bg-my-when-focus-bg-color bg-opacity-100 backdrop-blur-3xl"> */}

    <div className="mt-2 flex justify-center gap-4">

        <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
            onClick={PrintPDF}>   
            Print
        </button>           

        <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BtnFontSize text-BackBtnFontColor font-BtnFontName rounded-none"
            onClick={goPrePageFunction}
        >Back</button>

    </div>

    <div className="mt-10" ref={conponentPDF}> 
            
            <table className="table-fixed border ml-[20px] w-[506px] mt-5 font-my-font-name text-rec-fontSize">                
                <thead>                                      
                    <tr><th className="font-my-font-name text-FeeRecFontSize-1 font-FeeRecFontWeight-1 pl-1 text-center">{v_report_head_1}</th></tr>
                    <tr><th className="font-my-font-name text-FeeRecFontSize-2 font-FeeRecFontWeight-2 pl-1 text-center">{v_report_head_2}</th></tr>
                    <tr><th className="font-my-font-name text-FeeRecFontSize-3 font-FeeRecFontWeight-3 pl-1 text-center">{v_report_head_3}</th></tr>
                    <tr><th className="underline font-my-font-name text-FeeRecFontSize-3 font-FeeRecFontWeight-2 pl-1 text-center">Prospectus Receipt</th></tr>
                </thead>
            </table>
 
            <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize text-[12px]">                
                <thead>                                      
                    <tr className="border-x-[1px] border-black">                       

                        <th className="pl-2 w-[1px]  text-left">Session:</th>
                        <td className="pl-1 w-[20px] text-left">{v_f_session}</td>

                        <th className="pl-1 w-[11px] text-left">Receipt.No.:</th>
                        <td className="pl-1 w-[20px] text-left">{v_rec_no}</td>

                        

                    </tr>
                </thead>
            </table>

            <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize text-[12px]">                
                <thead>                                      
                    <tr className="border-x-[1px] border-black">
                        <th className="pl-2 w-[1px]  text-left">Name:</th>
                        <td className="pl-1 w-[20px] text-left">{v_st_name}</td>

                        <th className="pl-1 w-[11px] text-left">Father's Name:</th>
                        <td className="pl-1 w-[20px] text-left">{v_fat_name}</td>
                    </tr>
                </thead>
            </table>

            <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize text-[12px]">                
                <thead>                                      
                    {/* <tr className="border-x-[1px] border-b-[1px]"> */}
                    <tr className="border-x-[1px] border-black">
                        <th className="pl-2 w-[15px]  text-left">Class:</th>
                        <td className="pl-1 w-[20px] text-left">{v_st_class}</td>

                        <th className="pl-1 w-[10px] text-left"></th>
                        <td className="pl-1 w-[20px] text-left"></td>

                        <th className="pl-1 w-[11px] text-left">Stream:</th>
                        <td className="pl-1 w-[20px] text-left">{v_st_stream}</td>
                    </tr>
                </thead>
            </table>

            <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize text-[12px]">       
           
                <thead>                            

                    <tr>

                        <th className="border w-170 pl-1 print-border-color text-left">Description of Fee</th>
                        <th className="border w-20 pr-1 print-border-color text-right">Rec.Amt</th>

                    </tr>
                </thead>

                <tr>
                    <td className="border w-170 pl-1 print-border-color text-left">Propectus</td>                    
                    <td className="border w-20 pr-1 print-border-color text-right">{v_totalamtpaid}</td>
                </tr>                

                {
                    BlankLine.map((item, index) => (
                        <tr key={index}>                                
                            <td className="border w-170 p-2.5 print-border-color text-left"></td>
                            <td className="border w-20 p-2.5 print-border-color text-right"></td> 
                        </tr>                            
                ))}
                
                <tr>
                    <th className="border pr-1 text-right">Total</th>
                     
                    <td className="border pr-1 text-right">{v_totalamtpaid}</td>                    
                </tr>


            </table>

            <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize text-[12px]">
                <thead> 
                    <tr className="border-x-[1px] border-black">
                        <th className="w-[100px] pl-1 pr-1 text-left">Amount in Words:</th>
                        <td className="w-[250px] pl-1 pr-1 text-left">{v_amttoword}</td> 

                        <th className="w-[100px]  pl-1 pr-1 text-left">{v_user_name}</th>
                    </tr>
                </thead>
            </table>
            
            <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize text-[12px]">
                <thead> 
                    <tr className="border-x-[1px] border-black">
                        <td className="w-52 p-2.5 print-border-color text-left"></td>
                    </tr>
                </thead>
            </table>

            <table className="table-fixed ml-[20px] w-[506px] mt-0 font-my-font-name text-rec-fontSize text-[12px]">
                <thead> 
                    <tr className="border-x-[1px] border-b-[1px] border-black">
                        <th className="w-[70px] pl-1 pr-1 text-left">Date:</th>
                        <td className="w-[200px] pl-1 pr-1 text-left">{v_rec_date}</td>                        
                        <th className="w-[100px] pl-1 pr-1 text-left">Cashier/Accountant</th>      
                    </tr>
                </thead>
            </table>            

    </div>      

    


</>
);};

export default Pspr;
