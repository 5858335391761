import React, { useContext } from "react";
// import axios from 'axios';
import { useSelector } from 'react-redux';
import CRUDContext from './CRUDContext';

const ModelFormYesNo = ({ isVisible, onClose, api_name, table_name }) => {   

    const {
        createRecord,
    } = useContext(CRUDContext);    

    // const ReduxData = useSelector((state) => state);

    const ReduxData = useSelector((state) => state.TempData);

    if (!isVisible) return null;
    
    let ListData = ''

    if (table_name === 'LIST_CODE' || table_name === 'PARAM' || table_name === 'UNDERGROUP' || table_name === 'COMP_GROUP' || table_name === 'COMP_BRANCH' || table_name === 'LIST_CODE_BR_CHECK'
        || table_name === 'school_param_branch_wise' || table_name === 'usergroup_branch_wise' || table_name === 'AUTH_USER'
        || table_name === 'branch_wise_default' || table_name === 'FEE_MASTER' || table_name === 'STUDENT_MASTER'
        || table_name === 'fee_code_setting_branch_wise' || table_name === 'fee_month_setting_branch_wise'
        || table_name === 'fee_code_group_branch_wise' || table_name === 'FEE_COLL_MASTER' || table_name === 'st_fee_ledger' || table_name === 'fee_concession'
        || table_name === 'fee_rec_setting_branch_wise' || table_name === 'fee_fine_group_branch_wise' || table_name === 'fee_rec_format_branch_wise'
        || table_name === 'copy_to_fee_code_group_branch_wise' || table_name === 'delete_to_fee_code_group_branch_wise'
        || table_name === 'AC_GROUP' || table_name === 'AC_LEDGER' || table_name === 'PROPECTUS_SALE' 
        || table_name === 'STANDARD'
    ) {
        // ListData = ReduxData.cartData[0];
        ListData = ReduxData[0]
    }

    const createRecordFunction = (e) => {
        e.preventDefault();

        const getIPAddess = async () => {
            // const res = await axios.get('https://geolocation-db.com/json/')
            // const ipAddess = await res.data;

            const ipAddess = localStorage.getItem('ipadd');

            const listFinalData = {
                ListData,
                'last_access_ip_final': ipAddess,
                'last_access_by_final': localStorage.getItem('id'),
                'last_updel_notes_final': '',
            }            

            createRecord(listFinalData, api_name, 'NEW');

            onClose();

        };
        getIPAddess();
    }

    return (
        <>

            {/* <div className=" justify-center fixed inset-0 bg-black bg-opacity-25 backdrop-blur-3xl flex"> */}

            <div className="justify-center items-center fixed inset-0 bg-my-when-focus-bg-color bg-opacity-100 backdrop-blur-3xl flex">

                <div className="pt-2 px-2">

                    <div className="mb-4 pt-7 max-w-full text-my-font-color font-myFontWeight text-my-main-heading-fontSize text-center">
                        Are you sure to continue !!!
                    </div>

                    <div>
                        <span>
                            <button className="mx-1 m-4 w-[48%] bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-my-fontSize text-SaveButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={createRecordFunction}
                            >
                                Yes
                            </button>

                            <button className="w-[48%] bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-my-fontSize text-SaveButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={() => onClose()}
                            >
                                No
                            </button>
                        </span>
                    </div>


                </div>
            </div>
        </>
    )
}

export default ModelFormYesNo;
