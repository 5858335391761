// Table name : st_fee_ledger
// using new css

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import ModelFormFindStudentAll from '../components/ModelFormFindStudentAll';
import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';
// import Layout from '../components/Layout';
// import { useNavigate } from 'next/navigate';

import { useNavigate } from "react-router-dom";

// import { CSVLink } from "react-csv";

const Sfl = () => {
    const dispatch = useDispatch();    
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);  
    const [ShowModelFormFindStudentAll, setModelFormFindStudentAll] = useState(false); 
    // const navigate = usenavigate(); 

    const navigate = useNavigate();

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);    
    const [defaultdata, setdefaultdata]= useState([]);

    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0);
    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);
    
    const [st_id, setst_id] = useState('')
    const [adm_no, setadm_no] = useState('')
    const [st_name_display, setst_name_display] = useState('')
    const [st_class_display, setst_class_display] = useState('')
    const [st_father_display, setst_father_display] = useState('')
    const [st_mother_display, setst_mother_display] = useState('')
    const [st_branchname_display, setst_branchname_display] = useState('')
    const [st_finyear_display, setst_finyear_display] = useState('')    

    const [totaldue, settotaldue] = useState(0);
    const [totalrec, settotalrec] = useState(0);
    const [totalbalance, settotalbalance] = useState(0); 

    let list1 = [], v_calculate_list = [];
    
    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,        
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        viewRecord,
        setviewRecord,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenPageRefreshMessage,
        whenNoRecordFoundMessage,
        whenStudentNotFoundMessage,
    } = useContext(CRUDContext);


    // ////////////////////////////

    // const getAuthroziedUser = async () => {  
    //     try{
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //               { headers: { Authorization: `Bearer ${token}` } });            
    
    //         if (response.status === 401 || response.status === 403){
    //             localStorage.clear();
    //             navigate('/Login')
    //         };

    //     }catch(error){
    //         localStorage.clear();
    //         navigate('/Login')
    //     }
    // }    

    // useEffect( () => {        
    //     getAuthroziedUser();    
    // }, []);

    // ////////////////////////////


    useEffect(() => { 
        if (error){
          toast.error(error);
          clearErrors();
        }

        if (created){
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
        }

        if (updated){
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
        }

        if (deleted){
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
        }
    }, [error, created, updated, deleted]);    

 
    const createRecordFunction = (event) => {
        event.preventDefault();

        // if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
        //     toast.error("Entry required for under branch !");
        //     clearErrors();
        //     return;
        // }

        const data = {                        
            under_comp_branch_id : 100, 
            fin_year : 200, 
            student_id : st_id,             
            toitems: Array(items),
        };

        // console.log("data is : ", data)

        dispatch(emptyCart());
        dispatch(addTempList(data));
        setModelModelFormYesNo(true)
    }

    const handlerChange = (event, i) => {
        event.preventDefault();
        const { name, value } = event.target;
        // const list = [...items];        
        // list[i][name] = value;
        // setItems(list);

        let v_value = value;

        if (name === 'due_amt' || name === 'rec_amt' || name === 'act_amt')
        {
            v_value = event.target.value.replace(/\D/g, "");            
        }

        const list = [...items];
        list[i][name] = v_value;
        setItems(list);
    };
    
    const showStudentDetailFunction = async () => {
        let updatedata = [], response = [], v_student_id = 0;

        settotaldue('');
        settotalrec('');
        settotalbalance('');

        // const response = await axios.get(`url_fee_code_setting_branch_wise_view/${under_comp_branch_id}`,
        // { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

        response = await axios.get(`url_st_fee_ledger_view/${localStorage.getItem('tm')}/${localStorage.getItem('tm_brno')}/${localStorage.getItem('tm_fyno')}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

        updatedata = await response.data.v_out_data;
        
        // console.log("updatedata : ", updatedata)
        
        list1 = updatedata?.map((item, i) => (
            {"id":item.month_cal_seq, "st_fee_coll_group" : item.st_fee_coll_group  || '', 
            "fee_group" : item.fee_group || '', 
            "month_name" : item.month_name || '',  "fee_code_desc" : item.fee_code_desc  || '',
            "due_amt" : item.due_amt || '',"rec_amt" : item.rec_amt || '', "bal_amt" : item.bal_amt || '',
            "act_amt" : item.act_amt || '', "notes" : item.notes || '',
            "under_comp_branch_id" : item.under_comp_branch_id || '', "fin_year" : item.fin_year || '',
            "student_id" : item.student_id || '', "st_fee_ledger_id" : item.st_fee_ledger_id || '',
            "t1_id" : i}
        ));

        setTabID(list1.length);        
        setItems(list1);

        // to calculate the sum of due_amt, rec_amt, bal_amt

        v_calculate_list = updatedata?.map((item) => (
            [item.due_amt, item.rec_amt, item.bal_amt]                
        ));

        // console.log("v_calculate_list : ", v_calculate_list)

        let v_due_amt = 0, v_rec_amt = 0, v_balance_amt = 0;

        for (let i = 0; i < v_calculate_list.length; i++){
            for (let j = 0; j < v_calculate_list[i].length; j++){

                
                if (j === 0){
                    if (parseFloat(v_calculate_list[i][j]) > 0) {
                        v_due_amt = parseFloat(v_due_amt) + parseFloat(v_calculate_list[i][j]);
                    }     
                }
                
                if (j === 1){
                    if (parseFloat(v_calculate_list[i][j]) > 0) {
                        v_rec_amt = parseFloat(v_rec_amt) + parseFloat(v_calculate_list[i][j]);                        
                    }
                }

                if (j === 2){
                    if (parseFloat(v_calculate_list[i][j]) > 0) {
                        v_balance_amt = parseFloat(v_balance_amt) + parseFloat(v_calculate_list[i][j]);
                    }     
                }

            }
        }       

        // console.log("Due amount is     : ", v_due_amt)
        // console.log("Rec amount is     : ", v_rec_amt)
        // console.log("Balance amount is : ", v_balance_amt)

        settotaldue(v_due_amt);
        settotalrec(v_rec_amt);
        settotalbalance(v_balance_amt);
    
    }

    const setModelFormFindStudentFunction = () => {
        setBlankFields();

        setst_id(localStorage.getItem('tm'));
        setadm_no(localStorage.getItem('tm_an'));
        setst_class_display(localStorage.getItem('tm_cn'));
        setst_name_display(localStorage.getItem('tm_sn'));
        setst_father_display(localStorage.getItem('tm_fn'));
        setst_mother_display(localStorage.getItem('tm_mn'));
        setst_branchname_display(localStorage.getItem('tm_bn'));
        setst_finyear_display(localStorage.getItem('tm_fyn'))
       
        showStudentDetailFunction();
    }   

    const findStudentFunction = (event) => {
        event.preventDefault();
        setModelFormFindStudentAll(true);
    }

    function setBlankFields() {
        setPkID(0);

        setItems([]);
        setst_id('');
        setadm_no('');
        setst_name_display('');
        setst_class_display('');
        setst_father_display('');
        setst_mother_display('');

        setModelFormFindStudentAll(false);
    }
    
    const handleUnderBranchOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        setunder_comp_branch_id(getId);
        
        setItems([]);
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();
        navigate('/brms')
    }

    const handleChangeBrachFY = (event) => {
        event.preventDefault();
        
        localStorage.setItem('fm', 'sfl'); // form_name

        // router.push('/cb');

        navigate("/CbAll");
    }

    return (

<>

<div className="pt-PaddingTopAfterMenu pl-1 flex justify-start text-MainHeadingFontSize text-MainHeadingFontColor bg-MainHeadingBGColor">Student Fee Ledger</div>
        <div className="grid lg:grid-cols-12">
            <div className="mt-6 col-span-1">
                <button className="w-[85%] max-h-10 bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    onClick={findStudentFunction}
                >
                    Find
                </button>
            </div>  

            <div className="col-span-1">
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Adm.No.</label>
                <input className="w-[100%] py-PaddingPY px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="adm_no"
                    id="adm_no"
                    type="text"
                    value={adm_no}
                    onChange={(e) => setadm_no(e.target.value)}
                    disabled
                ></input>
            </div>

            {/* <div className="mt-6 col-span-1">
                <button className="w-[85%] max-h-10 ml-3 bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                    onClick={showStudentFunction}
                >
                    Show
                </button>
            </div> */}              

            <div className="col-span-1">
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Class</label>
                <input className="w-[100%] py-PaddingPY px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="st_class_display"
                    id="st_class_display"
                    type="text"
                    value={st_class_display}
                    onChange={(e) => setst_class_display(e.target.value)}
                    disabled
                ></input>
            </div>

            <div className="col-span-2">
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Name</label>
                <input className="w-[100%] py-PaddingPY px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="st_name_display"
                    id="st_name_display"
                    type="text"
                    value={st_name_display}
                    onChange={(e) => setst_name_display(e.target.value)}
                    disabled
                ></input>
            </div>

            <div className="col-span-2">
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Father's Name</label>
                <input className="w-[100%] py-PaddingPY px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="st_father_display"
                    id="st_father_display"
                    type="text"
                    value={st_father_display}
                    onChange={(e) => setst_father_display(e.target.value)}
                    disabled
                ></input>
            </div>

            <div className="col-span-2">
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Mother's Name</label>
                <input className="w-[100%] py-PaddingPY px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="st_mother_display"
                    id="st_mother_display"
                    type="text"
                    value={st_mother_display}
                    onChange={(e) => setst_mother_display(e.target.value)}
                    disabled
                ></input>
            </div>

            <div className="col-span-1">
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Year</label>
                <input className="w-[100%] py-PaddingPY px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="st_finyear_display"
                    id="st_finyear_display"
                    type="text"
                    value={st_finyear_display}
                    onChange={(e) => setst_finyear_display(e.target.value)}
                    disabled
                ></input>
            </div>  

            <div className="col-span-12">
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Under Branch</label>
                <input className="w-[100%] py-PaddingPY px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="st_branchname_display"
                    id="st_branchname_display"
                    type="text"
                    value={st_branchname_display}
                    onChange={(e) => setst_branchname_display(e.target.value)}
                    disabled
                ></input>
            </div>   
        </div>
        
        <div className="mt-2 border-TabBorderWidth border-TabHeadingBorderColor overflow-y-scroll">          
            
            <div className="grid lg:grid-cols-12">
                
                <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Collection Group
                </div>
        
                <div className="pr-PaddingRight text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Fee Group
                </div>

                <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Month
                </div>

                <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-2">
                    Fee Code
                </div>
                
                <div className="pr-PaddingRight text-right text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Due Amt.
                </div>

                <div className="pr-PaddingRight text-right text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Rec Amt.
                </div>

                <div className="pr-PaddingRight text-right text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Balance Amt.
                </div>

                <div className="pr-PaddingRight text-right text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    Act Amt.
                </div>

                <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-3">
                    Notes
                </div>

        
            </div>
        </div>
        
        <div className="border-TabBorderWidth border-TabBorderColor h-InsertTableHeight overflow-y-scroll">
            {
                items?.map((item, i) => (
        
                    <div className="grid grid-cols-12" key={item.t1_id}>
        
                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="st_fee_coll_group"
                            value={item.st_fee_coll_group}
                            onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />
        
                        <input className="w-[100%] pl-PaddingLeft text-left text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="fee_group"
                            value={item.fee_group}
                            onChange={(e) => handlerChange(e, i)} 
                            autoComplete="off"
                            disabled  
                        />
        
                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="month_name"
                            value={item.month_name}
                            onChange={(e) => handlerChange(e, i)}
                            disabled                              
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-2"
                            type="text"
                            name="fee_code_desc"
                            value={item.fee_code_desc}
                            onChange={(e) => handlerChange(e, i)}
                            disabled                              
                        />

                        <input className="w-[100%] text-right pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="due_amt"
                            value={item.due_amt}
                            onChange={(e) => handlerChange(e, i)}                                
                        />

                        <input className="w-[100%] text-right pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="rec_amt"
                            value={item.rec_amt}
                            onChange={(e) => handlerChange(e, i)}                                
                        />

                        <input className="w-[100%] text-right pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="bal_amt"
                            value={item.bal_amt}
                            onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] text-right pr-PaddingRight text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="act_amt"
                            value={item.act_amt}
                            onChange={(e) => handlerChange(e, i)}                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-3"
                            type="text"
                            name="notes"
                            value={item.notes}
                            onChange={(e) => handlerChange(e, i)}                                
                        />
                    </div>
                ))
            }
        </div>

        <div className="mt-2 border-TabBorderWidth border-TabHeadingBorderColor overflow-y-scroll">          
            
            <div className="grid lg:grid-cols-12">
                
                <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                </div>
        
                <div className="pr-PaddingRight text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                </div>

                <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                </div>

                <div className="pr-PaddingRight text-right text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-2">
                    Total
                </div>
                
                <div className="pr-PaddingRight text-right text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    {totaldue}
                </div>

                <div className="pr-PaddingRight text-right text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    {totalrec}
                </div>

                <div className="pr-PaddingRight text-right text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                    {totalbalance}
                </div>

                <div className="pr-PaddingRight text-right text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-1">
                </div>

                <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                    col-span-3">
                </div>
        
            </div>
        </div>
        
        <div className="mt-2 flex justify-center gap-4">

            <button className="w-[10%] bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={handleChangeBrachFY}>
                Year/Branch
            </button>

            <div className="w-[10%] text-center bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                >
                Record Found : {items.length}
            </div>  

            <button className="w-[10%] bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={createRecordFunction}>
                Save
            </button>        
        
            <button className="w-[10%] bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={goPrePageFunction}>
                Back
            </button>

        </div>
        
        <ModelFormFindStudentAll form_name={'UPDATE'} isVisible={ShowModelFormFindStudentAll} 
            onClose={() => setModelFormFindStudentFunction(false)} />

        <ModelFormYesNo table_name = 'st_fee_ledger' api_name = {'url_st_fee_ledger_updel_multiple'} isVisible = {ShowModelFormYesNo} onClose = {()=>setModelModelFormYesNo(false)} />


</>
);
};

export default Sfl;
