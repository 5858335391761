import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import CRUDContext from '../components/CRUDContext';

import { emptyGlobalDataFun, setTempDataFun } from '../components/redux/actions';

import Cookies from 'js-cookie';

const BranchWiseDefault = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';    
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);    
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);      
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);   

    const dispatch = useDispatch();
    const navigate = useNavigate();
        
    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData); 

    let v_input_result, v_active_status = 1, v_null_parameter = 'NA';

    // define variable for tables entry (master detail form)    

    const [items, setItems] = useState([]);        

    const [fin_year, setfin_year] = useState(0);
    const [fin_yearItems, setfin_yearItems] = useState([]);      

    const [copy_under_comp_branch_id, copy_setunder_comp_branch_id] = useState(0);
    const [copy_fin_year, copy_setfin_year] = useState(0);

    const [st_classItems, setst_classItems] = useState([]);
    const [st_class, setst_class] = useState('') ;

    const [copy_st_classItems, copy_setst_classItems] = useState([]);
    const [copy_st_class, copy_setst_class] = useState('');

    const [copy_stream, copy_setstream] = useState(0);
    const [copy_streamItems, copy_setstreamItems] = useState([]);

    const [image, setImage] = useState();
    const [allImage, setallImage] = useState([]);

    // /////////////////////////////////////////////////////////////////////////////

    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0)

    const [board, setboard] = useState(0)
    const [student_type, setstudent_type] = useState(0)
    const [medium, setmedium] = useState(0)
    const [stream, setstream] = useState(0)
    const [fee_categ, setfee_categ] = useState(0)
    const [pay_term, setpay_term] = useState(0) 
    const [prospectus_led_id, setprospectus_led_id] = useState(0)

    const [student_adv_amt_ac_led_id, setstudent_adv_amt_ac_led_id] = useState(0)

    const [def_pay_mode, setdef_pay_mode] = useState(0)
    
    const [notes, setnotes] = useState('')

    const [report_head_1, setreport_head_1] = useState('')
    const [report_head_2, setreport_head_2] = useState('')
    const [report_head_3, setreport_head_3] = useState('')
    const [report_head_4, setreport_head_4] = useState('')

    const [exam_report_head_1, setexam_report_head_1] = useState('')
    const [exam_report_head_2, setexam_report_head_2] = useState('')
    const [exam_report_head_3, setexam_report_head_3] = useState('')
    const [exam_report_head_4, setexam_report_head_4] = useState('')
    
    
    const [sec_label, setsec_label] = useState('')    
    const [group_label, setgroup_label] = useState('')    
    
    
    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);
    const [boardItems, setboardItems] = useState([]);
    const [student_typeItems, setstudent_typeItems] = useState([]);
    const [mediumItems, setmediumItems] = useState([]);
    const [streamItems, setstreamItems] = useState([]);
    const [fee_categItems, setfee_categItems] = useState([]);
    const [pay_termItems, setpay_termItems] = useState([]);
    const [prospectus_led_idItems, setprospectus_led_idItems] = useState([]);
    const [student_adv_amt_ac_led_idItems, setstudent_adv_amt_ac_led_idItems] = useState([]);

    const [def_pay_modeItems, setdef_pay_modeItems] = useState([]);

    const [checkedPrintName, setCheckedPrintName] = useState(false);  

    // define form name and navigation details

    const [v_urlSaveUpdateDelete, setv_urlSaveUpdateDelete] = useState('3_url_ExamGroup_branch_wise_new');
    
    const v_backToNavigateName = '/brms';
    // const v_findToNavigateName = '/';
    const v_main_form_heading = 'Exam Group Branch & Financial Year Wise';
    const v_frm_no = 3;

    const {
        deletedMessage,
        createdMessage,
        updatedMessage,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);          

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code

                setv_open_frm(true);

                // console.log("Update allow_update >> ", result.allow_update)

                setBlankFields();

                const v_token = Cookies.get('token');
                
                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_user_wise_branch_view_drop_down/38/0/0/${ReduxGlobalData[0].id}`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setunder_comp_branchItems(updatedata);               

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_get_data/38/${ReduxGlobalData[0].ag}/0/BOARD`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data
                setboardItems(updatedata);

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_get_data/38/${ReduxGlobalData[0].ag}/0/FEE_CATEG`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data
                setfee_categItems(updatedata);

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_get_data/38/${ReduxGlobalData[0].ag}/0/FEE_PAY_TERM`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data
                setpay_termItems(updatedata); 

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_get_data/38/${ReduxGlobalData[0].ag}/0/ST_TYPE`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data
                setstudent_typeItems(updatedata);

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_get_data/38/${ReduxGlobalData[0].ag}/0/MEDIUM`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data
                setmediumItems(updatedata);

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_get_data/38/${ReduxGlobalData[0].ag}/0/ST_STREAM`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data
                setstreamItems(updatedata);

                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_get_data/38/${ReduxGlobalData[0].ag}/0/PAY_MODE`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data
                setdef_pay_modeItems(updatedata);
            }
        }
        loadData();
    }, []);

    useEffect(() => {
        setBlankFields();
    }, [ReduxGlobalData[0]]);

    const handleCopyToRecordOption = () => {      
        
        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.info('Entry required for Under branch', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;  
        }

        if(fin_year === undefined || fin_year.length === undefined || fin_year.length === 0){
            toast.info('Entry required for Financial year', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.info('Entry required for Student class', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(stream === undefined || stream.length === undefined || stream.length === 0){
            toast.info('Entry required for Stream', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(copy_under_comp_branch_id === undefined || copy_under_comp_branch_id.length === undefined || copy_under_comp_branch_id.length === 0){
            toast.info('Entry required for Copy Under branch', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;  
        }

        if(copy_fin_year === undefined || copy_fin_year.length === undefined || copy_fin_year.length === 0){
            toast.info('Entry required for Copy Financial year', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(copy_st_class === undefined || copy_st_class.length === undefined || copy_st_class.length === 0){
            toast.info('Entry required for Copy Student class', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(copy_stream === undefined || copy_stream.length === undefined || copy_stream.length === 0){
            toast.info('Entry required for Copy Stream', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }
        
        if (under_comp_branch_id + fin_year + st_class + stream === copy_under_comp_branch_id + copy_fin_year + copy_st_class + copy_stream){
            toast.info('Copy From and To are same', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }
        
        const data = {
            insupdel_type : 'INSERT',
            copy_from_under_comp_branch_id : under_comp_branch_id, 
            copy_to_under_comp_branch_id : copy_under_comp_branch_id,
            copy_from_fin_year : fin_year, 
            copy_to_fin_year : copy_fin_year,
            copy_from_st_class : st_class, 
            copy_to_st_class : copy_st_class,
            copy_from_st_stream : stream, 
            copy_to_st_stream : copy_stream,            
        };

        let insupdel_type = 'INSERT';       

        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }
        
        setv_urlSaveUpdateDelete('3_url_copy_to_url_examgroup_branch_wise');
    }

    const FindSavedTableDetail = async(p_TableRecordID) => {

        if(p_TableRecordID === undefined || p_TableRecordID.length === undefined || p_TableRecordID.length === 0){
            toast.info('Entry required for Under branch', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;  
        }

        const v_token = Cookies.get('token');
        
        // show the detail part

        response = await axios.get(`url_fetch_data/3_url_branch_wise_default_view/${v_frm_no}/${ReduxGlobalData[0].ag}/${p_TableRecordID}/0/${v_active_status}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;             
        
        if (updatedata.length > 0)
        {
            setboard(updatedata[0].board) 
            setstudent_type(updatedata[0].student_type ) 
            setmedium(updatedata[0].medium) 
            setstream(updatedata[0].stream) 
            setfee_categ(updatedata[0].fee_categ) 
            setpay_term(updatedata[0].pay_term)
            setprospectus_led_id(updatedata[0].prospectus_led_id)
            setstudent_adv_amt_ac_led_id(updatedata[0].student_adv_amt_ac_led_id)
            setdef_pay_mode(updatedata[0].def_pay_mode)
        
            setnotes(updatedata[0].notes)

            setreport_head_1(updatedata[0].report_head_1)
            setreport_head_2(updatedata[0].report_head_2)
            setreport_head_3(updatedata[0].report_head_3)
            setreport_head_4(updatedata[0].report_head_4)

            setexam_report_head_1(updatedata[0].exam_report_head_1)
            setexam_report_head_2(updatedata[0].exam_report_head_2)
            setexam_report_head_3(updatedata[0].exam_report_head_3)
            setexam_report_head_4(updatedata[0].exam_report_head_4)

            setsec_label(updatedata[0].sec_label)
            setgroup_label(updatedata[0].group_label)       
            
            if (updatedata[0].print_name === 1){
                setCheckedPrintName(true);
            }else{
                setCheckedPrintName(false);
            }                   

            response = await axios.get(`url_fetch_data/38_url_getimage/38/${ReduxGlobalData[0].ag}/0/0/${v_active_status}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}        
            `,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data; 
            setallImage(updatedata);







        }else{
            setBlankFields();
        }

        
    }
    
    function setBlankFields() {      
        // setunder_comp_branch_id(0);
        setboard(0);
        setfee_categ(0);
        setpay_term(0);
        setstudent_type(0);
        setmedium(0);
        setstream(0);
        setdef_pay_mode(0);
        setprospectus_led_id(0);
        setstudent_adv_amt_ac_led_id(0);

        setreport_head_1('');
        setreport_head_2('');
        setreport_head_3('');
        setreport_head_4('');
        setexam_report_head_1('');
        setexam_report_head_2('');
        setexam_report_head_3('');
        setexam_report_head_4('');
        setsec_label('');
        setgroup_label('');
        setnotes('');

        setCheckedPrintName(false);
    }

    const handleInputValueFun = async(name, value) => {       

        const v_token = Cookies.get('token');

        if (name === 'under_comp_branch_id') {            
             
            setunder_comp_branch_id(value);

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${value}/0/FEE_CODE`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data
            setprospectus_led_idItems(updatedata);
            
            response = await axios.get(`url_fetch_data/22_url_ac_led_br_wise/${v_frm_no}/${ReduxGlobalData[0].ag}/${value}/0/${v_active_status}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}        
            `,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;             
            setstudent_adv_amt_ac_led_idItems(await updatedata);

            setBlankFields();
            FindSavedTableDetail(value);            
            return;
        }else if(name === 'board') {
            setboard(value);
            return;
        }else if(name === 'fee_categ') {
            setfee_categ(value);
            return;
        }else if(name === 'pay_term') {
            setpay_term(value);
            return;
        }else if(name === 'student_type') {
            setstudent_type(value);
            return;
        }else if(name === 'medium') {
            setmedium(value);
            return;
        }else if(name === 'stream') {
            setstream(value);
            return;
        }else if(name === 'def_pay_mode') {
            setdef_pay_mode(value);
            return;
        }else if(name === 'prospectus_led_id') {
            setprospectus_led_id(value);
            return;
        }else if(name === 'student_adv_amt_ac_led_id') {
            setstudent_adv_amt_ac_led_id(value);
            return;
        }else if(name === 'report_head_1') {
            setreport_head_1(value);
            return;
        }else if(name === 'report_head_2') {
            setreport_head_2(value);
            return;
        }else if(name === 'report_head_3') {
            setreport_head_3(value);
            return;
        }else if(name === 'report_head_4') {
            setreport_head_4(value);
            return;
        }else if(name === 'exam_report_head_1') {
            setexam_report_head_1(value);
            return;
        }else if(name === 'exam_report_head_2') {
            setexam_report_head_2(value);
            return;
        }else if(name === 'exam_report_head_3') {
            setexam_report_head_3(value);
            return;
        }else if(name === 'exam_report_head_4') {
            setexam_report_head_4(value);
            return;
        }else if(name === 'sec_label') {
            setsec_label(value);
            return;
        }else if(name === 'group_label') {
            setgroup_label(value);
            return;
        }else if(name === 'notes') {
            setnotes(value);
            return;
        }
    }

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        
        let insupdel_type = 'INSERT';

        let v_board = 0, v_student_type = 0, v_medium = 0, v_stream = 0, v_fee_categ = 0, v_pay_term = 0, v_prospectus_led_id = 0, 
            v_student_adv_amt_ac_led_id = 0, v_def_pay_mode = 0, v_print_name = 0;       

        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.info('Entry required for Under branch', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(parseInt(board) > 0){v_board = board;}
        if(parseInt(student_type) > 0){v_student_type = student_type;}
        if(parseInt(medium) > 0){v_medium = medium;}
        if(parseInt(stream) > 0){v_stream = stream;}
        if(parseInt(fee_categ) > 0){v_fee_categ = fee_categ;}
        if(parseInt(pay_term) > 0){v_pay_term = pay_term;}
        if(parseInt(prospectus_led_id) > 0){v_prospectus_led_id = prospectus_led_id;}
        if(parseInt(student_adv_amt_ac_led_id) > 0){v_student_adv_amt_ac_led_id = student_adv_amt_ac_led_id;}
        if(parseInt(def_pay_mode) > 0){v_def_pay_mode = def_pay_mode;}
        
        if(checkedPrintName){
            v_print_name = 1;
        }else{
            v_print_name = 2;
        }  

        const data = {
            id: 0,
            insupdel_type:'INSERT',            
            under_comp_branch_id,
            board :v_board,
            fee_categ:v_fee_categ,
            pay_term:v_pay_term, 
            student_type : v_student_type,            
            medium:v_medium,
            stream:v_stream,
            def_pay_mode : v_def_pay_mode,            
            prospectus_led_id : v_prospectus_led_id,            
            student_adv_amt_ac_led_id : v_student_adv_amt_ac_led_id,
            report_head_1,
            report_head_2,
            report_head_3,
            report_head_4,
            exam_report_head_1,
            exam_report_head_2,
            exam_report_head_3,
            exam_report_head_4,
            sec_label, 
            group_label,
            notes,
            print_name:v_print_name,
            active_status : 1,
            show_in_list : 1,
        };
        
        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);
        setv_urlSaveUpdateDelete('3_url_branch_wise_default_new');

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }

    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);        

        if (p_data.ModelYesNoAnswer === 'YES'){
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async(p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final:p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final:p_last_updel_notes_final,
        }        

        const v_token = Cookies.get('token');
        
        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });


        // ////////////////////////////////////////////////////////////

        // const formData = new FormData();
        // formData.append("image", image);
        // formData.append("id", under_comp_branch_id);
        // const v_urlSaveUpdateDelete_new = 'uploadimage';

        // response = await axios.post(`url_save_data_new_proc_image/${v_urlSaveUpdateDelete_new}/3`, formData, 
        // { headers: { Authorization: `Bearer ${v_token}` } });

        // // updatedata = await response.data.v_out_data;
        
        

        // ////////////////////////////////////////////////////////////
        
        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }
    
    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                    
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            // setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);
            
            // console.log("updatedata.TabID.v_data >> ", updatedata.v_data);
            
            if (updatedata.transType === 'INSERT'){                
                v_toast_msg = createdMessage;

                setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);

                getSavedDetailFunction(0);
            }else if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;

                getSavedDetailFunction(0);
            }else if (updatedata.transType === 'DELETE'){                
                v_toast_msg = deletedMessage;
                setBlankFields();
            }

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }

    const setNewRecordIDFunction = async() => {
        
        // console.log("setNewRecordIDFunction >> ", p_v_data[0].p_id, p_v_data[0].p_vou_no);

        // setTableRecordID(await p_v_data[0].p_id);        
    }

    const getSavedDetailFunction = async (p_v_data) => {     
        FindSavedTableDetail(under_comp_branch_id);
    }

    const handleInputFocus = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
    };

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        if (name === 'exam_principal_sign'){
            setImage(event.target.files[0]);
            return;
        }

        handleInputValueFun(name, value);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        if (value === 'BTN_FIND'){
            FindSavedTableDetail(0);
            return;
        }else if (value === 'BTN_COPY'){                        
            handleCopyToRecordOption();
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };
    
    const goCheckDataFunction = (event) => {
        event.preventDefault();

        // console.log("TableRecordID >> ", TableRecordID);

        console.log("items >> ", items);

    }

    // For details entry (entry in table)
    // For details entry (entry in table)
    
    const handlerChangeTableRowFocus = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;
    };

    const handlerChange = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;        

        let v_value = value;        
        const list = [...items];

        if (name === 'dis_seq'){
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', v_value, '', 100);
            v_value = v_input_result.v_value;
        }else if (name === 'print_name'){
            v_value = capitalizeWordFun(v_value);
        }else if (name === 'notes'){            
            v_value = capitalizeWordFun(v_value);
        }
        list[i][name] = v_value; 
        setItems(list);
    };

    const handleChangeCheckBox = (event) => {
        // const { name, checked } = event.target;          

        setCheckedPrintName(event.target.checked);
    }

    const setShowPopUpFalseFunction = (event) => {        
        // setShowPopUp(false);
    };

    const handleInputBlur = (event, i, p_col_name) => {
        // event.preventDefault();
        // const { name, value } = event.target;

        // console.log("handleInputBlur >> ", value);

        // // setSelectedrow(i);
        // // setSelectedColumn(p_col_name);

        // if (p_col_name === 'FEE_DETAIL_FEE_MONTH_DESCR' || p_col_name === 'FEE_DETAIL_FEE_CODE_DESCR'){
            

            
        //     setShowPopUp(true);
        // }else{
            
        //     setShowPopUp(false);
        // }

        // console.log('Input field lost focus');

    };

return (

<>
    {
        ShowConfirmModelSave ? 
        <div className="centerWindow_css">
            <ConfirmModelSave transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">

<div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

<div className="form_div_main_css">
    <div className="form_div_inner_css grid grid-cols-2">  

        <div className="col-span-1">
            <label className="label_inner_page_css required_css">Under Branch</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}       
                autoFocus         
                name="under_comp_branch_id"
                id="under_comp_branch_id"
                value={under_comp_branch_id}                                    
                onChange={handleInputValue}                                
                onFocus={handleInputFocus}                                
            > 
                <option></option>
                {
                    under_comp_branchItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>          
        
        <div className="col-span-1">
            <label className="label_inner_page_css">Board</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)} 
                name="board"
                id="board"
                value={board}                
                onChange={handleInputValue}                                
                onFocus={handleInputFocus}                                
            > 
                <option></option>
                {
                    boardItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>
        
        <div className="col-span-1">
            <label className="label_inner_page_css">Fee Category</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 1)}                
                name="fee_categ"
                id="fee_categ"
                value={fee_categ}                
                onChange={handleInputValue}                                
                onFocus={handleInputFocus}                                
            >                
            <option></option>
            {
                fee_categItems?.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
            </select>
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css">Payment Term</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 2)}                
                name="pay_term"
                id="pay_term"
                value={pay_term}                
                onChange={handleInputValue}                                
                onFocus={handleInputFocus}                                
            >                 
            <option></option>
            {
                pay_termItems?.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
            </select>
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css">Student Type</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 3)}                                    
                name="student_type"
                id="student_type"
                value={student_type}                
                onChange={handleInputValue}                                
                onFocus={handleInputFocus}                
            >                 
            <option></option>
            {
                student_typeItems?.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
            </select>
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css">Medium</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 5)}                
                name="medium"
                id="medium"
                value={medium}                
                onChange={handleInputValue}                                
                onFocus={handleInputFocus}
            >
            <option></option>
            {
                mediumItems?.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
            </select>
        </div> 
            
        <div className="col-span-1">
            <label className="label_inner_page_css">Stream</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 4)}                
                name="stream"
                id="stream"
                value={stream}                
                onChange={handleInputValue}                                
                onFocus={handleInputFocus}
            >
            <option></option>
            {
                streamItems?.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
            </select>
        </div> 

        <div className="col-span-1">
            <label className="label_inner_page_css">Default Payment Mode</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 4)}                
                name="def_pay_mode"
                id="def_pay_mode"
                value={def_pay_mode}                
                onChange={handleInputValue}                                
                onFocus={handleInputFocus}
            >
            <option></option>
            {
                def_pay_modeItems?.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
            </select>
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css">Prospectus Sale A/c Ledger</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 4)}                
                name="prospectus_led_id"
                id="prospectus_led_id"
                value={prospectus_led_id}                                  
                onChange={handleInputValue}                                
                onFocus={handleInputFocus}
            >
            <option></option>
            {
                prospectus_led_idItems?.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
            </select>
        </div>       

        <div className="col-span-1">
            <label className="label_inner_page_css">Student Advanced Amt.Received A/c Ledger</label>
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 4)}                
                name="student_adv_amt_ac_led_id"
                id="student_adv_amt_ac_led_id"
                value={student_adv_amt_ac_led_id}                
                onChange={handleInputValue}                                
                onFocus={handleInputFocus}
            >
            <option></option>
            {
                student_adv_amt_ac_led_idItems?.map((item, index) => (
                    <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                ))
            }
            </select>
        </div>             
            
        <div className="col-span-1">
            <label htmlFor="report_head_1" className="label_inner_page_css">Report Heading-1</label>
            <input className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 6)}
                type="text"                                
                name="report_head_1"
                id="report_head_1"
                value={report_head_1}                
                onChange={handleInputValue}                
                onFocus={handleInputFocus}                
            ></input>            
        </div>

        <div className="col-span-1">
            <label htmlFor="report_head_2" className="label_inner_page_css">Report Heading-2</label>
            <input className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 6)}
                type="text"                                
                name="report_head_2"
                id="report_head_2"
                value={report_head_2}                
                onChange={handleInputValue}                
                onFocus={handleInputFocus}
            ></input>            
        </div>

        <div className="col-span-1">
            <label htmlFor="report_head_3" className="label_inner_page_css">Report Heading-3</label>
            <input className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 6)}
                type="text"                                
                name="report_head_3"
                id="report_head_3"
                value={report_head_3}                
                onChange={handleInputValue}                
                onFocus={handleInputFocus}                
            ></input>            
        </div>
          
        <div className="col-span-1">
            <label htmlFor="report_head_4" className="label_inner_page_css">Report Heading-4</label>
            <input className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 6)}
                type="text"                                
                name="report_head_4"
                id="report_head_4"
                value={report_head_4}                
                onChange={handleInputValue}                
                onFocus={handleInputFocus}                
            ></input>            
        </div>
        
        <div className="col-span-1">
            <label htmlFor="exam_report_head_1" className="label_inner_page_css">Exam Report Card Heading-1</label>
            <input className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 6)}
                type="text"                                
                name="exam_report_head_1"
                id="exam_report_head_1"
                value={exam_report_head_1}                
                onChange={handleInputValue}                
                onFocus={handleInputFocus}                
            ></input>            
        </div>

        <div className="col-span-1">
            <label htmlFor="exam_report_head_2" className="label_inner_page_css">Exam Report Card Heading-2</label>
            <input className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 6)}
                type="text"                                
                name="exam_report_head_2"
                id="exam_report_head_2"
                value={exam_report_head_2}                
                onChange={handleInputValue}                
                onFocus={handleInputFocus}                
            ></input>            
        </div>

        <div className="col-span-1">
            <label htmlFor="exam_report_head_3" className="label_inner_page_css">Exam Report Card Heading-3</label>
            <input className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 6)}
                type="text"                                
                name="exam_report_head_3"
                id="exam_report_head_3"
                value={exam_report_head_3}                
                onChange={handleInputValue}                
                onFocus={handleInputFocus}
            ></input>            
        </div>
            
        <div className="col-span-1">
            <label htmlFor="exam_report_head_4" className="label_inner_page_css">Exam Report Card Heading-4</label>
            <input className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 6)}
                type="text"                                
                name="exam_report_head_4"
                id="exam_report_head_4"
                value={exam_report_head_4}               
                onChange={handleInputValue}                
                onFocus={handleInputFocus}                
            ></input>            
        </div>

        <div className="col-span-1">
            <label htmlFor="sec_label" className="label_inner_page_css">Show Section Label</label>
            <input className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 6)}
                type="text"                                
                name="sec_label"
                id="sec_label"
                value={sec_label}                  
                onChange={handleInputValue}                
                onFocus={handleInputFocus}                
            ></input>            
        </div>

        <div className="col-span-1">
            <label htmlFor="group_label" className="label_inner_page_css">Show Group Label</label>
            <input className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 6)}
                type="text"                                
                name="group_label"
                id="group_label"
                value={group_label}                  
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            ></input>            
        </div>

        <div className="col-span-1">
            <label className="label_inner_page_css">Notes</label>
            <input className="input_inner_page_css"
                type="text"
                ref={(ref) => addInputRef(ref, 33)}
                name="notes"
                id="notes"
                value={notes}                
                onChange={handleInputValue}                
                onFocus={handleInputFocus}
            ></input>              
        </div>

        <div className="col-span-1">           
            <label className="label_inner_page_css">Print Name on Receipt</label>            
            <input className="table_check_inner_page_css w-10"
                type="checkbox" name="checkedPrintName" checked={checkedPrintName}
                onChange={handleChangeCheckBox}
                onFocus={handleInputFocus}
            />        
        </div>

        <div className="col-span-1">     
            <label className="label_inner_page_css">Principal Sign.on Exam Report Card</label>            
            <input className="table_check_inner_page_css w-[100%]"
                type="file" accept="image/*"
                name="exam_principal_sign"            
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            />
        </div>

        {
            allImage.map((data) => {
            return(

            <div>

                {/* <img src = {data.emp_img} /> */}

                <img src = {require(`../images/${data.emp_img}`)} />

            </div>

            )
            
        })}  

        {/* <input type = "file" accept="image/*" onChange={onInputChange}></input> */}

    </div>
</div>

<nav className="btn_nav_inner_css">
<div className="btn_div_main_css">
<div className="btn_div_inner_css">  

    <div>
        {userAuthData.allow_new === 1 && (
            <button className="btn_save_css"                
                onClick={createRecordFunction}>
                Save
            </button>
        )}
    </div>  
  
    <div>
        <button className="btn_back_css"
            value = 'BTN_BACK'
            onClick={handleButtonValue}>
            Back
        </button>
    </div>

    {/* <div>
        <button className="btn_back_css"
            onClick={goCheckDataFunction}>
            Check Data
        </button>
    </div> */}
            
</div>
    
</div>
</nav>
</form>
:null
}
</>
);};

export default BranchWiseDefault;
