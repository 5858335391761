import { takeEvery, put } from 'redux-saga/effects';
import { SET_USER_DATA_SAGA, USER_LIST } from './constants';

function* userListFun() {
    // console.warn('Saga function called');

    // try {
    //     const response = yield fetch('http://192.168.1.35:3001/api/testemployee');
    //     const json = yield response.json();
    //     const data = json.v_out_data;

    //     yield put({type:SET_USER_DATA_SAGA, data})


    //     console.warn('Saga function called >>', json.v_out_data);



    //     // setData(json.v_out_data);

    // } catch (error) {
    //     console.error(error);
    // } finally {
    //     // setLoading(false);
    // }

}

function* SagaData() {

    yield takeEvery(USER_LIST, userListFun);

}

export default SagaData;
