import { ADD_TO_CART, REMOVE_FROM_CART, SET_USER_DATA_SAGA, SET_GLOBAL_DATA, EMPTY_GLOBAL_DATA, SET_TEMP_DATA, EMPTY_TEMP_DATA,
    SET_DD_BRANCH_BOARD, EMPTY_DD_BRANCH_BOARD, SET_DD_MONTH, EMPTY_DD_MONTH, SET_DD_STUDENT_TYPE, EMPTY_DD_STUDENT_TYPE, SET_DD_MEDIUM, EMPTY_DD_MEDIUM,
    SET_DD_ST_STREAM, EMPTY_DD_ST_STREAM, SET_DD_FEE_CATEG, EMPTY_DD_FEE_CATEG,
    SET_DD_REL_TYPE, EMPTY_DD_REL_TYPE, SET_DD_JOB_NATURE, EMPTY_DD_JOB_NATURE, SET_DD_OCCUP, EMPTY_DD_OCCUP, 
    SET_DD_APP_FOR, EMPTY_DD_APP_FOR, SET_DD_ST_SEC, EMPTY_DD_ST_SEC, SET_DD_PRE_SCH_CL, EMPTY_DD_PRE_SCH_CL,
    SET_DD_PRE_SCH, EMPTY_DD_PRE_SCH, SET_DD_CASTE, EMPTY_DD_CASTE,
    SET_DD_CAST_CATEG, EMPTY_DD_CAST_CATEG,
    SET_DD_RELIGION, EMPTY_DD_RELIGION,
    SET_DD_GENDER, EMPTY_DD_GENDER,
    SET_DD_ST_CLASS, EMPTY_DD_ST_CLASS,
    SET_DD_FEE_PAY_TERM, EMPTY_DD_FEE_PAY_TERM,
    SET_DD_TABLE_PER_PAGE, EMPTY_DD_TABLE_PER_PAGE,
    SET_DD_FEE_CODE, EMPTY_DD_FEE_CODE, 
    SET_DD_SEQ_WISE_MONTH, EMPTY_DD_SEQ_WISE_MONTH,  
    SET_DD_FEE_GROUP_TYPE, EMPTY_DD_FEE_GROUP_TYPE,
} from './constants';

const initialState = [];

// below paramter comes from addToCartAction (in action.js file)

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            // console.warn("In reducer : ", action.data)
            // console.warn("In reducer : ", state)
            return [
                ...state,
                action.data
            ]

        // 12:53 Anil Sindhu

        case REMOVE_FROM_CART:
            // console.warn("In reducer : ", action.data)
            // console.warn("In reducer : ", state)

            let result = state.filter(item => {
                return item.name !== action.data;
            })

            return [...result]

        case SET_USER_DATA_SAGA:            
            return [...state, action.data]        

        default:
            return state

    }

}

export const GlobalData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_GLOBAL_DATA:
            return [...state, action.data];

        case EMPTY_GLOBAL_DATA:            
            state = initialState;
            return [...state];

        default:
            return state;
    }
}

export const TempData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_TEMP_DATA:
            return [...state, action.data]
            
        case EMPTY_TEMP_DATA:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDBranchBoardData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_BRANCH_BOARD:
            return [...state, action.data]
            
        case EMPTY_DD_BRANCH_BOARD:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDMonthData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_MONTH:
            return [...state, action.data]
            
        case EMPTY_DD_MONTH:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDStudentTypeData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_STUDENT_TYPE:
            return [...state, action.data]
            
        case EMPTY_DD_STUDENT_TYPE:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}


export const DDMediumData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_MEDIUM:
            return [...state, action.data]
            
        case EMPTY_DD_MEDIUM:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDStStreamData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_ST_STREAM:
            return [...state, action.data]
            
        case EMPTY_DD_ST_STREAM:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDFeeCategData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_FEE_CATEG:
            return [...state, action.data]
            
        case EMPTY_DD_FEE_CATEG:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDFeePaymentTermData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_FEE_PAY_TERM:
            return [...state, action.data]
            
        case EMPTY_DD_FEE_PAY_TERM:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDStudentClassData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_ST_CLASS:
            return [...state, action.data]
            
        case EMPTY_DD_ST_CLASS:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDGenderData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_GENDER:
            return [...state, action.data]
            
        case EMPTY_DD_GENDER:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDReligionData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_RELIGION:
            return [...state, action.data]
            
        case EMPTY_DD_RELIGION:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDCasteCategData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_CAST_CATEG:
            return [...state, action.data]
            
        case EMPTY_DD_CAST_CATEG:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDCasteData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_CASTE:
            return [...state, action.data]
            
        case EMPTY_DD_CASTE:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDPreviousSchoolData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_PRE_SCH:
            return [...state, action.data]
            
        case EMPTY_DD_PRE_SCH:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDPreviousClassData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_PRE_SCH_CL:
            return [...state, action.data]
            
        case EMPTY_DD_PRE_SCH_CL:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDStudentSectionData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_ST_SEC:
            return [...state, action.data]
            
        case EMPTY_DD_ST_SEC:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDAppForData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_APP_FOR:
            return [...state, action.data]
            
        case EMPTY_DD_APP_FOR:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDOccupationData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_OCCUP:
            return [...state, action.data]
            
        case EMPTY_DD_OCCUP:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDJobNatureData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_JOB_NATURE:
            return [...state, action.data]
            
        case EMPTY_DD_JOB_NATURE:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDRelationTypeData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_REL_TYPE:
            return [...state, action.data]
            
        case EMPTY_DD_REL_TYPE:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDTablePerPageData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_TABLE_PER_PAGE:
            return [...state, action.data]
            
        case EMPTY_DD_TABLE_PER_PAGE:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDFeeCodeData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_FEE_CODE:
            return [...state, action.data]
            
        case EMPTY_DD_FEE_CODE:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDFeeMonthSeqWiseData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_SEQ_WISE_MONTH:
            return [...state, action.data]
            
        case EMPTY_DD_SEQ_WISE_MONTH:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}

export const DDFeeGroupTypeData = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_DD_FEE_GROUP_TYPE:
            return [...state, action.data]
            
        case EMPTY_DD_FEE_GROUP_TYPE:
            state = initialState;
            return [...state];            

        default:
            return state;
    }
}


