import React from "react";

const DropDownTableComponent = (props) => {

    return (
        <div>
            <select className="table_input_inner_page_css"
                // autoFocus
                name={props.columnName}
                id={props.columnName}
                value={props.columnNameValue}   
                onChange={(e) => props.fun1(e, props.p_val)}
                disabled = {props.saveUpdateTag}                
            >
                <option></option>
                {
                    props.paramItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>  
    );
};

export default DropDownTableComponent;


