import React, { useContext, useState, useEffect, useRef } from "react";
import axios from 'axios';
// import CRUDContext from '../components/CRUDContext';
import { useNavigate } from "react-router-dom";
// import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from 'react-redux';

import Cookies from 'js-cookie';

const ExamReportCardPrintPreview = (props) => {
    let response = [], updatedata = []; 

    const navigate = useNavigate();

    const conponentPDF = useRef();        
    const [items, setItems] = useState([]);   

    const ReduxGlobalData = useSelector((state) => state.GlobalData);

    const [itemsRemarks, setItemsRemarks] = useState([]);
    const [st_name, setst_name] = useState('');  
    const [fat_name, setfat_name] = useState('');
    const [mot_name, setmot_name] = useState('');
    const [adm_no, setadm_no] = useState('');
    const [dob, setdob] = useState('');
    const [columnRemarks, setcolumnRemarks] = useState([]);

    const [column, setcolumn] = useState([]);    
  
    const [st_classprint, setst_classprint] = useState('');
    const [st_secprint , setst_secprint] = useState('');

    const [st_streamprintlabel, setst_streamprintlabel] = useState('');
    
    const [st_streamprint, setst_streamprint] = useState('');

    // const [fin_year, setfin_year] = useState('');
    const [examRemarks, setExamRemarks] = useState('');

    const [examFinalRemarks, setExamFinalRemarks] = useState('');

    const [school_reopen_date, setschool_reopen_date] = useState('');
    const [school_reopen_time, setschool_reopen_time] = useState('');
    const [school_reopen_notes, setschool_reopen_notes] = useState('');

    const [final_rank, setfinal_rank] = useState('');
    const [final_perc, setfinal_perc] = useState('');
    

    const[BlankLine, setBlankLine] = useState([]);
    
    let v_null_parameter = 'NA';

    let v_frm_no = 35;

    useEffect(() => {
         
        const loadData = async () => { 
         
            let v_active_status = 1, column1;   
            
            let table_line = 0;
            
            // 598 is for Academics Performance 

            let v_exam_group = 598;            

            const v_token = Cookies.get('token');
        
            response = await axios.get(`url_fetch_data/25_url_exam_report_card_fun/${v_frm_no}/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
            /${props.st_id}
            /${v_exam_group}
            /${v_null_parameter}
            /${v_null_parameter}  
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}        
            `,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;            
            
            table_line = updatedata.length;

            // console.log("598 updatedata >> ", updatedata.length, " >> ", updatedata.length);
            
            if(updatedata.length > 0){
                setItems(await updatedata);
                column1 = Object.keys(await updatedata[0]);
                setcolumn(column1);          
            }
            

            // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            // for Non-Academics Performance

            v_exam_group = 710;            
        
            response = await axios.get(`url_fetch_data/25_url_exam_report_card_fun/${v_frm_no}/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
            /${props.st_id}
            /${v_exam_group}
            /${v_null_parameter}
            /${v_null_parameter}  
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}        
            `,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;

            // console.log("710 updatedata >> ", updatedata);

            table_line = parseInt(table_line) + parseInt(updatedata.length);

            // console.log("710 updatedata >> ", updatedata.length, " >> ",table_line);
            
            if(updatedata.length > 0){
                setItemsRemarks(await updatedata);
                column1 = Object.keys(await updatedata[0]);
                setcolumnRemarks(column1);
            }

            // console.log("710 updatedata column1 >> ", column1);  

            // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////          

            response = await axios.get(`url_fetch_data/25_url_exam_consolidate_output/${v_frm_no}/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
            /${props.st_id}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}  
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}        
            `,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;                
            
            setst_classprint(updatedata[0].st_class)
                  
            
            if (updatedata[0].st_stream === 'Common'){
                setst_streamprint(''); 
                setst_streamprintlabel('');   
            }else{
                setst_streamprint(updatedata[0].st_stream);
                setst_streamprintlabel('Stream:')
            }

            const v_blank = Cookies.get('tempVal');

            // console.log("BLANK >> ", v_blank)

            if (v_blank === 'NO_BLANK'){
                setst_name(updatedata[0].st_name);
                setfat_name(updatedata[0].fat_name);
                setmot_name(updatedata[0].mot_name);
                setadm_no(updatedata[0].adm_no);
                setdob(updatedata[0].dob); 
                setst_secprint(updatedata[0].st_sec);  
            }

            setExamRemarks(updatedata[0].term_remarks);
            setExamFinalRemarks(updatedata[0].exam_final_remarks);

            setschool_reopen_date(updatedata[0].school_reopen_date);
            setschool_reopen_time(updatedata[0].school_reopen_time);
            setschool_reopen_notes(updatedata[0].school_reopen_notes);

            setfinal_rank(updatedata[0].final_rank);
            setfinal_perc(updatedata[0].final_perc);

            // console.log("updatedata[0] >> ", updatedata[0])

            // we are considering total 26 rows in A4 size print

            // let v_obj = [];
            // let v_list = [];
            
            // for (var i = 0; i < 100 - parseInt(table_line); i++) {
            //     v_obj = [];
            //     for (var j = 0; j < 4; j++) {
            //         let key = "col_" + (j + 1);
            //         let value = '';
            //         v_obj[key] = value;
            //     }                
            //     v_list.push(v_obj);
            // }
            // setBlankLine(v_list);

        }
        loadData();
    }, []);
    
const ThData =()=>{

    return column?.map((data, index)=>{
        const target = data.indexOf('>');
        const colName = data.substring(0,target);        

        if (index === 0){
            return <td className="w-[5%] ml-1 text-1xl border border-gray-900 bg-slate-200 font-semibold" key={data}><span className="ml-1 text-left">{data}</span></td>

        }else if (index >= 1){
            return <td className="w-[5%] ml-1 text-1xl border border-gray-900 bg-slate-200 font-semibold" key={data}><span className="ml-1 text-left">{colName}</span></td>       

        }
    })

}

const tdData =() =>{    

    return items?.map((data)=>{
    return(
    <tr>
    {

        column?.map((v_data, index)=>{

            let v_col_data, v_db_css;

            if(data[v_data]?.length > 0){

                v_col_data =  data[v_data].split(">");
                v_db_css = v_col_data[0];
                v_col_data = v_col_data[1];
            
            }
            
            if (parseInt(v_col_data) === 0){v_col_data = ''};

            if (index === 0){
                
                // return <td className="border border-gray-300"><span className="text-sm">{v_col_data}</span></td>

                // return <td className="border border-gray-300 text-xs">{v_col_data}</td>

                return <td className={v_db_css}>{v_col_data}</td>
            
            }else if (index => 1){

                // return <td className="border border-gray-300 text-xs">{v_col_data}</td>
                
                return <td className={v_db_css}>{v_col_data}</td>         

            }
            
        })
    }
    </tr>

)})
}

// for remarks


const tdDataRemarks =() =>{

    // return;

    return itemsRemarks.map((data)=>{
    return(

        <tr>
            {

            columnRemarks.map((v_data, index)=>{

                let v_col_data, v_db_css;

                if(data[v_data]?.length > 0){
    
                    v_col_data =  data[v_data].split(">");
                    v_db_css = v_col_data[0];
                    v_col_data = v_col_data[1];
                
                }
                
                if (parseInt(v_col_data) === 0){v_col_data = ''};
    
                if (index === 0){                    

                    // return <td className="border border-gray-300 text-xs">{v_col_data}</td>

                    return <td className={v_db_css}>{v_col_data}</td>
                
                }else if (index => 1){                    
                  
                    // return <td className="border border-gray-300 text-xs">{v_col_data}</td>

                    return <td className={v_db_css}>{v_col_data}</td>
    
                }    
                
                // if (index => 1){                    
                  
                //     return <td className="border border-gray-300 text-xs ml-10">{v_col_data}</td>                    
    
                // }    

                
            })
            }

        </tr>

        // <tr>
        //     {

        //     columnRemarks.map((v_data, index)=>{

        //         let v_col_data;

        //         if(data[v_data]?.length > 0){

        //             v_col_data =  data[v_data].split(">");
        //             v_subject_marks_css = v_col_data[0];
        //             v_col_data = v_col_data[1];
                
        //         }

        //         if (index === 0){
        //             return <td className="w-[60%] text-1xl border border-gray-300">
        //             {v_col_data}</td>
                
        //         }else if (index >= 1){
        //             return <td className="w-[10%] text-1xl border border-gray-300">
        //             {v_col_data}</td>                    
        //         }                    
                
        //     })
        //     }
        // </tr>
    )
    })
}

const ThDataRemarks =()=>{

    // return;

    // const target = givenWord.indexOf(toSearch);
    // // const firstPart = givenWord.substring(0,target);

    return columnRemarks?.map((data, index)=>{

        const target = data.indexOf('>');
        const colName = data.substring(0,target);

        if (index === 0){
            return <td className="w-[60%] text-1xl border border-gray-900 bg-slate-200 font-semibold"
            key={data}>{data}</td>
        
        }else if (index >= 1){
            return <td className="w-[10%] text-1xl border border-gray-900 bg-slate-200 font-semibold"
            key={data}>{colName}                    
            </td>
        
        }

    })
}

 // get table row data
    

// const PrintPDF = useReactToPrint({
//     // content: () => conponentPDF.current,
//     // documentTitle: "Userdata",
//     // onAfterPrint: () => alert("Data saved in PDF")

//     content: () => conponentPDF.current,
//     documentTitle: "",
//     // pageStyle : "35px"
//     // pageStyle : "@page { size: 2.5in 4in }"        
//     // pageStyle : "1080px"

//     pageStyle : "size: A4 landscape"

//     // pageStyle : "size: A4 portrait" 
    
//     // ///////////////////////////////////////////////////////////////////////////////////////////////////////
     
//     // content: () => conponentPDF.current,
//     // documentTitle: "Daily Fee Collecion",        
//     // pageStyle: "size: A4 landscape"
    
// });

return (
<>

{/* <div className="ml-[15px] w-[750px] border border-black bg-blue-150 bg-red-100"> */}

<div className="ml-[15px] w-[750px] h-[1100px] border-4 border-black bg-blue-150 relative">

    <div className="flex border-black border-b-[1px]">
    
        {/* <div className="mt-3 ml-2">
            <img src="KGIC_LKO_LOGO.jpg" alt="Logo" width="120" height="120"></img>
        </div> */}

        {
            (parseInt(ReduxGlobalData[0].br) === 30 ) ?            
            <div className="mt-3 ml-2">
                <img src="OXFORD_LKO_LOGO.jpg" alt="Logo" width="90" height="900"></img>
            </div>            
            :          
            null
        }

        {
            (parseInt(ReduxGlobalData[0].br) === 31 || parseInt(ReduxGlobalData[0].br) === 25 || parseInt(ReduxGlobalData[0].br) === 26 || parseInt(ReduxGlobalData[0].br) === 27 || parseInt(ReduxGlobalData[0].br) === 28 || parseInt(ReduxGlobalData[0].br) === 29 ) ?
            <div className="mt-3 ml-2">
                <img src="KGIC_LKO_LOGO.jpg" alt="Logo" width="120" height="120"></img>
            </div> 
            :          
            null
        }

        {/* <div className="w-[100%] text-center">            
            <div className={ReduxGlobalData[0].exam_report_head_1.split(">")[0]}>{ReduxGlobalData[0].exam_report_head_1.split(">")[1]}</div>
            <div className={ReduxGlobalData[0].exam_report_head_2.split(">")[0]}>{ReduxGlobalData[0].exam_report_head_2.split(">")[1]}</div>
            <div className={ReduxGlobalData[0].exam_report_head_3.split(">")[0]}>{ReduxGlobalData[0].exam_report_head_3.split(">")[1]}20{ReduxGlobalData[0].fy_name}</div>
            <div className={ReduxGlobalData[0].exam_report_head_4.split(">")[0]}>{ReduxGlobalData[0].exam_report_head_4.split(">")[1]}</div>
        </div> */}

        <div className="w-[100%] text-center">            

            <div className="text-2xl font-bold text-blue-950">{ReduxGlobalData[0].exam_report_head_1.split(">")[1]}</div>
            <div className="font-bold text-blue-950 text-[14px]">{ReduxGlobalData[0].exam_report_head_2.split(">")[1]}</div>
            <div className="font-bold text-blue-950 text-[16px]">{ReduxGlobalData[0].exam_report_head_3.split(">")[1]}</div>
            <div className="font-bold text-blue-950 text-[16px]">{ReduxGlobalData[0].exam_report_head_4.split(">")[1]}20{ReduxGlobalData[0].fy_name}</div>
            <div className="font-bold text-red-800" >REPORT CARD</div>            
            
        </div>

    </div>

    <div className="flex ml-1">
        <div className="w-[70%] text-1xl font-semibold capitalize"><span className="font-bold">Student's Name :</span>{st_name}</div>
        <div className="w-[30%] text-1xl font-semibold"><span className="font-bold">Class: </span>{st_classprint} - {st_secprint} {st_streamprint}</div>
    </div>

    <div className="flex ml-1">
        <div className="w-[70%] text-1xl font-semibold capitalize"><span className="font-bold">Father's Name :</span>{fat_name}</div>
        <div className="w-[30%] text-1xl font-semibold"><span className="font-bold">DOB :</span>{dob}</div>
    </div>

    <div className="flex border-black border-b-[1px]">
        <div className="ml-1 w-[70%] text-1xl font-semibold capitalize"><span className="font-bold">Mother's Name :</span>{mot_name}</div>
        <div className="ml-1 w-[30%] text-1xl font-semibold"><span className="font-bold">Adm.No.:</span>{adm_no}</div>
    </div>

    <div className="text-1xl font-bold border-black border-b-[1px]"><span className="ml-1">Academic Performance</span></div>

    {ThData()}
    {tdData()}    

    <div className="flex border-black border-y-[1px] border-b-[0px]">

        <div className="w-[65%] border-black border-x-[1px] border-b-[1px]">

            <div className="w-[100%] text-1xl font-bold border-black border-b-[1px]"><span className="ml-1">Non-Academic Performance</span></div>

            {ThDataRemarks()}

            {tdDataRemarks()}            

        </div>

        <div className="w-[35%]">

            <div className="ml-1 border-black text-1xl mb-1"><span className="font-bold"> Overall Rank:</span>{final_rank}</div>

            <div className="ml-1 border-black text-1xl mb-1"><span className="font-bold"> Overall Percentage:</span>{final_perc}</div>        

            <div className="ml-1 border-black text-1xl mb-1"><span className="font-bold">Remarks:</span>{examRemarks}</div>

            <div className="ml-1 text-1xl"><span className="font-bold text-blue-950">RESULT:</span> {examFinalRemarks}</div>

            <div className="ml-1 border-black text-1xl mt-2 font-bold">School will reopen</div>
            
            <div><span className="ml-1 font-bold">on </span>{school_reopen_date}<span className="font-bold"> at </span>{school_reopen_time}</div>            

            <div className="ml-1 border-black text-1xl mt-2 font-bold">{school_reopen_notes}</div>

            
            {/* <div className="mt-3 ml-2 bg-yellow-100 top-64">
                <img src="KGIC_LKO_PRINCIPAL_SIGN.jpg" alt="Logo" width="70" height="50"></img>
            </div> */}

            <div class="relative">
                {/* <div class="absolute bottom-0 right-0">09</div> */}

                {/* <img class="absolute bottom-0 top-28 right-12" src="KGIC_LKO_PRINCIPAL_SIGN.jpg" alt="Logo" width="70" height="50"></img> */}
                
            </div>

        </div>

        {/* <div className="mt-3 ml-2 bg-yellow-100">
            <img src="KGIC_LKO_PRINCIPAL_SIGN.jpg" alt="Logo" width="70" height="50"></img>
        </div> */}

    </div>

    {/* {
        BlankLine.map((item, index) => (
            <tr key={index}>                                
                <td className="border border-none">{}</td>

            </tr>                            
    ))} */}

    {
        (parseInt(ReduxGlobalData[0].br) === 25 || parseInt(ReduxGlobalData[0].br) === 26 ) ?
        <div className="flex space-x-28 text-1xl mt-5 font-bold right-20 absolute bottom-5">
            <div className="mt-3 ml-2">
                <img src="KGIC_LKO_PRINCIPAL_SIGN.jpg" alt="Logo" width="90" height="80"></img>
            </div>
        </div>                          
        :          
        null
    }

    {
        (parseInt(ReduxGlobalData[0].br) === 28 || parseInt(ReduxGlobalData[0].br) === 29 ) ?
        <div className="flex space-x-28 text-1xl mt-5 font-bold right-20 absolute bottom-5">
            <div className="mt-3 ml-2">
                <img src="TELCO_LKO_PRINCIPAL_SIGN.jpg" alt="Logo" width="90" height="80"></img>
            </div>
        </div>                          
        :          
        null
    }

    

    {
        ((parseInt(ReduxGlobalData[0].br) === 30 || parseInt(ReduxGlobalData[0].br) === 31) && Cookies.get('tempVal') === 'NO_BLANK') ?
        <div className="flex space-x-28 text-1xl mt-5 font-bold right-20 absolute bottom-5">
            <div className="mt-3 ml-2">
                <img src="OXFORD_LKO_PRINCIPAL_SIGN.jpg" alt="Logo" width="90" height="80"></img>
            </div>
        </div>                          
        :          
        null
    }

    <div className="flex space-x-28 text-1xl mt-5 font-bold absolute bottom-0">
        
        <div className="ml-1">Signature of Class Teacher</div>

        <div>Signature of Parents</div>

        <div>Signature of Principal</div>   

    </div> 
    
</div>
</>
);};

export default ExamReportCardPrintPreview;
