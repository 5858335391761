import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import SelectRecordPopUpSingleColumn from '../components/SelectRecordPopUpSingleColumn';
import CRUDContext from '../components/CRUDContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

import { faSortAlphaDownAlt, faSortAlphaDown} from '@fortawesome/free-solid-svg-icons';
import { emptyTempDataFun, setTempDataFun, emptyGlobalDataFun } from '../components/redux/actions';

const StudentYearEndProcess = () => {
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';
    const [TransType, setTransType] = useState('');
    const [userAuthData, setUserAuthData] = useState([]);   
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const [rowsLimit, setRowsLimit] = useState(10000);    
    const [ascWise, setAscWise] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);
    const [conditionalCSS, setConditionalCSS] = useState(false);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // standard items define for table populate

    const [items, setItems] = useState([]);
    const [origionalData, setOrigionalData] = useState([]);
    const [filterItems, setFilterItems] = useState([]);
    const [FilterStatus, setFilterStatus] = useState(false);

    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0);
    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);   

    const [copy_under_comp_branch_id, copy_setunder_comp_branch_id] = useState(0);
    
    // const [copy_fin_year, copy_setfin_year] = useState(0);

    // const [st_classItems, setst_classItems] = useState([]);
    const [st_class, setst_class] = useState('');

    const [copy_st_classItems, copy_setst_classItems] = useState([]);
    const [copy_st_class, copy_setst_class] = useState(0);

    const [copy_st_secItems, copy_setst_secItems] = useState([]);
    const [st_sec, setst_sec] = useState('') ;

    const [streamItems, setstreamItems] = useState([]);
    const [stream, setstream] = useState(0);

    const [copy_stream, copy_setstream] = useState(0);
    const [copy_streamItems, copy_setstreamItems] = useState([]);

    const [copy_st_sec, copy_setst_sec] = useState(0);

    const [student_type, setstudent_type] = useState(0);
    const [copy_student_type, copy_setstudent_type] = useState(0);

    const [student_typeItems, setstudent_typeItems] = useState([]);
    const [copy_student_typeItems, copy_setstudent_typeItems] = useState([]);

    // items search list items
    
    const [st_classItems, setst_classItems] = useState([]);
    const [st_secItems, setst_secItems] = useState([]);

    // define query input
    
    const [queryStClass, setQueryStClass] = useState([]);
    const [queryStSec, setQueryStSec] = useState([]);
    
    // show and hide pop-up

    const [ShowPopUpSt_Class, setShowPopUpSt_Class] = useState(false);
    const [ShowPopUpSt_Sec, setShowPopUpSt_Sec] = useState(false);   

    // save ID and Desc

    const [IDArraySt_Class, setIDArraySt_Class] = useState([]);
    const [IDArraySt_Sec, setIDArraySt_Sec] = useState([]);

    const [IDArray, setIDArray] = useState([]);
    const [IDArrayValue, setIDArrayValue] = useState([]);

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData);
   
    // define form name and navigation details

    const [sortingColumn, setSortingColumn] = useState(["st_name"]);
    const [v_urlSaveUpdateDelete, setv_urlSaveUpdateDelete] = useState('');    
    const v_backToNavigateName = '/dashboard';
    const v_findToNavigateName = '';
    const v_main_form_heading = 'Year-Ending Process';
    const v_frm_no = 39;

    let v_input_result, v_active_status = 1, v_null_parameter = 'NA';

    // define standard errors and entry required messages

    const {
        deletedMessage,
        whenNoRecordFoundMessage, 
        updatedMessage,       
        whenTablePaginationLastPage,
        whenTablePaginationFirstPage,
        WhenRecordNotFoundToDelete,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,
    } = useContext(CRUDContext);

    useEffect(() => {

        async function loadData() {
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);          

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());
                
                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_read === 2) {
                toast.info(v_allow_open, {
                    position: v_toast_position, autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
                });
                navigate(`${v_backToNavigateName}`);
                return;
            } else {

                setBlankFields();
                
                const v_token = Cookies.get('token');
                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_user_wise_branch_view_drop_down/38/0/0/${ReduxGlobalData[0].id}`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setunder_comp_branchItems(await updatedata);                

            }
        }
        loadData();
    }, [ReduxGlobalData[0]]);

    function setBlankFields() {

        setIDArray([]);
        setItems([]);
        
        // setst_class([]);
        // setst_sec([]);
        // setstream([]);
        // setstudent_type([]);

        // copy_setst_class([]);
        // copy_setst_sec([]);
        // copy_setstream([]);
        // copy_setstudent_type([]);
        
    }

    const ShowRecordFun = async () => {

        // console.log("In API Part")

        // edit in token
        // delete token
        // if user is not active
        // check if permission is allow for new, update and delete      

        setBlankFields();

        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.info('Entry required for Under branch', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;  
        }        

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.info('Entry required for Student class', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(st_sec === undefined || st_sec.length === undefined || st_sec.length === 0){
            toast.info('Entry required for Class section', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }        

        if(stream === undefined || stream.length === undefined || stream.length === 0){
            toast.info('Entry required for Stream', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        if(student_type === undefined || student_type.length === undefined || student_type.length === 0){
            toast.info('Entry required for Student type', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }

        const v_token = Cookies.get('token');

        // SetPaginationItems([]);
        // setOrigionalData([]);
        // setItems([]);

        

        // response = await axios.get(`url_fetch_data_branch_finyear_id_wise/9_url_student_master_find_fun_with_param/${v_frm_no}/${under_comp_branch_id}/${ReduxGlobalData[0].fy}/0`,
        // { headers: { Authorization: `Bearer ${v_token}` }});
        // updatedata = await response.data.v_out_data;
        
        response = await axios.get(`url_fetch_data/9_url_student_master_find_fun_with_param/${v_frm_no}/${ReduxGlobalData[0].ag}/${under_comp_branch_id}/${ReduxGlobalData[0].fy}/${v_active_status}
        /${st_class}
        /${st_sec}
        /${stream}
        /${student_type}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;             
        setItems(await updatedata);

        // const returnVal = AuthenticationCheckDetailFun(await response.data.v_out_data.v_data, 'READ');
        
        // if (!returnVal){
        //     setItems([]);
        //     setOrigionalData([]);
        //     return;
        // };  
        
        // setItems(updatedata);

        // SetPaginationItems(updatedata.v_data, rowsLimit);
        // setOrigionalData(updatedata.v_data);        
    }

    const handleInputValueFun = async(name, value) => {       

        const v_token = Cookies.get('token');

        if (name === 'under_comp_branch_id') {            
             
            setunder_comp_branch_id(value);

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${value}/0/ST_CLASS`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            setst_classItems(updatedata);

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${value}/0/ST_SEC`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            setst_secItems(updatedata);
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${value}/0/ST_STREAM`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            setstreamItems(updatedata);

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${value}/0/ST_TYPE`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            setstudent_typeItems(updatedata);
            
            setItems([]);
            return;
        }else if(name === 'st_class') {
            setst_class(value);
            setItems([]);
        }else if(name === 'st_sec') {
            setst_sec(value);
            setItems([]);
        }else if(name === 'stream') {
            setstream(value);
            setItems([]);
        }else if(name === 'student_type') {
            setstudent_type(value);
            setItems([]);
        }else if (name === 'copy_under_comp_branch_id') {            
             
            copy_setunder_comp_branch_id(value);

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${value}/0/ST_CLASS`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            copy_setst_classItems(updatedata);

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${value}/0/ST_SEC`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            copy_setst_secItems(updatedata);
            
            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${value}/0/ST_STREAM`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            copy_setstreamItems(updatedata);

            response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${value}/0/ST_TYPE`,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            copy_setstudent_typeItems(updatedata);
            
            // setItems([]);
            return;
        }else if(name === 'copy_st_class') {
            copy_setst_class(value);
            // setItems([]);
            return;
        }else if(name === 'copy_st_sec') {
            copy_setst_sec(value);
            // setItems([]);
            return;
        }else if(name === 'copy_stream') {
            copy_setstream(value);
            // setItems([]);
            return;
        }else if(name === 'copy_student_type') {
            copy_setstudent_type(value);
            // setItems([]);
            return;
        }
    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        // to be used for show pop single or multi select

        const { name, value } = event.target;

        if (name === 'queryStClass') {
            setShowPopUpSt_Class(true);
            setShowPopUpSt_Sec(false);

            setConditionalCSS(true);

            return;
        } else if (name === 'queryStSec') {
            setShowPopUpSt_Sec(true);
            setShowPopUpSt_Class(false);

            setConditionalCSS(true);

            return;
        } else {
            setShowPopUpSt_Sec(false);
            setShowPopUpSt_Class(false);
            return;
        }

    };

    const setPopUpDDFalseFunction = (p_data) => {
        // console.log("Set False >> ", p_data);      

        if (p_data.selectedColumnName === "queryStClass") {
            setIDArraySt_Class(p_data.SelectedIDArray);
            setQueryStClass(p_data.SelectedIDArrayValue);
            setShowPopUpSt_Class(false);

            setConditionalCSS(false);

            handleInputValueFun("queryStClass", p_data.SelectedIDArrayValue);
        }

        if (p_data.selectedColumnName === "queryStSec") {
            setIDArraySt_Sec(p_data.SelectedIDArray);
            setQueryStSec(p_data.SelectedIDArrayValue);
            setShowPopUpSt_Sec(false);

            setConditionalCSS(false);

            handleInputValueFun("queryStSec", p_data.SelectedIDArrayValue);
        }

    }

    const handleEnter = (event) => {
        // console.log(event.key)

        if (event.key === 'Enter') {

            // console.log("In Enetr")

            // event.preventDefault();

            // tbodyref.current.focus();

            // inputRefs.current[4].focus();

        }

        // inputRefs.current[4].focus();
    }

    const handleFocus = (event) => {
        event.preventDefault();

        // const [ConditionalTableRowCSS, setConditionalTableRowCSS] = useState('table_thead_tr_css hover:bg-red-400');

        // setConditionalTableRowCSS("table_thead_tr_css hover:bg-gray-950")

        // const filteredData = items.filter((item) => item.id.includes(p_id));

        // console.log("Table Row Focus >> ", filteredData)        

        // setConditionalTableRowCSS("table_thead_tr_css focus:bg-red-950")
    }
 
    const handleButtonValue = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        if (value === 'BTN_TRANSFER' || value === 'DELETE' || value === 'CREATE_NEW_STUDENT') {
            createRecordFunction(value);
            return;
        } else if (value === 'BTN_BACK') {            
            navigate(`${v_backToNavigateName}`);
            return;
        } else if (value === 'BTN_FIND'){
            setBlankFields();
            ShowRecordFun();
            return;
        }
        
    }

    const createRecordFunction = (value) => {
        
        if (value != 'DELETE'){

            if(copy_under_comp_branch_id === undefined || copy_under_comp_branch_id.length === undefined || copy_under_comp_branch_id.length === 0){
                toast.info('Entry required for Transfer Under branch', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;  
            }        

            if(copy_st_class === undefined || copy_st_class.length === undefined || copy_st_class.length === 0){
                toast.info('Entry required for Transfer Student class', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            if(copy_st_sec === undefined || copy_st_sec.length === undefined || copy_st_sec.length === 0){
                toast.info('Entry required for Transfer Student section', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            if(copy_stream === undefined || copy_stream.length === undefined || copy_stream.length === 0){
                toast.info('Entry required for Transfer Stream', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            if(copy_student_type === undefined || copy_student_type.length === undefined || copy_student_type.length === 0){
                toast.info('Entry required for Transfer Student type', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }
        }

        if (IDArray.length === 0) {
            toast.error(whenNoRecordFoundMessage, {
                position: v_toast_position, autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar, closeOnClick: v_toast_closeOnClick, pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable, progress: v_toast_progress, theme: v_toast_theme,
            });
            return;
        }        

        if (value === 'DELETE'){            
            setTransType('DELETE');
        }
        else if (value === 'CREATE_NEW_STUDENT'){
            setTransType('CREATE_NEW_STUDENT');        
        }else{
            setTransType('UPDATE');
        }

        setv_urlSaveUpdateDelete('url_39_url_year_end_process_new_proc');

        setConditionalCSS(true);

        if (userAuthData.allow_new === 1) {
            setConfirmModelUpdateDelete(true);
        }
        
    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {

        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);

        if (p_data.ModelYesNoAnswer === 'YES') {
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async (p_last_access_ip_final, p_last_updel_notes_final) => {

        // const listFinalData = {
        //     id:0,
        //     ListData: IDArray,
        //     under_comp_branch_id: ReduxGlobalData[0].br,
        //     fin_year: ReduxGlobalData[0].fy,
        //     insupdel_type: 'UPDATE',
        //     from_fee_month :from_month_id,
        //     to_fee_month:to_month_id,
        //     fee_code_id,
        //     fee_amt,
        //     fee_add_reason:0,
        //     last_access_ip_final: p_last_access_ip_final,
        //     'last_access_by_final': ReduxGlobalData[0].id,
        //     last_updel_notes_final: v_last_updel_notes_final,
        // }

       

        const listFinalData = {
            ListData: IDArray,
            from_fin_year: ReduxGlobalData[0].fy,
            
            under_comp_branch_id,            
            st_class,
            st_sec,
            stream,
            student_type,

            copy_under_comp_branch_id,            
            copy_st_class,
            copy_st_sec,
            copy_stream,
            copy_student_type,

            insupdel_type: TransType,
            last_access_ip_final: p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final: p_last_updel_notes_final,
        }

        // console.log("shiv-2")

        const v_token = Cookies.get('token');

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });

        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }

    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }     

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then            
            
            if (updatedata.v_data.length === 0) {                
                toast.info(whenNoRecordFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }      

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then       

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            // setNewRecordIDFunction(updatedata.TabID); 

            // shiv

            // console.log("updatedata.transType >> ", updatedata.transType)            

            if (TransType === 'UPDATE'){                
                v_toast_msg = updatedMessage;
            }
            
            if (TransType === 'DELETE'){                
                v_toast_msg = deletedMessage;
            }

            if (TransType === 'CREATE_NEW_STUDENT'){                
                v_toast_msg = updatedMessage;
            }
            
            // if (updatedata.transType === 'UPDATE'){                
            //     v_toast_msg = updatedMessage;
            // }
            
            // if (updatedata.transType === 'DELETE'){                
            //     v_toast_msg = deletedMessage;
            // }

            ShowRecordFun();

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }    

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        handleInputValueFun(name, value);
    }

    const SetPaginationItems = (p_items, p_rowsLimit) => {
        // setCustomPagination(Array(Math.ceil(p_items?.length / parseInt(p_rowsLimit))).fill(null));
        // setTotalPage(Math.ceil(p_items?.length / parseInt(p_rowsLimit)));
        // setCurrentPage(0);
        setTotalRecordFound(p_items.length);
        setRowsLimit(parseInt(p_rowsLimit));
        setItems(p_items.slice(0, parseInt(p_rowsLimit)));

        // ResetCheckBoxFunction(items);      
    }

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        let tempListID = [], tempListValue = [];

        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, ischecked: checked } });
            setItems(checkedvalue);

            setIDArray([]);
            setIDArrayValue([]);

            if (checked) {
                for (let i = 0; i < items.length; i++) {
                    tempListID.push(items[i].id);
                    // tempListValue.push(items[i].st_class);
                }
            }
        }
        else {
            const checkedvalue = items.map((user) => user.id.toString() === name ? { ...user, ischecked: checked } : user);
            setItems(checkedvalue);

            tempListID = Object.assign(IDArray);
            tempListValue = Object.assign(IDArrayValue);

            // const filteredData = items.filter((item) => item.id.includes(name));

            const filteredData = items.find(item => {return item.id === name});          

            // console.log("In handleChangeCheckBox Click filteredData : ", filteredData);

            if (filteredData.ischecked) {
                const index = tempListID.indexOf(name);
                tempListID.splice(index, 1);
                tempListValue.splice(index, 1);
            } else {
                tempListID.push(filteredData.id);
                // tempListValue.push(filteredData[0]["st_class"]);
            }
        }

        SelectedValueConvertToStr(tempListID, tempListValue);
    }

    const onClickTableRowFun = (event, p_id, p_value) => {
        event.preventDefault();

        // console.log("When Click on SHOW_RECORD >> ", p_value, p_id);

        if (p_value === 'SHOW_RECORD') {

            const temp_data = {
                tempuserid: p_id,
                tempTransType: p_value
            }
            
            dispatch(setTempDataFun(temp_data));

            navigate(`${v_backToNavigateName}`);
        } else {
            ischeckedTick(p_id);
        }
    };

    const ischeckedTick = (p_id) => {

        let v_check_value = true;

        // console.log("In Table Row Click : ", p_id);

        // console.log("In Row Tick IDArray Before >> ", IDArray);

        // const filteredData = items.filter((item) => item.id.includes(p_id));

        const filteredData = items.find(item => {return item.id === p_id});        

        // console.log("In Table Row Click filteredData : ", filteredData.ischecked);

        if (filteredData.ischecked) {
            v_check_value = false;
            const index = IDArray.indexOf(p_id);
            IDArray.splice(index, 1);
            IDArrayValue.splice(index, 1);
        } else {
            v_check_value = true;
            IDArray.push(filteredData.id);
            // IDArrayValue.push(filteredData.st_class);
        }

        for (let i = 0; i < items.length; i++) {
            const checkedvalue = items.map((user) => user.id.toString() === p_id.toString() ? { ...user, ischecked: v_check_value } : user);
            setItems(checkedvalue);
        }

        SelectedValueConvertToStr(IDArray, IDArrayValue);
    }

    const SelectedValueConvertToStr = (p_tempIDList, p_tempValueList) => {

        // console.log("In Convert >> ", p_tempList.length, ' >> ', p_tempList);

        let tempIDArrayStr = '', tempIDArrayValueStr = '';

        for (let i = 0; i < p_tempIDList.length; i++) {
            if (i === 0) {
                tempIDArrayStr = p_tempIDList[i];
                tempIDArrayValueStr = p_tempValueList[i];
            } else {
                tempIDArrayStr = tempIDArrayStr + "," + p_tempIDList[i];
                tempIDArrayValueStr = tempIDArrayValueStr + "," + p_tempValueList[i];
            }
        }

        setIDArray(p_tempIDList);
        setIDArrayValue(p_tempValueList);
    }

    const addInputRef = (ref, index) => {
        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    const sortByColumn = (column) => {

        let tempData = [];

        if (FilterStatus) {
            tempData = filterItems;
        } else {
            tempData = origionalData;
        }

        let sortData = [];

        if (sortingColumn?.includes(column)) {
            sortData = tempData.slice().sort((a, b) => b[column].toString().trim().localeCompare(a[column].toString().trim()));
            setItems(sortData);
            setSortingColumn([]);
            setAscWise(true);
        } else {
            sortData = tempData.slice().sort((a, b) => a[column].toString().trim().localeCompare(b[column].toString().trim()));
            setItems(sortData);
            setSortingColumn([`${column}`]);
            setAscWise(false);
        }

        // setActiveColumn([`${column}`]);
        // setCurrentPage(0);
        ResetCheckBoxFunction(sortData);
    }

    const ResetCheckBoxFunction = (p_sortData) => {
        const newItems = p_sortData.map(obj => {

            // if (obj.id === '490') {
            //     return {...obj, ischecked: true};
            // }            

            if (IDArray.find((element) => element === obj.id) != undefined) {
                return { ...obj, ischecked: true };
            }
            return obj;
        });

        setItems(newItems);

        // console.log(newState);
    }   

    const CheckFilterOptions = (event) => {
        event.preventDefault();

        // -------------------------------------------------------------------------------------------------

        // Get one column 

        // let extractedValue = items.map(function(item) {return item["st_name"]});
        // console.log(extractedValue)

        // -------------------------------------------------------------------------------------------------

        // const filteredData = origionalData.filter((item) => item.st_class.includes(['K.G.']));

        // const filteredData = origionalData.find(obj => {return obj.st_class === 'K.G.'});

        // const filtered2 = employees.filter(employee => {
        //     return employee.country === 'Canada' && employee.id === 3;
        // });

        // --------------------------------------------------------------------------------------------------

        // var  people =[
        //     { "id": 1, "name": "abc", "gender": "m","age": "15" },
        //     { "id": 2, "name": "a", "gender": "m", "age": "25"  },
        //     { "id": 3,"name": "efg", "gender": "f","age": "5" },
        //     { "id": 4,"name": "hjk","gender": "m","age": "35" },
        //     {  "id": 5, "name": "ikly","gender": "m","age": "41" },
        //     { "id": 6, "name": "ert", "gender": "f", "age": "30" },
        //     { "id": 7, "name": "qwe", "gender": "f", "age": "31" },
        //     { "id":8, "name": "bdd",  "gender": "m", "age": " 8" }
        //   ];
        //   var id_filter = [1,4,5,8];
        //   var filtered = people.filter(function(item) {
        //       return id_filter.indexOf(item.id) !== -1 && item.gender==='m';
        //   });
        //   console.log(filtered);

        // --------------------------------------------------------------------------------------------------

        // Filter data using multiple condition (1 method - using include)

        // const selectedClass = ['K.G.', 'P.Nur.', 'I'];
        // const selectedSec = ['A', 'B'];      

        // var filtered = origionalData.filter((item) => selectedClass.includes(item.st_class) && selectedSec.includes(item.st_sec));

        // console.log(filtered);

        // --------------------------------------------------------------------------------------------------

        // Filter data using multiple condition (2 method - using filter)

        // Filter data using multiple condition

        // const selectedClass = ['K.G.', 'P.Nur.'];
        // const selectedSec = ['A', 'B'];      

        // var filtered = origionalData.filter(function(item) {
        //     return selectedClass.indexOf(item.st_class) !== -1 && selectedSec.indexOf(item.st_sec) !== -1;
        // });
        // console.log(filtered);

        // --------------------------------------------------------------------------------------------------      

    }

    const CheckFun = () => {

        // console.log("in Check Function");

        // ResetCheckBoxFunction(items);        

        // const searchdata =origionalData.filter((item) => queryStClass.includes(item.st_class));

        // setItems(searchdata)

    };    

    const goCheckDataFunction = (event) => {
        event.preventDefault();

        // router.push('/')

        // console.log("ReduxTempData[0].tempuserid >> ", ReduxTempData[0].tempuserid);

        // console.log("ReduxDDBranchBoardData >> ", ReduxDDBranchBoardData);

        // navigate("/");


        // const v_token = Cookies.get('token');

        // console.log("From Cookies is >> ", Cookies.get('token'));



        // console.log("userAuthData Open >> ", userAuthData.allow_open);
        // console.log("userAuthData New >> ", userAuthData.allow_new);
        // console.log("userAuthData Read >> ", userAuthData.allow_read);
        // console.log("userAuthData Update >> ", userAuthData.allow_update);
        // console.log("userAuthData Delete >> ", userAuthData.allow_delete);        

    }

    return (

// <form className="border-none opacity-75 pointer-events-none" autoComplete="off" >
<>

{
    ShowConfirmModelUpdateDelete ?
        <div className="centerWindow_css w-[32%]">
            <ConfirmModelUpdateDelete transType={TransType} setModelSaveUpdateDeleteFalseFunction={setModelSaveUpdateDeleteFalseFunction} />
        </div>
        : null
}


{
    ShowPopUpSt_Class ?
        <div className="Show_SelectRecordPopUpSingleColumn_css">
            <SelectRecordPopUpSingleColumn setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                items={st_classItems}
                columnName='queryStClass'
                PropsIDArray={IDArraySt_Class}
                PropsIDArrayValueStr={queryStClass} />
        </div>
        : null
}

{
    ShowPopUpSt_Sec ?
        // <div className="w-[25%] h-[45%] ml-[38%] mt-[10%] border bg-yellow-100 absolute flex flex-col items-center justify-center">

        <div className="Show_SelectRecordPopUpSingleColumn_css">
            <SelectRecordPopUpSingleColumn setPopUpDDFalseFunction={setPopUpDDFalseFunction}
                items={st_secItems}
                columnName='queryStSec'
                PropsIDArray={IDArraySt_Sec}
                PropsIDArrayValueStr={queryStSec} />
        </div>
        : null
}

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">

    <div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

    {/* <div className="top_heading_all_full_page_css">{IDArray}</div>
        <div className="top_heading_all_full_page_css">{IDArrayStr}</div>
        <div className="top_heading_all_full_page_css">{IDArrayValue}</div>
        <div className="top_heading_all_full_page_css">{IDArrayValueStr}</div> */}

    {/* <table className="table_main_css" onMouseDown={() => CheckFun()}> */}

<table className="table_main_css">
<thead className="table_thead_css">
    <tr className="table_thead_tr_css">

        <th className="table_th_td_css w-[4%]">From</th>

        <th className="table_th_td_css w-[32%]">
            {/* <label className="label_inner_page_css required_css">Under Branch</label> */}
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="under_comp_branch_id"
                id="under_comp_branch_id"
                value={under_comp_branch_id}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                 <option></option>
                {
                    under_comp_branchItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </th>

        <th className="table_th_td_css w-[18%]">
            {/* <label className="label_inner_page_css required_css">Class</label> */}
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="st_class"
                id="st_class"
                value={st_class}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    st_classItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </th>

        <th className="table_th_td_css w-[5%]">
            {/* <label className="label_inner_page_css required_css">Section</label> */}
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="st_sec"
                id="st_sec"
                value={st_sec}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    st_secItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </th>

        <th className="table_th_td_css w-[13%]">
            {/* <label className="label_inner_page_css required_css">Stream</label> */}
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="stream"
                id="stream"
                value={stream}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    streamItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </th>        

        <th className="table_th_td_css w-[18%]">
            {/* <label className="label_inner_page_css required_css">Student Type</label> */}
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="student_type"
                id="student_type"
                value={student_type}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    student_typeItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </th>

        <th className="table_th_td_css w-[10%]">
            <button className="btn_back_css w-[90%]"
                value = 'BTN_FIND'
                onClick={handleButtonValue}>
                Show
            </button>
        </th>

    </tr>
</thead>

<thead className="table_thead_css">
    <tr className="table_thead_tr_css">

        <th className="table_th_td_css w-[4%]">To</th>

        <th className="table_th_td_css w-[32%] text-left">
            {/* <label className="label_inner_page_css required_css">To Fin./Year</label> */}
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="copy_under_comp_branch_id"
                id="copy_under_comp_branch_id"
                value={copy_under_comp_branch_id}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    under_comp_branchItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </th>        
                
        <th className="table_th_td_css w-[18%] text-left">
            {/* <label className="label_inner_page_css required_css">To Class</label> */}
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="copy_st_class"
                id="copy_st_class"
                value={copy_st_class}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    copy_st_classItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </th>

        <th className="table_th_td_css w-[5%] text-left">
            {/* <label className="label_inner_page_css required_css">To Section</label> */}
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="copy_st_sec"
                id="copy_st_sec"
                value={copy_st_sec}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    copy_st_secItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </th>

        <th className="table_th_td_css w-[13%] text-left">
            {/* <label className="label_inner_page_css required_css">To Stream</label> */}
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="copy_stream"
                id="copy_stream"
                value={copy_stream}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    copy_streamItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </th>       

        <th className="table_th_td_css w-[18%] text-left">
            {/* <label className="label_inner_page_css required_css">To Student Type</label> */}
            <select className="input_inner_page_css"
                ref={(ref) => addInputRef(ref, 0)}
                // autoFocus         
                name="copy_student_type"
                id="copy_student_type"
                value={copy_student_type}
                onChange={handleInputValue}
                onFocus={handleInputFocus}
            >
                <option></option>
                {
                    copy_student_typeItems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select> 
        </th>

        <th className="table_th_td_css w-[10%]">
            <button className="btn_back_css w-[90%]"
                value = 'BTN_TRANSFER'
                onClick={handleButtonValue}>
                Transfer
            </button>
        </th>

    </tr>
</thead>

<thead className="table_thead_css">
    <tr className="table_thead_tr_css">

        <th className="table_th_td_css text-left w-[4%]"></th>                

        <th className="table_th_td_css w-[4%]">
            <input className="table_check_inner_page_css"
                type="checkbox" name="allselect" checked={!items.some((user) => user?.ischecked !== true)}
                onChange={handleChangeCheckBox}
                onFocus={handleInputFocus}
            />
        </th>

        {/* <th className="table_th_td_css text-left w-[5%]"></th> */}

        <th className="table_th_td_css text-center w-[6%]">Adm.No.</th>

        <th className="table_th_td_css text-left w-[22%]" onClick={() => sortByColumn("st_name")}>
            {
                ascWise ? <FontAwesomeIcon className="fai_css" icon={faSortAlphaDownAlt} />
                    :
                    <FontAwesomeIcon className="fai_css" icon={faSortAlphaDown} />
            }
            Name
        </th>

        <th className="table_th_td_css text-left w-[18%]" onClick={() => sortByColumn("fat_name")}>
            {
                ascWise ? <FontAwesomeIcon className="fai_css" icon={faSortAlphaDownAlt} />
                    :
                    <FontAwesomeIcon className="fai_css" icon={faSortAlphaDown} />
            }
            Father Name
        </th>

        <th className="table_th_td_css text-left w-[5%]" onClick={() => sortByColumn("st_class")}>Class</th>

        <th className="table_th_td_css text-center w-[13%]" onClick={() => sortByColumn("st_sec")}>
            {
                ascWise ? <FontAwesomeIcon className="fai_css" icon={faSortAlphaDownAlt} />
                    :
                    <FontAwesomeIcon className="fai_css" icon={faSortAlphaDown} />
            }
            Sec.
        </th>

        <th className="table_th_td_css text-left w-[18%]" onClick={() => sortByColumn("st_stream")}>
            {
                ascWise ? <FontAwesomeIcon className="fai_css" icon={faSortAlphaDownAlt} />
                    :
                    <FontAwesomeIcon className="fai_css" icon={faSortAlphaDown} />
            }
            Stream
        </th>

        <th className="table_th_td_css text-left w-[10%]">Student Type</th>

    </tr>
</thead>        

<tbody className="table_tbody_css h-[55vh]">
{
    items?.map((item, index) => (
        <tr className="table_thead_tr_css table_tbody_hover_css" key={item.id} >                    

            <td className="table_th_td_css text-left w-[4%]" onClick={(e) => onClickTableRowFun(e, item.id, '', item.isChecked)}>
                {index + 1}
            </td>

            <td className="table_th_td_css w-[4%]">
                <input className="table_check_inner_page_css"
                    // ref={(ref) => addInputRef(ref, index + 4)}
                    ref={(ref) => addInputRef(ref, index + 4)}
                    type="checkbox" name={item.id}
                    checked={item?.ischecked || false}
                    onChange={handleChangeCheckBox}
                // onFocus={handleFocus}                            
                />
            </td>
           
            <td className="table_th_td_css text-center w-[6%]" onClick={(e) => onClickTableRowFun(e, item.id, '', item.isChecked)}>{item.adm_no}</td>

            <td className="table_th_td_css text-left w-[22%]" onClick={(e) => onClickTableRowFun(e, item.id, '', item.isChecked)}>{item.st_name}</td>
            <td className="table_th_td_css text-left w-[18%]" onClick={(e) => onClickTableRowFun(e, item.id, '', item.isChecked)}>{item.fat_name}</td>
            <td className="table_th_td_css text-left w-[5%]" onClick={(e) => onClickTableRowFun(e, item.id, '', item.isChecked)}>{item.st_class}</td>
            <td className="table_th_td_css text-center w-[13%]" onClick={(e) => onClickTableRowFun(e, item.id, '', item.isChecked)}>{item.st_sec}</td>
            <td className="table_th_td_css text-left w-[18%]" onClick={(e) => onClickTableRowFun(e, item.id, '', item.isChecked)}>{item.st_stream}</td>
            <td className="table_th_td_css text-left w-[10%]" onClick={(e) => onClickTableRowFun(e, item.id, '', item.isChecked)}>{item.student_type_name}</td>

        </tr>
    ))
}
</tbody>
</table>    

<nav className="btn_nav_inner_css">
    <div className="btn_div_main_css">
        <div className="btn_div_inner_css">

            <div>
                <span className="div_table_record_show_1_css">Record found </span>
                <span className="div_table_record_show_2_css">{items.length}</span>
            </div>

            <div>                
                {userAuthData.allow_delete === 1 && (
                    <button className="btn_delete_css"
                        value = 'DELETE'
                        // disabled={disableDelete}
                        onClick={handleButtonValue}>
                            Back to Previous Year
                    </button>
                )}                
            </div>

            <div>                
                {userAuthData.allow_delete === 1 && (
                    <button className="btn_delete_css"
                        value = 'CREATE_NEW_STUDENT'
                        // disabled={disableDelete}
                        onClick={handleButtonValue}>
                        Transfer (With Generate New Student-ID)
                    </button>
                )}                
            </div>            

            <div>
                <button className="btn_back_css"
                    value='BTN_BACK'
                    onClick={handleButtonValue}>
                    Back
                </button>
            </div>

            {/* <div>
                <button className="btn_back_css"
                    onClick={goCheckDataFunction}>
                    Check
                </button>
            </div> */}
            
        </div>
    </div>
</nav>

</form>
</>
    );
};

export default StudentYearEndProcess;
