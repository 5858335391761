import React, { useState } from "react";

const Paramtable = (props) => {

    return (
        <div className="w-full py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color">

            <select className="w-full" name = {props.columnName} value= {props.columnNameValue} onChange={(e) => props.fun1(e, props.p_val)}>
            <option></option>
            {   
                props.paramItems.length > 0 && props.paramItems.map((item, i) => ( 
                    <option key={item.id} value={item.id}>{item.sel_desc}</option>                
                ))
            }

            </select>

        </div >
    );
};

export default Paramtable;


