// Table name : Daily Fee Collection Report

// pending work

import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from "react-toastify";
import { CSVLink} from 'react-csv';

import CRUDContext from '../components/CRUDContext';

import ModelFormYesNo from '../components/ModelFormYesNo';

// import Layout from '../components/Layout';
// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { addTempList, emptyCart } from '../components/redux/actions';

const AdmOkFromExcel = () => {  

    // const router = useRouter();

    const dispatch = useDispatch();    

    const ReduxData = useSelector((state) => state);

    const navigate = useNavigate();

    const conponentPDF = useRef();

    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);

    const [apiName, setapiName] = useState('')
    
    // const dispatch = useDispatch();
    // const ReduxData = useSelector((state) => state);    
    
    const [items, setItems] = useState([]);
    
    const [column, setcolumn] = useState([]);

    const [from_month, setfrom_month] = useState('')
    const [to_month, setto_month] = useState('')    
    
    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0);
    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]); 
    
    const {
        // pk_id,
        // loading,                
        error,  
        created,
        updated,
        deleted,    
        // createRecord,       
        // setPkID,               
        setCreated,
        setUpdated,                                   
        setDeleted,
        clearErrors,

        deletedMessage, 
        createdMessage, 
        updatedMessage,    
        // whenNulldeleteMessage,
        whenNoRecordFoundMessage,
    } = useContext(CRUDContext);
    
    useEffect(() => {
        if (error) {
            toast.error(error);
            clearErrors();
        }

        if (created) {
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
        }

        if (updated) {
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
        }
    }, [error, created, updated, deleted]); 
    

    useEffect(() =>{       

        let response = []
        let updatedata = []

        const getTableRecordFunctionBranch = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_comp_branchItems(updatedata);
        }
        getTableRecordFunctionBranch('url_comp_branch_view_drop_down');

    }, []);


    const PrintPDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "Userdata",
        // onAfterPrint: () => alert("Data saved in PDF")
    });

    const handleUnderBranchOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        setunder_comp_branch_id(getId);
        
        setItems([]);
    }    

    const goPrePageFunction = (event) => {
        event.preventDefault();        
        const v_form_name = localStorage.getItem('fm');
        // navigate(`/${v_form_name}`);               

        navigate('/Sdt');
    }

    const ConfirmAdmissionFunction = (event) => {
        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.error("Entry required for under branch !");
            clearErrors();
            return;
        }
       
        if (items.length === 0 || items === undefined){
            toast.info("We have not found records. Please click on show report button to continue...")
            clearErrors();
            return;
        }        

        const data = {
            under_comp_branch_id,               
            // toitems: Array(items),
            // items,
        };

        // console.log("data is : ", data)

        dispatch(emptyCart());
        dispatch(addTempList(data));

        setapiName('url_insert_student_excel_new_proc');

        setModelModelFormYesNo(true)
    }

    const GenerateReport = (event) => {
        event.preventDefault();      
        
        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.error("Entry Required For Under Branch !");
            clearErrors();
            return;
        }        
        
        const getReport = async (var_link) => {
            
            // console.log("Data start - 1")

            // console.log("Selected branch is : ", under_comp_branch_id)
            
            const response = await axios.get(`${var_link}/${under_comp_branch_id}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            // response = await axios.get(`${var_link}/`,
            //     { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });    

            // const response = await axios.get(`${var_link}/${localStorage.getItem('br')}/`,
            //     { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            // console.log("Data start - 2")

            const updatedata = await response.data.v_out_data;   
            
            // console.log("Data updatedata : ", updatedata)

            if (updatedata.length === 0){
                toast.info("Record not found !!!")
                clearErrors();
                return;
            }

            setItems(updatedata);
                    
            const column1 = Object.keys(updatedata[0]);

            setcolumn(column1)
        }
        getReport('url_import_excel_adm_view_fun');
    }    

    // get table heading data
    const ThData =()=>{
    
        return column.map((data)=>{
            // return <th key={data}>{data}</th>

            return <th className = "py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
            key={data}>{data}</th>
        })
    }

    // get table row data
    const tdData =() =>{

        return items.map((data)=>{
        return(
            <tr>
                {
                column.map((v)=>{
                    // return <td>{data[v]}</td>
                    return <td className = "py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName">
                        {data[v]}</td>
                })
                }
            </tr>
        )
        })
    }
   

return (
<>
    {/* <div className="mt-32 grid justify-center cols-4 gap-4"> */}

    <div className="pt-PaddingReportTopAfterMenu grid grid-cols-7 gap-2">

        <div className="col-span-2">
            <label className="max-w-full text-my-font-color font-myFontWeight">Under Branch</label>        
            <select className="w-[100%] col-span-2 border-my-borderWidth border-my-TableHeadingBorderColor py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name focus:bg-my-when-focus-bg-color"
                name="under_comp_branch_id" value={under_comp_branch_id} onChange={(e) => handleUnderBranchOption(e)}
                >
                <option></option>
                {
                    under_comp_branchItems.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
            </select>
        </div>        

        <button className="h-DropDownHeight mt-4 bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
            onClick={GenerateReport}>   
            Show Report
        </button>    

        <button className="h-DropDownHeight mt-4 bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
            onClick={PrintPDF}>   
            Print
        </button>

        <CSVLink className="h-DropDownHeight mt-4 text-center bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BackBtnFontColor text-BtnFontSize font-InputFontName rounded-none"
            data={items} filename="StudentExcelData">Export</CSVLink>

        <button className="h-DropDownHeight mt-4 bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BtnFontSize text-BackBtnFontColor font-BtnFontName rounded-none"
            onClick={ConfirmAdmissionFunction}
        >Confirm Admission</button>         
        
        <button className="h-DropDownHeight mt-4 bg-BackBtnBGColor hover:bg-BackBtnHoverColor text-BtnFontSize text-BackBtnFontColor font-BtnFontName rounded-none"
            onClick={goPrePageFunction}
        >Back</button>

        <ModelFormYesNo table_name = {'STANDARD'} api_name = {'url_insert_student_excel_new_proc'} isVisible = {ShowModelFormYesNo} onClose = {()=>setModelModelFormYesNo(false)} />


    </div>    

    <div ref={conponentPDF}>  
        
        <table className="w-[100%] mt-2 py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName">
             

         {/* <table className="table"> */}
            
            {/* <thead className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"> */}
            <thead>
                <tr>
                    {ThData()}
                </tr>
            </thead>
            
            <tbody>
                {tdData()}
            </tbody>

        </table>

    </div>
</>
);};

export default AdmOkFromExcel;
