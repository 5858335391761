// Table name : ac_group

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import CRUDContext from '../components/CRUDContext';
// import Layout from './Layout';
import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';

// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";

const Agview = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state);
    const [ShowModelFormDeleteUpdate, setModelFormDeleteUpdate] = useState(false);
    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);
    const [updateDelTag, setUpdateDelTag] = useState('');

    const [items, setItems] = useState([]);
    const [ExcelItems, setExcelItems] = useState([]);
    const [filterdata, setFilterdata] = useState([]);
    const [queryCode, setQueryCode] = useState('');
    const [queryName, setQueryName] = useState('');    

    // const ExcelHeaders = [
    //     { label: "Code", key: "l_code" },
    //     { label: "Name", key: "l_name" },
    //     { label: "Notes", key: "notes" }
    // ];

    let list1 = [];
    let checkedinputvalueItems = '';

    // const router = useRouter()

    const navigate = useNavigate();

    // const getAuthroziedUser = async () => {  
    //     try{
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //               { headers: { Authorization: `Bearer ${token}` } });            
    
    //         if (response.status === 401 || response.status === 403){
    //             localStorage.clear();
    //             // router.push('/Login')
    //             navigate("/Login");
    //         };

    //     }catch(error){
    //         localStorage.clear();
    //         // router.push('/Login')
    //         navigate("/Login");
    //     }
    // }    

    // useEffect( () => {        
    //     getAuthroziedUser();    
    // }, []);

    const {
        pk_id,
        loading,
        error,
        created,
        updated,
        deleted,
        createRecord,
        updateRecord,
        deleteRecord,
        setPkID,
        setCreated,
        setUpdated,
        setDeleted,
        clearErrors,

        deletedMessage,
        createdMessage,
        updatedMessage,
        whenNulldeleteMessage,
        whenNullViewRecordMessage,
        whenMultipleViewRecordMessage,
    } = useContext(CRUDContext);
    
    useEffect(() => {        
        if (error) {
            toast.error(error);
            clearErrors();
            return;
        }

        if (deleted) {
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
            return;
        }

    }, [error, deleted]);

    useEffect(() => {
        const getTableRecordFunction = async (var_link) => {
            const response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            const updatedata = await response.data.v_out_data

            // console.log("In page view : ", updatedata)

            setItems(updatedata);
            setFilterdata(list1);
            generateExcelData(list1);
        }

        getTableRecordFunction('url_ac_group_page_view');

    }, [deleted])    

    function generateExcelData(data) {
        const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
        setExcelItems(checkedinputvalue);
    }

    // handleChangeCheckBox for checkbox

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;
        if (name === "allselect") {
            const checkedvalue = items.map((user) => { return { ...user, isChecked: checked } });
            setItems(checkedvalue);
        }
        else {
            const checkedvalue = items.map((user) => user.id.toString() === name ? { ...user, isChecked: checked } : user);
            setItems(checkedvalue);
        }
    }

    const handleDeleteCheckBox = (event) => {
        event.preventDefault();

        // console.log("checkedinputvalue is : ", checkedinputvalue)        

        const checkedinputvalue = [];
        for (let i = 0; i < items.length; i++) {
            if (items[i].isChecked === true) {
                checkedinputvalue.push(parseInt(items[i].id));
            }
        }

        if (checkedinputvalue.length === 0) {
            toast.error(whenNulldeleteMessage);
            clearErrors();
            return;
        }

        const data = {
            id: checkedinputvalue,
            last_access_ip: '', 
            // ipAddess,
            last_access_by: userData.userData[0].id,
        };

        setQueryCode('');
        setQueryName('');

        setUpdateDelTag('DELETE')

        dispatch(addTempList(data));
        setModelFormDeleteUpdate(true);
    }

    function showRecordFunction(pk_id_val) {
        setPkID(pk_id_val);
        // router.push('/ag')
        navigate("/ag");

    }

    const goPrePageFunction = (event) => {
        event.preventDefault();
        setPkID(0);
        // router.push('/ag')
        navigate("/ag");
    }

    const handleSearchCode = (event) => {
        event.preventDefault();
        const getSearch = event.target.value;
        let searchdata = filterdata;

        searchdata = searchdata.filter((item) => item.l_code.toLowerCase().includes(getSearch.toLowerCase()));
        searchdata = searchdata.filter((item) => item.l_name.toLowerCase().includes(queryName.toLowerCase()));

        setQueryCode(getSearch);
        setItems(searchdata);
        generateExcelData(searchdata);
    }

    const handleSearchName = (event) => {
        event.preventDefault();
        const getSearch = event.target.value;
        let searchdata = filterdata;


        searchdata = searchdata.filter((item) => item.l_name.toLowerCase().includes(getSearch.toLowerCase()));
        searchdata = searchdata.filter((item) => item.l_code.toLowerCase().includes(queryCode.toLowerCase()));

        setQueryName(getSearch);
        setItems(searchdata);
        generateExcelData(searchdata);
    }

    return (

        <>

            <div className="pt-PaddingTopAfterMenu max-w-full text-my-main-heading-fontSize bg-my-main-heading-color text-my-main-heading-font-color text-left">Account Group</div>

            <div className="border-my-borderWidth border-my-TableHeadingBorderColor overflow-y-scroll">

                <div className="grid mm:grid-cols-1 md:grid-cols-12 lg:grid-cols-12">

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor                        
                        col-span-3">

                        <input className="py-my-padding-py px-my-padding-px  text-my-fontSize text-TableHeadingFontColor font-my-font-name font-myFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor w-[5%]"
                            type="checkbox" name="allselect" checked={!items.some((user) => user?.isChecked !== true)}
                            onChange={handleChangeCheckBox}
                        />

                        <label className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor                                
                                col-span-3 w-[95%]">
                            Group Name
                        </label>
                    </div>

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-4">
                        Under-Group
                    </div>

                    <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-TableHeadingFontColor font-my-font-name font-myTableHeadingFontWeight border-my-tableBorderWidth border-my-TableHeadingBorderColor bg-my-TableHeadingBGColor
                        col-span-5">
                        Notes
                    </div>

                    {/* --------------------------------------------------------------------------- */}
                    {/* Start of Search */}

                    {/* <div className="col-span-3">
                        <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                w-[6%]"
                            disabled
                        />

                        <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-3 w-[94%]"
                            type="text"
                            name="group_name"
                            value={queryCode}
                            placeholder="Search Group..."
                            onChange={(e) => handleSearchCode(e)}
                            autoComplete="off"
                        />
                    </div>

                    <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                        col-span-4"
                        type="text"
                        name="under_group_name"
                        value={queryName}
                        placeholder="Search Under Group..."
                        onChange={(e) => handleSearchName(e)}
                        autoComplete="off"
                    /> */}

                    {/* --------------------------------------------------------------------------- */}
                    {/* End of Search */}

                </div>
            </div>

            <div className="py-my-padding-py px-my-padding-px border-my-borderWidth border-my-border-color h-myTableHeight overflow-y-scroll">
                {
                    items?.map((item) => (

                        <div className="grid mm:grid-cols-1 md:grid-cols-12 lg:grid-cols-12" key={item.id}>

                            <div className="col-span-3">
                                <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                    w-[6%]"
                                    type="checkbox" name={item.id} checked={item?.isChecked || false}
                                    onChange={handleChangeCheckBox}
                                />

                                <input className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                    col-span-3 w-[94%]"
                                    type="text"
                                    name="group_name"
                                    value={item.group_name}
                                    disabled
                                />
                            </div>

                            <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-4">
                                {item.under_group_name}
                            </div>

                            <div className="py-my-padding-py px-my-padding-px text-my-fontSize text-my-font-color font-my-font-name border-my-tableBorderWidth border-my-border-color focus:bg-my-when-focus-bg-color
                                col-span-4">
                                {item.notes}
                            </div>

                            <button className="bg-AddRowButtonBGColor hover:bg-AddRowButtonHoverColor text-my-fontSize text-AddRowButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                                onClick={() => showRecordFunction(item.id)}
                            >
                                View
                            </button>

                        </div>
                    ))
                }
            </div>

            <div className="py-my-padding-py px-my-padding-px overflow-y-scroll">
                <div className="grid mm:grid-cols-1 md:grid-cols-12 lg:grid-cols-10 gap-4">

                    <div></div><div></div><div></div>

                    <div>
                        Records: {items.length}
                    </div>

                    {/* <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                        onClick={handleDeleteCheckBox}>
                        Delete
                    </button> */}

                    <button className="bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                        onClick={goPrePageFunction}>
                        Back
                    </button>

                </div>
            </div>

            <ModelFormDeleteUpdate api_type={updateDelTag} api_name={'userGroup_del_multiple'} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)} />

        </>
    );
};

export default Agview;
