// import data from excel sheet
// using new css

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import ModelFormDeleteUpdate from '../components/ModelFormDeleteUpdate';
import ModelFormYesNo from '../components/ModelFormYesNo';
import { addTempList, emptyCart } from '../components/redux/actions';
import CRUDContext from '../components/CRUDContext';

// import Layout from './Layout';
// import { useRouter } from 'next/router';

import { useNavigate } from "react-router-dom";
import StudentExImport from './StudentExImport';

import { CSVLink} from 'react-csv';

const Sdt = () => {    
    const dispatch = useDispatch();    

    const ReduxData = useSelector((state) => state);

    const [ShowModelFormYesNo, setModelModelFormYesNo] = useState(false);   
    
    // const router = useRouter(); 

    const navigate = useNavigate();

    const [tabID, setTabID] = useState(0);
    const [items, setItems] = useState([]);    
    const [defaultdata, setdefaultdata]= useState([]);

    const [under_comp_branch_id, setunder_comp_branch_id] = useState(0);
    const [under_comp_branchItems, setunder_comp_branchItems] = useState([]);    

    const [apiName, setapiName] = useState('')
    
    const [board, setboard] = useState(0)
    const [student_type, setstudent_type] = useState(0)
    const [medium, setmedium] = useState(0)
    const [stream, setstream] = useState(0)
    const [fee_categ, setfee_categ] = useState(0)
    const [pay_term, setpay_term] = useState(0)
    const [st_class, setst_class] = useState(0)
    const [st_sec, setst_sec] = useState(0)    
    const [st_newold, setst_newold] = useState(0)   
    const [st_fy, setst_fy] = useState(0)    
 

    const [show_in_list, setShow_in_list] = useState(1)    
    const [boardItems, setboardItems] = useState([]);
    const [student_typeItems, setstudent_typeItems] = useState([]);
    const [mediumItems, setmediumItems] = useState([]);
    const [streamItems, setstreamItems] = useState([]);
    const [fee_categItems, setfee_categItems] = useState([]);
    const [pay_termItems, setpay_termItems] = useState([]);
    const [st_classItems, setst_classItems] = useState([]);
    const [st_classsecItems, setst_classsecItems] = useState([]);    
    const [st_newoldItems, setst_newoldItems] = useState([]); 
    const [st_fyItems, setsetst_fyItems] = useState([]);
    
    const [pay_fee_monthItems, setfee_monthItems] = useState([]);
    const [fee_codeItems, setfee_codeItems] = useState([]);
    const [app_forItems, setapp_forItems] = useState([]);
  
    let list1 = [];
    
    const {
        pk_id,
        loading,                
        error,  
        created,
        updated,
        deleted,    
        createRecord,       
        setPkID,               
        setCreated,
        setUpdated,                                   
        setDeleted,
        clearErrors,

        deletedMessage, 
        createdMessage, 
        updatedMessage,    
        whenNulldeleteMessage,
        whenNoRecordFoundMessage,
    } = useContext(CRUDContext);    

    // ////////////////////////////

    // const getAuthroziedUser = async () => {  
    //     try{
    //         const token = localStorage.getItem('token');

    //         const response = await axios.get(`verifyTokenAPI/`,
    //               { headers: { Authorization: `Bearer ${token}` } });            
    
    //         if (response.status === 401 || response.status === 403){
    //             localStorage.clear();
    //             navigate('/Login')
    //         };

    //     }catch(error){
    //         localStorage.clear();
    //         navigate('/Login')
    //     }
    // }    

    // useEffect( () => {        
    //     getAuthroziedUser();    
    // }, []);

    // ////////////////////////////


    useEffect(() => { 
        if (error){
          toast.error(error);
          clearErrors();
        }

        if (created){
            setCreated(false);
            toast.success(createdMessage)
            clearErrors();
        }

        if (updated){
            setUpdated(false);
            toast.success(updatedMessage)
            clearErrors();
        }

        if (deleted){
            setDeleted(false);
            toast.success(deletedMessage)
            clearErrors();
        }
    }, [error, created, updated, deleted]);    

    useEffect(() =>{       

        let response = []
        let updatedata = []

        const getTableRecordFunctionBranch = async (var_link) => {
            response = await axios.get(`${var_link}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data
            setunder_comp_branchItems(updatedata);
        }
        getTableRecordFunctionBranch('url_comp_branch_view_drop_down');
        
        const getParamTableRecordFunctionBranch = async (var_link) => {    
            response = await axios.get(`${var_link}/BOARD`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setboardItems(updatedata);

            response = await axios.get(`${var_link}/ST_TYPE`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setstudent_typeItems(updatedata);

            response = await axios.get(`${var_link}/MEDIUM`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setmediumItems(updatedata);

            
            
            // setpay_termItems(updatedata);
            // setapp_forItems(updatedata);
            // setst_classItems(updatedata);

            response = await axios.get(`${var_link}/ST_STREAM`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setstreamItems(updatedata);

            response = await axios.get(`${var_link}/FEE_CATEG`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setfee_categItems(updatedata);

            response = await axios.get(`${var_link}/FEE_PAY_TERM`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setpay_termItems(updatedata);   
            
            response = await axios.get(`${var_link}/ST_CLASS`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setst_classItems(updatedata);  
            
            response = await axios.get(`${var_link}/ST_SEC`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setst_classsecItems(updatedata);

            response = await axios.get(`${var_link}/APP_FOR`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setst_newoldItems(updatedata);

            response = await axios.get(`${var_link}/FY`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            updatedata = await response.data.v_out_data
            setsetst_fyItems(updatedata);
        }
        getParamTableRecordFunctionBranch('url_param_view_drop_down');

    }, [])   
    
    const createRecordFunction = (event) => {
        event.preventDefault();

        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            toast.error("Entry required for under branch !");
            clearErrors();
            return;
        }

        if(board === undefined || board.length === undefined || board.length === 0){
            toast.error("Entry required for board !");
            clearErrors();
            return;
        }


        if(fee_categ === undefined || fee_categ.length === undefined || fee_categ.length === 0){
            toast.error("Entry required for fee category !");
            clearErrors();
            return;
        }


        if(pay_term === undefined || pay_term.length === undefined || pay_term.length === 0){
            toast.error("Entry required for pay term !");
            clearErrors();
            return;
        }

        if(student_type === undefined || student_type.length === undefined || student_type.length === 0){
            toast.error("Entry required for student type !");
            clearErrors();
            return;
        }

        if(medium === undefined || medium.length === undefined || medium.length === 0){
            toast.error("Entry required for medium !");
            clearErrors();
            return;
        }

        if(st_fy === undefined || st_fy.length === undefined || st_fy.length === 0){
            toast.error("Entry required for financial year !");
            clearErrors();
            return;
        }

        if(st_newold === undefined || st_newold.length === undefined || st_newold.length === 0){
            toast.error("Entry required for new/ old !");
            clearErrors();
            return;
        }

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.error("Entry required for class !");
            clearErrors();
            return;
        }

        if(st_sec === undefined || st_sec.length === undefined || st_sec.length === 0){
            toast.error("Entry required for section !");
            clearErrors();
            return;
        }

        if(stream === undefined || stream.length === undefined || stream.length === 0){
            toast.error("Entry required for stream !");
            clearErrors();
            return;
        }              
       
        const data = {
            under_comp_branch_id,
            board,
            fee_categ,
            pay_term,
            student_type,
            medium,
            st_class,
            st_sec,
            stream,
            new_old : st_newold,
            fy : st_fy,                  
            toitems: Array(items),
            // items,
        };

        // console.log("data is : ", data)

        dispatch(emptyCart());
        dispatch(addTempList(data));

        setapiName('url_import_excel_student_master_new');

        setModelModelFormYesNo(true)
    }

    const AdmOkFunction = (event) => {
        event.preventDefault();

        navigate("/exadm")

        // if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
        //     toast.error("Entry required for under branch !");
        //     clearErrors();
        //     return;
        // }

        // if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
        //     toast.error("Entry required for class !");
        //     clearErrors();
        //     return;
        // }

        // if(st_fy === undefined || st_fy.length === undefined || st_fy.length === 0){
        //     toast.error("Entry required for financial year !");
        //     clearErrors();
        //     return;
        // }

        // // console.log("Admission ok items => ", items.length)

        // if (items.length === 0 || items === undefined){
        //     toast.info("We have not found records. Please click on find button to continue...")
        //     clearErrors();
        //     return;
        // }        

        // const data = {
        //     under_comp_branch_id,
        //     board,
        //     fee_categ,
        //     pay_term,
        //     student_type,
        //     medium,
        //     st_class,
        //     st_sec,
        //     stream,
        //     new_old : st_newold,
        //     fy : st_fy,                  
        //     toitems: Array(items),
        //     // items,
        // };

        // // console.log("data is : ", data)

        // dispatch(emptyCart());
        // dispatch(addTempList(data));

        // setapiName('url_insert_student_excel_new_proc');

        // setModelModelFormYesNo(true)
    }

    const handleShowRecordOption = (event) => {
        event.preventDefault();
        setItems(ReduxData.cartData)
        const updatedata = ReduxData.cartData[0];
        
        // console.log("updatedata.length is : ", updatedata)        

        if (updatedata === undefined){
            setTabID(0);     
            setdefaultdata([]);        
            setItems([]);    
            toast.error("Please slect excel file to show the records")
            clearErrors();
        }else{
            setTabID(updatedata.length);     
            setdefaultdata(updatedata);        
            setItems(updatedata);    
        }
    }

    const handleShowSavedRecordOption = (event) => {
        event.preventDefault();

        let response = [], updatedata = []

        // ////////////////////////////////////////////////////////////////////////

        let v_under_comp_branch_id = 0, v_board = 0, v_fee_categ = 0, v_pay_term = 0, v_student_type = 0, v_medium = 0, v_st_fy = 0, v_st_newold = 0,
        v_st_class = 0, v_st_sec = 0, v_stream = 0;

        if(under_comp_branch_id === undefined || under_comp_branch_id.length === undefined || under_comp_branch_id.length === 0){
            v_under_comp_branch_id = 0;
        }else{
            v_under_comp_branch_id = under_comp_branch_id;
        }

        if(board === undefined || board.length === undefined || board.length === 0){
            v_board = 0;
        }else{
            v_board = board;
        }

        if(fee_categ === undefined || fee_categ.length === undefined || fee_categ.length === 0){
            v_fee_categ = 0;
        }else{
            v_fee_categ = fee_categ;
        }

        if(pay_term === undefined || pay_term.length === undefined || pay_term.length === 0){
            v_pay_term = 0;
        }else{
            v_pay_term = pay_term;
        }

        if(student_type === undefined || student_type.length === undefined || student_type.length === 0){
            v_student_type = 0;
        }else{
            v_student_type = student_type;
        }

        if(medium === undefined || medium.length === undefined || medium.length === 0){
            v_medium = 0;
        }else{
            v_medium = medium;
        }

        if(st_fy === undefined || st_fy.length === undefined || st_fy.length === 0){
            v_st_fy = 0;
        }else{
            v_st_fy = st_fy;
        }

        if(st_newold === undefined || st_newold.length === undefined || st_newold.length === 0){
            v_st_newold = 0;
        }else{
            v_st_newold = st_newold;
        }

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            v_st_class = 0;
        }else{
            v_st_class = st_class;
        }

        if(st_sec === undefined || st_sec.length === undefined || st_sec.length === 0){
            v_st_sec = 0;
        }else{
            v_st_sec = st_sec;
        }

        if(stream === undefined || stream.length === undefined || stream.length === 0){
            v_stream = 0;
        }else{
            v_stream = stream;
        }

        // ///////////////////////////////////////////////////////////////////////       
        
        const getTableRecordFunctionBranch = async (var_link) => {
            response = await axios.get(`${var_link}/${v_under_comp_branch_id}/${v_board}/${v_fee_categ}/${v_pay_term}/${v_student_type}/${v_medium}/${v_st_fy}/${v_st_newold}/${v_st_class}/${v_st_sec}/${v_stream}/`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            updatedata = await response.data.v_out_data

            // console.log("updatedata is : ", updatedata)

            if (updatedata.length === 0){
                toast.info("No data found");
                clearErrors();
                return;
            }
        
            setTabID(updatedata.length);     
            setdefaultdata(updatedata);        
            setItems(updatedata);    
        }
        getTableRecordFunctionBranch('url_import_excel_student_master');      
        // console.log(updatedata)        
    }
    
    const handleUnderBranchOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;        
        setunder_comp_branch_id(getId);
        
        setItems([])
    }

    const goPrePageFunction = (event) => {
        event.preventDefault();
        navigate('/brms')
    }

    const handleboardListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setboard(getId);

        setItems([]);
    }

    const handlefee_categListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setfee_categ(getId);

        setItems([]);
    }

    const handlepay_termOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setpay_term(getId);

        setItems([]);
    }

    const handlestudent_typeListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setstudent_type(getId);

        setItems([]);
    }

    const handlemediumListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setmedium(getId);
        setItems([]);
    }

    const handlest_classListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setst_class(getId);
        setItems([]);
    }

    const handlest_classsecListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setst_sec(getId);
        setItems([]);
    }

    const handlestreamListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setstream(getId);
        setItems([]);
    }

    const handlest_newoldListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setst_newold(getId);
        setItems([]);
    }

    const handlest_fyListOption = (event) => {
        event.preventDefault();
        const getId = event.target.value;
        setst_fy(getId);
        setItems([]);
    }

return (

<>

<div className="pt-PaddingTopAfterMenu pl-1 flex justify-start text-MainHeadingFontSize text-MainHeadingFontColor bg-MainHeadingBGColor">Branch Wise Import Student Data From Excel</div>
                
        <div className="grid lg:grid-cols-7 gap-2">

            <div className="col-span-2">
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Under Branch</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="under_comp_branch_id" value={under_comp_branch_id} onChange={(e) => handleUnderBranchOption(e)}
                >
                    <option></option>
                    {
                        under_comp_branchItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>    
                
            <div className="col-span-1">
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Board</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="board" value={board} onChange={(e) => handleboardListOption(e)}
                >
                    <option></option>
                    {
                        boardItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>            

            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Fee Category</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="fee_categ" value={fee_categ} onChange={(e) => handlefee_categListOption(e)}
                >
                    <option></option>
                    {
                        fee_categItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>            
            
            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Payment Term</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="pay_term" value={pay_term} onChange={(e) => handlepay_termOption(e)}
                >
                    <option></option>
                    {
                        pay_termItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>
            
            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Student Type</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="student_type" value={student_type} onChange={(e) => handlestudent_typeListOption(e)}
                >
                    <option></option>
                    {
                        student_typeItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>            

            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Medium</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="medium" value={medium} onChange={(e) => handlemediumListOption(e)}
                >
                    <option></option>
                    {
                        mediumItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div> 

            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Session</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="st_fy" value={st_fy} onChange={(e) => handlest_fyListOption(e)}
                >
                    <option></option>
                    {
                        st_fyItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">New/Old</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="st_newold" value={st_newold} onChange={(e) => handlest_newoldListOption(e)}
                >
                    <option></option>
                    {
                        st_newoldItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>                        

            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Class</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="st_class" value={st_class} onChange={(e) => handlest_classListOption(e)}
                >
                    <option></option>
                    {
                        st_classItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div> 

            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Section</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="st_sec" value={st_sec} onChange={(e) => handlest_classsecListOption(e)}
                >
                    <option></option>
                    {
                        st_classsecItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>
            
            <div>
                <label className="mb-1 max-w-full text-InputFontColor font-LabelFontWeight font-InputFontName">Stream</label>
                <select className="w-[100%] py-PaddingPY border-InputBorderWidth border-InputBorderColor px-PaddingPX text-InputFontSize text-InputFontColor font-InputFontName"
                    name="stream" value={stream} onChange={(e) => handlestreamListOption(e)}
                >
                    <option></option>
                    {
                        streamItems.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>
            
            <div className="mt-6 col-span-1">
                <StudentExImport />
            </div>

            <div className="mt-2 col-span-1">

                <button className="w-[47%] ml-0 mt-2 h-DropDownInputHeight bg-NewButtonBGColor hover:bg-ViewRowBtnHoverColor text-ViewRowBtnFontSize text-ViewRowBtnFontColor font-ViewRowBtnFontName rounded-none"
                    onClick={handleShowRecordOption}
                >Show</button>

                <button className="w-[47%] ml-3 mt-2 h-DropDownInputHeight bg-BackBtnHoverColor hover:bg-ViewRowBtnHoverColor text-ViewRowBtnFontSize text-ViewRowBtnFontColor font-ViewRowBtnFontName rounded-none"
                    onClick={handleShowSavedRecordOption}
                >Find</button>

            </div>

        </div>


        <div className="mt-2 border-TabBorderWidth border-TabHeadingBorderColor overflow-y-scroll">
            
            <div className="grid grid-cols-12">

                {/* <div className="col-span-12">                   */}
                    
                    <div className="pr-PaddingRight text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        sr_no
                    </div>
            
                    <div className="pr-PaddingRight text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        st_name
                    </div>

                    <div className="pl-PaddingLeft text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        male_female
                    </div>

                    <div className="pl-PaddingLeft text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        dob
                    </div>

                    <div className="pl-PaddingLeft text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        dob_text
                    </div>
                    
                    <div className="pl-PaddingLeft text-center text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        fat_name
                    </div>

                    <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        mot_name
                    </div>

                    <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        contact_no
                    </div>

                    <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        adm_date
                    </div>

                    <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        adm_date_text
                    </div>

                    <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        class_adm
                    </div>

                    <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        st_address
                    </div>

                    <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        cast_categ
                    </div>

                    <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        pre_school
                    </div>

                    <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        pre_class
                    </div>

                    <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        fat_mobile_1
                    </div>

                    <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        mot_mobile_1
                    </div>

                    <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        what_app_mobile
                    </div>

                    <div className="pl-PaddingLeft text-TabHeadFontSize text-TabHeadFontColor font-InputFontName font-TabeadingFontWeight border-TabHeadingBorderWidth border-TabHeadingBorderColor bg-TabHeadBGColor
                        col-span-1">
                        fat_mobile_2
                    </div>
                    
                {/* </div> */}
            </div>
        </div>
        
        <div className="border-TabBorderWidth border-TabBorderColor h-InsertTableHeight overflow-y-scroll  overflow-x-scroll">
            {
                items?.map((item, i) => (
        
                    <div className="grid grid-cols-12" key={item.t1_id}>
        
                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="sr_no"
                            value={item.sr_no}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="st_name"
                            value={item.st_name}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="male_female"
                            value={item.male_female}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="dob"
                            value={item.dob}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="dob_text"
                            value={item.dob_text}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="fat_name"
                            value={item.fat_name}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="mot_name"
                            value={item.mot_name}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="contact_no"
                            value={item.contact_no}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="adm_date"
                            value={item.adm_date}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="adm_date_text"
                            value={item.adm_date_text}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="class_adm"
                            value={item.class_adm}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />                       

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="st_address"
                            value={item.st_address}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="cast_categ"
                            value={item.cast_categ}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="pre_school"
                            value={item.pre_school}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="pre_class"
                            value={item.pre_class}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="fat_mobile_1"
                            value={item.fat_mobile_1}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="mot_mobile_1"
                            value={item.mot_mobile_1}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />


                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="what_app_mobile"
                            value={item.what_app_mobile}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                        <input className="w-[100%] pl-PaddingLeft text-InputFontSize text-InputFontColor font-InputFontName border-InputBorderWidth border-InputBorderColor focus:bg-InputFocusBGColor
                            col-span-1"
                            type="text"
                            name="fat_mobile_2"
                            value={item.fat_mobile_2}
                            // onChange={(e) => handlerChange(e, i)}
                            disabled                                
                        />

                    </div>
                ))
            }
        </div>
        
        <div className="mt-2 flex justify-center gap-4">
            <div className="w-[10%] text-center bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                >
                Record Found : {items.length}
            </div>  

            <button className="w-[10%] bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
                onClick={createRecordFunction}>
                Save
            </button>

            <CSVLink className="w-[10%] text-center bg-SaveButtonBGColor hover:bg-SaveButtonHoverColor text-SaveButtonFontColor text-BtnFontSize font-InputFontName rounded-none"
            data={items} filename="RegisterUserData">Export User Data</CSVLink>

            <button className="w-[10%] text-center bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                onClick={AdmOkFunction}>
                Admission Ok
            </button>

            <button className="w-[10%] text-center bg-DeleteButtonBGColor hover:bg-DeleteButtonHoverColor text-my-fontSize text-DeleteButtonFontColor font-my-font-name border-my-tableBorderWidth border-my-border-color rounded-none"
                onClick={goPrePageFunction}>
                Back
            </button>
        
        </div>
        
        {/* <ModelFormDeleteUpdate api_type = {'UPDATE'} api_name={'url_fee_code_setting_branch_wise_updel_multiple'} isVisible={ShowModelFormDeleteUpdate} onClose={() => setModelFormDeleteUpdate(false)} /> */}
       
        {/* <ModelFormYesNo table_name = 'fee_code_setting_branch_wise' api_name = {'url_fee_code_setting_branch_wise_updel_multiple'} isVisible = {ShowModelFormYesNo} onClose = {()=>setModelModelFormYesNo(false)} /> */}

        <ModelFormYesNo table_name = {'fee_code_setting_branch_wise'} api_name = {apiName} isVisible = {ShowModelFormYesNo} onClose = {()=>setModelModelFormYesNo(false)} />

</>
);
};

export default Sdt;
