import React, { useContext, useState, useRef } from "react";
import { toast } from "react-toastify";
import CRUDContext from '../components/CRUDContext';
import {ValidateInput, capitalizeWordFun} from '../components/GlobalFunction';

const ConfirmModelUpdateDelete = (props) => {
    const inputRefs = useRef([]);     

    const ConfirmMessage = (props.transType === 'UPDATE' ? 'Confirm Update' : 'Confirm Delete');
    const ReasonMessage  = (props.transType === 'UPDATE' ? 'Reason For Update' : 'Reason For Delete');

    let v_input_result, v_active_status = 1, v_null_parameter = 'NA';

    const [notes, setnotes] = useState('');

    // define standard errors and entry required messages

    const {      
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);
    
    const sendDataToParentFun = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        SelectedDataFunction(value);
    }

    const SelectedDataFunction = (p_value) => {

        let v_last_access_ip_final = '', v_last_updel_notes_final = '';

        if (p_value === 'YES'){

            if(notes.length === 0){
                toast.error('Entry required for reason !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;    
            }
            
            v_last_access_ip_final = 'shiv';
            v_last_updel_notes_final = notes;            
        }
        
        const data = {ModelYesNoAnswer:p_value, transType:props.transType, last_access_ip_final:v_last_access_ip_final, last_updel_notes_final:v_last_updel_notes_final};

        props.setModelSaveUpdateDeleteFalseFunction(data);
    }

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
        setnotes(capitalizeWordFun(v_input_result.v_value)); 

        

        // console.log('in handleInputValue function')        
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };
    
return (

<div>           
    <h1 className="h1_model_saveupdel_yes_css">{ConfirmMessage}</h1>
    <h1 className="h2_model_saveupdel_yes_css">Are you sure to continue ?</h1>

    <div>
        <label className="label_inner_page_css">{ReasonMessage}</label>
        <textarea className="input_inner_page_css h-24 resize-none rounded-md"
            ref={(ref) => addInputRef(ref, 0)}
            autoFocus
            name="notes"
            id="notes"
            value={notes}
            // placeholder="notes"                    
            onChange={handleInputValue}                
            // onKeyDown={handleEnter}
            // onFocus={handleInputFocus}
            // onBlur={handleInputBlur}
            // disabled            
        >
        </textarea>

    </div>

    <div className="btn_div_model_inner_css">
        <div className="btn_div_model_saveupdel_css">
            <button className="btn_model_saveupdel_yes_css" type="submit" value = 'YES' onClick={sendDataToParentFun}>Yes</button>
        </div>

        <div className="btn_div_model_saveupdel_css">
            <button className="btn_model_saveupdel_no_css" type="submit" value = 'NO' onClick={sendDataToParentFun}>No</button>
        </div>
    </div>
</div>
);
};

export default ConfirmModelUpdateDelete;
